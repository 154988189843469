import Swal from 'sweetalert2';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import OrderService from '../../services/OrderService';
import LayoutAdminComponent from '../../components/LayoutAdminComponent';
import { Field, Form, Formik, useFormikContext, ErrorMessage } from 'formik';

export default function BodegaEnvioPage() {
    const [order, setOrder] = useState({
        products: [],
    });

    const params = useParams();

    useEffect(() => {
        const getOrder = async () => {
            const order = await OrderService.getOrder(params.orderId);
            setOrder(order);
        };
        getOrder();
    }, [params]);

    const getOrder = async () => {
        const order = await OrderService.getOrder(params.orderId);
        setOrder(order);
    };

    const setProcessed = () => {
        Swal.fire({
            title: 'Marcar como tramitado',
            text: '¿Está seguro de marcar esta orden como tramitada?',
            icon: 'question',
            showCancelButton: true,
            cancelButtonColor: '#d33',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const resp = await OrderService.setProcessed(params.orderId);
                console.log(resp);
                if (resp._id) {
                    getOrder();
                    Swal.fire('Orden tramitada', 'La orden ha sido tramitada', 'success');
                } else {
                    Swal.fire('Error', 'La orden no se pudo tramitar.' + resp.error, 'error');
                }
            }
        });
    };

    const initialValues = {
        transportadora: '',
        guia: '',
        link: '',
        height: '',
        length: '',
        width: '',
        weight: '',
    };

    const validation = (values) => {
        const errors = {};
        if (
            values.link &&
            // eslint-disable-next-line no-useless-escape
            !/^(http|https):\/\/[\w\-]+(\.[\w\-]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?$/.test(
                values.link
            )
        ) {
            errors.link = 'Ingrese un link válido';
        }
        return errors;
    };

    const onSubmit = async (values) => {
        const resp = await OrderService.updateShipping(params.orderId, values);
        if (resp._id) {
            Swal.fire('Actualizado', 'Información actualizada correctamente', 'success');
            window.history.back();
        } else {
            Swal.fire('Error', 'La información no se pudo actualizar', 'error');
        }
    };

    return (
        <LayoutAdminComponent>
            <div className="pb-5">
                <div className="px-md-5 px-2">
                    <div className="bg-primary rounded px-3 py-1 my-4">
                        <p className="text-white mb-0">Añadir información detalles del paquete</p>
                    </div>

                    <Formik
                        initialValues={initialValues}
                        validate={validation}
                        validateOnChange
                        onSubmit={onSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <Form>
                                <BodegaEnvioForm order={order} setProcessed={setProcessed} />
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </LayoutAdminComponent>
    );
}

function BodegaEnvioForm({ order, setProcessed }) {
    const { setValues, isSubmitting, errors } = useFormikContext();

    useEffect(() => {
        setValues({
            transportadora: order?.transportadora,
            guia: order?.guia,
            link: order?.link,
            height: order?.height,
            length: order?.length,
            width: order?.width,
            weight: order?.weight,
        });
    }, [order, setValues]);

    return (
        <>
            <div className="row w-100 ">
                <div className="col-lg-4 mb-3">
                    <label htmlFor="">Seleccione transportadora</label>
                    <Field
                        component="select"
                        className="form-control"
                        name="transportadora"
                        id="transportadora"
                    >
                        <option value="">Seleccione</option>
                        <option value="dhl">DHL</option>
                        <option value="coordinadora">COORDINADORA</option>
                    </Field>
                </div>
                <div className="col-lg-4">
                    <label htmlFor=""># de Guía generada</label>
                    <Field
                        type="text"
                        className="form-control"
                        placeholder="Número de guía"
                        name="guia"
                        id="guia"
                    />
                    {errors?.guia && (
                        <ErrorMessage name="guia" component="small" className="text-danger" />
                    )}
                </div>
                <div className="col-lg-4">
                    <label htmlFor="">Adjuntar link de la guía</label>
                    <Field
                        type="text"
                        className="form-control"
                        placeholder="Link"
                        name="link"
                        id="link"
                    />
                    {errors?.link && (
                        <ErrorMessage name="link" component="small" className="text-danger" />
                    )}
                </div>
            </div>

            <div className="row w-100 ">
                <div className="col-lg-4 mb-3">
                    <label htmlFor="">Tamaño (cm)</label>
                    <div className="d-flex justify-content-between" style={{ gap: '1em' }}>
                        <Field
                            type="number"
                            className="form-control"
                            placeholder="Alto (cm)"
                            name="height"
                            id="height"
                            min="0"
                        />
                        <Field
                            type="number"
                            className="form-control"
                            placeholder="Largo (cm)"
                            name="length"
                            id="length"
                            min="0"
                        />
                        <Field
                            type="number"
                            className="form-control"
                            placeholder="Ancho (cm)"
                            name="width"
                            id="width"
                            min="0"
                        />
                    </div>
                </div>
                <div className="col-lg-4 mb-3">
                    <label htmlFor="">Peso (kg)</label>
                    <Field
                        type="number"
                        className="form-control"
                        name="weight"
                        id="weight"
                        placeholder="Peso (kg)"
                    />
                </div>
                <div className="col-lg-4 mb-3 d-flex justify-content-end align-items-end">
                    <button type="submit" className="btn btn-sm btn-outline-success">
                        {isSubmitting ? 'Enviando...' : 'Guardar'}
                    </button>
                </div>
            </div>
            {/* {<div className="row w-100">
                <div className="col-12">

                    <hr />
                    <div className='d-flex justify-content-end flex-row'>
                        {
                            order.status === 'processed' || order.status !== 'completed' ?
                                <button type='button' className='btn btn-success btn-sm'>Tramitado</button>
                                :
                                <button type='button' className='btn btn-outline-success btn-sm' onClick={setProcessed}>Marcar como tramitado</button>
                        }
                    </div>
                </div>

            </div>} */}
        </>
    );
}
