import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import './trulift.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import { userLoged } from './utils/auth.js';
import CartPage from './pages/client/CartPage';
import UsersPage from './pages/admin/UsersPage';
import SiigoPage from './pages/admin/SiigoPage';
import OrderPage from './pages/client/OrderPage';
import CuentaPage from './pages/client/CuentaPage';
import TiendaPage from './pages/client/TiendaPage';
import SoportePage from './pages/client/SoportePage';
import ProductList from './pages/client/ProductList';
import ProductPage from './pages/client/ProductPage';
import ProductsPage from './pages/admin/ProductsPage';
import MyOrdersPage from './pages/client/MyOrdersPage';
import ProtectedRoute from './components/ProtectedRoute';
import PedidosPage from './pages/contabilidad/PedidosPage';
import UserDetailsPage from './pages/admin/UserDetailsPage';
import SoporteContacto from './pages/client/SoporteContacto';
import AdminProductPage from './pages/admin/AdminProductPage';
import SoportePoliticas from './pages/client/SoportePoliticas';
import PasswordRecoveryPage from './pages/PasswordRecoveryPage';
import PedidosPendientesPage from './pages/bodega/PedidosPendientesPage';
import PedidosTramitadosPage from './pages/bodega/PedidosTramitadosPage';
import PasswordRecoveryConfirmPage from './pages/PasswordRecoveryConfirmPage';
import ContabilidadOrderPage from './pages/contabilidad/ContabilidadOrderPage';
import PedidosProcesadosPage from './pages/bodega/PedidosProcesadosPage';
import BodegaOrderPage from './pages/bodega/BodegaOrderPage';
import BodegaEnvioPage from './pages/bodega/BodegaEnvioPage';
import SignupPage from './pages/SignupPage';
import SignupPageSuccess from './pages/SignupPageSuccess';
import ReporteVentasPage from './pages/admin/ReporteVentasPage';
import ReporteUsuariosPage from './pages/admin/ReporteUsuariosPage';
import RankingPiezasPage from './pages/admin/RankingPiezasPage';
import HistoricoBusquedaPage from './pages/admin/HistoricoBusquedaPage';
import { Backorders } from './pages/bodega/Backorders';
import { BackOrderDetails } from './pages/bodega/BackOrderDetails';
function App() {
    const validateHome = () => {
        if (userLoged) {
            if (userLoged.role === 'admin') {
                return <Navigate replace to="/usuarios" />;
            } else if (userLoged.role === 'client') {
                return <HomePage />;
            } else if (userLoged.role === 'contabilidad') {
                return <Navigate replace to="/pedidos" />;
            } else if (userLoged.role === 'bodega') {
                return <Navigate replace to="/pedidos-pendientes" />;
            }
        } else {
            return <Navigate replace to="/login" />;
        }
    };

    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route exact path="/" element={<>{validateHome()}</>} />

                    {/* Rutas publicas */}
                    <Route element={<ProtectedRoute isAllowed={!userLoged} />}>
                        <Route exact path="/login" element={<LoginPage />} />
                        <Route exact path="/password-recovery" element={<PasswordRecoveryPage />} />
                        <Route
                            exact
                            path="/password-recovery-confirm/:token"
                            element={<PasswordRecoveryConfirmPage />}
                        />
                        <Route exact path="/request-user" element={<SignupPage />} />
                        <Route exact path="/request-user/success" element={<SignupPageSuccess />} />
                    </Route>

                    {/* Rutas Privadas Admins */}
                    <Route
                        element={
                            <ProtectedRoute isAllowed={!!userLoged && userLoged.role === 'admin'} />
                        }
                    >
                        <Route exact path="/usuarios" element={<UsersPage />} />
                        <Route exact path="/user/:userId" element={<UserDetailsPage />} />
                        <Route exact path="/productos" element={<ProductsPage />} />
                        <Route exact path="/siigo" element={<SiigoPage />} />
                        <Route
                            exact
                            path="/admin/producto/:productId/*"
                            element={<AdminProductPage />}
                        />
                        <Route exact path="/admin/pedido/:orderId" element={<BodegaOrderPage />} />
                        <Route exact path="/reporte-ventas" element={<ReporteVentasPage />} />
                        <Route exact path="/reporte-usuarios" element={<ReporteUsuariosPage />} />
                        <Route
                            exact
                            path="/ranking-piezas-mas-vendidas"
                            element={<RankingPiezasPage />}
                        />
                        <Route
                            exact
                            path="/historico-busqueda"
                            element={<HistoricoBusquedaPage />}
                        />
                        <Route exact path="/gestor-pedidos" element={<PedidosPage />} />
                        <Route
                            exact
                            path="/gestor-pedidos/pedido/:orderId"
                            element={<ContabilidadOrderPage />}
                        />
                        {/* <Route exact path="/backorders" element={<Backorders />} /> */}
                        {/* <Route exact path="/backorders/:orderId" element={<BackOrderDetails />} /> */}
                    </Route>

                    <Route
                        element={
                            <ProtectedRoute
                                isAllowed={
                                    (!!userLoged && userLoged?.role === 'admin') ||
                                    userLoged?.role === 'bodega'
                                }
                            />
                        }
                    >
                        <Route exact path="/backorders" element={<Backorders />} />
                        <Route exact path="/backorders/:orderId" element={<BackOrderDetails />} />
                    </Route>

                    {/* Rutas Privadas contabilidad */}
                    <Route
                        element={
                            <ProtectedRoute
                                isAllowed={!!userLoged && userLoged.role === 'contabilidad'}
                            />
                        }
                    >
                        <Route exact path="/pedidos" element={<PedidosPage />} />
                        <Route exact path="/pedido/:orderId" element={<ContabilidadOrderPage />} />
                    </Route>

                    {/* Rutas Privadas bodega */}
                    <Route
                        element={
                            <ProtectedRoute
                                isAllowed={!!userLoged && userLoged.role === 'bodega'}
                            />
                        }
                    >
                        <Route
                            exact
                            path="/pedidos-pendientes"
                            element={<PedidosPendientesPage />}
                        />
                        <Route
                            exact
                            path="/pedidos-tramitados"
                            element={<PedidosTramitadosPage />}
                        />
                        <Route
                            exact
                            path="/pedidos-procesados"
                            element={<PedidosProcesadosPage />}
                        />
                        <Route exact path="/bodega/pedido/:orderId" element={<BodegaOrderPage />} />
                        <Route
                            exact
                            path="/bodega/pedido/:orderId/envio"
                            element={<BodegaEnvioPage />}
                        />
                    </Route>

                    {/* Rutas Privadas Clientes*/}
                    <Route
                        element={
                            <ProtectedRoute
                                isAllowed={!!userLoged && userLoged.role === 'client'}
                            />
                        }
                    >
                        <Route exact path="/" element={<HomePage />} />
                        <Route exact path="/partes/*" element={<ProductList />} />
                        <Route
                            exact
                            path="/producto/:productId/:productName/*"
                            element={<ProductPage />}
                        />
                        <Route exact path="/tienda" element={<TiendaPage />} />
                        <Route exact path="/carrito/:cartId" element={<CartPage />} />
                        <Route exact path="/soporte" element={<SoportePage />} />
                        <Route exact path="/contacto" element={<SoporteContacto />} />
                        <Route exact path="/politicas" element={<SoportePoliticas />} />
                        <Route exact path="/mis-ordenes" element={<MyOrdersPage />} />
                        <Route exact path="/mis-ordenes/:orderId" element={<OrderPage />} />
                        <Route exact path="/cuenta" element={<CuentaPage />} />
                        <Route
                            exact
                            path="/mis-pedidos-procesados"
                            element={<MyOrdersPage filter={'completed'} filter2={'approved'} />}
                        />
                        <Route
                            exact
                            path="/mis-pedidos-pendientes"
                            element={<MyOrdersPage filter={'pending'} filter2={'backorder'} />}
                        />
                    </Route>
                </Routes>

                {/* Not Found Page */}
                {/* <Route path='*' element={<Navigate replace to='/' />} /> */}
            </Router>
        </div>
    );
}

export default App;
