import { get } from '../utils/request'

class SiigoService {
    async logs() {
        return await get('siigo/logs');
    }

    async status() {
        return await get('siigo/status');
    }
}

export default new SiigoService();