import React from 'react';
import { cities } from '../constants';
export const SelectState = ({
    handleChange,
    country,
    defaultValue,
    className = 'form-control',
    disabled,
}) => {
    console.log({country, defaultValue})
    return (
        <div className="form-group">
            <label htmlFor="state">Departamento/Estado</label>
            <select
                name="state"
                id="state"
                className={className}
                onChange={handleChange}
                defaultValue={defaultValue}
                disabled={disabled}
            >
                {cities.find((city) => city.StateCode === defaultValue) ? (
                    <option value={defaultValue}>
                        {cities.find((city) => city.StateCode === defaultValue).StateName}
                    </option>
                ) : (
                    <option value="">Seleccione</option>
                )}
                {cities
                    .filter((city) => city.CountryCode?.toLowerCase() === country?.toLowerCase())
                    .filter(
                        (city, index, self) =>
                            self.findIndex((c) => c.StateName === city.StateName) === index
                    )
                    .sort()
                    .map((city, index) => (
                        <option key={index} value={city.StateCode}>
                            {city.StateName}
                        </option>
                    ))}
            </select>
        </div>
    );
};
