import React from 'react'

import MenuAdmin from './MenuAdmin'
import MenuBodega from './MenuBodega'
import { userLoged } from '../utils/auth'
import logo from '../assets/trulift-logo.svg'
import MenuContabilidad from './MenuContabilidad'
import ValidateFirstLogin from './ValidateFirstLogin'

export default function LayoutAdminComponent({ children }) {

  const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    window.location.href = '/login'
  }

  return (
    <>
      <ValidateFirstLogin />
      <main className='d-flex' style={{ minHeight: '100vh' }}>
        <div className="d-flex flex-column flex-shrink-0 py-3 ps-3 text-white bg-primary no-print" style={{ width: '280px' }}>
          <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none ps-3 pe-5">
            <img src={logo} alt="Trulift logo" className='img-fluid px-4 px-md-0 pb-2 pb-md-0 rounded' />
          </a>
          <hr />
          <ul className="nav nav-pills flex-column mb-auto">
            {
              userLoged.role === 'admin' && <MenuAdmin />
            }
            {
              userLoged.role === 'contabilidad' && <MenuContabilidad />
            }
            {
              userLoged.role === 'bodega' && <MenuBodega />
            }
            <li>
              <span className="nav-link text-white" onClick={logout} role="button">
                Salir
              </span>
            </li>
          </ul>
        </div>
        <div className=" pt-5 d-flex flex-column flex-shrink-0 p-3 ps-4 bg-light main-layout">
          {
            children
          }
        </div>
      </main>

    </>
  )
}
