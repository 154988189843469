import moment from 'moment'
import React from 'react'
import Swal from 'sweetalert2';
import UserService from '../services/UserService';

export default function UserSiigoInfo({ user, updateData }) {
    const vincularSiigo = async () => {
        const resp = await UserService.vincularSiigo({ userId: user._id })
        if (resp.success) {
            Swal.fire({
                title: 'Vinculación exitosa',
                text: 'Se ha vinculado el usuario con la base de datos de clientes de Siigo',
                icon: 'success',
                confirmButtonText: 'Aceptar',
            }).then(() => {
                updateData(user._id)
            })

        } else {
            if (resp.message) {
                Swal.fire({
                    title: 'Datos incorrectos',
                    text: resp.message,
                    icon: 'warning',
                    confirmButtonText: 'Aceptar',
                })
            }
        }
    }

    return (
        <>
            <div className='bg-primary rounded px-3 py-2'>
                <p className='text-white mb-0'>Siigo Info</p>
            </div>

            <div className="row mt-3">
                {
                    user.lastUpdateSiigo ? <>
                        <div className="col-lg-6 mb-4">
                            <p htmlFor="">Siigo ID: <br />{user.siigoId}</p>
                        </div>
                        <div className="col-lg-6 mb-4">
                            <p htmlFor="">Última actualización:<br></br>
                                {
                                    user.lastUpdateSiigo && moment(user.lastUpdateSiigo).format('DD/MM/YYYY HH:mm a')
                                }
                            </p>
                        </div>
                    </>
                        :
                        <>
                            <div className="col-lg-12 mb-4">
                                <button className='btn btn-sm btn-outline-primary ms-3' type='button' onClick={vincularSiigo}>Vincular a Siigo</button>
                            </div>
                        </>
                }
            </div>
        </>
    )
}
