import React from 'react';
import LayoutAdminComponent from '../../components/LayoutAdminComponent';
import OrderService from '../../services/OrderService';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { AscButton, DescButton } from '../../components/SortButton';
export const Backorders = () => {
    const [data, setData] = React.useState([]);
    const [asc, setAsc] = React.useState(true);
    const [desc, setDesc] = React.useState(false);
    const navigate = useNavigate();
    React.useEffect(() => {
        const fetchData = async () => {
            const response = await OrderService.getBackOrders();
            const sorted = _.orderBy(response, (item) => new Date(item.createdAt), 'desc');
            setData(sorted);
        };
        fetchData();
    }, []);

    const formatDate = (date) => {
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };

        return new Date(date).toLocaleString('es-ES', options);
    };

    const handleNavigation = (orderId) => {
        const searchOrder = data.find((order) => order._id === orderId);
        localStorage.setItem('order', JSON.stringify(searchOrder));

        navigate(`/backorders/${orderId}`);
    };

    const handleSort = () => {
        if (asc) {
            setData(_.orderBy(data, (item) => new Date(item.createdAt), ['asc']));
            setDesc((desc) => !desc);
            setAsc((asc) => !asc);
        } else {
            setData(_.orderBy(data, (item) => new Date(item.createdAt), ['desc']));
            setAsc((asc) => !asc);
            setDesc((desc) => !desc);
        }
    };

    return (
        <LayoutAdminComponent>
            <p className="text-primary title mb-4">Backorders</p>
            <div className="d-flex justify-content-end">
                <div>
                    <AscButton label="Ordenar más nuevo" asc={asc} onClick={handleSort} />
                    <DescButton label="Ordenar más viejo" desc={desc} onClick={handleSort} />
                </div>
            </div>
            <div className="card shadow pt-3 pb-3">
                <div className="card-body">
                    <table className="w-100 table-sm">
                        <thead>
                            <tr>
                                <th>Número de orden</th>
                                <th>Productos</th>
                                <th>Usuario</th>
                                <th>Status</th>
                                <th>Fecha de creación</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data &&
                                data.map((order) => (
                                    <tr key={order._id}>
                                        <td>#{order.order_number}</td>
                                        <td>
                                            {order.products.map((product, index) => (
                                                <div
                                                    className="flex flex-row gap-1"
                                                    key={product._id}
                                                >
                                                    <span style={{ marginRight: '5px' }}>
                                                        {product.name}
                                                    </span>
                                                    | <span>Cantidad: {product.quantity}</span>
                                                </div>
                                            ))}
                                        </td>
                                        <td>
                                            {order.user?.firstName} {order.user?.lastName}
                                            <p> {order.user?.email}</p>
                                        </td>
                                        <td>
                                            <h6>
                                                <span className="badge bg-success">
                                                    {order.status === 'processed'
                                                        ? 'Notificado'
                                                        : 'Pendiente'}
                                                </span>
                                            </h6>
                                        </td>
                                        <td>{formatDate(order.createdAt)}</td>
                                        <td>
                                            <div className="flex flex-row gap-1">
                                                <button
                                                    onClick={() => handleNavigation(order._id)}
                                                    className="btn btn-primary btn-sm"
                                                >
                                                    <i className="fas fa-eye"></i>
                                                    Ver
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </LayoutAdminComponent>
    );
};
