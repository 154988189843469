import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import { makeUniq } from '../utils/random'
import UserService from '../services/UserService';
import Swal from 'sweetalert2';

export default function ChangePassword({ userId }) {
    const [passwordType, setPasswordType] = useState("password");
    const [password, setPassword] = useState('')

    const generateRandomPassword = () => {
        const randomPassword = makeUniq('', 10)
        setPassword(randomPassword)
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const submitChangePassword = async () => {
        const resp = await UserService.changePassword({
            userId: userId,
            newPassword: password,
            changePassword: true
        })
        if (resp) {
            Swal.fire({
                icon: 'success',
                title: 'Contraseña cambiada',
                text: '¿Desea enviar la nueva contraseña al correo del usuario?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Si, enviar',
                cancelButtonText: 'No enviar',
                preConfirm: async (result) => {
                    return await UserService.sendAccess(userId, { password });
                }
            }).then(async (result) => {
                console.log({
                    result
                });
                if (result.value.success) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Contraseña enviada',
                        text: 'La contraseña ha sido enviada al correo del usuario',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al enviar',
                        text: result.value.message || 'No se pudo enviar la contraseña al correo del usuario',
                        showConfirmButton: false,
                    })
                }
            })

            setPassword('')
        }
    }

    return (
        <div className="row mt-3">
            <div className="col-md-12 col-xl-6">
                <label htmlFor="">Nueva contraseña</label>
                <div class="input-group">
                    <input type={passwordType} className='form-control' onChange={handlePassword} value={password} />
                    <div class="input-group-append">
                        <button className="btn btn-outline-secondary" onClick={togglePassword}>
                            {passwordType === "password" ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                        </button>
                        <button className="btn btn-outline-secondary" onClick={generateRandomPassword}>
                            <FontAwesomeIcon icon={faSyncAlt} />
                        </button>
                    </div>

                </div>
            </div>
            <div className="col-md-12 col-xl-6">
                <button className='btn btn-sm btn-outline-danger mt-4' onClick={submitChangePassword}>Cambiar contraseña</button>
            </div>
        </div>
    )
}
