import moment from 'moment/moment';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { formatCurrency } from '../../utils/format';
import ImageModal from '../../components/ImageModal';
import imgPlaceholder from '../../assets/placeholder.gif';
import ProductService from '../../services/ProductService';
import LayoutAdminComponent from '../../components/LayoutAdminComponent';
import Swal from 'sweetalert2';

export default function AdminProductPage() {
    const params = useParams();
    const [product, setProduct] = useState({});
    const [referenciasCrudasString, setReferenciasCrudasString] = useState('');
    const [showImageModal, setShowImageModal] = useState(false);
    useEffect(() => {
        setProduct({});
        async function getProduct(productId) {
            const product = await ProductService.getProduct(productId);
            setProduct(product);
        }
        const productId = params['productId'];
        getProduct(productId ?? '');
    }, [params]);

    useEffect(() => {
        if (product?.crossReferences) {
            setReferenciasCrudasString(product?.crossReferences.join('\n'));
        }
    }, [product]);

    const onChangeRefCruzadas = (e) => {
        setReferenciasCrudasString(e.target.value);
    };

    function clearEmptys(e) {
        const refCruzadas = referenciasCrudasString.split('\n');
        setReferenciasCrudasString(refCruzadas.filter((ref) => ref !== '').join('\n'));
    }

    const saveRefs = async () => {
        clearEmptys();
        const crossReferences = referenciasCrudasString.split('\n').filter((ref) => ref !== '');
        await ProductService.saveRefCruzadas(product._id, { crossReferences });
        Swal.fire({
            icon: 'success',
            title: 'Referencia Agregada',
        });
    };

    const openImageViewer = (product) => {
        setShowImageModal(true);
    };

    return (
        <LayoutAdminComponent>
            <ImageModal
                show={showImageModal}
                product={product}
                onSave={(product) => {
                    setTimeout(() => {
                        setShowImageModal(false);
                        setTimeout(() => {
                            setProduct(product);
                        }, 500);
                    }, 500);
                }}
                handleClose={() => {
                    setShowImageModal(false);
                }}
            />
            <p className="text-primary title mb-4">Información del producto</p>
            <div>
                <button
                    className="btn btn-outline-primary"
                    onClick={() => {
                        window.history.back();
                    }}
                >
                    Volver
                </button>
            </div>

            <div className="bg-primary rounded px-3 py-2 my-4">
                <p className="text-white mb-0">Información del producto</p>
            </div>

            <div className="pt-4">
                <div className="row">
                    <div className="col-xl-7 ">
                        <div className="card mb-3 product-card-list">
                            <div className="card-body bg-white rounded pb-5 pt-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <img
                                            src={product.image || imgPlaceholder}
                                            alt=""
                                            className="img-fluid w-75 shadow"
                                        />
                                        <button
                                            className="btn btn-outline-primary mt-3"
                                            onClick={openImageViewer}
                                        >
                                            Cambiar imagen
                                        </button>
                                    </div>
                                    <div className="col-md-6 pt-lg-4 mb-lg-5 pb-lg-2">
                                        <h1 className="text-primary mb-3" style={{ fontSize: 22 }}>
                                            {product?.name}
                                        </h1>
                                        <p className="text-primary mb-2">
                                            <span className="text-muted">Marca</span>:{' '}
                                            {product?.additional_fields?.brand}
                                        </p>
                                        <p className="text-primary mb-2">
                                            <span className="text-muted">Modelo</span>:{' '}
                                            {product.additional_fields?.model}
                                        </p>
                                        <p className="text-primary mb-2">
                                            <span className="text-muted">Código</span>:{' '}
                                            {product.code}
                                        </p>
                                        <p className="text-primary mb-2">
                                            <span className="text-muted">Codigo de barras</span>:{' '}
                                            {product?.additional_fields?.barcode}
                                        </p>
                                        <p className="text-primary mb-2">
                                            <span className="text-muted">Precio</span>:{' '}
                                            {formatCurrency(product.price, product.currency)}
                                        </p>
                                        <p className="text-primary mb-2">
                                            <span className="text-muted">Cantidad disponible</span>:{' '}
                                            {product.available_quantity}
                                        </p>
                                        <p className="text-primary mb-2">
                                            <span className="text-muted">Fecha de creación</span>:{' '}
                                            {moment(product?.metadata?.created).format(
                                                'DD MMM YYYY HH:MM A'
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5">
                        <div className="card mb-3 product-card-list">
                            <div className="card-body bg-white rounded pb-4 pt-4">
                                <h5 className="text-primary ms-1">Referencias cruzadas</h5>

                                <small className="text-muted">
                                    Ingrese las referencias cruzadas del producto separadas por
                                    salto de linea.
                                </small>
                                <div className="mb-2 text-end">
                                    <small
                                        role="button"
                                        className="text-primary text-decoration-underline"
                                        onClick={clearEmptys}
                                    >
                                        Limpiar vacios
                                    </small>
                                </div>
                                <textarea
                                    name=""
                                    id=""
                                    cols="30"
                                    rows="12"
                                    className="form-control mb-4"
                                    style={{ fontSize: 13 }}
                                    placeholder="Ingrese las referencias cruzadas..."
                                    value={referenciasCrudasString}
                                    onChange={onChangeRefCruzadas}
                                ></textarea>
                                <button className="btn btn-outline-primary" onClick={saveRefs}>
                                    Guardar referencias cruzadas
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutAdminComponent>
    );
}
