import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import Swal from 'sweetalert2';
export const ModalCreateUser = ({ show, handleClose }) => {
    const handleValidate = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'El correo electrónico es requerido';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Correo inválido';
        }

        if (!values.firstName) {
            errors.firstName = 'El nombre es requerido';
        }

        if (!values.lastName) {
            errors.lastName = 'El apellido es requerido';
        }

        if (!values.password) {
            errors.password = 'La contraseña es requerida';
        }

        if (!values.role) {
            errors.role = 'El rol es requerido';
        }
        return errors;
    };

    const handleSubmit = async (values) => {
        try {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/api/user`, values);

            if (resp.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Usuario creado correctamente',
                    showConfirmButton: false,
                    timer: 1500,
                });
                handleClose();
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message,
            });
        }
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Crear usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        email: '',
                        password: '',
                        role: '',
                    }}
                    validate={handleValidate}
                    onSubmit={handleSubmit}
                >
                    {(formik) => (
                        <Form>
                            <div className="form-group ">
                                <label htmlFor="firstName">Nombre</label>
                                <Field
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    className="form-control"
                                    placeholder="Escribe tu nombre"
                                />
                                {formik.errors.firstName && (
                                    <ErrorMessage
                                        name="firstName"
                                        component="small"
                                        className="text-danger"
                                    />
                                )}
                            </div>
                            <div className="form-group ">
                                <label htmlFor="lastName">Apellido</label>
                                <Field
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    className="form-control"
                                    placeholder="Escribe tu apellido"
                                />
                                {formik.errors.lastName && (
                                    <ErrorMessage
                                        name="lastName"
                                        component="small"
                                        className="text-danger"
                                    />
                                )}
                            </div>
                            <div className="form-group ">
                                <label htmlFor="email">Correo electrónico</label>
                                <Field
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="form-control"
                                    placeholder="Escribe tu correo electrónico"
                                />
                                {formik.errors.email && (
                                    <ErrorMessage
                                        name="email"
                                        component="small"
                                        className="text-danger"
                                    />
                                )}
                            </div>

                            <div className="form-group">
                                <label htmlFor="password">Contraseña</label>
                                <Field
                                    type="text"
                                    name="password"
                                    id="password"
                                    className="form-control"
                                    placeholder="Escribe tu contraseña"
                                />
                                {formik.errors.password && (
                                    <ErrorMessage
                                        name="password"
                                        component="small"
                                        className="text-danger"
                                    />
                                )}
                            </div>

                            <div className="form-group ">
                                <label htmlFor="role">Rol</label>
                                <Field as="select" name="role" id="role" className="form-control">
                                    <option value="">Selecciona un rol</option>
                                    {/* <option value="admin">Administrador</option> */}
                                    <option value="bodega">Bodega</option>
                                    <option value="contabilidad">Contabilidad</option>
                                </Field>
                                {formik.errors.role && (
                                    <ErrorMessage
                                        name="role"
                                        component="small"
                                        className="text-danger"
                                    />
                                )}
                            </div>

                            {/* {loginErrors && <p className="text-danger">{loginErrors}</p>} */}
                            <Button
                                type="submit"
                                className="btn btn-primary py-md-2 py-lg-3 w-100 mb-4 mt-4"
                            >
                                {/* {isSubmiting ? 'Enviando...' : 'Siguiente'} */}
                                Siguiente
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};
