import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { NavLink } from 'react-router-dom';
import CartService from '../services/CartService';

export default function CartPreviewForm({ cart }) {

    const initialValues = {
        name: cart?.name ?? '',
    }

    const onSubmit = async (values) => {
        await CartService.update(cart._id, values)
    }

    const onValidate = (values) => {
        const errors = {}
        if (!values.name) {
            errors.name = 'El nombre es requerido'
            return errors
        } else {
            onSubmit(values);
        }
    }

    return (
        <div className='bg-white mt-4 mx-5 rounded'>
            <div className='bg-primary rounded' style={{ height: 30 }}>
            </div>
            <div className='px-lg-5 px-3 pt-4 pb-2'>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validateOnBlur
                    validate={onValidate}
                    validateOnChange={false}
                >
                    {
                        formik => (
                            <Form>
                                <div className='row mb-2 w-100'>
                                    <div className="col-lg-8 pe-0 pe-xl-5 mb-3">
                                        <div className='d-flex align-items-center'>
                                            <label htmlFor="" className='me-2'>Nombre:</label>
                                            <Field type="text" name='name' id="name" className="form-control border-black" placeholder='Ingrese el nombre del carrito de compras' />
                                        </div>
                                        <ErrorMessage name="name" className='text-danger' component='span' />
                                    </div>
                                    <div className="col-lg-2 mb-3 text-center text-lg-start">
                                        <NavLink to={`/carrito/${cart._id}`} type='button' className='btn btn-outline-primary'>Ver carrito</NavLink>
                                    </div>
                                </div>

                            </Form>
                        )
                    }
                </Formik>
            </div>
        </div>
    )
}
