import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import LayoutAdminComponent from '../../components/LayoutAdminComponent';
import OrderService from '../../services/OrderService';
import { AscButton, DescButton } from '../../components/SortButton';
import _ from 'lodash';
import moment from 'moment';

export default function PedidosProcesadosPage() {
    const [orders, setOrders] = useState([]);
    const [asc, setAsc] = useState(true);
    const [desc, setDesc] = useState(false);
    useEffect(() => {
        const getOrders = async () => {
            const responseCompleted = await OrderService.getCompleted();
            const responseProcessed = await OrderService.getProcessed();
            let response = responseCompleted.concat(responseProcessed);
            const sorted = _.orderBy(response, (item) => new Date(item.createdAt), 'desc');
            setOrders(sorted);
        };
        getOrders();
    }, []);

    useEffect(() => {
        localStorage.setItem('isPedidosProcesados', 'true');
    }, []);

    const handleSort = () => {
        if (asc) {
            setOrders(_.orderBy(orders, ['createdAt'], ['asc']));
            setDesc((desc) => !desc);
            setAsc((asc) => !asc);
        } else {
            setOrders(_.orderBy(orders, ['createdAt'], ['desc']));
            setAsc((asc) => !asc);
            setDesc((desc) => !desc);
        }
    };

    return (
        <LayoutAdminComponent>
            <p className="text-primary title mb-4">Pedidos Finalizados</p>

            <div className="mb-3 no-print text-end">
                <button className="btn btn-outline-primary btn-sm" onClick={() => window.print()}>
                    Imprimir Reporte
                </button>
            </div>

            <div className="d-flex justify-content-end">
                <div>
                    <AscButton label="Ordenar más nuevo" asc={asc} onClick={handleSort} />
                    <DescButton label="Ordenar más viejo" desc={desc} onClick={handleSort} />
                </div>
            </div>

            <div className="card shadow pt-3 pb-3">
                <div className="card-body">
                    <table className="w-100 table-sm">
                        <thead>
                            <tr>
                                <th>Número de orden</th>
                                <th>Cant.</th>
                                <th className="text-start">Referencias cruzadas</th>
                                <th className="text-start">Referencias internas</th>
                                <th>Bussiness Name</th>
                                <th>Metodo de entrega</th>
                                <th>Número de guía</th>
                                <th>Fecha de creación</th>
                                <th>Status</th>
                                <th className="no-print">Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(!orders || orders.length === 0) && (
                                <tr>
                                    <td colSpan="5" className="text-center">
                                        No hay pedidos finalizados
                                    </td>
                                </tr>
                            )}
                            {orders &&
                                orders.map((order) => (
                                    <tr key={order._id}>
                                        <td>#{order.order_number}</td>
                                        <td className="pe-0">
                                            {order.products.map((product, index) => (
                                                <>
                                                    {index > 0 ? <hr className="my-0" /> : <></>}
                                                    <p
                                                        className="mb-0 text-nowrap pe-1"
                                                        key={product._id}
                                                    >
                                                        {product.quantity}
                                                    </p>
                                                </>
                                            ))}
                                        </td>
                                        <td className="text-start px-0">
                                            {order.products.map((product, index) => (
                                                <>
                                                    {index > 0 ? <hr className="my-0" /> : <></>}
                                                    <p
                                                        className="mb-0 text-nowrap pe-1"
                                                        key={product._id}
                                                    >
                                                        {product.crossReference}
                                                    </p>
                                                </>
                                            ))}
                                        </td>
                                        <td className="text-start ps-0">
                                            {order.products.map((product, index) => (
                                                <>
                                                    {index > 0 ? <hr className="my-0" /> : <></>}
                                                    <p
                                                        className="mb-0 text-nowrap"
                                                        key={product._id}
                                                    >
                                                        {product.internalReference}
                                                    </p>
                                                </>
                                            ))}
                                        </td>
                                        <td>
                                            {[order.user?.firstName, order.user?.lastName].join(
                                                ' '
                                            )}
                                        </td>
                                        <td>{order.delivery_method}</td>
                                        <td>
                                            {order.link ? (
                                                <a
                                                    href={order.link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {order.guia}
                                                </a>
                                            ) : (
                                                order.guia
                                            )}
                                        </td>
                                        <td>
                                            {moment(order.createdAt).format('DD/MM/YYYY HH:mm a')}
                                        </td>
                                        <td>
                                            {order.status === 'completed' ? (
                                                <span className={`badge py-1 bg-completed`}>
                                                    Procesado
                                                </span>
                                            ) : (
                                                <span className={`badge py-1 bg-success`}>
                                                    Tramitado
                                                </span>
                                            )}
                                        </td>
                                        <td className="no-print">
                                            <NavLink
                                                className="d-block"
                                                to={`/bodega/pedido/${order._id}`}
                                            >
                                                Ver
                                            </NavLink>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </LayoutAdminComponent>
    );
}
