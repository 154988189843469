import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { NavLink, useNavigate } from 'react-router-dom';
import CartService from '../services/CartService';
import { userLoged } from '../utils/auth';

export default function ModalAddToCart({ show, handleClose, product, quantity, crossReference }) {
    const [carts, setCarts] = useState([]);
    const [cart, setCart] = useState({});
    const [saved, setSaved] = useState(false);
    useEffect(() => {
        getCarts()
    }, [])

    useEffect(() => {
        if (!show) {
            setSaved(false)
        }
    }, [show]);

    const getCarts = async () => {
        const carts = await CartService.getCarts(userLoged._id)
        setCarts(carts)
    }
    const onChangeCart = (e) => {
        const cart = carts.find(cart => cart._id === e.target.value)
        setCart(cart)
    }

    const handleConfirm = async () => {
        const resp = await CartService.addProduct(cart._id, product._id, product.quantity, crossReference, product.type);
        /* const resp = await CartService.addProduct(cart._id, productId, cantidadToAdd ?? 1, croosReference); */
        if (resp._id) {
            setSaved(true);
        }
        console.log({ resp });
    }

    const navigate = useNavigate();

    const gotToCart = () => {
        navigate(`/carrito/${cart._id}`)
    }

    const createCart = async () => {
        const cart = await CartService.create({
            name: "Nuevo carrito de compras",
            user_id: userLoged._id
        })

        setCarts([...carts, cart])
    }



    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Agregar producto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    saved ?
                        <p className='text-center alert alert-success mb-0 ' style={{ fontSize: 20 }}>Producto agregado correctamente</p>
                        :
                        <>
                            <label htmlFor="cart">
                                Seleccione un carrito de compras al que desea agregar el producto <b>{product?.name}</b>
                            </label>

                            {
                                carts.length > 0 ?
                                    <select name="cart" id="cart" className='form-control mt-2' onChange={onChangeCart}>
                                        <option value="">Seleccione</option>
                                        {carts.map(cart => (<option value={cart._id}>{cart.name}</option>))}
                                    </select>
                                    :

                                    <div className='text-center mt-3'>
                                        <p className='mb-0'>Aun no hay carritos</p>
                                        <p className='text-primary' role='button' onClick={createCart}>Agregue uno</p>
                                    </div>


                            }
                        </>
                }

            </Modal.Body >
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {saved ? 'Cerrar' : 'Cancelar'}
                </Button>
                {
                    saved ?
                        <Button variant="primary" onClick={gotToCart}>Ver carrito</Button>
                        :
                        <Button variant="primary" onClick={handleConfirm}>Agregar</Button>
                }
            </Modal.Footer>
        </Modal >
    )
}
