import React, { useEffect, useState } from 'react'
import { NavLink, redirect, useNavigate, useParams } from 'react-router-dom'
import imageBg from '../assets/passwordConfirmBg.png'
import UserService from '../services/UserService'
import logo from '../assets/trulift-logo.svg'

export default function PasswordRecoveryPage(props) {
    let { token } = useParams();
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    

    const [errors, setErrors] = useState('');
    const [submited, setSubmited] = useState(false);

    const handleChangeInput = (event) => {
        const { name, value } = event.target
        if (name === 'password') {
            setPassword(value)
        }else if (name === 'passwordConfirm') {
            setPasswordConfirm(value)
        }
    }

    useEffect(() => {
        const getUser = async () => {
            const resp = await UserService.getUserByPasswordRecoveryToken(token)
            if (resp.error) {
                navigate('/', {replace: true })
                return;
            }
            setEmail(resp.email)
        }
        getUser();
    }, [token, navigate])
    

    const confirmPassword = async () => {
        setErrors('');
        console.log({
            email,
            password,
            passwordConfirm
        });
        if(!password){
            setErrors('Debes ingresar una contraseña')
            return;
        } else if(password !== passwordConfirm){
            setErrors('Las contraseñas no coinciden')
            return;
        } else {
            const resp = await UserService.passwordRecoveryCofirm({ token, password })
            if (resp.error) {
                setErrors(resp.error)
                return;
            }
            setSubmited(true);
        }
    }

    return (
        <div>
            <div className='row mx-0'>
                <div className="col-md-5 col-lg-6 d-flex align-items-center justify-content-center py-5 py-md-0">
                    <div className='px-5 px-md-2 px-lg-5' style={{ maxWidth: '520px' }}>
                        <NavLink to='/'><img src={logo} alt="Trulift logo" className='img-fluid px-4 px-md-0 pb-2 pb-md-0' /></NavLink>
                        {
                            submited ?
                                <div>
                                    <p>Contraseña restablecida. Ahora puedes usar tu nueva contraseña para iniciar sesión.</p>
                                    <NavLink to="/" className='btn btn-outline-primary mb-4'>Iniciar sesión</NavLink>
                                </div>
                                :
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="">Correo electrónico</label>
                                        <input type="text" name="email" value={email} className='form-control' placeholder='Escribe tu correo electrónico' disabled />
                                    </div>
                                    <div className={`form-group ${errors ? "mb-0" : ""}`}>
                                        <label htmlFor="">Nueva contraseña</label>
                                        <input type="password" name="password" onChange={handleChangeInput} className='form-control' placeholder='Escribe tu nueva contraseña' />
                                    </div>
                                    {errors && <p className='text-danger'>{errors}</p>}
                                    <div className="form-group">
                                        <label htmlFor="">Confirmar nueva contraseña</label>
                                        <input type="password" name="passwordConfirm" onChange={handleChangeInput} className='form-control' placeholder='Escribe tu nueva contraseña' />
                                    </div>

                                    <button className='btn btn-primary py-2 w-100 mb-4 mt-2' onClick={confirmPassword}>Cambiar</button>
                                </div>
                        }
                    </div>
                </div>
                <div className="col-md-7 col-lg-6 d-none d-md-flex align-items-center" style={{ height: '100vh' }}>
                    <div>
                        <img src={imageBg} alt="login-bg" className='img-fluid ' />
                    </div>
                </div>

            </div>
        </div>
    )
}
