import { deleteAuth, getAuth, postAuth, putAuth } from '../utils/request';

class CartService {
    async create(payload) {
        return await postAuth(`carts`, payload);
    }

    async getOne(cartId) {
        return await getAuth(`carts/${cartId}`);
    }

    async getCarts(userId) {
        return await getAuth(`user/${userId}/carts`);
    }

    async update(cartId, payload) {
        return await putAuth(`carts/${cartId}`, payload);
    }

    async addProduct(cartId, productId, quantity = 1, croosReference = null, type = 'product') {
        return await postAuth(`carts/${cartId}/product/${productId}`, {
            quantity,
            croosReference,
            type,
        });
    }

    async massiveAddProduct(cartId, products) {
        return await postAuth(`carts/${cartId}/massive`, { products });
    }

    async deleteOne(cartId) {
        return await deleteAuth(`carts/${cartId}`);
    }

    async updateProductCartQuantity(cartProductId, quantity) {
        return await postAuth(`cartProduct/${cartProductId}`, { quantity });
    }

    async removeCartProduct(cartProductId) {
        return await deleteAuth(`cartProduct/${cartProductId}`);
    }

    async makeOrder(cartId, delivery_method, address, purchaseOrder) {
        return await postAuth(`carts/${cartId}/order`, { delivery_method, address, purchaseOrder });
    }

    async updateProductQuantity(cartId, productId, quantity) {
        return await getAuth(`carts/${cartId}/product/${productId}"`, { quantity });
    }

    async quotation(cartId, products) {
        // return await getAuth(`carts/${cartId}/quotation`)

        // return await getAuth(`carts/quotation`);
        return await getAuth(`carts/${cartId}/quotation`);
    }
}

export default new CartService();
