import moment from 'moment'
import React, { useEffect, useState } from 'react'

import UserService from '../../services/UserService'
import LayoutAdminComponent from '../../components/LayoutAdminComponent'
import ProductService from '../../services/ProductService'
import { NavLink } from 'react-bootstrap'
import { loadImagePlaceholder } from '../../utils'
import imgPlaceholder from '../../assets/placeholder.gif'
export default function RankingproductsPage() {
    const [products, setProducts] = useState([])

    useEffect(() => {
        const getReport = async () => {
            const resp = await ProductService.getRanking()
            console.log(resp);
            setProducts(resp)
        }
        getReport()
    }, [])


    return (
        <LayoutAdminComponent>

            <div className='d-flex justify-content-between'>
                <p className='text-primary title mb-4'>Ranking piezas mas vendidas</p>
            </div>

            <div className="card boder-white shadow pt-3 pb-4">
                <div className="card-body">
                    <table className='table table-sm w-100'>
                        <thead>
                            <tr>
                                <th>Imagen</th>
                                <th className='text-start'>Nombre</th>
                                <th>Marca</th>
                                <th>Modelo</th>
                                <th>Precio</th>
                                <th>Ventas</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                products && products.map(product => (
                                    <tr key={product?._id}>
                                        <td role={'button'}>
                                            <img src={product?.image || imgPlaceholder} alt="" className='img-fluid rounded' width={60} onError={loadImagePlaceholder} />
                                        </td>
                                        <td className='text-start'>{product?.name}</td>
                                        <td>{product?.additional_fields?.brand}</td>
                                        <td>{product?.additional_fields?.model}</td>
                                        <td>{product?.price} {product?.currency}</td>
                                        <td>{product?.sells}</td>

                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </LayoutAdminComponent>
    )
}
