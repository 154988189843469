import React, { useEffect, useState } from 'react'
import { userLoged } from "../utils/auth";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import UserService from '../services/UserService';
import Swal from 'sweetalert2';



export default function ValidateFirstLogin() {
    const [showModal, setShowModal] = useState(false)
    const [password, setPassword] = useState('')

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.passwordChange) {
            setShowModal(true)
        }
    }, []);

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const submitChangePassword = async () => {
        const resp = await UserService.changePassword({
            userId: userLoged._id,
            newPassword: password,
        })
        if (resp) {
            localStorage.setItem('user', JSON.stringify({ ...userLoged, passwordChange: false }));
            Swal.fire({
                icon: 'success',
                title: 'Contraseña actualizada',
                text: 'La contraseña ha sido actualizada',
                showConfirmButton: false,
                timer: 1500
            })
            setShowModal(false)
        }
    }

    return (

        <Modal show={showModal}>
            <Modal.Header>
                <p className='mb-0'>Es necesario que actualice su contraseña</p>
            </Modal.Header>

            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="" className='mb-2'>Nueva contraseña</label>
                            <input type="text" name="email" className='form-control' placeholder='Escribe tu contraseña' onChange={handlePassword} />
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={submitChangePassword}>Guardar</Button>
            </Modal.Footer>
        </Modal>
    )
}
