import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import LayoutAdminComponent from '../../components/LayoutAdminComponent';
import UserService from '../../services/UserService';
import { approvalStatus } from '../../constants';
import { ModalCreateUser } from '../../components/ModalCreateUser';
import { AscButton, DescButton } from '../../components/SortButton';
import _ from 'lodash';
import moment from 'moment';

const ROLES = {
    client: 'Cliente',
    admin: 'Administrador',
    bodega: 'Bodega',
    contabilidad: 'Contabilidad',
};
export default function UsersPage() {
    const [users, setUsers] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [asc, setAsc] = useState(true);
    const [desc, setDesc] = useState(false);

    const getUsers = useCallback(async (searchValue = '') => {
        const users = await UserService.users(searchValue);
        setUsers(_.orderBy(users, (user) => new Date(user.createdAt), ['desc']));
    }, []);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    const search = () => {
        getUsers(searchValue);
    };

    const handleHideModal = () => {
        setShowModal(false);
    };

    const handleSort = () => {
        if (asc) {
            setUsers(_.orderBy(users, (user) => new Date(user.createdAt), ['asc']));
            setDesc((desc) => !desc);
            setAsc((asc) => !asc);
        } else {
            setUsers(_.orderBy(users, (user) => new Date(user.createdAt), ['desc']));
            setAsc((asc) => !asc);
            setDesc((desc) => !desc);
        }
    };

    return (
        <>
            <ModalCreateUser show={showModal} handleClose={handleHideModal} />
            <LayoutAdminComponent>
                <p className="text-primary title mb-4">Usuarios</p>
                <div className="d-flex justify-content-end">
                    <div>
                        <AscButton label="Ordenar más nuevo" asc={asc} onClick={handleSort} />
                        <DescButton label="Ordenar más viejo" desc={desc} onClick={handleSort} />
                    </div>
                    <div className="col-lg-2 mb-2 pe-1 ps-1">
                        <button
                            className="btn btn-primary w-100"
                            onClick={() => setShowModal(true)}
                        >
                            Crear usuario
                        </button>
                    </div>
                    <div className="col-lg-4  mb-2 pe-1 ps-1">
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Buscar"
                                aria-label="Buscar"
                                aria-describedby="basic-addon2"
                                onChange={(e) => setSearchValue(e.target.value)}
                                onKeyUp={(e) => e.key === 'Enter' && search()}
                            />
                            <div className="input-group-append">
                                <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={search}
                                >
                                    Buscar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card boder-white shadow pt-3 pb-4">
                    <div className="card-body">
                        <table className="w-100">
                            <thead>
                                <tr>
                                    <th className="text-start">Nombre completo</th>
                                    <th className="text-start">Correo electrónico</th>
                                    <th className="text-start">Número celular</th>
                                    <th className="text-start">Identificación</th>
                                    <th className="text-start">Actividad económica</th>
                                    <th className="text-start">Technicians People</th>
                                    <th>Rol</th>
                                    <th>Fecha de creación</th>
                                    <th>Status</th>
                                    <th>Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users &&
                                    users.map((user) => (
                                        <tr key={user._id}>
                                            <td className="text-start">
                                                {user.firstName} {user.lastName}
                                            </td>
                                            <td className="text-start">{user.email}</td>
                                            <td className="text-start">{user?.phone}</td>
                                            <td className="text-start">{user?.identification}</td>
                                            <td className="text-start">
                                                {user?.actividadEconomica}
                                            </td>
                                            <td className="text-start">
                                                {user?.techniciansPeople}
                                            </td>
                                            <td>{ROLES[user?.role]}</td>
                                            <td>
                                                {moment(user?.createdAt).format(
                                                    'DD/MM/YYYY HH:mm a'
                                                )}
                                            </td>
                                            <td>
                                                {user?.approvalStatus ? (
                                                    <span
                                                        className={`badge bg-${
                                                            approvalStatus[user?.approvalStatus]
                                                                ?.classColor
                                                        }`}
                                                    >
                                                        {
                                                            approvalStatus[user?.approvalStatus]
                                                                ?.title
                                                        }
                                                    </span>
                                                ) : (
                                                    <span className="badge bg-secondary">
                                                        No aprobado
                                                    </span>
                                                )}
                                            </td>
                                            <td>
                                                <NavLink
                                                    className="d-block"
                                                    to={`/user/${user._id}`}
                                                >
                                                    Ver
                                                </NavLink>
                                            </td>
                                        </tr>
                                    ))}
                                {(!users || users.length === 0) && (
                                    <tr>
                                        <td colSpan="8" className="text-center">
                                            Sin resultados
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </LayoutAdminComponent>
        </>
    );
}
