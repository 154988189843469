import React from 'react'
import Header from './Header'
import ValidateFirstLogin from './ValidateFirstLogin'

export default function LayoutComponent({ children }) {
  return (
    <>
    <ValidateFirstLogin/>
    <Header/>
    {children}
    </>
  )
}
