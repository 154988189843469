import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/trulift-logo.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem } from '@mui/material';

export default function Header() {
    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.href = '/login';
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (route) => {
        if (route) {
            navigate(route);
        }
        setAnchorEl(null);
    };
    const [user, setUser] = React.useState({});

    const navigate = useNavigate();

    React.useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));

        if (user) {
            setUser(user);
        }
    }, []);

    return (
        <>
            {/* Header */}
            <div className="row py-2 px-md-5 px-2 mx-0">
                <div className="d-flex">
                    <div className="col-3 col-md-9 d-flex align-items-center">
                        <a href="/">
                            <img
                                src={logo}
                                alt="Trulift logo"
                                className="img-fluid"
                                style={{ width: '100px' }}
                            />
                        </a>
                    </div>
                    <div>
                        <div className="d-flex align-items-center gap-1" onClick={handleClick}>
                            {' '}
                            <AccountCircleIcon
                                sx={{
                                    width: '40px',
                                    height: '40px',
                                    color: '#ef6000',
                                }}
                            />
                            <h4
                                style={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                }}
                            >
                                {' '}
                                Hola, {user?.firstName}{' '}
                            </h4>
                        </div>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={() => handleClose(null)}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => handleClose('/cuenta')}>Mi cuenta</MenuItem>
                            <MenuItem onClick={() => handleClose('/mis-pedidos-procesados')}>
                                Mis pedidos procesados
                            </MenuItem>
                            <MenuItem onClick={() => handleClose('/mis-pedidos-pendientes')}>
                                Mis pedidos pendientes
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>
            {/* Menu */}
            <div className="header-menu bg-primary py-3 no-print">
                <div className="row mx-0">
                    <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                        <ul className="w-100">
                            <li className="menu-item">
                                <NavLink to="/partes">Partes</NavLink>
                            </li>
                            <li className="menu-item">
                                <NavLink to="/tienda">Tienda</NavLink>
                            </li>
                            <li className="menu-item">
                                <NavLink to="/soporte">Soporte</NavLink>
                            </li>
                            <li className="menu-item" role="button" onClick={logout}>
                                <span>Salir</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
