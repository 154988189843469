import { get, getAuth, postAuth, putAuth } from '../utils/request';

class UserService {
    constructor() {
        this.URI = `${process.env.REACT_APP_API_URL}/api`;
    }
    /**
     * @deprecated use post or postAuth from utils/request instead
     */
    async post(path, payload, headers = {}) {
        const response = await fetch(`${this.URI}/${path}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: JSON.stringify(payload),
        });
        return await response.json();
    }

    /**
     * @deprecated use put or putAuth from utils/request instead
     */
    async put(path, payload, headers = {}) {
        const response = await fetch(`${this.URI}/${path}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: JSON.stringify(payload),
        });
        return await response.json();
    }

    /**
     * @deprecated use get or getAuth from utils/request instead
     */
    async get(path, payload, headers = {}) {
        const response = await fetch(`${this.URI}/${path}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: JSON.stringify(payload),
        });
        return await response.json();
    }

    async login(payload) {
        const resp = await this.post('user/login', payload);
        return resp;
    }

    async users(searchValue = '') {
        const resp = await get('user', { searchValue });
        return resp;
    }

    async getUser(userId) {
        const resp = await this.get('user/' + userId);
        return resp;
    }

    async changePassword(payload) {
        const resp = await this.post('user/change-password', payload);
        return resp;
    }

    async update(userId, payload) {
        const resp = await this.put('user/' + userId, payload);
        return resp;
    }

    async updateAddress(userId, payload) {
        const resp = await this.put('user/' + userId + '/address', payload);
        return resp;
    }

    async sendRecovery(payload) {
        try {
            const resp = await this.post('user/send-recovery', payload);
            return resp;
        } catch (err) {
            console.log({ err });
            return {
                error: 'Ocurrio un error al intentar enviar el correo de recuperación de contraseña. Intente nuevamente.',
            };
        }
    }

    async getUserByPasswordRecoveryToken(token) {
        try {
            const resp = await this.get('user/recovery-token/' + token);
            return resp;
        } catch (err) {
            console.log({ err });
            return {
                error: 'Ocurrio un error al intentar obtener el usuario. Intente nuevamente.',
            };
        }
    }

    async passwordRecoveryCofirm(payload) {
        try {
            const resp = await this.post('user/recovery-confirm', payload);
            return resp;
        } catch (err) {
            console.log({ err });
            return {
                error: 'Ocurrio un error al intentar cambiar la contraseña. Intente nuevamente.',
            };
        }
    }

    async requestUser(payload, address) {
        try {
            const resp = await this.post('user/request-user', payload, address);
            return resp;
        } catch (err) {
            console.log({ err });
            return {
                error: 'Ocurrio un error al intentar enviar la solicitud de usuario. Intente nuevamente.',
            };
        }
    }

    async vincularSiigo(payload) {
        console.log({ payload });
        try {
            const resp = await this.post('user/vincular-siigo', payload);
            return resp;
        } catch (err) {
            console.log({ err });
            return {
                error: 'Ocurrio un error al intentar vincular el usuario con Siigo. Intente nuevamente.',
            };
        }
    }

    async reject(userId) {
        try {
            const resp = await postAuth('user/' + userId + '/reject');
            return resp;
        } catch (err) {
            console.log({ err });
            return {
                error: 'Ocurrio un error al intentar rechazar el usuario. Intente nuevamente.',
            };
        }
    }

    async approve(userId) {
        try {
            const resp = await postAuth('user/' + userId + '/approve');
            return resp;
        } catch (err) {
            console.log({ err });
            return {
                error: 'Ocurrio un error al intentar rechazar el usuario. Intente nuevamente.',
            };
        }
    }

    async sendAccess(userId, payload) {
        try {
            const resp = await postAuth(`user/${userId}/send-access`, payload);
            return resp;
        } catch (err) {
            console.log({ err });
            return {
                error: 'Ocurrio un error al intentar enviar el correo de acceso. Intente nuevamente.',
            };
        }
    }

    async getReport() {
        try {
            const resp = await getAuth('user/report');
            return resp;
        } catch (err) {
            console.log({ err });
            return {
                error: 'Ocurrio un error al intentar obtener el reporte. Intente nuevamente.',
            };
        }
    }

    async updateAddresses(userId, addressId, payload) {
        try {
            const resp = await putAuth(`user/addresses/${userId}/${addressId}`, payload);
            return resp;
        } catch (error) {
            console.log({ error });
            return {
                error: 'Ocurrio un error al intentar actualizar la dirección. Intente nuevamente.',
            };
        }
    }
}

export default new UserService();
