import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import LayoutAdminComponent from '../../components/LayoutAdminComponent';
import OrderService from '../../services/OrderService';
import { userLoged } from '../../utils/auth';
import { formatCurrency } from '../../utils/format';
import { SelectCountry } from '../../components/SelectCountry';
import { SelectState } from '../../components/SelectState';
import { SelectCity } from '../../components/SelectCity';

export default function BodegaOrderPage() {
    const [order, setOrder] = useState({
        products: [],
    });
    const [isPedidosProcesados, setIsPedidosProcesados] = useState(false);
    const [user, setUser] = useState({});

    const [address, setAddress] = useState({});

    const handleAddress = (e) => {
        setAddress({
            ...address,
            [e.target.name]: e.target.value,
        });
    };
    const params = useParams();

    useEffect(() => {
        const getOrder = async () => {
            const order = await OrderService.getOrder(params.orderId);
            setOrder(order);
            const user = order?.user;
            setUser(user);
            console.log(user);
            if (order?.address?.address === '') {
                setAddress({
                    address: user?.address?.address || '',
                    country:
                        user?.address?.city?.country_code ||
                        user?.address?.country ||
                        (user?.addresses?.length && user?.addresses[0]?.city?.country_code) ||
                        (user?.addresses?.length && user?.addresses[0]?.country),
                    state:
                        user?.address?.city?.state_code ||
                        user?.address?.state ||
                        (user?.addresses?.length && user?.addresses[0]?.city?.state_code) ||
                        (user?.addresses?.length && user?.addresses[0]?.state),
                    city:
                        user?.address?.city?.city_code ||
                        user?.address?.city ||
                        (user?.addresses?.length && user?.addresses[0]?.city?.city_code) ||
                        (user?.addresses?.length && user?.addresses[0]?.city),
                    details: user?.address?.details || '',
                });
            } else {
                setAddress({
                    address: user?.address?.address || '',
                    country: user?.address?.city?.country_code || order?.address?.country || '',
                    state: user?.address?.city?.state_code,
                    city: user?.address?.city?.city_code || order?.address?.city || '',
                    details: user?.address?.details || '',
                });
            }
        };
        getOrder();
        setIsPedidosProcesados(localStorage.getItem('isPedidosProcesados') === 'true');
    }, [params]);

    const getOrder = async () => {
        const order = await OrderService.getOrder(params.orderId);
        setOrder(order);
    };

    const setCompleted = () => {
        Swal.fire(
            'Marcar como completado',
            '¿Está seguro de marcar esta orden como completada?',
            'question'
        ).then(async (result) => {
            if (result.isConfirmed) {
                if (
                    address.country !== '' ||
                    address.state !== '' ||
                    address.city !== '' ||
                    address.address !== '' ||
                    address.details !== ''
                ) {
                    await OrderService.updateAddress(params.orderId, address);
                }
                const resp = await OrderService.setCompleted(params.orderId);
                if (resp._id) {
                    getOrder();
                    Swal.fire('Orden completada', 'La orden ha sido completada', 'success').then(
                        () => {
                            window.location.href = '/pedidos-pendientes';
                        }
                    );
                } else {
                    Swal.fire('Error', 'La orden no se pudo completar.' + resp.error, 'error');
                }
            }
        });
    };

    const isShipping = order.delivery_method === 'dhl';

    const getInvoice = async () => {
        window.open(
            `https://api.truliftparts.com/api/invoice/${order.invoice_number._id}/pdf`,
            '_blank'
        );
    };

    console.log(address);

    return (
        <LayoutAdminComponent>
            <div className="pb-5">
                <div className="px-md-5 px-2">
                    <div
                        className="d-flex gap-2 direction-row
                        justify-content-between align-items-center
                    "
                    >
                        <div>
                            <p className="text-primary title mb-4">
                                {isShipping ? (
                                    <>Para envío por transportadora</>
                                ) : (
                                    <>Para retiro en bodega</>
                                )}
                            </p>
                            <button
                                onClick={() => window.history.back()}
                                className="btn btn-outline-primary btn-sm"
                                style={{ height: 'fit-content' }}
                            >
                                Volver
                            </button>
                        </div>
                        {isPedidosProcesados && (
                            <button
                                onClick={getInvoice}
                                className="btn btn-primary btn-sm"
                                style={{ height: 'fit-content' }}
                            >
                                Imprimir Factura
                            </button>
                        )}
                    </div>
                    <div className="bg-primary rounded px-3 py-1 my-4">
                        <p className="text-white mb-0">Acerca de la compañia</p>
                    </div>

                    <div className="row w-100 ">
                        <div className="col-lg-4 mb-3">
                            <label htmlFor="">Nombre completo</label>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                value={order.user?.firstName}
                                disabled
                            />
                        </div>
                        <div className="col-lg-4 mb-3">
                            <label htmlFor="">Correo electrónico</label>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                value={order.user?.email}
                                disabled
                            />
                        </div>
                        <div className="col-lg-4 mb-3">
                            <label htmlFor="">Número de teléfono</label>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                value={order.user?.phone}
                                disabled
                            />
                        </div>
                    </div>

                    <div className="bg-primary rounded px-3 py-1 my-4">
                        <p className="text-white mb-0">Dirección de entrega</p>
                    </div>

                    <div className="row w-100 ">
                        <div className="col-lg-4 mb-3">
                            <SelectCountry
                                handleChange={handleAddress}
                                defaultValue={address.country}
                                className="form-control form-control-sm"
                                disabled={isPedidosProcesados}
                            />
                        </div>
                        <div className="col-lg-4 mb-3">
                            <SelectState
                                handleChange={handleAddress}
                                country={address.country}
                                defaultValue={address.state}
                                className="form-control form-control-sm"
                                disabled={isPedidosProcesados}
                            />
                        </div>
                        <div className="col-lg-4 mb-3">
                            <SelectCity
                                handleChange={handleAddress}
                                country={address.country}
                                state={address.state}
                                defaultValue={address.city}
                                className="form-control form-control-sm"
                                disabled={isPedidosProcesados}
                            />
                        </div>
                    </div>
                    <div className="row w-100">
                        <div className="col-lg-4 mb-3">
                            <label htmlFor="">Dirección</label>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                name="address"
                                onChange={handleAddress}
                                defaultValue={address?.address}
                                disabled={isPedidosProcesados}
                            />
                        </div>
                        <div className="col-lg-4 mb-3">
                            <label htmlFor="">Casa/Apartamento</label>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                name="details"
                                onChange={handleAddress}
                                defaultValue={order?.address?.details}
                                disabled={isPedidosProcesados}
                            />
                        </div>
                    </div>

                    <div className="bg-primary rounded px-3 py-1 my-4">
                        <p className="text-white mb-0">Información del pedido</p>
                    </div>

                    <div className="row w-100">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body bg-white">
                                    <table className="table table-hover table-sm">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nombre producto</th>
                                                <th scope="col">Cantidad</th>
                                                <th scope="col">Valor unit</th>
                                                <th scope="col">Valor total</th>
                                                <th scope="col">Referencia cruzada</th>
                                                <th scope="col">Referencia interna</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {order.products.length === 0 && (
                                                <tr>
                                                    <td colSpan="5" className="text-center">
                                                        No hay productos
                                                    </td>
                                                </tr>
                                            )}
                                            {order.products &&
                                                order.products.map((product) => (
                                                    <tr key={product._id}>
                                                        <td>{product.name}</td>
                                                        <td>{product.quantity}</td>
                                                        <td>{formatCurrency(product.price)}</td>
                                                        <td>
                                                            {formatCurrency(
                                                                product.price * product.quantity
                                                            )}
                                                        </td>
                                                        <td>{product.crossReference}</td>
                                                        <td>{product.internalReference}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div
                                style={{ backgroundColor: '#E0E0E0' }}
                                className="mt-3 py-2 px-3 rounded d-flex justify-content-end"
                            >
                                <span>
                                    Valor Total:{' '}
                                    <span className="text-primary">
                                        <b>{formatCurrency(order.total)}</b>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="bg-primary rounded px-3 py-1 my-4">
                        <p className="text-white mb-0">Información del paquete</p>
                    </div>

                    <div className="row w-100">
                        <div className="col-6 col-lg-3 col-xl-2">
                            <label htmlFor="">Alto</label>
                            <input
                                type="text"
                                className="form-control form-control-sm me-3"
                                value={order?.height ? `${order?.height} cm` : ''}
                                disabled
                            />
                        </div>
                        <div className="col-6 col-lg-3 col-xl-2">
                            <label htmlFor="">Largo</label>
                            <input
                                type="text"
                                className="form-control form-control-sm me-3"
                                value={order?.length ? `${order?.length} cm` : ''}
                                disabled
                            />
                        </div>
                        <div className="col-6 col-lg-3 col-xl-2">
                            <label htmlFor="">Ancho</label>
                            <input
                                type="text"
                                className="form-control form-control-sm me-3"
                                value={order?.width ? `${order?.width} cm` : ''}
                                disabled
                            />
                        </div>
                        <div className="col-6 col-lg-3 col-xl-2">
                            <label htmlFor="">Peso</label>
                            <input
                                type="text"
                                className="form-control form-control-sm me-3"
                                value={order?.weight ? `${order?.weight} kg` : ''}
                                disabled
                            />
                        </div>
                    </div>

                    <div className="row w-100">
                        <div className="col-12">
                            <hr />
                            {order.status !== 'completed' && (
                                <div className="d-flex justify-content-end flex-row">
                                    {userLoged.role === 'bodega' &&
                                        isShipping &&
                                        order.transportadora && (
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={setCompleted}
                                            >
                                                Marcar como procesado
                                            </button>
                                        )}
                                    {userLoged.role === 'bodega' && !isShipping && (
                                        <button
                                            className="btn btn-primary btn-sm"
                                            onClick={setCompleted}
                                        >
                                            Marcar como procesado
                                        </button>
                                    )}
                                    {userLoged.role === 'bodega' &&
                                        isShipping &&
                                        order?.status !== 'processed' && (
                                            <NavLink
                                                to={`/bodega/pedido/${order._id}/envio`}
                                                className="btn btn-primary btn-sm ms-3"
                                            >
                                                Añadir información del envío
                                            </NavLink>
                                        )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </LayoutAdminComponent>
    );
}
