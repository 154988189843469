import { get, getAuth, post } from '../utils/request'

class ProductService {

    async getAllProducts(reference = '') {
        return await get('product/all');
    }
    async getProducts(reference = '', quantity, page) {
        return await getAuth('product', { reference, quantity, page });
    }

    async getProduct(productId) {
        return await get(`product/${productId}`);
    }

    async getRelated(productId) {
        return await get(`product/${productId}/similar`);
    }

    async updateImage(productId, payload) {
        return await post(`product/${productId}/img`, payload);
    }

    async saveRefCruzadas(productId, payload) {
        return await post(`product/${productId}/cross-references`, payload);
    }

    async getRanking() {
        return await get(`product/ranking`);
    }

    async historialBusqueda(page, limit) {

        return await get(`product/historial-busqueda`, { page, limit });
    }
}

export default new ProductService();