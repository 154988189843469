import React from 'react';
import LayoutAdminComponent from '../../components/LayoutAdminComponent';
import { SelectCountry } from '../../components/SelectCountry';
import { SelectState } from '../../components/SelectState';
import { SelectCity } from '../../components/SelectCity';
import { formatCurrency } from '../../utils/format';
import { Navigate } from 'react-router-dom';

import OrderService from '../../services/OrderService';
import Swal from 'sweetalert2';
export const BackOrderDetails = () => {
    const [order, setOrder] = React.useState([]);

    React.useEffect(() => {
        const savedOrder = JSON.parse(localStorage.getItem('order'));
        setOrder(savedOrder);
    }, []);

    const handleAccounting = async () => {
        const resp = await OrderService.sendBackOrderAccounting(order._id);
        if (resp.status === 'pending') {
            Swal.fire({
                icon: 'success',
                title: 'Orden enviada a contabilidad',
                showConfirmButton: false,
                timer: 1500,
            }).then(() => {
                window.location.href = '/backorders';
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Algo salió mal!',
            });
        }
    };

    return (
        <LayoutAdminComponent>
            <div className="px-md-5 px-2">
                <div
                    className="d-flex gap-2
                    "
                    style={{ flexDirection: 'column' }}
                >
                    <p className="text-primary title mb-4">
                        Detalles de la orden #{order.order_number}
                    </p>
                    <div>
                        <button
                            type="button"
                            className="btn btn-outline-primary btn-sm"
                            onClick={() => window.history.back()}
                        >
                            Volver
                        </button>
                    </div>
                </div>
                <div className="bg-primary rounded px-3 py-1 my-4">
                    <p className="text-white mb-0">Acerca de la compañia</p>
                </div>
                <div className="row w-100 ">
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="">Nombre completo</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            value={order.user?.firstName + ' ' + order.user?.lastName}
                            disabled
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="">Correo electrónico</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            value={order.user?.email}
                            disabled
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="">Número de teléfono</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            value={order.user?.phone}
                            disabled
                        />
                    </div>
                </div>
                <div className="bg-primary rounded px-3 py-1 my-4">
                    <p className="text-white mb-0">Dirección de entrega</p>
                </div>
                <div className="row w-100 ">
                    <div className="col-lg-4 mb-3">
                        <SelectCountry
                            defaultValue={order.user?.address?.city.country_code}
                            className="form-control form-control-sm"
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <SelectState
                            country={order.user?.address?.country}
                            defaultValue={order.user?.address?.city.state_code}
                            className="form-control form-control-sm"
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <SelectCity
                            country={order.user?.address?.country}
                            state={order.user?.address?.state}
                            defaultValue={order.user?.address?.city.city_code}
                            className="form-control form-control-sm"
                        />
                    </div>
                </div>
                <div className="row w-100">
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="">Dirección</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            name="address"
                            defaultValue={order.user?.address?.address}
                            disabled
                        />
                    </div>
                </div>
                <div className="bg-primary rounded px-3 py-1 my-4">
                    <p className="text-white mb-0">Información del pedido</p>
                </div>
                <div className="row w-100">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body bg-white">
                                <table className="table table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">Nombre producto</th>
                                            <th scope="col">Cantidad</th>
                                            <th scope="col">Valor unit</th>
                                            <th scope="col">Valor total</th>
                                            <th scope="col">Referencia cruzada</th>
                                            <th scope="col">Referencia interna</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {order.products?.length === 0 && (
                                            <tr>
                                                <td colSpan="5" className="text-center">
                                                    No hay productos
                                                </td>
                                            </tr>
                                        )}
                                        {order.products &&
                                            order.products.map((product) => (
                                                <tr key={product._id}>
                                                    <td>{product.name}</td>
                                                    <td>{product.quantity}</td>
                                                    <td>{formatCurrency(product.price)}</td>
                                                    <td>
                                                        {formatCurrency(
                                                            product.price * product.quantity
                                                        )}
                                                    </td>
                                                    <td>{product.crossReference}</td>
                                                    <td>{product.internalReference}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div
                            style={{ backgroundColor: '#E0E0E0' }}
                            className="mt-3 py-2 px-3 rounded d-flex justify-content-end"
                        >
                            <span>
                                Valor Total:{' '}
                                <span className="text-primary">
                                    <b>{formatCurrency(order.total)}</b>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="row w-100">
                        <div className="col-12">
                            <hr />
                            <div className="d-flex justify-content-end flex-row">
                                <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={handleAccounting}
                                >
                                    Pasar a contabilidad
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutAdminComponent>
    );
};
