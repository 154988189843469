import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import imageBg from '../assets/passwordRecoveryBg.png'
import UserService from '../services/UserService'

export default function PasswordRecoveryPage() {
    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState('');
    const [submited, setSubmited] = useState(false);

    const handleChangeInput = (event) => {
        const { name, value } = event.target
        if (name === 'email') {
            setEmail(value)
        }
    }

    const sendRecovery = async () => {
        setErrors('');
        if (!email) {
            setErrors('El campo correo electrónico es requerido')
            return;

        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            setErrors('Correo electrónico inválido')
            return;
        } else {
            const resp = await UserService.sendRecovery({ email })
            if (resp.error) {
                setErrors("El correo electrónico no existe")
                return;
            }
            setSubmited(true);
        }
    }

    return (
        <div>
            <div className='row mx-0'>
                <div className="col-md-5 col-lg-6 d-flex align-items-center justify-content-center py-5 py-md-0">
                    <div className='px-5 px-md-2 px-lg-5' style={{ maxWidth: '520px' }}>
                        <h1 className='text-primary mb-0 title' style={{ fontWeight: '500' }}>¿Has olvidado tu contraseña?</h1>
                        <p className='text-primary'>No te preocupes, aquí estamos para ayudarte</p>
                        {
                            submited ?
                                <div>
                                    <p>Hemos enviado un mensaje adjunto con un link a tu correo electrónico terminado en <b><i>“{email}"</i></b> Por favor verifica para que puedas continuar con el reestablecimiento de tu contraseña.</p>
                                    <NavLink to="/" className='btn btn-outline-primary mb-4'>Volver</NavLink>
                                </div>
                                :
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="">Correo electrónico</label>
                                        <input type="text" name="email" onChange={handleChangeInput} className='form-control' placeholder='Escribe tu correo electrónico' />
                                    </div>
                                    {errors && <p className='text-danger'>{errors}</p>}

                                    <button className='btn btn-primary w-100 mb-4' onClick={sendRecovery}>Continuar</button>
                                </div>
                        }
                    </div>
                </div>
                <div className="col-md-7 col-lg-6 d-none d-md-flex align-items-center" style={{ height: '100vh' }}>
                    <div>
                        <img src={imageBg} alt="login-bg" className='img-fluid ' />
                    </div>
                </div>

            </div>
        </div>
    )
}
