import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { loadImagePlaceholder } from '../../utils'
import ProductService from '../../services/ProductService'
import LayoutAdminComponent from '../../components/LayoutAdminComponent'

export default function HistoricoBusquedaPage() {
    const [busquedas, setBusquedas] = useState([])
    const [limit, setLimit] = useState(20)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(1)

    useEffect(() => {
        const getReport = async () => {
            const resp = await ProductService.historialBusqueda(page, limit)
            if (resp.docs) {
                console.log(resp);
                setBusquedas(resp.docs)
                setPages(resp.pages)
            }
        }
        getReport()
    }, [])

    useEffect(() => {
        const getReport = async () => {
            const resp = await ProductService.historialBusqueda(page, limit)
            if (resp.docs) {
                console.log(resp);
                setBusquedas(resp.docs)
                setPages(resp.pages)
            }
        }
        getReport()
    }, [page, limit])

    const changeLimit = (e) => {
        setLimit(e.target.value)
        setPage(1)
    }

    return (
        <LayoutAdminComponent>

            <div className='d-flex justify-content-between'>
                <p className='text-primary title mb-4'>Historico de búsquedas</p>
            </div>

            <div className="card boder-white shadow pt-3 pb-4">
                <div className="card-body">
                    <table className='table table-sm w-100'>
                        <thead>
                            <tr>
                                <th className='text-start'>Usuario</th>
                                <th className='text-start'>Búsqueda</th>
                                <th>Fecha</th>
                                <th>Resultados</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                busquedas && busquedas.map(busqueda => (
                                    <tr key={busqueda?._id}>
                                        <td className='text-start'>{[busqueda?.user?.firstName, busqueda?.user?.lastName].filter(item => item).join(' ')}</td>
                                        <td className='text-start'>{busqueda?.search}</td>
                                        <td>{moment(busqueda?.createdAt).format('YYYY-MM-DD hh:mm:ss a')}</td>
                                        <td>
                                            <span className='badge bg-primary'>{busqueda?.results?.length}</span>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    {/* Pagiante controls */}
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <label className='mb-0 me-2'>Mostrar</label>
                            <select className='form-select form-select-sm' value={limit} onChange={changeLimit}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                        <small className='d-flex align-items-center'>Página {page} de {pages}</small>
                        <div className='d-flex'>
                            <button className='btn btn-sm btn-outline-primary me-2' onClick={() => setPage(page - 1)} disabled={page === 1}>Anterior</button>
                            {
                                page > 1 && (
                                    <button className='btn btn-sm btn-outline-primary me-2' onClick={() => setPage(1)}>1</button>
                                )
                            }
                            {
                                page > 2 && (
                                    <button className='btn btn-sm btn-outline-primary me-2' onClick={() => setPage(page - 1)}>{page - 1}</button>
                                )
                            }
                            <button className='btn btn-sm btn-primary me-2' disabled>{page}</button>
                            {
                                page < pages - 1 && (
                                    <button className='btn btn-sm btn-outline-primary me-2' onClick={() => setPage(page + 1)}>{page + 1}</button>
                                )
                            }

                            <button className='btn btn-sm btn-outline-primary' onClick={() => setPage(page + 1)} disabled={busquedas?.length < limit}>Siguiente</button>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutAdminComponent>
    )
}
