import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import LayoutComponent from '../../components/LayoutComponent';
import MontoLabel from '../../components/MontoLabel';
import imgPlaceholder from '../../assets/placeholder.gif';
import CartService from '../../services/CartService';
import ProductService from '../../services/ProductService';
import { formatCurrency } from '../../utils/format';
import Swal from 'sweetalert2';
import { ModalBackOrder } from '../../components/ModalBackOrder';
import OrderService from '../../services/OrderService';
import { CartAddressForm } from '../../components/CartAddressForm';

const taxes = 0.19;

export default function CartPage() {
    const params = useParams();
    const [cart, setCart] = useState({});
    const [initialValue, setInitialValue] = useState(0);
    const [searchReference, setSearchReference] = useState('');
    const [searchProducts, setSearchProducts] = useState([]);
    const [cantidadToAdd, setCantidadToAdd] = useState('');
    const [openBackOrder, setOpenBackOrder] = useState(false);
    const [backOrder, setBackOrder] = useState({});
    const [purchaseOrder, setPurchaseOrder] = useState('');
    const [cartAddress, setCartAddress] = useState({});
    const cartId = params['cartId'];

    const getCart = async (cartId) => {
        const cart = await CartService.getOne(cartId);
        let initialValue = 0;

        if (cart._id) {
            const productos = [];
            for (const index in cart.products) {
                const producto = await getProduct(cart.products[index].product);
                productos.push({ ...cart.products[index], item: { ...producto } });
            }
            setCart({ ...cart, products: productos });
        }
        setInitialValue(initialValue);
    };

    const getBackOrderByUser = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const res = await OrderService.getBackOrder(user._id);
            setBackOrder(res);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const cartId = params['cartId'];
        const user = JSON.parse(localStorage.getItem('user'));
        getCart(cartId);
        getBackOrderByUser();
        setCartAddress(user.addresses[0]);
    }, [params]);

    useEffect(() => {
        const calculeTotal = () => {
            let total = 0;
            for (const index in cart.products) {
                total += cart.products[index].quantity * cart.products[index].item.price;
            }
            setInitialValue(total);
        };
        calculeTotal();
        // calcularDisponiblidad();
    }, [cart]);

    useEffect(() => {
        setSearchProducts([]);
        if (searchReference) {
            const searchProduct = async () => {
                const products = await ProductService.getProducts(searchReference);
                setSearchProducts(products);
            };
            searchProduct();
        }
    }, [searchReference]);

    const getProduct = async (productId) => {
        return await ProductService.getProduct(productId);
    };

    const deleteCart = async () => {
        const cartId = params['cartId'];
        if (backOrder?.length > 0) {
            Swal.fire({
                title: 'Esta canasta esta asociada a un backorder ¿Desea eliminarla?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const backOrder = await OrderService.getBackOrder(cartId);
                    await OrderService.deleteBackOrder(backOrder[0]._id);
                    await CartService.deleteOne(cartId);
                    gotToCarts();
                }
            });
        } else {
            await CartService.deleteOne(cartId);
            gotToCarts();
        }
    };

    const navigate = useNavigate();

    const gotToCarts = () => {
        navigate(`/mis-pedidos-pendientes`);
    };

    const print = () => {
        // window.print();
        const user = JSON.parse(localStorage.getItem('user'));
        const countrySelected = cartAddress.city?.country_code || user?.address?.city?.country_code;
        window.open(`https://api.truliftparts.com/api/carts/${cartId}/quotation/${countrySelected}`, '_blank');
    };

    const changeQuantity = (quantity, index, quantityAviable) => {
        // Validar si el valor es negativo
        let minValue = 1;
        let maxValue = 1000;
        let aviable = parseInt(quantityAviable);
        if (quantity >= minValue && quantity <= maxValue) {
            quantity = quantity;
            const newCart = { ...cart };
            newCart.products[index].quantity = quantity;
            setCart(newCart);
        }
    };

    const updateQuantity = async (index) => {
        const product = cart.products[index];
        await CartService.updateProductCartQuantity(product._id, product.quantity);
    };

    const removeItem = async (index) => {
        const cartProduct = cart.products[index];
        const resp = await CartService.removeCartProduct(cartProduct._id);
        if (resp.deleted) {
            const newCart = { ...cart };
            newCart.products.splice(index, 1);
            setCart(newCart);
        }
    };

    const addToCard = async (productId, croosReference) => {
        console.log(productId);
        Swal.fire({
            title: 'Validando existencia',
            text: 'Espere un momento por favor',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        const resp = await CartService.addProduct(
            cart._id,
            productId,
            cantidadToAdd ?? 1,
            croosReference
        );
        setTimeout(() => {
            Swal.close();
            console.log(resp);
            if (resp._id) {
                getCart(cart._id);
                setSearchReference('');
                setCantidadToAdd('');
            }
        }, 500);
    };

    const makeOrder = async () => {
        const address = cartAddress;
        console.log(address, 'asd');
        Swal.fire({
            title: '¿Desea realizar el pedido?',
            icon: 'warning',
            input: 'select',
            inputOptions: {
                dhl: 'Envio DHL',
                'Recoger presencial': 'Recoger presencial',
            },
            inputPlaceholder: 'Seleccione el método de entrega',
            showCancelButton: true,
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    if (value) {
                        resolve();
                    } else {
                        resolve('Debe seleccionar un método de entrega');
                    }
                });
            },
            cancelButtonColor: '#d33',
            confirmButtonColor: '#4BB543',
            confirmButtonText: 'Si, confirmar',
            cancelButtonText: 'Cancelar',
        })
            .then(async (result) => {
                console.log({ result });
                if (result.isConfirmed) {
                    Swal.fire({
                        title: 'Realizando pedido',
                        text: 'Espere un momento por favor',
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });
                    const resp = await CartService.makeOrder(
                        cart._id,
                        result.value,
                        address,
                        purchaseOrder
                    );
                    Swal.close();
                    if (resp._id) {
                        Swal.fire(
                            'Pedido realizado!',
                            'El pedido se ha realizado correctamente',
                            'success'
                        ).then(() => {
                            gotToCarts();
                        });
                    } else if (resp.stockErrors) {
                        Swal.fire({
                            title: 'Lo sentimos',
                            text: 'Los siguientes productos no tienen suficiente stock',
                            icon: 'error',
                            html: `${resp.stockErrors
                                .map(
                                    (error) => `<p style='margin-bottom:0.5em'>${error.message}</p>`
                                )
                                .join(
                                    ''
                                )}<br><p>Por favor, elimine los productos que no tiene stock o actualice la cantidad</p>`,
                            confirmButtonColor: '#4BB543',
                            confirmButtonText: 'Aceptar',
                        });
                    } else if (resp.error === 'siigo_account_incomplete') {
                        Swal.fire({
                            title: 'Tu cuenta actualmente carece de información necesaria para registar un pedido',
                            html: `Por favor, ponte en contacto con nosotros a través del correo electrónico <b>contacto@trulift.com</b> para que podamos ayudarte a solucionarlo.`,
                            icon: 'warning',
                            confirmButtonColor: '#4BB543',
                            confirmButtonText: 'Aceptar',
                        });
                    } else {
                        Swal.fire({
                            title: 'Lo sentimos',
                            text: 'Ha ocurrido un error al realizar el pedido',
                            icon: 'error',
                            confirmButtonColor: '#4BB543',
                            confirmButtonText: 'Aceptar',
                        });
                    }
                }
            })
            .catch((err) => {
                Swal.fire({
                    title: 'Lo sentimos',
                    text: 'Ha ocurrido un error al realizar el pedido',
                    icon: 'error',
                    confirmButtonColor: '#4BB543',
                    confirmButtonText: 'Aceptar',
                });
            });
    };

    const handleOpenBackOrderModal = () => {
        setOpenBackOrder(!openBackOrder);
    };

    return (
        <>
            <ModalBackOrder
                show={openBackOrder}
                handleClose={handleOpenBackOrderModal}
                cart={cart}
                getBackOrders={getBackOrderByUser}
            />
            <LayoutComponent>
                <div style={{ backgroundColor: '#F2F2F2', minHeight: 'calc(100vh - 130px)' }}>
                    <CartAddressForm
                        cartInfo={cart}
                        cartAddress={setCartAddress}
                        setPurchaseOrder={setPurchaseOrder}
                        purchaseOrder={purchaseOrder}
                    />
                    <div
                        style={{ backgroundColor: '#E0E0E0' }}
                        className="px-5 py-4 d-flex flex-md-row flex-column justify-content-between align-items-start"
                    >
                        <div style={{ minWidth: '60%' }}>
                            <p className="mb-0">
                                <b>Nombre: {cart?.name}</b>
                            </p>
                            <p className="mb-2 no-print">Acá se agregarán todos los productos</p>
                            <div className="row no-print">
                                <div className="col-md-6 col-lg-4 mb-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Referencia"
                                        onChange={(e) => setSearchReference(e.currentTarget.value)}
                                        value={searchReference}
                                    />
                                </div>
                                <div className="col-md-6 col-lg-4 mb-2">
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Cantidad"
                                        value={cantidadToAdd}
                                        onChange={(e) => setCantidadToAdd(e.currentTarget.value)}
                                    />
                                </div>
                                <div className="col-md-6 col-lg-4 mb-2">
                                    <select name="" id="" className="form-control text-primary">
                                        <option value="">Seleccione</option>
                                        <option value="">Florida</option>
                                    </select>
                                </div>
                            </div>
                            {searchProducts && searchProducts.length > 0 && (
                                <div className="resultSearchContainer">
                                    <p className="mb-0 text-end me-2 my-1">
                                        <small role="button" onClick={() => setSearchReference('')}>
                                            X
                                        </small>
                                    </p>
                                    {searchProducts.map((product, index) => (
                                        <div key={index} className="card mb-3 product-card-list">
                                            <div className="card-body bg-white rounded ">
                                                <div className="row">
                                                    <div className="col-md-9 col-lg-12 d-flex align-items-center justify-content-between">
                                                        <img
                                                            src={product.image || imgPlaceholder}
                                                            alt=""
                                                            className="img-fluid w-100"
                                                        />
                                                        <p className="text-primary mb-0">
                                                            <b>{product.name}</b>
                                                        </p>
                                                        <div>
                                                            <p className="text-primary mb-0">
                                                                {[
                                                                    product.additional_fields
                                                                        ?.brand,
                                                                    product.additional_fields
                                                                        ?.model,
                                                                ]
                                                                    .filter((item) => item)
                                                                    .join(' / ')}
                                                            </p>
                                                            <p className="text-muted mb-0">
                                                                {product.code}
                                                            </p>
                                                        </div>
                                                        <button
                                                            className="btn btn-primary btn"
                                                            onClick={() =>
                                                                addToCard(product._id, product.code)
                                                            }
                                                        >
                                                            Agregar +
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            <p className="mb-0 text-success">
                                {cart?.products?.length} articulo
                                {cart?.products?.length === 1 ? '' : 's'}
                            </p>
                        </div>
                        <button
                            className="btn btn-outline-primary btn-sm no-print"
                            onClick={deleteCart}
                        >
                            Borrar canasta
                        </button>
                    </div>
                    <div className="p-4">
                        <div className="row w-100">
                            <div className="col-lg-9 print-w-100">
                                {cart?.products && cart?.products.length ? (
                                    cart?.products.map((product, index) => (
                                        <div key={index} className="card mb-3 product-card-list">
                                            <div className="card-body bg-white rounded ">
                                                <div className="row">
                                                    <div className="col-lg-2 mb-2 d-flex justify-content-center">
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                name=""
                                                                id=""
                                                                className="me-2"
                                                            />
                                                            <img
                                                                src={
                                                                    product.item.image ||
                                                                    imgPlaceholder
                                                                }
                                                                alt=""
                                                                className="img-fluid rounded"
                                                                style={{ maxHeight: '130px' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 mb-2 d-flex align-items-start flex-column justify-content-center">
                                                        <p className="text-primary mb-0">
                                                            {product.item.name}
                                                        </p>
                                                        {product.item.additional_fields && (
                                                            <small className="text-primary">
                                                                {[
                                                                    product.item.additional_fields
                                                                        ?.brand,
                                                                    product.item.additional_fields
                                                                        ?.model,
                                                                ]
                                                                    .filter((item) => item)
                                                                    .join(' / ')}
                                                            </small>
                                                        )}
                                                        <small className="text-muted">
                                                            {product.crossReference}
                                                        </small>
                                                    </div>
                                                    <div className="col-lg-2 mb-2 ">
                                                        <label>Referencia</label>
                                                        <input
                                                            type="text"
                                                            readOnly
                                                            className="form-control form-control-sm mb-2"
                                                            value={product.item.code}
                                                        />

                                                        <label>Bodega</label>
                                                        <select className="form-control form-control-sm">
                                                            <option value="">Bodega 1</option>
                                                            <option value="">Bodega 2</option>
                                                            <option value="">Bodega 3</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-lg-1 mb-2">
                                                        <label>Cant.</label>
                                                        <input
                                                            type="number"
                                                            className="form-control form-control-sm mb-1"
                                                            // value={product.quantity}
                                                            defaultValue={product.quantity}
                                                            onChange={(e) => {
                                                                changeQuantity(
                                                                    e.currentTarget.value,
                                                                    index,
                                                                    product.available_quantity
                                                                );
                                                                setCantidadToAdd(
                                                                    e.currentTarget.value
                                                                );
                                                            }}
                                                            onBlur={() => updateQuantity(index)}
                                                            min="1"
                                                            max="1000"
                                                        />
                                                    </div>
                                                    <div className="col-lg-2 mb-2">
                                                        <label>Precio</label>
                                                        <input
                                                            type="text"
                                                            readOnly
                                                            className="form-control form-control-sm mb-2"
                                                            value={formatCurrency(
                                                                product.item.price,
                                                                product.item.currency
                                                            )}
                                                        />
                                                        <label>Precio Total</label>
                                                        <input
                                                            type="text"
                                                            readOnly
                                                            className="form-control form-control-sm mb-2"
                                                            value={formatCurrency(
                                                                product.item.price *
                                                                    product.quantity,
                                                                product.item.currency
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-lg-2 mb-2 no-print d-flex flex-column justify-content-center">
                                                        <NavLink
                                                            to={`/producto/${
                                                                product.item._id
                                                            }/${product?.item?.name
                                                                ?.toLowerCase()
                                                                .replaceAll(' ', '-')
                                                                .replaceAll('/', '')}/${
                                                                product.crossReference
                                                            }`}
                                                            className="btn btn-sm btn-outline-primary mb-md-1 mb-0 me-3 me-md-0"
                                                        >
                                                            Detalle producto
                                                        </NavLink>
                                                        <button
                                                            className="btn btn-sm btn-outline-danger"
                                                            onClick={() => removeItem(index)}
                                                        >
                                                            Quitar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>
                                        Aun no hay productos en este carrito de compra. Consulte el{' '}
                                        <NavLink to="/partes">Listado de partes</NavLink> para
                                        agregar algunos.
                                    </p>
                                )}
                            </div>
                            <div className="col-lg-3 print-w-100">
                                <div className="card mb-3 product-card-list">
                                    <div className="card-body bg-white rounded ">
                                        <p>
                                            <b>{cart?.name}</b>
                                        </p>
                                        <MontoLabel title="Valor inicial" value={initialValue} />
                                        <MontoLabel
                                            title="Impuestos"
                                            value={initialValue * taxes}
                                        />
                                        <div className="mt-2 mb-4 pb-3">
                                            <b>
                                                <MontoLabel
                                                    title="Valor Total"
                                                    value={initialValue + initialValue * taxes}
                                                />
                                            </b>
                                        </div>

                                        <hr />

                                        <div className="d-flex justify-content-center flex-column px-5 mt-4 no-print">
                                            {!purchaseOrder && (
                                                <p
                                                    style={{
                                                        color: 'red',
                                                        fontSize: '12px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Digite la orden de compra
                                                </p>
                                            )}

                                            {cart?.products?.length ? (
                                                <button
                                                    className="no-print btn btn-success mb-3 py-2"
                                                    onClick={makeOrder}
                                                    disabled={!purchaseOrder}
                                                >
                                                    Hacer pedido
                                                </button>
                                            ) : (
                                                <button
                                                    className="no-print btn btn-success mb-3 py-2"
                                                    disabled
                                                >
                                                    Hacer pedido
                                                </button>
                                            )}
                                            <button
                                                className="no-print btn btn-primary mb-3 py-2"
                                                onClick={print}
                                            >
                                                Imprimir cotización
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutComponent>
        </>
    );
}
