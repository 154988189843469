import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import LayoutAdminComponent from '../../components/LayoutAdminComponent';
import OrderService from '../../services/OrderService';
import { AscButton, DescButton } from '../../components/SortButton';
import { Checkbox } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { cities } from '../../constants';
export default function PedidosPendientesPage() {
    const [orders, setOrders] = useState([]);
    const [asc, setAsc] = useState(true);
    const [desc, setDesc] = useState(false);

    useEffect(() => {
        const getOrders = async () => {
            const response = await OrderService.getPending();
            const sorted = _.orderBy(response, (item) => new Date(item.createdAt), 'desc');
            setOrders(sorted);
        };
        getOrders();
    }, []);

    useEffect(() => {
        localStorage.setItem('isPedidosProcesados', 'false');
    }, []);

    const handleSort = () => {
        if (asc) {
            setOrders(_.orderBy(orders, (item) => new Date(item.createdAt), ['asc']));
            setDesc((desc) => !desc);
            setAsc((asc) => !asc);
        } else {
            setOrders(_.orderBy(orders, (item) => new Date(item.createdAt), ['desc']));
            setAsc((asc) => !asc);
            setDesc((desc) => !desc);
        }
    };

    const handlePrintedStatus = async (orderId, isLabelPrinted, isPackingListPrinted) => {
        const res = await OrderService.changePrintedStatus(
            orderId,
            isLabelPrinted,
            isPackingListPrinted
        );
        const updatedOrders = orders.map((order) => {
            if (order._id === res._id) {
                order.isLabelPrinted = isLabelPrinted;
                order.isPackingListPrinted = isPackingListPrinted;
            }
            return order;
        });
        setOrders(updatedOrders);
    };

    return (
        <LayoutAdminComponent>
            <p className="text-primary title mb-4">Pedidos pendientes</p>
            <div className="mb-3 no-print text-end">
                <button className="btn btn-outline-primary btn-sm" onClick={() => window.print()}>
                    Print
                </button>
            </div>
            <div className="d-flex justify-content-end">
                <div>
                    <AscButton label="Ordenar más nuevo" asc={asc} onClick={handleSort} />
                    <DescButton label="Ordenar más viejo" desc={desc} onClick={handleSort} />
                </div>
            </div>
            <div className="card shadow pt-3 pb-3">
                <div className="card-body">
                    <table className="w-100 table-sm">
                        <thead>
                            <tr>
                                <th>Número de orden</th>
                                <th>Cant.</th>
                                <th className="text-start">Referencias cruzadas</th>
                                <th className="text-start">Referencias internas</th>
                                <th>Bussiness Name</th>
                                <th>Metodo de entrega</th>
                                <th>Fecha de creación</th>
                                <th>Status</th>
                                <th className="no-print">Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(!orders || orders.length === 0) && (
                                <tr>
                                    <td colSpan="6" className="text-center">
                                        No hay pedidos pendientes
                                    </td>
                                </tr>
                            )}
                            {orders &&
                                orders.map((order) => (
                                    <tr key={order._id}>
                                        <td>#{order.order_number}</td>
                                        <td className="pe-0">
                                            {order.products.map((product, index) => (
                                                <>
                                                    {index > 0 ? <hr className="my-0" /> : <></>}
                                                    <p
                                                        className="mb-0 text-nowrap pe-1"
                                                        key={product._id}
                                                    >
                                                        {product.quantity}
                                                    </p>
                                                </>
                                            ))}
                                        </td>
                                        <td className="text-start px-0">
                                            <p>
                                                {order.products.map((product) => (
                                                    <React.Fragment key={product._id}>
                                                        <span style={{ cursor: 'pointer' }}>
                                                            {product.crossReference}{' '}
                                                            {order.products.length > 1 ? ',' : ''}
                                                        </span>
                                                    </React.Fragment>
                                                ))}
                                            </p>
                                        </td>
                                        <td className="text-start ps-0">
                                            {order.products.map((product, index) => (
                                                <>
                                                    <React.Fragment key={product._id}>
                                                        <span style={{ cursor: 'pointer' }}>
                                                            {product.internalReference}{' '}
                                                            {order.products.length > 1 &&
                                                            index < order.products.length - 1
                                                                ? ','
                                                                : ''}
                                                        </span>
                                                    </React.Fragment>
                                                </>
                                            ))}
                                        </td>
                                        <td>
                                            {[order.user?.firstName, order.user?.lastName].join(
                                                ' '
                                            )}
                                        </td>
                                        <td>{order.delivery_method}</td>
                                        <td>
                                            {moment(order.createdAt).format('DD/MM/YYYY HH:mm a')}
                                        </td>
                                        <td>
                                            {order.delivery_method === 'dhl' ? (
                                                <span className={`badge py-1 bg-success`}>
                                                    {order.status === 'processed'
                                                        ? 'Enviado'
                                                        : 'Pendiente de envio'}
                                                </span>
                                            ) : (
                                                <span className={`badge py-1 bg-success`}>
                                                    Retiro en bodega
                                                </span>
                                            )}
                                        </td>
                                        <td className="no-print">
                                            <div className="d-flex align-items-center">
                                                <a
                                                    className="d-block"
                                                    href={`https://api.truliftparts.com/api/order/picklist/${order._id}`}
                                                    // href={`http://localhost:3030/api/order/picklist/${order._id}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    onClick={() =>
                                                        !order?.isPackingListPrinted &&
                                                        handlePrintedStatus(
                                                            order._id,
                                                            order.isLabelPrinted,
                                                            true
                                                        )
                                                    }
                                                >
                                                    Picklist
                                                </a>
                                                <Checkbox
                                                    className="ms-2"
                                                    checked={order?.isPackingListPrinted}
                                                    disabled={order?.isPackingListPrinted}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <a
                                                    className="d-block"
                                                    href={`https://api.truliftparts.com/api/order/label/pdf/${order._id}`}
                                                    // href={`http://localhost:3030/api/order/label/pdf/${order._id}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    onClick={() =>
                                                        !order?.isLabelPrinted &&
                                                        handlePrintedStatus(
                                                            order._id,
                                                            true,
                                                            order.isPackingListPrinted
                                                        )
                                                    }
                                                >
                                                    Label
                                                </a>
                                                <Checkbox
                                                    className="ms-2"
                                                    checked={order?.isLabelPrinted}
                                                    disabled={order?.isLabelPrinted}
                                                />
                                            </div>
                                            <NavLink
                                                className="d-block"
                                                to={`/bodega/pedido/${order._id}`}
                                            >
                                                Despachar
                                            </NavLink>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </LayoutAdminComponent>
    );
}
