import React from 'react';

export const AscButton = ({ asc, onClick, label }) => {
    return (
        <button
            className={
                asc
                    ? 'btn btn-outline-primary mb-2 mx-1 active'
                    : 'btn btn-outline-primary mb-2 mx-1 '
            }
            onClick={onClick}
        >
            {label}
        </button>
    );
};

export const DescButton = ({ desc, onClick, label }) => {
    return (
        <button
            className={
                desc
                    ? 'btn btn-outline-primary mb-2 mx-1 active'
                    : 'btn btn-outline-primary mb-2 mx-1'
            }
            onClick={onClick}
        >
            {label}
        </button>
    );
}