import React from 'react'
import bodegaImg from '../assets/bodega.png'
import montacargas from '../assets/montacargas.png'
import LayoutComponent from '../components/LayoutComponent'
import repuestosBanner from '../assets/repuestos-banner.png'

export default function HomePage() {
    return (
        <LayoutComponent>
            <div>
                <div className="row mx-0" style={{ background: 'linear-gradient(226.48deg, #9CA9CD 23.55%, #5A647E 88.66%)' }}>
                    <div className="col-md-8 col-lg-8 d-flex align-items-center px-0">
                        <img src={montacargas} alt="" className='img-fluid mt-4 mt-md-0' />
                    </div>
                    <div className="col-md-4 col-lg-3 d-flex align-items-center py-5 pt-md-2 pb-md-3">
                        <div className='container'>
                            <h3 className='text-white'>Aquí encuentras los mejores montacargas del mundo</h3>
                            <p className='text-white'>Trulift te ofrece comodidad en el trabajo para que rindas en un mercado competente.</p>
                            <button className='btn btn-outline-light'>Shop Now</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container py-5'>
                <div className="row py-5">
                    <div className="col-md-7 col-lg-6">
                        <h3 className='text-primary'>Repuestos para montacargas</h3>
                        <p className='text-primary'>Precios en especial en repuestos seleccionados hasta el 31 de marzo del 2022</p>
                        <button className='btn btn-outline-primary  mb-4'>Compre ahora</button>
                    </div>
                    <div className="col-8 col-md-5 col-lg-4 offset-1 offset-md-0 offset-lg-1">
                        <img src={repuestosBanner} alt="" className='img-fluid' />
                    </div>
                </div>
            </div>


            <div className='bg-dark text-center py-5' style={{ background: `url(${bodegaImg})`, backgroundPosition: 'center' }}>
                <div className='py-4'>
                    <h5 className='text-white mt-5 mb-4 pt-3'>Ofrecemos más de 50 años de experiencia para ayudarle a encontrar la parte que necesita</h5>
                    <button className='btn btn-outline-light mb-5'>Conozca más</button>
                </div>
            </div>
        </LayoutComponent>
    )
}
