import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import ImageModal from '../../components/ImageModal'
import LayoutAdminComponent from '../../components/LayoutAdminComponent'
import ProductService from '../../services/ProductService'
import SiigoService from '../../services/SiigoService'

export default function SiigoPage() {
  const [logs, setLogs] = useState()
  const [status, setStatus] = useState()
  const [loadingStatus, setLoadingStatus] = useState(true)

  useEffect(() => {
    const getLogs = async () => {
      const logs = await SiigoService.logs()
      setLogs(logs)
    }

    const getStatus = async () => {
      const resp = await SiigoService.status()
      setStatus(resp.status)
      setLoadingStatus(false)
    }
    getStatus()
    getLogs()
  }, [])


  return (
    <LayoutAdminComponent>

      <div className='d-flex justify-content-between'>

        <p className='text-primary title mb-4'>Siigo</p>
        <div>
          {
            loadingStatus ?
              <span className='badge bg-warning'>Cargando status...</span>
              :
              status ?
                <span className='badge bg-success'>Siigo conectado</span>
                :
                <span className='badge bg-danger'>Siigo desconectado</span>
          }
        </div>
      </div>

      <div className="card boder-white shadow pt-3 pb-4">
        <div className="card-body">
          <p>Siigo Logs</p>
          <table className='table table-sm w-100'>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Elemento</th>
                <th>Acción</th>
                <th className='text-start w-50'>Descripción</th>
              </tr>
            </thead>
            <tbody>

              {
                logs && logs.map(log => (
                  <tr key={log._id}>
                    <td>{moment(log.createdAt).tz('America/Bogota').format('YYYY-MM-DD hh:mm:ss a')}</td>
                    <td>{log.element}</td>
                    <td>{log.action}</td>
                    <td className='text-start'>{log.data?.siigoItems} items en Siigo,<br /> {log.data?.exist} items en trulift,<br /> {log.data?.new} nuevos</td>
                  </tr>
                ))

              }

            </tbody>


          </table>
        </div>
      </div>

    </LayoutAdminComponent >
  )
}
