import { Field, Form, Formik } from 'formik';
import React from 'react';
import CuentaForm from '../../components/CuentaForm';
import AddressForm from '../../components/AddressForm';
import LayoutComponent from '../../components/LayoutComponent';
import { NavLink, useNavigate } from 'react-router-dom';

export default function CuentaPage() {
    const navigate = useNavigate();

    return (
        <LayoutComponent>
            <div style={{ backgroundColor: '#F2F2F2', minHeight: 'calc(100vh - 130px)' }}>
                <div
                    style={{ backgroundColor: '#E0E0E0', gap: '1em' }}
                    className="px-5 pt-3  pb-2 d-flex justify-content-between"
                >
                    <div>
                        <h5 className="mb-0">Configuración del Usuario</h5>
                        <p className="text-muted">Los campos obligatorios son *</p>
                        <button type="button" className="btn btn-outline-primary" onClick={() => navigate(-1)}>
                            Volver
                        </button>
                    </div>
                    
                    <NavLink to="/mis-ordenes">
                        <button type="button" className="btn btn-outline-primary">
                            Mis ordenes
                        </button>
                    </NavLink>
                </div>
                <div className="py-4 px-md-5 px-2">
                    <div className="row w-100 ">
                        <div className="col-lg-6">
                            <CuentaForm />
                        </div>
                        <div className="col-lg-6">
                            <AddressForm />
                        </div>
                    </div>
                </div>
            </div>
        </LayoutComponent>
    );
}
