import React from 'react'
import { NavLink } from 'react-router-dom'
import image from '../assets/servicio-al-cliente.png'


export default function SignupPageSuccess() {
    return (
        <div>
            <div className='row mx-0'>
                <div className="col-md-5 col-lg-5 d-flex align-items-center justify-content-center py-5 py-md-0">
                    <div className='px-3 px-md-2 px-lg-5'>
                        <h4 className='text-primary mb-4'>!Tú solicitud ha sido enviada con exito!</h4>
                        <p className='text-primary'>Pronto recibirás un email con la confirmación o la denegación
                            de tu cuenta para que continues el proceso de compra</p>
                        <div>
                            <NavLink to="/">
                                <button className='btn btn-outline-primary'>Perfecto</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="col-md-7 col-lg-5 offset-lg-1 d-none d-md-flex align-items-center" style={{ height: '100vh' }}>
                    <div>
                        <img src={image} alt="login-bg" className='img-fluid my-5 py-4' />
                    </div>
                </div>

            </div>
        </div>
    )
}
