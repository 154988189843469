import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../assets/trulift-logo.svg'
import loginImage from '../assets/loginImage.png'
import UserService from '../services/UserService'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import Swal from 'sweetalert2'


const initialValues = {
    email: '',
    password: ''
};

export default function LoginPage() {
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [loginErrors, setLoginErrors] = useState('');

    const login = async (values) => {
        const { email, password } = values
        setIsSubmiting(true)
        setLoginErrors(undefined)
        const user = await UserService.login({ email, password })
        setIsSubmiting(false)
        if (user && typeof user !== 'string' && user.token) {
            localStorage.setItem('user', JSON.stringify({ ...user.user }));            
            localStorage.setItem('token', user.token);
            if (user.user.role === 'client')
                window.location.href = '/'
            else
                window.location.href = '/usuarios'
        } else if (user?.error === 'siigo_account_incomplete') {
            Swal.fire({
                title: 'Cuenta incompleta',
                text: 'Por favor, ponte en contacto con nosotros a través del correo electrónico "contacto@trulift.com" para que podamos ayudarte a solucionarlo.',
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        } else {
            setLoginErrors("Credenciales incorrectas")
        }
    }

    const onValidate = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'El correo es requerido';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Correo inválido';
        } else if (!values.password) {
            errors.password = 'La contraseña es requerida';
        }
        return errors;
    }

    return (
        <div>
            <div className='row mx-0'>
                <div className="col-md-5 col-lg-6 d-flex align-items-center justify-content-center py-5 py-md-0">
                    <div className='px-3 px-md-2 px-lg-5'>
                        <img src={logo} alt="Trulift logo" className='img-fluid px-4 px-md-0 pb-2 pb-md-0' />
                        <div>
                            <Formik
                                initialValues={initialValues}
                                validate={onValidate}
                                onSubmit={login}
                            >{
                                    (formik) => (

                                        <Form>
                                            <div className="form-group">
                                                <label htmlFor="">Correo electrónico empresarial</label>
                                                <Field type="text" name="email" id="email" className='form-control' placeholder='ejemplo@correo.com' />
                                                {formik.errors.email && <ErrorMessage name="email" component="small" className='text-danger' />}
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="">Contraseña</label>
                                                <Field type="password" name="password" id="password" className='form-control' placeholder='Escribe tu contraseña' />
                                                {formik.errors.email && <ErrorMessage name="password" component="small" className='text-danger' />}
                                            </div>
                                            {loginErrors && <p className='text-danger'>{loginErrors}</p>}
                                            <button type="submit" className='btn btn-primary py-md-2 py-lg-3 w-100 mb-4'>{isSubmiting ? "Iniciando..." : "Iniciar Sesión"}</button>
                                        </Form>
                                    )

                                }
                            </Formik>

                            <div className='text-center mb-5'>
                                <NavLink to='/password-recovery'>Olvide mi contraseña</NavLink>
                            </div>
                            <div className='text-center mb-2'>
                                <small className='link-dark'>Quiero una cuenta nueva</small>
                            </div>

                            <NavLink to='/request-user'><button type="button" className='btn btn-primary py-md-2 py-lg-3 w-100 mb-4'>Solicitar cuenta</button></NavLink>

                        </div>
                    </div>
                </div>
                <div className="col-md-7 col-lg-6 d-none d-md-flex align-items-center" style={{ height: '100vh' }}>
                    <div>
                        <img src={loginImage} alt="login-bg" className='img-fluid my-5 py-4' />
                    </div>
                </div>

            </div>
        </div>
    )
}
