import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import CartPreviewForm from '../../components/CartPreviewForm';
import LayoutComponent from '../../components/LayoutComponent';
import CartService from '../../services/CartService';
import { userLoged } from '../../utils/auth';
import { DescButton, AscButton } from '../../components/SortButton';
import _ from 'lodash';
export default function TiendaPage() {
    const [carts, setCarts] = useState([]);
    const [asc, setAsc] = useState(true);
    const [desc, setDesc] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getCarts();
    }, []);

    const getCarts = async () => {
        const carts = await CartService.getCarts(userLoged._id);
        const sorted = _.orderBy(carts, (item) => new Date(item.createdAt), 'desc');
        setCarts(sorted);
    };

    const createCart = async () => {
        const cart = await CartService.create({
            name: 'Nuevo carrito de compras',
            user_id: userLoged._id,
        });
        setCarts([...carts, cart]);
    };

    const handleSort = () => {
        if (asc) {
            setCarts(_.orderBy(carts, ['createdAt'], ['asc']));
            setDesc((desc) => !desc);
            setAsc((asc) => !asc);
        } else {
            setCarts(_.orderBy(carts, ['createdAt'], ['desc']));
            setAsc((asc) => !asc);
            setDesc((desc) => !desc);
        }
    };

    return (
        <LayoutComponent>
            <div style={{ backgroundColor: '#C7C7C7', minHeight: 'calc(100vh - 130px)' }}>
                <div
                    style={{ backgroundColor: '#E0E0E0' }}
                    className="p-4 d-flex justify-content-between"
                >
                    <button className="btn btn-outline-primary" onClick={() => 
                        navigate(-1)
                    }>
                        Volver
                    </button>
                    <button className="btn btn-outline-primary" onClick={createCart}>
                        Crear Carrito de Compras
                    </button>

                    <AscButton label="Ordenar más nuevo arriba" asc={asc} onClick={handleSort} />
                    <DescButton label="Ordenar más viejo arriba" desc={desc} onClick={handleSort} />
                    <NavLink to="/mis-ordenes">
                        <button type="button" className="btn btn-outline-primary">
                            Mis ordenes
                        </button>
                    </NavLink>
                </div>
                <div className="pt-2">
                    {carts?.length ? (
                        carts.map((cart) => <CartPreviewForm key={cart._id} cart={cart} />)
                    ) : (
                        <div className="bg-white m-5 rounded p-3 text-center">
                            <p className="mb-0">Aun no hay carritos</p>
                            <p className="mb-0">
                                Empiece{' '}
                                <span role="button" className="text-primary" onClick={createCart}>
                                    agregando uno
                                </span>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </LayoutComponent>
    );
}
