const formatCurrency = (value, currency = 'USD', locale = 'en-CO') => {
    const formater = new Intl.NumberFormat(locale ?? 'en-CO', {
        style: 'currency',
        currency: currency || 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    })

    return formater.format(value);
}
module.exports = {
    formatCurrency,
}
