import React from 'react';
import { SelectCity } from './SelectCity';
import { SelectCountry } from './SelectCountry';
import { SelectState } from './SelectState';
import { useNavigate } from 'react-router-dom';
export const CartAddressForm = ({ cartInfo, cartAddress, setPurchaseOrder, purchaseOrder }) => {
    const [address, setAddress] = React.useState([]);
    const [addressSelected, setAddressSelected] = React.useState(0);
    const { name } = cartInfo;
    const navigate = useNavigate();

    const handleAddress = (e) => {
        setAddressSelected(e.target.value);
        cartAddress(address[e.target.value]);
    };

    React.useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        setAddress(user.addresses);
        cartAddress(user.addresses[0]);
        const addressesLength = user.addresses.length;
        if (addressesLength > 1) {
            setAddressSelected(addressesLength - 1);
        }
    }, [cartAddress]);

    return (
        <section className="p-3">
            <div>
                <h4>Información de Envío: {name} </h4>
                <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => navigate(-1)}
                >
                    Volver
                </button>
            </div>
            <div className="d-flex flex-row gap-1 justify-content-evenly">
                <div>
                    <h5>Detalles</h5>
                    <div className="form-group">
                        <label htmlFor="purchase_order">Número de orden de cliente</label>
                        <input
                            type="text"
                            className="form-control"
                            name="purchase_order"
                            id="purchase_order"
                            placeholder="Obligatorio*"
                            onChange={(e) => setPurchaseOrder(e.target.value)}
                            // error style if purchase_order is empty
                            style={{ border: !purchaseOrder && '1px solid red' }}
                        />
                    </div>
                </div>
                <div>
                    <h5>Enviar a esta dirección</h5>
                    <select
                        value={addressSelected}
                        className="form-control"
                        onChange={handleAddress}
                    >
                        {address?.map((item, ind) => (
                            <option key={ind} value={ind}>
                                {item.address}
                            </option>
                        ))}
                    </select>
                    <div className="form-group my-1">
                        <label htmlFor="direction">Dirección</label>
                        <input
                            type="text"
                            placeholder="Dirección"
                            className="form-control"
                            name="direction"
                            id="direction"
                            disabled
                            defaultValue={address[addressSelected]?.address || ''}
                        />
                    </div>
                    <SelectCountry
                        defaultValue={address ? address[addressSelected]?.city.country_code : ''}
                        disabled
                    />
                    <SelectState
                        defaultValue={
                            // address[addressSelected]?.address.city &&
                            // address[addressSelected]?.address.state
                            address ? address[addressSelected]?.city.state_code : ''
                        }
                        disabled
                    />
                    <SelectCity
                        defaultValue={
                            // address[addressSelected]?.address.city &&
                            // address[addressSelected]?.address.city
                            address ? address[addressSelected]?.city.city_code : ''
                        }
                        disabled
                    />
                </div>
            </div>
        </section>
    );
};
