import React from 'react'
import { formatCurrency } from '../utils/format'

export default function MontoLabel({ title, value, currency = true }) {
    return (
        <small className='d-flex'>
            <span className='w-50'>{title}</span>
            <span className='w-100' style={{

                backgroundImage: 'linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%)',
                backgroundPosition: 'bottom',
                backgroundSize: '5px 2px',
                backgroundRepeat: 'repeat-x',
            }}></span>
            <span className='w-75 ps-3'>{currency ? formatCurrency(value, 'COP') : value}</span>
        </small>
    )
}
