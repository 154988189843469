import moment from 'moment';
import { NavLink, useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import { formatCurrency } from '../../utils/format';
import OrderService from '../../services/OrderService';
import LayoutComponent from '../../components/LayoutComponent';
import { cities, countries, statusBadgeColor, statusBadgeText } from '../../constants';

export default function MyOrdersPage({ filter, filter2 }) {
    const [orders, setOrders] = useState([]);
    const [sortBy, setSortBy] = useState('order_number');
    const [sortAsc, setSortAsc] = useState(true);
    const [copyOrders, setCopyOrders] = useState([]);
    const [filterLength, setFilterLength] = useState(0);

    const [invoiceSearch, setInvoiceSearch] = useState('');
    const navigate = useNavigate();

    const getOrders = useCallback(async (invoiceSearch = '') => {
        const orders = await OrderService.getMyOrders(invoiceSearch);
        const filtered =
        filter && filter2
            ? orders.filter((order) => order.status === filter || order.status === filter2)
            : filter
            ? orders.filter((order) => order.status === filter)
            : orders;
        setOrders(filtered);
        setCopyOrders(filtered);
    }, [filter, filter2]);

    const filterOrders = (invoiceSearch) => {
        let searching = copyOrders.filter((item) =>
            item.invoice_number?.name.toLowerCase().includes(invoiceSearch.toLowerCase())
        );
        console.log(copyOrders);
        console.log(orders)
        if (searching.length === 0) {
            if (invoiceSearch !== '') {
                searching = orders.filter((item) =>
                    item.invoice_number?.name.toLowerCase().includes(invoiceSearch.toLowerCase())
                );
            }
        }

        setCopyOrders(searching);
    };

    useEffect(() => {
        getOrders();
    }, [getOrders]);

    useEffect(() => {
        const copy = [...orders];
        const filtered =
            filter && filter2
                ? copy.filter((order) => order.status === filter || order.status === filter2)
                : filter
                ? copy.filter((order) => order.status === filter)
                : orders;
        setFilterLength(filtered.length);
        setCopyOrders(
            filtered.sort((a, b) => {
                switch (sortBy) {
                    case 'invoice_number':
                        return sortAsc
                            ? a.invoice_number?.name > b.invoice_number?.name
                                ? 1
                                : -1
                            : b.invoice_number?.name > a.invoice_number?.name
                            ? 1
                            : -1;
                    case 'ordered_date':
                        return sortAsc ? a.createdAt - b.createdAt : b.createdAt - a.createdAt;
                    case 'processedAt':
                        return sortAsc
                            ? a.processedAt - b.processedAt
                            : b.processedAt - a.processedAt;
                    case 'order_number':
                    default:
                        return sortAsc
                            ? a?.customer_purchase_order - b?.customer_purchase_order
                            : b?.customer_purchase_order - a?.customer_purchase_order;
                }
            })
        );
    }, [sortBy, orders, sortAsc, filter, filter2]);

    const handleSortBy = (newSortBy) => {
        setSortAsc(newSortBy === sortBy ? !sortAsc : true);
        setSortBy(newSortBy);
    };

    const search = () => {
        if (invoiceSearch === '') {
           getOrders();
        } else {
            filterOrders(invoiceSearch);
        }
    };

    React.useEffect(() => {
        search();
    }, [invoiceSearch]);

    const savePathOnLocalStorage = () => {
        localStorage.setItem('path', window.location.pathname);
    };

    return (
        <LayoutComponent>
            <div style={{ backgroundColor: '#F2F2F2', minHeight: 'calc(100vh - 130px)' }}>
                <div
                    style={{ backgroundColor: '#E0E0E0', gap: '1em' }}
                    className="px-5 py-4 d-flex flex-md-row flex-column mb-3 align-items-center"
                >
                    <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => navigate(-1)}
                    >
                        Volver
                    </button>
                    <h5 className="mb-0 text-success">
                        {filterLength} registros encontrados
                    </h5>
                    <span>Ordenar por: </span>
                    <button
                        className={`btn btn-sm ${
                            sortBy === 'order_number' ? 'btn-primary' : 'btn-light'
                        }`}
                        onClick={() => handleSortBy('order_number')}
                    >
                        Número de orden
                    </button>
                    <button
                        className={`btn btn-sm ${
                            sortBy === 'invoice_number' ? 'btn-primary' : 'btn-light'
                        }`}
                        onClick={() => handleSortBy('invoice_number')}
                    >
                        Número de factura
                    </button>
                    <button
                        className={`btn btn-sm ${
                            sortBy === 'ordered_date' ? 'btn-primary' : 'btn-light'
                        }`}
                        onClick={() => handleSortBy('ordered_date')}
                    >
                        Fecha ordenada
                    </button>
                    <button
                        className={`btn btn-sm ${
                            sortBy === 'processedAt' ? 'btn-primary' : 'btn-light'
                        }`}
                        onClick={() => handleSortBy('processedAt')}
                    >
                        Fecha de envío
                    </button>
                </div>

                <div className="row mb-3 w-100 px-md-5 px-2">
                    <div className="col-lg-4 offset-lg-8">
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Buscar factura"
                                aria-label="Buscar factura"
                                aria-describedby="basic-addon2"
                                onChange={(e) => setInvoiceSearch(e.target.value)}
                                onKeyUp={(e) => e.key === 'Enter' && search()}
                                value={invoiceSearch}
                            />
                            <div className="input-group-append">
                                <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={search}
                                >
                                    Buscar factura
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pb-4 px-md-5 px-2">
                    <div className="row w-100 ">
                        <div className="col-lg-12">
                            <div className="card bg-white">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Número de orden</th>
                                                    <th scope="col">Valor total</th>
                                                    <th scope="col">Dirección</th>
                                                    <th scope="col">Estado</th>
                                                    <th scope="col">Número de factura</th>
                                                    <th scope="col">Fecha de orden</th>
                                                    <th scope="col">Fecha de envío</th>
                                                    <th scope="col">Metodo de entrega</th>
                                                    <th scope="col">Número de guía</th>
                                                    <th scope="col">Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orders.length === 0 && (
                                                    <tr>
                                                        <td colSpan="10" className="text-center">
                                                            No hay registros
                                                        </td>
                                                    </tr>
                                                )}
                                                {orders &&
                                                    copyOrders.map((order) => (
                                                        <tr key={order._id}>
                                                            <td>
                                                                #
                                                                {order?.customer_purchase_order
                                                                    ? order?.customer_purchase_order.split(
                                                                          ':'
                                                                      )[1]
                                                                    : 'N/A'}
                                                            </td>
                                                            <td>{formatCurrency(order.total)}</td>
                                                            <td>
                                                                {[
                                                                    countries.find(
                                                                        (country) =>
                                                                            country.countryCode ===
                                                                            order?.address?.country
                                                                    )?.countryName,
                                                                    cities.find(
                                                                        (city) =>
                                                                            city.StateCode ===
                                                                            order?.address?.state
                                                                    )?.StateName,
                                                                    cities.find(
                                                                        (city) =>
                                                                            city.CityCode ===
                                                                            order?.address?.city
                                                                    )?.CityName,
                                                                    order?.address?.address,
                                                                    order?.address?.apartment,
                                                                ]
                                                                    .filter((item) => item)
                                                                    .join(', ')}
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className={`badge ${
                                                                        statusBadgeColor[
                                                                            order.status
                                                                        ]
                                                                    }`}
                                                                >
                                                                    {statusBadgeText[order.status]}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {order.invoice_number && (
                                                                    <a
                                                                        rel="noreferrer"
                                                                        href={`${process.env.REACT_APP_API_URL}/api/invoice/${order?.invoice_number._id}/pdf`}
                                                                        target="_blank"
                                                                    >
                                                                        {order.invoice_number?.name}
                                                                    </a>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {order.createdAt
                                                                    ? moment(
                                                                          order.createdAt
                                                                      ).format('YYYY-MM-DD')
                                                                    : ''}
                                                            </td>
                                                            <td>
                                                                {order.processedAt
                                                                    ? moment(
                                                                          order.processedAt
                                                                      ).format('YYYY-MM-DD')
                                                                    : ''}
                                                            </td>
                                                            <td>{order.delivery_method}</td>
                                                            <td>
                                                                {order.link ? (
                                                                    <a
                                                                        href={order.link}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        {order.guia}
                                                                    </a>
                                                                ) : (
                                                                    order.guia
                                                                )}
                                                            </td>
                                                            <td>
                                                                <NavLink
                                                                    to={`/mis-ordenes/${order._id}`}
                                                                    onClick={savePathOnLocalStorage}
                                                                >
                                                                    Ver detalles
                                                                </NavLink>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutComponent>
    );
}
