import axios from 'axios'
import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import ProductService from '../services/ProductService'

export default function ImageModal({ show, handleClose, handleConfirm, onSave, product }) {
    const [image, setImage] = React.useState(null)
    const updateImage = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('image', file)
        const resp = await axios.post(`${process.env.REACT_APP_API_URL}/api/product/${product._id}/img`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        console.log(resp.data.image);
        setImage(resp.data.image)
        onSave?.(resp.data)
    }

    const changeImageClick = () => {
        document.getElementById('image').click()
    }

    useEffect(() => {
        setImage(product?.image)
    }, [product])


    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{product?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        product &&
                        <img src={image} alt="" className='w-100 image-fluid rounded' />
                    }
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-between'>
                    <div>
                        <Button variant="outline-primary" onClick={changeImageClick}>Cambiar imagen</Button>
                        <input type="file" id='image' onChange={updateImage} className='d-none' accept='image/*' />
                    </div>
                    <div>
                        <Button variant="secondary" onClick={handleClose} className="me-2">
                            Cerrar
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal></>
    )
}
