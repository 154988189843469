import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { NavLink } from 'react-router-dom';
import LayoutAdminComponent from '../../components/LayoutAdminComponent';
import { cities, countries, statusBadgeColor, statusBadgeText } from '../../constants';
import OrderService from '../../services/OrderService';
import { formatCurrency } from '../../utils/format';
import _ from 'lodash';
import { AscButton, DescButton } from '../../components/SortButton';
export default function ReporteVentasPage() {
    const [orders, setOrders] = useState([]);
    const [asc, setAsc] = useState(true);
    const [desc, setDesc] = useState(false);

    useEffect(() => {
        const getOrders = async () => {
            const resp = await OrderService.getOrders();
            const sorted = _.orderBy(resp, (orders) => new Date(orders.createdAt), ['desc']);
            setOrders(sorted);
        };
        getOrders();
    }, []);

    const handleSort = () => {
        if (asc) {
            setOrders(_.orderBy(orders, (orders) => new Date(orders.createdAt), ['asc']));
            setDesc((desc) => !desc);
            setAsc((asc) => !asc);
        } else {
            setOrders(_.orderBy(orders, (orders) => new Date(orders.createdAt), ['desc']));
            setAsc((asc) => !asc);
            setDesc((desc) => !desc);
        }
    };

    return (
        <LayoutAdminComponent>
            <div className="d-flex justify-content-between">
                <p className="text-primary title mb-4">Reporte ventas</p>
                <p className="text-primary title me-4 ">
                    <b>
                        Total: {formatCurrency(orders.reduce((acc, order) => acc + order.total, 0))}
                    </b>
                </p>
            </div>
            <div className="d-flex justify-content-end">
                <div>
                    <AscButton label="Ordenar más nuevo" asc={asc} onClick={handleSort} />
                    <DescButton label="Ordenar más viejo" desc={desc} onClick={handleSort} />
                </div>
            </div>
            <div className="card boder-white shadow pt-3 pb-4">
                <div className="card-body">
                    <table className="table table-sm w-100">
                        <thead>
                            <tr>
                                <th>Orden</th>
                                <th className="text-start">Bussiness Name</th>
                                <th className="text-start">Correo electrónico</th>
                                <th className="text-start">País</th>
                                <th className="text-start">Ciudad</th>
                                <th className="text-start">Dirección</th>
                                <th>Metodo de entrega</th>
                                <th>Guía</th>
                                <th>Total</th>
                                <th>Estatus</th>
                                <th>Fecha de creación</th>
                                <th>Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders &&
                                orders.map((order) => (
                                    <tr key={order._id}>
                                        <td>#{order.order_number}</td>
                                        <td className="text-start">
                                            {[order.user.firstName, order.user.lastName]
                                                .filter((item) => item)
                                                .join(' ')}

                                            {!order.user.lastUpdateSiigo && (
                                                <span className="badge bg-danger ms-1">
                                                    Sin Siigo ID{' '}
                                                </span>
                                            )}
                                        </td>
                                        <td className="text-start">{order.user.email}</td>
                                        <td className="text-start">
                                            {
                                                countries.find(
                                                    (country) =>
                                                        country.countryCode ===
                                                        order?.address?.country
                                                )?.countryName
                                            }
                                        </td>
                                        <td className="text-start">
                                            {[
                                                cities.find(
                                                    (city) =>
                                                        city.StateCode === order?.address?.state
                                                )?.StateName,
                                                cities.find(
                                                    (city) => city.CityCode === order?.address?.city
                                                )?.CityName,
                                            ]
                                                .filter((item) => item)
                                                .join(', ')}
                                        </td>
                                        <td className="text-start">
                                            {[order.address?.address, order.address?.apartment]
                                                .filter((item) => item)
                                                .join(', ')}
                                        </td>
                                        <td>{order.delivery_method}</td>
                                        <td>
                                            {order.link ? (
                                                <a
                                                    href={order.link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {order.guia}
                                                </a>
                                            ) : (
                                                order.guia
                                            )}
                                        </td>
                                        <td>{formatCurrency(order.total)}</td>
                                        <td>
                                            <span
                                                className={`badge ${
                                                    statusBadgeColor[order.status]
                                                }`}
                                            >
                                                {statusBadgeText[order.status]}
                                            </span>
                                        </td>
                                        <td>
                                            {moment(order.createdAt).format('DD/MM/YYYY HH:mm a')}
                                        </td>
                                        <td>
                                            <NavLink to={`/admin/pedido/${order._id}`}>Ver</NavLink>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </LayoutAdminComponent>
    );
}
