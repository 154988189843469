import React, { useEffect } from 'react';
import { Field, Form, Formik, useFormikContext } from 'formik';
import UserService from '../services/UserService';
import { userLoged } from '../utils/auth';
import Swal from 'sweetalert2';
import { cities, countries } from '../constants';

function AddressFormContent() {
    const { setValues, isSubmitting, values } = useFormikContext();

    useEffect(() => {
        const getUser = async () => {
            const resp = await UserService.getUser(userLoged._id);
            setValues(resp.address);
        };

        getUser();
    }, [setValues]);
    return (
        <div className="row w-100">
            <div className="col-lg-10 mb-2">
                <label htmlFor="">País</label>
                <Field
                    component="select"
                    type="text"
                    className="form-control form-control-sm"
                    id="country"
                    name="country"
                >
                    {values.city?.country_code && (
                        <option
                            defaultValue={values.city?.country_code}
                            value={values.city?.country_code}
                        >
                            {
                                countries.find(
                                    (country) => country.countryCode === values.city?.country_code
                                ).countryName
                            }
                        </option>
                    )}
                    {/* <option value="">Seleccione</option> */}
                    {countries.map((country, index) => (
                        <option key={index} value={country.countryCode}>
                            {country.countryName}
                        </option>
                    ))}
                </Field>
            </div>
            <div className="col-lg-10 mb-2">
                <label htmlFor="">Departamento - Estado</label>
                <Field
                    component="select"
                    type="text"
                    className="form-control form-control-sm"
                    id="state"
                    name="state"
                >
                    {values.city?.state_code && (
                        <option
                            defaultValue={values.city?.state_code}
                            value={values.city?.state_code}
                        >
                            {
                                cities
                                    .filter((city) => city.StateCode === values.city?.state_code)
                                    .find((city) => city.CityCode === values.city?.city_code)
                                    .StateName
                            }
                        </option>
                    )}
                    {/* <option value="">Seleccione</option> */}
                    {cities
                        .filter((city) => city.CountryCode === values.city?.country_code)
                        .filter(
                            (city, index, self) =>
                                self.findIndex((c) => c.StateName === city.StateName) === index
                        )
                        .sort()
                        .map((city, index) => (
                            <option key={index} value={city.StateCode}>
                                {city.StateName}
                            </option>
                        ))}
                </Field>
            </div>
            <div className="col-lg-10 mb-2">
                <label htmlFor="">Ciudad</label>
                <Field
                    component="select"
                    type="text"
                    className="form-control form-control-sm"
                    id="city"
                    name="city"
                >
                    {values.city?.city_code && (
                        <option
                            defaultValue={values.city?.city_code}
                            value={values.city?.city_code}
                        >
                            {
                                cities
                                    .filter(
                                        (city) =>
                                            city.CountryCode === values.city?.country_code &&
                                            city.StateCode === values.city?.state_code
                                    )
                                    .find((city) => city.CityCode === values.city?.city_code)
                                    .CityName
                            }
                        </option>
                    )}
                    <option value="">Seleccione</option>
                    {cities
                        .filter(
                            (city) =>
                                city.CountryCode === values.city?.country_code &&
                                city.StateCode === values.city?.state_code
                        )
                        .sort()
                        .map((city, index) => (
                            <option key={index} value={city.CityCode}>
                                {city.CityName}
                            </option>
                        ))}
                </Field>
            </div>
            <div className="col-lg-10 mb-2">
                <label htmlFor="">Dirección</label>
                <Field
                    type="text"
                    className="form-control form-control-sm"
                    id="address"
                    name="address"
                />
            </div>
            <div className="col-lg-10 mb-3">
                <label htmlFor="">Casa o Apartamento</label>
                <Field
                    type="text"
                    className="form-control form-control-sm"
                    id="apartment"
                    name="apartment"
                />
            </div>

            <div className="col-lg-10 mb-2">
                <button className="btn btn-outline-primary btn-sm">
                    {isSubmitting ? 'Actualizando...' : 'Actualizar dirección'}
                </button>
            </div>
        </div>
    );
}

export default function AddressForm() {
    const initialValues = {
        country: '',
        state: '',
        city: '',
        address: {
            apartment: '',
            country: '',
            state: '',
            city: '',
        },
        apartment: '',
    };
    const validation = (values) => {
        const errors = {};
        return errors;
    };

    const onSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
            const resp = await UserService.updateAddress(userLoged._id, values);
            if (resp.user) {
                Swal.fire({
                    icon: 'success',
                    title: 'Actualizado',
                    text: 'Información actualizada correctamente',
                    showConfirmButton: false,
                    timer: 1500,
                });
                localStorage.setItem('user', JSON.stringify(resp.user));
            } else {
                Swal.fire({
                    title: 'Datos incorrectos',
                    text: 'Por favor, verifique los datos ingresados',
                    icon: 'warning',
                    confirmButtonText: 'Aceptar',
                });
            }
        } catch (error) {
            console.log('entro aca');
        }
        setSubmitting(false);
    };

    return (
        <>
            <p className="mb-3 border-bottom">Dirección de envío</p>
            <Formik initialValues={initialValues} validation={validation} onSubmit={onSubmit}>
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <Form>
                        <AddressFormContent />
                    </Form>
                )}
            </Formik>
        </>
    );
}
