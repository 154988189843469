import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import OrderService from '../services/OrderService';
import CartService from '../services/CartService';
import Swal from 'sweetalert2';
export const ModalBackOrder = ({ show, handleClose, cart, getBackOrders }) => {
    const [products, setProducts] = React.useState([]);

    const handleChange = (e) => {
        setProducts({
            ...products,
            [e.target.name]: e.target.value,
        });
    };

    const handleBackOrder = async () => {
        try {
            cart.products.map(
                async (product) =>
                    await CartService.updateProductCartQuantity(product._id, products[product._id])
            );

            OrderService.createBackOrder(cart._id)
                .then((res) => {
                    if (res?.error === 'Backorder exists') {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Ya existe un backorder para este carrito!',
                        });
                        return;
                    }
                    Swal.fire({
                        icon: 'success',
                        title: 'Backorder realizado con éxito!',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    getBackOrders();
                })
                .catch((err) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Algo salió mal!',
                    });
                });

            handleClose();
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Algo salió mal!',
            });
        }
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>BackOrder</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {cart &&
                    cart.products?.map((product) => (
                        <div
                            className="d-flex justify-content-evenly align-items-center gap-3 product-card-list border-bottom border-dark"
                            key={product._id}
                        >
                            <div className="d-flex flex-column">
                                <label>Producto</label>
                                <span className="text-primary">{product.item.name}</span>
                            </div>

                            <div className="col-lg-1 mb-2">
                                <label>Cant.</label>
                                <input
                                    type="number"
                                    className="form-control form-control-sm mb-1"
                                    style={{ width: '100px' }}
                                    name={product._id}
                                    onChange={handleChange}
                                    defaultValue={product.quantity}
                                />
                            </div>
                        </div>
                    ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleBackOrder}>
                    Hacer BackOrder
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
