import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import LayoutAdminComponent from '../../components/LayoutAdminComponent';
import { cities, countries } from '../../constants';
import OrderService from '../../services/OrderService';
import UserService from '../../services/UserService';
import _ from 'lodash';
import { AscButton, DescButton } from '../../components/SortButton';
export default function ReporteUsuariosPage() {
    const [clients, setClients] = useState([]);
    const [asc, setAsc] = useState(true);
    const [desc, setDesc] = useState(false);

    useEffect(() => {
        const getReport = async () => {
            const resp = await UserService.getReport();
            const sorted = _.orderBy(resp, (item) => new Date(item.createdAt), 'desc');
            setClients(sorted);
        };
        getReport();
    }, []);

    const [sortField, setSortField] = useState('email');
    const [sortOrder, setSortOrder] = useState('asc');

    const sort = (field, order = 'asc') => {
        setSortField(field);
        setSortOrder(order);
        const sorted = [...clients].sort((a, b) => {
            if (a[field] > b[field]) {
                return order === 'asc' ? 1 : -1;
            }
            if (a[field] < b[field]) {
                return order === 'asc' ? -1 : 1;
            }
            return 0;
        });
        setClients(sorted);
    };

    const handleSort = () => {
        if (asc) {
            setClients(_.orderBy(clients, ['createdAt'], ['asc']));
            setDesc((desc) => !desc);
            setAsc((asc) => !asc);
        } else {
            setClients(_.orderBy(clients, ['createdAt'], ['desc']));
            setAsc((asc) => !asc);
            setDesc((desc) => !desc);
        }
    };

    return (
        <LayoutAdminComponent>
            <div className="d-flex justify-content-between">
                <p className="text-primary title mb-4">Reporte de usuarios</p>
            </div>
            <div className="d-flex justify-content-end">
                <div>
                    <AscButton label="Ordenar más nuevo" asc={asc} onClick={handleSort} />
                    <DescButton label="Ordenar más viejo" desc={desc} onClick={handleSort} />
                </div>
            </div>
            <div className="card boder-white shadow pt-3 pb-4">
                <div className="card-body">
                    <table className="table table-sm w-100">
                        <thead>
                            <tr>
                                <th
                                    role="button"
                                    onClick={() => {
                                        sort(
                                            'email',
                                            sortField === 'email'
                                                ? sortOrder === 'asc'
                                                    ? 'desc'
                                                    : 'asc'
                                                : 'asc'
                                        );
                                    }}
                                >
                                    Correo
                                </th>

                                <th
                                    role="button"
                                    onClick={() => {
                                        sort(
                                            'name',
                                            sortField === 'name'
                                                ? sortOrder === 'asc'
                                                    ? 'desc'
                                                    : 'asc'
                                                : 'asc'
                                        );
                                    }}
                                >
                                    Nombre del negocio
                                </th>
                                <th
                                    role="button"
                                    onClick={() => {
                                        sort(
                                            'orders',
                                            sortField === 'orders'
                                                ? sortOrder === 'asc'
                                                    ? 'desc'
                                                    : 'asc'
                                                : 'asc'
                                        );
                                    }}
                                >
                                    Número de pedidos
                                </th>
                                <th
                                    role="button"
                                    onClick={() => {
                                        sort(
                                            'lastLogin',
                                            sortField === 'lastLogin'
                                                ? sortOrder === 'asc'
                                                    ? 'desc'
                                                    : 'asc'
                                                : 'asc'
                                        );
                                    }}
                                >
                                    Última entrada
                                </th>
                                <th
                                    role="button"
                                    onClick={() => {
                                        sort(
                                            'loginCount',
                                            sortField === 'loginCount'
                                                ? sortOrder === 'asc'
                                                    ? 'desc'
                                                    : 'asc'
                                                : 'asc'
                                        );
                                    }}
                                >
                                    Número de entradas
                                </th>
                                <th>Fecha de creación</th>
                            </tr>
                        </thead>
                        <tbody>
                            {clients &&
                                clients.map((client) => (
                                    <tr key={client._id}>
                                        <td>{client.email}</td>
                                        <td>
                                            {[client.firstName, client.lastName]
                                                .filter((item) => item)
                                                .join(' ')}
                                        </td>
                                        <td>{client.orders.length}</td>
                                        <td>
                                            {client.lastLogin
                                                ? moment(client.lastLogin).format(
                                                      'DD/MM/YYYY HH:mm a'
                                                  )
                                                : ''}
                                        </td>
                                        <td>{client.loginCount}</td>
                                        <td>
                                            {client.createdAt
                                                ? moment(client.createdAt).format(
                                                      'DD/MM/YYYY HH:mm a'
                                                  )
                                                : ''}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </LayoutAdminComponent>
    );
}
