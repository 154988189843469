import React from 'react'
import LayoutComponent from '../../components/LayoutComponent'

export default function SoportePoliticas() {
  return (
    <LayoutComponent>
        <div className='mt-5 w-50' style={{ marginLeft:'150px' }}>
            <h3 className='text-secondary'>Terminos y condiciones de venta</h3>
            <hr style={{ borderTop:'2px solid' }} />
            <p style={{ fontSize:'18px' }}>
                La información y los materiales de ésta página son propiedad de TVH Parts Co. (TVH). Solo se puede bajar, copiar o reproducir información de la página con el objeto de pedir partes de TVH ó de obtener información sobre las partes vendidas por TVH. Cualquier otra reproducción, distribución, republicación o retransmisión de qualquier información o material en ésta página sin permiso por escrito de TVH está prohibido
            </p>
            <p style={{ fontSize:'18px' }}>
                La información y materiales contenidos en este portal son exactos hasta la extensión de nuestros propios conocimientos; sin embargo ésta puede contener información técnica no exacta, errores de tipeo y/o inconsistencias. Los precios e información expuestos en este portal están sujetos a cambios sin previa notificación. TVH declina toda responsabilidad por daños que puedan ser causados por el uso de este portal, así como también cualquier garantia implicita.
            </p>
            
            
            <h3 className='text-secondary'>Política de privacidad</h3>
            <hr style={{ borderTop:'2px solid' }} />
            <p style={{ fontSize:'18px' }}>
                La información y los materiales de ésta página son propiedad de TVH Parts Co. (TVH). Solo se puede bajar, copiar o reproducir información de la página con el objeto de pedir partes de TVH ó de obtener información sobre las partes vendidas por TVH. Cualquier otra reproducción, distribución, republicación o retransmisión de qualquier información o material en ésta página sin permiso por escrito de TVH está prohibido
            </p>
            <p style={{ fontSize:'18px' }}>
                La información y materiales contenidos en este portal son exactos hasta la extensión de nuestros propios conocimientos; sin embargo ésta puede contener información técnica no exacta, errores de tipeo y/o inconsistencias. Los precios e información expuestos en este portal están sujetos a cambios sin previa notificación. TVH declina toda responsabilidad por daños que puedan ser causados por el uso de este portal, así como también cualquier garantia implicita.
            </p>
        </div>
    </LayoutComponent>
  )
}
