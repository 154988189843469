import React from 'react';
import { cities } from '../constants';
export const SelectCity = ({
    handleChange,
    country,
    state,
    defaultValue,
    className = 'form-control',
    disabled,
}) => {
    return (
        <div className="form-group">
            <label htmlFor="city">Ciudad</label>
            <select
                name="city"
                id="city"
                className={className}
                onChange={handleChange}
                defaultValue={defaultValue}
                disabled={disabled}
            >
                {cities.find((city) => city.CityCode === defaultValue) ? (
                    <option value={defaultValue}>
                        {cities.find((city) => city.CityCode === defaultValue).CityName}
                    </option>
                ) : (
                    <option value="">Seleccione</option>
                )}
                {cities
                    .filter((city) => city.CountryCode === country && city.StateCode === state)
                    .sort()
                    .map((city, index) => (
                        <option key={index} value={city.CityCode}>
                            {city.CityName}
                        </option>
                    ))}
            </select>
        </div>
    );
};
