exports.statusBadgeColor = {
    pending: 'bg-primary',
    approved: 'bg-success',
    rejected: 'bg-black',
    processed: 'bg-info',
    completed: 'bg-completed',
    backorder: 'bg-primary',
};

exports.statusBadgeText = {
    pending: 'En revisión',
    approved: 'Aprobada',
    rejected: 'Rechazada',
    processed: 'Tramitado',
    completed: 'Finalizado',
    backorder: 'Backorder',
};

exports.idTypes = {
    13: 'Cédula de ciudadanía',
    31: 'NIT',
    22: 'Cédula de extranjería',
    42: 'Documento de identificación extranjero',
    50: 'NIT de otro país',
    'R-00-PN': 'No obligado a registrarse en el RUT PN',
    91: 'NUIP',
    41: 'Pasaporte',
    47: 'Permiso especial de permanencia PEP',
    11: 'Registro civil',
    43: 'Sin identificación del exterior o para uso definido por la DIAN',
    21: 'Tarjeta de extranjería',
    12: 'Tarjeta de identidad',
};

exports.payment_methods = [
    {
        id: 8336,
        name: 'Serfinanza Cte',
        type: 'Cartera',
        active: true,
        due_date: false,
    },
    {
        id: 7482,
        name: 'Anticipo',
        type: 'Cartera',
        active: true,
        due_date: true,
    },
    {
        id: 2083,
        name: 'Tarjeta Crédito',
        type: 'Cartera',
        active: true,
        due_date: false,
    },
    {
        id: 2082,
        name: 'Tarjeta Débito',
        type: 'Cartera',
        active: true,
        due_date: false,
    },
    {
        id: 2081,
        name: 'Crédito',
        type: 'Cartera',
        active: true,
        due_date: true,
    },
    {
        id: 2080,
        name: 'Efectivo',
        type: 'CarteraProveedor',
        active: true,
        due_date: false,
    },
    {
        id: 2088,
        name: 'Honorarios',
        type: 'CarteraProveedor',
        active: true,
        due_date: false,
    },
];

exports.approvalStatus = {
    pending: {
        title: 'Pendiente',
        classColor: 'secondary',
    },
    approved: {
        title: 'Aprobado',
        classColor: 'success',
    },
    rejected: {
        title: 'Rechazado',
        classColor: 'danger',
    },
};

exports.cities = [
    {
        CityCode: '05002',
        CityName: 'Abejorral',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54003',
        CityName: 'Abrego',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05004',
        CityName: 'Abriaquí',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50006',
        CityName: 'Acacías',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27006',
        CityName: 'Acandí',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41006',
        CityName: 'Acevedo',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13006',
        CityName: 'Achí',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41013',
        CityName: 'Agrado',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25001',
        CityName: 'Agua De Dios',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20011',
        CityName: 'Aguachica',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68013',
        CityName: 'Aguada',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17013',
        CityName: 'Aguadas',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '85010',
        CityName: 'Aguazul',
        StateCode: '85',
        StateName: 'Casanare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20013',
        CityName: 'Agustín Codazzi',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41016',
        CityName: 'Aipe',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25019',
        CityName: 'Albán',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52019',
        CityName: 'Albán',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '18029',
        CityName: 'Albania',
        StateCode: '18',
        StateName: 'Caquetá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '44035',
        CityName: 'Albania',
        StateCode: '44',
        StateName: 'La Guajira',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68020',
        CityName: 'Albania',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76020',
        CityName: 'Alcalá',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52022',
        CityName: 'Aldana',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05021',
        CityName: 'Alejandría',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47030',
        CityName: 'Algarrobo',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41020',
        CityName: 'Algeciras',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19022',
        CityName: 'Almaguer',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15022',
        CityName: 'Almeida',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73024',
        CityName: 'Alpujarra',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41026',
        CityName: 'Altamira',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27025',
        CityName: 'Alto Baudo',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13030',
        CityName: 'Altos Del Rosario',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73026',
        CityName: 'Alvarado',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05030',
        CityName: 'Amagá',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05031',
        CityName: 'Amalfi',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73030',
        CityName: 'Ambalema',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25035',
        CityName: 'Anapoima',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52036',
        CityName: 'Ancuyá',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76036',
        CityName: 'Andalucía',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05034',
        CityName: 'Andes',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05036',
        CityName: 'Angelópolis',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05038',
        CityName: 'Angostura',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25040',
        CityName: 'Anolaima',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05040',
        CityName: 'Anorí',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17042',
        CityName: 'Anserma',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76041',
        CityName: 'Ansermanuevo',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05044',
        CityName: 'Anza',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73043',
        CityName: 'Anzoátegui',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05045',
        CityName: 'Apartadó',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '66045',
        CityName: 'Apía',
        StateCode: '66',
        StateName: 'Risaralda',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25599',
        CityName: 'Apulo',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15047',
        CityName: 'Aquitania',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47053',
        CityName: 'Aracataca',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17050',
        CityName: 'Aranzazu',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68051',
        CityName: 'Aratoca',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '81001',
        CityName: 'Arauca',
        StateCode: '81',
        StateName: 'Arauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '81065',
        CityName: 'Arauquita',
        StateCode: '81',
        StateName: 'Arauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25053',
        CityName: 'Arbeláez',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52051',
        CityName: 'Arboleda',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54051',
        CityName: 'Arboledas',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05051',
        CityName: 'Arboletes',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15051',
        CityName: 'Arcabuco',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13042',
        CityName: 'Arenal',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05055',
        CityName: 'Argelia',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19050',
        CityName: 'Argelia',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76054',
        CityName: 'Argelia',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47058',
        CityName: 'Ariguaní',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13052',
        CityName: 'Arjona',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05059',
        CityName: 'Armenia',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '63001',
        CityName: 'Armenia',
        StateCode: '63',
        StateName: 'Quindio',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73055',
        CityName: 'Armero',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13062',
        CityName: 'Arroyohondo',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20032',
        CityName: 'Astrea',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73067',
        CityName: 'Ataco',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27050',
        CityName: 'Atrato',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23068',
        CityName: 'Ayapel',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27073',
        CityName: 'Bagadó',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27075',
        CityName: 'Bahía Solano',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27077',
        CityName: 'Bajo Baudó',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19075',
        CityName: 'Balboa',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '66075',
        CityName: 'Balboa',
        StateCode: '66',
        StateName: 'Risaralda',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08078',
        CityName: 'Baranoa',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41078',
        CityName: 'Baraya',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52079',
        CityName: 'Barbacoas',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05079',
        CityName: 'Barbosa',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68077',
        CityName: 'Barbosa',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68079',
        CityName: 'Barichara',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50110',
        CityName: 'Barranca De Upía',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68081',
        CityName: 'Barrancabermeja',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '44078',
        CityName: 'Barrancas',
        StateCode: '44',
        StateName: 'La Guajira',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13074',
        CityName: 'Barranco De Loba',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '94343',
        CityName: 'Barranco Minas',
        StateCode: '94',
        StateName: 'Guainía',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08001',
        CityName: 'Barranquilla',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20045',
        CityName: 'Becerril',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17088',
        CityName: 'Belalcázar',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15087',
        CityName: 'Belén',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52083',
        CityName: 'Belén',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27086',
        CityName: 'Belén De Bajirá',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '18094',
        CityName: 'Belén De Los Andaquies',
        StateCode: '18',
        StateName: 'Caquetá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '66088',
        CityName: 'Belén De Umbría',
        StateCode: '66',
        StateName: 'Risaralda',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05088',
        CityName: 'Bello',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05086',
        CityName: 'Belmira',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25086',
        CityName: 'Beltrán',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15090',
        CityName: 'Berbeo',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05091',
        CityName: 'Betania',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15092',
        CityName: 'Betéitiva',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05093',
        CityName: 'Betulia',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68092',
        CityName: 'Betulia',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25095',
        CityName: 'Bituima',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15097',
        CityName: 'Boavita',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54099',
        CityName: 'Bochalema',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '11001',
        CityName: 'Bogotá',
        StateCode: '11',
        StateName: 'Bogotá D.C',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25099',
        CityName: 'Bojacá',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27099',
        CityName: 'Bojaya',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19100',
        CityName: 'Bolívar',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68101',
        CityName: 'Bolívar',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76100',
        CityName: 'Bolívar',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20060',
        CityName: 'Bosconia',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15104',
        CityName: 'Boyacá',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05107',
        CityName: 'Briceño',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15106',
        CityName: 'Briceño',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68001',
        CityName: 'Bucaramanga',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54109',
        CityName: 'Bucarasica',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76109',
        CityName: 'Buenaventura',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15109',
        CityName: 'Buenavista',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23079',
        CityName: 'Buenavista',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '63111',
        CityName: 'Buenavista',
        StateCode: '63',
        StateName: 'Quindio',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70110',
        CityName: 'Buenavista',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19110',
        CityName: 'Buenos Aires',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52110',
        CityName: 'Buesaco',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76113',
        CityName: 'Bugalagrande',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05113',
        CityName: 'Buriticá',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15114',
        CityName: 'Busbanzá',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25120',
        CityName: 'Cabrera',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68121',
        CityName: 'Cabrera',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50124',
        CityName: 'Cabuyaro',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '94886',
        CityName: 'Cacahual',
        StateCode: '94',
        StateName: 'Guainía',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05120',
        CityName: 'Cáceres',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25123',
        CityName: 'Cachipay',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54128',
        CityName: 'Cachirá',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54125',
        CityName: 'Cácota',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05125',
        CityName: 'Caicedo',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76122',
        CityName: 'Caicedonia',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70124',
        CityName: 'Caimito',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73124',
        CityName: 'Cajamarca',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19130',
        CityName: 'Cajibío',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25126',
        CityName: 'Cajicá',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13140',
        CityName: 'Calamar',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '95015',
        CityName: 'Calamar',
        StateCode: '95',
        StateName: 'Guaviare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '63130',
        CityName: 'Calarca',
        StateCode: '63',
        StateName: 'Quindio',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05129',
        CityName: 'Caldas',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15131',
        CityName: 'Caldas',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19137',
        CityName: 'Caldono',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76001',
        CityName: 'Cali',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68132',
        CityName: 'California',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76126',
        CityName: 'Calima',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19142',
        CityName: 'Caloto',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05134',
        CityName: 'Campamento',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08137',
        CityName: 'Campo De La Cruz',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41132',
        CityName: 'Campoalegre',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15135',
        CityName: 'Campohermoso',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23090',
        CityName: 'Canalete',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08141',
        CityName: 'Candelaria',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76130',
        CityName: 'Candelaria',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13160',
        CityName: 'Cantagallo',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05138',
        CityName: 'Cañasgordas',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25148',
        CityName: 'Caparrapí',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68147',
        CityName: 'Capitanejo',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25151',
        CityName: 'Caqueza',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05142',
        CityName: 'Caracolí',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05145',
        CityName: 'Caramanta',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68152',
        CityName: 'Carcasí',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05147',
        CityName: 'Carepa',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73148',
        CityName: 'Carmen De Apicalá',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25154',
        CityName: 'Carmen De Carupa',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27150',
        CityName: 'Carmen Del Darien',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05150',
        CityName: 'Carolina',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13001',
        CityName: 'Cartagena',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '18150',
        CityName: 'Cartagena Del Chairá',
        StateCode: '18',
        StateName: 'Caquetá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76147',
        CityName: 'Cartago',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '97161',
        CityName: 'Caruru',
        StateCode: '97',
        StateName: 'Vaupés',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73152',
        CityName: 'Casabianca',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50150',
        CityName: 'Castilla La Nueva',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05154',
        CityName: 'Caucasia',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68160',
        CityName: 'Cepitá',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23162',
        CityName: 'Cereté',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15162',
        CityName: 'Cerinza',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68162',
        CityName: 'Cerrito',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47161',
        CityName: 'Cerro San Antonio',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27160',
        CityName: 'Cértegui',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52240',
        CityName: 'Chachagüí',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25168',
        CityName: 'Chaguaní',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70230',
        CityName: 'Chalán',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '85015',
        CityName: 'Chameza',
        StateCode: '85',
        StateName: 'Casanare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73168',
        CityName: 'Chaparral',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68167',
        CityName: 'Charalá',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68169',
        CityName: 'Charta',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25175',
        CityName: 'Chía',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47170',
        CityName: 'Chibolo',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05172',
        CityName: 'Chigorodó',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68176',
        CityName: 'Chima',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23168',
        CityName: 'Chimá',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20175',
        CityName: 'Chimichagua',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54172',
        CityName: 'Chinácota',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15172',
        CityName: 'Chinavita',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17174',
        CityName: 'Chinchiná',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23182',
        CityName: 'Chinú',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25178',
        CityName: 'Chipaque',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68179',
        CityName: 'Chipatá',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15176',
        CityName: 'Chiquinquirá',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15232',
        CityName: 'Chíquiza',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20178',
        CityName: 'Chiriguaná',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15180',
        CityName: 'Chiscas',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15183',
        CityName: 'Chita',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54174',
        CityName: 'Chitagá',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15185',
        CityName: 'Chitaraque',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15187',
        CityName: 'Chivatá',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15236',
        CityName: 'Chivor',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25181',
        CityName: 'Choachí',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25183',
        CityName: 'Chocontá',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13188',
        CityName: 'Cicuco',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47189',
        CityName: 'Ciénaga',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23189',
        CityName: 'Ciénaga De Oro',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15189',
        CityName: 'Ciénega',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68190',
        CityName: 'Cimitarra',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '63190',
        CityName: 'Circasia',
        StateCode: '63',
        StateName: 'Quindio',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05190',
        CityName: 'Cisneros',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05101',
        CityName: 'Ciudad Bolívar',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13222',
        CityName: 'Clemencia',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05197',
        CityName: 'Cocorná',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73200',
        CityName: 'Coello',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25200',
        CityName: 'Cogua',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41206',
        CityName: 'Colombia',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52203',
        CityName: 'Colón',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '86219',
        CityName: 'Colón',
        StateCode: '86',
        StateName: 'Putumayo',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70204',
        CityName: 'Coloso',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15204',
        CityName: 'Cómbita',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05206',
        CityName: 'Concepción',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68207',
        CityName: 'Concepción',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05209',
        CityName: 'Concordia',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47205',
        CityName: 'Concordia',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27205',
        CityName: 'Condoto',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68209',
        CityName: 'Confines',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52207',
        CityName: 'Consaca',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52210',
        CityName: 'Contadero',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68211',
        CityName: 'Contratación',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54206',
        CityName: 'Convención',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05212',
        CityName: 'Copacabana',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15212',
        CityName: 'Coper',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13212',
        CityName: 'Córdoba',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52215',
        CityName: 'Córdoba',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '63212',
        CityName: 'Córdoba',
        StateCode: '63',
        StateName: 'Quindio',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19212',
        CityName: 'Corinto',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68217',
        CityName: 'Coromoro',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70215',
        CityName: 'Corozal',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15215',
        CityName: 'Corrales',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25214',
        CityName: 'Cota',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23300',
        CityName: 'Cotorra',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15218',
        CityName: 'Covarachía',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70221',
        CityName: 'Coveñas',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73217',
        CityName: 'Coyaima',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '81220',
        CityName: 'Cravo Norte',
        StateCode: '81',
        StateName: 'Arauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52224',
        CityName: 'Cuaspud',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15223',
        CityName: 'Cubará',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50223',
        CityName: 'Cubarral',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15224',
        CityName: 'Cucaita',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25224',
        CityName: 'Cucunubá',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54001',
        CityName: 'Cúcuta',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54223',
        CityName: 'Cucutilla',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15226',
        CityName: 'Cuítiva',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50226',
        CityName: 'Cumaral',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '99773',
        CityName: 'Cumaribo',
        StateCode: '99',
        StateName: 'Vichada',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52227',
        CityName: 'Cumbal',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52233',
        CityName: 'Cumbitara',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73226',
        CityName: 'Cunday',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '18205',
        CityName: 'Curillo',
        StateCode: '18',
        StateName: 'Caquetá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68229',
        CityName: 'Curití',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20228',
        CityName: 'Curumaní',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05234',
        CityName: 'Dabeiba',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76233',
        CityName: 'Dagua',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '44090',
        CityName: 'Dibulla',
        StateCode: '44',
        StateName: 'La Guajira',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '44098',
        CityName: 'Distracción',
        StateCode: '44',
        StateName: 'La Guajira',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73236',
        CityName: 'Dolores',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05237',
        CityName: 'Don Matías',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '66170',
        CityName: 'Dosquebradas',
        StateCode: '66',
        StateName: 'Risaralda',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15238',
        CityName: 'Duitama',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54239',
        CityName: 'Durania',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05240',
        CityName: 'Ebéjico',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76243',
        CityName: 'El Águila',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05250',
        CityName: 'El Bagre',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47245',
        CityName: 'El Banco',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76246',
        CityName: 'El Cairo',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50245',
        CityName: 'El Calvario',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27135',
        CityName: 'El Cantón Del San Pablo',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54245',
        CityName: 'El Carmen',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27245',
        CityName: 'El Carmen De Atrato',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13244',
        CityName: 'El Carmen De Bolívar',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68235',
        CityName: 'El Carmen De Chucurí',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05148',
        CityName: 'El Carmen De Viboral',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50251',
        CityName: 'El Castillo',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76248',
        CityName: 'El Cerrito',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52250',
        CityName: 'El Charco',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15244',
        CityName: 'El Cocuy',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25245',
        CityName: 'El Colegio',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20238',
        CityName: 'El Copey',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '18247',
        CityName: 'El Doncello',
        StateCode: '18',
        StateName: 'Caquetá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50270',
        CityName: 'El Dorado',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76250',
        CityName: 'El Dovio',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '91263',
        CityName: 'El Encanto',
        StateCode: '91',
        StateName: 'Amazonas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15248',
        CityName: 'El Espino',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68245',
        CityName: 'El Guacamayo',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13248',
        CityName: 'El Guamo',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27250',
        CityName: 'El Litoral Del San Juan',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '44110',
        CityName: 'El Molino',
        StateCode: '44',
        StateName: 'La Guajira',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20250',
        CityName: 'El Paso',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '18256',
        CityName: 'El Paujil',
        StateCode: '18',
        StateName: 'Caquetá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52254',
        CityName: 'El Peñol',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13268',
        CityName: 'El Peñón',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25258',
        CityName: 'El Peñón',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68250',
        CityName: 'El Peñón',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47258',
        CityName: 'El Piñon',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68255',
        CityName: 'El Playón',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47268',
        CityName: 'El Retén',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '95025',
        CityName: 'El Retorno',
        StateCode: '95',
        StateName: 'Guaviare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70233',
        CityName: 'El Roble',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25260',
        CityName: 'El Rosal',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52256',
        CityName: 'El Rosario',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05697',
        CityName: 'El Santuario',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52258',
        CityName: 'El Tablón De Gómez',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19256',
        CityName: 'El Tambo',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52260',
        CityName: 'El Tambo',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54250',
        CityName: 'El Tarra',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54261',
        CityName: 'El Zulia',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41244',
        CityName: 'Elías',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68264',
        CityName: 'Encino',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68266',
        CityName: 'Enciso',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05264',
        CityName: 'Entrerrios',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05266',
        CityName: 'Envigado',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73268',
        CityName: 'Espinal',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25269',
        CityName: 'Facatativá',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73270',
        CityName: 'Falan',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17272',
        CityName: 'Filadelfia',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '63272',
        CityName: 'Filandia',
        StateCode: '63',
        StateName: 'Quindio',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15272',
        CityName: 'Firavitoba',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73275',
        CityName: 'Flandes',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '18001',
        CityName: 'Florencia',
        StateCode: '18',
        StateName: 'Caquetá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19290',
        CityName: 'Florencia',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15276',
        CityName: 'Floresta',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68271',
        CityName: 'Florián',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76275',
        CityName: 'Florida',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68276',
        CityName: 'Floridablanca',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25279',
        CityName: 'Fomeque',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '44279',
        CityName: 'Fonseca',
        StateCode: '44',
        StateName: 'La Guajira',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '81300',
        CityName: 'Fortul',
        StateCode: '81',
        StateName: 'Arauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25281',
        CityName: 'Fosca',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52520',
        CityName: 'Francisco Pizarro',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05282',
        CityName: 'Fredonia',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73283',
        CityName: 'Fresno',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05284',
        CityName: 'Frontino',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50287',
        CityName: 'Fuente De Oro',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47288',
        CityName: 'Fundación',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52287',
        CityName: 'Funes',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25286',
        CityName: 'Funza',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25288',
        CityName: 'Fúquene',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25290',
        CityName: 'Fusagasugá',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25293',
        CityName: 'Gachala',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25295',
        CityName: 'Gachancipá',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15293',
        CityName: 'Gachantivá',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25297',
        CityName: 'Gachetá',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68296',
        CityName: 'Galán',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08296',
        CityName: 'Galapa',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70235',
        CityName: 'Galeras',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25299',
        CityName: 'Gama',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20295',
        CityName: 'Gamarra',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68298',
        CityName: 'Gambita',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15296',
        CityName: 'Gameza',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15299',
        CityName: 'Garagoa',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41298',
        CityName: 'Garzón',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '63302',
        CityName: 'Génova',
        StateCode: '63',
        StateName: 'Quindio',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41306',
        CityName: 'Gigante',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76306',
        CityName: 'Ginebra',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05306',
        CityName: 'Giraldo',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25307',
        CityName: 'Girardot',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05308',
        CityName: 'Girardota',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68307',
        CityName: 'Girón',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05310',
        CityName: 'Gómez Plata',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20310',
        CityName: 'González',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54313',
        CityName: 'Gramalote',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05313',
        CityName: 'Granada',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25312',
        CityName: 'Granada',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50313',
        CityName: 'Granada',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68318',
        CityName: 'Guaca',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15317',
        CityName: 'Guacamayas',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76318',
        CityName: 'Guacarí',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25317',
        CityName: 'Guachetá',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52317',
        CityName: 'Guachucal',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76111',
        CityName: 'Guadalajara De Buga',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05315',
        CityName: 'Guadalupe',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41319',
        CityName: 'Guadalupe',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68320',
        CityName: 'Guadalupe',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25320',
        CityName: 'Guaduas',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52320',
        CityName: 'Guaitarilla',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52323',
        CityName: 'Gualmatán',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47318',
        CityName: 'Guamal',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50318',
        CityName: 'Guamal',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73319',
        CityName: 'Guamo',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19318',
        CityName: 'Guapi',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68322',
        CityName: 'Guapotá',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70265',
        CityName: 'Guaranda',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05318',
        CityName: 'Guarne',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25322',
        CityName: 'Guasca',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05321',
        CityName: 'Guatape',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25324',
        CityName: 'Guataquí',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25326',
        CityName: 'Guatavita',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15322',
        CityName: 'Guateque',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '66318',
        CityName: 'Guática',
        StateCode: '66',
        StateName: 'Risaralda',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68324',
        CityName: 'Guavatá',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25328',
        CityName: 'Guayabal De Siquima',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25335',
        CityName: 'Guayabetal',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15325',
        CityName: 'Guayatá',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68327',
        CityName: 'Güepsa',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15332',
        CityName: 'Güicán',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25339',
        CityName: 'Gutiérrez',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54344',
        CityName: 'Hacarí',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13300',
        CityName: 'Hatillo De Loba',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68344',
        CityName: 'Hato',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '85125',
        CityName: 'Hato Corozal',
        StateCode: '85',
        StateName: 'Casanare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '44378',
        CityName: 'Hatonuevo',
        StateCode: '44',
        StateName: 'La Guajira',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05347',
        CityName: 'Heliconia',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54347',
        CityName: 'Herrán',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73347',
        CityName: 'Herveo',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05353',
        CityName: 'Hispania',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41349',
        CityName: 'Hobo',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73349',
        CityName: 'Honda',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73001',
        CityName: 'Ibagué',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73352',
        CityName: 'Icononzo',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52352',
        CityName: 'Iles',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52354',
        CityName: 'Imués',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '94001',
        CityName: 'Inírida',
        StateCode: '94',
        StateName: 'Guainía',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19355',
        CityName: 'Inzá',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52356',
        CityName: 'Ipiales',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41357',
        CityName: 'Iquira',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41359',
        CityName: 'Isnos',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27361',
        CityName: 'Istmina',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05360',
        CityName: 'Itagui',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05361',
        CityName: 'Ituango',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15362',
        CityName: 'Iza',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19364',
        CityName: 'Jambaló',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76364',
        CityName: 'Jamundí',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05364',
        CityName: 'Jardín',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15367',
        CityName: 'Jenesano',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05368',
        CityName: 'Jericó',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15368',
        CityName: 'Jericó',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25368',
        CityName: 'Jerusalén',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68368',
        CityName: 'Jesús María',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68370',
        CityName: 'Jordán',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08372',
        CityName: 'Juan De Acosta',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25372',
        CityName: 'Junín',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27372',
        CityName: 'Juradó',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23350',
        CityName: 'La Apartada',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41378',
        CityName: 'La Argentina',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68377',
        CityName: 'La Belleza',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25377',
        CityName: 'La Calera',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15380',
        CityName: 'La Capilla',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05376',
        CityName: 'La Ceja',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '66383',
        CityName: 'La Celia',
        StateCode: '66',
        StateName: 'Risaralda',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '91405',
        CityName: 'La Chorrera',
        StateCode: '91',
        StateName: 'Amazonas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52378',
        CityName: 'La Cruz',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76377',
        CityName: 'La Cumbre',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17380',
        CityName: 'La Dorada',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54385',
        CityName: 'La Esperanza',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05380',
        CityName: 'La Estrella',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52381',
        CityName: 'La Florida',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20383',
        CityName: 'La Gloria',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '94885',
        CityName: 'La Guadalupe',
        StateCode: '94',
        StateName: 'Guainía',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20400',
        CityName: 'La Jagua De Ibirico',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '44420',
        CityName: 'La Jagua Del Pilar',
        StateCode: '44',
        StateName: 'La Guajira',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52385',
        CityName: 'La Llanada',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50350',
        CityName: 'La Macarena',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17388',
        CityName: 'La Merced',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25386',
        CityName: 'La Mesa',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '18410',
        CityName: 'La Montañita',
        StateCode: '18',
        StateName: 'Caquetá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25394',
        CityName: 'La Palma',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20621',
        CityName: 'La Paz',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68397',
        CityName: 'La Paz',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '91407',
        CityName: 'La Pedrera',
        StateCode: '91',
        StateName: 'Amazonas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25398',
        CityName: 'La Peña',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05390',
        CityName: 'La Pintada',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41396',
        CityName: 'La Plata',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54398',
        CityName: 'La Playa',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '99524',
        CityName: 'La Primavera',
        StateCode: '99',
        StateName: 'Vichada',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '85136',
        CityName: 'La Salina',
        StateCode: '85',
        StateName: 'Casanare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19392',
        CityName: 'La Sierra',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '63401',
        CityName: 'La Tebaida',
        StateCode: '63',
        StateName: 'Quindio',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52390',
        CityName: 'La Tola',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05400',
        CityName: 'La Unión',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52399',
        CityName: 'La Unión',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70400',
        CityName: 'La Unión',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76400',
        CityName: 'La Unión',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15403',
        CityName: 'La Uvita',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19397',
        CityName: 'La Vega',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25402',
        CityName: 'La Vega',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15401',
        CityName: 'La Victoria',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76403',
        CityName: 'La Victoria',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '91430',
        CityName: 'La Victoria',
        StateCode: '91',
        StateName: 'Amazonas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '66400',
        CityName: 'La Virginia',
        StateCode: '66',
        StateName: 'Risaralda',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54377',
        CityName: 'Labateca',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15377',
        CityName: 'Labranzagrande',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68385',
        CityName: 'Landázuri',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68406',
        CityName: 'Lebríja',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '86573',
        CityName: 'Leguízamo',
        StateCode: '86',
        StateName: 'Putumayo',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52405',
        CityName: 'Leiva',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50400',
        CityName: 'Lejanías',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25407',
        CityName: 'Lenguazaque',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73408',
        CityName: 'Lérida',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '91001',
        CityName: 'Leticia',
        StateCode: '91',
        StateName: 'Amazonas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73411',
        CityName: 'Líbano',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05411',
        CityName: 'Liborina',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52411',
        CityName: 'Linares',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27413',
        CityName: 'Lloró',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19418',
        CityName: 'López',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23417',
        CityName: 'Lorica',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52418',
        CityName: 'Los Andes',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23419',
        CityName: 'Los Córdobas',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70418',
        CityName: 'Los Palmitos',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54405',
        CityName: 'Los Patios',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68418',
        CityName: 'Los Santos',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54418',
        CityName: 'Lourdes',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08421',
        CityName: 'Luruaco',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15425',
        CityName: 'Macanal',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68425',
        CityName: 'Macaravita',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05425',
        CityName: 'Maceo',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25426',
        CityName: 'Macheta',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25430',
        CityName: 'Madrid',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13430',
        CityName: 'Magangué',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52427',
        CityName: 'Magüi',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13433',
        CityName: 'Mahates',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '44430',
        CityName: 'Maicao',
        StateCode: '44',
        StateName: 'La Guajira',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70429',
        CityName: 'Majagual',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68432',
        CityName: 'Málaga',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08433',
        CityName: 'Malambo',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52435',
        CityName: 'Mallama',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08436',
        CityName: 'Manatí',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20443',
        CityName: 'Manaure',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '44560',
        CityName: 'Manaure',
        StateCode: '44',
        StateName: 'La Guajira',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '85139',
        CityName: 'Maní',
        StateCode: '85',
        StateName: 'Casanare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17001',
        CityName: 'Manizales',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25436',
        CityName: 'Manta',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17433',
        CityName: 'Manzanares',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50325',
        CityName: 'Mapiripán',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '94663',
        CityName: 'Mapiripana',
        StateCode: '94',
        StateName: 'Guainía',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13440',
        CityName: 'Margarita',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13442',
        CityName: 'María La Baja',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05440',
        CityName: 'Marinilla',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15442',
        CityName: 'Maripí',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73443',
        CityName: 'Mariquita',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17442',
        CityName: 'Marmato',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17444',
        CityName: 'Marquetalia',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '66440',
        CityName: 'Marsella',
        StateCode: '66',
        StateName: 'Risaralda',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17446',
        CityName: 'Marulanda',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68444',
        CityName: 'Matanza',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05001',
        CityName: 'Medellín',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25438',
        CityName: 'Medina',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27425',
        CityName: 'Medio Atrato',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27430',
        CityName: 'Medio Baudó',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27450',
        CityName: 'Medio San Juan',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73449',
        CityName: 'Melgar',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19450',
        CityName: 'Mercaderes',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50330',
        CityName: 'Mesetas',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '18460',
        CityName: 'Milán',
        StateCode: '18',
        StateName: 'Caquetá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15455',
        CityName: 'Miraflores',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '95200',
        CityName: 'Miraflores',
        StateCode: '95',
        StateName: 'Guaviare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19455',
        CityName: 'Miranda',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '91460',
        CityName: 'Miriti - Paraná',
        StateCode: '91',
        StateName: 'Amazonas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '66456',
        CityName: 'Mistrató',
        StateCode: '66',
        StateName: 'Risaralda',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '97001',
        CityName: 'Mitú',
        StateCode: '97',
        StateName: 'Vaupés',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '86001',
        CityName: 'Mocoa',
        StateCode: '86',
        StateName: 'Putumayo',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68464',
        CityName: 'Mogotes',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68468',
        CityName: 'Molagavita',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23464',
        CityName: 'Momil',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13468',
        CityName: 'Mompós',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15464',
        CityName: 'Mongua',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15466',
        CityName: 'Monguí',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15469',
        CityName: 'Moniquirá',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05467',
        CityName: 'Montebello',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13458',
        CityName: 'Montecristo',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23466',
        CityName: 'Montelíbano',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '63470',
        CityName: 'Montenegro',
        StateCode: '63',
        StateName: 'Quindio',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23001',
        CityName: 'Montería',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '85162',
        CityName: 'Monterrey',
        StateCode: '85',
        StateName: 'Casanare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23500',
        CityName: 'Moñitos',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13473',
        CityName: 'Morales',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19473',
        CityName: 'Morales',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '18479',
        CityName: 'Morelia',
        StateCode: '18',
        StateName: 'Caquetá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '94888',
        CityName: 'Morichal',
        StateCode: '94',
        StateName: 'Guainía',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70473',
        CityName: 'Morroa',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25473',
        CityName: 'Mosquera',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52473',
        CityName: 'Mosquera',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15476',
        CityName: 'Motavita',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73461',
        CityName: 'Murillo',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05475',
        CityName: 'Murindó',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05480',
        CityName: 'Mutatá',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54480',
        CityName: 'Mutiscua',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15480',
        CityName: 'Muzo',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05483',
        CityName: 'Nariño',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25483',
        CityName: 'Nariño',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52480',
        CityName: 'Nariño',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41483',
        CityName: 'Nátaga',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73483',
        CityName: 'Natagaima',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05495',
        CityName: 'Nechí',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05490',
        CityName: 'Necoclí',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17486',
        CityName: 'Neira',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41001',
        CityName: 'Neiva',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25486',
        CityName: 'Nemocón',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25488',
        CityName: 'Nilo',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25489',
        CityName: 'Nimaima',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15491',
        CityName: 'Nobsa',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25491',
        CityName: 'Nocaima',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17495',
        CityName: 'Norcasia',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27491',
        CityName: 'Nóvita',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47460',
        CityName: 'Nueva Granada',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15494',
        CityName: 'Nuevo Colón',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '85225',
        CityName: 'Nunchía',
        StateCode: '85',
        StateName: 'Casanare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27495',
        CityName: 'Nuquí',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76497',
        CityName: 'Obando',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68498',
        CityName: 'Ocamonte',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54498',
        CityName: 'Ocaña',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68500',
        CityName: 'Oiba',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15500',
        CityName: 'Oicatá',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05501',
        CityName: 'Olaya',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52490',
        CityName: 'Olaya Herrera',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68502',
        CityName: 'Onzaga',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41503',
        CityName: 'Oporapa',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '86320',
        CityName: 'Orito',
        StateCode: '86',
        StateName: 'Putumayo',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '85230',
        CityName: 'Orocué',
        StateCode: '85',
        StateName: 'Casanare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73504',
        CityName: 'Ortega',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52506',
        CityName: 'Ospina',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15507',
        CityName: 'Otanche',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70508',
        CityName: 'Ovejas',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15511',
        CityName: 'Pachavita',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25513',
        CityName: 'Pacho',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '97511',
        CityName: 'Pacoa',
        StateCode: '97',
        StateName: 'Vaupés',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17513',
        CityName: 'Pácora',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19513',
        CityName: 'Padilla',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19517',
        CityName: 'Paez',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15514',
        CityName: 'Páez',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41518',
        CityName: 'Paicol',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20517',
        CityName: 'Pailitas',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25518',
        CityName: 'Paime',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15516',
        CityName: 'Paipa',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15518',
        CityName: 'Pajarito',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41524',
        CityName: 'Palermo',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17524',
        CityName: 'Palestina',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41530',
        CityName: 'Palestina',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68522',
        CityName: 'Palmar',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08520',
        CityName: 'Palmar De Varela',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68524',
        CityName: 'Palmas Del Socorro',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76520',
        CityName: 'Palmira',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70523',
        CityName: 'Palmito',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73520',
        CityName: 'Palocabildo',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54518',
        CityName: 'Pamplona',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54520',
        CityName: 'Pamplonita',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '94887',
        CityName: 'Pana Pana',
        StateCode: '94',
        StateName: 'Guainía',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25524',
        CityName: 'Pandi',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15522',
        CityName: 'Panqueba',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '97777',
        CityName: 'Papunaua',
        StateCode: '97',
        StateName: 'Vaupés',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68533',
        CityName: 'Páramo',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25530',
        CityName: 'Paratebueno',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25535',
        CityName: 'Pasca',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52001',
        CityName: 'Pasto',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19532',
        CityName: 'Patía',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15531',
        CityName: 'Pauna',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15533',
        CityName: 'Paya',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '85250',
        CityName: 'Paz De Ariporo',
        StateCode: '85',
        StateName: 'Casanare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15537',
        CityName: 'Paz De Río',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47541',
        CityName: 'Pedraza',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20550',
        CityName: 'Pelaya',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17541',
        CityName: 'Pensilvania',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05541',
        CityName: 'Peñol',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05543',
        CityName: 'Peque',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '66001',
        CityName: 'Pereira',
        StateCode: '66',
        StateName: 'Risaralda',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15542',
        CityName: 'Pesca',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19533',
        CityName: 'Piamonte',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68547',
        CityName: 'Piedecuesta',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73547',
        CityName: 'Piedras',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19548',
        CityName: 'Piendamó',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '63548',
        CityName: 'Pijao',
        StateCode: '63',
        StateName: 'Quindio',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47545',
        CityName: 'Pijiño Del Carmen',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68549',
        CityName: 'Pinchote',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13549',
        CityName: 'Pinillos',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08549',
        CityName: 'Piojó',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15550',
        CityName: 'Pisba',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41548',
        CityName: 'Pital',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41551',
        CityName: 'Pitalito',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47551',
        CityName: 'Pivijay',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73555',
        CityName: 'Planadas',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23555',
        CityName: 'Planeta Rica',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47555',
        CityName: 'Plato',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52540',
        CityName: 'Policarpa',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08558',
        CityName: 'Polonuevo',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08560',
        CityName: 'Ponedera',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19001',
        CityName: 'Popayán',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '85263',
        CityName: 'Pore',
        StateCode: '85',
        StateName: 'Casanare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52560',
        CityName: 'Potosí',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76563',
        CityName: 'Pradera',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73563',
        CityName: 'Prado',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52565',
        CityName: 'Providencia',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '88564',
        CityName: 'Providencia',
        StateCode: '88',
        StateName: 'Archipiélago de San Andrés, Providencia y Santa Ca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20570',
        CityName: 'Pueblo Bello',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23570',
        CityName: 'Pueblo Nuevo',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '66572',
        CityName: 'Pueblo Rico',
        StateCode: '66',
        StateName: 'Risaralda',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05576',
        CityName: 'Pueblorrico',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47570',
        CityName: 'Puebloviejo',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68572',
        CityName: 'Puente Nacional',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52573',
        CityName: 'Puerres',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '91530',
        CityName: 'Puerto Alegría',
        StateCode: '91',
        StateName: 'Amazonas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '91536',
        CityName: 'Puerto Arica',
        StateCode: '91',
        StateName: 'Amazonas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '86568',
        CityName: 'Puerto Asís',
        StateCode: '86',
        StateName: 'Putumayo',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05579',
        CityName: 'Puerto Berrío',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15572',
        CityName: 'Puerto Boyacá',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '86569',
        CityName: 'Puerto Caicedo',
        StateCode: '86',
        StateName: 'Putumayo',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '99001',
        CityName: 'Puerto Carreño',
        StateCode: '99',
        StateName: 'Vichada',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08573',
        CityName: 'Puerto Colombia',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '94884',
        CityName: 'Puerto Colombia',
        StateCode: '94',
        StateName: 'Guainía',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50450',
        CityName: 'Puerto Concordia',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23574',
        CityName: 'Puerto Escondido',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50568',
        CityName: 'Puerto Gaitán',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '86571',
        CityName: 'Puerto Guzmán',
        StateCode: '86',
        StateName: 'Putumayo',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23580',
        CityName: 'Puerto Libertador',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50577',
        CityName: 'Puerto Lleras',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50573',
        CityName: 'Puerto López',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05585',
        CityName: 'Puerto Nare',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '91540',
        CityName: 'Puerto Nariño',
        StateCode: '91',
        StateName: 'Amazonas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68573',
        CityName: 'Puerto Parra',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '18592',
        CityName: 'Puerto Rico',
        StateCode: '18',
        StateName: 'Caquetá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50590',
        CityName: 'Puerto Rico',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '81591',
        CityName: 'Puerto Rondón',
        StateCode: '81',
        StateName: 'Arauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25572',
        CityName: 'Puerto Salgar',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54553',
        CityName: 'Puerto Santander',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '91669',
        CityName: 'Puerto Santander',
        StateCode: '91',
        StateName: 'Amazonas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19573',
        CityName: 'Puerto Tejada',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05591',
        CityName: 'Puerto Triunfo',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68575',
        CityName: 'Puerto Wilches',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25580',
        CityName: 'Pulí',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52585',
        CityName: 'Pupiales',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19585',
        CityName: 'Puracé',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73585',
        CityName: 'Purificación',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23586',
        CityName: 'Purísima',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25592',
        CityName: 'Quebradanegra',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25594',
        CityName: 'Quetame',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27001',
        CityName: 'Quibdó',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '63594',
        CityName: 'Quimbaya',
        StateCode: '63',
        StateName: 'Quindio',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '66594',
        CityName: 'Quinchía',
        StateCode: '66',
        StateName: 'Risaralda',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15580',
        CityName: 'Quípama',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25596',
        CityName: 'Quipile',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54599',
        CityName: 'Ragonvalia',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15599',
        CityName: 'Ramiriquí',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15600',
        CityName: 'Ráquira',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '85279',
        CityName: 'Recetor',
        StateCode: '85',
        StateName: 'Casanare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13580',
        CityName: 'Regidor',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05604',
        CityName: 'Remedios',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47605',
        CityName: 'Remolino',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08606',
        CityName: 'Repelón',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50606',
        CityName: 'Restrepo',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76606',
        CityName: 'Restrepo',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05607',
        CityName: 'Retiro',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25612',
        CityName: 'Ricaurte',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52612',
        CityName: 'Ricaurte',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20614',
        CityName: 'Río De Oro',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27580',
        CityName: 'Río Iro',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27600',
        CityName: 'Río Quito',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13600',
        CityName: 'Río Viejo',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73616',
        CityName: 'Rioblanco',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76616',
        CityName: 'Riofrío',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '44001',
        CityName: 'Riohacha',
        StateCode: '44',
        StateName: 'La Guajira',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05615',
        CityName: 'Rionegro',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68615',
        CityName: 'Rionegro',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17614',
        CityName: 'Riosucio',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27615',
        CityName: 'Riosucio',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17616',
        CityName: 'Risaralda',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41615',
        CityName: 'Rivera',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52621',
        CityName: 'Roberto Payán',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76622',
        CityName: 'Roldanillo',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73622',
        CityName: 'Roncesvalles',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15621',
        CityName: 'Rondón',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19622',
        CityName: 'Rosas',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73624',
        CityName: 'Rovira',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68655',
        CityName: 'Sabana De Torres',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08634',
        CityName: 'Sabanagrande',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05628',
        CityName: 'Sabanalarga',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08638',
        CityName: 'Sabanalarga',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '85300',
        CityName: 'Sabanalarga',
        StateCode: '85',
        StateName: 'Casanare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47660',
        CityName: 'Sabanas De San Angel',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05631',
        CityName: 'Sabaneta',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15632',
        CityName: 'Saboyá',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '85315',
        CityName: 'Sácama',
        StateCode: '85',
        StateName: 'Casanare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15638',
        CityName: 'Sáchica',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23660',
        CityName: 'Sahagún',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41660',
        CityName: 'Saladoblanco',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17653',
        CityName: 'Salamina',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47675',
        CityName: 'Salamina',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54660',
        CityName: 'Salazar',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73671',
        CityName: 'Saldaña',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '63690',
        CityName: 'Salento',
        StateCode: '63',
        StateName: 'Quindio',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05642',
        CityName: 'Salgar',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15646',
        CityName: 'Samacá',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17662',
        CityName: 'Samaná',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52678',
        CityName: 'Samaniego',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70670',
        CityName: 'Sampués',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41668',
        CityName: 'San Agustín',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20710',
        CityName: 'San Alberto',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05647',
        CityName: 'San Andrés',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68669',
        CityName: 'San Andrés',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '88001',
        CityName: 'San Andrés',
        StateCode: '88',
        StateName: 'Archipiélago de San Andrés, Providencia y Santa Ca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23670',
        CityName: 'San Andrés Sotavento',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23672',
        CityName: 'San Antero',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73675',
        CityName: 'San Antonio',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25645',
        CityName: 'San Antonio Del Tequendama',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68673',
        CityName: 'San Benito',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70678',
        CityName: 'San Benito Abad',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25649',
        CityName: 'San Bernardo',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52685',
        CityName: 'San Bernardo',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23675',
        CityName: 'San Bernardo Del Viento',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54670',
        CityName: 'San Calixto',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05649',
        CityName: 'San Carlos',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23678',
        CityName: 'San Carlos',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50680',
        CityName: 'San Carlos De Guaroa',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25653',
        CityName: 'San Cayetano',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54673',
        CityName: 'San Cayetano',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13620',
        CityName: 'San Cristóbal',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20750',
        CityName: 'San Diego',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15660',
        CityName: 'San Eduardo',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13647',
        CityName: 'San Estanislao',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '94883',
        CityName: 'San Felipe',
        StateCode: '94',
        StateName: 'Guainía',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13650',
        CityName: 'San Fernando',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05652',
        CityName: 'San Francisco',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25658',
        CityName: 'San Francisco',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '86755',
        CityName: 'San Francisco',
        StateCode: '86',
        StateName: 'Putumayo',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68679',
        CityName: 'San Gil',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13654',
        CityName: 'San Jacinto',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13655',
        CityName: 'San Jacinto Del Cauca',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05656',
        CityName: 'San Jerónimo',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68682',
        CityName: 'San Joaquín',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17665',
        CityName: 'San José',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05658',
        CityName: 'San José De La Montaña',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68684',
        CityName: 'San José De Miranda',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15664',
        CityName: 'San José De Pare',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '18610',
        CityName: 'San José Del Fragua',
        StateCode: '18',
        StateName: 'Caquetá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '95001',
        CityName: 'San José Del Guaviare',
        StateCode: '95',
        StateName: 'Guaviare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27660',
        CityName: 'San José Del Palmar',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50683',
        CityName: 'San Juan De Arama',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70702',
        CityName: 'San Juan De Betulia',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25662',
        CityName: 'San Juan De Río Seco',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05659',
        CityName: 'San Juan De Urabá',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '44650',
        CityName: 'San Juan Del Cesar',
        StateCode: '44',
        StateName: 'La Guajira',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13657',
        CityName: 'San Juan Nepomuceno',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50686',
        CityName: 'San Juanito',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52687',
        CityName: 'San Lorenzo',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05660',
        CityName: 'San Luis',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73678',
        CityName: 'San Luis',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15667',
        CityName: 'San Luis De Gaceno',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '85325',
        CityName: 'San Luis De Palenque',
        StateCode: '85',
        StateName: 'Casanare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70708',
        CityName: 'San Marcos',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20770',
        CityName: 'San Martín',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50689',
        CityName: 'San Martín',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13667',
        CityName: 'San Martín De Loba',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15673',
        CityName: 'San Mateo',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68686',
        CityName: 'San Miguel',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '86757',
        CityName: 'San Miguel',
        StateCode: '86',
        StateName: 'Putumayo',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15676',
        CityName: 'San Miguel De Sema',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70713',
        CityName: 'San Onofre',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13670',
        CityName: 'San Pablo',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52693',
        CityName: 'San Pablo',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15681',
        CityName: 'San Pablo De Borbur',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05664',
        CityName: 'San Pedro',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70717',
        CityName: 'San Pedro',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76670',
        CityName: 'San Pedro',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52694',
        CityName: 'San Pedro De Cartago',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05665',
        CityName: 'San Pedro De Uraba',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23686',
        CityName: 'San Pelayo',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05667',
        CityName: 'San Rafael',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05670',
        CityName: 'San Roque',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19693',
        CityName: 'San Sebastián',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47692',
        CityName: 'San Sebastián De Buenavista',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05674',
        CityName: 'San Vicente',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68689',
        CityName: 'San Vicente De Chucurí',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '18753',
        CityName: 'San Vicente Del Caguán',
        StateCode: '18',
        StateName: 'Caquetá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47703',
        CityName: 'San Zenón',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52683',
        CityName: 'Sandoná',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47707',
        CityName: 'Santa Ana',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05679',
        CityName: 'Santa Bárbara',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52696',
        CityName: 'Santa Bárbara',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68705',
        CityName: 'Santa Bárbara',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47720',
        CityName: 'Santa Bárbara De Pinto',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13673',
        CityName: 'Santa Catalina',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68720',
        CityName: 'Santa Helena Del Opón',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73686',
        CityName: 'Santa Isabel',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08675',
        CityName: 'Santa Lucía',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15690',
        CityName: 'Santa María',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41676',
        CityName: 'Santa María',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47001',
        CityName: 'Santa Marta',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13683',
        CityName: 'Santa Rosa',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19701',
        CityName: 'Santa Rosa',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '66682',
        CityName: 'Santa Rosa De Cabal',
        StateCode: '66',
        StateName: 'Risaralda',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05686',
        CityName: 'Santa Rosa De Osos',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15693',
        CityName: 'Santa Rosa De Viterbo',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13688',
        CityName: 'Santa Rosa Del Sur',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '99624',
        CityName: 'Santa Rosalía',
        StateCode: '99',
        StateName: 'Vichada',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15696',
        CityName: 'Santa Sofía',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52699',
        CityName: 'Santacruz',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05042',
        CityName: 'Santafé De Antioquia',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15686',
        CityName: 'Santana',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19698',
        CityName: 'Santander De Quilichao',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54680',
        CityName: 'Santiago',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '86760',
        CityName: 'Santiago',
        StateCode: '86',
        StateName: 'Putumayo',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70820',
        CityName: 'Santiago De Tolú',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05690',
        CityName: 'Santo Domingo',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08685',
        CityName: 'Santo Tomás',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '66687',
        CityName: 'Santuario',
        StateCode: '66',
        StateName: 'Risaralda',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52720',
        CityName: 'Sapuyes',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '81736',
        CityName: 'Saravena',
        StateCode: '81',
        StateName: 'Arauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54720',
        CityName: 'Sardinata',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25718',
        CityName: 'Sasaima',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15720',
        CityName: 'Sativanorte',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15723',
        CityName: 'Sativasur',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05736',
        CityName: 'Segovia',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25736',
        CityName: 'Sesquilé',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76736',
        CityName: 'Sevilla',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15740',
        CityName: 'Siachoque',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25740',
        CityName: 'Sibaté',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '86749',
        CityName: 'Sibundoy',
        StateCode: '86',
        StateName: 'Putumayo',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54743',
        CityName: 'Silos',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25743',
        CityName: 'Silvania',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19743',
        CityName: 'Silvia',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68745',
        CityName: 'Simacota',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25745',
        CityName: 'Simijaca',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13744',
        CityName: 'Simití',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70742',
        CityName: 'Sincé',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70001',
        CityName: 'Sincelejo',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27745',
        CityName: 'Sipí',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47745',
        CityName: 'Sitionuevo',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25754',
        CityName: 'Soacha',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15753',
        CityName: 'Soatá',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15757',
        CityName: 'Socha',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68755',
        CityName: 'Socorro',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15755',
        CityName: 'Socotá',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15759',
        CityName: 'Sogamoso',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '18756',
        CityName: 'Solano',
        StateCode: '18',
        StateName: 'Caquetá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08758',
        CityName: 'Soledad',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '18785',
        CityName: 'Solita',
        StateCode: '18',
        StateName: 'Caquetá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15761',
        CityName: 'Somondoco',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05756',
        CityName: 'Sonson',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05761',
        CityName: 'Sopetrán',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13760',
        CityName: 'Soplaviento',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25758',
        CityName: 'Sopó',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15762',
        CityName: 'Sora',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15764',
        CityName: 'Soracá',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15763',
        CityName: 'Sotaquirá',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19760',
        CityName: 'Sotara',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68770',
        CityName: 'Suaita',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08770',
        CityName: 'Suan',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19780',
        CityName: 'Suárez',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73770',
        CityName: 'Suárez',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41770',
        CityName: 'Suaza',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25769',
        CityName: 'Subachoque',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19785',
        CityName: 'Sucre',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68773',
        CityName: 'Sucre',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70771',
        CityName: 'Sucre',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25772',
        CityName: 'Suesca',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25777',
        CityName: 'Supatá',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17777',
        CityName: 'Supía',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68780',
        CityName: 'Suratá',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25779',
        CityName: 'Susa',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15774',
        CityName: 'Susacón',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15776',
        CityName: 'Sutamarchán',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25781',
        CityName: 'Sutatausa',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15778',
        CityName: 'Sutatenza',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25785',
        CityName: 'Tabio',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27787',
        CityName: 'Tadó',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13780',
        CityName: 'Talaigua Nuevo',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20787',
        CityName: 'Tamalameque',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '85400',
        CityName: 'Támara',
        StateCode: '85',
        StateName: 'Casanare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '81794',
        CityName: 'Tame',
        StateCode: '81',
        StateName: 'Arauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05789',
        CityName: 'Támesis',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52786',
        CityName: 'Taminango',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52788',
        CityName: 'Tangua',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '97666',
        CityName: 'Taraira',
        StateCode: '97',
        StateName: 'Vaupés',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '91798',
        CityName: 'Tarapacá',
        StateCode: '91',
        StateName: 'Amazonas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05790',
        CityName: 'Tarazá',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41791',
        CityName: 'Tarqui',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05792',
        CityName: 'Tarso',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15790',
        CityName: 'Tasco',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '85410',
        CityName: 'Tauramena',
        StateCode: '85',
        StateName: 'Casanare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25793',
        CityName: 'Tausa',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41799',
        CityName: 'Tello',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25797',
        CityName: 'Tena',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47798',
        CityName: 'Tenerife',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25799',
        CityName: 'Tenjo',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15798',
        CityName: 'Tenza',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54800',
        CityName: 'Teorama',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41801',
        CityName: 'Teruel',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41797',
        CityName: 'Tesalia',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25805',
        CityName: 'Tibacuy',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15804',
        CityName: 'Tibaná',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15806',
        CityName: 'Tibasosa',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25807',
        CityName: 'Tibirita',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54810',
        CityName: 'Tibú',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23807',
        CityName: 'Tierralta',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41807',
        CityName: 'Timaná',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19807',
        CityName: 'Timbío',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19809',
        CityName: 'Timbiquí',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15808',
        CityName: 'Tinjacá',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15810',
        CityName: 'Tipacoque',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13810',
        CityName: 'Tiquisio',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05809',
        CityName: 'Titiribí',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15814',
        CityName: 'Toca',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25815',
        CityName: 'Tocaima',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25817',
        CityName: 'Tocancipá',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15816',
        CityName: 'Togüí',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05819',
        CityName: 'Toledo',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54820',
        CityName: 'Toledo',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '70823',
        CityName: 'Tolú Viejo',
        StateCode: '70',
        StateName: 'Sucre',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68820',
        CityName: 'Tona',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15820',
        CityName: 'Tópaga',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25823',
        CityName: 'Topaipí',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19821',
        CityName: 'Toribio',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76823',
        CityName: 'Toro',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15822',
        CityName: 'Tota',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19824',
        CityName: 'Totoró',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '85430',
        CityName: 'Trinidad',
        StateCode: '85',
        StateName: 'Casanare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76828',
        CityName: 'Trujillo',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08832',
        CityName: 'Tubará',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76834',
        CityName: 'Tuluá',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52835',
        CityName: 'Tumaco',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15001',
        CityName: 'Tunja',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15832',
        CityName: 'Tununguá',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52838',
        CityName: 'Túquerres',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13836',
        CityName: 'Turbaco',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13838',
        CityName: 'Turbaná',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05837',
        CityName: 'Turbo',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15835',
        CityName: 'Turmequé',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15837',
        CityName: 'Tuta',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15839',
        CityName: 'Tutazá',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25839',
        CityName: 'Ubalá',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25841',
        CityName: 'Ubaque',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76845',
        CityName: 'Ulloa',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15842',
        CityName: 'Umbita',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25845',
        CityName: 'Une',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27800',
        CityName: 'Unguía',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '27810',
        CityName: 'Unión Panamericana',
        StateCode: '27',
        StateName: 'Chocó',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05842',
        CityName: 'Uramita',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50370',
        CityName: 'Uribe',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '44847',
        CityName: 'Uribia',
        StateCode: '44',
        StateName: 'La Guajira',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05847',
        CityName: 'Urrao',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '44855',
        CityName: 'Urumita',
        StateCode: '44',
        StateName: 'La Guajira',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '08849',
        CityName: 'Usiacurí',
        StateCode: '08',
        StateName: 'Atlántico',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25851',
        CityName: 'Útica',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05854',
        CityName: 'Valdivia',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23855',
        CityName: 'Valencia',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68855',
        CityName: 'Valle De San José',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73854',
        CityName: 'Valle De San Juan',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '86865',
        CityName: 'Valle Del Guamuez',
        StateCode: '86',
        StateName: 'Putumayo',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '20001',
        CityName: 'Valledupar',
        StateCode: '20',
        StateName: 'Cesar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05856',
        CityName: 'Valparaíso',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '18860',
        CityName: 'Valparaíso',
        StateCode: '18',
        StateName: 'Caquetá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05858',
        CityName: 'Vegachí',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68861',
        CityName: 'Vélez',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73861',
        CityName: 'Venadillo',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05861',
        CityName: 'Venecia',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25506',
        CityName: 'Venecia',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15861',
        CityName: 'Ventaquemada',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25862',
        CityName: 'Vergara',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76863',
        CityName: 'Versalles',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68867',
        CityName: 'Vetas',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25867',
        CityName: 'Vianí',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17867',
        CityName: 'Victoria',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05873',
        CityName: 'Vigía Del Fuerte',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76869',
        CityName: 'Vijes',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54871',
        CityName: 'Villa Caro',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15407',
        CityName: 'Villa De Leyva',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25843',
        CityName: 'Villa De San Diego De Ubate',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '54874',
        CityName: 'Villa Del Rosario',
        StateCode: '54',
        StateName: 'Norte de Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '19845',
        CityName: 'Villa Rica',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '86885',
        CityName: 'Villagarzón',
        StateCode: '86',
        StateName: 'Putumayo',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25871',
        CityName: 'Villagómez',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73870',
        CityName: 'Villahermosa',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17873',
        CityName: 'Villamaría',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13873',
        CityName: 'Villanueva',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '44874',
        CityName: 'Villanueva',
        StateCode: '44',
        StateName: 'La Guajira',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68872',
        CityName: 'Villanueva',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '85440',
        CityName: 'Villanueva',
        StateCode: '85',
        StateName: 'Casanare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25873',
        CityName: 'Villapinzón',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '73873',
        CityName: 'Villarrica',
        StateCode: '73',
        StateName: 'Tolima',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50001',
        CityName: 'Villavicencio',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41872',
        CityName: 'Villavieja',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25875',
        CityName: 'Villeta',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25878',
        CityName: 'Viotá',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15879',
        CityName: 'Viracachá',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '50711',
        CityName: 'Vistahermosa',
        StateCode: '50',
        StateName: 'Meta',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '17877',
        CityName: 'Viterbo',
        StateCode: '17',
        StateName: 'Caldas',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25885',
        CityName: 'Yacopí',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '52885',
        CityName: 'Yacuanquer',
        StateCode: '52',
        StateName: 'Nariño',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '41885',
        CityName: 'Yaguará',
        StateCode: '41',
        StateName: 'Huila',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05885',
        CityName: 'Yalí',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05887',
        CityName: 'Yarumal',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '97889',
        CityName: 'Yavaraté',
        StateCode: '97',
        StateName: 'Vaupés',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05890',
        CityName: 'Yolombó',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05893',
        CityName: 'Yondó',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '85001',
        CityName: 'Yopal',
        StateCode: '85',
        StateName: 'Casanare',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76890',
        CityName: 'Yotoco',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76892',
        CityName: 'Yumbo',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13894',
        CityName: 'Zambrano',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '68895',
        CityName: 'Zapatoca',
        StateCode: '68',
        StateName: 'Santander',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47960',
        CityName: 'Zapayán',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '05895',
        CityName: 'Zaragoza',
        StateCode: '05',
        StateName: 'Antioquia',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '76895',
        CityName: 'Zarzal',
        StateCode: '76',
        StateName: 'Valle del Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '15897',
        CityName: 'Zetaquira',
        StateCode: '15',
        StateName: 'Boyacá',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25898',
        CityName: 'Zipacón',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '25899',
        CityName: 'Zipaquirá',
        StateCode: '25',
        StateName: 'Cundinamarca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '47980',
        CityName: 'Zona Bananera',
        StateCode: '47',
        StateName: 'Magdalena',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '01001',
        CityName: 'Cuenca',
        StateCode: '01',
        StateName: 'Azuay',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '01002',
        CityName: 'Camilo Ponce Enríquez',
        StateCode: '01',
        StateName: 'Azuay',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '01003',
        CityName: 'Chordeleg',
        StateCode: '01',
        StateName: 'Azuay',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '01004',
        CityName: 'El Pan',
        StateCode: '01',
        StateName: 'Azuay',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '01005',
        CityName: 'Girón',
        StateCode: '01',
        StateName: 'Azuay',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '01006',
        CityName: 'Guachapala',
        StateCode: '01',
        StateName: 'Azuay',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '01007',
        CityName: 'Gualaceo',
        StateCode: '01',
        StateName: 'Azuay',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '01008',
        CityName: 'Nabón',
        StateCode: '01',
        StateName: 'Azuay',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '01009',
        CityName: 'Oña',
        StateCode: '01',
        StateName: 'Azuay',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '01010',
        CityName: 'Paute',
        StateCode: '01',
        StateName: 'Azuay',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '01011',
        CityName: 'Pucara',
        StateCode: '01',
        StateName: 'Azuay',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '01012',
        CityName: 'San Fernando',
        StateCode: '01',
        StateName: 'Azuay',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '01013',
        CityName: 'Santa Isabel',
        StateCode: '01',
        StateName: 'Azuay',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '01014',
        CityName: 'Sevilla de Oro',
        StateCode: '01',
        StateName: 'Azuay',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '01015',
        CityName: 'SígSig',
        StateCode: '01',
        StateName: 'Azuay',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '02016',
        CityName: 'Guaranda',
        StateCode: '02',
        StateName: 'Bolívar',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '02017',
        CityName: 'Chimbo',
        StateCode: '02',
        StateName: 'Bolívar',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '02018',
        CityName: 'Echeandía',
        StateCode: '02',
        StateName: 'Bolívar',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '02019',
        CityName: 'San Miguel',
        StateCode: '02',
        StateName: 'Bolívar',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '02020',
        CityName: 'Chillanes',
        StateCode: '02',
        StateName: 'Bolívar',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '02021',
        CityName: 'Caluma',
        StateCode: '02',
        StateName: 'Bolívar',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '02022',
        CityName: 'Las Naves',
        StateCode: '02',
        StateName: 'Bolívar',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '03023',
        CityName: 'Azogues',
        StateCode: '03',
        StateName: 'Cañar',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '03024',
        CityName: 'Cañar',
        StateCode: '03',
        StateName: 'Cañar',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '03025',
        CityName: 'Biblián',
        StateCode: '03',
        StateName: 'Cañar',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '03026',
        CityName: 'La Troncal',
        StateCode: '03',
        StateName: 'Cañar',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '03027',
        CityName: 'El Tambo',
        StateCode: '03',
        StateName: 'Cañar',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '03028',
        CityName: 'Déleg',
        StateCode: '03',
        StateName: 'Cañar',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '03029',
        CityName: 'Suscal',
        StateCode: '03',
        StateName: 'Cañar',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '04030',
        CityName: 'Tulcán',
        StateCode: '04',
        StateName: 'Carchi',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '04031',
        CityName: 'Espejo',
        StateCode: '04',
        StateName: 'Carchi',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '04032',
        CityName: 'Montúfar',
        StateCode: '04',
        StateName: 'Carchi',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '04033',
        CityName: 'Mira',
        StateCode: '04',
        StateName: 'Carchi',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '04034',
        CityName: 'Bolívar',
        StateCode: '04',
        StateName: 'Carchi',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '04035',
        CityName: 'San Pedro de Huaca',
        StateCode: '04',
        StateName: 'Carchi',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '06036',
        CityName: 'Latacunga',
        StateCode: '06',
        StateName: 'Chimborazo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '06037',
        CityName: 'La Maná',
        StateCode: '06',
        StateName: 'Chimborazo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '06038',
        CityName: 'Pangua',
        StateCode: '06',
        StateName: 'Chimborazo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '06039',
        CityName: 'Pujilí',
        StateCode: '06',
        StateName: 'Chimborazo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '06040',
        CityName: 'Salcedo',
        StateCode: '06',
        StateName: 'Chimborazo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '06041',
        CityName: 'Saquislí',
        StateCode: '06',
        StateName: 'Chimborazo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '06042',
        CityName: 'Sigchos',
        StateCode: '06',
        StateName: 'Chimborazo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '06043',
        CityName: 'Riobamba',
        StateCode: '06',
        StateName: 'Chimborazo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '06044',
        CityName: 'Alausí',
        StateCode: '06',
        StateName: 'Chimborazo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '06045',
        CityName: 'Colta',
        StateCode: '06',
        StateName: 'Chimborazo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '06046',
        CityName: 'Chunchi',
        StateCode: '06',
        StateName: 'Chimborazo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '06047',
        CityName: 'Guamote',
        StateCode: '06',
        StateName: 'Chimborazo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '06048',
        CityName: 'Guano',
        StateCode: '06',
        StateName: 'Chimborazo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '06049',
        CityName: 'Penipe',
        StateCode: '06',
        StateName: 'Chimborazo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '06050',
        CityName: 'Pallatanga',
        StateCode: '06',
        StateName: 'Chimborazo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '06051',
        CityName: 'Chambo',
        StateCode: '06',
        StateName: 'Chimborazo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '06052',
        CityName: 'Cumandá',
        StateCode: '06',
        StateName: 'Chimborazo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14053',
        CityName: 'Ibarra',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14054',
        CityName: 'Antonio Ante',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14055',
        CityName: 'Otavalo',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14056',
        CityName: 'Cotacachi',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14057',
        CityName: 'Pimampiro',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14058',
        CityName: 'San Miguel de Urcuquí',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14059',
        CityName: 'Loja',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14060',
        CityName: 'Macará',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14061',
        CityName: 'Paltas',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14062',
        CityName: 'Puyango',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14063',
        CityName: 'Saraguro',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14064',
        CityName: 'Celica',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14065',
        CityName: 'Catamayo',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14066',
        CityName: 'Espíndola',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14067',
        CityName: 'Gonzanamá',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14068',
        CityName: 'Sozoranga',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14069',
        CityName: 'Zapotillo',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14070',
        CityName: 'Calvas',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14071',
        CityName: 'Chaguarpamba',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14072',
        CityName: 'Olmedo',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14073',
        CityName: 'Pindal',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14074',
        CityName: 'Quilanga',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20075',
        CityName: 'Quito',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20076',
        CityName: 'Cayambe',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20077',
        CityName: 'Mejía',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20078',
        CityName: 'Pedro Moncayo',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20079',
        CityName: 'Pedro Vicente Maldonado',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20080',
        CityName: 'Puerto Quito',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20081',
        CityName: 'Rumiñahui',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20082',
        CityName: 'San Miguel de los Bancos',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20083',
        CityName: 'Ambato',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20084',
        CityName: 'Baños',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20085',
        CityName: 'Cevallos',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20086',
        CityName: 'Mocha',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20087',
        CityName: 'Patate',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20088',
        CityName: 'Quero',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20089',
        CityName: 'San Pedro de Pelileo',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20090',
        CityName: 'Santiago de Píllaro',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20091',
        CityName: 'Tisaleo',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20092',
        CityName: 'Santo Domingo',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '12093',
        CityName: 'Machala',
        StateCode: '12',
        StateName: 'El Oro',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '12094',
        CityName: 'Arenillas',
        StateCode: '12',
        StateName: 'El Oro',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '12095',
        CityName: 'Atahualpa',
        StateCode: '12',
        StateName: 'El Oro',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '12096',
        CityName: 'El Guabo',
        StateCode: '12',
        StateName: 'El Oro',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '12097',
        CityName: 'Huaquillas',
        StateCode: '12',
        StateName: 'El Oro',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '12098',
        CityName: 'La Concordia',
        StateCode: '12',
        StateName: 'El Oro',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '12099',
        CityName: 'Marcabelí',
        StateCode: '12',
        StateName: 'El Oro',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '12100',
        CityName: 'Pasaje',
        StateCode: '12',
        StateName: 'El Oro',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '12101',
        CityName: 'Piñas',
        StateCode: '12',
        StateName: 'El Oro',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '12102',
        CityName: 'Portovelo',
        StateCode: '12',
        StateName: 'El Oro',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '12103',
        CityName: 'Rioverde',
        StateCode: '12',
        StateName: 'El Oro',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '12104',
        CityName: 'Santa Rosa',
        StateCode: '12',
        StateName: 'El Oro',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '12105',
        CityName: 'Zaruma',
        StateCode: '12',
        StateName: 'El Oro',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '12106',
        CityName: 'Balsas',
        StateCode: '12',
        StateName: 'El Oro',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '12107',
        CityName: 'Chilla',
        StateCode: '12',
        StateName: 'El Oro',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '12108',
        CityName: 'Las Lajas',
        StateCode: '12',
        StateName: 'El Oro',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '13109',
        CityName: 'Esmeraldas',
        StateCode: '13',
        StateName: 'Esmeraldas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '13110',
        CityName: 'Eloy Alfaro',
        StateCode: '13',
        StateName: 'Esmeraldas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '13111',
        CityName: 'Muisne',
        StateCode: '13',
        StateName: 'Esmeraldas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '13112',
        CityName: 'Quinindé',
        StateCode: '13',
        StateName: 'Esmeraldas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '13113',
        CityName: 'San Lorenzo',
        StateCode: '13',
        StateName: 'Esmeraldas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '13114',
        CityName: 'Atacames',
        StateCode: '13',
        StateName: 'Esmeraldas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14115',
        CityName: 'Guayaquil',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14116',
        CityName: 'Daule',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14117',
        CityName: 'Durán',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14118',
        CityName: 'Yaguachi',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14119',
        CityName: 'Balzar',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14120',
        CityName: 'Milagro',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14121',
        CityName: 'Naranjal',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14122',
        CityName: 'Samborondón',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14123',
        CityName: 'El Triunfo',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14124',
        CityName: 'Isidro Ayora',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14125',
        CityName: 'Naranjito',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14126',
        CityName: 'El Empalme',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14127',
        CityName: 'Baquerizo Moreno',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14128',
        CityName: 'Pedro Carbo',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14129',
        CityName: 'Salitre',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14130',
        CityName: 'Santa Lucía',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14131',
        CityName: 'Palestina',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14132',
        CityName: 'Balao',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14133',
        CityName: 'Colimes',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14134',
        CityName: 'Playas',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14135',
        CityName: 'Simón Bolívar',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14136',
        CityName: 'Coronel. Marcelino Maridueña',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14137',
        CityName: 'Lomas de Sangentillo',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '14138',
        CityName: 'Nobol',
        StateCode: '14',
        StateName: 'Guayas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '15139',
        CityName: 'Babahoyo',
        StateCode: '15',
        StateName: 'Los Ríos',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '15140',
        CityName: 'Baba',
        StateCode: '15',
        StateName: 'Los Ríos',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '15141',
        CityName: 'Buena Fe',
        StateCode: '15',
        StateName: 'Los Ríos',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '15142',
        CityName: 'Montalvo',
        StateCode: '15',
        StateName: 'Los Ríos',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '15143',
        CityName: 'Puebloviejo',
        StateCode: '15',
        StateName: 'Los Ríos',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '15144',
        CityName: 'Quevedo',
        StateCode: '15',
        StateName: 'Los Ríos',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '15145',
        CityName: 'Quinsaloma',
        StateCode: '15',
        StateName: 'Los Ríos',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '15146',
        CityName: 'Urdaneta',
        StateCode: '15',
        StateName: 'Los Ríos',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '15147',
        CityName: 'Valencia',
        StateCode: '15',
        StateName: 'Los Ríos',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '15148',
        CityName: 'Mocache',
        StateCode: '15',
        StateName: 'Los Ríos',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '15149',
        CityName: 'Ventanas',
        StateCode: '15',
        StateName: 'Los Ríos',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '15150',
        CityName: 'Vinces',
        StateCode: '15',
        StateName: 'Los Ríos',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '15151',
        CityName: 'Palenque',
        StateCode: '15',
        StateName: 'Los Ríos',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16152',
        CityName: 'Portoviejo',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16153',
        CityName: 'Bolívar',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16154',
        CityName: 'Chone',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16155',
        CityName: 'El Carmen',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16156',
        CityName: 'Flavio Alfaro',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16157',
        CityName: 'Jama',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16158',
        CityName: 'Jaramijó',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16159',
        CityName: 'Junín',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16160',
        CityName: 'Jipijapa',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16161',
        CityName: 'Manta',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16162',
        CityName: 'Montecristi',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16163',
        CityName: 'Olmedo',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16164',
        CityName: 'Paján',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16165',
        CityName: 'Pedernales',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16166',
        CityName: 'Pichincha',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16167',
        CityName: 'Puerto López',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16168',
        CityName: 'Rocafuerte',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16169',
        CityName: 'San Vicente',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16170',
        CityName: 'Santa Ana',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16171',
        CityName: 'Sucre',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16172',
        CityName: 'Tosagua',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '16173',
        CityName: '24 de mayo',
        StateCode: '16',
        StateName: 'Manabí',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20174',
        CityName: 'Santa Elena',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20175',
        CityName: 'La Libertad',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20176',
        CityName: 'Salinas',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '18177',
        CityName: 'Morona',
        StateCode: '18',
        StateName: 'Morona Santiago',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '18178',
        CityName: 'Gualaquiza',
        StateCode: '18',
        StateName: 'Morona Santiago',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '18179',
        CityName: 'Limón Indanza',
        StateCode: '18',
        StateName: 'Morona Santiago',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '18180',
        CityName: 'Logroño',
        StateCode: '18',
        StateName: 'Morona Santiago',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '18181',
        CityName: 'Pablo Sexto',
        StateCode: '18',
        StateName: 'Morona Santiago',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '18182',
        CityName: 'Palora',
        StateCode: '18',
        StateName: 'Morona Santiago',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '18183',
        CityName: 'Santiago',
        StateCode: '18',
        StateName: 'Morona Santiago',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '18184',
        CityName: 'Sucúa',
        StateCode: '18',
        StateName: 'Morona Santiago',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '18185',
        CityName: 'Huamboya',
        StateCode: '18',
        StateName: 'Morona Santiago',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '18186',
        CityName: 'San Juan Bosco',
        StateCode: '18',
        StateName: 'Morona Santiago',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '18187',
        CityName: 'Taisha',
        StateCode: '18',
        StateName: 'Morona Santiago',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '18188',
        CityName: 'Tiwintza',
        StateCode: '18',
        StateName: 'Morona Santiago',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '19189',
        CityName: 'Tena',
        StateCode: '19',
        StateName: 'Napo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '19190',
        CityName: 'Archidona',
        StateCode: '19',
        StateName: 'Napo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '19191',
        CityName: 'Carlos Julio Arosemena Tola',
        StateCode: '19',
        StateName: 'Napo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '19192',
        CityName: 'El Chaco',
        StateCode: '19',
        StateName: 'Napo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '19193',
        CityName: 'Quijos',
        StateCode: '19',
        StateName: 'Napo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20194',
        CityName: 'Pastaza',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20195',
        CityName: 'Arajuno',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20196',
        CityName: 'Mera',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20197',
        CityName: 'Santa Clara',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '21198',
        CityName: 'Zamora',
        StateCode: '21',
        StateName: 'Zamora Chinchipe',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '21199',
        CityName: 'Chinchipe',
        StateCode: '21',
        StateName: 'Zamora Chinchipe',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '21200',
        CityName: 'Nangaritza',
        StateCode: '21',
        StateName: 'Zamora Chinchipe',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '21201',
        CityName: 'Palanda',
        StateCode: '21',
        StateName: 'Zamora Chinchipe',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '21202',
        CityName: 'Paquisha',
        StateCode: '21',
        StateName: 'Zamora Chinchipe',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '21203',
        CityName: 'Yacuambi',
        StateCode: '21',
        StateName: 'Zamora Chinchipe',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '21204',
        CityName: 'Yantzaza',
        StateCode: '21',
        StateName: 'Zamora Chinchipe',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '21205',
        CityName: 'El Pangui',
        StateCode: '21',
        StateName: 'Zamora Chinchipe',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '21206',
        CityName: 'Centinela del Cóndor',
        StateCode: '21',
        StateName: 'Zamora Chinchipe',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20207',
        CityName: 'Lago Agrío',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20208',
        CityName: 'Cuyabeno',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20209',
        CityName: 'Gonzalo Pizarro',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20210',
        CityName: 'Putumayo',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20211',
        CityName: 'Shushufindi',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20212',
        CityName: 'Sucumbíos',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '20213',
        CityName: 'Cascales',
        StateCode: '20',
        StateName: 'Pastaza',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '19214',
        CityName: 'Orellana',
        StateCode: '19',
        StateName: 'Napo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '19215',
        CityName: 'Aguarico',
        StateCode: '19',
        StateName: 'Napo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '19216',
        CityName: 'La Joya de los Sachas',
        StateCode: '19',
        StateName: 'Napo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '19217',
        CityName: 'Loreto',
        StateCode: '19',
        StateName: 'Napo',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '13218',
        CityName: 'San Cristóbal',
        StateCode: '13',
        StateName: 'Esmeraldas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '13219',
        CityName: 'Isabela',
        StateCode: '13',
        StateName: 'Esmeraldas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '13220',
        CityName: 'Santa Cruz',
        StateCode: '13',
        StateName: 'Esmeraldas',
        CountryCode: 'Ec',
        CountryName: 'Ecuador',
    },
    {
        CityCode: '05001',
        CityName: 'Chachapollas',
        StateCode: '05',
        StateName: 'Amazonas',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '05002',
        CityName: 'Bagua',
        StateCode: '05',
        StateName: 'Amazonas',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '05003',
        CityName: 'Bongara',
        StateCode: '05',
        StateName: 'Amazonas',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '05004',
        CityName: 'Condorcanqui',
        StateCode: '05',
        StateName: 'Amazonas',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '05005',
        CityName: 'Luya',
        StateCode: '05',
        StateName: 'Amazonas',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '05006',
        CityName: 'Rodriguez de Mendoza',
        StateCode: '05',
        StateName: 'Amazonas',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '05007',
        CityName: 'Utcubamba',
        StateCode: '05',
        StateName: 'Amazonas',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '08008',
        CityName: 'Huaraz',
        StateCode: '08',
        StateName: 'Ancash',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '08009',
        CityName: 'Aija',
        StateCode: '08',
        StateName: 'Ancash',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '08010',
        CityName: 'Antonio Raymondi',
        StateCode: '08',
        StateName: 'Ancash',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '08011',
        CityName: 'Asunción',
        StateCode: '08',
        StateName: 'Ancash',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '08012',
        CityName: 'Bolognesi',
        StateCode: '08',
        StateName: 'Ancash',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '08013',
        CityName: 'Carhuaz',
        StateCode: '08',
        StateName: 'Ancash',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '08014',
        CityName: 'Carlos Fermín Fitzcarrald',
        StateCode: '08',
        StateName: 'Ancash',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '08015',
        CityName: 'Casma',
        StateCode: '08',
        StateName: 'Ancash',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '08016',
        CityName: 'Corongo',
        StateCode: '08',
        StateName: 'Ancash',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '08017',
        CityName: 'Huari',
        StateCode: '08',
        StateName: 'Ancash',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '08018',
        CityName: 'Huarmey',
        StateCode: '08',
        StateName: 'Ancash',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '08019',
        CityName: 'Huaylas',
        StateCode: '08',
        StateName: 'Ancash',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '08020',
        CityName: 'Mariscal Luzuriaga',
        StateCode: '08',
        StateName: 'Ancash',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '08021',
        CityName: 'Ocros',
        StateCode: '08',
        StateName: 'Ancash',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '08022',
        CityName: 'Pallasca',
        StateCode: '08',
        StateName: 'Ancash',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '08023',
        CityName: 'Pomabamba',
        StateCode: '08',
        StateName: 'Ancash',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '08024',
        CityName: 'Recuay',
        StateCode: '08',
        StateName: 'Ancash',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '08025',
        CityName: 'Santa',
        StateCode: '08',
        StateName: 'Ancash',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '08026',
        CityName: 'Sihuas',
        StateCode: '08',
        StateName: 'Ancash',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '08027',
        CityName: 'Yungay',
        StateCode: '08',
        StateName: 'Ancash',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '11028',
        CityName: 'Abancay',
        StateCode: '11',
        StateName: 'Apurimac',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '11029',
        CityName: 'Antabamba',
        StateCode: '11',
        StateName: 'Apurimac',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '11030',
        CityName: 'Aymaraes',
        StateCode: '11',
        StateName: 'Apurimac',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '11031',
        CityName: 'Cotabambas',
        StateCode: '11',
        StateName: 'Apurimac',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '11032',
        CityName: 'Grau',
        StateCode: '11',
        StateName: 'Apurimac',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '11033',
        CityName: 'Chincheros',
        StateCode: '11',
        StateName: 'Apurimac',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '11034',
        CityName: 'Andahuaylas',
        StateCode: '11',
        StateName: 'Apurimac',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '13035',
        CityName: 'Arequipa',
        StateCode: '13',
        StateName: 'Arequipa',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '13036',
        CityName: 'Camaná',
        StateCode: '13',
        StateName: 'Arequipa',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '13037',
        CityName: 'Caravelí',
        StateCode: '13',
        StateName: 'Arequipa',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '13038',
        CityName: 'Castilla',
        StateCode: '13',
        StateName: 'Arequipa',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '13039',
        CityName: 'Caylloma',
        StateCode: '13',
        StateName: 'Arequipa',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '13040',
        CityName: 'Condesuyos',
        StateCode: '13',
        StateName: 'Arequipa',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '13041',
        CityName: 'Islay',
        StateCode: '13',
        StateName: 'Arequipa',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '13042',
        CityName: 'La Unión',
        StateCode: '13',
        StateName: 'Arequipa',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '15043',
        CityName: 'Cangallo',
        StateCode: '15',
        StateName: 'Ayacucho',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '15044',
        CityName: 'Huanta',
        StateCode: '15',
        StateName: 'Ayacucho',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '15045',
        CityName: 'Huamanga',
        StateCode: '15',
        StateName: 'Ayacucho',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '15046',
        CityName: 'Huancasancos',
        StateCode: '15',
        StateName: 'Ayacucho',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '15047',
        CityName: 'La Mar',
        StateCode: '15',
        StateName: 'Ayacucho',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '15048',
        CityName: 'Lucanas',
        StateCode: '15',
        StateName: 'Ayacucho',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '15049',
        CityName: 'Parinacochas',
        StateCode: '15',
        StateName: 'Ayacucho',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '15050',
        CityName: 'Paúcar del Sara Sara',
        StateCode: '15',
        StateName: 'Ayacucho',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '15051',
        CityName: 'Sucre',
        StateCode: '15',
        StateName: 'Ayacucho',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '15052',
        CityName: 'Victor Fajardo',
        StateCode: '15',
        StateName: 'Ayacucho',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '15053',
        CityName: 'Vilcas Huamán',
        StateCode: '15',
        StateName: 'Ayacucho',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '17054',
        CityName: 'San Ignacio',
        StateCode: '17',
        StateName: 'Cajamarca',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '17055',
        CityName: 'Jaén',
        StateCode: '17',
        StateName: 'Cajamarca',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '17056',
        CityName: 'Cutervo',
        StateCode: '17',
        StateName: 'Cajamarca',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '17057',
        CityName: 'Chota',
        StateCode: '17',
        StateName: 'Cajamarca',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '17058',
        CityName: 'Santa Cruz',
        StateCode: '17',
        StateName: 'Cajamarca',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '17059',
        CityName: 'Hualgayoc',
        StateCode: '17',
        StateName: 'Cajamarca',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '17060',
        CityName: 'Celendín',
        StateCode: '17',
        StateName: 'Cajamarca',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '17061',
        CityName: 'San Miguel',
        StateCode: '17',
        StateName: 'Cajamarca',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '17062',
        CityName: 'San Pablo',
        StateCode: '17',
        StateName: 'Cajamarca',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '17063',
        CityName: 'Cajamarca',
        StateCode: '17',
        StateName: 'Cajamarca',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '17064',
        CityName: 'Contumazá',
        StateCode: '17',
        StateName: 'Cajamarca',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '17065',
        CityName: 'Cajabamba',
        StateCode: '17',
        StateName: 'Cajamarca',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '17066',
        CityName: 'San Marcos',
        StateCode: '17',
        StateName: 'Cajamarca',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '18067',
        CityName: 'Callao',
        StateCode: '18',
        StateName: 'Callao',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '18068',
        CityName: 'Bellavista',
        StateCode: '18',
        StateName: 'Callao',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '18069',
        CityName: 'Carmen de la Legua-Reynoso',
        StateCode: '18',
        StateName: 'Callao',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '18070',
        CityName: 'La Perla',
        StateCode: '18',
        StateName: 'Callao',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '18071',
        CityName: 'La Punta',
        StateCode: '18',
        StateName: 'Callao',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '18072',
        CityName: 'Ventanilla',
        StateCode: '18',
        StateName: 'Callao',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '19073',
        CityName: 'Cuzco',
        StateCode: '19',
        StateName: 'Cusco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '19074',
        CityName: 'Acomayo',
        StateCode: '19',
        StateName: 'Cusco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '19075',
        CityName: 'Anta',
        StateCode: '19',
        StateName: 'Cusco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '19076',
        CityName: 'Calca',
        StateCode: '19',
        StateName: 'Cusco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '19077',
        CityName: 'Canas',
        StateCode: '19',
        StateName: 'Cusco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '19078',
        CityName: 'Canchis',
        StateCode: '19',
        StateName: 'Cusco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '19079',
        CityName: 'Chumbivilcas',
        StateCode: '19',
        StateName: 'Cusco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '19080',
        CityName: 'Espinar',
        StateCode: '19',
        StateName: 'Cusco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '19081',
        CityName: 'La Convención',
        StateCode: '19',
        StateName: 'Cusco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '19082',
        CityName: 'Paruro',
        StateCode: '19',
        StateName: 'Cusco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '19083',
        CityName: 'Paucartambo',
        StateCode: '19',
        StateName: 'Cusco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '19084',
        CityName: 'Quispicanchi',
        StateCode: '19',
        StateName: 'Cusco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '19085',
        CityName: 'Urubamba',
        StateCode: '19',
        StateName: 'Cusco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '20086',
        CityName: 'Huancavelica',
        StateCode: '20',
        StateName: 'Huancavelica',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '20087',
        CityName: 'Acobamba',
        StateCode: '20',
        StateName: 'Huancavelica',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '20088',
        CityName: 'Angaraes',
        StateCode: '20',
        StateName: 'Huancavelica',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '20089',
        CityName: 'Castrovirreyna',
        StateCode: '20',
        StateName: 'Huancavelica',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '20090',
        CityName: 'Churcampa',
        StateCode: '20',
        StateName: 'Huancavelica',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '20091',
        CityName: 'Huaytará',
        StateCode: '20',
        StateName: 'Huancavelica',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '20092',
        CityName: 'Tayacaja',
        StateCode: '20',
        StateName: 'Huancavelica',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '23093',
        CityName: 'Huánuco',
        StateCode: '23',
        StateName: 'Huánuco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '23094',
        CityName: 'Ambo',
        StateCode: '23',
        StateName: 'Huánuco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '23095',
        CityName: 'Dos de Mayo',
        StateCode: '23',
        StateName: 'Huánuco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '23096',
        CityName: 'Huacaybamba',
        StateCode: '23',
        StateName: 'Huánuco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '23097',
        CityName: 'Huamalíes',
        StateCode: '23',
        StateName: 'Huánuco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '23098',
        CityName: 'Leoncio Prado',
        StateCode: '23',
        StateName: 'Huánuco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '23099',
        CityName: 'Marañón',
        StateCode: '23',
        StateName: 'Huánuco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '23100',
        CityName: 'Pachitea',
        StateCode: '23',
        StateName: 'Huánuco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '23101',
        CityName: 'Puerto Inca',
        StateCode: '23',
        StateName: 'Huánuco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '23102',
        CityName: 'Lauricocha',
        StateCode: '23',
        StateName: 'Huánuco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '23103',
        CityName: 'Yarowilca',
        StateCode: '23',
        StateName: 'Huánuco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '25104',
        CityName: 'Ica',
        StateCode: '25',
        StateName: 'Ica',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '25105',
        CityName: 'Chincha',
        StateCode: '25',
        StateName: 'Ica',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '25106',
        CityName: 'Nazca',
        StateCode: '25',
        StateName: 'Ica',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '25107',
        CityName: 'Palpa',
        StateCode: '25',
        StateName: 'Ica',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '25108',
        CityName: 'Pisco',
        StateCode: '25',
        StateName: 'Ica',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '27109',
        CityName: 'Chanchamayo',
        StateCode: '27',
        StateName: 'Junín',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '27110',
        CityName: 'Chupaca',
        StateCode: '27',
        StateName: 'Junín',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '27111',
        CityName: 'Concepción',
        StateCode: '27',
        StateName: 'Junín',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '27112',
        CityName: 'Huancayo',
        StateCode: '27',
        StateName: 'Junín',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '27113',
        CityName: 'Jauja',
        StateCode: '27',
        StateName: 'Junín',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '27114',
        CityName: 'Junín',
        StateCode: '27',
        StateName: 'Junín',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '27115',
        CityName: 'Satipo',
        StateCode: '27',
        StateName: 'Junín',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '27116',
        CityName: 'Tarma',
        StateCode: '27',
        StateName: 'Junín',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '27117',
        CityName: 'Yauli',
        StateCode: '27',
        StateName: 'Junín',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '41118',
        CityName: 'Trujillo',
        StateCode: '41',
        StateName: 'La Libertad',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '41119',
        CityName: 'Ascope',
        StateCode: '41',
        StateName: 'La Libertad',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '41120',
        CityName: 'Bolívar',
        StateCode: '41',
        StateName: 'La Libertad',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '41121',
        CityName: 'Chepén',
        StateCode: '41',
        StateName: 'La Libertad',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '41122',
        CityName: 'Gran Chimú',
        StateCode: '41',
        StateName: 'La Libertad',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '41123',
        CityName: 'Julcán',
        StateCode: '41',
        StateName: 'La Libertad',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '41124',
        CityName: 'Otuzco',
        StateCode: '41',
        StateName: 'La Libertad',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '41125',
        CityName: 'Pacasmayo',
        StateCode: '41',
        StateName: 'La Libertad',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '41126',
        CityName: 'Pataz',
        StateCode: '41',
        StateName: 'La Libertad',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '41127',
        CityName: 'Sánchez Carrión',
        StateCode: '41',
        StateName: 'La Libertad',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '41128',
        CityName: 'Santiago de Chuco',
        StateCode: '41',
        StateName: 'La Libertad',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '41129',
        CityName: 'Virú',
        StateCode: '41',
        StateName: 'La Libertad',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '44130',
        CityName: 'Chiclayo',
        StateCode: '44',
        StateName: 'Lambayeque',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '44131',
        CityName: 'Lambayeque',
        StateCode: '44',
        StateName: 'Lambayeque',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '44132',
        CityName: 'Ferreñafe',
        StateCode: '44',
        StateName: 'Lambayeque',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '47133',
        CityName: 'Barranca',
        StateCode: '47',
        StateName: 'Lima',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '47134',
        CityName: 'Cajatambo',
        StateCode: '47',
        StateName: 'Lima',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '47135',
        CityName: 'Canta',
        StateCode: '47',
        StateName: 'Lima',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '47136',
        CityName: 'Cañete',
        StateCode: '47',
        StateName: 'Lima',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '47137',
        CityName: 'Huaral',
        StateCode: '47',
        StateName: 'Lima',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '47138',
        CityName: 'Huarochirí',
        StateCode: '47',
        StateName: 'Lima',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '47139',
        CityName: 'Huaura',
        StateCode: '47',
        StateName: 'Lima',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '47140',
        CityName: 'Lima',
        StateCode: '47',
        StateName: 'Lima',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '47141',
        CityName: 'Oyón',
        StateCode: '47',
        StateName: 'Lima',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '47142',
        CityName: 'Yauyos',
        StateCode: '47',
        StateName: 'Lima',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '50143',
        CityName: 'Maynas',
        StateCode: '50',
        StateName: 'Loreto',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '50144',
        CityName: 'Alto Amazonas',
        StateCode: '50',
        StateName: 'Loreto',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '50145',
        CityName: 'Datem del Marañón',
        StateCode: '50',
        StateName: 'Loreto',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '50146',
        CityName: 'Loreto',
        StateCode: '50',
        StateName: 'Loreto',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '50147',
        CityName: 'Mariscal Ramón Castilla',
        StateCode: '50',
        StateName: 'Loreto',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '50148',
        CityName: 'Requena',
        StateCode: '50',
        StateName: 'Loreto',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '50149',
        CityName: 'Ucayali',
        StateCode: '50',
        StateName: 'Loreto',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '52150',
        CityName: 'Tambopata',
        StateCode: '52',
        StateName: 'Madre de Dios',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '52151',
        CityName: 'Manu',
        StateCode: '52',
        StateName: 'Madre de Dios',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '52152',
        CityName: 'Tahuamanu',
        StateCode: '52',
        StateName: 'Madre de Dios',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '54153',
        CityName: 'Mariscal Nieto',
        StateCode: '52',
        StateName: 'Madre de Dios',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '54154',
        CityName: 'General Sánchez Cerro',
        StateCode: '52',
        StateName: 'Madre de Dios',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '54155',
        CityName: 'Ilo.',
        StateCode: '52',
        StateName: 'Madre de Dios',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '63156',
        CityName: 'Pasco',
        StateCode: '63',
        StateName: 'Pasco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '63157',
        CityName: 'Daniel Alcides Carrión',
        StateCode: '63',
        StateName: 'Pasco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '63158',
        CityName: 'Oxapampa',
        StateCode: '63',
        StateName: 'Pasco',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '66159',
        CityName: 'Ayabaca',
        StateCode: '66',
        StateName: 'Piura',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '66160',
        CityName: 'Huancabamba',
        StateCode: '66',
        StateName: 'Piura',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '66161',
        CityName: 'Morropón',
        StateCode: '66',
        StateName: 'Piura',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '66162',
        CityName: 'Paita',
        StateCode: '66',
        StateName: 'Piura',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '66163',
        CityName: 'Piura',
        StateCode: '66',
        StateName: 'Piura',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '66164',
        CityName: 'Sechura',
        StateCode: '66',
        StateName: 'Piura',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '66165',
        CityName: 'Sullana',
        StateCode: '66',
        StateName: 'Piura',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '66166',
        CityName: 'Talara',
        StateCode: '66',
        StateName: 'Piura',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '68167',
        CityName: 'Chucuito',
        StateCode: '68',
        StateName: 'Puno',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '68168',
        CityName: 'Puno',
        StateCode: '68',
        StateName: 'Puno',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '68169',
        CityName: 'San Antonio de Putina',
        StateCode: '68',
        StateName: 'Puno',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '68170',
        CityName: 'San Roman',
        StateCode: '68',
        StateName: 'Puno',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '68171',
        CityName: 'Lampa',
        StateCode: '68',
        StateName: 'Puno',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '68172',
        CityName: 'Azángaro',
        StateCode: '68',
        StateName: 'Puno',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '68173',
        CityName: 'Melgar',
        StateCode: '68',
        StateName: 'Puno',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '68174',
        CityName: 'Carabaya',
        StateCode: '68',
        StateName: 'Puno',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '68175',
        CityName: 'Sandia',
        StateCode: '68',
        StateName: 'Puno',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '68176',
        CityName: 'Huancane',
        StateCode: '68',
        StateName: 'Puno',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '68177',
        CityName: 'Moho',
        StateCode: '68',
        StateName: 'Puno',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '68178',
        CityName: 'El Collao',
        StateCode: '68',
        StateName: 'Puno',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '68179',
        CityName: 'Yunguyo',
        StateCode: '68',
        StateName: 'Puno',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '70180',
        CityName: 'Moyobamba',
        StateCode: '70',
        StateName: 'San Martín',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '70181',
        CityName: 'Rioja',
        StateCode: '70',
        StateName: 'San Martín',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '70182',
        CityName: 'Lamas',
        StateCode: '70',
        StateName: 'San Martín',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '70183',
        CityName: 'San Martín',
        StateCode: '70',
        StateName: 'San Martín',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '70184',
        CityName: 'Mariscal Cácres',
        StateCode: '70',
        StateName: 'San Martín',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '70185',
        CityName: 'El Dorado',
        StateCode: '70',
        StateName: 'San Martín',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '70186',
        CityName: 'Picota',
        StateCode: '70',
        StateName: 'San Martín',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '70187',
        CityName: 'Bellavista',
        StateCode: '70',
        StateName: 'San Martín',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '70188',
        CityName: 'Huallaga',
        StateCode: '70',
        StateName: 'San Martín',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '70189',
        CityName: 'Tocache',
        StateCode: '70',
        StateName: 'San Martín',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '73190',
        CityName: 'Tacna',
        StateCode: '73',
        StateName: 'Tacna',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '73191',
        CityName: 'Candarave',
        StateCode: '73',
        StateName: 'Tacna',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '73192',
        CityName: 'Jorge Basadre',
        StateCode: '73',
        StateName: 'Tacna',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '73193',
        CityName: 'Tarata',
        StateCode: '73',
        StateName: 'Tacna',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '76194',
        CityName: 'Zarumilla',
        StateCode: '76',
        StateName: 'Tumbes',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '76195',
        CityName: 'Tumbes',
        StateCode: '76',
        StateName: 'Tumbes',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '76196',
        CityName: 'Contralmirante Villar',
        StateCode: '76',
        StateName: 'Tumbes',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '81197',
        CityName: 'Coronel Portillo',
        StateCode: '81',
        StateName: 'Ucayali',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '81198',
        CityName: 'Atalaya',
        StateCode: '81',
        StateName: 'Ucayali',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '81199',
        CityName: 'Padre Abad',
        StateCode: '81',
        StateName: 'Ucayali',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '81200',
        CityName: 'Purús',
        StateCode: '81',
        StateName: 'Ucayali',
        CountryCode: 'Pe',
        CountryName: 'Perú',
    },
    {
        CityCode: '0101',
        CityName: 'Fayzabad',
        StateCode: '01',
        StateName: 'Badahan',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '0201',
        CityName: "Qal'eh-ye Now",
        StateCode: '02',
        StateName: 'Badgis',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '0301',
        CityName: 'Puli Khumri',
        StateCode: '03',
        StateName: 'Baglan',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '0401',
        CityName: 'Mazari Sharif',
        StateCode: '04',
        StateName: 'Balh',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '0501',
        CityName: 'Bamiyán',
        StateCode: '05',
        StateName: 'Bamiyan',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '0601',
        CityName: 'Nili',
        StateCode: '06',
        StateName: 'Daikondi',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '0701',
        CityName: 'Farah',
        StateCode: '07',
        StateName: 'Farah',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '0801',
        CityName: 'Maymanah',
        StateCode: '08',
        StateName: 'Faryab',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '0901',
        CityName: 'Gazni',
        StateCode: '09',
        StateName: 'Gazni',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '1001',
        CityName: 'Cagcaran',
        StateCode: '10',
        StateName: 'Gawr',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '1101',
        CityName: 'Jost',
        StateCode: '11',
        StateName: 'Jost',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '1201',
        CityName: 'Lashkar Gah',
        StateCode: '12',
        StateName: 'Helmand',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '1301',
        CityName: 'Herat',
        StateCode: '13',
        StateName: 'Herat',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '1401',
        CityName: 'ibargan',
        StateCode: '14',
        StateName: 'Jawzjan',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '1501',
        CityName: 'Kabul',
        StateCode: '15',
        StateName: 'Kabul',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '1601',
        CityName: 'Kandahar',
        StateCode: '16',
        StateName: 'Kandahar',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '1701',
        CityName: 'Mahmud-i-Raqi',
        StateCode: '17',
        StateName: 'Kapisa',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '1801',
        CityName: 'Asadabad',
        StateCode: '18',
        StateName: 'Kunar',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '1901',
        CityName: 'Mehtar Lam',
        StateCode: '19',
        StateName: 'Lagman',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '2001',
        CityName: 'Pul-i-Alam',
        StateCode: '20',
        StateName: 'Lawgar',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '2101',
        CityName: 'Jalalabad',
        StateCode: '21',
        StateName: 'Nangarhar',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '2201',
        CityName: 'Zaranj',
        StateCode: '22',
        StateName: 'Nimruz',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '2301',
        CityName: 'Parun',
        StateCode: '23',
        StateName: 'Nuristan',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '2401',
        CityName: 'Gardez',
        StateCode: '24',
        StateName: 'Paktiya',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '2501',
        CityName: 'Sharan',
        StateCode: '25',
        StateName: 'Paktika',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '2601',
        CityName: 'Bazarak',
        StateCode: '26',
        StateName: 'Panjshir',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '2701',
        CityName: 'Charikar',
        StateCode: '27',
        StateName: 'Parvan',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '2801',
        CityName: 'Qunduz',
        StateCode: '28',
        StateName: 'Qunduz',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '2901',
        CityName: 'Aybak',
        StateCode: '29',
        StateName: 'Samangan',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '3001',
        CityName: 'Sar-e Pul',
        StateCode: '30',
        StateName: 'Sar-e Pul',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '3101',
        CityName: 'Taloqan',
        StateCode: '31',
        StateName: 'Tahar',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '3201',
        CityName: 'Tarin Kowt',
        StateCode: '32',
        StateName: 'Uruzgan',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '3301',
        CityName: 'Meydan Shahr',
        StateCode: '33',
        StateName: 'Vardak',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '3401',
        CityName: 'Qalat',
        StateCode: '34',
        StateName: 'Zabul',
        CountryCode: 'Af',
        CountryName: 'Afganistán',
    },
    {
        CityCode: '0101',
        CityName: 'Berat',
        StateCode: '01',
        StateName: 'Berat',
        CountryCode: 'Al',
        CountryName: 'Albania',
    },
    {
        CityCode: '0201',
        CityName: 'Peshkopi',
        StateCode: '02',
        StateName: 'Dibër',
        CountryCode: 'Al',
        CountryName: 'Albania',
    },
    {
        CityCode: '0301',
        CityName: 'Durrës',
        StateCode: '03',
        StateName: 'Durrës',
        CountryCode: 'Al',
        CountryName: 'Albania',
    },
    {
        CityCode: '0401',
        CityName: 'Elbasan',
        StateCode: '04',
        StateName: 'Elbasan',
        CountryCode: 'Al',
        CountryName: 'Albania',
    },
    {
        CityCode: '0501',
        CityName: 'Fier',
        StateCode: '05',
        StateName: 'Fier',
        CountryCode: 'Al',
        CountryName: 'Albania',
    },
    {
        CityCode: '0601',
        CityName: 'Gjirokastër',
        StateCode: '06',
        StateName: 'Gjirokastër',
        CountryCode: 'Al',
        CountryName: 'Albania',
    },
    {
        CityCode: '0701',
        CityName: 'Korçë',
        StateCode: '07',
        StateName: 'Korçë',
        CountryCode: 'Al',
        CountryName: 'Albania',
    },
    {
        CityCode: '0801',
        CityName: 'Kukës',
        StateCode: '08',
        StateName: 'Kukës',
        CountryCode: 'Al',
        CountryName: 'Albania',
    },
    {
        CityCode: '0901',
        CityName: 'Lezhë',
        StateCode: '09',
        StateName: 'Lezhë',
        CountryCode: 'Al',
        CountryName: 'Albania',
    },
    {
        CityCode: '1001',
        CityName: 'Shkodër',
        StateCode: '10',
        StateName: 'Shkodër',
        CountryCode: 'Al',
        CountryName: 'Albania',
    },
    {
        CityCode: '1101',
        CityName: 'Tirana',
        StateCode: '11',
        StateName: 'Tirana',
        CountryCode: 'Al',
        CountryName: 'Albania',
    },
    {
        CityCode: '1201',
        CityName: 'Vlorë',
        StateCode: '12',
        StateName: 'Vlorë',
        CountryCode: 'Al',
        CountryName: 'Albania',
    },
    {
        CityCode: '0101',
        CityName: 'Stuttgart',
        StateCode: '01',
        StateName: 'Baden-Wurtemberg',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '0201',
        CityName: 'Múnich',
        StateCode: '02',
        StateName: 'Baviera',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '0301',
        CityName: 'München',
        StateCode: '03',
        StateName: 'Bayern',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '0401',
        CityName: 'Berlin',
        StateCode: '04',
        StateName: 'Berlín',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '0501',
        CityName: 'Potsdam',
        StateCode: '05',
        StateName: 'Brandeburgo',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '0601',
        CityName: 'Podstupim',
        StateCode: '06',
        StateName: 'Brandenburg, Bramborska, Brannenborg',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '0701',
        CityName: 'Bremen',
        StateCode: '07',
        StateName: 'Ciudad libre hanseática de Bremen',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '0801',
        CityName: 'Hamburgo',
        StateCode: '08',
        StateName: 'Ciudad libre y hanseática de Hamburgo',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '0901',
        CityName: 'Wiesbaden',
        StateCode: '09',
        StateName: 'Hesse',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '1001',
        CityName: 'Schwerin',
        StateCode: '10',
        StateName: 'Mecklemburgo-Pomerania Occidental',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '1101',
        CityName: 'Hanóver',
        StateCode: '11',
        StateName: 'Baja Sajonia',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '1201',
        CityName: 'Hannover',
        StateCode: '12',
        StateName: 'Niedersachsen',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '1301',
        CityName: 'Düsseldorf',
        StateCode: '13',
        StateName: 'Renania del Norte-Westfalia Nordrhein-Westfalen',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '1401',
        CityName: 'Maguncia',
        StateCode: '14',
        StateName: 'Renania-Palatinado Rheinland-Pfalz',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '1501',
        CityName: 'Saarbrücken',
        StateCode: '15',
        StateName: 'Sarre',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '1601',
        CityName: 'Dresde',
        StateCode: '16',
        StateName: 'Sajonia',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '1701',
        CityName: 'Dresden',
        StateCode: '17',
        StateName: 'Sachsen',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '1801',
        CityName: 'Magdeburgo',
        StateCode: '18',
        StateName: 'Sajonia-Anhalt',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '1901',
        CityName: 'Magdeburg',
        StateCode: '19',
        StateName: 'Sachsen-Anhalt',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '2001',
        CityName: 'Kiel',
        StateCode: '20',
        StateName: 'Schleswig-Holstein',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '2101',
        CityName: 'Érfurt',
        StateCode: '21',
        StateName: 'Turingia',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '2201',
        CityName: 'Erfurt',
        StateCode: '22',
        StateName: 'Thüringen',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '2301',
        CityName: 'Berlín',
        StateCode: '23',
        StateName: 'República Federal de Alemania',
        CountryCode: 'De',
        CountryName: 'Alemania',
    },
    {
        CityCode: '0101',
        CityName: 'Caxito',
        StateCode: '01',
        StateName: 'Bengo',
        CountryCode: 'Ao',
        CountryName: 'Angola',
    },
    {
        CityCode: '0201',
        CityName: 'Benguela',
        StateCode: '02',
        StateName: 'Benguela',
        CountryCode: 'Ao',
        CountryName: 'Angola',
    },
    {
        CityCode: '0301',
        CityName: 'Kuito',
        StateCode: '03',
        StateName: 'Bié',
        CountryCode: 'Ao',
        CountryName: 'Angola',
    },
    {
        CityCode: '0401',
        CityName: 'Cabinda',
        StateCode: '04',
        StateName: 'Cabinda',
        CountryCode: 'Ao',
        CountryName: 'Angola',
    },
    {
        CityCode: '0501',
        CityName: 'Menongue',
        StateCode: '05',
        StateName: 'Kuando Kubango',
        CountryCode: 'Ao',
        CountryName: 'Angola',
    },
    {
        CityCode: '0601',
        CityName: "N'Dalatando",
        StateCode: '06',
        StateName: 'Kwanza-Norte',
        CountryCode: 'Ao',
        CountryName: 'Angola',
    },
    {
        CityCode: '0701',
        CityName: 'Sumbe',
        StateCode: '07',
        StateName: 'Kwanza-Sul',
        CountryCode: 'Ao',
        CountryName: 'Angola',
    },
    {
        CityCode: '0801',
        CityName: "N'Giva",
        StateCode: '08',
        StateName: 'Cunene',
        CountryCode: 'Ao',
        CountryName: 'Angola',
    },
    {
        CityCode: '0901',
        CityName: 'Huambo',
        StateCode: '09',
        StateName: 'Huambo',
        CountryCode: 'Ao',
        CountryName: 'Angola',
    },
    {
        CityCode: '1001',
        CityName: 'Lubango',
        StateCode: '10',
        StateName: 'Huila',
        CountryCode: 'Ao',
        CountryName: 'Angola',
    },
    {
        CityCode: '1101',
        CityName: 'Luanda',
        StateCode: '11',
        StateName: 'Luanda',
        CountryCode: 'Ao',
        CountryName: 'Angola',
    },
    {
        CityCode: '1201',
        CityName: 'Lucapa',
        StateCode: '12',
        StateName: 'Lunda-Norte',
        CountryCode: 'Ao',
        CountryName: 'Angola',
    },
    {
        CityCode: '1301',
        CityName: 'Saurimo',
        StateCode: '13',
        StateName: 'Lunda-Sul',
        CountryCode: 'Ao',
        CountryName: 'Angola',
    },
    {
        CityCode: '1401',
        CityName: 'Malanje',
        StateCode: '14',
        StateName: 'Malanje',
        CountryCode: 'Ao',
        CountryName: 'Angola',
    },
    {
        CityCode: '1501',
        CityName: 'Lwena',
        StateCode: '15',
        StateName: 'Moxico',
        CountryCode: 'Ao',
        CountryName: 'Angola',
    },
    {
        CityCode: '1601',
        CityName: 'Namibe',
        StateCode: '16',
        StateName: 'Namibe',
        CountryCode: 'Ao',
        CountryName: 'Angola',
    },
    {
        CityCode: '1701',
        CityName: 'Uíge',
        StateCode: '17',
        StateName: 'Uíge',
        CountryCode: 'Ao',
        CountryName: 'Angola',
    },
    {
        CityCode: '1801',
        CityName: "M'Banza Kongo",
        StateCode: '18',
        StateName: 'Zaire',
        CountryCode: 'Ao',
        CountryName: 'Angola',
    },
    {
        CityCode: '0101',
        CityName: 'Sakaka',
        StateCode: '01',
        StateName: 'Yauf',
        CountryCode: 'Sa',
        CountryName: 'Arabia Saudita',
    },
    {
        CityCode: '0201',
        CityName: 'Arar',
        StateCode: '02',
        StateName: 'Frontera del Norte',
        CountryCode: 'Sa',
        CountryName: 'Arabia Saudita',
    },
    {
        CityCode: '0301',
        CityName: 'Tabuk',
        StateCode: '03',
        StateName: 'Tabuk',
        CountryCode: 'Sa',
        CountryName: 'Arabia Saudita',
    },
    {
        CityCode: '0401',
        CityName: 'Haíl',
        StateCode: '04',
        StateName: 'Haíl',
        CountryCode: 'Sa',
        CountryName: 'Arabia Saudita',
    },
    {
        CityCode: '0501',
        CityName: 'Medina',
        StateCode: '05',
        StateName: 'Medina',
        CountryCode: 'Sa',
        CountryName: 'Arabia Saudita',
    },
    {
        CityCode: '0601',
        CityName: 'Buraidá',
        StateCode: '06',
        StateName: 'Casim',
        CountryCode: 'Sa',
        CountryName: 'Arabia Saudita',
    },
    {
        CityCode: '0701',
        CityName: 'La Meca',
        StateCode: '07',
        StateName: 'La Meca',
        CountryCode: 'Sa',
        CountryName: 'Arabia Saudita',
    },
    {
        CityCode: '0801',
        CityName: 'Riad',
        StateCode: '08',
        StateName: 'Riad',
        CountryCode: 'Sa',
        CountryName: 'Arabia Saudita',
    },
    {
        CityCode: '0901',
        CityName: 'Dammam',
        StateCode: '09',
        StateName: 'Provincia Oriental',
        CountryCode: 'Sa',
        CountryName: 'Arabia Saudita',
    },
    {
        CityCode: '1001',
        CityName: 'Al Bahah',
        StateCode: '10',
        StateName: 'Baha',
        CountryCode: 'Sa',
        CountryName: 'Arabia Saudita',
    },
    {
        CityCode: '1101',
        CityName: 'Abha',
        StateCode: '11',
        StateName: 'Asir',
        CountryCode: 'Sa',
        CountryName: 'Arabia Saudita',
    },
    {
        CityCode: '1201',
        CityName: 'Jizán',
        StateCode: '12',
        StateName: 'Jizán',
        CountryCode: 'Sa',
        CountryName: 'Arabia Saudita',
    },
    {
        CityCode: '1301',
        CityName: 'Najrán',
        StateCode: '13',
        StateName: 'Najrán',
        CountryCode: 'Sa',
        CountryName: 'Arabia Saudita',
    },
    {
        CityCode: '0101',
        CityName: 'Adrar',
        StateCode: '01',
        StateName: 'Adrar',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '0201',
        CityName: 'Chlef',
        StateCode: '02',
        StateName: 'Chlef',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '0301',
        CityName: 'Laghouat',
        StateCode: '03',
        StateName: 'Laghouat',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '0401',
        CityName: 'Oum el-Bouaghi',
        StateCode: '04',
        StateName: 'Oum el-Bouaghi',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '0501',
        CityName: 'Batna',
        StateCode: '05',
        StateName: 'Batna',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '0601',
        CityName: 'Bugía',
        StateCode: '06',
        StateName: 'Bugía',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '0701',
        CityName: 'Biskra',
        StateCode: '07',
        StateName: 'Biskra',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '0801',
        CityName: 'Béchar',
        StateCode: '08',
        StateName: 'Béchar',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '0901',
        CityName: 'Blida',
        StateCode: '09',
        StateName: 'Blida',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '1001',
        CityName: 'Buira',
        StateCode: '10',
        StateName: 'Buira',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '1101',
        CityName: 'Tamanghasset',
        StateCode: '11',
        StateName: 'Tamanghasset',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '1201',
        CityName: 'Tébessa',
        StateCode: '12',
        StateName: 'Tébessa',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '1301',
        CityName: 'Tlemecén',
        StateCode: '13',
        StateName: 'Tlemecén',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '1401',
        CityName: 'Tiaret',
        StateCode: '14',
        StateName: 'Tiaret',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '1501',
        CityName: 'Tizi Ouzou',
        StateCode: '15',
        StateName: 'Tizi Ouzou',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '1601',
        CityName: 'Argel',
        StateCode: '16',
        StateName: 'Argel',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '1701',
        CityName: 'Djelfa',
        StateCode: '17',
        StateName: 'Djelfa',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '1801',
        CityName: 'Jijel',
        StateCode: '18',
        StateName: 'Jijel',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '1901',
        CityName: 'Sétif',
        StateCode: '19',
        StateName: 'Sétif',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '2001',
        CityName: 'Saida',
        StateCode: '20',
        StateName: 'Saida',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '2101',
        CityName: 'Skikda',
        StateCode: '21',
        StateName: 'Skikda',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '2201',
        CityName: 'Sidi Bel Abbes',
        StateCode: '22',
        StateName: 'Sidi Bel Abbes',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '2301',
        CityName: 'Annaba',
        StateCode: '23',
        StateName: 'Annaba',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '2401',
        CityName: 'Guelma',
        StateCode: '24',
        StateName: 'Guelma',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '2501',
        CityName: 'Constantina',
        StateCode: '25',
        StateName: 'Constantina',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '2601',
        CityName: 'Médéa',
        StateCode: '26',
        StateName: 'Médéa',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '2701',
        CityName: 'Mostaganem',
        StateCode: '27',
        StateName: 'Mostaganem',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '2801',
        CityName: "M'Sila",
        StateCode: '28',
        StateName: "M'Sila",
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '2901',
        CityName: 'Muaskar',
        StateCode: '29',
        StateName: 'Muaskar',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '3001',
        CityName: 'Ouargla',
        StateCode: '30',
        StateName: 'Ouargla',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '3101',
        CityName: 'Orán',
        StateCode: '31',
        StateName: 'Orán',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '3201',
        CityName: 'El Bayadh',
        StateCode: '32',
        StateName: 'El Bayadh',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '3301',
        CityName: 'Illizi',
        StateCode: '33',
        StateName: 'Illizi',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '3401',
        CityName: 'Bordj Bou Arréridj',
        StateCode: '34',
        StateName: 'Bordj Bou Arréridj',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '3501',
        CityName: 'Bumerdés',
        StateCode: '35',
        StateName: 'Bumerdés',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '3601',
        CityName: 'El Tarf',
        StateCode: '36',
        StateName: 'El Tarf',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '3701',
        CityName: 'Tinduf',
        StateCode: '37',
        StateName: 'Tinduf',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '3801',
        CityName: 'Tissemsilt',
        StateCode: '38',
        StateName: 'Tissemsilt',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '3901',
        CityName: 'El Oued',
        StateCode: '39',
        StateName: 'El Oued',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '4001',
        CityName: 'Jenchela',
        StateCode: '40',
        StateName: 'Jenchela',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '4101',
        CityName: 'Souk Ahras',
        StateCode: '41',
        StateName: 'Souk Ahras',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '4201',
        CityName: 'Tipasa',
        StateCode: '42',
        StateName: 'Tipasa',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '4301',
        CityName: 'Mila',
        StateCode: '43',
        StateName: 'Mila',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '4401',
        CityName: 'Aín Defla',
        StateCode: '44',
        StateName: 'Aín Defla',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '4501',
        CityName: 'Naama',
        StateCode: '45',
        StateName: 'Naama',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '4601',
        CityName: 'Aïn Témouchent',
        StateCode: '46',
        StateName: 'Aïn Témouchent',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '4701',
        CityName: 'Gardaya',
        StateCode: '47',
        StateName: 'Gardaya',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '4801',
        CityName: 'Relizan',
        StateCode: '48',
        StateName: 'Relizan',
        CountryCode: 'Dz',
        CountryName: 'Argelia',
    },
    {
        CityCode: '0101',
        CityName: 'Gran Buenos Aires',
        StateCode: '01',
        StateName: 'Buenos Aires - Ciudad de Buenos Aires',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
    {
        CityCode: '0201',
        CityName: 'Gran Córdoba',
        StateCode: '02',
        StateName: 'Córdoba',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
    {
        CityCode: '0301',
        CityName: 'Gran Rosario',
        StateCode: '03',
        StateName: 'Santa Fe',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
    {
        CityCode: '0801',
        CityName: 'Gran Santa Fe',
        StateCode: '03',
        StateName: 'Santa Fe',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
    {
        CityCode: '0401',
        CityName: 'Gran Mendoza',
        StateCode: '04',
        StateName: 'Mendoza',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
    {
        CityCode: '0501',
        CityName: 'Gran San Miguel de Tucumán',
        StateCode: '05',
        StateName: 'Tucumán',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
    {
        CityCode: '0601',
        CityName: 'Gran La Plata',
        StateCode: '06',
        StateName: 'Buenos Aires',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
    {
        CityCode: '0602',
        CityName: 'Mar del Plata',
        StateCode: '06',
        StateName: 'Buenos Aires',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
    {
        CityCode: '1601',
        CityName: 'Gran Bahía Blanca',
        StateCode: '06',
        StateName: 'Buenos Aires',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
    {
        CityCode: '0701',
        CityName: 'Gran Salta',
        StateCode: '07',
        StateName: 'Salta',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
    {
        CityCode: '0901',
        CityName: 'Gran San Juan',
        StateCode: '08',
        StateName: 'San Juan',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
    {
        CityCode: '1001',
        CityName: 'Gran Resistencia',
        StateCode: '09',
        StateName: 'Chaco',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
    {
        CityCode: '1101',
        CityName: 'Santiago del Estero - La Banda',
        StateCode: '10',
        StateName: 'Santiago del Estero',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
    {
        CityCode: '1201',
        CityName: 'Gran Corrientes',
        StateCode: '11',
        StateName: 'Corrientes',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
    {
        CityCode: '1301',
        CityName: 'Neuquén - Plottier - Cipolletti',
        StateCode: '12',
        StateName: 'Neuquén-Río Negro',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
    {
        CityCode: '1401',
        CityName: 'Gran Posadas',
        StateCode: '13',
        StateName: 'Misiones',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
    {
        CityCode: '1501',
        CityName: 'Gran San Salvador de Jujuy',
        StateCode: '14',
        StateName: 'Jujuy',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
    {
        CityCode: '1701',
        CityName: 'Gran Paraná',
        StateCode: '15',
        StateName: 'Entre Ríos',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
    {
        CityCode: '1801',
        CityName: 'Formosa',
        StateCode: '16',
        StateName: 'Formosa',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
    {
        CityCode: '1901',
        CityName: 'Gran San Luis',
        StateCode: '17',
        StateName: 'San Luis',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
    {
        CityCode: '0101',
        CityName: 'Ashtarak',
        StateCode: '01',
        StateName: 'Aragatsotn',
        CountryCode: 'Am',
        CountryName: 'Armenia',
    },
    {
        CityCode: '0201',
        CityName: 'Artashat',
        StateCode: '02',
        StateName: 'Ararat',
        CountryCode: 'Am',
        CountryName: 'Armenia',
    },
    {
        CityCode: '0301',
        CityName: 'Armavir',
        StateCode: '03',
        StateName: 'Armavir',
        CountryCode: 'Am',
        CountryName: 'Armenia',
    },
    {
        CityCode: '0401',
        CityName: 'Gavar',
        StateCode: '04',
        StateName: 'Gegharkunik',
        CountryCode: 'Am',
        CountryName: 'Armenia',
    },
    {
        CityCode: '0501',
        CityName: 'Hrazdan',
        StateCode: '05',
        StateName: 'Kotayk',
        CountryCode: 'Am',
        CountryName: 'Armenia',
    },
    {
        CityCode: '0601',
        CityName: 'Vanadzor',
        StateCode: '06',
        StateName: 'Lorri',
        CountryCode: 'Am',
        CountryName: 'Armenia',
    },
    {
        CityCode: '0701',
        CityName: 'Gyumri',
        StateCode: '07',
        StateName: 'Shirak',
        CountryCode: 'Am',
        CountryName: 'Armenia',
    },
    {
        CityCode: '0801',
        CityName: 'Kapan',
        StateCode: '08',
        StateName: 'Syunik',
        CountryCode: 'Am',
        CountryName: 'Armenia',
    },
    {
        CityCode: '0901',
        CityName: 'Ijevan',
        StateCode: '09',
        StateName: 'Tavush',
        CountryCode: 'Am',
        CountryName: 'Armenia',
    },
    {
        CityCode: '1001',
        CityName: 'Yeghegnadzor',
        StateCode: '10',
        StateName: 'Vayots Dzor',
        CountryCode: 'Am',
        CountryName: 'Armenia',
    },
    {
        CityCode: '1101',
        CityName: 'Yereván',
        StateCode: '11',
        StateName: 'Yereván',
        CountryCode: 'Am',
        CountryName: 'Armenia',
    },
    {
        CityCode: '0101',
        CityName: 'Sídney',
        StateCode: '01',
        StateName: 'Nueva Gales del Sur',
        CountryCode: 'Au',
        CountryName: 'Australia',
    },
    {
        CityCode: '0201',
        CityName: 'Melbourne',
        StateCode: '02',
        StateName: 'Victoria',
        CountryCode: 'Au',
        CountryName: 'Australia',
    },
    {
        CityCode: '0301',
        CityName: 'Brisbane',
        StateCode: '03',
        StateName: 'Queensland',
        CountryCode: 'Au',
        CountryName: 'Australia',
    },
    {
        CityCode: '0401',
        CityName: 'Perth',
        StateCode: '04',
        StateName: 'Australia Occidental',
        CountryCode: 'Au',
        CountryName: 'Australia',
    },
    {
        CityCode: '0501',
        CityName: 'Adelaida',
        StateCode: '05',
        StateName: 'Australia Meridional',
        CountryCode: 'Au',
        CountryName: 'Australia',
    },
    {
        CityCode: '0601',
        CityName: 'Canberra',
        StateCode: '06',
        StateName: 'Territorio de la Capital Australiana',
        CountryCode: 'Au',
        CountryName: 'Australia',
    },
    {
        CityCode: '0701',
        CityName: 'Hobart',
        StateCode: '07',
        StateName: 'Tasmania',
        CountryCode: 'Au',
        CountryName: 'Australia',
    },
    {
        CityCode: '0801',
        CityName: 'Darwin',
        StateCode: '08',
        StateName: 'Territorio del Norte',
        CountryCode: 'Au',
        CountryName: 'Australia',
    },
    {
        CityCode: '0101',
        CityName: 'Eisenstadt',
        StateCode: '01',
        StateName: 'Burgenland',
        CountryCode: 'At',
        CountryName: 'Austria',
    },
    {
        CityCode: '0201',
        CityName: 'Klagenfurt',
        StateCode: '02',
        StateName: 'Carintia',
        CountryCode: 'At',
        CountryName: 'Austria',
    },
    {
        CityCode: '0301',
        CityName: 'Sankt Pölten',
        StateCode: '03',
        StateName: 'Baja Austria',
        CountryCode: 'At',
        CountryName: 'Austria',
    },
    {
        CityCode: '0401',
        CityName: 'Salzburgo',
        StateCode: '04',
        StateName: 'Salzburgo',
        CountryCode: 'At',
        CountryName: 'Austria',
    },
    {
        CityCode: '0501',
        CityName: 'Graz',
        StateCode: '05',
        StateName: 'Estiria',
        CountryCode: 'At',
        CountryName: 'Austria',
    },
    {
        CityCode: '0601',
        CityName: 'Innsbruck',
        StateCode: '06',
        StateName: 'Tirol',
        CountryCode: 'At',
        CountryName: 'Austria',
    },
    {
        CityCode: '0701',
        CityName: 'Linz',
        StateCode: '07',
        StateName: 'Alta Austria',
        CountryCode: 'At',
        CountryName: 'Austria',
    },
    {
        CityCode: '0801',
        CityName: 'Viena',
        StateCode: '08',
        StateName: 'Viena',
        CountryCode: 'At',
        CountryName: 'Austria',
    },
    {
        CityCode: '0901',
        CityName: 'Bregenz',
        StateCode: '09',
        StateName: 'Vorarlberg',
        CountryCode: 'At',
        CountryName: 'Austria',
    },
    {
        CityCode: '0101',
        CityName: 'Bakú',
        StateCode: '01',
        StateName: 'Bakú',
        CountryCode: 'Az',
        CountryName: 'Azerbaiyán',
    },
    {
        CityCode: '0201',
        CityName: 'Ganja',
        StateCode: '02',
        StateName: 'Ganja',
        CountryCode: 'Az',
        CountryName: 'Azerbaiyán',
    },
    {
        CityCode: '0301',
        CityName: 'Sumqayit',
        StateCode: '03',
        StateName: 'Sumqayit',
        CountryCode: 'Az',
        CountryName: 'Azerbaiyán',
    },
    {
        CityCode: '0401',
        CityName: 'Mingachevir',
        StateCode: '04',
        StateName: 'Mingachevir',
        CountryCode: 'Az',
        CountryName: 'Azerbaiyán',
    },
    {
        CityCode: '0501',
        CityName: 'Lankaran',
        StateCode: '05',
        StateName: 'Lankaran',
        CountryCode: 'Az',
        CountryName: 'Azerbaiyán',
    },
    {
        CityCode: '0601',
        CityName: 'Shirvan',
        StateCode: '06',
        StateName: 'Shirvan',
        CountryCode: 'Az',
        CountryName: 'Azerbaiyán',
    },
    {
        CityCode: '0701',
        CityName: 'Najicheván',
        StateCode: '07',
        StateName: 'Najicheván',
        CountryCode: 'Az',
        CountryName: 'Azerbaiyán',
    },
    {
        CityCode: '0801',
        CityName: 'Smkir',
        StateCode: '08',
        StateName: 'Smkir',
        CountryCode: 'Az',
        CountryName: 'Azerbaiyán',
    },
    {
        CityCode: '0901',
        CityName: 'Ski',
        StateCode: '09',
        StateName: 'Ski',
        CountryCode: 'Az',
        CountryName: 'Azerbaiyán',
    },
    {
        CityCode: '1001',
        CityName: 'Yevlax',
        StateCode: '10',
        StateName: 'Yevlax',
        CountryCode: 'Az',
        CountryName: 'Azerbaiyán',
    },
    {
        CityCode: '1101',
        CityName: 'Xaçmaz',
        StateCode: '11',
        StateName: 'Xaçmaz',
        CountryCode: 'Az',
        CountryName: 'Azerbaiyán',
    },
    {
        CityCode: '1201',
        CityName: 'Clilabad',
        StateCode: '12',
        StateName: 'Clilabad',
        CountryCode: 'Az',
        CountryName: 'Azerbaiyán',
    },
    {
        CityCode: '1301',
        CityName: 'Stepanakert',
        StateCode: '13',
        StateName: 'Stepanakert',
        CountryCode: 'Az',
        CountryName: 'Azerbaiyán',
    },
    {
        CityCode: '1401',
        CityName: 'Agcabdi',
        StateCode: '14',
        StateName: 'Agcabdi',
        CountryCode: 'Az',
        CountryName: 'Azerbaiyán',
    },
    {
        CityCode: '1501',
        CityName: 'Samaxi',
        StateCode: '15',
        StateName: 'Samaxi',
        CountryCode: 'Az',
        CountryName: 'Azerbaiyán',
    },
    {
        CityCode: '1601',
        CityName: 'Salyan',
        StateCode: '16',
        StateName: 'Salyan',
        CountryCode: 'Az',
        CountryName: 'Azerbaiyán',
    },
    {
        CityCode: '1701',
        CityName: 'Agdam',
        StateCode: '17',
        StateName: 'Agdam',
        CountryCode: 'Az',
        CountryName: 'Azerbaiyán',
    },
    {
        CityCode: '1801',
        CityName: 'Neftçala',
        StateCode: '18',
        StateName: 'Neftçala',
        CountryCode: 'Az',
        CountryName: 'Azerbaiyán',
    },
    {
        CityCode: '1901',
        CityName: 'Quba',
        StateCode: '19',
        StateName: 'Quba',
        CountryCode: 'Az',
        CountryName: 'Azerbaiyán',
    },
    {
        CityCode: '2001',
        CityName: 'Brd',
        StateCode: '20',
        StateName: 'Brd',
        CountryCode: 'Az',
        CountryName: 'Azerbaiyán',
    },
    {
        CityCode: '0101',
        CityName: 'Spring Point',
        StateCode: '01',
        StateName: 'Acklins',
        CountryCode: 'Bs',
        CountryName: 'Bahamas',
    },
    {
        CityCode: '0201',
        CityName: 'Nasáu',
        StateCode: '02',
        StateName: 'Nueva providencia',
        CountryCode: 'Bs',
        CountryName: 'Bahamas',
    },
    {
        CityCode: '0101',
        CityName: 'Daca',
        StateCode: '01',
        StateName: 'Daca',
        CountryCode: 'Bd',
        CountryName: 'Bangladés',
    },
    {
        CityCode: '0201',
        CityName: 'Chittagong',
        StateCode: '02',
        StateName: 'Chittagong',
        CountryCode: 'Bd',
        CountryName: 'Bangladés',
    },
    {
        CityCode: '0301',
        CityName: 'Daca',
        StateCode: '03',
        StateName: 'Narayanganj',
        CountryCode: 'Bd',
        CountryName: 'Bangladés',
    },
    {
        CityCode: '0401',
        CityName: 'Khulna',
        StateCode: '04',
        StateName: 'Khulna',
        CountryCode: 'Bd',
        CountryName: 'Bangladés',
    },
    {
        CityCode: '0501',
        CityName: 'Rajshahi',
        StateCode: '05',
        StateName: 'Rajshahi',
        CountryCode: 'Bd',
        CountryName: 'Bangladés',
    },
    {
        CityCode: '0601',
        CityName: 'Sylhet',
        StateCode: '06',
        StateName: 'Sylhet',
        CountryCode: 'Bd',
        CountryName: 'Bangladés',
    },
    {
        CityCode: '0701',
        CityName: 'Daca',
        StateCode: '07',
        StateName: 'Mymensingh',
        CountryCode: 'Bd',
        CountryName: 'Bangladés',
    },
    {
        CityCode: '0801',
        CityName: 'Chittagong',
        StateCode: '08',
        StateName: 'Comilla',
        CountryCode: 'Bd',
        CountryName: 'Bangladés',
    },
    {
        CityCode: '0901',
        CityName: 'Rajshahi',
        StateCode: '09',
        StateName: 'Rangpur',
        CountryCode: 'Bd',
        CountryName: 'Bangladés',
    },
    {
        CityCode: '1001',
        CityName: 'Barisal',
        StateCode: '10',
        StateName: 'Barisal',
        CountryCode: 'Bd',
        CountryName: 'Bangladés',
    },
    {
        CityCode: '1101',
        CityName: 'Rajshahi',
        StateCode: '11',
        StateName: 'Bogra',
        CountryCode: 'Bd',
        CountryName: 'Bangladés',
    },
    {
        CityCode: '1201',
        CityName: 'Khulna',
        StateCode: '12',
        StateName: 'Jessore',
        CountryCode: 'Bd',
        CountryName: 'Bangladés',
    },
    {
        CityCode: '0101',
        CityName: 'Oistins',
        StateCode: '01',
        StateName: 'Christ Church',
        CountryCode: 'Bb',
        CountryName: 'Barbados',
    },
    {
        CityCode: '0201',
        CityName: 'Belleplaine',
        StateCode: '02',
        StateName: 'Saint Andrew',
        CountryCode: 'Bb',
        CountryName: 'Barbados',
    },
    {
        CityCode: '0301',
        CityName: 'The Glebe',
        StateCode: '03',
        StateName: 'Saint George',
        CountryCode: 'Bb',
        CountryName: 'Barbados',
    },
    {
        CityCode: '0401',
        CityName: 'Holetown',
        StateCode: '04',
        StateName: 'Saint James',
        CountryCode: 'Bb',
        CountryName: 'Barbados',
    },
    {
        CityCode: '0501',
        CityName: 'Bathsheba',
        StateCode: '05',
        StateName: 'Saint Joseph',
        CountryCode: 'Bb',
        CountryName: 'Barbados',
    },
    {
        CityCode: '0601',
        CityName: 'Checker Hall',
        StateCode: '06',
        StateName: 'Saint Lucy',
        CountryCode: 'Bb',
        CountryName: 'Barbados',
    },
    {
        CityCode: '0701',
        CityName: 'Bridgetown',
        StateCode: '07',
        StateName: 'Saint Michael',
        CountryCode: 'Bb',
        CountryName: 'Barbados',
    },
    {
        CityCode: '0801',
        CityName: 'Speightstown',
        StateCode: '08',
        StateName: 'Saint Peter',
        CountryCode: 'Bb',
        CountryName: 'Barbados',
    },
    {
        CityCode: '0901',
        CityName: 'Six Cross Roads',
        StateCode: '09',
        StateName: 'Saint Philip',
        CountryCode: 'Bb',
        CountryName: 'Barbados',
    },
    {
        CityCode: '1001',
        CityName: 'Sturges',
        StateCode: '10',
        StateName: 'Saint Thomas',
        CountryCode: 'Bb',
        CountryName: 'Barbados',
    },
    {
        CityCode: '1101',
        CityName: 'Parroquia',
        StateCode: '11',
        StateName: 'Saint John',
        CountryCode: 'Bb',
        CountryName: 'Barbados',
    },
    {
        CityCode: '0101',
        CityName: 'Al Muharraq',
        StateCode: '01',
        StateName: 'Muharraq',
        CountryCode: 'Bh',
        CountryName: 'Baréin',
    },
    {
        CityCode: '0201',
        CityName: 'Riffa',
        StateCode: '02',
        StateName: 'Sur',
        CountryCode: 'Bh',
        CountryName: 'Baréin',
    },
    {
        CityCode: '0301',
        CityName: 'Madinat Isa',
        StateCode: '03',
        StateName: 'Central',
        CountryCode: 'Bh',
        CountryName: 'Baréin',
    },
    {
        CityCode: '0401',
        CityName: 'Jidhafs',
        StateCode: '04',
        StateName: 'Norte',
        CountryCode: 'Bh',
        CountryName: 'Baréin',
    },
    {
        CityCode: '0101',
        CityName: 'Bruselas',
        StateCode: '01',
        StateName: 'Comunidad Flamenca',
        CountryCode: 'Be',
        CountryName: 'Bélgica',
    },
    {
        CityCode: '0201',
        CityName: 'Bruselas',
        StateCode: '02',
        StateName: 'Comunidad Francesa',
        CountryCode: 'Be',
        CountryName: 'Bélgica',
    },
    {
        CityCode: '0301',
        CityName: 'Eupen',
        StateCode: '03',
        StateName: 'Comunidad Germanófona',
        CountryCode: 'Be',
        CountryName: 'Bélgica',
    },
    {
        CityCode: '0101',
        CityName: 'Ciudad de Belice',
        StateCode: '01',
        StateName: 'Belice',
        CountryCode: 'Bz',
        CountryName: 'Belice',
    },
    {
        CityCode: '0201',
        CityName: 'San Ignacio',
        StateCode: '02',
        StateName: 'Cayo',
        CountryCode: 'Bz',
        CountryName: 'Belice',
    },
    {
        CityCode: '0301',
        CityName: 'Corozal',
        StateCode: '03',
        StateName: 'Corozal',
        CountryCode: 'Bz',
        CountryName: 'Belice',
    },
    {
        CityCode: '0401',
        CityName: 'Orange Walk Town',
        StateCode: '04',
        StateName: 'Orange Walk',
        CountryCode: 'Bz',
        CountryName: 'Belice',
    },
    {
        CityCode: '0501',
        CityName: 'Dangriga',
        StateCode: '05',
        StateName: 'Stann Creek',
        CountryCode: 'Bz',
        CountryName: 'Belice',
    },
    {
        CityCode: '0601',
        CityName: 'Punta Gorda',
        StateCode: '06',
        StateName: 'Toledo',
        CountryCode: 'Bz',
        CountryName: 'Belice',
    },
    {
        CityCode: '0101',
        CityName: 'Kandi',
        StateCode: '01',
        StateName: 'Alibori',
        CountryCode: 'Bj',
        CountryName: 'Benín',
    },
    {
        CityCode: '0201',
        CityName: 'Natitingou',
        StateCode: '02',
        StateName: 'Atakora',
        CountryCode: 'Bj',
        CountryName: 'Benín',
    },
    {
        CityCode: '0301',
        CityName: 'Ouidah',
        StateCode: '03',
        StateName: 'Atlantique',
        CountryCode: 'Bj',
        CountryName: 'Benín',
    },
    {
        CityCode: '0401',
        CityName: 'Parakou',
        StateCode: '04',
        StateName: 'Borgou',
        CountryCode: 'Bj',
        CountryName: 'Benín',
    },
    {
        CityCode: '0501',
        CityName: 'Savalou',
        StateCode: '05',
        StateName: 'Collines',
        CountryCode: 'Bj',
        CountryName: 'Benín',
    },
    {
        CityCode: '0601',
        CityName: 'Djougou',
        StateCode: '06',
        StateName: 'Donga',
        CountryCode: 'Bj',
        CountryName: 'Benín',
    },
    {
        CityCode: '0701',
        CityName: 'Aplahoué',
        StateCode: '07',
        StateName: 'Kouffo',
        CountryCode: 'Bj',
        CountryName: 'Benín',
    },
    {
        CityCode: '0801',
        CityName: 'Cotonú',
        StateCode: '08',
        StateName: 'Litoral',
        CountryCode: 'Bj',
        CountryName: 'Benín',
    },
    {
        CityCode: '0901',
        CityName: 'Lokossa',
        StateCode: '09',
        StateName: 'Mono',
        CountryCode: 'Bj',
        CountryName: 'Benín',
    },
    {
        CityCode: '1001',
        CityName: 'Porto Novo',
        StateCode: '10',
        StateName: 'Ouémé',
        CountryCode: 'Bj',
        CountryName: 'Benín',
    },
    {
        CityCode: '1101',
        CityName: 'Pobè',
        StateCode: '11',
        StateName: 'Plateau',
        CountryCode: 'Bj',
        CountryName: 'Benín',
    },
    {
        CityCode: '1201',
        CityName: 'Abomey',
        StateCode: '12',
        StateName: 'Zou',
        CountryCode: 'Bj',
        CountryName: 'Benín',
    },
    {
        CityCode: '0101',
        CityName: 'Minsk',
        StateCode: '01',
        StateName: 'Minsk',
        CountryCode: 'By',
        CountryName: 'Bielorrusia',
    },
    {
        CityCode: '0201',
        CityName: 'Gómel',
        StateCode: '02',
        StateName: 'Gómel',
        CountryCode: 'By',
        CountryName: 'Bielorrusia',
    },
    {
        CityCode: '0301',
        CityName: 'Maguilov',
        StateCode: '03',
        StateName: 'Maguilov',
        CountryCode: 'By',
        CountryName: 'Bielorrusia',
    },
    {
        CityCode: '0401',
        CityName: 'Vítebsk',
        StateCode: '04',
        StateName: 'Vítebsk',
        CountryCode: 'By',
        CountryName: 'Bielorrusia',
    },
    {
        CityCode: '0501',
        CityName: 'Grodno',
        StateCode: '05',
        StateName: 'Grodno',
        CountryCode: 'By',
        CountryName: 'Bielorrusia',
    },
    {
        CityCode: '0601',
        CityName: 'Brest',
        StateCode: '06',
        StateName: 'Brest',
        CountryCode: 'By',
        CountryName: 'Bielorrusia',
    },
    {
        CityCode: '0701',
        CityName: 'Babruisk',
        StateCode: '07',
        StateName: 'Babruisk',
        CountryCode: 'By',
        CountryName: 'Bielorrusia',
    },
    {
        CityCode: '0801',
        CityName: 'Baránavichi',
        StateCode: '08',
        StateName: 'Baránavichi',
        CountryCode: 'By',
        CountryName: 'Bielorrusia',
    },
    {
        CityCode: '0901',
        CityName: 'Borisov',
        StateCode: '09',
        StateName: 'Borisov',
        CountryCode: 'By',
        CountryName: 'Bielorrusia',
    },
    {
        CityCode: '1001',
        CityName: 'Orsha',
        StateCode: '10',
        StateName: 'Orsha',
        CountryCode: 'By',
        CountryName: 'Bielorrusia',
    },
    {
        CityCode: '0101',
        CityName: 'Trinidad',
        StateCode: '01',
        StateName: 'Beni',
        CountryCode: 'Bo',
        CountryName: 'Bolivia',
    },
    {
        CityCode: '0201',
        CityName: 'Sucre',
        StateCode: '02',
        StateName: 'Chuquisaca',
        CountryCode: 'Bo',
        CountryName: 'Bolivia',
    },
    {
        CityCode: '0301',
        CityName: 'Cochabamba',
        StateCode: '03',
        StateName: 'Cochabamba',
        CountryCode: 'Bo',
        CountryName: 'Bolivia',
    },
    {
        CityCode: '0401',
        CityName: 'Nuestra Señora de La Paz',
        StateCode: '04',
        StateName: 'La Paz',
        CountryCode: 'Bo',
        CountryName: 'Bolivia',
    },
    {
        CityCode: '0501',
        CityName: 'Oruro',
        StateCode: '05',
        StateName: 'Oruro',
        CountryCode: 'Bo',
        CountryName: 'Bolivia',
    },
    {
        CityCode: '0601',
        CityName: 'Cobija',
        StateCode: '06',
        StateName: 'Pando',
        CountryCode: 'Bo',
        CountryName: 'Bolivia',
    },
    {
        CityCode: '0701',
        CityName: 'Potosí',
        StateCode: '07',
        StateName: 'Potosí',
        CountryCode: 'Bo',
        CountryName: 'Bolivia',
    },
    {
        CityCode: '0801',
        CityName: 'Santa Cruz de la Sierra',
        StateCode: '08',
        StateName: 'Santa Cruz',
        CountryCode: 'Bo',
        CountryName: 'Bolivia',
    },
    {
        CityCode: '0901',
        CityName: 'Tarija',
        StateCode: '09',
        StateName: 'Tarija',
        CountryCode: 'Bo',
        CountryName: 'Bolivia',
    },
    {
        CityCode: '0101',
        CityName: 'Kralendijk',
        StateCode: '01',
        StateName: 'Bonaire',
        CountryCode: 'Bq',
        CountryName: 'Bonaire, San Eustaquio Y Saba',
    },
    {
        CityCode: '0201',
        CityName: 'Oranjestad (San Eustaquio)',
        StateCode: '02',
        StateName: 'San Eustaquio',
        CountryCode: 'Bq',
        CountryName: 'Bonaire, San Eustaquio Y Saba',
    },
    {
        CityCode: '0301',
        CityName: 'The Bottom',
        StateCode: '03',
        StateName: 'Saba',
        CountryCode: 'Bq',
        CountryName: 'Bonaire, San Eustaquio Y Saba',
    },
    {
        CityCode: '0101',
        CityName: 'Bihac',
        StateCode: '01',
        StateName: 'Cantón de Una-Sana',
        CountryCode: 'Ba',
        CountryName: 'Bosnia-Herzegovina',
    },
    {
        CityCode: '0201',
        CityName: 'Oraje',
        StateCode: '02',
        StateName: 'Cantón de Posavina',
        CountryCode: 'Ba',
        CountryName: 'Bosnia-Herzegovina',
    },
    {
        CityCode: '0301',
        CityName: 'Tuzla',
        StateCode: '03',
        StateName: 'Cantón de Tuzla',
        CountryCode: 'Ba',
        CountryName: 'Bosnia-Herzegovina',
    },
    {
        CityCode: '0401',
        CityName: 'Zenica',
        StateCode: '04',
        StateName: 'Zenicko-dobojski kanton',
        CountryCode: 'Ba',
        CountryName: 'Bosnia-Herzegovina',
    },
    {
        CityCode: '0501',
        CityName: 'Gorade',
        StateCode: '05',
        StateName: 'Cantón de Podrinje Bosnio',
        CountryCode: 'Ba',
        CountryName: 'Bosnia-Herzegovina',
    },
    {
        CityCode: '0601',
        CityName: 'Travnik',
        StateCode: '06',
        StateName: 'Cantón de Bosnia Central',
        CountryCode: 'Ba',
        CountryName: 'Bosnia-Herzegovina',
    },
    {
        CityCode: '0701',
        CityName: 'Mostar',
        StateCode: '07',
        StateName: 'Cantón de Herzegovina-Neretva',
        CountryCode: 'Ba',
        CountryName: 'Bosnia-Herzegovina',
    },
    {
        CityCode: '0801',
        CityName: 'iroki Brijeg',
        StateCode: '08',
        StateName: 'Cantón de Herzegovina Occidental',
        CountryCode: 'Ba',
        CountryName: 'Bosnia-Herzegovina',
    },
    {
        CityCode: '0901',
        CityName: 'Sarajevo',
        StateCode: '09',
        StateName: 'Cantón de Sarajevo',
        CountryCode: 'Ba',
        CountryName: 'Bosnia-Herzegovina',
    },
    {
        CityCode: '1001',
        CityName: 'Livno',
        StateCode: '10',
        StateName: 'Condado de Herzeg-Bosnia',
        CountryCode: 'Ba',
        CountryName: 'Bosnia-Herzegovina',
    },
    {
        CityCode: '0101',
        CityName: 'Serowe',
        StateCode: '01',
        StateName: 'Distrito Central',
        CountryCode: 'Bw',
        CountryName: 'Botsuana',
    },
    {
        CityCode: '0201',
        CityName: 'Ghanzi',
        StateCode: '02',
        StateName: 'Distrito de Ghanzi',
        CountryCode: 'Bw',
        CountryName: 'Botsuana',
    },
    {
        CityCode: '0301',
        CityName: 'Tsabong',
        StateCode: '03',
        StateName: 'Distrito de Kgalagadi',
        CountryCode: 'Bw',
        CountryName: 'Botsuana',
    },
    {
        CityCode: '0401',
        CityName: 'Mochudi',
        StateCode: '04',
        StateName: 'Distrito de Kgatleng',
        CountryCode: 'Bw',
        CountryName: 'Botsuana',
    },
    {
        CityCode: '0501',
        CityName: 'Molepolole',
        StateCode: '05',
        StateName: 'Distrito de Kweneng',
        CountryCode: 'Bw',
        CountryName: 'Botsuana',
    },
    {
        CityCode: '0601',
        CityName: 'Masunga',
        StateCode: '06',
        StateName: 'Distrito Noreste',
        CountryCode: 'Bw',
        CountryName: 'Botsuana',
    },
    {
        CityCode: '0701',
        CityName: 'Maun',
        StateCode: '07',
        StateName: 'Noroeste Central',
        CountryCode: 'Bw',
        CountryName: 'Botsuana',
    },
    {
        CityCode: '0801',
        CityName: 'Ramotswa',
        StateCode: '08',
        StateName: 'Distrito Sudeste',
        CountryCode: 'Bw',
        CountryName: 'Botsuana',
    },
    {
        CityCode: '0901',
        CityName: 'Kanye',
        StateCode: '09',
        StateName: 'Distrito Sur',
        CountryCode: 'Bw',
        CountryName: 'Botsuana',
    },
    {
        CityCode: '0401',
        CityName: 'Manaos',
        StateCode: '04',
        StateName: 'Amazonas',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '0101',
        CityName: 'Rio Branco',
        StateCode: '01',
        StateName: 'Acre',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '0201',
        CityName: 'Maceió',
        StateCode: '02',
        StateName: 'Alagoas',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '0301',
        CityName: 'Macapá',
        StateCode: '03',
        StateName: 'Amapá',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '0501',
        CityName: 'Salvador de Bahía',
        StateCode: '05',
        StateName: 'Bahía',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '0601',
        CityName: 'Fortaleza',
        StateCode: '06',
        StateName: 'Ceará',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '0701',
        CityName: 'Vitória',
        StateCode: '07',
        StateName: 'Espírito Santo',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '0801',
        CityName: 'Goiânia',
        StateCode: '08',
        StateName: 'Goiás',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '0901',
        CityName: 'São Luís',
        StateCode: '09',
        StateName: 'Maranhão',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '1001',
        CityName: 'Cuiabá',
        StateCode: '10',
        StateName: 'Mato Grosso',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '1101',
        CityName: 'Campo Grande',
        StateCode: '11',
        StateName: 'Mato Grosso del Sur',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '1201',
        CityName: 'Belo Horizonte',
        StateCode: '12',
        StateName: 'Minas Gerais',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '1301',
        CityName: 'Belém do Pará',
        StateCode: '13',
        StateName: 'Estado de Pará',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '1401',
        CityName: 'João Pessoa',
        StateCode: '14',
        StateName: 'Paraíba',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '1501',
        CityName: 'Curitiba',
        StateCode: '15',
        StateName: 'Paraná',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '1601',
        CityName: 'Recife',
        StateCode: '16',
        StateName: 'Estado de Pernambuco',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '1701',
        CityName: 'Teresina',
        StateCode: '17',
        StateName: 'Piauí',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '1801',
        CityName: 'Río de Janeiro',
        StateCode: '18',
        StateName: 'Río de Janeiro',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '1901',
        CityName: 'Natal',
        StateCode: '19',
        StateName: 'Río Grande del Norte',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '2001',
        CityName: 'Porto Alegre',
        StateCode: '20',
        StateName: 'Río Grande del Sur',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '2101',
        CityName: 'Porto Velho',
        StateCode: '21',
        StateName: 'Rondônia',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '2201',
        CityName: 'Boa Vista',
        StateCode: '22',
        StateName: 'Roraima',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '2301',
        CityName: 'Florianópolis',
        StateCode: '23',
        StateName: 'Santa Catarina',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '2401',
        CityName: 'São Paulo',
        StateCode: '24',
        StateName: 'São Paulo',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '2501',
        CityName: 'Aracaju',
        StateCode: '25',
        StateName: 'Sergipe',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '2601',
        CityName: 'Palmas',
        StateCode: '26',
        StateName: 'Tocantins',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '2701',
        CityName: 'Brasilia',
        StateCode: '27',
        StateName: 'Distrito Federal',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '2801',
        CityName: 'Brasilia',
        StateCode: '28',
        StateName: 'República Federal de Brasil',
        CountryCode: 'Br',
        CountryName: 'Brasil',
    },
    {
        CityCode: '0101',
        CityName: 'Kuala Belait',
        StateCode: '01',
        StateName: 'Belait',
        CountryCode: 'Bn',
        CountryName: 'Brunéi',
    },
    {
        CityCode: '0201',
        CityName: 'Tutong',
        StateCode: '02',
        StateName: 'Tutong',
        CountryCode: 'Bn',
        CountryName: 'Brunéi',
    },
    {
        CityCode: '0301',
        CityName: 'Bandar Seri Begawan',
        StateCode: '03',
        StateName: 'Brunéi y Muara',
        CountryCode: 'Bn',
        CountryName: 'Brunéi',
    },
    {
        CityCode: '0401',
        CityName: 'Bangar',
        StateCode: '04',
        StateName: 'Temburong',
        CountryCode: 'Bn',
        CountryName: 'Brunéi',
    },
    {
        CityCode: '0101',
        CityName: 'Blagoevgrad',
        StateCode: '01',
        StateName: 'Blagoevgrad',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '0201',
        CityName: 'Burgas',
        StateCode: '02',
        StateName: 'Burgas',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '0301',
        CityName: 'Dobrich',
        StateCode: '03',
        StateName: 'Dobrich',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '0401',
        CityName: 'Gabrovo',
        StateCode: '04',
        StateName: 'Gabrovo',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '0501',
        CityName: 'Haskovo',
        StateCode: '05',
        StateName: 'Haskovo',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '0601',
        CityName: 'Kardzhali',
        StateCode: '06',
        StateName: 'Kardzhali',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '0701',
        CityName: 'Kyustendil',
        StateCode: '07',
        StateName: 'Kyustendil',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '0801',
        CityName: 'Lovech',
        StateCode: '08',
        StateName: 'Lovech',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '0901',
        CityName: 'Montana',
        StateCode: '09',
        StateName: 'Montana',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '1001',
        CityName: 'Pazardzhik',
        StateCode: '10',
        StateName: 'Pazardzhik',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '1101',
        CityName: 'Pernik',
        StateCode: '11',
        StateName: 'Pernik',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '1201',
        CityName: 'Pleven',
        StateCode: '12',
        StateName: 'Pleven',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '1301',
        CityName: 'Plovdiv',
        StateCode: '13',
        StateName: 'Plovdiv',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '1401',
        CityName: 'Razgrad',
        StateCode: '14',
        StateName: 'Razgrad',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '1501',
        CityName: 'Ruse',
        StateCode: '15',
        StateName: 'Ruse',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '1601',
        CityName: 'Shumen',
        StateCode: '16',
        StateName: 'Shumen',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '1701',
        CityName: 'Silistra',
        StateCode: '17',
        StateName: 'Silistra',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '1801',
        CityName: 'Sliven',
        StateCode: '18',
        StateName: 'Sliven',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '1901',
        CityName: 'Smolyan',
        StateCode: '19',
        StateName: 'Smolyan',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '2001',
        CityName: 'Sofía',
        StateCode: '20',
        StateName: 'Sofía',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '2101',
        CityName: 'Sofía-Ciudad',
        StateCode: '21',
        StateName: 'Sofía-Ciudad',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '2201',
        CityName: 'Stara Zagora',
        StateCode: '22',
        StateName: 'Stara Zagora',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '2301',
        CityName: 'Targovishte',
        StateCode: '23',
        StateName: 'Targovishte',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '2401',
        CityName: 'Varna',
        StateCode: '24',
        StateName: 'Varna',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '2501',
        CityName: 'Veliko Tarnovo',
        StateCode: '25',
        StateName: 'Veliko Tarnovo',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '2601',
        CityName: 'Vidin',
        StateCode: '26',
        StateName: 'Vidin',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '2701',
        CityName: 'Vratsa',
        StateCode: '27',
        StateName: 'Vratsa',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '2801',
        CityName: 'Yambol',
        StateCode: '28',
        StateName: 'Yambol',
        CountryCode: 'Bg',
        CountryName: 'Bulgaria',
    },
    {
        CityCode: '0101',
        CityName: 'Dédougou',
        StateCode: '01',
        StateName: 'Boucle du Mouhoun',
        CountryCode: 'Bf',
        CountryName: 'Burkina Faso',
    },
    {
        CityCode: '0201',
        CityName: 'Banfora',
        StateCode: '02',
        StateName: 'Cascades',
        CountryCode: 'Bf',
        CountryName: 'Burkina Faso',
    },
    {
        CityCode: '0301',
        CityName: 'Uagadugú',
        StateCode: '03',
        StateName: 'Centro',
        CountryCode: 'Bf',
        CountryName: 'Burkina Faso',
    },
    {
        CityCode: '0401',
        CityName: 'Tenkodogo',
        StateCode: '04',
        StateName: 'Centro-Este',
        CountryCode: 'Bf',
        CountryName: 'Burkina Faso',
    },
    {
        CityCode: '0501',
        CityName: 'Kaya',
        StateCode: '05',
        StateName: 'Centro-Norte',
        CountryCode: 'Bf',
        CountryName: 'Burkina Faso',
    },
    {
        CityCode: '0601',
        CityName: 'Koudougou',
        StateCode: '06',
        StateName: 'Centro-Oeste',
        CountryCode: 'Bf',
        CountryName: 'Burkina Faso',
    },
    {
        CityCode: '0701',
        CityName: 'Manga',
        StateCode: '07',
        StateName: 'Centro-Sur',
        CountryCode: 'Bf',
        CountryName: 'Burkina Faso',
    },
    {
        CityCode: '0801',
        CityName: "Fada N'gourma",
        StateCode: '08',
        StateName: 'Este',
        CountryCode: 'Bf',
        CountryName: 'Burkina Faso',
    },
    {
        CityCode: '0901',
        CityName: 'Bobo Dioulasso',
        StateCode: '09',
        StateName: 'Hauts-Bassins',
        CountryCode: 'Bf',
        CountryName: 'Burkina Faso',
    },
    {
        CityCode: '1001',
        CityName: 'Ouahigouya',
        StateCode: '10',
        StateName: 'Norte',
        CountryCode: 'Bf',
        CountryName: 'Burkina Faso',
    },
    {
        CityCode: '1101',
        CityName: 'Ziniaré',
        StateCode: '11',
        StateName: 'Plateau-Central',
        CountryCode: 'Bf',
        CountryName: 'Burkina Faso',
    },
    {
        CityCode: '1201',
        CityName: 'Dori',
        StateCode: '12',
        StateName: 'Sahel',
        CountryCode: 'Bf',
        CountryName: 'Burkina Faso',
    },
    {
        CityCode: '1301',
        CityName: 'Gaoua',
        StateCode: '13',
        StateName: 'Suroeste',
        CountryCode: 'Bf',
        CountryName: 'Burkina Faso',
    },
    {
        CityCode: '0101',
        CityName: 'Bubanza',
        StateCode: '01',
        StateName: 'Bubanza',
        CountryCode: 'Bi',
        CountryName: 'Burundi',
    },
    {
        CityCode: '0201',
        CityName: 'Buyumbura Mairie',
        StateCode: '02',
        StateName: 'Buyumbura Mairie',
        CountryCode: 'Bi',
        CountryName: 'Burundi',
    },
    {
        CityCode: '0301',
        CityName: 'Buyumbura Rural',
        StateCode: '03',
        StateName: 'Buyumbura Rural',
        CountryCode: 'Bi',
        CountryName: 'Burundi',
    },
    {
        CityCode: '0401',
        CityName: 'Bururi',
        StateCode: '04',
        StateName: 'Bururi',
        CountryCode: 'Bi',
        CountryName: 'Burundi',
    },
    {
        CityCode: '0501',
        CityName: 'Cankuzo',
        StateCode: '05',
        StateName: 'Cankuzo',
        CountryCode: 'Bi',
        CountryName: 'Burundi',
    },
    {
        CityCode: '0601',
        CityName: 'Cibitoke',
        StateCode: '06',
        StateName: 'Cibitoke',
        CountryCode: 'Bi',
        CountryName: 'Burundi',
    },
    {
        CityCode: '0701',
        CityName: 'Gitega',
        StateCode: '07',
        StateName: 'Gitega',
        CountryCode: 'Bi',
        CountryName: 'Burundi',
    },
    {
        CityCode: '0801',
        CityName: 'Karuzi',
        StateCode: '08',
        StateName: 'Karuzi',
        CountryCode: 'Bi',
        CountryName: 'Burundi',
    },
    {
        CityCode: '0901',
        CityName: 'Kayanza',
        StateCode: '09',
        StateName: 'Kayanza',
        CountryCode: 'Bi',
        CountryName: 'Burundi',
    },
    {
        CityCode: '1001',
        CityName: 'Kirundo',
        StateCode: '10',
        StateName: 'Kirundo',
        CountryCode: 'Bi',
        CountryName: 'Burundi',
    },
    {
        CityCode: '1101',
        CityName: 'Makamba',
        StateCode: '11',
        StateName: 'Makamba',
        CountryCode: 'Bi',
        CountryName: 'Burundi',
    },
    {
        CityCode: '1201',
        CityName: 'Muramvya',
        StateCode: '12',
        StateName: 'Muramvya',
        CountryCode: 'Bi',
        CountryName: 'Burundi',
    },
    {
        CityCode: '1301',
        CityName: 'Muyinga',
        StateCode: '13',
        StateName: 'Muyinga',
        CountryCode: 'Bi',
        CountryName: 'Burundi',
    },
    {
        CityCode: '1401',
        CityName: 'Mwaro',
        StateCode: '14',
        StateName: 'Mwaro',
        CountryCode: 'Bi',
        CountryName: 'Burundi',
    },
    {
        CityCode: '1501',
        CityName: 'Ngozi',
        StateCode: '15',
        StateName: 'Ngozi',
        CountryCode: 'Bi',
        CountryName: 'Burundi',
    },
    {
        CityCode: '1601',
        CityName: 'Rutana',
        StateCode: '16',
        StateName: 'Rutana',
        CountryCode: 'Bi',
        CountryName: 'Burundi',
    },
    {
        CityCode: '1701',
        CityName: 'Ruyigi',
        StateCode: '17',
        StateName: 'Ruyigi',
        CountryCode: 'Bi',
        CountryName: 'Burundi',
    },
    {
        CityCode: '0101',
        CityName: 'Jakar',
        StateCode: '01',
        StateName: 'Bumthang',
        CountryCode: 'Bt',
        CountryName: 'Bután',
    },
    {
        CityCode: '0201',
        CityName: 'Chukha',
        StateCode: '02',
        StateName: 'Chukha',
        CountryCode: 'Bt',
        CountryName: 'Bután',
    },
    {
        CityCode: '0301',
        CityName: 'Dagana',
        StateCode: '03',
        StateName: 'Dagana',
        CountryCode: 'Bt',
        CountryName: 'Bután',
    },
    {
        CityCode: '0401',
        CityName: 'Gasa',
        StateCode: '04',
        StateName: 'Gasa',
        CountryCode: 'Bt',
        CountryName: 'Bután',
    },
    {
        CityCode: '0501',
        CityName: 'Haa',
        StateCode: '05',
        StateName: 'Haa',
        CountryCode: 'Bt',
        CountryName: 'Bután',
    },
    {
        CityCode: '0601',
        CityName: 'Lhuntse',
        StateCode: '06',
        StateName: 'Lhuntse',
        CountryCode: 'Bt',
        CountryName: 'Bután',
    },
    {
        CityCode: '0701',
        CityName: 'Mongar',
        StateCode: '07',
        StateName: 'Mongar',
        CountryCode: 'Bt',
        CountryName: 'Bután',
    },
    {
        CityCode: '0801',
        CityName: 'Paro',
        StateCode: '08',
        StateName: 'Paro',
        CountryCode: 'Bt',
        CountryName: 'Bután',
    },
    {
        CityCode: '0901',
        CityName: 'Permagatshel',
        StateCode: '09',
        StateName: 'Pemagatshel',
        CountryCode: 'Bt',
        CountryName: 'Bután',
    },
    {
        CityCode: '1001',
        CityName: 'Punakha',
        StateCode: '10',
        StateName: 'Punakha',
        CountryCode: 'Bt',
        CountryName: 'Bután',
    },
    {
        CityCode: '1101',
        CityName: 'Samdrup Jongkhar',
        StateCode: '11',
        StateName: 'Samdrup Jongkhar',
        CountryCode: 'Bt',
        CountryName: 'Bután',
    },
    {
        CityCode: '1201',
        CityName: 'Samste',
        StateCode: '12',
        StateName: 'Samtse',
        CountryCode: 'Bt',
        CountryName: 'Bután',
    },
    {
        CityCode: '1301',
        CityName: 'Sarpang',
        StateCode: '13',
        StateName: 'Sarpang',
        CountryCode: 'Bt',
        CountryName: 'Bután',
    },
    {
        CityCode: '1401',
        CityName: 'Timbu',
        StateCode: '14',
        StateName: 'Timbu',
        CountryCode: 'Bt',
        CountryName: 'Bután',
    },
    {
        CityCode: '1501',
        CityName: 'Trashigang',
        StateCode: '15',
        StateName: 'Trashigang',
        CountryCode: 'Bt',
        CountryName: 'Bután',
    },
    {
        CityCode: '1601',
        CityName: 'Trashiyangtse',
        StateCode: '16',
        StateName: 'Trashiyangtse',
        CountryCode: 'Bt',
        CountryName: 'Bután',
    },
    {
        CityCode: '1701',
        CityName: 'Trongsa',
        StateCode: '17',
        StateName: 'Trongsa',
        CountryCode: 'Bt',
        CountryName: 'Bután',
    },
    {
        CityCode: '1801',
        CityName: 'Damphu',
        StateCode: '18',
        StateName: 'Tsirang',
        CountryCode: 'Bt',
        CountryName: 'Bután',
    },
    {
        CityCode: '1901',
        CityName: 'Wangdue Phodrang',
        StateCode: '19',
        StateName: 'Wangdue Phodrang',
        CountryCode: 'Bt',
        CountryName: 'Bután',
    },
    {
        CityCode: '2001',
        CityName: 'Zhemgang',
        StateCode: '20',
        StateName: 'Zhemgang',
        CountryCode: 'Bt',
        CountryName: 'Bután',
    },
    {
        CityCode: '0101',
        CityName: 'Santiago',
        StateCode: '01',
        StateName: 'Santiago',
        CountryCode: 'Cv',
        CountryName: 'Cabo Verde',
    },
    {
        CityCode: '0201',
        CityName: 'San Antón',
        StateCode: '02',
        StateName: 'Santo Antão',
        CountryCode: 'Cv',
        CountryName: 'Cabo Verde',
    },
    {
        CityCode: '0301',
        CityName: 'Buena Vista',
        StateCode: '03',
        StateName: 'Boavista',
        CountryCode: 'Cv',
        CountryName: 'Cabo Verde',
    },
    {
        CityCode: '0401',
        CityName: 'Fuego',
        StateCode: '04',
        StateName: 'Fogo',
        CountryCode: 'Cv',
        CountryName: 'Cabo Verde',
    },
    {
        CityCode: '0501',
        CityName: 'San Nicólas',
        StateCode: '05',
        StateName: 'São Nicolau',
        CountryCode: 'Cv',
        CountryName: 'Cabo Verde',
    },
    {
        CityCode: '0601',
        CityName: 'Mayo',
        StateCode: '06',
        StateName: 'Maio',
        CountryCode: 'Cv',
        CountryName: 'Cabo Verde',
    },
    {
        CityCode: '0701',
        CityName: 'San Vicente',
        StateCode: '07',
        StateName: 'São Vicente',
        CountryCode: 'Cv',
        CountryName: 'Cabo Verde',
    },
    {
        CityCode: '0801',
        CityName: 'Sal',
        StateCode: '08',
        StateName: 'Sal',
        CountryCode: 'Cv',
        CountryName: 'Cabo Verde',
    },
    {
        CityCode: '0901',
        CityName: 'Brava',
        StateCode: '09',
        StateName: 'Brava',
        CountryCode: 'Cv',
        CountryName: 'Cabo Verde',
    },
    {
        CityCode: '1001',
        CityName: 'Santa Lucía',
        StateCode: '10',
        StateName: 'Santa Luzia',
        CountryCode: 'Cv',
        CountryName: 'Cabo Verde',
    },
    {
        CityCode: '0101',
        CityName: 'Sisophon',
        StateCode: '01',
        StateName: 'Banteay Mean Chey',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '0201',
        CityName: 'Battambang',
        StateCode: '02',
        StateName: 'Battambang',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '0301',
        CityName: 'Kompung Cham',
        StateCode: '03',
        StateName: 'Kompung Cham',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '0401',
        CityName: 'Kompung Chinang',
        StateCode: '04',
        StateName: 'Kompung Chinang',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '0501',
        CityName: 'Kompung Speu',
        StateCode: '05',
        StateName: 'Kompung Speu',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '0601',
        CityName: 'Kompung Thom',
        StateCode: '06',
        StateName: 'Kompung Thom',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '0701',
        CityName: 'Kompot',
        StateCode: '07',
        StateName: 'Kompot',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '0801',
        CityName: 'Ta Khmau',
        StateCode: '08',
        StateName: 'Kandal',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '0901',
        CityName: 'Koh Kong',
        StateCode: '09',
        StateName: 'Koh Kong',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '1001',
        CityName: 'Ciudad de Kep',
        StateCode: '10',
        StateName: 'Kep',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '1101',
        CityName: 'Kratié',
        StateCode: '11',
        StateName: 'Kratié',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '1201',
        CityName: 'Sen Monorom',
        StateCode: '12',
        StateName: 'Mondol Kirí',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '1301',
        CityName: 'Anglong Veng',
        StateCode: '13',
        StateName: 'Oddar Mean Chey',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '1401',
        CityName: 'Ciudad de Pailín',
        StateCode: '14',
        StateName: 'Pailín',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '1501',
        CityName: 'Ciudad de Phnom Penh',
        StateCode: '15',
        StateName: 'Phnom Penh',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '1601',
        CityName: 'Ciudad de Sihanoukville',
        StateCode: '16',
        StateName: 'Sihanoukville',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '1701',
        CityName: 'Phnom Tbeng Mean Chey',
        StateCode: '17',
        StateName: 'Preah Wijía',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '1801',
        CityName: 'Pursat',
        StateCode: '18',
        StateName: 'Pursat',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '1901',
        CityName: 'Prey Veng',
        StateCode: '19',
        StateName: 'Prey Veng',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '2001',
        CityName: 'Banlung',
        StateCode: '20',
        StateName: 'Ratanak Kirí',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '2101',
        CityName: 'Siem Riep',
        StateCode: '21',
        StateName: 'Siem Riep',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '2201',
        CityName: 'Stung Treng',
        StateCode: '22',
        StateName: 'Stung Treng',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '2301',
        CityName: 'Svay Rieng',
        StateCode: '23',
        StateName: 'Svay Rieng',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '2401',
        CityName: 'Takéo',
        StateCode: '24',
        StateName: 'Takéo',
        CountryCode: 'Kh',
        CountryName: 'Camboya',
    },
    {
        CityCode: '0101',
        CityName: 'Ngaoundéré',
        StateCode: '01',
        StateName: 'Adamawa',
        CountryCode: 'Cm',
        CountryName: 'Camerún',
    },
    {
        CityCode: '0201',
        CityName: 'Yaoundé',
        StateCode: '02',
        StateName: 'Centro',
        CountryCode: 'Cm',
        CountryName: 'Camerún',
    },
    {
        CityCode: '0301',
        CityName: 'Bertoua',
        StateCode: '03',
        StateName: 'Este',
        CountryCode: 'Cm',
        CountryName: 'Camerún',
    },
    {
        CityCode: '0401',
        CityName: 'Maroua',
        StateCode: '04',
        StateName: 'Extremo Norte',
        CountryCode: 'Cm',
        CountryName: 'Camerún',
    },
    {
        CityCode: '0501',
        CityName: 'Douala',
        StateCode: '05',
        StateName: 'Litoral',
        CountryCode: 'Cm',
        CountryName: 'Camerún',
    },
    {
        CityCode: '0601',
        CityName: 'Garoua',
        StateCode: '06',
        StateName: 'Norte',
        CountryCode: 'Cm',
        CountryName: 'Camerún',
    },
    {
        CityCode: '0701',
        CityName: 'Bamenda',
        StateCode: '07',
        StateName: 'Noroeste',
        CountryCode: 'Cm',
        CountryName: 'Camerún',
    },
    {
        CityCode: '0801',
        CityName: 'Ebolowa',
        StateCode: '08',
        StateName: 'Sur',
        CountryCode: 'Cm',
        CountryName: 'Camerún',
    },
    {
        CityCode: '0901',
        CityName: 'Buea',
        StateCode: '09',
        StateName: 'Sudoeste',
        CountryCode: 'Cm',
        CountryName: 'Camerún',
    },
    {
        CityCode: '1001',
        CityName: 'Bafoussam',
        StateCode: '10',
        StateName: 'Oeste',
        CountryCode: 'Cm',
        CountryName: 'Camerún',
    },
    {
        CityCode: '0101',
        CityName: 'Toronto',
        StateCode: '01',
        StateName: 'Ontario',
        CountryCode: 'Ca',
        CountryName: 'Canadá',
    },
    {
        CityCode: '0201',
        CityName: 'Ciudad de Quebec',
        StateCode: '02',
        StateName: 'Quebec',
        CountryCode: 'Ca',
        CountryName: 'Canadá',
    },
    {
        CityCode: '0301',
        CityName: 'Halifax',
        StateCode: '03',
        StateName: 'Nueva Escocia',
        CountryCode: 'Ca',
        CountryName: 'Canadá',
    },
    {
        CityCode: '0401',
        CityName: 'Fredericton',
        StateCode: '04',
        StateName: 'Nuevo Brunswick',
        CountryCode: 'Ca',
        CountryName: 'Canadá',
    },
    {
        CityCode: '0501',
        CityName: 'Winnipeg',
        StateCode: '05',
        StateName: 'Manitoba',
        CountryCode: 'Ca',
        CountryName: 'Canadá',
    },
    {
        CityCode: '0601',
        CityName: 'Victoria',
        StateCode: '06',
        StateName: 'Columbia Británica',
        CountryCode: 'Ca',
        CountryName: 'Canadá',
    },
    {
        CityCode: '0701',
        CityName: 'Charlottetown',
        StateCode: '07',
        StateName: 'Isla del Príncipe Eduardo',
        CountryCode: 'Ca',
        CountryName: 'Canadá',
    },
    {
        CityCode: '0801',
        CityName: 'Regina',
        StateCode: '08',
        StateName: 'Saskatchewan',
        CountryCode: 'Ca',
        CountryName: 'Canadá',
    },
    {
        CityCode: '0901',
        CityName: 'Edmonton',
        StateCode: '09',
        StateName: 'Alberta',
        CountryCode: 'Ca',
        CountryName: 'Canadá',
    },
    {
        CityCode: '1001',
        CityName: 'San Juan de Terranova',
        StateCode: '10',
        StateName: 'Terranova y Labrador',
        CountryCode: 'Ca',
        CountryName: 'Canadá',
    },
    {
        CityCode: '1101',
        CityName: 'Yellowknife',
        StateCode: '11',
        StateName: 'Territorios del Noroeste',
        CountryCode: 'Ca',
        CountryName: 'Canadá',
    },
    {
        CityCode: '1201',
        CityName: 'Whitehorse',
        StateCode: '12',
        StateName: 'Yukón',
        CountryCode: 'Ca',
        CountryName: 'Canadá',
    },
    {
        CityCode: '1301',
        CityName: 'Iqaluit',
        StateCode: '13',
        StateName: 'Nunavut',
        CountryCode: 'Ca',
        CountryName: 'Canadá',
    },
    {
        CityCode: '0101',
        CityName: 'Ati',
        StateCode: '01',
        StateName: 'Batha',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '0201',
        CityName: 'Massenya',
        StateCode: '02',
        StateName: 'Chari-Baguirmi',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '0301',
        CityName: 'Massakory',
        StateCode: '03',
        StateName: 'Hadjer-Lamis',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '0401',
        CityName: 'Biltine',
        StateCode: '04',
        StateName: 'Wadi Fira',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '0501',
        CityName: 'Moussoro',
        StateCode: '05',
        StateName: 'Barh El Gazel',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '0601',
        CityName: 'Faya-Largeau',
        StateCode: '06',
        StateName: 'Borkou',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '0701',
        CityName: 'Fada',
        StateCode: '07',
        StateName: 'Ennedi',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '0801',
        CityName: 'Mongo',
        StateCode: '08',
        StateName: 'Guéra',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '0901',
        CityName: 'Mao',
        StateCode: '09',
        StateName: 'Kanem',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '1001',
        CityName: 'Bol',
        StateCode: '10',
        StateName: 'Lac',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '1101',
        CityName: 'Moundou',
        StateCode: '11',
        StateName: 'Logone Occidental',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '1201',
        CityName: 'Doba',
        StateCode: '12',
        StateName: 'Logone Oriental',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '1301',
        CityName: 'Koumra',
        StateCode: '13',
        StateName: 'Mandoul',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '1401',
        CityName: 'Bongor',
        StateCode: '14',
        StateName: 'Mayo-Kebbi Este',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '1501',
        CityName: 'Pala',
        StateCode: '15',
        StateName: 'Mayo-Kebbi Oeste',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '1601',
        CityName: 'Sarh',
        StateCode: '16',
        StateName: 'Moyen-Chari',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '1701',
        CityName: 'Abéché',
        StateCode: '17',
        StateName: 'Ouaddaï',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '1801',
        CityName: 'Am Timan',
        StateCode: '18',
        StateName: 'Salamat',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '1901',
        CityName: 'Bardai',
        StateCode: '19',
        StateName: 'Sila',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '2001',
        CityName: 'Laï',
        StateCode: '20',
        StateName: 'Tandjilé',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '2101',
        CityName: 'Goz Beïda',
        StateCode: '21',
        StateName: 'Tibesti',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '2201',
        CityName: 'Yamena',
        StateCode: '22',
        StateName: 'Yamena',
        CountryCode: 'Td',
        CountryName: 'Chad',
    },
    {
        CityCode: '0101',
        CityName: 'Arica',
        StateCode: '01',
        StateName: 'Arica y Parinacota',
        CountryCode: 'Cl',
        CountryName: 'Chile',
    },
    {
        CityCode: '0201',
        CityName: 'Iquique',
        StateCode: '02',
        StateName: 'Tarapacá',
        CountryCode: 'Cl',
        CountryName: 'Chile',
    },
    {
        CityCode: '0301',
        CityName: 'Antofagasta',
        StateCode: '03',
        StateName: 'Antofagasta',
        CountryCode: 'Cl',
        CountryName: 'Chile',
    },
    {
        CityCode: '0401',
        CityName: 'Copiapó',
        StateCode: '04',
        StateName: 'Atacama',
        CountryCode: 'Cl',
        CountryName: 'Chile',
    },
    {
        CityCode: '0501',
        CityName: 'La Serena',
        StateCode: '05',
        StateName: 'Coquimbo',
        CountryCode: 'Cl',
        CountryName: 'Chile',
    },
    {
        CityCode: '0601',
        CityName: 'Valparaíso',
        StateCode: '06',
        StateName: 'Valparaíso',
        CountryCode: 'Cl',
        CountryName: 'Chile',
    },
    {
        CityCode: '0701',
        CityName: 'Santiago',
        StateCode: '07',
        StateName: 'Metropolitana de Santiago',
        CountryCode: 'Cl',
        CountryName: 'Chile',
    },
    {
        CityCode: '0801',
        CityName: 'Rancagua',
        StateCode: '08',
        StateName: "Libertador General Bernardo O'Higgins",
        CountryCode: 'Cl',
        CountryName: 'Chile',
    },
    {
        CityCode: '0901',
        CityName: 'Talca',
        StateCode: '09',
        StateName: 'Maule',
        CountryCode: 'Cl',
        CountryName: 'Chile',
    },
    {
        CityCode: '1001',
        CityName: 'Concepción',
        StateCode: '10',
        StateName: 'Biobío',
        CountryCode: 'Cl',
        CountryName: 'Chile',
    },
    {
        CityCode: '1101',
        CityName: 'Temuco',
        StateCode: '11',
        StateName: 'La Araucanía',
        CountryCode: 'Cl',
        CountryName: 'Chile',
    },
    {
        CityCode: '1201',
        CityName: 'Valdivia',
        StateCode: '12',
        StateName: 'Los Ríos',
        CountryCode: 'Cl',
        CountryName: 'Chile',
    },
    {
        CityCode: '1301',
        CityName: 'Puerto Montt',
        StateCode: '13',
        StateName: 'Los Lagos',
        CountryCode: 'Cl',
        CountryName: 'Chile',
    },
    {
        CityCode: '1401',
        CityName: 'Coyhaique',
        StateCode: '14',
        StateName: 'Aysén del General Carlos Ibáñez del Campo',
        CountryCode: 'Cl',
        CountryName: 'Chile',
    },
    {
        CityCode: '1501',
        CityName: 'Punta Arenas',
        StateCode: '15',
        StateName: 'Magallanes y Antártica Chilena',
        CountryCode: 'Cl',
        CountryName: 'Chile',
    },
    {
        CityCode: '0101',
        CityName: 'Ürümqi',
        StateCode: '01',
        StateName: 'Xinjiang',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '0201',
        CityName: 'Hohhot',
        StateCode: '02',
        StateName: 'Mongolia Interior',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '0301',
        CityName: 'Harbin',
        StateCode: '03',
        StateName: 'Heilongjiang',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '0401',
        CityName: 'Changchun',
        StateCode: '04',
        StateName: 'Jilin',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '0501',
        CityName: 'Shenyang',
        StateCode: '05',
        StateName: 'Liaoning',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '0601',
        CityName: 'Lhasa',
        StateCode: '06',
        StateName: 'Tíbet',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '0701',
        CityName: 'Xining',
        StateCode: '07',
        StateName: 'Qinghai',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '0801',
        CityName: 'Lanzhou',
        StateCode: '08',
        StateName: 'Gansu',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '0901',
        CityName: 'Yinchuan',
        StateCode: '09',
        StateName: 'Ningxia',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '1001',
        CityName: "Xi'an",
        StateCode: '10',
        StateName: 'Shaanxi',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '1101',
        CityName: 'Taiyuan',
        StateCode: '11',
        StateName: 'Shanxi',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '1201',
        CityName: 'Shijiazhuang',
        StateCode: '12',
        StateName: 'Hebei',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '1301',
        CityName: 'Pekín',
        StateCode: '13',
        StateName: 'Pekín',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '1401',
        CityName: 'Tianjin',
        StateCode: '14',
        StateName: 'Tianjin',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '1501',
        CityName: 'Jinan',
        StateCode: '15',
        StateName: 'Shandong',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '1601',
        CityName: 'Zhengzhou',
        StateCode: '16',
        StateName: 'Henan',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '1701',
        CityName: 'Chengdú',
        StateCode: '17',
        StateName: 'Sichuan',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '1801',
        CityName: 'Chongqing',
        StateCode: '18',
        StateName: 'Chongqing',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '1901',
        CityName: 'Wuhan',
        StateCode: '19',
        StateName: 'Hubei',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '2001',
        CityName: 'Heféi',
        StateCode: '20',
        StateName: 'Anhui',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '2101',
        CityName: 'Nankín',
        StateCode: '21',
        StateName: 'Jiangsu',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '2201',
        CityName: 'Kunming',
        StateCode: '22',
        StateName: 'Yunnan',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '2301',
        CityName: 'Guiyang',
        StateCode: '23',
        StateName: 'Guizhou',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '2401',
        CityName: 'Changsha',
        StateCode: '24',
        StateName: 'Hunan',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '2501',
        CityName: 'Nanchang',
        StateCode: '25',
        StateName: 'Jiangxi',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '2601',
        CityName: 'Hangzhou',
        StateCode: '26',
        StateName: 'Zhejiang',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '2701',
        CityName: 'Shanghái',
        StateCode: '27',
        StateName: 'Shanghái',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '2801',
        CityName: 'Nanning',
        StateCode: '28',
        StateName: 'Guangxi',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '2901',
        CityName: 'Guangzhou',
        StateCode: '29',
        StateName: 'Guangdong',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '3001',
        CityName: 'Fuzhou',
        StateCode: '30',
        StateName: 'Fujian',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '3101',
        CityName: 'Macao',
        StateCode: '31',
        StateName: 'Macao',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '3201',
        CityName: 'Hong Kong',
        StateCode: '32',
        StateName: 'Hong Kong',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '3301',
        CityName: 'Haikou',
        StateCode: '33',
        StateName: 'Hainan',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '3401',
        CityName: 'Taipéi',
        StateCode: '34',
        StateName: 'Taiwán',
        CountryCode: 'Cn',
        CountryName: 'China',
    },
    {
        CityCode: '0101',
        CityName: 'Famagusta',
        StateCode: '01',
        StateName: 'Distrito de Famagusta',
        CountryCode: 'Cy',
        CountryName: 'Chipre',
    },
    {
        CityCode: '0201',
        CityName: 'Kyrenia',
        StateCode: '02',
        StateName: 'Distrito de Kyrenia',
        CountryCode: 'Cy',
        CountryName: 'Chipre',
    },
    {
        CityCode: '0301',
        CityName: 'Lárnaca',
        StateCode: '03',
        StateName: 'Distrito de Lárnaca',
        CountryCode: 'Cy',
        CountryName: 'Chipre',
    },
    {
        CityCode: '0401',
        CityName: 'Limasol',
        StateCode: '04',
        StateName: 'Distrito de Limassol',
        CountryCode: 'Cy',
        CountryName: 'Chipre',
    },
    {
        CityCode: '0501',
        CityName: 'Nicosia',
        StateCode: '05',
        StateName: 'Distrito de Nicosia',
        CountryCode: 'Cy',
        CountryName: 'Chipre',
    },
    {
        CityCode: '0601',
        CityName: 'Pafos',
        StateCode: '06',
        StateName: 'Distrito de Pafos',
        CountryCode: 'Cy',
        CountryName: 'Chipre',
    },
    {
        CityCode: '0101',
        CityName: 'Moroni',
        StateCode: '01',
        StateName: 'Isla Autónoma de Gran Comora',
        CountryCode: 'Km',
        CountryName: 'Comoras',
    },
    {
        CityCode: '0201',
        CityName: 'Mutsamudu',
        StateCode: '02',
        StateName: 'Isla Autónoma de Anjouan',
        CountryCode: 'Km',
        CountryName: 'Comoras',
    },
    {
        CityCode: '0301',
        CityName: 'Fomboni',
        StateCode: '03',
        StateName: 'Isla Autónoma de Mohéli',
        CountryCode: 'Km',
        CountryName: 'Comoras',
    },
    {
        CityCode: '0101',
        CityName: 'Brazzaville',
        StateCode: '01',
        StateName: 'Pool',
        CountryCode: 'Cg',
        CountryName: 'Congo',
    },
    {
        CityCode: '1001',
        CityName: 'Kinkala',
        StateCode: '01',
        StateName: 'Pool',
        CountryCode: 'Cg',
        CountryName: 'Congo',
    },
    {
        CityCode: '0201',
        CityName: 'Madingou',
        StateCode: '02',
        StateName: 'Bouenza',
        CountryCode: 'Cg',
        CountryName: 'Congo',
    },
    {
        CityCode: '0301',
        CityName: 'Owando',
        StateCode: '03',
        StateName: 'Cuvette',
        CountryCode: 'Cg',
        CountryName: 'Congo',
    },
    {
        CityCode: '0401',
        CityName: 'Ewo',
        StateCode: '04',
        StateName: 'Cuvette-Oeste',
        CountryCode: 'Cg',
        CountryName: 'Congo',
    },
    {
        CityCode: '0501',
        CityName: 'Hinda',
        StateCode: '05',
        StateName: 'Kouilou',
        CountryCode: 'Cg',
        CountryName: 'Congo',
    },
    {
        CityCode: '0601',
        CityName: 'Sibiti',
        StateCode: '06',
        StateName: 'Lékoumou',
        CountryCode: 'Cg',
        CountryName: 'Congo',
    },
    {
        CityCode: '0701',
        CityName: 'Impfondo',
        StateCode: '07',
        StateName: 'Likouala',
        CountryCode: 'Cg',
        CountryName: 'Congo',
    },
    {
        CityCode: '0801',
        CityName: 'Loubomo',
        StateCode: '08',
        StateName: 'Niari',
        CountryCode: 'Cg',
        CountryName: 'Congo',
    },
    {
        CityCode: '0901',
        CityName: 'Djambala',
        StateCode: '09',
        StateName: 'Plateaux',
        CountryCode: 'Cg',
        CountryName: 'Congo',
    },
    {
        CityCode: '1101',
        CityName: 'Ouésso',
        StateCode: '10',
        StateName: 'Sangha',
        CountryCode: 'Cg',
        CountryName: 'Congo',
    },
    {
        CityCode: '0101',
        CityName: 'Chung-guyok',
        StateCode: '01',
        StateName: 'Pionyang',
        CountryCode: 'Kp',
        CountryName: 'Corea Del Norte',
    },
    {
        CityCode: '0201',
        CityName: 'Rason',
        StateCode: '02',
        StateName: 'Rason',
        CountryCode: 'Kp',
        CountryName: 'Corea Del Norte',
    },
    {
        CityCode: '0301',
        CityName: 'Sinuiju',
        StateCode: '03',
        StateName: 'Sinuiju',
        CountryCode: 'Kp',
        CountryName: 'Corea Del Norte',
    },
    {
        CityCode: '0401',
        CityName: 'Kaesong',
        StateCode: '04',
        StateName: 'Kaesong',
        CountryCode: 'Kp',
        CountryName: 'Corea Del Norte',
    },
    {
        CityCode: '0501',
        CityName: 'Kumgangsan',
        StateCode: '05',
        StateName: 'Kumgangsan',
        CountryCode: 'Kp',
        CountryName: 'Corea Del Norte',
    },
    {
        CityCode: '0601',
        CityName: 'Pyongsong',
        StateCode: '06',
        StateName: "P'yongan Sur",
        CountryCode: 'Kp',
        CountryName: 'Corea Del Norte',
    },
    {
        CityCode: '0701',
        CityName: 'Sinuiju',
        StateCode: '07',
        StateName: "P'yongan Norte",
        CountryCode: 'Kp',
        CountryName: 'Corea Del Norte',
    },
    {
        CityCode: '0801',
        CityName: 'Kanggye',
        StateCode: '08',
        StateName: 'Chagang',
        CountryCode: 'Kp',
        CountryName: 'Corea Del Norte',
    },
    {
        CityCode: '0901',
        CityName: 'Haeju',
        StateCode: '09',
        StateName: 'Hwanghae Sur',
        CountryCode: 'Kp',
        CountryName: 'Corea Del Norte',
    },
    {
        CityCode: '1001',
        CityName: 'Sariwon',
        StateCode: '10',
        StateName: 'Hwanghae del Norte',
        CountryCode: 'Kp',
        CountryName: 'Corea Del Norte',
    },
    {
        CityCode: '1101',
        CityName: 'Wonsan',
        StateCode: '11',
        StateName: 'Kangwon',
        CountryCode: 'Kp',
        CountryName: 'Corea Del Norte',
    },
    {
        CityCode: '1201',
        CityName: 'Hamhung',
        StateCode: '12',
        StateName: 'Hamgyong Sur',
        CountryCode: 'Kp',
        CountryName: 'Corea Del Norte',
    },
    {
        CityCode: '1301',
        CityName: 'Chongjin',
        StateCode: '13',
        StateName: 'Hamgyong Norte',
        CountryCode: 'Kp',
        CountryName: 'Corea Del Norte',
    },
    {
        CityCode: '1401',
        CityName: 'Hyesan',
        StateCode: '14',
        StateName: 'Ryanggang',
        CountryCode: 'Kp',
        CountryName: 'Corea Del Norte',
    },
    {
        CityCode: '0101',
        CityName: 'Hongseong',
        StateCode: '01',
        StateName: 'Chungcheong del Sur',
        CountryCode: 'Kr',
        CountryName: 'Corea Del Sur',
    },
    {
        CityCode: '0201',
        CityName: 'Chuncheon',
        StateCode: '02',
        StateName: 'Gangwon-do',
        CountryCode: 'Kr',
        CountryName: 'Corea Del Sur',
    },
    {
        CityCode: '0301',
        CityName: 'Suwon',
        StateCode: '03',
        StateName: 'Gyeonggi-do',
        CountryCode: 'Kr',
        CountryName: 'Corea Del Sur',
    },
    {
        CityCode: '0401',
        CityName: 'Andong',
        StateCode: '04',
        StateName: 'Gyeongsangbuk-do',
        CountryCode: 'Kr',
        CountryName: 'Corea Del Sur',
    },
    {
        CityCode: '0501',
        CityName: 'Changwon',
        StateCode: '05',
        StateName: 'Gyeongsangnam-do',
        CountryCode: 'Kr',
        CountryName: 'Corea Del Sur',
    },
    {
        CityCode: '0601',
        CityName: 'Jeonju',
        StateCode: '06',
        StateName: 'Jeollabuk-do',
        CountryCode: 'Kr',
        CountryName: 'Corea Del Sur',
    },
    {
        CityCode: '0701',
        CityName: 'Muan',
        StateCode: '07',
        StateName: 'Jeollanam-do',
        CountryCode: 'Kr',
        CountryName: 'Corea Del Sur',
    },
    {
        CityCode: '0801',
        CityName: 'Seúl',
        StateCode: '08',
        StateName: 'Ciudad especial',
        CountryCode: 'Kr',
        CountryName: 'Corea Del Sur',
    },
    {
        CityCode: '0901',
        CityName: 'Ciudad de Sejong',
        StateCode: '09',
        StateName: 'Ciudad autónoma especial',
        CountryCode: 'Kr',
        CountryName: 'Corea Del Sur',
    },
    {
        CityCode: '1001',
        CityName: 'Busan',
        StateCode: '10',
        StateName: 'Ciudad metropolitana',
        CountryCode: 'Kr',
        CountryName: 'Corea Del Sur',
    },
    {
        CityCode: '1002',
        CityName: 'Daegu',
        StateCode: '10',
        StateName: 'Ciudad metropolitana',
        CountryCode: 'Kr',
        CountryName: 'Corea Del Sur',
    },
    {
        CityCode: '1003',
        CityName: 'Daejeon',
        StateCode: '10',
        StateName: 'Ciudad metropolitana',
        CountryCode: 'Kr',
        CountryName: 'Corea Del Sur',
    },
    {
        CityCode: '1004',
        CityName: 'Gwangju',
        StateCode: '10',
        StateName: 'Ciudad metropolitana',
        CountryCode: 'Kr',
        CountryName: 'Corea Del Sur',
    },
    {
        CityCode: '1005',
        CityName: 'Incheon',
        StateCode: '10',
        StateName: 'Ciudad metropolitana',
        CountryCode: 'Kr',
        CountryName: 'Corea Del Sur',
    },
    {
        CityCode: '1006',
        CityName: 'Ulsan',
        StateCode: '10',
        StateName: 'Ciudad metropolitana',
        CountryCode: 'Kr',
        CountryName: 'Corea Del Sur',
    },
    {
        CityCode: '0101',
        CityName: 'Odienné',
        StateCode: '01',
        StateName: 'Denguélé',
        CountryCode: 'Ci',
        CountryName: 'Costa De Marfil',
    },
    {
        CityCode: '0201',
        CityName: 'Korhogo',
        StateCode: '02',
        StateName: 'Savanes',
        CountryCode: 'Ci',
        CountryName: 'Costa De Marfil',
    },
    {
        CityCode: '0301',
        CityName: 'Touba',
        StateCode: '03',
        StateName: 'Bafing',
        CountryCode: 'Ci',
        CountryName: 'Costa De Marfil',
    },
    {
        CityCode: '0401',
        CityName: 'Séguéla',
        StateCode: '04',
        StateName: 'Worodougou',
        CountryCode: 'Ci',
        CountryName: 'Costa De Marfil',
    },
    {
        CityCode: '0501',
        CityName: 'Bouaké',
        StateCode: '05',
        StateName: 'Vallée du Bandama',
        CountryCode: 'Ci',
        CountryName: 'Costa De Marfil',
    },
    {
        CityCode: '0601',
        CityName: 'Bondoukou',
        StateCode: '06',
        StateName: 'Zanzan',
        CountryCode: 'Ci',
        CountryName: 'Costa De Marfil',
    },
    {
        CityCode: '0701',
        CityName: 'Man',
        StateCode: '07',
        StateName: 'Dix-Huit Montagnes',
        CountryCode: 'Ci',
        CountryName: 'Costa De Marfil',
    },
    {
        CityCode: '0801',
        CityName: 'Daloa',
        StateCode: '08',
        StateName: 'Haut-Sassandra',
        CountryCode: 'Ci',
        CountryName: 'Costa De Marfil',
    },
    {
        CityCode: '0901',
        CityName: 'Bouaflé',
        StateCode: '09',
        StateName: 'Marahoué',
        CountryCode: 'Ci',
        CountryName: 'Costa De Marfil',
    },
    {
        CityCode: '1001',
        CityName: 'Yamusukro',
        StateCode: '10',
        StateName: 'Lacs',
        CountryCode: 'Ci',
        CountryName: 'Costa De Marfil',
    },
    {
        CityCode: '1101',
        CityName: 'Dimbokro',
        StateCode: '11',
        StateName: "N'zi-Comoé",
        CountryCode: 'Ci',
        CountryName: 'Costa De Marfil',
    },
    {
        CityCode: '1201',
        CityName: 'Abengourou',
        StateCode: '12',
        StateName: 'Moyen-Comoé',
        CountryCode: 'Ci',
        CountryName: 'Costa De Marfil',
    },
    {
        CityCode: '1301',
        CityName: 'Guiglo',
        StateCode: '13',
        StateName: 'Moyen-Cavally',
        CountryCode: 'Ci',
        CountryName: 'Costa De Marfil',
    },
    {
        CityCode: '1401',
        CityName: 'Gagnoa',
        StateCode: '14',
        StateName: 'Fromager',
        CountryCode: 'Ci',
        CountryName: 'Costa De Marfil',
    },
    {
        CityCode: '1501',
        CityName: 'Agboville',
        StateCode: '15',
        StateName: 'Agnéby',
        CountryCode: 'Ci',
        CountryName: 'Costa De Marfil',
    },
    {
        CityCode: '1601',
        CityName: 'San Pedro',
        StateCode: '16',
        StateName: 'Bas-Sassandra',
        CountryCode: 'Ci',
        CountryName: 'Costa De Marfil',
    },
    {
        CityCode: '1701',
        CityName: 'Divo',
        StateCode: '17',
        StateName: 'Sud-Bandama',
        CountryCode: 'Ci',
        CountryName: 'Costa De Marfil',
    },
    {
        CityCode: '1801',
        CityName: 'Abiyán',
        StateCode: '18',
        StateName: 'Lagunes',
        CountryCode: 'Ci',
        CountryName: 'Costa De Marfil',
    },
    {
        CityCode: '1901',
        CityName: 'Aboisso',
        StateCode: '19',
        StateName: 'Sud-Comoé',
        CountryCode: 'Ci',
        CountryName: 'Costa De Marfil',
    },
    {
        CityCode: '0101',
        CityName: 'San José',
        StateCode: '01',
        StateName: 'San José',
        CountryCode: 'Cr',
        CountryName: 'Costa Rica',
    },
    {
        CityCode: '0201',
        CityName: 'Alajuela',
        StateCode: '02',
        StateName: 'Alajuela',
        CountryCode: 'Cr',
        CountryName: 'Costa Rica',
    },
    {
        CityCode: '0301',
        CityName: 'Cartago',
        StateCode: '03',
        StateName: 'Cartago',
        CountryCode: 'Cr',
        CountryName: 'Costa Rica',
    },
    {
        CityCode: '0401',
        CityName: 'Heredia',
        StateCode: '04',
        StateName: 'Heredia',
        CountryCode: 'Cr',
        CountryName: 'Costa Rica',
    },
    {
        CityCode: '0501',
        CityName: 'Liberia',
        StateCode: '05',
        StateName: 'Liberia',
        CountryCode: 'Cr',
        CountryName: 'Costa Rica',
    },
    {
        CityCode: '0601',
        CityName: 'Puntarenas',
        StateCode: '06',
        StateName: 'Puntarenas',
        CountryCode: 'Cr',
        CountryName: 'Costa Rica',
    },
    {
        CityCode: '0701',
        CityName: 'Limón',
        StateCode: '07',
        StateName: 'Limón',
        CountryCode: 'Cr',
        CountryName: 'Costa Rica',
    },
    {
        CityCode: '0101',
        CityName: 'Zagreb',
        StateCode: '01',
        StateName: 'Condado de Zagreb',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '0201',
        CityName: 'Krapina',
        StateCode: '02',
        StateName: 'Krapina-Zagorje',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '0301',
        CityName: 'Sisak',
        StateCode: '03',
        StateName: 'Sisak-Moslavina',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '0401',
        CityName: 'Karlovac',
        StateCode: '04',
        StateName: 'Karlovac',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '0501',
        CityName: 'Varadin',
        StateCode: '05',
        StateName: 'Varadin',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '0601',
        CityName: 'Koprivnica',
        StateCode: '06',
        StateName: 'Koprivnica-Krievci',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '0701',
        CityName: 'Bjelovar',
        StateCode: '07',
        StateName: 'Bjelovar-Bilogora',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '0801',
        CityName: 'Cakovec',
        StateCode: '08',
        StateName: 'Medimurje',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '0901',
        CityName: 'Zagreb',
        StateCode: '09',
        StateName: 'Zagreb',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '1001',
        CityName: 'Rijeka',
        StateCode: '10',
        StateName: 'Primorje-Gorski Kotar',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '1101',
        CityName: 'Gospic',
        StateCode: '11',
        StateName: 'Lika-Senj',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '1201',
        CityName: 'Pazin',
        StateCode: '12',
        StateName: 'Istria',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '1301',
        CityName: 'Virovitica',
        StateCode: '13',
        StateName: 'Virovitica-Podravina',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '1401',
        CityName: 'Poega',
        StateCode: '14',
        StateName: 'Poega-Eslavonia',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '1501',
        CityName: 'Slavonski Brod',
        StateCode: '15',
        StateName: 'Brod-Posavina',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '1601',
        CityName: 'Osijek',
        StateCode: '16',
        StateName: 'Osijek-Baranya',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '1701',
        CityName: 'Vukovar',
        StateCode: '17',
        StateName: 'Vukovar-Sirmia',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '1801',
        CityName: 'Zadar',
        StateCode: '18',
        StateName: 'Zadar',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '1901',
        CityName: 'ibenik',
        StateCode: '19',
        StateName: 'ibenik-Knin',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '2001',
        CityName: 'Split',
        StateCode: '20',
        StateName: 'Split-Dalmacia',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '2101',
        CityName: 'Dubrovnik',
        StateCode: '21',
        StateName: 'Dubrovnik-Neretva',
        CountryCode: 'Hr',
        CountryName: 'Croacia',
    },
    {
        CityCode: '0101',
        CityName: 'Pinar del Río',
        StateCode: '01',
        StateName: 'Pinar del Río',
        CountryCode: 'Cu',
        CountryName: 'Cuba',
    },
    {
        CityCode: '0201',
        CityName: 'Artemisa',
        StateCode: '02',
        StateName: 'Artemisa',
        CountryCode: 'Cu',
        CountryName: 'Cuba',
    },
    {
        CityCode: '0301',
        CityName: 'La Habana',
        StateCode: '03',
        StateName: 'La Habana',
        CountryCode: 'Cu',
        CountryName: 'Cuba',
    },
    {
        CityCode: '0401',
        CityName: 'Mayabeque',
        StateCode: '04',
        StateName: 'Mayabeque',
        CountryCode: 'Cu',
        CountryName: 'Cuba',
    },
    {
        CityCode: '0501',
        CityName: 'Matanzas',
        StateCode: '05',
        StateName: 'Matanzas',
        CountryCode: 'Cu',
        CountryName: 'Cuba',
    },
    {
        CityCode: '0601',
        CityName: 'Cienfuegos',
        StateCode: '06',
        StateName: 'Cienfuegos',
        CountryCode: 'Cu',
        CountryName: 'Cuba',
    },
    {
        CityCode: '0701',
        CityName: 'Villa Clara',
        StateCode: '07',
        StateName: 'Villa Clara',
        CountryCode: 'Cu',
        CountryName: 'Cuba',
    },
    {
        CityCode: '0801',
        CityName: 'Sancti Spíritus',
        StateCode: '08',
        StateName: 'Sancti Spíritus',
        CountryCode: 'Cu',
        CountryName: 'Cuba',
    },
    {
        CityCode: '0901',
        CityName: 'Ciego de Ávila',
        StateCode: '09',
        StateName: 'Ciego de Ávila',
        CountryCode: 'Cu',
        CountryName: 'Cuba',
    },
    {
        CityCode: '1001',
        CityName: 'Camagüey',
        StateCode: '10',
        StateName: 'Camagüey',
        CountryCode: 'Cu',
        CountryName: 'Cuba',
    },
    {
        CityCode: '1101',
        CityName: 'Las Tunas',
        StateCode: '11',
        StateName: 'Las Tunas',
        CountryCode: 'Cu',
        CountryName: 'Cuba',
    },
    {
        CityCode: '1201',
        CityName: 'Granma',
        StateCode: '12',
        StateName: 'Granma',
        CountryCode: 'Cu',
        CountryName: 'Cuba',
    },
    {
        CityCode: '1301',
        CityName: 'Holguín',
        StateCode: '13',
        StateName: 'Holguín',
        CountryCode: 'Cu',
        CountryName: 'Cuba',
    },
    {
        CityCode: '1401',
        CityName: 'Santiago de Cuba',
        StateCode: '14',
        StateName: 'Santiago de Cuba',
        CountryCode: 'Cu',
        CountryName: 'Cuba',
    },
    {
        CityCode: '1501',
        CityName: 'Guantánamo',
        StateCode: '15',
        StateName: 'Guantánamo',
        CountryCode: 'Cu',
        CountryName: 'Cuba',
    },
    {
        CityCode: '1601',
        CityName: 'Nueva Gerona',
        StateCode: '16',
        StateName: 'Municipio Especial Isla de la Juventud',
        CountryCode: 'Cu',
        CountryName: 'Cuba',
    },
    {
        CityCode: '0101',
        CityName: 'Willemstad',
        StateCode: '01',
        StateName: 'Willemstad',
        CountryCode: 'Cw',
        CountryName: 'Curazao',
    },
    {
        CityCode: '0101',
        CityName: 'Viborg',
        StateCode: '01',
        StateName: 'Jutlandia Central',
        CountryCode: 'Dk',
        CountryName: 'Dinamarca',
    },
    {
        CityCode: '0201',
        CityName: 'Hillerød',
        StateCode: '02',
        StateName: 'Capital',
        CountryCode: 'Dk',
        CountryName: 'Dinamarca',
    },
    {
        CityCode: '0301',
        CityName: 'Vejle',
        StateCode: '03',
        StateName: 'Dinamarca Meridional',
        CountryCode: 'Dk',
        CountryName: 'Dinamarca',
    },
    {
        CityCode: '0401',
        CityName: 'Aalborg',
        StateCode: '04',
        StateName: 'Jutlandia Septentrional',
        CountryCode: 'Dk',
        CountryName: 'Dinamarca',
    },
    {
        CityCode: '0501',
        CityName: 'Sorø',
        StateCode: '05',
        StateName: 'Región de Selandia',
        CountryCode: 'Dk',
        CountryName: 'Dinamarca',
    },
    {
        CityCode: '0101',
        CityName: 'Roseau',
        StateCode: '01',
        StateName: 'Roseau',
        CountryCode: 'Dm',
        CountryName: 'Dominica',
    },
    {
        CityCode: '0101',
        CityName: 'Alejandría',
        StateCode: '01',
        StateName: 'Alejandría',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '0201',
        CityName: 'Asuán',
        StateCode: '02',
        StateName: 'Asuán',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '0301',
        CityName: 'Asiut',
        StateCode: '03',
        StateName: 'Asiut',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '0401',
        CityName: 'Damanhur',
        StateCode: '04',
        StateName: 'Behera',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '0501',
        CityName: 'Beni Suef',
        StateCode: '05',
        StateName: 'Beni Suef',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '0601',
        CityName: 'El Cairo',
        StateCode: '06',
        StateName: 'El Cairo',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '0701',
        CityName: 'Mansura',
        StateCode: '07',
        StateName: 'Dacalia',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '0801',
        CityName: 'Damieta',
        StateCode: '08',
        StateName: 'Damieta',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '0901',
        CityName: 'Fayún',
        StateCode: '09',
        StateName: 'Fayún',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '1001',
        CityName: 'Tanta',
        StateCode: '10',
        StateName: 'Occidental',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '1101',
        CityName: 'Guiza',
        StateCode: '11',
        StateName: 'Guiza',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '1201',
        CityName: 'Ismailia',
        StateCode: '12',
        StateName: 'Ismailia',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '1301',
        CityName: 'Kafr el Sheij',
        StateCode: '13',
        StateName: 'Kafr el Sheij',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '1401',
        CityName: 'Marsá Matrú',
        StateCode: '14',
        StateName: 'Matrú',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '1501',
        CityName: 'Menia',
        StateCode: '15',
        StateName: 'Menia',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '1601',
        CityName: 'Shibin el-qom',
        StateCode: '16',
        StateName: 'Menufia',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '1701',
        CityName: 'El Jariyá',
        StateCode: '17',
        StateName: 'Nuevo Valle',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '1801',
        CityName: 'El Arish',
        StateCode: '18',
        StateName: 'Sinaí del Norte',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '1901',
        CityName: 'Puerto Saíd',
        StateCode: '19',
        StateName: 'Puerto Saíd',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '2001',
        CityName: 'Banha',
        StateCode: '20',
        StateName: 'Caliubia',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '2101',
        CityName: 'Quena',
        StateCode: '21',
        StateName: 'Quena',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '2201',
        CityName: 'Hurgada',
        StateCode: '22',
        StateName: 'Mar Rojo',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '2301',
        CityName: 'Zaqaziq',
        StateCode: '23',
        StateName: 'Oriental',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '2401',
        CityName: 'Suhag',
        StateCode: '24',
        StateName: 'Suhag',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '2501',
        CityName: 'El Tor',
        StateCode: '25',
        StateName: 'Sinaí del Sur',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '2601',
        CityName: 'Suez',
        StateCode: '26',
        StateName: 'Suez',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '2701',
        CityName: 'Luxor',
        StateCode: '27',
        StateName: 'Luxor',
        CountryCode: 'Eg',
        CountryName: 'Egipto',
    },
    {
        CityCode: '0101',
        CityName: 'Ahuachapán',
        StateCode: '01',
        StateName: 'Ahuachapán',
        CountryCode: 'Sv',
        CountryName: 'El Salvador',
    },
    {
        CityCode: '0201',
        CityName: 'Sensuntepeque',
        StateCode: '02',
        StateName: 'Cabañas',
        CountryCode: 'Sv',
        CountryName: 'El Salvador',
    },
    {
        CityCode: '0301',
        CityName: 'Chalatenango',
        StateCode: '03',
        StateName: 'Chalatenango',
        CountryCode: 'Sv',
        CountryName: 'El Salvador',
    },
    {
        CityCode: '0401',
        CityName: 'Cojutepeque',
        StateCode: '04',
        StateName: 'Cuscatlán',
        CountryCode: 'Sv',
        CountryName: 'El Salvador',
    },
    {
        CityCode: '0501',
        CityName: 'Santa Tecla',
        StateCode: '05',
        StateName: 'La Libertad',
        CountryCode: 'Sv',
        CountryName: 'El Salvador',
    },
    {
        CityCode: '0601',
        CityName: 'Zacatecoluca',
        StateCode: '06',
        StateName: 'La Paz',
        CountryCode: 'Sv',
        CountryName: 'El Salvador',
    },
    {
        CityCode: '0701',
        CityName: 'La Unión',
        StateCode: '07',
        StateName: 'La Unión',
        CountryCode: 'Sv',
        CountryName: 'El Salvador',
    },
    {
        CityCode: '0801',
        CityName: 'San Francisco',
        StateCode: '08',
        StateName: 'Morazán',
        CountryCode: 'Sv',
        CountryName: 'El Salvador',
    },
    {
        CityCode: '0901',
        CityName: 'San Miguel',
        StateCode: '09',
        StateName: 'San Miguel',
        CountryCode: 'Sv',
        CountryName: 'El Salvador',
    },
    {
        CityCode: '1001',
        CityName: 'San Salvador',
        StateCode: '10',
        StateName: 'San Salvador',
        CountryCode: 'Sv',
        CountryName: 'El Salvador',
    },
    {
        CityCode: '1101',
        CityName: 'San Vicente',
        StateCode: '11',
        StateName: 'San Vicente',
        CountryCode: 'Sv',
        CountryName: 'El Salvador',
    },
    {
        CityCode: '1201',
        CityName: 'Santa Ana',
        StateCode: '12',
        StateName: 'Santa Ana',
        CountryCode: 'Sv',
        CountryName: 'El Salvador',
    },
    {
        CityCode: '1301',
        CityName: 'Sonsonate',
        StateCode: '13',
        StateName: 'Sonsonate',
        CountryCode: 'Sv',
        CountryName: 'El Salvador',
    },
    {
        CityCode: '1401',
        CityName: 'Usulután',
        StateCode: '14',
        StateName: 'Usulután',
        CountryCode: 'Sv',
        CountryName: 'El Salvador',
    },
    {
        CityCode: '0101',
        CityName: 'Abu Dabi',
        StateCode: '01',
        StateName: 'Abu Dabi',
        CountryCode: 'Ae',
        CountryName: 'Emiratos Árabes Unidos',
    },
    {
        CityCode: '0201',
        CityName: 'Ajmán',
        StateCode: '02',
        StateName: 'Ajmán',
        CountryCode: 'Ae',
        CountryName: 'Emiratos Árabes Unidos',
    },
    {
        CityCode: '0301',
        CityName: 'Dubái',
        StateCode: '03',
        StateName: 'Dubái',
        CountryCode: 'Ae',
        CountryName: 'Emiratos Árabes Unidos',
    },
    {
        CityCode: '0401',
        CityName: 'Fuyaira',
        StateCode: '04',
        StateName: 'Fuyaira',
        CountryCode: 'Ae',
        CountryName: 'Emiratos Árabes Unidos',
    },
    {
        CityCode: '0501',
        CityName: 'Ras al-Jaima',
        StateCode: '05',
        StateName: 'Ras al-Jaima',
        CountryCode: 'Ae',
        CountryName: 'Emiratos Árabes Unidos',
    },
    {
        CityCode: '0601',
        CityName: 'Sarja',
        StateCode: '06',
        StateName: 'Sarja',
        CountryCode: 'Ae',
        CountryName: 'Emiratos Árabes Unidos',
    },
    {
        CityCode: '0701',
        CityName: 'Umm al-Qaywayn',
        StateCode: '07',
        StateName: 'Umm al-Qaywayn',
        CountryCode: 'Ae',
        CountryName: 'Emiratos Árabes Unidos',
    },
    {
        CityCode: '0801',
        CityName: 'Abu Dabi',
        StateCode: '08',
        StateName: 'EAU',
        CountryCode: 'Ae',
        CountryName: 'Emiratos Árabes Unidos',
    },
    {
        CityCode: '0101',
        CityName: 'Asmara',
        StateCode: '01',
        StateName: 'Maekel',
        CountryCode: 'Er',
        CountryName: 'Eritrea',
    },
    {
        CityCode: '0201',
        CityName: 'Mendefera',
        StateCode: '02',
        StateName: 'Debub',
        CountryCode: 'Er',
        CountryName: 'Eritrea',
    },
    {
        CityCode: '0301',
        CityName: 'Zoba',
        StateCode: '03',
        StateName: 'Gash-Barka',
        CountryCode: 'Er',
        CountryName: 'Eritrea',
    },
    {
        CityCode: '0401',
        CityName: 'Keren',
        StateCode: '04',
        StateName: 'Anseba',
        CountryCode: 'Er',
        CountryName: 'Eritrea',
    },
    {
        CityCode: '0501',
        CityName: 'Massawa',
        StateCode: '05',
        StateName: 'Semenawi Keyih Bahri',
        CountryCode: 'Er',
        CountryName: 'Eritrea',
    },
    {
        CityCode: '0601',
        CityName: 'Assab',
        StateCode: '06',
        StateName: 'Debubawi Keyih Bahri',
        CountryCode: 'Er',
        CountryName: 'Eritrea',
    },
    {
        CityCode: '0101',
        CityName: 'Trnava',
        StateCode: '01',
        StateName: 'Región de Trnava',
        CountryCode: 'Sk',
        CountryName: 'Eslovaquia',
    },
    {
        CityCode: '0201',
        CityName: 'Trencín',
        StateCode: '02',
        StateName: 'Región de Trencín',
        CountryCode: 'Sk',
        CountryName: 'Eslovaquia',
    },
    {
        CityCode: '0301',
        CityName: 'Nitra',
        StateCode: '03',
        StateName: 'Región de Nitra',
        CountryCode: 'Sk',
        CountryName: 'Eslovaquia',
    },
    {
        CityCode: '0401',
        CityName: 'ilina',
        StateCode: '04',
        StateName: 'Región de ilina',
        CountryCode: 'Sk',
        CountryName: 'Eslovaquia',
    },
    {
        CityCode: '0501',
        CityName: 'Banská Bystrica',
        StateCode: '05',
        StateName: 'Región de Banská Bystrica',
        CountryCode: 'Sk',
        CountryName: 'Eslovaquia',
    },
    {
        CityCode: '0601',
        CityName: 'Preov',
        StateCode: '06',
        StateName: 'Región de Preov',
        CountryCode: 'Sk',
        CountryName: 'Eslovaquia',
    },
    {
        CityCode: '0701',
        CityName: 'Koice',
        StateCode: '07',
        StateName: 'Región de Koice',
        CountryCode: 'Sk',
        CountryName: 'Eslovaquia',
    },
    {
        CityCode: '0101',
        CityName: 'Murska Sobota',
        StateCode: '01',
        StateName: 'Región del Mura',
        CountryCode: 'Si',
        CountryName: 'Eslovenia',
    },
    {
        CityCode: '0201',
        CityName: 'Maribor',
        StateCode: '02',
        StateName: 'Región del Drava',
        CountryCode: 'Si',
        CountryName: 'Eslovenia',
    },
    {
        CityCode: '0301',
        CityName: 'Carintia eslovena',
        StateCode: '03',
        StateName: 'Carintia eslovena',
        CountryCode: 'Si',
        CountryName: 'Eslovenia',
    },
    {
        CityCode: '0401',
        CityName: 'Celje',
        StateCode: '04',
        StateName: 'Savinia',
        CountryCode: 'Si',
        CountryName: 'Eslovenia',
    },
    {
        CityCode: '0501',
        CityName: 'Trbovlje',
        StateCode: '05',
        StateName: 'Zasavie',
        CountryCode: 'Si',
        CountryName: 'Eslovenia',
    },
    {
        CityCode: '0601',
        CityName: 'Posavina',
        StateCode: '06',
        StateName: 'Posavina',
        CountryCode: 'Si',
        CountryName: 'Eslovenia',
    },
    {
        CityCode: '0701',
        CityName: 'Novo Mesto',
        StateCode: '07',
        StateName: 'Eslovenia Sudoriental',
        CountryCode: 'Si',
        CountryName: 'Eslovenia',
    },
    {
        CityCode: '0801',
        CityName: 'Liubliana',
        StateCode: '08',
        StateName: 'Eslovenia Central',
        CountryCode: 'Si',
        CountryName: 'Eslovenia',
    },
    {
        CityCode: '0901',
        CityName: 'Kranj',
        StateCode: '09',
        StateName: 'Alta Carniola',
        CountryCode: 'Si',
        CountryName: 'Eslovenia',
    },
    {
        CityCode: '1001',
        CityName: 'Postojna',
        StateCode: '10',
        StateName: 'Baja Carniola-Karst',
        CountryCode: 'Si',
        CountryName: 'Eslovenia',
    },
    {
        CityCode: '1101',
        CityName: 'Nova Gorica',
        StateCode: '11',
        StateName: 'Región de Gorizia',
        CountryCode: 'Si',
        CountryName: 'Eslovenia',
    },
    {
        CityCode: '1201',
        CityName: 'Koper',
        StateCode: '12',
        StateName: 'Litoral-Karst',
        CountryCode: 'Si',
        CountryName: 'Eslovenia',
    },
    {
        CityCode: '0101',
        CityName: 'Madrid',
        StateCode: '09',
        StateName: 'Comunidad de Madrid',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '0201',
        CityName: 'Barcelona',
        StateCode: '08',
        StateName: 'Cataluña',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '0301',
        CityName: 'Valencia',
        StateCode: '10',
        StateName: 'Comunidad Valenciana',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '0401',
        CityName: 'Sevilla',
        StateCode: '01',
        StateName: 'Andalucía',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '0501',
        CityName: 'Zaragoza',
        StateCode: '02',
        StateName: 'Aragón',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '0601',
        CityName: 'Málaga',
        StateCode: '01',
        StateName: 'Andalucía',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '0701',
        CityName: 'Murcia',
        StateCode: '17',
        StateName: 'Región de Murcia',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '0801',
        CityName: 'Palma de Mallorca',
        StateCode: '13',
        StateName: 'Islas Baleares',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '0901',
        CityName: 'Las Palmas de Gran Canaria',
        StateCode: '04',
        StateName: 'Canarias',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '1001',
        CityName: 'Bilbao',
        StateCode: '16',
        StateName: 'País Vasco',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '1101',
        CityName: 'Alicante',
        StateCode: '10',
        StateName: 'Comunidad Valenciana',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '1201',
        CityName: 'Córdoba',
        StateCode: '01',
        StateName: 'Andalucía',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '1301',
        CityName: 'Valladolid',
        StateCode: '06',
        StateName: 'Castilla y León',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '1401',
        CityName: 'Vigo',
        StateCode: '12',
        StateName: 'Galicia',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '1501',
        CityName: 'Gijón',
        StateCode: '03',
        StateName: 'Asturias',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '1601',
        CityName: 'Hospitalet de Llobregat',
        StateCode: '08',
        StateName: 'Cataluña',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '1701',
        CityName: 'Vitoria',
        StateCode: '16',
        StateName: 'País Vasco',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '1801',
        CityName: 'La Coruña',
        StateCode: '12',
        StateName: 'Galicia',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '1901',
        CityName: 'Granada',
        StateCode: '01',
        StateName: 'Andalucía',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '2001',
        CityName: 'Elche',
        StateCode: '10',
        StateName: 'Comunidad Valenciana',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '2101',
        CityName: 'Oviedo',
        StateCode: '03',
        StateName: 'Asturias',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '2201',
        CityName: 'Tarrasa',
        StateCode: '08',
        StateName: 'Cataluña',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '2301',
        CityName: 'Badalona',
        StateCode: '08',
        StateName: 'Cataluña',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '2401',
        CityName: 'Cartagena',
        StateCode: '17',
        StateName: 'Región de Murcia',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '2501',
        CityName: 'Jerez de la Frontera',
        StateCode: '01',
        StateName: 'Andalucía',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '2601',
        CityName: 'Sabadell',
        StateCode: '08',
        StateName: 'Cataluña',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '2701',
        CityName: 'Móstoles',
        StateCode: '09',
        StateName: 'Comunidad de Madrid',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '2801',
        CityName: 'Santa Cruz de Tenerife',
        StateCode: '04',
        StateName: 'Canarias',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '2901',
        CityName: 'Pamplona',
        StateCode: '15',
        StateName: 'Navarra',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '3001',
        CityName: 'Almería',
        StateCode: '01',
        StateName: 'Andalucía',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '3101',
        CityName: 'Fuenlabrada',
        StateCode: '09',
        StateName: 'Comunidad de Madrid',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '3201',
        CityName: 'Alcalá de Henares',
        StateCode: '09',
        StateName: 'Comunidad de Madrid',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '3301',
        CityName: 'Leganés',
        StateCode: '09',
        StateName: 'Comunidad de Madrid',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '3401',
        CityName: 'San Sebastián',
        StateCode: '16',
        StateName: 'País Vasco',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '3501',
        CityName: 'Getafe',
        StateCode: '09',
        StateName: 'Comunidad de Madrid',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '3601',
        CityName: 'Burgos',
        StateCode: '06',
        StateName: 'Castilla y León',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '3701',
        CityName: 'Albacete',
        StateCode: '07',
        StateName: 'Castilla-La Mancha',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '3801',
        CityName: 'Santander',
        StateCode: '05',
        StateName: 'Cantabria',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '3901',
        CityName: 'Castellón de la Plana',
        StateCode: '10',
        StateName: 'Comunidad Valenciana',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '4001',
        CityName: 'Alcorcón',
        StateCode: '09',
        StateName: 'Comunidad de Madrid',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '4101',
        CityName: 'San Cristóbal de la Laguna',
        StateCode: '04',
        StateName: 'Canarias',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '4201',
        CityName: 'Logroño',
        StateCode: '14',
        StateName: 'La Rioja',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '4301',
        CityName: 'Badajoz',
        StateCode: '11',
        StateName: 'Extremadura',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '4401',
        CityName: 'Huelva',
        StateCode: '01',
        StateName: 'Andalucía',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '4501',
        CityName: 'Salamanca',
        StateCode: '06',
        StateName: 'Castilla y León',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '4601',
        CityName: 'Marbella',
        StateCode: '01',
        StateName: 'Andalucía',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '4701',
        CityName: 'Lérida',
        StateCode: '08',
        StateName: 'Cataluña',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '4801',
        CityName: 'Dos Hermanas',
        StateCode: '01',
        StateName: 'Andalucía',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '4901',
        CityName: 'Tarragona',
        StateCode: '08',
        StateName: 'Cataluña',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '5001',
        CityName: 'Torrejón de Ardoz',
        StateCode: '09',
        StateName: 'Comunidad de Madrid',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '5101',
        CityName: 'Mataró',
        StateCode: '08',
        StateName: 'Cataluña',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '5201',
        CityName: 'Parla',
        StateCode: '09',
        StateName: 'Comunidad de Madrid',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '5301',
        CityName: 'León',
        StateCode: '06',
        StateName: 'Castilla y León',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '5401',
        CityName: 'Algeciras',
        StateCode: '01',
        StateName: 'Andalucía',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '5501',
        CityName: 'Cádiz',
        StateCode: '01',
        StateName: 'Andalucía',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '5601',
        CityName: 'Santa Coloma de Gramanet',
        StateCode: '08',
        StateName: 'Cataluña',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '5701',
        CityName: 'Alcobendas',
        StateCode: '09',
        StateName: 'Comunidad de Madrid',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '5801',
        CityName: 'Jaén',
        StateCode: '01',
        StateName: 'Andalucía',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '5901',
        CityName: 'Orense',
        StateCode: '12',
        StateName: 'Galicia',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '6001',
        CityName: 'Reus',
        StateCode: '08',
        StateName: 'Cataluña',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '6101',
        CityName: 'Telde',
        StateCode: '04',
        StateName: 'Canarias',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '6201',
        CityName: 'Baracaldo',
        StateCode: '16',
        StateName: 'País Vasco',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '0301',
        CityName: 'Phoenix',
        StateCode: '03',
        StateName: 'Arizona',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '0901',
        CityName: 'Tallahassee',
        StateCode: '09',
        StateName: 'Florida',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '0501',
        CityName: 'Sacramento',
        StateCode: '05',
        StateName: 'California',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '1401',
        CityName: 'Indianápolis',
        StateCode: '14',
        StateName: 'Indiana',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '0101',
        CityName: 'Montgomery',
        StateCode: '01',
        StateName: 'Alabama',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '0201',
        CityName: 'Juneau',
        StateCode: '02',
        StateName: 'Alaska',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '0401',
        CityName: 'Little Rock',
        StateCode: '04',
        StateName: 'Arkansas',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '0601',
        CityName: 'Denver',
        StateCode: '06',
        StateName: 'Colorado',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '0701',
        CityName: 'Hartford',
        StateCode: '07',
        StateName: 'Connecticut',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '0801',
        CityName: 'Dover',
        StateCode: '08',
        StateName: 'Delaware',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '1001',
        CityName: 'Atlanta',
        StateCode: '10',
        StateName: 'Georgia',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '1101',
        CityName: 'Honolulu',
        StateCode: '11',
        StateName: 'Hawaii',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '1201',
        CityName: 'Boise',
        StateCode: '12',
        StateName: 'Idaho',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '1301',
        CityName: 'Springfield',
        StateCode: '13',
        StateName: 'Illinois',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '1501',
        CityName: 'Des Moines',
        StateCode: '15',
        StateName: 'Iowa',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '1601',
        CityName: 'Topeka',
        StateCode: '16',
        StateName: 'Kansas',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '1701',
        CityName: 'Frankfort',
        StateCode: '17',
        StateName: 'Kentucky',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '1801',
        CityName: 'Baton Rouge',
        StateCode: '18',
        StateName: 'Louisiana',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '1901',
        CityName: 'Augusta',
        StateCode: '19',
        StateName: 'Maine',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '2001',
        CityName: 'Annapolis',
        StateCode: '20',
        StateName: 'Maryland',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '2101',
        CityName: 'Boston',
        StateCode: '21',
        StateName: 'Massachusetts',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '2201',
        CityName: 'Lansing',
        StateCode: '22',
        StateName: 'Míchigan',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '2301',
        CityName: 'Saint Paul',
        StateCode: '23',
        StateName: 'Minnesota',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '2401',
        CityName: 'Jackson',
        StateCode: '24',
        StateName: 'Misisipi',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '2501',
        CityName: 'Jefferson City',
        StateCode: '25',
        StateName: 'Misuri',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '2601',
        CityName: 'Helena',
        StateCode: '26',
        StateName: 'Montana',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '2701',
        CityName: 'Lincoln',
        StateCode: '27',
        StateName: 'Nebraska',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '2801',
        CityName: 'Carson City',
        StateCode: '28',
        StateName: 'Nevada',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '2901',
        CityName: 'Concord',
        StateCode: '29',
        StateName: 'New Hampshire',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '3001',
        CityName: 'Trenton',
        StateCode: '30',
        StateName: 'Nueva Jersey',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '3101',
        CityName: 'Santa Fe',
        StateCode: '31',
        StateName: 'Nuevo México',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '3201',
        CityName: 'Albany',
        StateCode: '32',
        StateName: 'Nueva York',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '3301',
        CityName: 'Raleigh',
        StateCode: '33',
        StateName: 'Carolina del Norte',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '3401',
        CityName: 'Bismarck',
        StateCode: '34',
        StateName: 'Dakota del Norte',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '3501',
        CityName: 'Columbus',
        StateCode: '35',
        StateName: 'Ohio',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '3601',
        CityName: 'Oklahoma City',
        StateCode: '36',
        StateName: 'Oklahoma',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '3701',
        CityName: 'Salem',
        StateCode: '37',
        StateName: 'Oregon',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '3801',
        CityName: 'Harrisburg',
        StateCode: '38',
        StateName: 'Pensilvania',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '3901',
        CityName: 'Providence',
        StateCode: '39',
        StateName: 'Rhode Island',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '4001',
        CityName: 'Columbia',
        StateCode: '40',
        StateName: 'Carolina del Sur',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '4101',
        CityName: 'Pierre',
        StateCode: '41',
        StateName: 'Dakota del Sur',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '4201',
        CityName: 'Nashville',
        StateCode: '42',
        StateName: 'Tennessee',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '4301',
        CityName: 'Austin',
        StateCode: '43',
        StateName: 'Texas',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '4401',
        CityName: 'Salt Lake City',
        StateCode: '44',
        StateName: 'Utah',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '4501',
        CityName: 'Montpelier',
        StateCode: '45',
        StateName: 'Vermont',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '4601',
        CityName: 'Richmond',
        StateCode: '46',
        StateName: 'Virginia',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '4701',
        CityName: 'Olympia',
        StateCode: '47',
        StateName: 'Washington',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '4801',
        CityName: 'Charleston',
        StateCode: '48',
        StateName: 'Virginia Occidental',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '4901',
        CityName: 'Madison',
        StateCode: '49',
        StateName: 'Wisconsin',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '5001',
        CityName: 'Cheyenne',
        StateCode: '50',
        StateName: 'Wyoming',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '0101',
        CityName: 'Harju',
        StateCode: '01',
        StateName: 'Tallin',
        CountryCode: 'Ee',
        CountryName: 'Estonia',
    },
    {
        CityCode: '0201',
        CityName: 'Tartu',
        StateCode: '02',
        StateName: 'Tartu',
        CountryCode: 'Ee',
        CountryName: 'Estonia',
    },
    {
        CityCode: '0301',
        CityName: 'Ida-Viru',
        StateCode: '03',
        StateName: 'Narva',
        CountryCode: 'Ee',
        CountryName: 'Estonia',
    },
    {
        CityCode: '0401',
        CityName: 'Ida-Viru',
        StateCode: '04',
        StateName: 'Kohtla-Järve',
        CountryCode: 'Ee',
        CountryName: 'Estonia',
    },
    {
        CityCode: '0501',
        CityName: 'Pärnu',
        StateCode: '05',
        StateName: 'Pärnu',
        CountryCode: 'Ee',
        CountryName: 'Estonia',
    },
    {
        CityCode: '0601',
        CityName: 'Viljandi',
        StateCode: '06',
        StateName: 'Viljandi',
        CountryCode: 'Ee',
        CountryName: 'Estonia',
    },
    {
        CityCode: '0701',
        CityName: 'Lääne-Viru',
        StateCode: '07',
        StateName: 'Rakvere',
        CountryCode: 'Ee',
        CountryName: 'Estonia',
    },
    {
        CityCode: '0801',
        CityName: 'Ida-Viru',
        StateCode: '08',
        StateName: 'Sillamäe',
        CountryCode: 'Ee',
        CountryName: 'Estonia',
    },
    {
        CityCode: '0901',
        CityName: 'Harju',
        StateCode: '09',
        StateName: 'Maardu',
        CountryCode: 'Ee',
        CountryName: 'Estonia',
    },
    {
        CityCode: '1001',
        CityName: 'Saare',
        StateCode: '10',
        StateName: 'Kuressaare',
        CountryCode: 'Ee',
        CountryName: 'Estonia',
    },
    {
        CityCode: '1101',
        CityName: 'Võru',
        StateCode: '11',
        StateName: 'Võru',
        CountryCode: 'Ee',
        CountryName: 'Estonia',
    },
    {
        CityCode: '1201',
        CityName: 'Valga',
        StateCode: '12',
        StateName: 'Valga',
        CountryCode: 'Ee',
        CountryName: 'Estonia',
    },
    {
        CityCode: '1301',
        CityName: 'Lääne',
        StateCode: '13',
        StateName: 'Haapsalu',
        CountryCode: 'Ee',
        CountryName: 'Estonia',
    },
    {
        CityCode: '1401',
        CityName: 'Ida-Viru',
        StateCode: '14',
        StateName: 'Jõhvi',
        CountryCode: 'Ee',
        CountryName: 'Estonia',
    },
    {
        CityCode: '1501',
        CityName: 'Järva',
        StateCode: '15',
        StateName: 'Paide',
        CountryCode: 'Ee',
        CountryName: 'Estonia',
    },
    {
        CityCode: '1601',
        CityName: 'Harju',
        StateCode: '16',
        StateName: 'Keila',
        CountryCode: 'Ee',
        CountryName: 'Estonia',
    },
    {
        CityCode: '1701',
        CityName: 'Ida-Viru',
        StateCode: '17',
        StateName: 'Kiviõli',
        CountryCode: 'Ee',
        CountryName: 'Estonia',
    },
    {
        CityCode: '1801',
        CityName: 'Lääne-Viru',
        StateCode: '18',
        StateName: 'Tapa',
        CountryCode: 'Ee',
        CountryName: 'Estonia',
    },
    {
        CityCode: '1901',
        CityName: 'Põlva',
        StateCode: '19',
        StateName: 'Põlva',
        CountryCode: 'Ee',
        CountryName: 'Estonia',
    },
    {
        CityCode: '2001',
        CityName: 'Jõgeva',
        StateCode: '20',
        StateName: 'Jõgeva',
        CountryCode: 'Ee',
        CountryName: 'Estonia',
    },
    {
        CityCode: '0101',
        CityName: 'Adís Abeba',
        StateCode: '01',
        StateName: 'Adís Abeba',
        CountryCode: 'Et',
        CountryName: 'Etiopía',
    },
    {
        CityCode: '0201',
        CityName: 'Semera',
        StateCode: '02',
        StateName: 'Afar',
        CountryCode: 'Et',
        CountryName: 'Etiopía',
    },
    {
        CityCode: '0301',
        CityName: 'Bahir Dar',
        StateCode: '03',
        StateName: 'Amhara',
        CountryCode: 'Et',
        CountryName: 'Etiopía',
    },
    {
        CityCode: '0401',
        CityName: 'Asosa',
        StateCode: '04',
        StateName: 'Benishangul-Gumaz',
        CountryCode: 'Et',
        CountryName: 'Etiopía',
    },
    {
        CityCode: '0501',
        CityName: 'Dire Dawa',
        StateCode: '05',
        StateName: 'Dire Dawa',
        CountryCode: 'Et',
        CountryName: 'Etiopía',
    },
    {
        CityCode: '0601',
        CityName: 'Gambela',
        StateCode: '06',
        StateName: 'Gambela',
        CountryCode: 'Et',
        CountryName: 'Etiopía',
    },
    {
        CityCode: '0701',
        CityName: 'Harar Jugol',
        StateCode: '07',
        StateName: 'Harar',
        CountryCode: 'Et',
        CountryName: 'Etiopía',
    },
    {
        CityCode: '0801',
        CityName: 'Adís Abeba',
        StateCode: '08',
        StateName: 'Oromía',
        CountryCode: 'Et',
        CountryName: 'Etiopía',
    },
    {
        CityCode: '0901',
        CityName: 'Jijiga',
        StateCode: '09',
        StateName: 'Somali Ogadén',
        CountryCode: 'Et',
        CountryName: 'Etiopía',
    },
    {
        CityCode: '1001',
        CityName: 'Awasa',
        StateCode: '10',
        StateName: 'Naciones y pueblos del sur',
        CountryCode: 'Et',
        CountryName: 'Etiopía',
    },
    {
        CityCode: '1101',
        CityName: 'Mekele',
        StateCode: '11',
        StateName: 'Tigray',
        CountryCode: 'Et',
        CountryName: 'Etiopía',
    },
    {
        CityCode: '0101',
        CityName: 'San Fernando',
        StateCode: '01',
        StateName: 'Ilocos',
        CountryCode: 'Ph',
        CountryName: 'Filipinas',
    },
    {
        CityCode: '0201',
        CityName: 'Baguio',
        StateCode: '02',
        StateName: 'Región Administrativa de La Cordillera',
        CountryCode: 'Ph',
        CountryName: 'Filipinas',
    },
    {
        CityCode: '0301',
        CityName: 'Tuguegarao',
        StateCode: '03',
        StateName: 'Valle del Cagayán',
        CountryCode: 'Ph',
        CountryName: 'Filipinas',
    },
    {
        CityCode: '0401',
        CityName: 'San Fernando',
        StateCode: '04',
        StateName: 'Luzón Central',
        CountryCode: 'Ph',
        CountryName: 'Filipinas',
    },
    {
        CityCode: '0501',
        CityName: 'Manila',
        StateCode: '05',
        StateName: 'Región de la Capital Nacional',
        CountryCode: 'Ph',
        CountryName: 'Filipinas',
    },
    {
        CityCode: '0601',
        CityName: 'Calambá',
        StateCode: '06',
        StateName: 'Calabarzon',
        CountryCode: 'Ph',
        CountryName: 'Filipinas',
    },
    {
        CityCode: '0701',
        CityName: 'Calapán',
        StateCode: '07',
        StateName: 'Mimaropa',
        CountryCode: 'Ph',
        CountryName: 'Filipinas',
    },
    {
        CityCode: '0801',
        CityName: 'Legazpi',
        StateCode: '08',
        StateName: 'Bicolandia',
        CountryCode: 'Ph',
        CountryName: 'Filipinas',
    },
    {
        CityCode: '0901',
        CityName: 'Tacloban',
        StateCode: '09',
        StateName: 'Bisayas Orientales',
        CountryCode: 'Ph',
        CountryName: 'Filipinas',
    },
    {
        CityCode: '1001',
        CityName: 'Iloílo',
        StateCode: '10',
        StateName: 'Bisayas Occidentales',
        CountryCode: 'Ph',
        CountryName: 'Filipinas',
    },
    {
        CityCode: '1101',
        CityName: 'Cebú',
        StateCode: '11',
        StateName: 'Bisayas Centrales',
        CountryCode: 'Ph',
        CountryName: 'Filipinas',
    },
    {
        CityCode: '1201',
        CityName: 'Pagadian',
        StateCode: '12',
        StateName: 'Península de Zamboanga',
        CountryCode: 'Ph',
        CountryName: 'Filipinas',
    },
    {
        CityCode: '1301',
        CityName: 'Cagayán de Oro',
        StateCode: '13',
        StateName: 'Mindanao del Norte',
        CountryCode: 'Ph',
        CountryName: 'Filipinas',
    },
    {
        CityCode: '1401',
        CityName: 'Butuan',
        StateCode: '14',
        StateName: 'Caraga',
        CountryCode: 'Ph',
        CountryName: 'Filipinas',
    },
    {
        CityCode: '1501',
        CityName: 'Cotabato',
        StateCode: '15',
        StateName: 'Región Autónoma del Mindanao Musulmán',
        CountryCode: 'Ph',
        CountryName: 'Filipinas',
    },
    {
        CityCode: '1601',
        CityName: 'Davao',
        StateCode: '16',
        StateName: 'Davao',
        CountryCode: 'Ph',
        CountryName: 'Filipinas',
    },
    {
        CityCode: '1701',
        CityName: 'Koronadal',
        StateCode: '17',
        StateName: 'Soccsksargen',
        CountryCode: 'Ph',
        CountryName: 'Filipinas',
    },
    {
        CityCode: '1801',
        CityName: 'Cabancalan-Mabinay',
        StateCode: '18',
        StateName: 'RIN',
        CountryCode: 'Ph',
        CountryName: 'Filipinas',
    },
    {
        CityCode: '0101',
        CityName: 'Hämeenlinna',
        StateCode: '01',
        StateName: 'Finlandia Meridional',
        CountryCode: 'Fi',
        CountryName: 'Finlandia',
    },
    {
        CityCode: '0201',
        CityName: 'Turku',
        StateCode: '02',
        StateName: 'Finlandia Occidental',
        CountryCode: 'Fi',
        CountryName: 'Finlandia',
    },
    {
        CityCode: '0301',
        CityName: 'Mikkeli',
        StateCode: '03',
        StateName: 'Finlandia Oriental',
        CountryCode: 'Fi',
        CountryName: 'Finlandia',
    },
    {
        CityCode: '0401',
        CityName: 'Oulu',
        StateCode: '04',
        StateName: 'Oulu',
        CountryCode: 'Fi',
        CountryName: 'Finlandia',
    },
    {
        CityCode: '0501',
        CityName: 'Rovaniemi',
        StateCode: '05',
        StateName: 'Laponia',
        CountryCode: 'Fi',
        CountryName: 'Finlandia',
    },
    {
        CityCode: '0601',
        CityName: 'Mariehamn',
        StateCode: '06',
        StateName: 'Åland',
        CountryCode: 'Fi',
        CountryName: 'Finlandia',
    },
    {
        CityCode: '0701',
        CityName: 'Helsinki',
        StateCode: '07',
        StateName: 'Uusimaa',
        CountryCode: 'Fi',
        CountryName: 'Finlandia',
    },
    {
        CityCode: '0101',
        CityName: 'Suva',
        StateCode: '01',
        StateName: 'División Central',
        CountryCode: 'Fj',
        CountryName: 'Fiyi',
    },
    {
        CityCode: '0201',
        CityName: 'Labasa',
        StateCode: '02',
        StateName: 'División Norte',
        CountryCode: 'Fj',
        CountryName: 'Fiyi',
    },
    {
        CityCode: '0301',
        CityName: 'Lautoka',
        StateCode: '03',
        StateName: 'División Oeste',
        CountryCode: 'Fj',
        CountryName: 'Fiyi',
    },
    {
        CityCode: '0101',
        CityName: 'Bourg-en-Bresse',
        StateCode: '01',
        StateName: 'Ain',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '0201',
        CityName: 'Laon',
        StateCode: '02',
        StateName: 'Aisne',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '0301',
        CityName: 'Moulins Allier',
        StateCode: '03',
        StateName: 'Allier',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '0401',
        CityName: 'Digne-les-Bains',
        StateCode: '04',
        StateName: 'Alpes de Alta Provenza',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '0501',
        CityName: 'Gap',
        StateCode: '05',
        StateName: 'Altos Alpes',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '0601',
        CityName: 'Niza',
        StateCode: '06',
        StateName: 'Alpes-Marítimos',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '0701',
        CityName: 'Privas',
        StateCode: '07',
        StateName: 'Ardèche',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '0801',
        CityName: 'Charleville-Mézières',
        StateCode: '08',
        StateName: 'Ardenas',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '0901',
        CityName: 'Foix',
        StateCode: '09',
        StateName: 'Ariège',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '1001',
        CityName: 'Troyes',
        StateCode: '10',
        StateName: 'Aube',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '1101',
        CityName: 'Carcasona',
        StateCode: '11',
        StateName: 'Aude',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '1201',
        CityName: 'Rodez',
        StateCode: '12',
        StateName: 'Aveyron',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '1301',
        CityName: 'Marsella',
        StateCode: '13',
        StateName: 'Bocas del Ródano',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '1401',
        CityName: 'Caen',
        StateCode: '14',
        StateName: 'Calvados',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '1501',
        CityName: 'Aurillac',
        StateCode: '15',
        StateName: 'Cantal',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '1601',
        CityName: 'Angulema',
        StateCode: '16',
        StateName: 'Charente',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '1701',
        CityName: 'La Rochela',
        StateCode: '17',
        StateName: 'Charente Marítimo',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '1801',
        CityName: 'Bourges',
        StateCode: '18',
        StateName: 'Cher',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '1901',
        CityName: 'Tulle',
        StateCode: '19',
        StateName: 'Corrèze',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '2001',
        CityName: 'Ajaccio',
        StateCode: '20',
        StateName: 'Córcega del Sur',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '2101',
        CityName: 'Bastia',
        StateCode: '21',
        StateName: 'Alta Córcega',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '2201',
        CityName: 'Dijon',
        StateCode: '22',
        StateName: "Côte-d'Or",
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '2301',
        CityName: 'Saint-Brieuc',
        StateCode: '23',
        StateName: "Côtes-d'Armor",
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '2401',
        CityName: 'Guéret',
        StateCode: '24',
        StateName: 'Creuse',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '2501',
        CityName: 'Périgueux',
        StateCode: '25',
        StateName: 'Dordoña',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '2601',
        CityName: 'Besanzón',
        StateCode: '26',
        StateName: 'Doubs',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '2701',
        CityName: 'Valence',
        StateCode: '27',
        StateName: 'Drôme',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '2801',
        CityName: 'Évreux',
        StateCode: '28',
        StateName: 'Eure',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '2901',
        CityName: 'Chartres',
        StateCode: '29',
        StateName: 'Eure y Loir',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '3001',
        CityName: 'Quimper',
        StateCode: '30',
        StateName: 'Finisterre',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '3101',
        CityName: 'Nimes',
        StateCode: '31',
        StateName: 'Gard',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '3201',
        CityName: 'Toulouse',
        StateCode: '32',
        StateName: 'Alto Garona',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '3301',
        CityName: 'Auch',
        StateCode: '33',
        StateName: 'Gers',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '3401',
        CityName: 'Burdeos',
        StateCode: '34',
        StateName: 'Gironda',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '3501',
        CityName: 'Montpellier',
        StateCode: '35',
        StateName: 'Hérault',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '3601',
        CityName: 'Rennes',
        StateCode: '36',
        StateName: 'Ille y Vilaine',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '3701',
        CityName: 'Châteauroux',
        StateCode: '37',
        StateName: 'Indre',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '3801',
        CityName: 'Tours',
        StateCode: '38',
        StateName: 'Indre y Loira',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '3901',
        CityName: 'Grenoble',
        StateCode: '39',
        StateName: 'Isère',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '4001',
        CityName: 'Lons-le-Saunier',
        StateCode: '40',
        StateName: 'Jura',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '4101',
        CityName: 'Mont-de-Marsan',
        StateCode: '41',
        StateName: 'Landas',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '4201',
        CityName: 'Blois',
        StateCode: '42',
        StateName: 'Loir y Cher',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '4301',
        CityName: 'Saint-Étienne',
        StateCode: '43',
        StateName: 'Loira',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '4401',
        CityName: 'Le Puy-en-Velay',
        StateCode: '44',
        StateName: 'Alto Loira',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '4501',
        CityName: 'Nantes',
        StateCode: '45',
        StateName: 'Loira Atlántico',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '4601',
        CityName: 'Orleans',
        StateCode: '46',
        StateName: 'Loiret',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '4701',
        CityName: 'Cahors',
        StateCode: '47',
        StateName: 'Lot',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '4801',
        CityName: 'Agen',
        StateCode: '48',
        StateName: 'Lot y Garona',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '4901',
        CityName: 'Mende',
        StateCode: '49',
        StateName: 'Lozère',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '5001',
        CityName: 'Angers',
        StateCode: '50',
        StateName: 'Maine y Loira',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '5101',
        CityName: 'Saint-Lô',
        StateCode: '51',
        StateName: 'Mancha',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '5201',
        CityName: 'Châlons-en-Champagne',
        StateCode: '52',
        StateName: 'Marne',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '5301',
        CityName: 'Chaumont',
        StateCode: '53',
        StateName: 'Alto Marne',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '5401',
        CityName: 'Laval',
        StateCode: '54',
        StateName: 'Mayenne',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '5501',
        CityName: 'Nancy',
        StateCode: '55',
        StateName: 'Meurthe y Mosela',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '5601',
        CityName: 'Bar-le-Duc',
        StateCode: '56',
        StateName: 'Mosa',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '5701',
        CityName: 'Vannes',
        StateCode: '57',
        StateName: 'Morbihan',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '5801',
        CityName: 'Metz',
        StateCode: '58',
        StateName: 'Mosela',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '5901',
        CityName: 'Nevers',
        StateCode: '59',
        StateName: 'Nièvre',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '6001',
        CityName: 'Lille',
        StateCode: '60',
        StateName: 'Norte',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '6101',
        CityName: 'Beauvais',
        StateCode: '61',
        StateName: 'Oise',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '6201',
        CityName: 'Alençon',
        StateCode: '62',
        StateName: 'Orne',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '6301',
        CityName: 'Arrás',
        StateCode: '63',
        StateName: 'Paso de Calais',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '6401',
        CityName: 'Clermont-Ferrand',
        StateCode: '64',
        StateName: 'Puy-de-Dôme',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '6501',
        CityName: 'Pau',
        StateCode: '65',
        StateName: 'Pirineos Atlánticos',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '6601',
        CityName: 'Tarbes',
        StateCode: '66',
        StateName: 'Altos Pirineos',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '6701',
        CityName: 'Perpiñán',
        StateCode: '67',
        StateName: 'Pirineos Orientales',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '6801',
        CityName: 'Estrasburgo',
        StateCode: '68',
        StateName: 'Bajo Rin',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '6901',
        CityName: 'Colmar',
        StateCode: '69',
        StateName: 'Alto Rin',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '7001',
        CityName: 'Lyon',
        StateCode: '70',
        StateName: 'Ródano',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '7101',
        CityName: 'Vesoul',
        StateCode: '71',
        StateName: 'Alto Saona',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '7201',
        CityName: 'Mâcon',
        StateCode: '72',
        StateName: 'Saona y Loira',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '7301',
        CityName: 'Le Mans',
        StateCode: '73',
        StateName: 'Sarthe',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '7401',
        CityName: 'Chambéry',
        StateCode: '74',
        StateName: 'Saboya',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '7501',
        CityName: 'Annecy',
        StateCode: '75',
        StateName: 'Alta Saboya',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '7601',
        CityName: 'París',
        StateCode: '76',
        StateName: 'París',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '7701',
        CityName: 'Ruán',
        StateCode: '77',
        StateName: 'Sena Marítimo',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '7801',
        CityName: 'Melun',
        StateCode: '78',
        StateName: 'Sena y Marne',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '7901',
        CityName: 'Versalles',
        StateCode: '79',
        StateName: 'Yvelines',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '8001',
        CityName: 'Niort',
        StateCode: '80',
        StateName: 'Deux-Sèvres',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '8101',
        CityName: 'Amiens',
        StateCode: '81',
        StateName: 'Somme',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '8201',
        CityName: 'Albi',
        StateCode: '82',
        StateName: 'Tarn',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '8301',
        CityName: 'Montauban',
        StateCode: '83',
        StateName: 'Tarn y Garona',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '8401',
        CityName: 'Toulon',
        StateCode: '84',
        StateName: 'Var',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '8501',
        CityName: 'Aviñón',
        StateCode: '85',
        StateName: 'Vaucluse',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '8601',
        CityName: 'La Roche-sur-Yon',
        StateCode: '86',
        StateName: 'Vendée',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '8701',
        CityName: 'Poitiers',
        StateCode: '87',
        StateName: 'Vienne',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '8801',
        CityName: 'Limoges',
        StateCode: '88',
        StateName: 'Alto Vienne',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '8901',
        CityName: 'Épinal',
        StateCode: '89',
        StateName: 'Vosgos',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '9001',
        CityName: 'Auxerre',
        StateCode: '90',
        StateName: 'Yonne',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '9101',
        CityName: 'Belfort',
        StateCode: '91',
        StateName: 'Territorio de Belfort',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '9201',
        CityName: 'Évry',
        StateCode: '92',
        StateName: 'Essonne',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '9301',
        CityName: 'Nanterre',
        StateCode: '93',
        StateName: 'Altos del Sena',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '9401',
        CityName: 'Bobigny',
        StateCode: '94',
        StateName: 'Sena-San Denis',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '9501',
        CityName: 'Créteil',
        StateCode: '95',
        StateName: 'Valle del Marne',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '9601',
        CityName: 'Pontoise',
        StateCode: '96',
        StateName: 'Valle del Oise',
        CountryCode: 'Fr',
        CountryName: 'Francia',
    },
    {
        CityCode: '0101',
        CityName: 'Libreville',
        StateCode: '01',
        StateName: 'Estuaire',
        CountryCode: 'Ga',
        CountryName: 'Gabón',
    },
    {
        CityCode: '0201',
        CityName: 'Franceville',
        StateCode: '02',
        StateName: 'Haut-Ogooué',
        CountryCode: 'Ga',
        CountryName: 'Gabón',
    },
    {
        CityCode: '0301',
        CityName: 'Lambaréné',
        StateCode: '03',
        StateName: 'Moyen-Ogooué',
        CountryCode: 'Ga',
        CountryName: 'Gabón',
    },
    {
        CityCode: '0401',
        CityName: 'Mouila',
        StateCode: '04',
        StateName: 'Ngounié',
        CountryCode: 'Ga',
        CountryName: 'Gabón',
    },
    {
        CityCode: '0501',
        CityName: 'Tchibanga',
        StateCode: '05',
        StateName: 'Nyanga',
        CountryCode: 'Ga',
        CountryName: 'Gabón',
    },
    {
        CityCode: '0601',
        CityName: 'Makokou',
        StateCode: '06',
        StateName: 'Ogooué-Ivindo',
        CountryCode: 'Ga',
        CountryName: 'Gabón',
    },
    {
        CityCode: '0701',
        CityName: 'Koulamoutou',
        StateCode: '07',
        StateName: 'Ogooué-Lolo',
        CountryCode: 'Ga',
        CountryName: 'Gabón',
    },
    {
        CityCode: '0801',
        CityName: 'Port-Gentil',
        StateCode: '08',
        StateName: 'Ogooué-Maritime',
        CountryCode: 'Ga',
        CountryName: 'Gabón',
    },
    {
        CityCode: '0901',
        CityName: 'Oyem',
        StateCode: '09',
        StateName: 'Woleu-Ntem',
        CountryCode: 'Ga',
        CountryName: 'Gabón',
    },
    {
        CityCode: '0101',
        CityName: 'Mansa Konko',
        StateCode: '01',
        StateName: 'División Lower River',
        CountryCode: 'Gm',
        CountryName: 'Gambia',
    },
    {
        CityCode: '0201',
        CityName: 'Janjanbureh',
        StateCode: '02',
        StateName: 'División Central River',
        CountryCode: 'Gm',
        CountryName: 'Gambia',
    },
    {
        CityCode: '0301',
        CityName: 'Kerewan',
        StateCode: '03',
        StateName: 'División North Bank',
        CountryCode: 'Gm',
        CountryName: 'Gambia',
    },
    {
        CityCode: '0401',
        CityName: 'Basse Santa Su',
        StateCode: '04',
        StateName: 'División Upper River',
        CountryCode: 'Gm',
        CountryName: 'Gambia',
    },
    {
        CityCode: '0501',
        CityName: 'Brikama',
        StateCode: '05',
        StateName: 'División Western',
        CountryCode: 'Gm',
        CountryName: 'Gambia',
    },
    {
        CityCode: '0601',
        CityName: 'Banjul',
        StateCode: '06',
        StateName: 'Banjul',
        CountryCode: 'Gm',
        CountryName: 'Gambia',
    },
    {
        CityCode: '0101',
        CityName: 'Sujumi',
        StateCode: '01',
        StateName: 'Abjasia',
        CountryCode: 'Ge',
        CountryName: 'Georgia',
    },
    {
        CityCode: '0201',
        CityName: 'Zugdidi',
        StateCode: '02',
        StateName: 'Samegrelo-Zemo Svaneti',
        CountryCode: 'Ge',
        CountryName: 'Georgia',
    },
    {
        CityCode: '0301',
        CityName: 'Ozurgueti',
        StateCode: '03',
        StateName: 'Guria',
        CountryCode: 'Ge',
        CountryName: 'Georgia',
    },
    {
        CityCode: '0401',
        CityName: 'Batumi',
        StateCode: '04',
        StateName: 'Ayaria',
        CountryCode: 'Ge',
        CountryName: 'Georgia',
    },
    {
        CityCode: '0501',
        CityName: 'Ambrolauri',
        StateCode: '05',
        StateName: 'Racha-Lechjumi y Kvemo Svaneti',
        CountryCode: 'Ge',
        CountryName: 'Georgia',
    },
    {
        CityCode: '0601',
        CityName: 'Kutaisi',
        StateCode: '06',
        StateName: 'Imereti',
        CountryCode: 'Ge',
        CountryName: 'Georgia',
    },
    {
        CityCode: '0701',
        CityName: 'Ajaltsije',
        StateCode: '07',
        StateName: 'Samtsje-Yavajeti',
        CountryCode: 'Ge',
        CountryName: 'Georgia',
    },
    {
        CityCode: '0801',
        CityName: 'Gori',
        StateCode: '08',
        StateName: 'Shida Kartli',
        CountryCode: 'Ge',
        CountryName: 'Georgia',
    },
    {
        CityCode: '0901',
        CityName: 'Mtsjeta',
        StateCode: '09',
        StateName: 'Mtsjeta-Mtianeti',
        CountryCode: 'Ge',
        CountryName: 'Georgia',
    },
    {
        CityCode: '1001',
        CityName: 'Rustavi',
        StateCode: '10',
        StateName: 'Kvemo Kartli',
        CountryCode: 'Ge',
        CountryName: 'Georgia',
    },
    {
        CityCode: '1101',
        CityName: 'Telavi',
        StateCode: '11',
        StateName: 'Kajeti',
        CountryCode: 'Ge',
        CountryName: 'Georgia',
    },
    {
        CityCode: '1201',
        CityName: 'Tiflis',
        StateCode: '12',
        StateName: 'Tiflis',
        CountryCode: 'Ge',
        CountryName: 'Georgia',
    },
    {
        CityCode: '0101',
        CityName: 'Kumasi',
        StateCode: '01',
        StateName: 'Ashanti',
        CountryCode: 'Gh',
        CountryName: 'Ghana',
    },
    {
        CityCode: '0201',
        CityName: 'Sunyani',
        StateCode: '02',
        StateName: 'Brong-Ahafo',
        CountryCode: 'Gh',
        CountryName: 'Ghana',
    },
    {
        CityCode: '0301',
        CityName: 'Cape Coast',
        StateCode: '03',
        StateName: 'Ghana Central',
        CountryCode: 'Gh',
        CountryName: 'Ghana',
    },
    {
        CityCode: '0401',
        CityName: 'Koforidua',
        StateCode: '04',
        StateName: 'Ghana Oriental',
        CountryCode: 'Gh',
        CountryName: 'Ghana',
    },
    {
        CityCode: '0501',
        CityName: 'Acra',
        StateCode: '05',
        StateName: 'Gran Acra',
        CountryCode: 'Gh',
        CountryName: 'Ghana',
    },
    {
        CityCode: '0601',
        CityName: 'Tamale',
        StateCode: '06',
        StateName: 'Ghana Septentrional',
        CountryCode: 'Gh',
        CountryName: 'Ghana',
    },
    {
        CityCode: '0701',
        CityName: 'Bolgatanga',
        StateCode: '07',
        StateName: 'Alta Ghana Oriental',
        CountryCode: 'Gh',
        CountryName: 'Ghana',
    },
    {
        CityCode: '0801',
        CityName: 'Wa',
        StateCode: '08',
        StateName: 'Alta Ghana Occidental',
        CountryCode: 'Gh',
        CountryName: 'Ghana',
    },
    {
        CityCode: '0901',
        CityName: 'Ho',
        StateCode: '09',
        StateName: 'Volta',
        CountryCode: 'Gh',
        CountryName: 'Ghana',
    },
    {
        CityCode: '1001',
        CityName: 'Sekondi-Takoradi',
        StateCode: '10',
        StateName: 'Ghana Occidental',
        CountryCode: 'Gh',
        CountryName: 'Ghana',
    },
    {
        CityCode: '0101',
        CityName: 'Atenas',
        StateCode: '01',
        StateName: 'Ática',
        CountryCode: 'Gr',
        CountryName: 'Grecia',
    },
    {
        CityCode: '0201',
        CityName: 'Macedonia-Tracia',
        StateCode: '02',
        StateName: 'Tesalónica',
        CountryCode: 'Gr',
        CountryName: 'Grecia',
    },
    {
        CityCode: '0301',
        CityName: 'Epiro-Macedonia Occidental',
        StateCode: '03',
        StateName: 'Ioánina',
        CountryCode: 'Gr',
        CountryName: 'Grecia',
    },
    {
        CityCode: '0401',
        CityName: 'Tesalia-Grecia Central',
        StateCode: '04',
        StateName: 'Lárisa',
        CountryCode: 'Gr',
        CountryName: 'Grecia',
    },
    {
        CityCode: '0501',
        CityName: 'Peloponeso, Grecia Occidental y Jónico',
        StateCode: '05',
        StateName: 'Patras',
        CountryCode: 'Gr',
        CountryName: 'Grecia',
    },
    {
        CityCode: '0601',
        CityName: 'Egeo',
        StateCode: '06',
        StateName: 'El Pireo',
        CountryCode: 'Gr',
        CountryName: 'Grecia',
    },
    {
        CityCode: '0701',
        CityName: 'Creta',
        StateCode: '07',
        StateName: 'Heraclión',
        CountryCode: 'Gr',
        CountryName: 'Grecia',
    },
    {
        CityCode: '0101',
        CityName: 'Nuuk',
        StateCode: '01',
        StateName: 'Sermersooq',
        CountryCode: 'Gl',
        CountryName: 'Groenlandia',
    },
    {
        CityCode: '0201',
        CityName: 'Ilulissat',
        StateCode: '02',
        StateName: 'Qaasuitsup',
        CountryCode: 'Gl',
        CountryName: 'Groenlandia',
    },
    {
        CityCode: '0301',
        CityName: 'Sisimiut',
        StateCode: '03',
        StateName: 'Qeqqata',
        CountryCode: 'Gl',
        CountryName: 'Groenlandia',
    },
    {
        CityCode: '0401',
        CityName: 'Qaqortoq',
        StateCode: '04',
        StateName: 'Kujalleq',
        CountryCode: 'Gl',
        CountryName: 'Groenlandia',
    },
    {
        CityCode: '0101',
        CityName: 'Basse-Terre',
        StateCode: '01',
        StateName: 'Basse-Terre',
        CountryCode: 'Gp',
        CountryName: 'Guadalupe',
    },
    {
        CityCode: '0101',
        CityName: 'Agaña',
        StateCode: '01',
        StateName: 'Agaña',
        CountryCode: 'Gu',
        CountryName: 'Guam',
    },
    {
        CityCode: '0101',
        CityName: 'Flores',
        StateCode: '01',
        StateName: 'Petén',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '0201',
        CityName: 'Huehuetenango',
        StateCode: '02',
        StateName: 'Huehuetenango',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '0301',
        CityName: 'Santa Cruz del Quiché',
        StateCode: '03',
        StateName: 'Quiché',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '0401',
        CityName: 'Cobán',
        StateCode: '04',
        StateName: 'Alta Verapaz',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '0501',
        CityName: 'Puerto Barrios',
        StateCode: '05',
        StateName: 'Izabal',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '0601',
        CityName: 'San Marcos',
        StateCode: '06',
        StateName: 'San Marcos',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '0701',
        CityName: 'Quetzaltenango',
        StateCode: '07',
        StateName: 'Quetzaltenango',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '0801',
        CityName: 'Totonicapán',
        StateCode: '08',
        StateName: 'Totonicapán',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '0901',
        CityName: 'Sololá',
        StateCode: '09',
        StateName: 'Sololá',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '1001',
        CityName: 'Chimaltenango',
        StateCode: '10',
        StateName: 'Chimaltenango',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '1101',
        CityName: 'Antigua Guatemala',
        StateCode: '11',
        StateName: 'Sacatepéquez',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '1201',
        CityName: 'Ciudad de Guatemala',
        StateCode: '12',
        StateName: 'Guatemala',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '1301',
        CityName: 'Salamá',
        StateCode: '13',
        StateName: 'Baja Verapaz',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '1401',
        CityName: 'Guastatoya',
        StateCode: '14',
        StateName: 'El Progreso',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '1501',
        CityName: 'Jalapa',
        StateCode: '15',
        StateName: 'Jalapa',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '1601',
        CityName: 'Zacapa',
        StateCode: '16',
        StateName: 'Zacapa',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '1701',
        CityName: 'Chiquimula',
        StateCode: '17',
        StateName: 'Chiquimula',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '1801',
        CityName: 'Retalhuleu',
        StateCode: '18',
        StateName: 'Retalhuleu',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '1901',
        CityName: 'Mazatenango',
        StateCode: '19',
        StateName: 'Suchitepéquez',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '2001',
        CityName: 'Escuintla',
        StateCode: '20',
        StateName: 'Escuintla',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '2101',
        CityName: 'Cuilapa',
        StateCode: '21',
        StateName: 'Santa Rosa',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '2201',
        CityName: 'Jutiapa',
        StateCode: '22',
        StateName: 'Jutiapa',
        CountryCode: 'Gt',
        CountryName: 'Guatemala',
    },
    {
        CityCode: '0101',
        CityName: 'Cayena',
        StateCode: '01',
        StateName: 'Distrito de Cayenne',
        CountryCode: 'Gf',
        CountryName: 'Guayana Francesa',
    },
    {
        CityCode: '0201',
        CityName: 'Saint-Laurent-du-Maroni',
        StateCode: '02',
        StateName: 'Distrito de Saint-Laurent-du-Maroni',
        CountryCode: 'Gf',
        CountryName: 'Guayana Francesa',
    },
    {
        CityCode: '0101',
        CityName: 'Saint-Pierre-Port',
        StateCode: '01',
        StateName: 'Saint-Pierre-Port',
        CountryCode: 'Gg',
        CountryName: 'Guernsey',
    },
    {
        CityCode: '0201',
        CityName: 'Le Valle',
        StateCode: '02',
        StateName: 'Le Valle',
        CountryCode: 'Gg',
        CountryName: 'Guernsey',
    },
    {
        CityCode: '0301',
        CityName: 'Sainte-Marie-du-Câtel',
        StateCode: '03',
        StateName: 'Sainte-Marie-du-Câtel',
        CountryCode: 'Gg',
        CountryName: 'Guernsey',
    },
    {
        CityCode: '0401',
        CityName: 'Saint-Samson',
        StateCode: '04',
        StateName: 'Saint-Samson',
        CountryCode: 'Gg',
        CountryName: 'Guernsey',
    },
    {
        CityCode: '0501',
        CityName: 'Saint-Martin-de-la-Bellouse',
        StateCode: '05',
        StateName: 'Saint-Martin-de-la-Bellouse',
        CountryCode: 'Gg',
        CountryName: 'Guernsey',
    },
    {
        CityCode: '0601',
        CityName: 'Saint-Sauveur',
        StateCode: '06',
        StateName: 'Saint-Sauveur',
        CountryCode: 'Gg',
        CountryName: 'Guernsey',
    },
    {
        CityCode: '0701',
        CityName: 'Saint-André-de-la-Pommeraye',
        StateCode: '07',
        StateName: 'Saint-André-de-la-Pommeraye',
        CountryCode: 'Gg',
        CountryName: 'Guernsey',
    },
    {
        CityCode: '0801',
        CityName: 'Saint-Pierre-du-Bois',
        StateCode: '08',
        StateName: 'Saint-Pierre-du-Bois',
        CountryCode: 'Gg',
        CountryName: 'Guernsey',
    },
    {
        CityCode: '0901',
        CityName: 'La Forêt',
        StateCode: '09',
        StateName: 'La Forêt',
        CountryCode: 'Gg',
        CountryName: 'Guernsey',
    },
    {
        CityCode: '1001',
        CityName: 'Torteval',
        StateCode: '10',
        StateName: 'Torteval',
        CountryCode: 'Gg',
        CountryName: 'Guernsey',
    },
    {
        CityCode: '0101',
        CityName: 'Boké',
        StateCode: '01',
        StateName: 'Boké',
        CountryCode: 'Gn',
        CountryName: 'Guinea',
    },
    {
        CityCode: '0201',
        CityName: 'Faranah',
        StateCode: '02',
        StateName: 'Faranah',
        CountryCode: 'Gn',
        CountryName: 'Guinea',
    },
    {
        CityCode: '0301',
        CityName: 'Kankan',
        StateCode: '03',
        StateName: 'Kankan',
        CountryCode: 'Gn',
        CountryName: 'Guinea',
    },
    {
        CityCode: '0401',
        CityName: 'Kindia',
        StateCode: '04',
        StateName: 'Kindia',
        CountryCode: 'Gn',
        CountryName: 'Guinea',
    },
    {
        CityCode: '0501',
        CityName: 'Labé',
        StateCode: '05',
        StateName: 'Labé',
        CountryCode: 'Gn',
        CountryName: 'Guinea',
    },
    {
        CityCode: '0601',
        CityName: 'Mamou',
        StateCode: '06',
        StateName: 'Mamou',
        CountryCode: 'Gn',
        CountryName: 'Guinea',
    },
    {
        CityCode: '0701',
        CityName: 'Nzérékoré',
        StateCode: '07',
        StateName: 'Nzérékoré',
        CountryCode: 'Gn',
        CountryName: 'Guinea',
    },
    {
        CityCode: '0801',
        CityName: 'Conakri',
        StateCode: '08',
        StateName: 'Conakri',
        CountryCode: 'Gn',
        CountryName: 'Guinea',
    },
    {
        CityCode: '0101',
        CityName: 'San Antonio de Palé',
        StateCode: '01',
        StateName: 'Annobón',
        CountryCode: 'Gq',
        CountryName: 'Guinea Ecuatorial',
    },
    {
        CityCode: '0201',
        CityName: 'Malabo',
        StateCode: '02',
        StateName: 'Bioko del Norte',
        CountryCode: 'Gq',
        CountryName: 'Guinea Ecuatorial',
    },
    {
        CityCode: '0301',
        CityName: 'Luba',
        StateCode: '03',
        StateName: 'Bioko del Sur',
        CountryCode: 'Gq',
        CountryName: 'Guinea Ecuatorial',
    },
    {
        CityCode: '0401',
        CityName: 'Evinayong',
        StateCode: '04',
        StateName: 'Centro Sur',
        CountryCode: 'Gq',
        CountryName: 'Guinea Ecuatorial',
    },
    {
        CityCode: '0501',
        CityName: 'Ebebiyín',
        StateCode: '05',
        StateName: 'Kié-Ntem',
        CountryCode: 'Gq',
        CountryName: 'Guinea Ecuatorial',
    },
    {
        CityCode: '0601',
        CityName: 'Bata',
        StateCode: '06',
        StateName: 'Litoral',
        CountryCode: 'Gq',
        CountryName: 'Guinea Ecuatorial',
    },
    {
        CityCode: '0701',
        CityName: 'Mongomo',
        StateCode: '07',
        StateName: 'Wele-Nzas',
        CountryCode: 'Gq',
        CountryName: 'Guinea Ecuatorial',
    },
    {
        CityCode: '0101',
        CityName: 'Bafatá',
        StateCode: '01',
        StateName: 'Bafatá',
        CountryCode: 'Gw',
        CountryName: 'Guinea-Bisáu',
    },
    {
        CityCode: '0201',
        CityName: 'Biombo',
        StateCode: '02',
        StateName: 'Biombo',
        CountryCode: 'Gw',
        CountryName: 'Guinea-Bisáu',
    },
    {
        CityCode: '0301',
        CityName: 'Bisáu',
        StateCode: '03',
        StateName: 'Bisáu',
        CountryCode: 'Gw',
        CountryName: 'Guinea-Bisáu',
    },
    {
        CityCode: '0401',
        CityName: 'Bolama',
        StateCode: '04',
        StateName: 'Bolama',
        CountryCode: 'Gw',
        CountryName: 'Guinea-Bisáu',
    },
    {
        CityCode: '0501',
        CityName: 'Cacheu',
        StateCode: '05',
        StateName: 'Cacheu',
        CountryCode: 'Gw',
        CountryName: 'Guinea-Bisáu',
    },
    {
        CityCode: '0601',
        CityName: 'Gabu',
        StateCode: '06',
        StateName: 'Gabu',
        CountryCode: 'Gw',
        CountryName: 'Guinea-Bisáu',
    },
    {
        CityCode: '0701',
        CityName: 'Oio',
        StateCode: '07',
        StateName: 'Oio',
        CountryCode: 'Gw',
        CountryName: 'Guinea-Bisáu',
    },
    {
        CityCode: '0801',
        CityName: 'Quinara',
        StateCode: '08',
        StateName: 'Quinara',
        CountryCode: 'Gw',
        CountryName: 'Guinea-Bisáu',
    },
    {
        CityCode: '0901',
        CityName: 'Tombali',
        StateCode: '09',
        StateName: 'Tombali',
        CountryCode: 'Gw',
        CountryName: 'Guinea-Bisáu',
    },
    {
        CityCode: '0101',
        CityName: 'Mabaruma',
        StateCode: '01',
        StateName: 'Barima-Waini',
        CountryCode: 'Gy',
        CountryName: 'Guyana',
    },
    {
        CityCode: '0201',
        CityName: 'Anna Regina',
        StateCode: '02',
        StateName: 'Pomeroon-Supenaam',
        CountryCode: 'Gy',
        CountryName: 'Guyana',
    },
    {
        CityCode: '0301',
        CityName: 'Vreed en Hoop',
        StateCode: '03',
        StateName: 'Islas Esequibo-Demerara Occidental',
        CountryCode: 'Gy',
        CountryName: 'Guyana',
    },
    {
        CityCode: '0401',
        CityName: 'Paradise',
        StateCode: '04',
        StateName: 'Demerara-Mahaica',
        CountryCode: 'Gy',
        CountryName: 'Guyana',
    },
    {
        CityCode: '0501',
        CityName: 'Fort Wellington',
        StateCode: '05',
        StateName: 'Mahaica-Berbice',
        CountryCode: 'Gy',
        CountryName: 'Guyana',
    },
    {
        CityCode: '0601',
        CityName: 'Nueva Ámsterdam',
        StateCode: '06',
        StateName: 'Berbice Oriental-Corentyne',
        CountryCode: 'Gy',
        CountryName: 'Guyana',
    },
    {
        CityCode: '0701',
        CityName: 'Bartica',
        StateCode: '07',
        StateName: 'Cuyuni-Mazaruni',
        CountryCode: 'Gy',
        CountryName: 'Guyana',
    },
    {
        CityCode: '0801',
        CityName: 'Mahdia',
        StateCode: '08',
        StateName: 'Potaro-Siparuni',
        CountryCode: 'Gy',
        CountryName: 'Guyana',
    },
    {
        CityCode: '0901',
        CityName: 'Lethem',
        StateCode: '09',
        StateName: 'Alto Tacutu-Alto Esequibo',
        CountryCode: 'Gy',
        CountryName: 'Guyana',
    },
    {
        CityCode: '1001',
        CityName: 'Linden',
        StateCode: '10',
        StateName: 'Alto Demerara-Berbice',
        CountryCode: 'Gy',
        CountryName: 'Guyana',
    },
    {
        CityCode: '0101',
        CityName: 'Gonaïves',
        StateCode: '01',
        StateName: 'Artibonito',
        CountryCode: 'Ht',
        CountryName: 'Haití',
    },
    {
        CityCode: '0201',
        CityName: 'Hinche',
        StateCode: '02',
        StateName: 'Centro',
        CountryCode: 'Ht',
        CountryName: 'Haití',
    },
    {
        CityCode: '0301',
        CityName: 'Jérémie',
        StateCode: '03',
        StateName: 'GrandAnse',
        CountryCode: 'Ht',
        CountryName: 'Haití',
    },
    {
        CityCode: '0401',
        CityName: 'Miragoâne',
        StateCode: '04',
        StateName: 'Nippes',
        CountryCode: 'Ht',
        CountryName: 'Haití',
    },
    {
        CityCode: '0501',
        CityName: 'Cabo Haitiano',
        StateCode: '05',
        StateName: 'Norte',
        CountryCode: 'Ht',
        CountryName: 'Haití',
    },
    {
        CityCode: '0601',
        CityName: 'Fuerte Libertad',
        StateCode: '06',
        StateName: 'Nordeste',
        CountryCode: 'Ht',
        CountryName: 'Haití',
    },
    {
        CityCode: '0701',
        CityName: 'Port-de-Paix',
        StateCode: '07',
        StateName: 'Noroeste',
        CountryCode: 'Ht',
        CountryName: 'Haití',
    },
    {
        CityCode: '0801',
        CityName: 'Puerto Príncipe',
        StateCode: '08',
        StateName: 'Oeste',
        CountryCode: 'Ht',
        CountryName: 'Haití',
    },
    {
        CityCode: '0901',
        CityName: 'Les Cayes',
        StateCode: '09',
        StateName: 'Sur',
        CountryCode: 'Ht',
        CountryName: 'Haití',
    },
    {
        CityCode: '1001',
        CityName: 'Jacmel',
        StateCode: '10',
        StateName: 'Sudeste',
        CountryCode: 'Ht',
        CountryName: 'Haití',
    },
    {
        CityCode: '0101',
        CityName: 'Roatán',
        StateCode: '01',
        StateName: 'Islas de la Bahía',
        CountryCode: 'Hn',
        CountryName: 'Honduras',
    },
    {
        CityCode: '0201',
        CityName: 'San Pedro Sula',
        StateCode: '02',
        StateName: 'Cortés',
        CountryCode: 'Hn',
        CountryName: 'Honduras',
    },
    {
        CityCode: '0301',
        CityName: 'La Ceiba',
        StateCode: '03',
        StateName: 'Atlántida',
        CountryCode: 'Hn',
        CountryName: 'Honduras',
    },
    {
        CityCode: '0401',
        CityName: 'Trujillo',
        StateCode: '04',
        StateName: 'Colón',
        CountryCode: 'Hn',
        CountryName: 'Honduras',
    },
    {
        CityCode: '0501',
        CityName: 'Puerto Lempira',
        StateCode: '05',
        StateName: 'Gracias a Dios',
        CountryCode: 'Hn',
        CountryName: 'Honduras',
    },
    {
        CityCode: '0601',
        CityName: 'Santa Rosa de Copán',
        StateCode: '06',
        StateName: 'Copán',
        CountryCode: 'Hn',
        CountryName: 'Honduras',
    },
    {
        CityCode: '0701',
        CityName: 'Santa Bárbara',
        StateCode: '07',
        StateName: 'Santa Bárbara',
        CountryCode: 'Hn',
        CountryName: 'Honduras',
    },
    {
        CityCode: '0801',
        CityName: 'Yoro',
        StateCode: '08',
        StateName: 'Yoro',
        CountryCode: 'Hn',
        CountryName: 'Honduras',
    },
    {
        CityCode: '0901',
        CityName: 'Juticalpa',
        StateCode: '09',
        StateName: 'Olancho',
        CountryCode: 'Hn',
        CountryName: 'Honduras',
    },
    {
        CityCode: '1001',
        CityName: 'Ocotepeque',
        StateCode: '10',
        StateName: 'Ocotepeque',
        CountryCode: 'Hn',
        CountryName: 'Honduras',
    },
    {
        CityCode: '1101',
        CityName: 'Gracias',
        StateCode: '11',
        StateName: 'Lempira',
        CountryCode: 'Hn',
        CountryName: 'Honduras',
    },
    {
        CityCode: '1201',
        CityName: 'La Esperanza',
        StateCode: '12',
        StateName: 'Intibucá',
        CountryCode: 'Hn',
        CountryName: 'Honduras',
    },
    {
        CityCode: '1301',
        CityName: 'Comayagua',
        StateCode: '13',
        StateName: 'Comayagua',
        CountryCode: 'Hn',
        CountryName: 'Honduras',
    },
    {
        CityCode: '1401',
        CityName: 'Distrito Central',
        StateCode: '14',
        StateName: 'Francisco Morazán',
        CountryCode: 'Hn',
        CountryName: 'Honduras',
    },
    {
        CityCode: '1501',
        CityName: 'Yuscarán',
        StateCode: '15',
        StateName: 'El Paraíso',
        CountryCode: 'Hn',
        CountryName: 'Honduras',
    },
    {
        CityCode: '1601',
        CityName: 'La Paz',
        StateCode: '16',
        StateName: 'La Paz',
        CountryCode: 'Hn',
        CountryName: 'Honduras',
    },
    {
        CityCode: '1701',
        CityName: 'Nacaome',
        StateCode: '17',
        StateName: 'Valle',
        CountryCode: 'Hn',
        CountryName: 'Honduras',
    },
    {
        CityCode: '1801',
        CityName: 'Choluteca',
        StateCode: '18',
        StateName: 'Choluteca',
        CountryCode: 'Hn',
        CountryName: 'Honduras',
    },
    {
        CityCode: '0101',
        CityName: 'Ciudad Victoria',
        StateCode: '01',
        StateName: 'Ciudad Victoria',
        CountryCode: 'Hk',
        CountryName: 'Hong Kong',
    },
    {
        CityCode: '0201',
        CityName: 'las Islas',
        StateCode: '02',
        StateName: 'Distrito de las Islas',
        CountryCode: 'Hk',
        CountryName: 'Hong Kong',
    },
    {
        CityCode: '0301',
        CityName: 'Kwai Tsing',
        StateCode: '03',
        StateName: 'Distrito de Kwai Tsing',
        CountryCode: 'Hk',
        CountryName: 'Hong Kong',
    },
    {
        CityCode: '0401',
        CityName: 'Distrito del Norte',
        StateCode: '04',
        StateName: 'Distrito del Norte',
        CountryCode: 'Hk',
        CountryName: 'Hong Kong',
    },
    {
        CityCode: '0501',
        CityName: 'Sai Kung',
        StateCode: '05',
        StateName: 'Distrito de Sai Kung',
        CountryCode: 'Hk',
        CountryName: 'Hong Kong',
    },
    {
        CityCode: '0601',
        CityName: 'Sha Tin',
        StateCode: '06',
        StateName: 'Distrito de Sha Tin',
        CountryCode: 'Hk',
        CountryName: 'Hong Kong',
    },
    {
        CityCode: '0701',
        CityName: 'Tai Po',
        StateCode: '07',
        StateName: 'Distrito de Tai Po',
        CountryCode: 'Hk',
        CountryName: 'Hong Kong',
    },
    {
        CityCode: '0801',
        CityName: 'Tsuen Wan',
        StateCode: '08',
        StateName: 'Distrito de Tsuen Wan',
        CountryCode: 'Hk',
        CountryName: 'Hong Kong',
    },
    {
        CityCode: '0901',
        CityName: 'Tuen Mun',
        StateCode: '09',
        StateName: 'Distrito de Tuen Mun',
        CountryCode: 'Hk',
        CountryName: 'Hong Kong',
    },
    {
        CityCode: '1001',
        CityName: 'Yuen Long',
        StateCode: '10',
        StateName: 'Distrito de Yuen Long',
        CountryCode: 'Hk',
        CountryName: 'Hong Kong',
    },
    {
        CityCode: '1101',
        CityName: 'Eistrito de Kowloon',
        StateCode: '11',
        StateName: 'Eistrito de Kowloon',
        CountryCode: 'Hk',
        CountryName: 'Hong Kong',
    },
    {
        CityCode: '1201',
        CityName: 'Kwun Tong',
        StateCode: '12',
        StateName: 'Distrito de Kwun Tong',
        CountryCode: 'Hk',
        CountryName: 'Hong Kong',
    },
    {
        CityCode: '1301',
        CityName: 'Sham Shui Po',
        StateCode: '13',
        StateName: 'Distrito de Sham Shui Po',
        CountryCode: 'Hk',
        CountryName: 'Hong Kong',
    },
    {
        CityCode: '1401',
        CityName: 'Wong Tai Sin',
        StateCode: '14',
        StateName: 'Distrito de Wong Tai Sin',
        CountryCode: 'Hk',
        CountryName: 'Hong Kong',
    },
    {
        CityCode: '1501',
        CityName: 'Yau Tsim Mong',
        StateCode: '15',
        StateName: 'Distrito de Yau Tsim Mong',
        CountryCode: 'Hk',
        CountryName: 'Hong Kong',
    },
    {
        CityCode: '1601',
        CityName: 'la Central y Oeste',
        StateCode: '16',
        StateName: 'Distrito de la Central y Oeste',
        CountryCode: 'Hk',
        CountryName: 'Hong Kong',
    },
    {
        CityCode: '1701',
        CityName: 'Distrito del Este',
        StateCode: '17',
        StateName: 'Distrito del Este',
        CountryCode: 'Hk',
        CountryName: 'Hong Kong',
    },
    {
        CityCode: '1801',
        CityName: 'Distrito del Sur',
        StateCode: '18',
        StateName: 'Distrito del Sur',
        CountryCode: 'Hk',
        CountryName: 'Hong Kong',
    },
    {
        CityCode: '1901',
        CityName: 'Wan Chai',
        StateCode: '19',
        StateName: 'Distrito de Wan Chai',
        CountryCode: 'Hk',
        CountryName: 'Hong Kong',
    },
    {
        CityCode: '0101',
        CityName: 'Kecskemét',
        StateCode: '01',
        StateName: 'Bács-Kiskun',
        CountryCode: 'Hu',
        CountryName: 'Hungría',
    },
    {
        CityCode: '0201',
        CityName: 'Pécs',
        StateCode: '02',
        StateName: 'Baranya',
        CountryCode: 'Hu',
        CountryName: 'Hungría',
    },
    {
        CityCode: '0301',
        CityName: 'Békéscsaba',
        StateCode: '03',
        StateName: 'Békés',
        CountryCode: 'Hu',
        CountryName: 'Hungría',
    },
    {
        CityCode: '0401',
        CityName: 'Miskolc',
        StateCode: '04',
        StateName: 'Borsod-Abaúj-Zemplén',
        CountryCode: 'Hu',
        CountryName: 'Hungría',
    },
    {
        CityCode: '0501',
        CityName: 'Szeged',
        StateCode: '05',
        StateName: 'Csongrád',
        CountryCode: 'Hu',
        CountryName: 'Hungría',
    },
    {
        CityCode: '0601',
        CityName: 'Székesfehérvár',
        StateCode: '06',
        StateName: 'Fejér',
        CountryCode: 'Hu',
        CountryName: 'Hungría',
    },
    {
        CityCode: '0701',
        CityName: 'Gyor',
        StateCode: '07',
        StateName: 'Gyor-Moson-Sopron',
        CountryCode: 'Hu',
        CountryName: 'Hungría',
    },
    {
        CityCode: '0801',
        CityName: 'Debrecen',
        StateCode: '08',
        StateName: 'Hajdú-Bihar',
        CountryCode: 'Hu',
        CountryName: 'Hungría',
    },
    {
        CityCode: '0901',
        CityName: 'Eger',
        StateCode: '09',
        StateName: 'Heves',
        CountryCode: 'Hu',
        CountryName: 'Hungría',
    },
    {
        CityCode: '1001',
        CityName: 'Szolnok',
        StateCode: '10',
        StateName: 'Jász-Nagykun-Szolnok',
        CountryCode: 'Hu',
        CountryName: 'Hungría',
    },
    {
        CityCode: '1101',
        CityName: 'Tatabánya',
        StateCode: '11',
        StateName: 'Komárom-Esztergom',
        CountryCode: 'Hu',
        CountryName: 'Hungría',
    },
    {
        CityCode: '1201',
        CityName: 'Salgótarján',
        StateCode: '12',
        StateName: 'Nógrád',
        CountryCode: 'Hu',
        CountryName: 'Hungría',
    },
    {
        CityCode: '1301',
        CityName: 'Budapest',
        StateCode: '13',
        StateName: 'Pest',
        CountryCode: 'Hu',
        CountryName: 'Hungría',
    },
    {
        CityCode: '1401',
        CityName: 'Kaposvár',
        StateCode: '14',
        StateName: 'Somogy',
        CountryCode: 'Hu',
        CountryName: 'Hungría',
    },
    {
        CityCode: '1501',
        CityName: 'Nyíregyháza',
        StateCode: '15',
        StateName: 'Szabolcs-Szatmár-Bereg',
        CountryCode: 'Hu',
        CountryName: 'Hungría',
    },
    {
        CityCode: '1601',
        CityName: 'Szekszárd',
        StateCode: '16',
        StateName: 'Tolna',
        CountryCode: 'Hu',
        CountryName: 'Hungría',
    },
    {
        CityCode: '1701',
        CityName: 'Szombathely',
        StateCode: '17',
        StateName: 'Vas',
        CountryCode: 'Hu',
        CountryName: 'Hungría',
    },
    {
        CityCode: '1801',
        CityName: 'Veszprém',
        StateCode: '18',
        StateName: 'Veszprém',
        CountryCode: 'Hu',
        CountryName: 'Hungría',
    },
    {
        CityCode: '1901',
        CityName: 'Zalaegerszeg',
        StateCode: '19',
        StateName: 'Zala',
        CountryCode: 'Hu',
        CountryName: 'Hungría',
    },
    {
        CityCode: '0101',
        CityName: 'Hyderabad',
        StateCode: '01',
        StateName: 'Andhra Pradesh',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '0201',
        CityName: 'Itanagar',
        StateCode: '02',
        StateName: 'Arunachal Pradesh',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '0301',
        CityName: 'Dispur',
        StateCode: '03',
        StateName: 'Assam',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '0401',
        CityName: 'Patna',
        StateCode: '04',
        StateName: 'Bihar',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '0501',
        CityName: 'Naya Raipur',
        StateCode: '05',
        StateName: 'Chhattisgarh',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '0601',
        CityName: 'Panaji',
        StateCode: '06',
        StateName: 'Goa',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '0701',
        CityName: 'Gandhinagar',
        StateCode: '07',
        StateName: 'Guyarat',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '0801',
        CityName: 'Chandigarh',
        StateCode: '08',
        StateName: 'Haryana',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '0901',
        CityName: 'Shimla',
        StateCode: '09',
        StateName: 'Himachal Pradesh',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '1001',
        CityName: 'Srinagar',
        StateCode: '10',
        StateName: 'Jammu y Cachemira',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '1101',
        CityName: 'Ranchi',
        StateCode: '11',
        StateName: 'Jharkhand',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '1201',
        CityName: 'Bangalore',
        StateCode: '12',
        StateName: 'Karnataka',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '1301',
        CityName: 'Thiruvananthapuram',
        StateCode: '13',
        StateName: 'Kerala',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '1401',
        CityName: 'Bhopal',
        StateCode: '14',
        StateName: 'Madhya Pradesh',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '1501',
        CityName: 'Bombay',
        StateCode: '15',
        StateName: 'Maharastra',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '1601',
        CityName: 'Imphal',
        StateCode: '16',
        StateName: 'Manipur',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '1701',
        CityName: 'Shillong',
        StateCode: '17',
        StateName: 'Megalaya',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '1801',
        CityName: 'Aizawl',
        StateCode: '18',
        StateName: 'Mizorán',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '1901',
        CityName: 'Kohima',
        StateCode: '19',
        StateName: 'Nagaland',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '2001',
        CityName: 'Bhubaneshwar',
        StateCode: '20',
        StateName: 'Odisha',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '2101',
        CityName: 'Chandigarh',
        StateCode: '21',
        StateName: 'Punyab',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '2201',
        CityName: 'Jaipur',
        StateCode: '22',
        StateName: 'Rajastán',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '2301',
        CityName: 'Gangtok',
        StateCode: '23',
        StateName: 'Sikkim',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '2401',
        CityName: 'Chennai',
        StateCode: '24',
        StateName: 'Tamil Nadu',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '2501',
        CityName: 'Agartala',
        StateCode: '25',
        StateName: 'Tripura',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '2601',
        CityName: 'Lucknow',
        StateCode: '26',
        StateName: 'Uttar Pradesh',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '2701',
        CityName: 'Dehradun',
        StateCode: '27',
        StateName: 'Uttarakhand',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '2801',
        CityName: 'Calcuta',
        StateCode: '28',
        StateName: 'Bengala Occidental',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '2901',
        CityName: 'l Hyderabad',
        StateCode: '29',
        StateName: 'Telangana',
        CountryCode: 'In',
        CountryName: 'India',
    },
    {
        CityCode: '0101',
        CityName: 'Banda Aceh',
        StateCode: '01',
        StateName: 'Aceh Nanggroe Aceh Darussalam',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '0201',
        CityName: 'Medan',
        StateCode: '02',
        StateName: 'Sumatra Septentrional Sumatera Utara',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '0301',
        CityName: 'Padang',
        StateCode: '03',
        StateName: 'Sumatra Occidental Sumatera Barat',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '0401',
        CityName: 'Pekanbaru',
        StateCode: '04',
        StateName: 'Riau',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '0501',
        CityName: 'Tanjung Pinang',
        StateCode: '05',
        StateName: 'Islas Riau Kepulauan Riau',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '0601',
        CityName: 'Jambi',
        StateCode: '06',
        StateName: 'Jambi',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '0701',
        CityName: 'Bengkulu',
        StateCode: '07',
        StateName: 'Bengkulu',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '0801',
        CityName: 'Palembang',
        StateCode: '08',
        StateName: 'Sumatra Meridional Sumatera Selatan',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '0901',
        CityName: 'Pangkal Pinang',
        StateCode: '09',
        StateName: 'Bangka-Belitung',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '1001',
        CityName: 'Bandar Lampung',
        StateCode: '10',
        StateName: 'Lampung',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '1101',
        CityName: 'Yakarta',
        StateCode: '11',
        StateName: 'Región de Yakarta',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '1201',
        CityName: 'Serang',
        StateCode: '12',
        StateName: 'Bantén',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '1301',
        CityName: 'Bandung',
        StateCode: '13',
        StateName: 'Java Occidental Jawa Barat',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '1401',
        CityName: 'Semarang',
        StateCode: '14',
        StateName: 'Java Central Jawa Tengah',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '1501',
        CityName: 'Yogyakarta',
        StateCode: '15',
        StateName: 'Región de Yogyakarta',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '1601',
        CityName: 'Surabaya',
        StateCode: '16',
        StateName: 'Java Oriental Jawa Timur',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '1701',
        CityName: 'Denpasar',
        StateCode: '17',
        StateName: 'Bali',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '1801',
        CityName: 'Mataram',
        StateCode: '18',
        StateName: 'Nusatenggara Occidental Nusa Tenggara Barat',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '1901',
        CityName: 'Kupang',
        StateCode: '19',
        StateName: 'Nusatenggara Oriental Nusa Tenggara Timur',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '2001',
        CityName: 'Tanjung Selor',
        StateCode: '20',
        StateName: 'Borneo Septentrional',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '2101',
        CityName: 'Pontianak',
        StateCode: '21',
        StateName: 'Borneo Occidental',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '2201',
        CityName: 'Palangkaraya',
        StateCode: '22',
        StateName: 'Borneo Central Kalimantan Tengah',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '2301',
        CityName: 'Banjarmasin',
        StateCode: '23',
        StateName: 'Borneo Meridional Kalimantan Selatan',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '2401',
        CityName: 'Samarinda',
        StateCode: '24',
        StateName: 'Borneo Oriental Kalimantan Timur',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '2501',
        CityName: 'Manado',
        StateCode: '25',
        StateName: 'Célebes Septentrional',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '2601',
        CityName: 'Gorontalo',
        StateCode: '26',
        StateName: 'Gorontalo',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '2701',
        CityName: 'Palu',
        StateCode: '27',
        StateName: 'Célebes Central Sulawesi Tengah',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '2801',
        CityName: 'Kendari',
        StateCode: '28',
        StateName: 'Célebes Suroriental Sulawesi Tenggara',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '2901',
        CityName: 'Makassar',
        StateCode: '29',
        StateName: 'Célebes Meridional Sulawesi Selatan',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '3001',
        CityName: 'Mamuju',
        StateCode: '30',
        StateName: 'Célebes Occidental Sulawesi Barat',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '3101',
        CityName: 'Ambon',
        StateCode: '31',
        StateName: 'Molucas',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '3201',
        CityName: 'Ternate',
        StateCode: '32',
        StateName: 'Molucas Septentrional Maluku Utara',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '3301',
        CityName: 'Jayapura',
        StateCode: '33',
        StateName: 'Papúa',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '3401',
        CityName: 'Manokwari',
        StateCode: '34',
        StateName: 'Provincia de Papúa Occidental Papua Barat',
        CountryCode: 'Id',
        CountryName: 'Indonesia',
    },
    {
        CityCode: '0101',
        CityName: 'Ramadi',
        StateCode: '01',
        StateName: 'Ambar',
        CountryCode: 'Iq',
        CountryName: 'Irak',
    },
    {
        CityCode: '0201',
        CityName: 'Hilla',
        StateCode: '02',
        StateName: 'Babilonia',
        CountryCode: 'Iq',
        CountryName: 'Irak',
    },
    {
        CityCode: '0301',
        CityName: 'Bagdad',
        StateCode: '03',
        StateName: 'Bagdad',
        CountryCode: 'Iq',
        CountryName: 'Irak',
    },
    {
        CityCode: '0401',
        CityName: 'Basora',
        StateCode: '04',
        StateName: 'Basora',
        CountryCode: 'Iq',
        CountryName: 'Irak',
    },
    {
        CityCode: '0501',
        CityName: 'Nasiriya',
        StateCode: '05',
        StateName: 'Di Car',
        CountryCode: 'Iq',
        CountryName: 'Irak',
    },
    {
        CityCode: '0601',
        CityName: 'Diwaniya',
        StateCode: '06',
        StateName: 'Cadisia',
        CountryCode: 'Iq',
        CountryName: 'Irak',
    },
    {
        CityCode: '0701',
        CityName: 'Baquba',
        StateCode: '07',
        StateName: 'Diala',
        CountryCode: 'Iq',
        CountryName: 'Irak',
    },
    {
        CityCode: '0801',
        CityName: 'Duhok',
        StateCode: '08',
        StateName: 'Duhok',
        CountryCode: 'Iq',
        CountryName: 'Irak',
    },
    {
        CityCode: '0901',
        CityName: 'Erbil',
        StateCode: '09',
        StateName: 'Erbil',
        CountryCode: 'Iq',
        CountryName: 'Irak',
    },
    {
        CityCode: '1001',
        CityName: 'Kerbala',
        StateCode: '10',
        StateName: 'Kerbala',
        CountryCode: 'Iq',
        CountryName: 'Irak',
    },
    {
        CityCode: '1101',
        CityName: 'Kirkuk',
        StateCode: '11',
        StateName: 'Kirkuk',
        CountryCode: 'Iq',
        CountryName: 'Irak',
    },
    {
        CityCode: '1201',
        CityName: 'Amara',
        StateCode: '12',
        StateName: 'Mesena',
        CountryCode: 'Iq',
        CountryName: 'Irak',
    },
    {
        CityCode: '1301',
        CityName: 'Samawa',
        StateCode: '13',
        StateName: 'Mutana',
        CountryCode: 'Iq',
        CountryName: 'Irak',
    },
    {
        CityCode: '1401',
        CityName: 'Nayaf',
        StateCode: '14',
        StateName: 'Nayaf',
        CountryCode: 'Iq',
        CountryName: 'Irak',
    },
    {
        CityCode: '1501',
        CityName: 'Mosul',
        StateCode: '15',
        StateName: 'Nínive',
        CountryCode: 'Iq',
        CountryName: 'Irak',
    },
    {
        CityCode: '1601',
        CityName: 'Tikrit',
        StateCode: '16',
        StateName: 'Saladino',
        CountryCode: 'Iq',
        CountryName: 'Irak',
    },
    {
        CityCode: '1701',
        CityName: 'Solimania',
        StateCode: '17',
        StateName: 'Solimania',
        CountryCode: 'Iq',
        CountryName: 'Irak',
    },
    {
        CityCode: '1801',
        CityName: 'Kut',
        StateCode: '18',
        StateName: 'Wasit',
        CountryCode: 'Iq',
        CountryName: 'Irak',
    },
    {
        CityCode: '0101',
        CityName: 'Tabriz',
        StateCode: '01',
        StateName: 'Provincia de Azerbaiyán Oriental Az¯arbayjan-e Sha',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '0201',
        CityName: 'Urmia',
        StateCode: '02',
        StateName: 'Provincia de Azerbaiyán Occidental Az¯arbayjan-e G',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '0301',
        CityName: 'Ardebil',
        StateCode: '03',
        StateName: 'Provincia de Ardebil',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '0401',
        CityName: 'Isfahán',
        StateCode: '04',
        StateName: 'Provincia de Isfahán',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '0501',
        CityName: 'Ilam',
        StateCode: '05',
        StateName: 'Provincia de Ilam',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '0601',
        CityName: 'Bushehr',
        StateCode: '06',
        StateName: 'Provincia de Bushehr',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '0701',
        CityName: 'Teherán',
        StateCode: '07',
        StateName: 'Provincia de Teherán',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '0801',
        CityName: 'Shahrekord',
        StateCode: '08',
        StateName: 'Provincia de Chahar Mahal y Bajtiarí',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '0901',
        CityName: 'Ahvaz',
        StateCode: '09',
        StateName: 'Provincia de Juzestán',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '1001',
        CityName: 'Zanyán',
        StateCode: '10',
        StateName: 'Provincia de Zanyán',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '1101',
        CityName: 'Semnán',
        StateCode: '11',
        StateName: 'Provincia de Semnán',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '1201',
        CityName: 'Zahedán',
        StateCode: '12',
        StateName: 'Provincia de Sistán y Baluchistán',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '1301',
        CityName: 'Shiraz',
        StateCode: '13',
        StateName: 'Provincia de Fars',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '1401',
        CityName: 'Kermán',
        StateCode: '14',
        StateName: 'Provincia de Kermán',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '1501',
        CityName: 'Sanandaj',
        StateCode: '15',
        StateName: 'Provincia de Kurdistán',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '1601',
        CityName: 'Kermanshah',
        StateCode: '16',
        StateName: 'Provincia de Kermanshah',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '1701',
        CityName: 'Yasuch',
        StateCode: '17',
        StateName: 'Provincia de Kohkiluyeh y Buyer Ahmad',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '1801',
        CityName: 'Rasht',
        StateCode: '18',
        StateName: 'Provincia de Guilán',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '1901',
        CityName: 'Jorramabad',
        StateCode: '19',
        StateName: 'Provincia de Lorestán',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '2001',
        CityName: 'Sarí',
        StateCode: '20',
        StateName: 'Provincia de Mazandarán',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '2101',
        CityName: 'Arak',
        StateCode: '21',
        StateName: 'Provincia de Markazi',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '2201',
        CityName: 'Bandar Abbás',
        StateCode: '22',
        StateName: 'Provincia de Hormozgán',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '2301',
        CityName: 'Hamadán',
        StateCode: '23',
        StateName: 'Provincia de Hamadán',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '2401',
        CityName: 'Yazd',
        StateCode: '24',
        StateName: 'Provincia de Yazd',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '2501',
        CityName: 'Qom',
        StateCode: '25',
        StateName: 'Provincia de Qom',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '2601',
        CityName: 'Gorgan',
        StateCode: '26',
        StateName: 'Provincia de Golestán',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '2701',
        CityName: 'Qazvín',
        StateCode: '27',
        StateName: 'Provincia de Qazvín',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '2801',
        CityName: 'Biryand',
        StateCode: '28',
        StateName: 'Provincia de Jorasán del Sur  Khorasan-e Jonubi',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '2901',
        CityName: 'Mashhad',
        StateCode: '29',
        StateName: 'Provincia de Jorasán Razaví',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '3001',
        CityName: 'Bochnurd',
        StateCode: '30',
        StateName: 'Provincia de Jorasán del Norte Khorasan-e Shemali',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '3101',
        CityName: 'Karaj',
        StateCode: '31',
        StateName: 'Provincia de Elburz',
        CountryCode: 'Ir',
        CountryName: 'Irán',
    },
    {
        CityCode: '0101',
        CityName: 'Antrim',
        StateCode: '01',
        StateName: 'Antrim',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '0201',
        CityName: 'Armagh',
        StateCode: '02',
        StateName: 'Armagh',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '0301',
        CityName: 'Carlow',
        StateCode: '03',
        StateName: 'Carlow',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '0401',
        CityName: 'Cavan',
        StateCode: '04',
        StateName: 'Cavan',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '0501',
        CityName: 'Ennis',
        StateCode: '05',
        StateName: 'Clare',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '0601',
        CityName: 'Cork',
        StateCode: '06',
        StateName: 'Cork',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '0701',
        CityName: 'Lifford',
        StateCode: '07',
        StateName: 'Donegal',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '0801',
        CityName: 'Downpatrick',
        StateCode: '08',
        StateName: 'Down',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '0901',
        CityName: 'Dublín',
        StateCode: '09',
        StateName: 'Dublín',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '1001',
        CityName: 'Enniskillen',
        StateCode: '10',
        StateName: 'Fermanagh',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '1101',
        CityName: 'Galway',
        StateCode: '11',
        StateName: 'Galway',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '1201',
        CityName: 'Tralee',
        StateCode: '12',
        StateName: 'Kerry',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '1301',
        CityName: 'Naas',
        StateCode: '13',
        StateName: 'Kildare',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '1401',
        CityName: 'Kilkenny',
        StateCode: '14',
        StateName: 'Kilkenny',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '1501',
        CityName: 'Port Laoise',
        StateCode: '15',
        StateName: 'Laois',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '1601',
        CityName: 'Carrick-on-Shannon',
        StateCode: '16',
        StateName: 'Leitrim',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '1701',
        CityName: 'Limerick',
        StateCode: '17',
        StateName: 'Limerick',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '1801',
        CityName: 'Coleraine',
        StateCode: '18',
        StateName: 'Londonderry',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '1901',
        CityName: 'Longford',
        StateCode: '19',
        StateName: 'Longford',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '2001',
        CityName: 'Dundalk',
        StateCode: '20',
        StateName: 'Louth',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '2101',
        CityName: 'Castlebar',
        StateCode: '21',
        StateName: 'Mayo',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '2201',
        CityName: 'Navan',
        StateCode: '22',
        StateName: 'Meath',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '2301',
        CityName: 'Monaghan',
        StateCode: '23',
        StateName: 'Monaghan',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '2401',
        CityName: 'Tullamore',
        StateCode: '24',
        StateName: 'Offaly',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '2501',
        CityName: 'Roscommon',
        StateCode: '25',
        StateName: 'Roscommon',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '2601',
        CityName: 'Sligo',
        StateCode: '26',
        StateName: 'Sligo',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '2701',
        CityName: 'Clonmel y Nenagh',
        StateCode: '27',
        StateName: 'Tipperary',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '2801',
        CityName: 'Omagh',
        StateCode: '28',
        StateName: 'Tyrone',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '2901',
        CityName: 'Dungarvan',
        StateCode: '29',
        StateName: 'Waterford',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '3001',
        CityName: 'Mullingar',
        StateCode: '30',
        StateName: 'Westmeath',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '3101',
        CityName: 'Wexford',
        StateCode: '31',
        StateName: 'Wexford',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '3201',
        CityName: 'Wicklow',
        StateCode: '32',
        StateName: 'Wicklow',
        CountryCode: 'Ie',
        CountryName: 'Irlanda',
    },
    {
        CityCode: '0101',
        CityName: 'Árnessýsla',
        StateCode: '01',
        StateName: 'Árnessýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '0201',
        CityName: 'Austur-Barðastrandarsýsla',
        StateCode: '02',
        StateName: 'Austur-Barðastrandarsýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '0301',
        CityName: 'Austur-Húnavatnssýsla',
        StateCode: '03',
        StateName: 'Austur-Húnavatnssýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '0401',
        CityName: 'Austur-Skaftafellssýsla',
        StateCode: '04',
        StateName: 'Austur-Skaftafellssýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '0501',
        CityName: 'Borgarfjarðarsýsla',
        StateCode: '05',
        StateName: 'Borgarfjarðarsýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '0601',
        CityName: 'Dalasýsla',
        StateCode: '06',
        StateName: 'Dalasýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '0701',
        CityName: 'Eyjafjarðarsýsla',
        StateCode: '07',
        StateName: 'Eyjafjarðarsýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '0801',
        CityName: 'Gullbringusýsla',
        StateCode: '08',
        StateName: 'Gullbringusýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '0901',
        CityName: 'Kjósarsýsla',
        StateCode: '09',
        StateName: 'Kjósarsýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '1001',
        CityName: 'Mýrasýsla',
        StateCode: '10',
        StateName: 'Mýrasýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '1101',
        CityName: 'Norður-Ísafjarðarsýsla',
        StateCode: '11',
        StateName: 'Norður-Ísafjarðarsýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '1201',
        CityName: 'Norður-Múlasýsla',
        StateCode: '12',
        StateName: 'Norður-Múlasýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '1301',
        CityName: 'Norður-Þingeyjarsýsla',
        StateCode: '13',
        StateName: 'Norður-Þingeyjarsýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '1401',
        CityName: 'Rangárvallasýsla',
        StateCode: '14',
        StateName: 'Rangárvallasýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '1501',
        CityName: 'Skagafjarðarsýsla',
        StateCode: '15',
        StateName: 'Skagafjarðarsýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '1601',
        CityName: 'Snæfellsnes-og Hnappadalssýsla',
        StateCode: '16',
        StateName: 'Snæfellsnes-og Hnappadalssýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '1701',
        CityName: 'Strandasýsla',
        StateCode: '17',
        StateName: 'Strandasýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '1801',
        CityName: 'Suður-Múlasýsla',
        StateCode: '18',
        StateName: 'Suður-Múlasýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '1901',
        CityName: 'Suður-Þingeyjarsýsla',
        StateCode: '19',
        StateName: 'Suður-Þingeyjarsýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '2001',
        CityName: 'Vestur-Barðastrandarsýsla',
        StateCode: '20',
        StateName: 'Vestur-Barðastrandarsýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '2101',
        CityName: 'Vestur-Húnavatnssýsla',
        StateCode: '21',
        StateName: 'Vestur-Húnavatnssýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '2201',
        CityName: 'Vestur-Ísafjarðarsýsla',
        StateCode: '22',
        StateName: 'Vestur-Ísafjarðarsýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '2301',
        CityName: 'Vestur-Skaftafellssýsla',
        StateCode: '23',
        StateName: 'Vestur-Skaftafellssýsla',
        CountryCode: 'Is',
        CountryName: 'Islandia',
    },
    {
        CityCode: '0101',
        CityName: 'Ramla',
        StateCode: '01',
        StateName: 'Central',
        CountryCode: 'Il',
        CountryName: 'Israel',
    },
    {
        CityCode: '0201',
        CityName: 'Haifa',
        StateCode: '02',
        StateName: 'Haifa',
        CountryCode: 'Il',
        CountryName: 'Israel',
    },
    {
        CityCode: '0301',
        CityName: 'Jerusalén',
        StateCode: '03',
        StateName: 'Jerusalén',
        CountryCode: 'Il',
        CountryName: 'Israel',
    },
    {
        CityCode: '0401',
        CityName: 'Beerseba',
        StateCode: '04',
        StateName: 'Meridional',
        CountryCode: 'Il',
        CountryName: 'Israel',
    },
    {
        CityCode: '0501',
        CityName: 'Nazaret',
        StateCode: '05',
        StateName: 'Norte',
        CountryCode: 'Il',
        CountryName: 'Israel',
    },
    {
        CityCode: '0601',
        CityName: 'Tel Aviv-Yafo',
        StateCode: '06',
        StateName: 'Tel Aviv',
        CountryCode: 'Il',
        CountryName: 'Israel',
    },
    {
        CityCode: '0701',
        CityName: 'Ariel',
        StateCode: '07',
        StateName: 'Judea y Samaria',
        CountryCode: 'Il',
        CountryName: 'Israel',
    },
    {
        CityCode: '0101',
        CityName: 'Aosta',
        StateCode: '01',
        StateName: 'Valle de Aosta',
        CountryCode: 'It',
        CountryName: 'Italia',
    },
    {
        CityCode: '0201',
        CityName: 'Turín',
        StateCode: '02',
        StateName: 'Piamonte',
        CountryCode: 'It',
        CountryName: 'Italia',
    },
    {
        CityCode: '0301',
        CityName: 'Génova',
        StateCode: '03',
        StateName: 'Liguria',
        CountryCode: 'It',
        CountryName: 'Italia',
    },
    {
        CityCode: '0401',
        CityName: 'Milán',
        StateCode: '04',
        StateName: 'Lombardía',
        CountryCode: 'It',
        CountryName: 'Italia',
    },
    {
        CityCode: '0501',
        CityName: 'Trento',
        StateCode: '05',
        StateName: 'Trentino-Alto Adigio',
        CountryCode: 'It',
        CountryName: 'Italia',
    },
    {
        CityCode: '0601',
        CityName: 'Venecia',
        StateCode: '06',
        StateName: 'Véneto',
        CountryCode: 'It',
        CountryName: 'Italia',
    },
    {
        CityCode: '0701',
        CityName: 'Trieste',
        StateCode: '07',
        StateName: 'Friuli-Venecia Julia',
        CountryCode: 'It',
        CountryName: 'Italia',
    },
    {
        CityCode: '0801',
        CityName: 'Bolonia',
        StateCode: '08',
        StateName: 'Emilia-Romaña',
        CountryCode: 'It',
        CountryName: 'Italia',
    },
    {
        CityCode: '0901',
        CityName: 'Florencia',
        StateCode: '09',
        StateName: 'Toscana',
        CountryCode: 'It',
        CountryName: 'Italia',
    },
    {
        CityCode: '1001',
        CityName: 'Perugia',
        StateCode: '10',
        StateName: 'Umbría',
        CountryCode: 'It',
        CountryName: 'Italia',
    },
    {
        CityCode: '1101',
        CityName: 'Ancona',
        StateCode: '11',
        StateName: 'Marcas',
        CountryCode: 'It',
        CountryName: 'Italia',
    },
    {
        CityCode: '1201',
        CityName: 'Roma',
        StateCode: '12',
        StateName: 'Lacio',
        CountryCode: 'It',
        CountryName: 'Italia',
    },
    {
        CityCode: '1301',
        CityName: "L'Aquila",
        StateCode: '13',
        StateName: 'Abruzos',
        CountryCode: 'It',
        CountryName: 'Italia',
    },
    {
        CityCode: '1401',
        CityName: 'Campobasso',
        StateCode: '14',
        StateName: 'Molise',
        CountryCode: 'It',
        CountryName: 'Italia',
    },
    {
        CityCode: '1501',
        CityName: 'Nápoles',
        StateCode: '15',
        StateName: 'Campania',
        CountryCode: 'It',
        CountryName: 'Italia',
    },
    {
        CityCode: '1601',
        CityName: 'Bari',
        StateCode: '16',
        StateName: 'Apulia',
        CountryCode: 'It',
        CountryName: 'Italia',
    },
    {
        CityCode: '1701',
        CityName: 'Potenza',
        StateCode: '17',
        StateName: 'Basilicata',
        CountryCode: 'It',
        CountryName: 'Italia',
    },
    {
        CityCode: '1801',
        CityName: 'Catanzaro',
        StateCode: '18',
        StateName: 'Calabria',
        CountryCode: 'It',
        CountryName: 'Italia',
    },
    {
        CityCode: '1901',
        CityName: 'Palermo',
        StateCode: '19',
        StateName: 'Sicilia',
        CountryCode: 'It',
        CountryName: 'Italia',
    },
    {
        CityCode: '2001',
        CityName: 'Cagliari',
        StateCode: '20',
        StateName: 'Cerdeña',
        CountryCode: 'It',
        CountryName: 'Italia',
    },
    {
        CityCode: '0101',
        CityName: 'Lucea',
        StateCode: '01',
        StateName: 'Parroquia de Hanover',
        CountryCode: 'Jm',
        CountryName: 'Jamaica',
    },
    {
        CityCode: '0201',
        CityName: 'Black River',
        StateCode: '02',
        StateName: 'Parroquia de Saint Elizabeth',
        CountryCode: 'Jm',
        CountryName: 'Jamaica',
    },
    {
        CityCode: '0301',
        CityName: 'Montego Bay',
        StateCode: '03',
        StateName: 'Parroquia de Saint James',
        CountryCode: 'Jm',
        CountryName: 'Jamaica',
    },
    {
        CityCode: '0401',
        CityName: 'Falmouth',
        StateCode: '04',
        StateName: 'Parroquia de Trelawny',
        CountryCode: 'Jm',
        CountryName: 'Jamaica',
    },
    {
        CityCode: '0501',
        CityName: 'Savanna-la-Mar',
        StateCode: '05',
        StateName: 'Parroquia de Westmoreland',
        CountryCode: 'Jm',
        CountryName: 'Jamaica',
    },
    {
        CityCode: '0601',
        CityName: 'May Pen',
        StateCode: '06',
        StateName: 'Parroquia de Clarendon',
        CountryCode: 'Jm',
        CountryName: 'Jamaica',
    },
    {
        CityCode: '0701',
        CityName: 'Mandeville',
        StateCode: '07',
        StateName: 'Parroquia de Manchester',
        CountryCode: 'Jm',
        CountryName: 'Jamaica',
    },
    {
        CityCode: '0801',
        CityName: "Saint Ann's Bay",
        StateCode: '08',
        StateName: 'Parroquia de Saint Ann',
        CountryCode: 'Jm',
        CountryName: 'Jamaica',
    },
    {
        CityCode: '0901',
        CityName: 'Spanish Town',
        StateCode: '09',
        StateName: 'Parroquia de Saint Catherine',
        CountryCode: 'Jm',
        CountryName: 'Jamaica',
    },
    {
        CityCode: '1001',
        CityName: 'Port Maria',
        StateCode: '10',
        StateName: 'Parroquia de Saint Mary',
        CountryCode: 'Jm',
        CountryName: 'Jamaica',
    },
    {
        CityCode: '1101',
        CityName: 'Kingston',
        StateCode: '11',
        StateName: 'Parroquia de Kingston',
        CountryCode: 'Jm',
        CountryName: 'Jamaica',
    },
    {
        CityCode: '1201',
        CityName: 'Port Antonio',
        StateCode: '12',
        StateName: 'Parroquia de Portland',
        CountryCode: 'Jm',
        CountryName: 'Jamaica',
    },
    {
        CityCode: '1301',
        CityName: 'Half Way Tree',
        StateCode: '13',
        StateName: 'Parroquia de Saint Andrew',
        CountryCode: 'Jm',
        CountryName: 'Jamaica',
    },
    {
        CityCode: '1401',
        CityName: 'Morant Bay',
        StateCode: '14',
        StateName: 'Parroquia de Saint Thomas',
        CountryCode: 'Jm',
        CountryName: 'Jamaica',
    },
    {
        CityCode: '0101',
        CityName: 'Sapporo',
        StateCode: '01',
        StateName: 'Prefectura de Hokkaido',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '0201',
        CityName: 'Aomori',
        StateCode: '02',
        StateName: 'Prefectura de Aomori',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '0301',
        CityName: 'Morioka',
        StateCode: '03',
        StateName: 'Prefectura de Iwate',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '0401',
        CityName: 'Sendai',
        StateCode: '04',
        StateName: 'Prefectura de Miyagi',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '0501',
        CityName: 'Akita',
        StateCode: '05',
        StateName: 'Prefectura de Akita',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '0601',
        CityName: 'Yamagata',
        StateCode: '06',
        StateName: 'Prefectura de Yamagata',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '0701',
        CityName: 'Fukushima',
        StateCode: '07',
        StateName: 'Prefectura de Fukushima',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '0801',
        CityName: 'Mito',
        StateCode: '08',
        StateName: 'Prefectura de Ibaraki',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '0901',
        CityName: 'Utsunomiya',
        StateCode: '09',
        StateName: 'Prefectura de Tochigi',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '1001',
        CityName: 'Maebashi',
        StateCode: '10',
        StateName: 'Prefectura de Gunma',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '1101',
        CityName: 'Saitama',
        StateCode: '11',
        StateName: 'Prefectura de Saitama',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '1201',
        CityName: 'Chiba',
        StateCode: '12',
        StateName: 'Prefectura de Chiba',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '1301',
        CityName: 'Shinjuku',
        StateCode: '13',
        StateName: 'Tokio',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '1401',
        CityName: 'Yokohama',
        StateCode: '14',
        StateName: 'Prefectura de Kanagawa',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '1501',
        CityName: 'Niigata',
        StateCode: '15',
        StateName: 'Prefectura de Niigata',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '1601',
        CityName: 'Toyama',
        StateCode: '16',
        StateName: 'Prefectura de Toyama',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '1701',
        CityName: 'Kanazawa',
        StateCode: '17',
        StateName: 'Prefectura de Ishikawa',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '1801',
        CityName: 'Fukui',
        StateCode: '18',
        StateName: 'Prefectura de Fukui',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '1901',
        CityName: 'Kofu',
        StateCode: '19',
        StateName: 'Prefectura de Yamanashi',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '2001',
        CityName: 'Nagano',
        StateCode: '20',
        StateName: 'Prefectura de Nagano',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '2101',
        CityName: 'Gifu',
        StateCode: '21',
        StateName: 'Prefectura de Gifu',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '2201',
        CityName: 'Shizuoka',
        StateCode: '22',
        StateName: 'Prefectura de Shizuoka',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '2301',
        CityName: 'Nagoya',
        StateCode: '23',
        StateName: 'Prefectura de Aichi',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '2401',
        CityName: 'Tsu',
        StateCode: '24',
        StateName: 'Prefectura de Mie',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '2501',
        CityName: 'Otsu',
        StateCode: '25',
        StateName: 'Prefectura de Shiga',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '2601',
        CityName: 'Kioto',
        StateCode: '26',
        StateName: 'Prefectura de Kioto',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '2701',
        CityName: 'Osaka',
        StateCode: '27',
        StateName: 'Prefectura de Osaka',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '2801',
        CityName: 'Kobe',
        StateCode: '28',
        StateName: 'Prefectura de Hyogo',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '2901',
        CityName: 'Nara',
        StateCode: '29',
        StateName: 'Prefectura de Nara',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '3001',
        CityName: 'Wakayama',
        StateCode: '30',
        StateName: 'Prefectura de Wakayama',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '3101',
        CityName: 'Tottori',
        StateCode: '31',
        StateName: 'Prefectura de Tottori',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '3201',
        CityName: 'Matsue',
        StateCode: '32',
        StateName: 'Prefectura de Shimane',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '3301',
        CityName: 'Okayama',
        StateCode: '33',
        StateName: 'Prefectura de Okayama',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '3401',
        CityName: 'Hiroshima',
        StateCode: '34',
        StateName: 'Prefectura de Hiroshima',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '3501',
        CityName: 'Yamaguchi',
        StateCode: '35',
        StateName: 'Prefectura de Yamaguchi',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '3601',
        CityName: 'Tokushima',
        StateCode: '36',
        StateName: 'Prefectura de Tokushima',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '3701',
        CityName: 'Takamatsu',
        StateCode: '37',
        StateName: 'Prefectura de Kagawa',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '3801',
        CityName: 'Matsuyama',
        StateCode: '38',
        StateName: 'Prefectura de Ehime',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '3901',
        CityName: 'Kochi',
        StateCode: '39',
        StateName: 'Prefectura de Kochi',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '4001',
        CityName: 'Fukuoka',
        StateCode: '40',
        StateName: 'Prefectura de Fukuoka',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '4101',
        CityName: 'Saga',
        StateCode: '41',
        StateName: 'Prefectura de Saga',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '4201',
        CityName: 'Nagasaki',
        StateCode: '42',
        StateName: 'Prefectura de Nagasaki',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '4301',
        CityName: 'Kumamoto',
        StateCode: '43',
        StateName: 'Prefectura de Kumamoto',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '4401',
        CityName: 'Oita',
        StateCode: '44',
        StateName: 'Prefectura de Oita',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '4501',
        CityName: 'Miyazaki',
        StateCode: '45',
        StateName: 'Prefectura de Miyazaki',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '4601',
        CityName: 'Kagoshima',
        StateCode: '46',
        StateName: 'Prefectura de Kagoshima',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '4701',
        CityName: 'Naha',
        StateCode: '47',
        StateName: 'Prefectura de Okinawa',
        CountryCode: 'Jp',
        CountryName: 'Japón',
    },
    {
        CityCode: '0101',
        CityName: 'Grouville',
        StateCode: '01',
        StateName: 'Grouville',
        CountryCode: 'Je',
        CountryName: 'Jersey',
    },
    {
        CityCode: '0201',
        CityName: 'Saint Brélade',
        StateCode: '02',
        StateName: 'Saint Brélade',
        CountryCode: 'Je',
        CountryName: 'Jersey',
    },
    {
        CityCode: '0301',
        CityName: 'Saint Clement',
        StateCode: '03',
        StateName: 'Saint Clement',
        CountryCode: 'Je',
        CountryName: 'Jersey',
    },
    {
        CityCode: '0401',
        CityName: 'Saint Helier',
        StateCode: '04',
        StateName: 'Saint Helier',
        CountryCode: 'Je',
        CountryName: 'Jersey',
    },
    {
        CityCode: '0501',
        CityName: 'Saint John',
        StateCode: '05',
        StateName: 'Saint John',
        CountryCode: 'Je',
        CountryName: 'Jersey',
    },
    {
        CityCode: '0601',
        CityName: 'Saint Lawrence',
        StateCode: '06',
        StateName: 'Saint Lawrence',
        CountryCode: 'Je',
        CountryName: 'Jersey',
    },
    {
        CityCode: '0701',
        CityName: 'Saint Martin',
        StateCode: '07',
        StateName: 'Saint Martin',
        CountryCode: 'Je',
        CountryName: 'Jersey',
    },
    {
        CityCode: '0801',
        CityName: 'Saint Mary',
        StateCode: '08',
        StateName: 'Saint Mary',
        CountryCode: 'Je',
        CountryName: 'Jersey',
    },
    {
        CityCode: '0901',
        CityName: 'Saint Ouen',
        StateCode: '09',
        StateName: 'Saint Ouen',
        CountryCode: 'Je',
        CountryName: 'Jersey',
    },
    {
        CityCode: '1001',
        CityName: 'Saint Peter',
        StateCode: '10',
        StateName: 'Saint Peter',
        CountryCode: 'Je',
        CountryName: 'Jersey',
    },
    {
        CityCode: '1101',
        CityName: 'Saint Saviour',
        StateCode: '11',
        StateName: 'Saint Saviour',
        CountryCode: 'Je',
        CountryName: 'Jersey',
    },
    {
        CityCode: '1201',
        CityName: 'Trinity',
        StateCode: '12',
        StateName: 'Trinity',
        CountryCode: 'Je',
        CountryName: 'Jersey',
    },
    {
        CityCode: '0101',
        CityName: 'Ammán',
        StateCode: '01',
        StateName: 'Gobernación de Ammán',
        CountryCode: 'Jo',
        CountryName: 'Jordania',
    },
    {
        CityCode: '0201',
        CityName: 'Irbid',
        StateCode: '02',
        StateName: 'Gobernación de Irbid',
        CountryCode: 'Jo',
        CountryName: 'Jordania',
    },
    {
        CityCode: '0301',
        CityName: 'Zarqa',
        StateCode: '03',
        StateName: 'Gobernación de Zarqa',
        CountryCode: 'Jo',
        CountryName: 'Jordania',
    },
    {
        CityCode: '0401',
        CityName: 'Salt',
        StateCode: '04',
        StateName: "Gobernación de Al Balqa'",
        CountryCode: 'Jo',
        CountryName: 'Jordania',
    },
    {
        CityCode: '0501',
        CityName: 'Mafraq',
        StateCode: '05',
        StateName: 'Gobernación de Al Mafraq',
        CountryCode: 'Jo',
        CountryName: 'Jordania',
    },
    {
        CityCode: '0601',
        CityName: 'Karak',
        StateCode: '06',
        StateName: 'Gobernación de Al Karak',
        CountryCode: 'Jo',
        CountryName: 'Jordania',
    },
    {
        CityCode: '0701',
        CityName: 'Jarash',
        StateCode: '07',
        StateName: 'Gobernación de Jarash',
        CountryCode: 'Jo',
        CountryName: 'Jordania',
    },
    {
        CityCode: '0801',
        CityName: 'Madaba',
        StateCode: '08',
        StateName: 'Gobernación de Madaba',
        CountryCode: 'Jo',
        CountryName: 'Jordania',
    },
    {
        CityCode: '0901',
        CityName: 'Ajlun',
        StateCode: '09',
        StateName: 'Gobernación de Ajlun',
        CountryCode: 'Jo',
        CountryName: 'Jordania',
    },
    {
        CityCode: '1001',
        CityName: 'Aqaba',
        StateCode: '10',
        StateName: 'Gobernación de Aqaba',
        CountryCode: 'Jo',
        CountryName: 'Jordania',
    },
    {
        CityCode: '1101',
        CityName: "Ma'an",
        StateCode: '11',
        StateName: "Gobernación de Ma'an",
        CountryCode: 'Jo',
        CountryName: 'Jordania',
    },
    {
        CityCode: '1201',
        CityName: 'At Tafilah',
        StateCode: '12',
        StateName: 'Gobernación de At Tafilah',
        CountryCode: 'Jo',
        CountryName: 'Jordania',
    },
    {
        CityCode: '0101',
        CityName: 'Kokshetau',
        StateCode: '01',
        StateName: 'Akmola',
        CountryCode: 'Kz',
        CountryName: 'Kazajistán',
    },
    {
        CityCode: '0201',
        CityName: 'Aktobé',
        StateCode: '02',
        StateName: 'Aktobé',
        CountryCode: 'Kz',
        CountryName: 'Kazajistán',
    },
    {
        CityCode: '0301',
        CityName: 'Almatý',
        StateCode: '03',
        StateName: 'Almatý',
        CountryCode: 'Kz',
        CountryName: 'Kazajistán',
    },
    {
        CityCode: '0302',
        CityName: 'Taldykorgan',
        StateCode: '03',
        StateName: 'Almatý',
        CountryCode: 'Kz',
        CountryName: 'Kazajistán',
    },
    {
        CityCode: '0401',
        CityName: 'Astana',
        StateCode: '04',
        StateName: 'Astana',
        CountryCode: 'Kz',
        CountryName: 'Kazajistán',
    },
    {
        CityCode: '0501',
        CityName: 'Atirau',
        StateCode: '05',
        StateName: 'Atyrau',
        CountryCode: 'Kz',
        CountryName: 'Kazajistán',
    },
    {
        CityCode: '0601',
        CityName: 'Baikonur',
        StateCode: '06',
        StateName: 'Baikonur',
        CountryCode: 'Kz',
        CountryName: 'Kazajistán',
    },
    {
        CityCode: '0701',
        CityName: 'Karagandá',
        StateCode: '07',
        StateName: 'Karagandá',
        CountryCode: 'Kz',
        CountryName: 'Kazajistán',
    },
    {
        CityCode: '0801',
        CityName: 'Shymkent',
        StateCode: '08',
        StateName: 'Kazajistán Meridional',
        CountryCode: 'Kz',
        CountryName: 'Kazajistán',
    },
    {
        CityCode: '0901',
        CityName: 'Oral',
        StateCode: '09',
        StateName: 'Kazajistán Occidental',
        CountryCode: 'Kz',
        CountryName: 'Kazajistán',
    },
    {
        CityCode: '1001',
        CityName: 'Oskemen',
        StateCode: '10',
        StateName: 'Kazajistán Oriental',
        CountryCode: 'Kz',
        CountryName: 'Kazajistán',
    },
    {
        CityCode: '1101',
        CityName: 'Petropavl',
        StateCode: '11',
        StateName: 'Kazajistán Septentrional',
        CountryCode: 'Kz',
        CountryName: 'Kazajistán',
    },
    {
        CityCode: '1201',
        CityName: 'Kostanay',
        StateCode: '12',
        StateName: 'Kostanay',
        CountryCode: 'Kz',
        CountryName: 'Kazajistán',
    },
    {
        CityCode: '1301',
        CityName: 'Kyzylorda',
        StateCode: '13',
        StateName: 'Kyzylorda',
        CountryCode: 'Kz',
        CountryName: 'Kazajistán',
    },
    {
        CityCode: '1401',
        CityName: 'Aktau',
        StateCode: '14',
        StateName: 'Mangystau',
        CountryCode: 'Kz',
        CountryName: 'Kazajistán',
    },
    {
        CityCode: '1501',
        CityName: 'Pavlodar',
        StateCode: '15',
        StateName: 'Pavlodar',
        CountryCode: 'Kz',
        CountryName: 'Kazajistán',
    },
    {
        CityCode: '1601',
        CityName: 'Taraz',
        StateCode: '16',
        StateName: 'Zhambyl',
        CountryCode: 'Kz',
        CountryName: 'Kazajistán',
    },
    {
        CityCode: '0101',
        CityName: 'Nyeri',
        StateCode: '01',
        StateName: 'Provincia Central',
        CountryCode: 'Ke',
        CountryName: 'Kenia',
    },
    {
        CityCode: '0201',
        CityName: 'Mombasa',
        StateCode: '02',
        StateName: 'Provincia Costera',
        CountryCode: 'Ke',
        CountryName: 'Kenia',
    },
    {
        CityCode: '0301',
        CityName: 'Embu',
        StateCode: '03',
        StateName: 'Provincia Oriental',
        CountryCode: 'Ke',
        CountryName: 'Kenia',
    },
    {
        CityCode: '0401',
        CityName: 'Nairobi',
        StateCode: '04',
        StateName: 'Provincia de Nairobi',
        CountryCode: 'Ke',
        CountryName: 'Kenia',
    },
    {
        CityCode: '0501',
        CityName: 'Garissa',
        StateCode: '05',
        StateName: 'Provincia Nororiental',
        CountryCode: 'Ke',
        CountryName: 'Kenia',
    },
    {
        CityCode: '0601',
        CityName: 'Kisumu',
        StateCode: '06',
        StateName: 'Provincia de Nyanza',
        CountryCode: 'Ke',
        CountryName: 'Kenia',
    },
    {
        CityCode: '0701',
        CityName: 'Nakuru',
        StateCode: '07',
        StateName: 'Provincia del Valle del Rift',
        CountryCode: 'Ke',
        CountryName: 'Kenia',
    },
    {
        CityCode: '0801',
        CityName: 'Kakamega',
        StateCode: '08',
        StateName: 'Provincia Occidental',
        CountryCode: 'Ke',
        CountryName: 'Kenia',
    },
    {
        CityCode: '0101',
        CityName: 'Biskek',
        StateCode: '01',
        StateName: 'Biskek - Chaar',
        CountryCode: 'Kg',
        CountryName: 'Kirguistán',
    },
    {
        CityCode: '0201',
        CityName: 'Batken',
        StateCode: '02',
        StateName: 'Batken',
        CountryCode: 'Kg',
        CountryName: 'Kirguistán',
    },
    {
        CityCode: '0301',
        CityName: 'Biskek',
        StateCode: '03',
        StateName: 'Chuy',
        CountryCode: 'Kg',
        CountryName: 'Kirguistán',
    },
    {
        CityCode: '0401',
        CityName: 'Jalal-Abad',
        StateCode: '04',
        StateName: 'Jalal-Abad',
        CountryCode: 'Kg',
        CountryName: 'Kirguistán',
    },
    {
        CityCode: '0501',
        CityName: 'Naryn',
        StateCode: '05',
        StateName: 'Naryn',
        CountryCode: 'Kg',
        CountryName: 'Kirguistán',
    },
    {
        CityCode: '0601',
        CityName: 'Osh',
        StateCode: '06',
        StateName: 'Osh',
        CountryCode: 'Kg',
        CountryName: 'Kirguistán',
    },
    {
        CityCode: '0701',
        CityName: 'Talas',
        StateCode: '07',
        StateName: 'Talas',
        CountryCode: 'Kg',
        CountryName: 'Kirguistán',
    },
    {
        CityCode: '0801',
        CityName: 'Karakol',
        StateCode: '08',
        StateName: 'Ysyk-Kol',
        CountryCode: 'Kg',
        CountryName: 'Kirguistán',
    },
    {
        CityCode: '0901',
        CityName: 'Biskek',
        StateCode: '09',
        StateName: 'Kirguistán',
        CountryCode: 'Kg',
        CountryName: 'Kirguistán',
    },
    {
        CityCode: '0101',
        CityName: 'Al Ahmadí',
        StateCode: '01',
        StateName: 'Ahmadí',
        CountryCode: 'Kw',
        CountryName: 'Kuwait',
    },
    {
        CityCode: '0201',
        CityName: 'Al Asimah',
        StateCode: '02',
        StateName: 'Al Asimah',
        CountryCode: 'Kw',
        CountryName: 'Kuwait',
    },
    {
        CityCode: '0301',
        CityName: 'Al Farwaniya',
        StateCode: '03',
        StateName: 'Farwaniya',
        CountryCode: 'Kw',
        CountryName: 'Kuwait',
    },
    {
        CityCode: '0401',
        CityName: 'Al Yahra',
        StateCode: '04',
        StateName: 'Yahra',
        CountryCode: 'Kw',
        CountryName: 'Kuwait',
    },
    {
        CityCode: '0501',
        CityName: 'Al Hawalli',
        StateCode: '05',
        StateName: 'Hawalli',
        CountryCode: 'Kw',
        CountryName: 'Kuwait',
    },
    {
        CityCode: '0601',
        CityName: 'Mubarak Al-Kabeer',
        StateCode: '06',
        StateName: 'Mubarak Al-Kabeer',
        CountryCode: 'Kw',
        CountryName: 'Kuwait',
    },
    {
        CityCode: '0101',
        CityName: 'Attapeu',
        StateCode: '01',
        StateName: 'Provincia de Attapeu',
        CountryCode: 'La',
        CountryName: 'Laos',
    },
    {
        CityCode: '0201',
        CityName: 'Bokeo',
        StateCode: '02',
        StateName: 'Provincia de Bokeo',
        CountryCode: 'La',
        CountryName: 'Laos',
    },
    {
        CityCode: '0301',
        CityName: 'Bolikhamxai',
        StateCode: '03',
        StateName: 'Provincia de Bolikhamxai',
        CountryCode: 'La',
        CountryName: 'Laos',
    },
    {
        CityCode: '0401',
        CityName: 'Champasak',
        StateCode: '04',
        StateName: 'Provincia de Champasak',
        CountryCode: 'La',
        CountryName: 'Laos',
    },
    {
        CityCode: '0501',
        CityName: 'Houaphan',
        StateCode: '05',
        StateName: 'Provincia de Houaphan',
        CountryCode: 'La',
        CountryName: 'Laos',
    },
    {
        CityCode: '0601',
        CityName: 'Khammouan',
        StateCode: '06',
        StateName: 'Provincia de Khammouan',
        CountryCode: 'La',
        CountryName: 'Laos',
    },
    {
        CityCode: '0701',
        CityName: 'Luang Namtha',
        StateCode: '07',
        StateName: 'Provincia de Luang Namtha',
        CountryCode: 'La',
        CountryName: 'Laos',
    },
    {
        CityCode: '0801',
        CityName: 'Luang Prabang',
        StateCode: '08',
        StateName: 'Provincia de Luang Prabang',
        CountryCode: 'La',
        CountryName: 'Laos',
    },
    {
        CityCode: '0901',
        CityName: 'Oudomxai',
        StateCode: '09',
        StateName: 'Provincia de Oudomxai',
        CountryCode: 'La',
        CountryName: 'Laos',
    },
    {
        CityCode: '1001',
        CityName: 'Phongsali',
        StateCode: '10',
        StateName: 'Provincia de Phongsali',
        CountryCode: 'La',
        CountryName: 'Laos',
    },
    {
        CityCode: '1101',
        CityName: 'Sainyabuli',
        StateCode: '11',
        StateName: 'Provincia de Sainyabuli',
        CountryCode: 'La',
        CountryName: 'Laos',
    },
    {
        CityCode: '1201',
        CityName: 'Salavan',
        StateCode: '12',
        StateName: 'Provincia de Salavan',
        CountryCode: 'La',
        CountryName: 'Laos',
    },
    {
        CityCode: '1301',
        CityName: 'Savannakhet',
        StateCode: '13',
        StateName: 'Provincia de Savannakhet',
        CountryCode: 'La',
        CountryName: 'Laos',
    },
    {
        CityCode: '1401',
        CityName: 'Sekong',
        StateCode: '14',
        StateName: 'Provincia de Sekong',
        CountryCode: 'La',
        CountryName: 'Laos',
    },
    {
        CityCode: '1501',
        CityName: 'Prefectura de Vientián',
        StateCode: '15',
        StateName: 'Prefectura de Vientián',
        CountryCode: 'La',
        CountryName: 'Laos',
    },
    {
        CityCode: '1601',
        CityName: 'Vientián',
        StateCode: '16',
        StateName: 'Provincia de Vientián',
        CountryCode: 'La',
        CountryName: 'Laos',
    },
    {
        CityCode: '1701',
        CityName: 'Xiangkhoang',
        StateCode: '17',
        StateName: 'Provincia de Xiangkhoang',
        CountryCode: 'La',
        CountryName: 'Laos',
    },
    {
        CityCode: '0101',
        CityName: 'Teyateyaneng',
        StateCode: '01',
        StateName: 'Berea',
        CountryCode: 'Ls',
        CountryName: 'Lesoto',
    },
    {
        CityCode: '0201',
        CityName: 'Butha-Bethe',
        StateCode: '02',
        StateName: 'Butha-Buthe',
        CountryCode: 'Ls',
        CountryName: 'Lesoto',
    },
    {
        CityCode: '0301',
        CityName: 'Hlotse',
        StateCode: '03',
        StateName: 'Leribe',
        CountryCode: 'Ls',
        CountryName: 'Lesoto',
    },
    {
        CityCode: '0401',
        CityName: 'Mafeteng',
        StateCode: '04',
        StateName: 'Mafeteng',
        CountryCode: 'Ls',
        CountryName: 'Lesoto',
    },
    {
        CityCode: '0501',
        CityName: 'Maseru',
        StateCode: '05',
        StateName: 'Maseru',
        CountryCode: 'Ls',
        CountryName: 'Lesoto',
    },
    {
        CityCode: '0601',
        CityName: "Mohale's Hoek",
        StateCode: '06',
        StateName: "Mohale's Hoek",
        CountryCode: 'Ls',
        CountryName: 'Lesoto',
    },
    {
        CityCode: '0701',
        CityName: 'Mokhotlong',
        StateCode: '07',
        StateName: 'Mokhotlong',
        CountryCode: 'Ls',
        CountryName: 'Lesoto',
    },
    {
        CityCode: '0801',
        CityName: "Qacha's Nek",
        StateCode: '08',
        StateName: "Qacha's Nek",
        CountryCode: 'Ls',
        CountryName: 'Lesoto',
    },
    {
        CityCode: '0901',
        CityName: 'Quthing/Moyeni',
        StateCode: '09',
        StateName: 'Quthing',
        CountryCode: 'Ls',
        CountryName: 'Lesoto',
    },
    {
        CityCode: '1001',
        CityName: 'Thaba-Tseka',
        StateCode: '10',
        StateName: 'Thaba-Tseka',
        CountryCode: 'Ls',
        CountryName: 'Lesoto',
    },
    {
        CityCode: '0101',
        CityName: 'Tukums',
        StateCode: '01',
        StateName: 'Riga',
        CountryCode: 'Lv',
        CountryName: 'Letonia',
    },
    {
        CityCode: '0201',
        CityName: 'Salaspils',
        StateCode: '02',
        StateName: 'Daugavpils',
        CountryCode: 'Lv',
        CountryName: 'Letonia',
    },
    {
        CityCode: '0301',
        CityName: 'Cesis',
        StateCode: '03',
        StateName: 'Liepaja',
        CountryCode: 'Lv',
        CountryName: 'Letonia',
    },
    {
        CityCode: '0401',
        CityName: 'Kuldiga',
        StateCode: '04',
        StateName: 'Jelgava',
        CountryCode: 'Lv',
        CountryName: 'Letonia',
    },
    {
        CityCode: '0501',
        CityName: 'Olaine',
        StateCode: '05',
        StateName: 'Jurmala',
        CountryCode: 'Lv',
        CountryName: 'Letonia',
    },
    {
        CityCode: '0601',
        CityName: 'Saldus',
        StateCode: '06',
        StateName: 'Ventspils',
        CountryCode: 'Lv',
        CountryName: 'Letonia',
    },
    {
        CityCode: '0701',
        CityName: 'Sigulda',
        StateCode: '07',
        StateName: 'Rezekne',
        CountryCode: 'Lv',
        CountryName: 'Letonia',
    },
    {
        CityCode: '0801',
        CityName: 'Talsi',
        StateCode: '08',
        StateName: 'Ogre',
        CountryCode: 'Lv',
        CountryName: 'Letonia',
    },
    {
        CityCode: '0901',
        CityName: 'Dobele',
        StateCode: '09',
        StateName: 'Valmiera',
        CountryCode: 'Lv',
        CountryName: 'Letonia',
    },
    {
        CityCode: '1001',
        CityName: 'Bauska',
        StateCode: '10',
        StateName: 'Jekabpils',
        CountryCode: 'Lv',
        CountryName: 'Letonia',
    },
    {
        CityCode: '0101',
        CityName: 'Halba',
        StateCode: '01',
        StateName: 'Akkar',
        CountryCode: 'Lb',
        CountryName: 'Líbano',
    },
    {
        CityCode: '0201',
        CityName: 'Baalbek',
        StateCode: '02',
        StateName: 'Baalbek-Hermel',
        CountryCode: 'Lb',
        CountryName: 'Líbano',
    },
    {
        CityCode: '0301',
        CityName: 'Beirut',
        StateCode: '03',
        StateName: 'Beirut',
        CountryCode: 'Lb',
        CountryName: 'Líbano',
    },
    {
        CityCode: '0401',
        CityName: 'Baabda',
        StateCode: '04',
        StateName: 'Monte Líbano',
        CountryCode: 'Lb',
        CountryName: 'Líbano',
    },
    {
        CityCode: '0501',
        CityName: 'Trípoli',
        StateCode: '05',
        StateName: 'Norte',
        CountryCode: 'Lb',
        CountryName: 'Líbano',
    },
    {
        CityCode: '0601',
        CityName: 'Zahlé',
        StateCode: '06',
        StateName: 'Bekaa',
        CountryCode: 'Lb',
        CountryName: 'Líbano',
    },
    {
        CityCode: '0701',
        CityName: 'Nabatiye',
        StateCode: '07',
        StateName: 'Nabatiye',
        CountryCode: 'Lb',
        CountryName: 'Líbano',
    },
    {
        CityCode: '0801',
        CityName: 'Sidón',
        StateCode: '08',
        StateName: 'Sur',
        CountryCode: 'Lb',
        CountryName: 'Líbano',
    },
    {
        CityCode: '0101',
        CityName: 'Tubmanburg',
        StateCode: '01',
        StateName: 'Bomi',
        CountryCode: 'Lr',
        CountryName: 'Liberia',
    },
    {
        CityCode: '0201',
        CityName: 'Gbarnga',
        StateCode: '02',
        StateName: 'Bong',
        CountryCode: 'Lr',
        CountryName: 'Liberia',
    },
    {
        CityCode: '0301',
        CityName: 'Bopulu',
        StateCode: '03',
        StateName: 'Gbarpolu',
        CountryCode: 'Lr',
        CountryName: 'Liberia',
    },
    {
        CityCode: '0401',
        CityName: 'Buchanan',
        StateCode: '04',
        StateName: 'Grand Bassa',
        CountryCode: 'Lr',
        CountryName: 'Liberia',
    },
    {
        CityCode: '0501',
        CityName: 'Robertsport',
        StateCode: '05',
        StateName: 'Grand Cape Mount',
        CountryCode: 'Lr',
        CountryName: 'Liberia',
    },
    {
        CityCode: '0601',
        CityName: 'Zwedru',
        StateCode: '06',
        StateName: 'Grand Gedeh',
        CountryCode: 'Lr',
        CountryName: 'Liberia',
    },
    {
        CityCode: '0701',
        CityName: 'Barclayville',
        StateCode: '07',
        StateName: 'Grand Kru',
        CountryCode: 'Lr',
        CountryName: 'Liberia',
    },
    {
        CityCode: '0801',
        CityName: 'Voinjama',
        StateCode: '08',
        StateName: 'Lofa',
        CountryCode: 'Lr',
        CountryName: 'Liberia',
    },
    {
        CityCode: '0901',
        CityName: 'Kakata',
        StateCode: '09',
        StateName: 'Margibi',
        CountryCode: 'Lr',
        CountryName: 'Liberia',
    },
    {
        CityCode: '1001',
        CityName: 'Harper',
        StateCode: '10',
        StateName: 'Maryland',
        CountryCode: 'Lr',
        CountryName: 'Liberia',
    },
    {
        CityCode: '1101',
        CityName: 'Bensonville',
        StateCode: '11',
        StateName: 'Montserrado',
        CountryCode: 'Lr',
        CountryName: 'Liberia',
    },
    {
        CityCode: '1201',
        CityName: 'Sanniquellie',
        StateCode: '12',
        StateName: 'Nimba',
        CountryCode: 'Lr',
        CountryName: 'Liberia',
    },
    {
        CityCode: '1301',
        CityName: 'River Cess',
        StateCode: '13',
        StateName: 'River Cess',
        CountryCode: 'Lr',
        CountryName: 'Liberia',
    },
    {
        CityCode: '1401',
        CityName: 'Fish Town',
        StateCode: '14',
        StateName: 'River Gee',
        CountryCode: 'Lr',
        CountryName: 'Liberia',
    },
    {
        CityCode: '1501',
        CityName: 'Greenville',
        StateCode: '15',
        StateName: 'Sinoe',
        CountryCode: 'Lr',
        CountryName: 'Liberia',
    },
    {
        CityCode: '0101',
        CityName: 'Tobruk',
        StateCode: '01',
        StateName: 'Al Butnan',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '0201',
        CityName: 'Derna',
        StateCode: '02',
        StateName: 'Derna',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '0301',
        CityName: 'Al Baida',
        StateCode: '03',
        StateName: 'Al Jabal al Akhdar',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '0401',
        CityName: 'Al Marj',
        StateCode: '04',
        StateName: 'Al Marj',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '0501',
        CityName: 'Bengasi',
        StateCode: '05',
        StateName: 'Bengasi',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '0601',
        CityName: 'Ajdabiya',
        StateCode: '06',
        StateName: 'Al Wahat',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '0701',
        CityName: 'Al Yauf',
        StateCode: '07',
        StateName: 'Al Kufrah',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '0801',
        CityName: 'Sirte',
        StateCode: '08',
        StateName: 'Sirte',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '0901',
        CityName: 'Misurata',
        StateCode: '09',
        StateName: 'Misurata',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '1001',
        CityName: 'Al Murgub',
        StateCode: '10',
        StateName: 'Al Murgub',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '1101',
        CityName: 'Trípoli',
        StateCode: '11',
        StateName: 'Trípoli',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '1201',
        CityName: 'Al Aziziya',
        StateCode: '12',
        StateName: 'Al Jfara',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '1301',
        CityName: 'Zauiya',
        StateCode: '13',
        StateName: 'Zauiya',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '1401',
        CityName: 'Zuara',
        StateCode: '14',
        StateName: 'An Nuqat al Khams',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '1501',
        CityName: 'Garian',
        StateCode: '15',
        StateName: 'Al Jabal al Gharbi',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '1601',
        CityName: 'Nalut',
        StateCode: '16',
        StateName: 'Nalut',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '1701',
        CityName: 'Hun',
        StateCode: '17',
        StateName: 'Al Jufrah',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '1801',
        CityName: 'Adiri',
        StateCode: '18',
        StateName: 'Wadi Al Shatii',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '1901',
        CityName: 'Sabha',
        StateCode: '19',
        StateName: 'Sabha',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '2001',
        CityName: 'Awbari',
        StateCode: '20',
        StateName: 'Wadi Al Hayaa',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '2101',
        CityName: 'Ghat',
        StateCode: '21',
        StateName: 'Ghat',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '2201',
        CityName: 'Murzuq',
        StateCode: '22',
        StateName: 'Murzuq',
        CountryCode: 'Ly',
        CountryName: 'Libia',
    },
    {
        CityCode: '0101',
        CityName: 'Schaan',
        StateCode: '01',
        StateName: 'Schaan',
        CountryCode: 'Li',
        CountryName: 'Liechtenstein',
    },
    {
        CityCode: '0201',
        CityName: 'Planken',
        StateCode: '02',
        StateName: 'Planken',
        CountryCode: 'Li',
        CountryName: 'Liechtenstein',
    },
    {
        CityCode: '0301',
        CityName: 'Vaduz',
        StateCode: '03',
        StateName: 'Vaduz',
        CountryCode: 'Li',
        CountryName: 'Liechtenstein',
    },
    {
        CityCode: '0401',
        CityName: 'Triesenberg',
        StateCode: '04',
        StateName: 'Triesenberg',
        CountryCode: 'Li',
        CountryName: 'Liechtenstein',
    },
    {
        CityCode: '0501',
        CityName: 'Triesen',
        StateCode: '05',
        StateName: 'Triesen',
        CountryCode: 'Li',
        CountryName: 'Liechtenstein',
    },
    {
        CityCode: '0601',
        CityName: 'Balzers',
        StateCode: '06',
        StateName: 'Balzers',
        CountryCode: 'Li',
        CountryName: 'Liechtenstein',
    },
    {
        CityCode: '0101',
        CityName: 'Alytus',
        StateCode: '01',
        StateName: 'Condado de Alytus',
        CountryCode: 'Lt',
        CountryName: 'Lituania',
    },
    {
        CityCode: '0201',
        CityName: 'Kaunas',
        StateCode: '02',
        StateName: 'Condado de Kaunas',
        CountryCode: 'Lt',
        CountryName: 'Lituania',
    },
    {
        CityCode: '0301',
        CityName: 'Klaipeda',
        StateCode: '03',
        StateName: 'Condado de Klaipeda',
        CountryCode: 'Lt',
        CountryName: 'Lituania',
    },
    {
        CityCode: '0401',
        CityName: 'Marijampole',
        StateCode: '04',
        StateName: 'Condado de Marijampole',
        CountryCode: 'Lt',
        CountryName: 'Lituania',
    },
    {
        CityCode: '0501',
        CityName: 'Paneveys',
        StateCode: '05',
        StateName: 'Condado de Paneveys',
        CountryCode: 'Lt',
        CountryName: 'Lituania',
    },
    {
        CityCode: '0601',
        CityName: 'iauliai',
        StateCode: '06',
        StateName: 'Condado de iauliai',
        CountryCode: 'Lt',
        CountryName: 'Lituania',
    },
    {
        CityCode: '0701',
        CityName: 'Taurage',
        StateCode: '07',
        StateName: 'Condado de Taurage',
        CountryCode: 'Lt',
        CountryName: 'Lituania',
    },
    {
        CityCode: '0801',
        CityName: 'Teliai',
        StateCode: '08',
        StateName: 'Condado de Teliai',
        CountryCode: 'Lt',
        CountryName: 'Lituania',
    },
    {
        CityCode: '0901',
        CityName: 'Utena',
        StateCode: '09',
        StateName: 'Condado de Utena',
        CountryCode: 'Lt',
        CountryName: 'Lituania',
    },
    {
        CityCode: '1001',
        CityName: 'Vilnius',
        StateCode: '10',
        StateName: 'Condado de Vilnius',
        CountryCode: 'Lt',
        CountryName: 'Lituania',
    },
    {
        CityCode: '0101',
        CityName: 'Diekirch',
        StateCode: '01',
        StateName: 'Distrito de Diekirch',
        CountryCode: 'Lu',
        CountryName: 'Luxemburgo',
    },
    {
        CityCode: '0201',
        CityName: 'Grevenmacher',
        StateCode: '02',
        StateName: 'Distrito de Grevenmacher',
        CountryCode: 'Lu',
        CountryName: 'Luxemburgo',
    },
    {
        CityCode: '0301',
        CityName: 'Luxemburgo',
        StateCode: '03',
        StateName: 'Distrito de Luxemburgo',
        CountryCode: 'Lu',
        CountryName: 'Luxemburgo',
    },
    {
        CityCode: '0101',
        CityName: 'Skopie',
        StateCode: '01',
        StateName: 'Skopie',
        CountryCode: 'Mk',
        CountryName: 'Macedonia, La Antigua Republicayugoslava De',
    },
    {
        CityCode: '0201',
        CityName: 'Bitola',
        StateCode: '02',
        StateName: 'Pelagonia',
        CountryCode: 'Mk',
        CountryName: 'Macedonia, La Antigua Republicayugoslava De',
    },
    {
        CityCode: '0401',
        CityName: 'Prilep',
        StateCode: '02',
        StateName: 'Pelagonia',
        CountryCode: 'Mk',
        CountryName: 'Macedonia, La Antigua Republicayugoslava De',
    },
    {
        CityCode: '0301',
        CityName: 'Kumanovo',
        StateCode: '03',
        StateName: 'Noreste',
        CountryCode: 'Mk',
        CountryName: 'Macedonia, La Antigua Republicayugoslava De',
    },
    {
        CityCode: '0501',
        CityName: 'Tetovo',
        StateCode: '04',
        StateName: 'Polog',
        CountryCode: 'Mk',
        CountryName: 'Macedonia, La Antigua Republicayugoslava De',
    },
    {
        CityCode: '0901',
        CityName: 'Gostivar',
        StateCode: '04',
        StateName: 'Polog',
        CountryCode: 'Mk',
        CountryName: 'Macedonia, La Antigua Republicayugoslava De',
    },
    {
        CityCode: '0601',
        CityName: 'Veles',
        StateCode: '05',
        StateName: 'Vardar',
        CountryCode: 'Mk',
        CountryName: 'Macedonia, La Antigua Republicayugoslava De',
    },
    {
        CityCode: '0701',
        CityName: 'tip',
        StateCode: '06',
        StateName: 'Este',
        CountryCode: 'Mk',
        CountryName: 'Macedonia, La Antigua Republicayugoslava De',
    },
    {
        CityCode: '0801',
        CityName: 'Ohrid',
        StateCode: '07',
        StateName: 'Sudoeste',
        CountryCode: 'Mk',
        CountryName: 'Macedonia, La Antigua Republicayugoslava De',
    },
    {
        CityCode: '1001',
        CityName: 'Strumica',
        StateCode: '08',
        StateName: 'Sudeste',
        CountryCode: 'Mk',
        CountryName: 'Macedonia, La Antigua Republicayugoslava De',
    },
    {
        CityCode: '0101',
        CityName: 'Antsiranana',
        StateCode: '01',
        StateName: 'Diana',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '0201',
        CityName: 'Sambava',
        StateCode: '02',
        StateName: 'Sava',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '0301',
        CityName: 'Miarinarivo',
        StateCode: '03',
        StateName: 'Itasy',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '0401',
        CityName: 'Antananarivo-Renivohitra',
        StateCode: '04',
        StateName: 'Analamanga',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '0501',
        CityName: 'Antsirabe I',
        StateCode: '05',
        StateName: 'Vakinankaratra',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '0601',
        CityName: 'Tsiroanomandidy',
        StateCode: '06',
        StateName: 'Bongolava',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '0701',
        CityName: 'Antsohihy',
        StateCode: '07',
        StateName: 'Sofía',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '0801',
        CityName: 'Mahajanga I',
        StateCode: '08',
        StateName: 'Boeny',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '0901',
        CityName: 'Maevatanana',
        StateCode: '09',
        StateName: 'Betsiboka',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '1001',
        CityName: 'Maintirano',
        StateCode: '10',
        StateName: 'Melaky',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '1101',
        CityName: 'Ambatondrazaka',
        StateCode: '11',
        StateName: 'Alaotra-Mangoro',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '1201',
        CityName: 'Toamasina I',
        StateCode: '12',
        StateName: 'Atsinanana Este',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '1301',
        CityName: 'Fenerive Est',
        StateCode: '13',
        StateName: 'Analanjirofo',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '1401',
        CityName: 'Ambositra',
        StateCode: '14',
        StateName: "Amoron'i Mania",
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '1501',
        CityName: 'Fianarantsoa I',
        StateCode: '15',
        StateName: 'Haute Matsiatra Alto Matsiatra',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '1601',
        CityName: 'Manakara',
        StateCode: '16',
        StateName: 'Vatovavy-Fitovinany',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '1701',
        CityName: 'Farafangana',
        StateCode: '17',
        StateName: 'Atsimo-Atsinanana Sureste',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '1801',
        CityName: 'Ihosy',
        StateCode: '18',
        StateName: 'Ihorombe',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '1901',
        CityName: 'Morondava',
        StateCode: '19',
        StateName: 'Menabe',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '2001',
        CityName: 'Toliara I',
        StateCode: '20',
        StateName: 'Atsimo-Andrefana Suroeste',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '2101',
        CityName: 'Ambovombe Androy',
        StateCode: '21',
        StateName: 'Androy',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '2201',
        CityName: 'Taolagnaro',
        StateCode: '22',
        StateName: 'Anosy',
        CountryCode: 'Mg',
        CountryName: 'Madagascar',
    },
    {
        CityCode: '0101',
        CityName: 'Johor Bahru',
        StateCode: '01',
        StateName: 'Sultanato de Johor',
        CountryCode: 'My',
        CountryName: 'Malasia',
    },
    {
        CityCode: '0201',
        CityName: 'Alor Setar',
        StateCode: '02',
        StateName: 'Sultanato de Kedah',
        CountryCode: 'My',
        CountryName: 'Malasia',
    },
    {
        CityCode: '0301',
        CityName: 'Kota Bharu',
        StateCode: '03',
        StateName: 'Sultanato de Kelantan',
        CountryCode: 'My',
        CountryName: 'Malasia',
    },
    {
        CityCode: '0401',
        CityName: 'Kuantan',
        StateCode: '04',
        StateName: 'Sultanato de Pahang',
        CountryCode: 'My',
        CountryName: 'Malasia',
    },
    {
        CityCode: '0501',
        CityName: 'Ipoh',
        StateCode: '05',
        StateName: 'Sultanato de Perak',
        CountryCode: 'My',
        CountryName: 'Malasia',
    },
    {
        CityCode: '0601',
        CityName: 'Shah Alam',
        StateCode: '06',
        StateName: 'Sultanato de Selangor',
        CountryCode: 'My',
        CountryName: 'Malasia',
    },
    {
        CityCode: '0701',
        CityName: 'Kuala Terengganu',
        StateCode: '07',
        StateName: 'Sultanato de Terengganu',
        CountryCode: 'My',
        CountryName: 'Malasia',
    },
    {
        CityCode: '0801',
        CityName: 'Seremban',
        StateCode: '08',
        StateName: 'Monarquía de Negeri Sembilan',
        CountryCode: 'My',
        CountryName: 'Malasia',
    },
    {
        CityCode: '0901',
        CityName: 'Kangar',
        StateCode: '09',
        StateName: 'Reino de Perlis',
        CountryCode: 'My',
        CountryName: 'Malasia',
    },
    {
        CityCode: '1001',
        CityName: 'Bandar Melaka',
        StateCode: '10',
        StateName: 'Malaca',
        CountryCode: 'My',
        CountryName: 'Malasia',
    },
    {
        CityCode: '1101',
        CityName: 'George Town',
        StateCode: '11',
        StateName: 'Pulau Pinang',
        CountryCode: 'My',
        CountryName: 'Malasia',
    },
    {
        CityCode: '1201',
        CityName: 'Putrajaya',
        StateCode: '12',
        StateName: 'Territorio Federal de Putrajaya',
        CountryCode: 'My',
        CountryName: 'Malasia',
    },
    {
        CityCode: '1301',
        CityName: 'Kuala Lumpur',
        StateCode: '13',
        StateName: 'Territorio Federal de Kuala Lumpur',
        CountryCode: 'My',
        CountryName: 'Malasia',
    },
    {
        CityCode: '1401',
        CityName: 'Kota Kinabalu',
        StateCode: '14',
        StateName: 'Sabah',
        CountryCode: 'My',
        CountryName: 'Malasia',
    },
    {
        CityCode: '1501',
        CityName: 'Kuching',
        StateCode: '15',
        StateName: 'Sarawak',
        CountryCode: 'My',
        CountryName: 'Malasia',
    },
    {
        CityCode: '1601',
        CityName: 'Victoria',
        StateCode: '16',
        StateName: 'Territorio Federal de Labuan',
        CountryCode: 'My',
        CountryName: 'Malasia',
    },
    {
        CityCode: '0101',
        CityName: 'Lilongüe',
        StateCode: '01',
        StateName: 'Región Central',
        CountryCode: 'Mw',
        CountryName: 'Malaui',
    },
    {
        CityCode: '0201',
        CityName: 'Mzuzu',
        StateCode: '02',
        StateName: 'Región del Norte',
        CountryCode: 'Mw',
        CountryName: 'Malaui',
    },
    {
        CityCode: '0301',
        CityName: 'Blantyre',
        StateCode: '03',
        StateName: 'Región del Sur',
        CountryCode: 'Mw',
        CountryName: 'Malaui',
    },
    {
        CityCode: '0101',
        CityName: 'Dhiddhoo',
        StateCode: '01',
        StateName: 'Haa Alif',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '0201',
        CityName: 'Kulhudhuffushi',
        StateCode: '02',
        StateName: 'Haa Dhaalu',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '0301',
        CityName: 'Funadhoo',
        StateCode: '03',
        StateName: 'Shaviyani',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '0401',
        CityName: 'Manadhoo',
        StateCode: '04',
        StateName: 'Noonu',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '0501',
        CityName: 'Ungoofaaru',
        StateCode: '05',
        StateName: 'Raa',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '0601',
        CityName: 'Eydhafushi',
        StateCode: '06',
        StateName: 'Baa',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '0701',
        CityName: 'Naifaru',
        StateCode: '07',
        StateName: 'Lhaviyani',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '0801',
        CityName: 'Thulusdhoo',
        StateCode: '08',
        StateName: 'Kaafu',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '0901',
        CityName: 'Rasdhoo',
        StateCode: '09',
        StateName: 'Alif Alif',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '1001',
        CityName: 'Mahibadhoo',
        StateCode: '10',
        StateName: 'Alif Dhaal',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '1101',
        CityName: 'Felidhoo',
        StateCode: '11',
        StateName: 'Vaavu',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '1201',
        CityName: 'Muli',
        StateCode: '12',
        StateName: 'Meemu',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '1301',
        CityName: 'Nilandhoo',
        StateCode: '13',
        StateName: 'Faafu',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '1401',
        CityName: 'Kudahuvadhoo',
        StateCode: '14',
        StateName: 'Dhaalu',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '1501',
        CityName: 'Veymandoo',
        StateCode: '15',
        StateName: 'Thaa',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '1601',
        CityName: 'Fonadhoo',
        StateCode: '16',
        StateName: 'Laamu',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '1701',
        CityName: 'Vilingili',
        StateCode: '17',
        StateName: 'Gaafu Alif',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '1801',
        CityName: 'Thinadhoo',
        StateCode: '18',
        StateName: 'Gaafu Dhaalu',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '1901',
        CityName: 'Fuvahmulah',
        StateCode: '19',
        StateName: 'Gnaviyani',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '2001',
        CityName: 'Hithadhoo',
        StateCode: '20',
        StateName: 'Seenu',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '2101',
        CityName: 'Malé',
        StateCode: '21',
        StateName: 'Malé',
        CountryCode: 'Mv',
        CountryName: 'Maldivas',
    },
    {
        CityCode: '0101',
        CityName: 'Gao',
        StateCode: '01',
        StateName: 'Gao',
        CountryCode: 'Ml',
        CountryName: 'Malí',
    },
    {
        CityCode: '0201',
        CityName: 'Kayes',
        StateCode: '02',
        StateName: 'Kayes',
        CountryCode: 'Ml',
        CountryName: 'Malí',
    },
    {
        CityCode: '0301',
        CityName: 'Kidal',
        StateCode: '03',
        StateName: 'Kidal',
        CountryCode: 'Ml',
        CountryName: 'Malí',
    },
    {
        CityCode: '0401',
        CityName: 'Kulikoró',
        StateCode: '04',
        StateName: 'Kulikoró',
        CountryCode: 'Ml',
        CountryName: 'Malí',
    },
    {
        CityCode: '0501',
        CityName: 'Mopti',
        StateCode: '05',
        StateName: 'Mopti',
        CountryCode: 'Ml',
        CountryName: 'Malí',
    },
    {
        CityCode: '0601',
        CityName: 'Segú',
        StateCode: '06',
        StateName: 'Segú',
        CountryCode: 'Ml',
        CountryName: 'Malí',
    },
    {
        CityCode: '0701',
        CityName: 'Sikasso',
        StateCode: '07',
        StateName: 'Sikasso',
        CountryCode: 'Ml',
        CountryName: 'Malí',
    },
    {
        CityCode: '0801',
        CityName: 'Tombuctú',
        StateCode: '08',
        StateName: 'Tombuctú',
        CountryCode: 'Ml',
        CountryName: 'Malí',
    },
    {
        CityCode: '0901',
        CityName: 'Bamako',
        StateCode: '09',
        StateName: 'Distrito de Bamako',
        CountryCode: 'Ml',
        CountryName: 'Malí',
    },
    {
        CityCode: '0101',
        CityName: 'La Valeta',
        StateCode: '01',
        StateName: 'Isla Malta',
        CountryCode: 'Mt',
        CountryName: 'Malta',
    },
    {
        CityCode: '0201',
        CityName: 'Victoria',
        StateCode: '02',
        StateName: 'Isla Gozo',
        CountryCode: 'Mt',
        CountryName: 'Malta',
    },
    {
        CityCode: '0101',
        CityName: 'Tánger',
        StateCode: '01',
        StateName: 'Tánger-Tetuán-Alhucemas',
        CountryCode: 'Ma',
        CountryName: 'Marruecos',
    },
    {
        CityCode: '0201',
        CityName: 'Uchda',
        StateCode: '02',
        StateName: 'La Oriental',
        CountryCode: 'Ma',
        CountryName: 'Marruecos',
    },
    {
        CityCode: '0301',
        CityName: 'Fez',
        StateCode: '03',
        StateName: 'Fez-Mequinez',
        CountryCode: 'Ma',
        CountryName: 'Marruecos',
    },
    {
        CityCode: '0401',
        CityName: 'Rabat',
        StateCode: '04',
        StateName: 'Rabat-Salé-Kenitra',
        CountryCode: 'Ma',
        CountryName: 'Marruecos',
    },
    {
        CityCode: '0501',
        CityName: 'Beni Melal',
        StateCode: '05',
        StateName: 'Beni Melal-Jenifra',
        CountryCode: 'Ma',
        CountryName: 'Marruecos',
    },
    {
        CityCode: '0601',
        CityName: 'Casablanca',
        StateCode: '06',
        StateName: 'Casablanca-Settat',
        CountryCode: 'Ma',
        CountryName: 'Marruecos',
    },
    {
        CityCode: '0701',
        CityName: 'Marrakech',
        StateCode: '07',
        StateName: 'Marrakech-Safí',
        CountryCode: 'Ma',
        CountryName: 'Marruecos',
    },
    {
        CityCode: '0801',
        CityName: 'Er-Rachidía',
        StateCode: '08',
        StateName: 'Draa-Tafilalet',
        CountryCode: 'Ma',
        CountryName: 'Marruecos',
    },
    {
        CityCode: '0901',
        CityName: 'Agadir',
        StateCode: '09',
        StateName: 'Sus-Masa',
        CountryCode: 'Ma',
        CountryName: 'Marruecos',
    },
    {
        CityCode: '1001',
        CityName: 'Guelmim',
        StateCode: '10',
        StateName: 'Guelmim-Río Noun',
        CountryCode: 'Ma',
        CountryName: 'Marruecos',
    },
    {
        CityCode: '1101',
        CityName: 'El Aaiún',
        StateCode: '11',
        StateName: 'El Aaiún-Saguía el-Hamra',
        CountryCode: 'Ma',
        CountryName: 'Marruecos',
    },
    {
        CityCode: '1201',
        CityName: 'Dajla',
        StateCode: '12',
        StateName: 'Dajla-Río de Oro',
        CountryCode: 'Ma',
        CountryName: 'Marruecos',
    },
    {
        CityCode: '0101',
        CityName: 'La Trinité',
        StateCode: '01',
        StateName: 'Distrito de La Trinité',
        CountryCode: 'Mq',
        CountryName: 'Martinica',
    },
    {
        CityCode: '0201',
        CityName: 'Fuerte de Francia',
        StateCode: '02',
        StateName: 'Distrito de Fort-de-France',
        CountryCode: 'Mq',
        CountryName: 'Martinica',
    },
    {
        CityCode: '0301',
        CityName: 'Le Marin',
        StateCode: '03',
        StateName: 'Distrito de Le Marin',
        CountryCode: 'Mq',
        CountryName: 'Martinica',
    },
    {
        CityCode: '0101',
        CityName: 'Tamarin',
        StateCode: '01',
        StateName: 'Black River',
        CountryCode: 'Mu',
        CountryName: 'Mauricio',
    },
    {
        CityCode: '0201',
        CityName: 'Centre de Flacq',
        StateCode: '02',
        StateName: 'Flacq',
        CountryCode: 'Mu',
        CountryName: 'Mauricio',
    },
    {
        CityCode: '0301',
        CityName: 'Mahebourg',
        StateCode: '03',
        StateName: 'Grand Port',
        CountryCode: 'Mu',
        CountryName: 'Mauricio',
    },
    {
        CityCode: '0401',
        CityName: 'Moka',
        StateCode: '04',
        StateName: 'Moka',
        CountryCode: 'Mu',
        CountryName: 'Mauricio',
    },
    {
        CityCode: '0501',
        CityName: 'Triolet',
        StateCode: '05',
        StateName: 'Pamplemousses',
        CountryCode: 'Mu',
        CountryName: 'Mauricio',
    },
    {
        CityCode: '0601',
        CityName: 'Rose-Hill',
        StateCode: '06',
        StateName: 'Plaines Wilhems',
        CountryCode: 'Mu',
        CountryName: 'Mauricio',
    },
    {
        CityCode: '0701',
        CityName: 'Port Louis',
        StateCode: '07',
        StateName: 'Port Louis',
        CountryCode: 'Mu',
        CountryName: 'Mauricio',
    },
    {
        CityCode: '0801',
        CityName: "Poudre d'Or",
        StateCode: '08',
        StateName: 'Rivière du Rempart',
        CountryCode: 'Mu',
        CountryName: 'Mauricio',
    },
    {
        CityCode: '0901',
        CityName: 'Souillac',
        StateCode: '09',
        StateName: 'Savanne',
        CountryCode: 'Mu',
        CountryName: 'Mauricio',
    },
    {
        CityCode: '0101',
        CityName: 'Atar Mauritania',
        StateCode: '01',
        StateName: 'Adrar Mauritania',
        CountryCode: 'Mr',
        CountryName: 'Mauritania',
    },
    {
        CityCode: '0201',
        CityName: 'Kiffa',
        StateCode: '02',
        StateName: 'Assaba',
        CountryCode: 'Mr',
        CountryName: 'Mauritania',
    },
    {
        CityCode: '0301',
        CityName: 'Aleg',
        StateCode: '03',
        StateName: 'Brakna',
        CountryCode: 'Mr',
        CountryName: 'Mauritania',
    },
    {
        CityCode: '0401',
        CityName: 'Nuadibú',
        StateCode: '04',
        StateName: 'Dajlet Nuadibú',
        CountryCode: 'Mr',
        CountryName: 'Mauritania',
    },
    {
        CityCode: '0501',
        CityName: 'Kaédi',
        StateCode: '05',
        StateName: 'Gorgol',
        CountryCode: 'Mr',
        CountryName: 'Mauritania',
    },
    {
        CityCode: '0601',
        CityName: 'Sélibaby',
        StateCode: '06',
        StateName: 'Guidimaka',
        CountryCode: 'Mr',
        CountryName: 'Mauritania',
    },
    {
        CityCode: '0701',
        CityName: 'Néma',
        StateCode: '07',
        StateName: 'Hod Oriental',
        CountryCode: 'Mr',
        CountryName: 'Mauritania',
    },
    {
        CityCode: '0801',
        CityName: 'Aiún el Atrús',
        StateCode: '08',
        StateName: 'Hod Occidental',
        CountryCode: 'Mr',
        CountryName: 'Mauritania',
    },
    {
        CityCode: '0901',
        CityName: 'Akjoujt',
        StateCode: '09',
        StateName: 'Inchiri',
        CountryCode: 'Mr',
        CountryName: 'Mauritania',
    },
    {
        CityCode: '1001',
        CityName: 'Distrito de la capital',
        StateCode: '10',
        StateName: 'Nuakchot',
        CountryCode: 'Mr',
        CountryName: 'Mauritania',
    },
    {
        CityCode: '1101',
        CityName: 'Tidjikja',
        StateCode: '11',
        StateName: 'Tagant',
        CountryCode: 'Mr',
        CountryName: 'Mauritania',
    },
    {
        CityCode: '1201',
        CityName: 'Zuérate',
        StateCode: '12',
        StateName: 'Tiris Zemmur',
        CountryCode: 'Mr',
        CountryName: 'Mauritania',
    },
    {
        CityCode: '1301',
        CityName: 'Rosso',
        StateCode: '13',
        StateName: 'Trarza',
        CountryCode: 'Mr',
        CountryName: 'Mauritania',
    },
    {
        CityCode: '0101',
        CityName: 'Mamoudzou',
        StateCode: '01',
        StateName: 'Mamoudzou',
        CountryCode: 'Yt',
        CountryName: 'Mayotte',
    },
    {
        CityCode: '0101',
        CityName: 'Aguascalientes',
        StateCode: '01',
        StateName: 'Aguascalientes',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '0201',
        CityName: 'Mexicali',
        StateCode: '02',
        StateName: 'Baja California',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '0301',
        CityName: 'La Paz',
        StateCode: '03',
        StateName: 'Baja California Sur',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '0401',
        CityName: 'San Francisco de Campeche',
        StateCode: '04',
        StateName: 'Campeche',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '0501',
        CityName: 'Tuxtla Gutiérrez',
        StateCode: '05',
        StateName: 'Chiapas',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '0601',
        CityName: 'Chihuahua',
        StateCode: '06',
        StateName: 'Chihuahua',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '0701',
        CityName: 'Ciudad de México',
        StateCode: '07',
        StateName: 'Ciudad de México',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '0801',
        CityName: 'Saltillo',
        StateCode: '08',
        StateName: 'Coahuila',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '0901',
        CityName: 'Colima',
        StateCode: '09',
        StateName: 'Colima',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '1001',
        CityName: 'Victoria de Durango',
        StateCode: '10',
        StateName: 'Durango',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '1101',
        CityName: 'Guanajuato',
        StateCode: '11',
        StateName: 'Guanajuato',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '1201',
        CityName: 'Chilpancingo de los Bravo',
        StateCode: '12',
        StateName: 'Guerrero',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '1301',
        CityName: 'Pachuca de Soto',
        StateCode: '13',
        StateName: 'Hidalgo',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '1401',
        CityName: 'Guadalajara',
        StateCode: '14',
        StateName: 'Jalisco',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '1501',
        CityName: 'Toluca de Lerdo',
        StateCode: '15',
        StateName: 'México',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '1601',
        CityName: 'Morelia',
        StateCode: '16',
        StateName: 'Michoacán',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '1701',
        CityName: 'Cuernavaca',
        StateCode: '17',
        StateName: 'Morelos',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '1801',
        CityName: 'Tepic',
        StateCode: '18',
        StateName: 'Nayarit',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '1901',
        CityName: 'Monterrey',
        StateCode: '19',
        StateName: 'Nuevo León',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '2001',
        CityName: 'Oaxaca de Juárez',
        StateCode: '20',
        StateName: 'Oaxaca',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '2101',
        CityName: 'Puebla de Zaragoza',
        StateCode: '21',
        StateName: 'Puebla',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '2201',
        CityName: 'Santiago de Querétaro',
        StateCode: '22',
        StateName: 'Querétaro',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '2301',
        CityName: 'Chetumal',
        StateCode: '23',
        StateName: 'Quintana Roo',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '2401',
        CityName: 'San Luis Potosí',
        StateCode: '24',
        StateName: 'San Luis Potosí',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '2501',
        CityName: 'Culiacán Rosales',
        StateCode: '25',
        StateName: 'Sinaloa',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '2601',
        CityName: 'Hermosillo',
        StateCode: '26',
        StateName: 'Sonora',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '2701',
        CityName: 'Villahermosa',
        StateCode: '27',
        StateName: 'Tabasco',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '2801',
        CityName: 'Ciudad Victoria',
        StateCode: '28',
        StateName: 'Tamaulipas',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '2901',
        CityName: 'Tlaxcala de Xicohténcatl',
        StateCode: '29',
        StateName: 'Tlaxcala',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '3001',
        CityName: 'Xalapa-Enríquez',
        StateCode: '30',
        StateName: 'Veracruz',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '3101',
        CityName: 'Mérida',
        StateCode: '31',
        StateName: 'Yucatán',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '3201',
        CityName: 'Zacatecas',
        StateCode: '32',
        StateName: 'Zacatecas',
        CountryCode: 'Mx',
        CountryName: 'México',
    },
    {
        CityCode: '0101',
        CityName: 'Anenii Noi',
        StateCode: '01',
        StateName: 'Anenii Noi',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '0201',
        CityName: 'Basarabeasca',
        StateCode: '02',
        StateName: 'Basarabeasca',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '0301',
        CityName: 'Briceni',
        StateCode: '03',
        StateName: 'Briceni',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '0401',
        CityName: 'Cahul',
        StateCode: '04',
        StateName: 'Cahul',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '0501',
        CityName: 'Cantemir',
        StateCode: '05',
        StateName: 'Cantemir',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '0601',
        CityName: 'Calarai',
        StateCode: '06',
        StateName: 'Calarai',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '0701',
        CityName: 'Caueni',
        StateCode: '07',
        StateName: 'Caueni',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '0801',
        CityName: 'Cimilia',
        StateCode: '08',
        StateName: 'Cimilia',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '0901',
        CityName: 'Criuleni',
        StateCode: '09',
        StateName: 'Criuleni',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '1001',
        CityName: 'Dondueni',
        StateCode: '10',
        StateName: 'Dondueni',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '1101',
        CityName: 'Drochia',
        StateCode: '11',
        StateName: 'Drochia',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '1201',
        CityName: 'Dubasari',
        StateCode: '12',
        StateName: 'Dubasari',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '1301',
        CityName: 'Edine',
        StateCode: '13',
        StateName: 'Edine',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '1401',
        CityName: 'Faleti',
        StateCode: '14',
        StateName: 'Faleti',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '1501',
        CityName: 'Floreti',
        StateCode: '15',
        StateName: 'Floreti',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '1601',
        CityName: 'Glodeni',
        StateCode: '16',
        StateName: 'Glodeni',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '1701',
        CityName: 'Hînceti',
        StateCode: '17',
        StateName: 'Hînceti',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '1801',
        CityName: 'Ialoveni',
        StateCode: '18',
        StateName: 'Ialoveni',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '1901',
        CityName: 'Leova',
        StateCode: '19',
        StateName: 'Leova',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '2001',
        CityName: 'Nisporeni',
        StateCode: '20',
        StateName: 'Nisporeni',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '2101',
        CityName: 'Ocnia',
        StateCode: '21',
        StateName: 'Ocnia',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '2201',
        CityName: 'Orhei',
        StateCode: '22',
        StateName: 'Orhei',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '2301',
        CityName: 'Rezina',
        StateCode: '23',
        StateName: 'Rezina',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '2401',
        CityName: 'Rîcani',
        StateCode: '24',
        StateName: 'Rîcani',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '2501',
        CityName: 'Sîngerei',
        StateCode: '25',
        StateName: 'Sîngerei',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '2601',
        CityName: 'Soroca',
        StateCode: '26',
        StateName: 'Soroca',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '2701',
        CityName: 'Straeni',
        StateCode: '27',
        StateName: 'Straeni',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '2801',
        CityName: 'oldaneti',
        StateCode: '28',
        StateName: 'oldaneti',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '2901',
        CityName: 'tefan Voda',
        StateCode: '29',
        StateName: 'tefan Voda',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '3001',
        CityName: 'Taraclia',
        StateCode: '30',
        StateName: 'Taraclia',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '3101',
        CityName: 'Teleneti',
        StateCode: '31',
        StateName: 'Teleneti',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '3201',
        CityName: 'Unghen',
        StateCode: '32',
        StateName: 'Unghen',
        CountryCode: 'Md',
        CountryName: 'Moldavia',
    },
    {
        CityCode: '0101',
        CityName: 'Mónaco',
        StateCode: '01',
        StateName: 'Mónaco',
        CountryCode: 'Mc',
        CountryName: 'Mónaco',
    },
    {
        CityCode: '0101',
        CityName: 'Tsetserleg',
        StateCode: '01',
        StateName: 'Arhangay',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '0201',
        CityName: 'Ölgiy',
        StateCode: '02',
        StateName: 'Bayan-Ölgiy',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '0301',
        CityName: 'Bayanhongor',
        StateCode: '03',
        StateName: 'Bayanhongor',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '0401',
        CityName: 'Bulgan',
        StateCode: '04',
        StateName: 'Bulgan',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '0501',
        CityName: 'Darkhan',
        StateCode: '05',
        StateName: 'Darhan-Uul',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '0601',
        CityName: 'Choybalsan',
        StateCode: '06',
        StateName: 'Dornod',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '0701',
        CityName: 'Sainshand',
        StateCode: '07',
        StateName: 'Dornogovi',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '0801',
        CityName: 'Mandalgobi',
        StateCode: '08',
        StateName: 'Dundgovi',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '0901',
        CityName: 'Altai',
        StateCode: '09',
        StateName: 'Govi-Altay',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '1001',
        CityName: 'Choyr',
        StateCode: '10',
        StateName: 'Govisümber',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '1101',
        CityName: 'Öndörkhaan',
        StateCode: '11',
        StateName: 'Hentiy',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '1201',
        CityName: 'Hovd',
        StateCode: '12',
        StateName: 'Hovd',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '1301',
        CityName: 'Mörön',
        StateCode: '13',
        StateName: 'Hövsgöl',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '1401',
        CityName: 'Dalanzadgad',
        StateCode: '14',
        StateName: 'Ömnögovi',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '1501',
        CityName: 'Erdenet',
        StateCode: '15',
        StateName: 'Orhon',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '1601',
        CityName: 'Arvaikheer',
        StateCode: '16',
        StateName: 'Övörhangay',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '1701',
        CityName: 'Sukhbaatar',
        StateCode: '17',
        StateName: 'Selenge',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '1801',
        CityName: 'Baruun-Urt',
        StateCode: '18',
        StateName: 'Sühbaatar',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '1901',
        CityName: 'Zuunmod',
        StateCode: '19',
        StateName: 'Töv',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '2001',
        CityName: 'Ulaangom',
        StateCode: '20',
        StateName: 'Uvs',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '2101',
        CityName: 'Uliastay',
        StateCode: '21',
        StateName: 'Zavhan',
        CountryCode: 'Mn',
        CountryName: 'Mongolia',
    },
    {
        CityCode: '0101',
        CityName: 'Andrijevica',
        StateCode: '01',
        StateName: 'Municipio de Andrijevica',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '0201',
        CityName: 'Bar',
        StateCode: '02',
        StateName: 'Municipio de Bar',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '0301',
        CityName: 'Berane',
        StateCode: '03',
        StateName: 'Municipio de Berane',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '0401',
        CityName: 'Bijelo Polje',
        StateCode: '04',
        StateName: 'Municipio de Bijelo Polje',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '0501',
        CityName: 'Budva',
        StateCode: '05',
        StateName: 'Municipio de Budva',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '0601',
        CityName: 'Cetinje',
        StateCode: '06',
        StateName: 'Municipio de Cetinje',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '0701',
        CityName: 'Danilovgrad',
        StateCode: '07',
        StateName: 'Municipio de Danilovgrad',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '0801',
        CityName: 'Gusinje',
        StateCode: '08',
        StateName: 'Municipio de Gusinje',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '0901',
        CityName: 'Herceg Novi',
        StateCode: '09',
        StateName: 'Municipio de Herceg Novi',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '1001',
        CityName: 'Kolain',
        StateCode: '10',
        StateName: 'Municipio de Kolain',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '1101',
        CityName: 'Kotor',
        StateCode: '11',
        StateName: 'Municipio de Kotor',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '1201',
        CityName: 'Mojkovac',
        StateCode: '12',
        StateName: 'Municipio de Mojkovac',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '1301',
        CityName: 'Nikic',
        StateCode: '13',
        StateName: 'Municipio de Nikic',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '1401',
        CityName: 'Petnjica',
        StateCode: '14',
        StateName: 'Municipio de Petnjica',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '1501',
        CityName: 'Plav',
        StateCode: '15',
        StateName: 'Municipio de Plav',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '1601',
        CityName: 'Pluine',
        StateCode: '16',
        StateName: 'Municipio de Pluine',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '1701',
        CityName: 'Pljevlja',
        StateCode: '17',
        StateName: 'Municipio de Pljevlja',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '1801',
        CityName: 'Podgorica',
        StateCode: '18',
        StateName: 'Municipio de Podgorica',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '1901',
        CityName: 'Roaje',
        StateCode: '19',
        StateName: 'Municipio de Roaje',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '2001',
        CityName: 'avnik',
        StateCode: '20',
        StateName: 'Municipio de avnik',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '2101',
        CityName: 'Tivat',
        StateCode: '21',
        StateName: 'Municipio de Tivat',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '2201',
        CityName: 'Ulcinj',
        StateCode: '22',
        StateName: 'Municipio de Ulcinj',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '2301',
        CityName: 'abljak',
        StateCode: '23',
        StateName: 'Municipio de abljak',
        CountryCode: 'Me',
        CountryName: 'Montenegro',
    },
    {
        CityCode: '0101',
        CityName: 'Pemba',
        StateCode: '01',
        StateName: 'Cabo Delgado',
        CountryCode: 'Mz',
        CountryName: 'Mozambique',
    },
    {
        CityCode: '0201',
        CityName: 'Xai-xai',
        StateCode: '02',
        StateName: 'Gaza',
        CountryCode: 'Mz',
        CountryName: 'Mozambique',
    },
    {
        CityCode: '0301',
        CityName: 'Inhambane',
        StateCode: '03',
        StateName: 'Inhambane',
        CountryCode: 'Mz',
        CountryName: 'Mozambique',
    },
    {
        CityCode: '0401',
        CityName: 'Chimoio',
        StateCode: '04',
        StateName: 'Manica',
        CountryCode: 'Mz',
        CountryName: 'Mozambique',
    },
    {
        CityCode: '0501',
        CityName: 'Maputo',
        StateCode: '05',
        StateName: 'Maputo ciudad',
        CountryCode: 'Mz',
        CountryName: 'Mozambique',
    },
    {
        CityCode: '0601',
        CityName: 'Matola',
        StateCode: '06',
        StateName: 'Maputo provincia',
        CountryCode: 'Mz',
        CountryName: 'Mozambique',
    },
    {
        CityCode: '0701',
        CityName: 'Nampula',
        StateCode: '07',
        StateName: 'Nampula',
        CountryCode: 'Mz',
        CountryName: 'Mozambique',
    },
    {
        CityCode: '0801',
        CityName: 'Lichinga',
        StateCode: '08',
        StateName: 'Niassa',
        CountryCode: 'Mz',
        CountryName: 'Mozambique',
    },
    {
        CityCode: '0901',
        CityName: 'Beira',
        StateCode: '09',
        StateName: 'Sofala',
        CountryCode: 'Mz',
        CountryName: 'Mozambique',
    },
    {
        CityCode: '1001',
        CityName: 'Tete',
        StateCode: '10',
        StateName: 'Tete',
        CountryCode: 'Mz',
        CountryName: 'Mozambique',
    },
    {
        CityCode: '1101',
        CityName: 'Quelimane',
        StateCode: '11',
        StateName: 'Zambezia',
        CountryCode: 'Mz',
        CountryName: 'Mozambique',
    },
    {
        CityCode: '0101',
        CityName: 'Opuwo',
        StateCode: '01',
        StateName: 'Kunene',
        CountryCode: 'Na',
        CountryName: 'Namibia',
    },
    {
        CityCode: '0201',
        CityName: 'Outapi',
        StateCode: '02',
        StateName: 'Omusati',
        CountryCode: 'Na',
        CountryName: 'Namibia',
    },
    {
        CityCode: '0301',
        CityName: 'Oshakati',
        StateCode: '03',
        StateName: 'Oshana',
        CountryCode: 'Na',
        CountryName: 'Namibia',
    },
    {
        CityCode: '0401',
        CityName: 'Eenhana',
        StateCode: '04',
        StateName: 'Ohangwena',
        CountryCode: 'Na',
        CountryName: 'Namibia',
    },
    {
        CityCode: '0501',
        CityName: 'Omuthiya',
        StateCode: '05',
        StateName: 'Oshikoto',
        CountryCode: 'Na',
        CountryName: 'Namibia',
    },
    {
        CityCode: '0601',
        CityName: 'Nkurenkuru',
        StateCode: '06',
        StateName: 'Kavango del Oeste',
        CountryCode: 'Na',
        CountryName: 'Namibia',
    },
    {
        CityCode: '0701',
        CityName: 'Rundu',
        StateCode: '07',
        StateName: 'Kavango del Este',
        CountryCode: 'Na',
        CountryName: 'Namibia',
    },
    {
        CityCode: '0801',
        CityName: 'KatimaMulilo',
        StateCode: '08',
        StateName: 'Zambezi',
        CountryCode: 'Na',
        CountryName: 'Namibia',
    },
    {
        CityCode: '0901',
        CityName: 'Swakopmund',
        StateCode: '09',
        StateName: 'Erongo',
        CountryCode: 'Na',
        CountryName: 'Namibia',
    },
    {
        CityCode: '1001',
        CityName: 'Otjiwarongo',
        StateCode: '10',
        StateName: 'Otjozondjupa',
        CountryCode: 'Na',
        CountryName: 'Namibia',
    },
    {
        CityCode: '1101',
        CityName: 'Gobabis',
        StateCode: '11',
        StateName: 'Omaheke',
        CountryCode: 'Na',
        CountryName: 'Namibia',
    },
    {
        CityCode: '1201',
        CityName: 'Windhoek',
        StateCode: '12',
        StateName: 'Khomas',
        CountryCode: 'Na',
        CountryName: 'Namibia',
    },
    {
        CityCode: '1301',
        CityName: 'Mariental',
        StateCode: '13',
        StateName: 'Hardap',
        CountryCode: 'Na',
        CountryName: 'Namibia',
    },
    {
        CityCode: '1401',
        CityName: 'Keetmanshoop',
        StateCode: '14',
        StateName: 'Karas',
        CountryCode: 'Na',
        CountryName: 'Namibia',
    },
    {
        CityCode: '0101',
        CityName: 'Aiwo',
        StateCode: '01',
        StateName: 'Aiwo',
        CountryCode: 'Nr',
        CountryName: 'Nauru',
    },
    {
        CityCode: '0201',
        CityName: 'Anabar',
        StateCode: '02',
        StateName: 'Anabar',
        CountryCode: 'Nr',
        CountryName: 'Nauru',
    },
    {
        CityCode: '0301',
        CityName: 'Anetan',
        StateCode: '03',
        StateName: 'Anetan',
        CountryCode: 'Nr',
        CountryName: 'Nauru',
    },
    {
        CityCode: '0401',
        CityName: 'Anibare',
        StateCode: '04',
        StateName: 'Anibare',
        CountryCode: 'Nr',
        CountryName: 'Nauru',
    },
    {
        CityCode: '0501',
        CityName: 'Baiti',
        StateCode: '05',
        StateName: 'Baiti',
        CountryCode: 'Nr',
        CountryName: 'Nauru',
    },
    {
        CityCode: '0601',
        CityName: 'Boe',
        StateCode: '06',
        StateName: 'Boe',
        CountryCode: 'Nr',
        CountryName: 'Nauru',
    },
    {
        CityCode: '0701',
        CityName: 'Buada',
        StateCode: '07',
        StateName: 'Buada',
        CountryCode: 'Nr',
        CountryName: 'Nauru',
    },
    {
        CityCode: '0801',
        CityName: 'Denigomodu',
        StateCode: '08',
        StateName: 'Denigomodu',
        CountryCode: 'Nr',
        CountryName: 'Nauru',
    },
    {
        CityCode: '0901',
        CityName: 'Ewa',
        StateCode: '09',
        StateName: 'Ewa',
        CountryCode: 'Nr',
        CountryName: 'Nauru',
    },
    {
        CityCode: '1001',
        CityName: 'Ijuw',
        StateCode: '10',
        StateName: 'Ijuw',
        CountryCode: 'Nr',
        CountryName: 'Nauru',
    },
    {
        CityCode: '1101',
        CityName: 'Meneng',
        StateCode: '11',
        StateName: 'Meneng',
        CountryCode: 'Nr',
        CountryName: 'Nauru',
    },
    {
        CityCode: '1201',
        CityName: 'Nibok',
        StateCode: '12',
        StateName: 'Nibok',
        CountryCode: 'Nr',
        CountryName: 'Nauru',
    },
    {
        CityCode: '1301',
        CityName: 'Uaboe',
        StateCode: '13',
        StateName: 'Uaboe',
        CountryCode: 'Nr',
        CountryName: 'Nauru',
    },
    {
        CityCode: '1401',
        CityName: 'Yaren',
        StateCode: '14',
        StateName: 'Yaren',
        CountryCode: 'Nr',
        CountryName: 'Nauru',
    },
    {
        CityCode: '0101',
        CityName: 'Biratnagar',
        StateCode: '01',
        StateName: 'Kosi',
        CountryCode: 'Np',
        CountryName: 'Nepal',
    },
    {
        CityCode: '0201',
        CityName: 'Ilam',
        StateCode: '02',
        StateName: 'Mechi',
        CountryCode: 'Np',
        CountryName: 'Nepal',
    },
    {
        CityCode: '0301',
        CityName: 'Rajbiraj',
        StateCode: '03',
        StateName: 'Sagarmatha',
        CountryCode: 'Np',
        CountryName: 'Nepal',
    },
    {
        CityCode: '0401',
        CityName: 'Katmandú',
        StateCode: '04',
        StateName: 'Bagmati',
        CountryCode: 'Np',
        CountryName: 'Nepal',
    },
    {
        CityCode: '0501',
        CityName: 'Janakpur',
        StateCode: '05',
        StateName: 'Janakpur',
        CountryCode: 'Np',
        CountryName: 'Nepal',
    },
    {
        CityCode: '0601',
        CityName: 'Birganj',
        StateCode: '06',
        StateName: 'Narayani',
        CountryCode: 'Np',
        CountryName: 'Nepal',
    },
    {
        CityCode: '0701',
        CityName: 'Baglung',
        StateCode: '07',
        StateName: 'Dhawalagiri',
        CountryCode: 'Np',
        CountryName: 'Nepal',
    },
    {
        CityCode: '0801',
        CityName: 'Butwal',
        StateCode: '08',
        StateName: 'Gandaki',
        CountryCode: 'Np',
        CountryName: 'Nepal',
    },
    {
        CityCode: '0901',
        CityName: 'Butwal',
        StateCode: '09',
        StateName: 'Lumbini',
        CountryCode: 'Np',
        CountryName: 'Nepal',
    },
    {
        CityCode: '1001',
        CityName: 'Nepalganj',
        StateCode: '10',
        StateName: 'Bheri',
        CountryCode: 'Np',
        CountryName: 'Nepal',
    },
    {
        CityCode: '1101',
        CityName: 'Chandannath',
        StateCode: '11',
        StateName: 'Karnali',
        CountryCode: 'Np',
        CountryName: 'Nepal',
    },
    {
        CityCode: '1201',
        CityName: 'Tulsipur',
        StateCode: '12',
        StateName: 'Rapti',
        CountryCode: 'Np',
        CountryName: 'Nepal',
    },
    {
        CityCode: '1301',
        CityName: 'Bhim Datta',
        StateCode: '13',
        StateName: 'Mahakali',
        CountryCode: 'Np',
        CountryName: 'Nepal',
    },
    {
        CityCode: '1401',
        CityName: 'Dipayal Silgadhi',
        StateCode: '14',
        StateName: 'Seti',
        CountryCode: 'Np',
        CountryName: 'Nepal',
    },
    {
        CityCode: '0101',
        CityName: 'Bilwi',
        StateCode: '01',
        StateName: 'CaribeNorte',
        CountryCode: 'Ni',
        CountryName: 'Nicaragua',
    },
    {
        CityCode: '0201',
        CityName: 'Bluefields',
        StateCode: '02',
        StateName: 'CaribeSur',
        CountryCode: 'Ni',
        CountryName: 'Nicaragua',
    },
    {
        CityCode: '0301',
        CityName: 'Boaco',
        StateCode: '03',
        StateName: 'Boaco',
        CountryCode: 'Ni',
        CountryName: 'Nicaragua',
    },
    {
        CityCode: '0401',
        CityName: 'Jinotepe',
        StateCode: '04',
        StateName: 'Carazo',
        CountryCode: 'Ni',
        CountryName: 'Nicaragua',
    },
    {
        CityCode: '0501',
        CityName: 'Chinandega',
        StateCode: '05',
        StateName: 'Chinandega',
        CountryCode: 'Ni',
        CountryName: 'Nicaragua',
    },
    {
        CityCode: '0601',
        CityName: 'Juigalpa',
        StateCode: '06',
        StateName: 'Chontales',
        CountryCode: 'Ni',
        CountryName: 'Nicaragua',
    },
    {
        CityCode: '0701',
        CityName: 'Esteli',
        StateCode: '07',
        StateName: 'Estelí',
        CountryCode: 'Ni',
        CountryName: 'Nicaragua',
    },
    {
        CityCode: '0801',
        CityName: 'Granada',
        StateCode: '08',
        StateName: 'Granada',
        CountryCode: 'Ni',
        CountryName: 'Nicaragua',
    },
    {
        CityCode: '0901',
        CityName: 'Jinotega',
        StateCode: '09',
        StateName: 'Jinotega',
        CountryCode: 'Ni',
        CountryName: 'Nicaragua',
    },
    {
        CityCode: '1001',
        CityName: 'León',
        StateCode: '10',
        StateName: 'León',
        CountryCode: 'Ni',
        CountryName: 'Nicaragua',
    },
    {
        CityCode: '1101',
        CityName: 'Somoto',
        StateCode: '11',
        StateName: 'Madriz',
        CountryCode: 'Ni',
        CountryName: 'Nicaragua',
    },
    {
        CityCode: '1201',
        CityName: 'Managua',
        StateCode: '12',
        StateName: 'Managua',
        CountryCode: 'Ni',
        CountryName: 'Nicaragua',
    },
    {
        CityCode: '1301',
        CityName: 'Masaya',
        StateCode: '13',
        StateName: 'Masaya',
        CountryCode: 'Ni',
        CountryName: 'Nicaragua',
    },
    {
        CityCode: '1401',
        CityName: 'Matagalpa',
        StateCode: '14',
        StateName: 'Matagalpa',
        CountryCode: 'Ni',
        CountryName: 'Nicaragua',
    },
    {
        CityCode: '1501',
        CityName: 'Ocotal',
        StateCode: '15',
        StateName: 'NuevaSegovia',
        CountryCode: 'Ni',
        CountryName: 'Nicaragua',
    },
    {
        CityCode: '1601',
        CityName: 'San Carlos',
        StateCode: '16',
        StateName: 'RíoSanJuan',
        CountryCode: 'Ni',
        CountryName: 'Nicaragua',
    },
    {
        CityCode: '1701',
        CityName: 'Rivas',
        StateCode: '17',
        StateName: 'Rivas',
        CountryCode: 'Ni',
        CountryName: 'Nicaragua',
    },
    {
        CityCode: '1801',
        CityName: 'Managua',
        StateCode: '18',
        StateName: 'Nicaragua',
        CountryCode: 'Ni',
        CountryName: 'Nicaragua',
    },
    {
        CityCode: '0101',
        CityName: 'Agadez',
        StateCode: '01',
        StateName: 'Agadez',
        CountryCode: 'Ne',
        CountryName: 'Níger',
    },
    {
        CityCode: '0201',
        CityName: 'Diffa',
        StateCode: '02',
        StateName: 'Diffa',
        CountryCode: 'Ne',
        CountryName: 'Níger',
    },
    {
        CityCode: '0301',
        CityName: 'Dosso',
        StateCode: '03',
        StateName: 'Dosso',
        CountryCode: 'Ne',
        CountryName: 'Níger',
    },
    {
        CityCode: '0401',
        CityName: 'Maradi',
        StateCode: '04',
        StateName: 'Maradi',
        CountryCode: 'Ne',
        CountryName: 'Níger',
    },
    {
        CityCode: '0501',
        CityName: 'Niamey',
        StateCode: '05',
        StateName: 'Niamey',
        CountryCode: 'Ne',
        CountryName: 'Níger',
    },
    {
        CityCode: '0601',
        CityName: 'Tahoua',
        StateCode: '06',
        StateName: 'Tahoua',
        CountryCode: 'Ne',
        CountryName: 'Níger',
    },
    {
        CityCode: '0701',
        CityName: 'Tillabéri',
        StateCode: '07',
        StateName: 'Tillabéri',
        CountryCode: 'Ne',
        CountryName: 'Níger',
    },
    {
        CityCode: '0801',
        CityName: 'Zinder',
        StateCode: '08',
        StateName: 'Zinder',
        CountryCode: 'Ne',
        CountryName: 'Níger',
    },
    {
        CityCode: '0101',
        CityName: 'Umuahia',
        StateCode: '01',
        StateName: 'Abia',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '0201',
        CityName: 'Yola',
        StateCode: '02',
        StateName: 'Adamawa',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '0301',
        CityName: 'Uyo',
        StateCode: '03',
        StateName: 'Akwa Ibom',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '0401',
        CityName: 'Awka',
        StateCode: '04',
        StateName: 'Anambra',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '0501',
        CityName: 'Bauchi',
        StateCode: '05',
        StateName: 'Bauchi',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '0601',
        CityName: 'Yenagoa',
        StateCode: '06',
        StateName: 'Bayelsa',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '0701',
        CityName: 'Makurdi',
        StateCode: '07',
        StateName: 'Benue',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '0801',
        CityName: 'Maiduguri',
        StateCode: '08',
        StateName: 'Borno',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '0901',
        CityName: 'Calabar',
        StateCode: '09',
        StateName: 'Cross River',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '1001',
        CityName: 'Asaba',
        StateCode: '10',
        StateName: 'Delta',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '1101',
        CityName: 'Abakaliki',
        StateCode: '11',
        StateName: 'Ebonyi',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '1201',
        CityName: 'Benin City',
        StateCode: '12',
        StateName: 'Edo',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '1301',
        CityName: 'Ado Ekiti',
        StateCode: '13',
        StateName: 'Ekiti',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '1401',
        CityName: 'Enugu',
        StateCode: '14',
        StateName: 'Enugu',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '1501',
        CityName: 'Gombe',
        StateCode: '15',
        StateName: 'Gombe',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '1601',
        CityName: 'Owerri',
        StateCode: '16',
        StateName: 'Imo',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '1701',
        CityName: 'Dutse',
        StateCode: '17',
        StateName: 'Jigawa',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '1801',
        CityName: 'Kaduna',
        StateCode: '18',
        StateName: 'Kaduna',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '1901',
        CityName: 'Kano',
        StateCode: '19',
        StateName: 'Kano',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '2001',
        CityName: 'Katsina',
        StateCode: '20',
        StateName: 'Katsina',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '2101',
        CityName: 'Birnin Kebbi',
        StateCode: '21',
        StateName: 'Kebbi',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '2201',
        CityName: 'Lokoja',
        StateCode: '22',
        StateName: 'Kogi',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '2301',
        CityName: 'Ilorin',
        StateCode: '23',
        StateName: 'Kwara',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '2401',
        CityName: 'Ikeja',
        StateCode: '24',
        StateName: 'Lagos',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '2501',
        CityName: 'Lafia',
        StateCode: '25',
        StateName: 'Nasarawa',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '2601',
        CityName: 'Minna',
        StateCode: '26',
        StateName: 'Níger',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '2701',
        CityName: 'Abeokuta',
        StateCode: '27',
        StateName: 'Ogun',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '2801',
        CityName: 'Akure',
        StateCode: '28',
        StateName: 'Ondo',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '2901',
        CityName: 'Osogbo',
        StateCode: '29',
        StateName: 'Osun',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '3001',
        CityName: 'Ibadán',
        StateCode: '30',
        StateName: 'Oyo',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '3101',
        CityName: 'Jos',
        StateCode: '31',
        StateName: 'Plateau',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '3201',
        CityName: 'Port Harcourt',
        StateCode: '32',
        StateName: 'Rivers',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '3301',
        CityName: 'Sokoto',
        StateCode: '33',
        StateName: 'Sokoto',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '3401',
        CityName: 'Jalingo',
        StateCode: '34',
        StateName: 'Taraba',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '3501',
        CityName: 'Damaturu',
        StateCode: '35',
        StateName: 'Yobe',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '3601',
        CityName: 'Gusau',
        StateCode: '36',
        StateName: 'Zamfara',
        CountryCode: 'Ng',
        CountryName: 'Nigeria',
    },
    {
        CityCode: '0101',
        CityName: 'Alofi',
        StateCode: '01',
        StateName: 'Alofi',
        CountryCode: 'Nu',
        CountryName: 'Niue',
    },
    {
        CityCode: '0101',
        CityName: 'Oslonota',
        StateCode: '01',
        StateName: 'Akershus',
        CountryCode: 'No',
        CountryName: 'Noruega',
    },
    {
        CityCode: '0201',
        CityName: 'Arendal',
        StateCode: '02',
        StateName: 'Aust-Agder',
        CountryCode: 'No',
        CountryName: 'Noruega',
    },
    {
        CityCode: '0301',
        CityName: 'Drammen',
        StateCode: '03',
        StateName: 'Buskerud',
        CountryCode: 'No',
        CountryName: 'Noruega',
    },
    {
        CityCode: '0401',
        CityName: 'Vadsø',
        StateCode: '04',
        StateName: 'Finnmark',
        CountryCode: 'No',
        CountryName: 'Noruega',
    },
    {
        CityCode: '0501',
        CityName: 'Hamar',
        StateCode: '05',
        StateName: 'Hedmark',
        CountryCode: 'No',
        CountryName: 'Noruega',
    },
    {
        CityCode: '0601',
        CityName: 'Bergen',
        StateCode: '06',
        StateName: 'Hordaland',
        CountryCode: 'No',
        CountryName: 'Noruega',
    },
    {
        CityCode: '0701',
        CityName: 'Molde',
        StateCode: '07',
        StateName: 'Møre og Romsdal',
        CountryCode: 'No',
        CountryName: 'Noruega',
    },
    {
        CityCode: '0801',
        CityName: 'Steinkjer',
        StateCode: '08',
        StateName: 'Nord-Trøndelag',
        CountryCode: 'No',
        CountryName: 'Noruega',
    },
    {
        CityCode: '0901',
        CityName: 'Bodø',
        StateCode: '09',
        StateName: 'Nordland',
        CountryCode: 'No',
        CountryName: 'Noruega',
    },
    {
        CityCode: '1001',
        CityName: 'Lillehammer',
        StateCode: '10',
        StateName: 'Oppland',
        CountryCode: 'No',
        CountryName: 'Noruega',
    },
    {
        CityCode: '1101',
        CityName: 'Oslo',
        StateCode: '11',
        StateName: 'Oslo',
        CountryCode: 'No',
        CountryName: 'Noruega',
    },
    {
        CityCode: '1201',
        CityName: 'Stavanger',
        StateCode: '12',
        StateName: 'Rogaland',
        CountryCode: 'No',
        CountryName: 'Noruega',
    },
    {
        CityCode: '1301',
        CityName: 'Hermansverk',
        StateCode: '13',
        StateName: 'Sogn og Fjordane',
        CountryCode: 'No',
        CountryName: 'Noruega',
    },
    {
        CityCode: '1401',
        CityName: 'Trondheim',
        StateCode: '14',
        StateName: 'Sør-Trøndelag',
        CountryCode: 'No',
        CountryName: 'Noruega',
    },
    {
        CityCode: '1501',
        CityName: 'Skien',
        StateCode: '15',
        StateName: 'Telemark',
        CountryCode: 'No',
        CountryName: 'Noruega',
    },
    {
        CityCode: '1601',
        CityName: 'Tromsø',
        StateCode: '16',
        StateName: 'Troms',
        CountryCode: 'No',
        CountryName: 'Noruega',
    },
    {
        CityCode: '1701',
        CityName: 'Kristiansand',
        StateCode: '17',
        StateName: 'Vest-Agder',
        CountryCode: 'No',
        CountryName: 'Noruega',
    },
    {
        CityCode: '1801',
        CityName: 'Tønsberg',
        StateCode: '18',
        StateName: 'Vestfold',
        CountryCode: 'No',
        CountryName: 'Noruega',
    },
    {
        CityCode: '1901',
        CityName: 'Sarpsborg',
        StateCode: '19',
        StateName: 'Østfold',
        CountryCode: 'No',
        CountryName: 'Noruega',
    },
    {
        CityCode: '0101',
        CityName: 'Numea',
        StateCode: '01',
        StateName: 'Numea',
        CountryCode: 'Nc',
        CountryName: 'Nueva Caledonia',
    },
    {
        CityCode: '0101',
        CityName: 'Whangarei',
        StateCode: '01',
        StateName: 'Northland',
        CountryCode: 'Nz',
        CountryName: 'Nueva Zelanda',
    },
    {
        CityCode: '0201',
        CityName: 'Auckland',
        StateCode: '02',
        StateName: 'Auckland',
        CountryCode: 'Nz',
        CountryName: 'Nueva Zelanda',
    },
    {
        CityCode: '0301',
        CityName: 'Hamilton',
        StateCode: '03',
        StateName: 'Waikato',
        CountryCode: 'Nz',
        CountryName: 'Nueva Zelanda',
    },
    {
        CityCode: '0401',
        CityName: 'Tauranga',
        StateCode: '04',
        StateName: 'Bay of Plenty',
        CountryCode: 'Nz',
        CountryName: 'Nueva Zelanda',
    },
    {
        CityCode: '0501',
        CityName: 'Gisborne',
        StateCode: '05',
        StateName: 'Gisborne East Cape',
        CountryCode: 'Nz',
        CountryName: 'Nueva Zelanda',
    },
    {
        CityCode: '0601',
        CityName: 'Napier',
        StateCode: '06',
        StateName: "Hawke's Bay",
        CountryCode: 'Nz',
        CountryName: 'Nueva Zelanda',
    },
    {
        CityCode: '0701',
        CityName: 'Nueva Plymouth',
        StateCode: '07',
        StateName: 'Taranaki',
        CountryCode: 'Nz',
        CountryName: 'Nueva Zelanda',
    },
    {
        CityCode: '0801',
        CityName: 'Palmerston North',
        StateCode: '08',
        StateName: 'Manawatu-Wanganui',
        CountryCode: 'Nz',
        CountryName: 'Nueva Zelanda',
    },
    {
        CityCode: '0901',
        CityName: 'Wellington',
        StateCode: '09',
        StateName: 'Wellington',
        CountryCode: 'Nz',
        CountryName: 'Nueva Zelanda',
    },
    {
        CityCode: '1001',
        CityName: 'Richmond',
        StateCode: '10',
        StateName: 'Tasman',
        CountryCode: 'Nz',
        CountryName: 'Nueva Zelanda',
    },
    {
        CityCode: '1101',
        CityName: 'Nelson',
        StateCode: '11',
        StateName: 'Nelson',
        CountryCode: 'Nz',
        CountryName: 'Nueva Zelanda',
    },
    {
        CityCode: '1201',
        CityName: 'Blenheim',
        StateCode: '12',
        StateName: 'Marlborough',
        CountryCode: 'Nz',
        CountryName: 'Nueva Zelanda',
    },
    {
        CityCode: '1301',
        CityName: 'Greymouth',
        StateCode: '13',
        StateName: 'West Coast',
        CountryCode: 'Nz',
        CountryName: 'Nueva Zelanda',
    },
    {
        CityCode: '1401',
        CityName: 'Christchurch',
        StateCode: '14',
        StateName: 'Canterbury',
        CountryCode: 'Nz',
        CountryName: 'Nueva Zelanda',
    },
    {
        CityCode: '1501',
        CityName: 'Dunedin',
        StateCode: '15',
        StateName: 'Otago',
        CountryCode: 'Nz',
        CountryName: 'Nueva Zelanda',
    },
    {
        CityCode: '1601',
        CityName: 'Invercargill',
        StateCode: '16',
        StateName: 'Southland',
        CountryCode: 'Nz',
        CountryName: 'Nueva Zelanda',
    },
    {
        CityCode: '0101',
        CityName: 'Nizwa',
        StateCode: '01',
        StateName: 'Interior ad-Dakhiliyah',
        CountryCode: 'Om',
        CountryName: 'Omán',
    },
    {
        CityCode: '0201',
        CityName: 'Suhar',
        StateCode: '02',
        StateName: 'Batina',
        CountryCode: 'Om',
        CountryName: 'Omán',
    },
    {
        CityCode: '0301',
        CityName: 'Haima Hayma',
        StateCode: '03',
        StateName: 'Central al-Wusta',
        CountryCode: 'Om',
        CountryName: 'Omán',
    },
    {
        CityCode: '0401',
        CityName: 'Sur',
        StateCode: '04',
        StateName: 'Oriental ash-Sharqiyah',
        CountryCode: 'Om',
        CountryName: 'Omán',
    },
    {
        CityCode: '0501',
        CityName: 'Ibri',
        StateCode: '05',
        StateName: 'Dhahirah',
        CountryCode: 'Om',
        CountryName: 'Omán',
    },
    {
        CityCode: '0601',
        CityName: 'Sib',
        StateCode: '06',
        StateName: 'Mascate',
        CountryCode: 'Om',
        CountryName: 'Omán',
    },
    {
        CityCode: '0701',
        CityName: 'Jasab',
        StateCode: '07',
        StateName: 'Musandam',
        CountryCode: 'Om',
        CountryName: 'Omán',
    },
    {
        CityCode: '0801',
        CityName: 'Salalah',
        StateCode: '08',
        StateName: 'Dhofar',
        CountryCode: 'Om',
        CountryName: 'Omán',
    },
    {
        CityCode: '0901',
        CityName: 'Buraimi',
        StateCode: '09',
        StateName: 'Buraimi',
        CountryCode: 'Om',
        CountryName: 'Omán',
    },
    {
        CityCode: '0101',
        CityName: 'Bolduquen',
        StateCode: '01',
        StateName: 'Brabante SeptentrionalNoord-Brabant',
        CountryCode: 'Nl',
        CountryName: 'Países Bajos',
    },
    {
        CityCode: '0201',
        CityName: 'Assen',
        StateCode: '02',
        StateName: 'Drente Drenthe',
        CountryCode: 'Nl',
        CountryName: 'Países Bajos',
    },
    {
        CityCode: '0301',
        CityName: 'Lelystad',
        StateCode: '03',
        StateName: 'Flevoland',
        CountryCode: 'Nl',
        CountryName: 'Países Bajos',
    },
    {
        CityCode: '0401',
        CityName: 'Leeuwarden',
        StateCode: '04',
        StateName: 'Frisia Friesland, frisio occidental: Fryslân',
        CountryCode: 'Nl',
        CountryName: 'Países Bajos',
    },
    {
        CityCode: '0501',
        CityName: 'Arnhem',
        StateCode: '05',
        StateName: 'GüeldresGelderland',
        CountryCode: 'Nl',
        CountryName: 'Países Bajos',
    },
    {
        CityCode: '0601',
        CityName: 'Groninga',
        StateCode: '06',
        StateName: 'GroningaGroningen, groningués: Grönnen; frisio occ',
        CountryCode: 'Nl',
        CountryName: 'Países Bajos',
    },
    {
        CityCode: '0701',
        CityName: 'La Hayan',
        StateCode: '07',
        StateName: 'Holanda Meridional Zuid-Holland',
        CountryCode: 'Nl',
        CountryName: 'Países Bajos',
    },
    {
        CityCode: '0801',
        CityName: 'Haarlem',
        StateCode: '08',
        StateName: 'Holanda SeptentrionalNoord-Holland',
        CountryCode: 'Nl',
        CountryName: 'Países Bajos',
    },
    {
        CityCode: '0901',
        CityName: 'Maastricht',
        StateCode: '09',
        StateName: 'LimburgoLimburg',
        CountryCode: 'Nl',
        CountryName: 'Países Bajos',
    },
    {
        CityCode: '1001',
        CityName: 'Zwolle',
        StateCode: '10',
        StateName: 'Overijssel',
        CountryCode: 'Nl',
        CountryName: 'Países Bajos',
    },
    {
        CityCode: '1101',
        CityName: 'Utrecht',
        StateCode: '11',
        StateName: 'Utrecht',
        CountryCode: 'Nl',
        CountryName: 'Países Bajos',
    },
    {
        CityCode: '1201',
        CityName: 'Middelburg',
        StateCode: '12',
        StateName: 'ZelandaZeeland',
        CountryCode: 'Nl',
        CountryName: 'Países Bajos',
    },
    {
        CityCode: '0101',
        CityName: 'Quetta',
        StateCode: '01',
        StateName: 'Baluchistán',
        CountryCode: 'Pk',
        CountryName: 'Pakistán',
    },
    {
        CityCode: '0201',
        CityName: 'Peshawar',
        StateCode: '02',
        StateName: 'Jaiber Pajtunjuá',
        CountryCode: 'Pk',
        CountryName: 'Pakistán',
    },
    {
        CityCode: '0301',
        CityName: 'Lahore',
        StateCode: '03',
        StateName: 'Panyab',
        CountryCode: 'Pk',
        CountryName: 'Pakistán',
    },
    {
        CityCode: '0401',
        CityName: 'Karachi',
        StateCode: '04',
        StateName: 'Sind',
        CountryCode: 'Pk',
        CountryName: 'Pakistán',
    },
    {
        CityCode: '0501',
        CityName: 'Islamabad',
        StateCode: '05',
        StateName: 'Islamabad',
        CountryCode: 'Pk',
        CountryName: 'Pakistán',
    },
    {
        CityCode: '0601',
        CityName: 'Peshawar',
        StateCode: '06',
        StateName: 'Áreas tribales',
        CountryCode: 'Pk',
        CountryName: 'Pakistán',
    },
    {
        CityCode: '0701',
        CityName: 'Muzaffarabad',
        StateCode: '07',
        StateName: 'Azad Cachemira',
        CountryCode: 'Pk',
        CountryName: 'Pakistán',
    },
    {
        CityCode: '0801',
        CityName: 'Gilgit',
        StateCode: '08',
        StateName: 'Gilgit-Baltistán',
        CountryCode: 'Pk',
        CountryName: 'Pakistán',
    },
    {
        CityCode: '0101',
        CityName: 'Ulimang',
        StateCode: '01',
        StateName: 'Aimeliik',
        CountryCode: 'Pw',
        CountryName: 'Palaos',
    },
    {
        CityCode: '0201',
        CityName: 'Ngetkib',
        StateCode: '02',
        StateName: 'Airai',
        CountryCode: 'Pw',
        CountryName: 'Palaos',
    },
    {
        CityCode: '0301',
        CityName: 'Ngeremasch',
        StateCode: '03',
        StateName: 'Angaur',
        CountryCode: 'Pw',
        CountryName: 'Palaos',
    },
    {
        CityCode: '0401',
        CityName: 'Hatohobei',
        StateCode: '04',
        StateName: 'Hatohobei',
        CountryCode: 'Pw',
        CountryName: 'Palaos',
    },
    {
        CityCode: '0501',
        CityName: 'Kayangel',
        StateCode: '05',
        StateName: 'Kayangel',
        CountryCode: 'Pw',
        CountryName: 'Palaos',
    },
    {
        CityCode: '0601',
        CityName: 'Koror',
        StateCode: '06',
        StateName: 'Koror',
        CountryCode: 'Pw',
        CountryName: 'Palaos',
    },
    {
        CityCode: '0701',
        CityName: 'Melekeok',
        StateCode: '07',
        StateName: 'Melekeok',
        CountryCode: 'Pw',
        CountryName: 'Palaos',
    },
    {
        CityCode: '0801',
        CityName: 'Ulimang',
        StateCode: '08',
        StateName: 'Ngaraard',
        CountryCode: 'Pw',
        CountryName: 'Palaos',
    },
    {
        CityCode: '0901',
        CityName: 'Imeong',
        StateCode: '09',
        StateName: 'Ngarchelong',
        CountryCode: 'Pw',
        CountryName: 'Palaos',
    },
    {
        CityCode: '1001',
        CityName: 'Ngardmau',
        StateCode: '10',
        StateName: 'Ngardmau',
        CountryCode: 'Pw',
        CountryName: 'Palaos',
    },
    {
        CityCode: '1101',
        CityName: 'Oikuul',
        StateCode: '11',
        StateName: 'Ngatpang',
        CountryCode: 'Pw',
        CountryName: 'Palaos',
    },
    {
        CityCode: '1201',
        CityName: 'Galakasan',
        StateCode: '12',
        StateName: 'Ngchesar',
        CountryCode: 'Pw',
        CountryName: 'Palaos',
    },
    {
        CityCode: '1301',
        CityName: 'Ollei',
        StateCode: '13',
        StateName: 'Ngeremlengui',
        CountryCode: 'Pw',
        CountryName: 'Palaos',
    },
    {
        CityCode: '1401',
        CityName: 'Ngerkeai',
        StateCode: '14',
        StateName: 'Ngiwal',
        CountryCode: 'Pw',
        CountryName: 'Palaos',
    },
    {
        CityCode: '1501',
        CityName: 'Kloulklubed',
        StateCode: '15',
        StateName: 'Peleliu',
        CountryCode: 'Pw',
        CountryName: 'Palaos',
    },
    {
        CityCode: '1601',
        CityName: 'Sonsora',
        StateCode: '16',
        StateName: 'Sonsora',
        CountryCode: 'Pw',
        CountryName: 'Palaos',
    },
    {
        CityCode: '0101',
        CityName: 'Yenín',
        StateCode: '01',
        StateName: 'Gobernación de Yenín',
        CountryCode: 'Ps',
        CountryName: 'Palestina',
    },
    {
        CityCode: '0201',
        CityName: 'Tubas',
        StateCode: '02',
        StateName: 'Gobernación de Tubas',
        CountryCode: 'Ps',
        CountryName: 'Palestina',
    },
    {
        CityCode: '0301',
        CityName: 'Tulkarem',
        StateCode: '03',
        StateName: 'Gobernación de Tulkarem',
        CountryCode: 'Ps',
        CountryName: 'Palestina',
    },
    {
        CityCode: '0401',
        CityName: 'Naplusa',
        StateCode: '04',
        StateName: 'Gobernación de Naplusa',
        CountryCode: 'Ps',
        CountryName: 'Palestina',
    },
    {
        CityCode: '0501',
        CityName: 'Qalqilya',
        StateCode: '05',
        StateName: 'Gobernación de Qalqilya',
        CountryCode: 'Ps',
        CountryName: 'Palestina',
    },
    {
        CityCode: '0601',
        CityName: 'Salfit',
        StateCode: '06',
        StateName: 'Gobernación de Salfit',
        CountryCode: 'Ps',
        CountryName: 'Palestina',
    },
    {
        CityCode: '0701',
        CityName: 'Ramala y Al Bireh',
        StateCode: '07',
        StateName: 'Gobernación de Ramala y Al Bireh',
        CountryCode: 'Ps',
        CountryName: 'Palestina',
    },
    {
        CityCode: '0801',
        CityName: 'Jericó',
        StateCode: '08',
        StateName: 'Gobernación de Jericó',
        CountryCode: 'Ps',
        CountryName: 'Palestina',
    },
    {
        CityCode: '0901',
        CityName: 'Jerusalén',
        StateCode: '09',
        StateName: 'Gobernación de Jerusalén',
        CountryCode: 'Ps',
        CountryName: 'Palestina',
    },
    {
        CityCode: '1001',
        CityName: 'Belén',
        StateCode: '10',
        StateName: 'Gobernación de Belén',
        CountryCode: 'Ps',
        CountryName: 'Palestina',
    },
    {
        CityCode: '1101',
        CityName: 'Hebrón',
        StateCode: '11',
        StateName: 'Gobernación de Hebrón',
        CountryCode: 'Ps',
        CountryName: 'Palestina',
    },
    {
        CityCode: '0101',
        CityName: 'Almirante',
        StateCode: '01',
        StateName: 'Almirante',
        CountryCode: 'Pa',
        CountryName: 'Panamá',
    },
    {
        CityCode: '0201',
        CityName: 'Bocas del Toro',
        StateCode: '02',
        StateName: 'Bocas del Toro',
        CountryCode: 'Pa',
        CountryName: 'Panamá',
    },
    {
        CityCode: '0301',
        CityName: 'Changuinola',
        StateCode: '03',
        StateName: 'Changuinola',
        CountryCode: 'Pa',
        CountryName: 'Panamá',
    },
    {
        CityCode: '0401',
        CityName: 'Chiriquí Grande',
        StateCode: '04',
        StateName: 'Chiriquí Grande',
        CountryCode: 'Pa',
        CountryName: 'Panamá',
    },
    {
        CityCode: '0101',
        CityName: 'Port Moresby',
        StateCode: '01',
        StateName: 'Central',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '0201',
        CityName: 'Kundiawa',
        StateCode: '02',
        StateName: 'Simbu Chimbu',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '0301',
        CityName: 'Goroka',
        StateCode: '03',
        StateName: 'Tierras Altas Orientales',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '0401',
        CityName: 'Rabaul',
        StateCode: '04',
        StateName: 'Nueva Bretaña del Este',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '0501',
        CityName: 'Wewak',
        StateCode: '05',
        StateName: 'Sepik del Este',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '0601',
        CityName: 'Webag',
        StateCode: '06',
        StateName: 'Enga',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '0701',
        CityName: 'Kerema',
        StateCode: '07',
        StateName: 'Golfo',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '0801',
        CityName: 'Madang',
        StateCode: '08',
        StateName: 'Madang',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '0901',
        CityName: 'Lorengau',
        StateCode: '09',
        StateName: 'Manus',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '1001',
        CityName: 'Alotau',
        StateCode: '10',
        StateName: 'Milne Bay',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '1101',
        CityName: 'Lae',
        StateCode: '11',
        StateName: 'Morobe',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '1201',
        CityName: 'Kavieng',
        StateCode: '12',
        StateName: 'Nueva Irlanda',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '1301',
        CityName: 'Popondetta',
        StateCode: '13',
        StateName: 'Oro',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '1401',
        CityName: 'Arawa',
        StateCode: '14',
        StateName: 'Bougainville',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '1501',
        CityName: 'Mendi',
        StateCode: '15',
        StateName: 'Tierras Altas del Sur',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '1601',
        CityName: 'Daru',
        StateCode: '16',
        StateName: 'Occidental',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '1701',
        CityName: 'Mount Hagen',
        StateCode: '17',
        StateName: 'Tierras Altas Occidentales',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '1801',
        CityName: 'Kimbe',
        StateCode: '18',
        StateName: 'Nueva Bretaña del Oeste',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '1901',
        CityName: 'Vanimo',
        StateCode: '19',
        StateName: 'Sandaun',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '2001',
        CityName: 'Port Moresby',
        StateCode: '20',
        StateName: 'Distrito Capital Nacional',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '2101',
        CityName: 'Tari',
        StateCode: '21',
        StateName: 'Hela',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '2201',
        CityName: 'Minj',
        StateCode: '22',
        StateName: 'Jiwaka',
        CountryCode: 'Pg',
        CountryName: 'Papúa Nueva Guinea',
    },
    {
        CityCode: '0101',
        CityName: 'Fuerte Olimpo',
        StateCode: '01',
        StateName: 'Alto Paraguay',
        CountryCode: 'Py',
        CountryName: 'Paraguay',
    },
    {
        CityCode: '0201',
        CityName: 'Ciudad del Este',
        StateCode: '02',
        StateName: 'Alto Paraná',
        CountryCode: 'Py',
        CountryName: 'Paraguay',
    },
    {
        CityCode: '0301',
        CityName: 'Pedro Juan Caballero',
        StateCode: '03',
        StateName: 'Amambay',
        CountryCode: 'Py',
        CountryName: 'Paraguay',
    },
    {
        CityCode: '0401',
        CityName: 'Asunción',
        StateCode: '04',
        StateName: 'Distrito Capital',
        CountryCode: 'Py',
        CountryName: 'Paraguay',
    },
    {
        CityCode: '0501',
        CityName: 'Filadelfia',
        StateCode: '05',
        StateName: 'Boquerón',
        CountryCode: 'Py',
        CountryName: 'Paraguay',
    },
    {
        CityCode: '0601',
        CityName: 'Coronel Oviedo',
        StateCode: '06',
        StateName: 'Caaguazú',
        CountryCode: 'Py',
        CountryName: 'Paraguay',
    },
    {
        CityCode: '0701',
        CityName: 'Caazapá',
        StateCode: '07',
        StateName: 'Caazapá',
        CountryCode: 'Py',
        CountryName: 'Paraguay',
    },
    {
        CityCode: '0801',
        CityName: 'Salto del Guairá',
        StateCode: '08',
        StateName: 'Canindeyú',
        CountryCode: 'Py',
        CountryName: 'Paraguay',
    },
    {
        CityCode: '0901',
        CityName: 'Areguá',
        StateCode: '09',
        StateName: 'Central',
        CountryCode: 'Py',
        CountryName: 'Paraguay',
    },
    {
        CityCode: '1001',
        CityName: 'Concepción',
        StateCode: '10',
        StateName: 'Concepción',
        CountryCode: 'Py',
        CountryName: 'Paraguay',
    },
    {
        CityCode: '1101',
        CityName: 'Caacupé',
        StateCode: '11',
        StateName: 'Cordillera',
        CountryCode: 'Py',
        CountryName: 'Paraguay',
    },
    {
        CityCode: '1201',
        CityName: 'Villarrica',
        StateCode: '12',
        StateName: 'Guairá',
        CountryCode: 'Py',
        CountryName: 'Paraguay',
    },
    {
        CityCode: '1301',
        CityName: 'Encarnación',
        StateCode: '13',
        StateName: 'Itapúa',
        CountryCode: 'Py',
        CountryName: 'Paraguay',
    },
    {
        CityCode: '1401',
        CityName: 'San Juan Bautista',
        StateCode: '14',
        StateName: 'Misiones',
        CountryCode: 'Py',
        CountryName: 'Paraguay',
    },
    {
        CityCode: '1501',
        CityName: 'Pilar',
        StateCode: '15',
        StateName: 'Ñeembucú',
        CountryCode: 'Py',
        CountryName: 'Paraguay',
    },
    {
        CityCode: '1601',
        CityName: 'Paraguarí',
        StateCode: '16',
        StateName: 'Paraguarí',
        CountryCode: 'Py',
        CountryName: 'Paraguay',
    },
    {
        CityCode: '1701',
        CityName: 'Villa Hayes',
        StateCode: '17',
        StateName: 'Presidente Hayes',
        CountryCode: 'Py',
        CountryName: 'Paraguay',
    },
    {
        CityCode: '1801',
        CityName: 'San Pedro de Ycuamandiyú',
        StateCode: '18',
        StateName: 'San Pedro',
        CountryCode: 'Py',
        CountryName: 'Paraguay',
    },
    {
        CityCode: '0101',
        CityName: 'Adamstown',
        StateCode: '01',
        StateName: 'Adamstown',
        CountryCode: 'Pn',
        CountryName: 'Pitcairn',
    },
    {
        CityCode: '0101',
        CityName: 'Papeete',
        StateCode: '01',
        StateName: 'Papeete',
        CountryCode: 'Pf',
        CountryName: 'Polinesia Francesa',
    },
    {
        CityCode: '0201',
        CityName: 'Islas Marquesas',
        StateCode: '02',
        StateName: 'La subdivisión de las Islas Marquesas',
        CountryCode: 'Pf',
        CountryName: 'Polinesia Francesa',
    },
    {
        CityCode: '0301',
        CityName: 'Tuamotu-Gambier',
        StateCode: '03',
        StateName: 'La subdivisión del Tuamotu-Gambier',
        CountryCode: 'Pf',
        CountryName: 'Polinesia Francesa',
    },
    {
        CityCode: '0401',
        CityName: 'Islas de la Sociedad',
        StateCode: '04',
        StateName: 'La subdivisión de las Islas de la Sociedad',
        CountryCode: 'Pf',
        CountryName: 'Polinesia Francesa',
    },
    {
        CityCode: '0501',
        CityName: 'Islas Australes',
        StateCode: '05',
        StateName: 'La subdivisión de las Islas Australes',
        CountryCode: 'Pf',
        CountryName: 'Polinesia Francesa',
    },
    {
        CityCode: '0101',
        CityName: 'Poznan',
        StateCode: '01',
        StateName: 'Gran Polonia',
        CountryCode: 'Pl',
        CountryName: 'Polonia',
    },
    {
        CityCode: '0201',
        CityName: 'Bydgoszcz',
        StateCode: '02',
        StateName: 'Cuyavia y Pomerania',
        CountryCode: 'Pl',
        CountryName: 'Polonia',
    },
    {
        CityCode: '0301',
        CityName: 'Cracovia',
        StateCode: '03',
        StateName: 'Pequeña Polonia',
        CountryCode: 'Pl',
        CountryName: 'Polonia',
    },
    {
        CityCode: '0401',
        CityName: 'Lódz',
        StateCode: '04',
        StateName: 'Lódz',
        CountryCode: 'Pl',
        CountryName: 'Polonia',
    },
    {
        CityCode: '0501',
        CityName: 'Breslavia',
        StateCode: '05',
        StateName: 'Baja Silesia',
        CountryCode: 'Pl',
        CountryName: 'Polonia',
    },
    {
        CityCode: '0601',
        CityName: 'Lublin',
        StateCode: '06',
        StateName: 'Lublin',
        CountryCode: 'Pl',
        CountryName: 'Polonia',
    },
    {
        CityCode: '0701',
        CityName: 'Gorzów Wielkopolski',
        StateCode: '07',
        StateName: 'Lubusz',
        CountryCode: 'Pl',
        CountryName: 'Polonia',
    },
    {
        CityCode: '0801',
        CityName: 'Varsovia',
        StateCode: '08',
        StateName: 'Mazovia',
        CountryCode: 'Pl',
        CountryName: 'Polonia',
    },
    {
        CityCode: '0901',
        CityName: 'Opole',
        StateCode: '09',
        StateName: 'Opole',
        CountryCode: 'Pl',
        CountryName: 'Polonia',
    },
    {
        CityCode: '1001',
        CityName: 'Bialystok',
        StateCode: '10',
        StateName: 'Podlaquia',
        CountryCode: 'Pl',
        CountryName: 'Polonia',
    },
    {
        CityCode: '1101',
        CityName: 'Gdansk',
        StateCode: '11',
        StateName: 'Pomerania',
        CountryCode: 'Pl',
        CountryName: 'Polonia',
    },
    {
        CityCode: '1201',
        CityName: 'Katowice',
        StateCode: '12',
        StateName: 'Silesia',
        CountryCode: 'Pl',
        CountryName: 'Polonia',
    },
    {
        CityCode: '1301',
        CityName: 'Rzeszów',
        StateCode: '13',
        StateName: 'Subcarpacia',
        CountryCode: 'Pl',
        CountryName: 'Polonia',
    },
    {
        CityCode: '1401',
        CityName: 'Kielce',
        StateCode: '14',
        StateName: 'Santa Cruz',
        CountryCode: 'Pl',
        CountryName: 'Polonia',
    },
    {
        CityCode: '1501',
        CityName: 'Olsztyn',
        StateCode: '15',
        StateName: 'Varmia y Masuria',
        CountryCode: 'Pl',
        CountryName: 'Polonia',
    },
    {
        CityCode: '1601',
        CityName: 'Szczecin',
        StateCode: '16',
        StateName: 'Pomerania Occidental',
        CountryCode: 'Pl',
        CountryName: 'Polonia',
    },
    {
        CityCode: '0101',
        CityName: 'Lisboa',
        StateCode: '01',
        StateName: 'Lisboa',
        CountryCode: 'Pt',
        CountryName: 'Portugal',
    },
    {
        CityCode: '0201',
        CityName: 'Leiria',
        StateCode: '02',
        StateName: 'Leiria',
        CountryCode: 'Pt',
        CountryName: 'Portugal',
    },
    {
        CityCode: '0301',
        CityName: 'Santarém',
        StateCode: '03',
        StateName: 'Santarém',
        CountryCode: 'Pt',
        CountryName: 'Portugal',
    },
    {
        CityCode: '0401',
        CityName: 'Setúbal',
        StateCode: '04',
        StateName: 'Setúbal',
        CountryCode: 'Pt',
        CountryName: 'Portugal',
    },
    {
        CityCode: '0501',
        CityName: 'Beja',
        StateCode: '05',
        StateName: 'Beja',
        CountryCode: 'Pt',
        CountryName: 'Portugal',
    },
    {
        CityCode: '0601',
        CityName: 'Faro',
        StateCode: '06',
        StateName: 'Faro',
        CountryCode: 'Pt',
        CountryName: 'Portugal',
    },
    {
        CityCode: '0701',
        CityName: 'Évora',
        StateCode: '07',
        StateName: 'Évora',
        CountryCode: 'Pt',
        CountryName: 'Portugal',
    },
    {
        CityCode: '0801',
        CityName: 'Portalegre',
        StateCode: '08',
        StateName: 'Portalegre',
        CountryCode: 'Pt',
        CountryName: 'Portugal',
    },
    {
        CityCode: '0901',
        CityName: 'Castelo Branco',
        StateCode: '09',
        StateName: 'Castelo Branco',
        CountryCode: 'Pt',
        CountryName: 'Portugal',
    },
    {
        CityCode: '0101',
        CityName: 'San Juan',
        StateCode: '01',
        StateName: 'San Juan',
        CountryCode: 'Pr',
        CountryName: 'Puerto Rico',
    },
    {
        CityCode: '0101',
        CityName: 'Doha',
        StateCode: '01',
        StateName: 'Doha',
        CountryCode: 'Qa',
        CountryName: 'Qatar',
    },
    {
        CityCode: '0201',
        CityName: 'Ad Dawhah',
        StateCode: '02',
        StateName: 'Ad Dawhah',
        CountryCode: 'Qa',
        CountryName: 'Qatar',
    },
    {
        CityCode: '0301',
        CityName: 'Al Ghuwariyah',
        StateCode: '03',
        StateName: 'Al Ghuwariyah',
        CountryCode: 'Qa',
        CountryName: 'Qatar',
    },
    {
        CityCode: '0401',
        CityName: 'Al Jumaliyah',
        StateCode: '04',
        StateName: 'Al Jumaliyah',
        CountryCode: 'Qa',
        CountryName: 'Qatar',
    },
    {
        CityCode: '0501',
        CityName: 'Al Khawr',
        StateCode: '05',
        StateName: 'Al Khawr',
        CountryCode: 'Qa',
        CountryName: 'Qatar',
    },
    {
        CityCode: '0601',
        CityName: 'Al Wakrah',
        StateCode: '06',
        StateName: 'Al Wakrah',
        CountryCode: 'Qa',
        CountryName: 'Qatar',
    },
    {
        CityCode: '0701',
        CityName: 'Ar Rayyan',
        StateCode: '07',
        StateName: 'Ar Rayyan',
        CountryCode: 'Qa',
        CountryName: 'Qatar',
    },
    {
        CityCode: '0801',
        CityName: 'Jariyan al Batnah',
        StateCode: '08',
        StateName: 'Jariyan al Batnah',
        CountryCode: 'Qa',
        CountryName: 'Qatar',
    },
    {
        CityCode: '0901',
        CityName: 'Madinat Ash Shamal',
        StateCode: '09',
        StateName: 'Madinat Ash Shamal',
        CountryCode: 'Qa',
        CountryName: 'Qatar',
    },
    {
        CityCode: '1001',
        CityName: 'Umm Salal',
        StateCode: '10',
        StateName: 'Umm Salal',
        CountryCode: 'Qa',
        CountryName: 'Qatar',
    },
    {
        CityCode: '1101',
        CityName: 'Mesaieed',
        StateCode: '11',
        StateName: 'Mesaieed',
        CountryCode: 'Qa',
        CountryName: 'Qatar',
    },
    {
        CityCode: '0101',
        CityName: 'Londres',
        StateCode: '01',
        StateName: 'Inglaterra',
        CountryCode: 'Gb',
        CountryName: 'Reino Unido',
    },
    {
        CityCode: '0201',
        CityName: 'Cardiff',
        StateCode: '02',
        StateName: 'Gales',
        CountryCode: 'Gb',
        CountryName: 'Reino Unido',
    },
    {
        CityCode: '0301',
        CityName: 'Belfast',
        StateCode: '03',
        StateName: 'Irlanda del Norte',
        CountryCode: 'Gb',
        CountryName: 'Reino Unido',
    },
    {
        CityCode: '0101',
        CityName: 'Bangui',
        StateCode: '01',
        StateName: 'Bangui',
        CountryCode: 'Cf',
        CountryName: 'República Centroafricana',
    },
    {
        CityCode: '0201',
        CityName: 'Ndélé',
        StateCode: '02',
        StateName: 'Bamingui-Bangoran',
        CountryCode: 'Cf',
        CountryName: 'República Centroafricana',
    },
    {
        CityCode: '0301',
        CityName: 'Mobaye',
        StateCode: '03',
        StateName: 'Basse-Kotto',
        CountryCode: 'Cf',
        CountryName: 'República Centroafricana',
    },
    {
        CityCode: '0401',
        CityName: 'Bria',
        StateCode: '04',
        StateName: 'Haute-Kotto',
        CountryCode: 'Cf',
        CountryName: 'República Centroafricana',
    },
    {
        CityCode: '0501',
        CityName: 'Obo',
        StateCode: '05',
        StateName: 'Haut-Mbomou',
        CountryCode: 'Cf',
        CountryName: 'República Centroafricana',
    },
    {
        CityCode: '0601',
        CityName: 'Sibut',
        StateCode: '06',
        StateName: 'Kémo',
        CountryCode: 'Cf',
        CountryName: 'República Centroafricana',
    },
    {
        CityCode: '0701',
        CityName: 'Mbaïki',
        StateCode: '07',
        StateName: 'Lobaye',
        CountryCode: 'Cf',
        CountryName: 'República Centroafricana',
    },
    {
        CityCode: '0801',
        CityName: 'Berbérati',
        StateCode: '08',
        StateName: 'Mambéré-Kadéï',
        CountryCode: 'Cf',
        CountryName: 'República Centroafricana',
    },
    {
        CityCode: '0901',
        CityName: 'Bangassou',
        StateCode: '09',
        StateName: 'Mbomou',
        CountryCode: 'Cf',
        CountryName: 'República Centroafricana',
    },
    {
        CityCode: '1001',
        CityName: 'Bouar',
        StateCode: '10',
        StateName: 'Nana-Mambéré',
        CountryCode: 'Cf',
        CountryName: 'República Centroafricana',
    },
    {
        CityCode: '1101',
        CityName: 'Bimbo',
        StateCode: '11',
        StateName: "Ombella-M'Poko",
        CountryCode: 'Cf',
        CountryName: 'República Centroafricana',
    },
    {
        CityCode: '1201',
        CityName: 'Bambari',
        StateCode: '12',
        StateName: 'Ouaka',
        CountryCode: 'Cf',
        CountryName: 'República Centroafricana',
    },
    {
        CityCode: '1301',
        CityName: 'Bossangoa',
        StateCode: '13',
        StateName: 'Ouham',
        CountryCode: 'Cf',
        CountryName: 'República Centroafricana',
    },
    {
        CityCode: '1401',
        CityName: 'Bozoum',
        StateCode: '14',
        StateName: 'Ouham-Pendé',
        CountryCode: 'Cf',
        CountryName: 'República Centroafricana',
    },
    {
        CityCode: '1501',
        CityName: 'Birao',
        StateCode: '15',
        StateName: 'Vakaga',
        CountryCode: 'Cf',
        CountryName: 'República Centroafricana',
    },
    {
        CityCode: '0101',
        CityName: 'Praga',
        StateCode: '01',
        StateName: 'Praga',
        CountryCode: 'Cz',
        CountryName: 'República Checa',
    },
    {
        CityCode: '0201',
        CityName: 'Ceské Budejovice',
        StateCode: '02',
        StateName: 'Bohemia Meridional Jihoceský kraj',
        CountryCode: 'Cz',
        CountryName: 'República Checa',
    },
    {
        CityCode: '0301',
        CityName: 'Brno',
        StateCode: '03',
        StateName: 'Moravia Meridional Jihomoravský kraj',
        CountryCode: 'Cz',
        CountryName: 'República Checa',
    },
    {
        CityCode: '0401',
        CityName: 'Karlovy Vary',
        StateCode: '04',
        StateName: 'Karlovy Vary Karlovarský kraj',
        CountryCode: 'Cz',
        CountryName: 'República Checa',
    },
    {
        CityCode: '0501',
        CityName: 'Hradec Králové',
        StateCode: '05',
        StateName: 'Hradec Králové Královéhradecký kraj',
        CountryCode: 'Cz',
        CountryName: 'República Checa',
    },
    {
        CityCode: '0601',
        CityName: 'Liberec',
        StateCode: '06',
        StateName: 'Liberec Liberecký kraj',
        CountryCode: 'Cz',
        CountryName: 'República Checa',
    },
    {
        CityCode: '0701',
        CityName: 'Ostrava',
        StateCode: '07',
        StateName: 'Moravia-Silesia Moravskoslezský kraj',
        CountryCode: 'Cz',
        CountryName: 'República Checa',
    },
    {
        CityCode: '0801',
        CityName: 'Olomouc',
        StateCode: '08',
        StateName: 'Olomouc Olomoucký kraj',
        CountryCode: 'Cz',
        CountryName: 'República Checa',
    },
    {
        CityCode: '0901',
        CityName: 'Pardubice',
        StateCode: '09',
        StateName: 'Pardubice Pardubický kraj',
        CountryCode: 'Cz',
        CountryName: 'República Checa',
    },
    {
        CityCode: '1001',
        CityName: 'Pilsen',
        StateCode: '10',
        StateName: 'Pilsen Plzenský kraj',
        CountryCode: 'Cz',
        CountryName: 'República Checa',
    },
    {
        CityCode: '1101',
        CityName: 'Praga',
        StateCode: '11',
        StateName: 'Bohemia Central Stredoceský kraj',
        CountryCode: 'Cz',
        CountryName: 'República Checa',
    },
    {
        CityCode: '1201',
        CityName: 'Ústí nad Labem',
        StateCode: '12',
        StateName: 'Ústí nad Labem Ústecký kraj',
        CountryCode: 'Cz',
        CountryName: 'República Checa',
    },
    {
        CityCode: '1301',
        CityName: 'Jihlava',
        StateCode: '13',
        StateName: 'Vysocina Kraj Vysocina',
        CountryCode: 'Cz',
        CountryName: 'República Checa',
    },
    {
        CityCode: '1401',
        CityName: 'Zlín',
        StateCode: '14',
        StateName: 'Zlín Zlínský kraj',
        CountryCode: 'Cz',
        CountryName: 'República Checa',
    },
    {
        CityCode: '0101',
        CityName: 'Kinsasa',
        StateCode: '01',
        StateName: 'Kinsasa',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '0201',
        CityName: 'Matadi',
        StateCode: '02',
        StateName: 'Kongo central',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '0301',
        CityName: 'Kenge',
        StateCode: '03',
        StateName: 'Kwango',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '0401',
        CityName: 'Kikwit',
        StateCode: '04',
        StateName: 'Kwilu',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '0501',
        CityName: 'Inongo',
        StateCode: '05',
        StateName: 'Mai-Ndombe',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '0601',
        CityName: 'Luebo',
        StateCode: '06',
        StateName: 'Kasaï',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '0701',
        CityName: 'Kananga',
        StateCode: '07',
        StateName: 'Lulua',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '0801',
        CityName: 'Mbuji-Mayi',
        StateCode: '08',
        StateName: 'Kasaï Oriental',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '0901',
        CityName: 'Kabinda',
        StateCode: '09',
        StateName: 'Lomami',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '1001',
        CityName: 'Lodja',
        StateCode: '10',
        StateName: 'Sankuru',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '1101',
        CityName: 'Kindu',
        StateCode: '11',
        StateName: 'Maniema',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '1201',
        CityName: 'Bukavu',
        StateCode: '12',
        StateName: 'Kivu del Sur',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '1301',
        CityName: 'Goma',
        StateCode: '13',
        StateName: 'Kivu del Norte',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '1401',
        CityName: 'Bunia',
        StateCode: '14',
        StateName: 'Ituri',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '1501',
        CityName: 'Isiro',
        StateCode: '15',
        StateName: 'Alto Uele',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '1601',
        CityName: 'Kisangani',
        StateCode: '16',
        StateName: 'Tshopo',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '1701',
        CityName: 'Buta',
        StateCode: '17',
        StateName: 'Bajo Uele',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '1801',
        CityName: 'Gbadolite',
        StateCode: '18',
        StateName: 'Ubangi del Norte',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '1901',
        CityName: 'Lisala',
        StateCode: '19',
        StateName: 'Mongala',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '2001',
        CityName: 'Gemena',
        StateCode: '20',
        StateName: 'Ubangi del Sur',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '2101',
        CityName: 'Mbandaka',
        StateCode: '21',
        StateName: 'Équateur',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '2201',
        CityName: 'Boende',
        StateCode: '22',
        StateName: 'Tshuapa',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '2301',
        CityName: 'Kalemie',
        StateCode: '23',
        StateName: 'Tanganyika',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '2401',
        CityName: 'Kamina',
        StateCode: '24',
        StateName: 'Alto Lomami',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '2501',
        CityName: 'Kolwezi',
        StateCode: '25',
        StateName: 'Lualaba',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '2601',
        CityName: 'Lubumbashi',
        StateCode: '26',
        StateName: 'Alto Katanga',
        CountryCode: 'Cd',
        CountryName: 'República Democrática Del Congo',
    },
    {
        CityCode: '0101',
        CityName: 'Ciudad Azua de Compostela',
        StateCode: '01',
        StateName: 'Azua',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '0201',
        CityName: 'Neiba',
        StateCode: '02',
        StateName: 'Bahoruco',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '0301',
        CityName: 'Santa Cruz de Barahona',
        StateCode: '03',
        StateName: 'Barahona',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '0401',
        CityName: 'Ciudad Dajabón',
        StateCode: '04',
        StateName: 'Dajabón',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '0501',
        CityName: 'Santo Domingo de Guzmán',
        StateCode: '05',
        StateName: 'Distrito Nacional',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '0601',
        CityName: 'San Francisco de Macorís',
        StateCode: '06',
        StateName: 'Duarte',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '0701',
        CityName: 'Comendador de Lares',
        StateCode: '07',
        StateName: 'Elías Piña',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '0801',
        CityName: 'Santa Cruz de El Seibo',
        StateCode: '08',
        StateName: 'El Seibo',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '0901',
        CityName: 'Moca',
        StateCode: '09',
        StateName: 'Espaillat',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '1001',
        CityName: 'Hato Mayor del Rey',
        StateCode: '10',
        StateName: 'Hato Mayor',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '1101',
        CityName: 'Salcedo',
        StateCode: '11',
        StateName: 'Hermanas Mirabal',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '1201',
        CityName: 'Jimaní',
        StateCode: '12',
        StateName: 'Independencia',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '1301',
        CityName: 'Salvaleón de Higüey',
        StateCode: '13',
        StateName: 'La Altagracia',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '1401',
        CityName: 'Ciudad La Romana',
        StateCode: '14',
        StateName: 'La Romana',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '1501',
        CityName: 'Concepción de La Vega',
        StateCode: '15',
        StateName: 'La Vega',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '1601',
        CityName: 'Trinidad Sánchez Nagua',
        StateCode: '16',
        StateName: 'María Trinidad Sánchez',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '1701',
        CityName: 'Bonao',
        StateCode: '17',
        StateName: 'Monseñor Nouel',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '1801',
        CityName: 'San Fernando de Monte Cristi',
        StateCode: '18',
        StateName: 'Monte Cristi',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '1901',
        CityName: 'Ciudad Monte Plata',
        StateCode: '19',
        StateName: 'Monte Plata',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '2001',
        CityName: 'Ciudad Pedernales',
        StateCode: '20',
        StateName: 'Pedernales',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '2101',
        CityName: 'Baní',
        StateCode: '21',
        StateName: 'Peravia',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '2201',
        CityName: 'San Felipe de Puerto Plata',
        StateCode: '22',
        StateName: 'Puerto Plata',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '2301',
        CityName: 'Santa Bárbara de Samaná',
        StateCode: '23',
        StateName: 'Samaná',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '2401',
        CityName: 'Ciudad San Cristóbal',
        StateCode: '24',
        StateName: 'San Cristóbal',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '2501',
        CityName: 'Ciudad San José de Ocoa',
        StateCode: '25',
        StateName: 'San José de Ocoa',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '2601',
        CityName: 'San Juan de la Maguana',
        StateCode: '26',
        StateName: 'San Juan',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '2701',
        CityName: 'Ciudad San Pedro de Macorís',
        StateCode: '27',
        StateName: 'San Pedro de Macorís',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '2801',
        CityName: 'Cotuí',
        StateCode: '28',
        StateName: 'Sánchez Ramírez',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '2901',
        CityName: 'Santiago de los Caballeros',
        StateCode: '29',
        StateName: 'Santiago',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '3001',
        CityName: 'San Ignacio de Sabaneta',
        StateCode: '30',
        StateName: 'Santiago Rodríguez',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '3101',
        CityName: 'Santo Domingo Este',
        StateCode: '31',
        StateName: 'Santo Domingo',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '3201',
        CityName: 'Santa Cruz de Mao',
        StateCode: '32',
        StateName: 'Valverde',
        CountryCode: 'Do',
        CountryName: 'República Dominicana',
    },
    {
        CityCode: '0101',
        CityName: 'Byumba',
        StateCode: '01',
        StateName: 'Provincia Norte',
        CountryCode: 'Rw',
        CountryName: 'Ruanda',
    },
    {
        CityCode: '0201',
        CityName: 'Nyanza',
        StateCode: '02',
        StateName: 'Provincia Sur',
        CountryCode: 'Rw',
        CountryName: 'Ruanda',
    },
    {
        CityCode: '0301',
        CityName: 'Rwamagana',
        StateCode: '03',
        StateName: 'Provincia Este',
        CountryCode: 'Rw',
        CountryName: 'Ruanda',
    },
    {
        CityCode: '0401',
        CityName: 'Kibuye',
        StateCode: '04',
        StateName: 'Provincia Oeste',
        CountryCode: 'Rw',
        CountryName: 'Ruanda',
    },
    {
        CityCode: '0501',
        CityName: 'Kigali',
        StateCode: '05',
        StateName: 'Provincia de Kigali capital',
        CountryCode: 'Rw',
        CountryName: 'Ruanda',
    },
    {
        CityCode: '0101',
        CityName: 'Alba Iulia',
        StateCode: '01',
        StateName: 'Alba Fehér',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '0201',
        CityName: 'Arad',
        StateCode: '02',
        StateName: 'Arad Arad',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '0301',
        CityName: 'Arge',
        StateCode: '03',
        StateName: 'Arge',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '0401',
        CityName: 'Bacau',
        StateCode: '04',
        StateName: 'Bacau Bákó',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '0501',
        CityName: 'Bihor',
        StateCode: '05',
        StateName: 'Bihor',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '0601',
        CityName: 'Bistria-Nasaud',
        StateCode: '06',
        StateName: 'Bistria-Nasaud',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '0701',
        CityName: 'Botoani',
        StateCode: '07',
        StateName: 'Botoani',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '0801',
        CityName: 'Braov',
        StateCode: '08',
        StateName: 'Braov',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '0901',
        CityName: 'Braila',
        StateCode: '09',
        StateName: 'Braila',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '1001',
        CityName: 'Buzau',
        StateCode: '10',
        StateName: 'Buzau',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '1101',
        CityName: 'Cara-Severin',
        StateCode: '11',
        StateName: 'Cara-Severin',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '1201',
        CityName: 'Calarai',
        StateCode: '12',
        StateName: 'Calarai',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '1301',
        CityName: 'Cluj',
        StateCode: '13',
        StateName: 'Cluj',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '1401',
        CityName: 'Constana',
        StateCode: '14',
        StateName: 'Constana',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '1501',
        CityName: 'Covasna',
        StateCode: '15',
        StateName: 'Covasna',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '1601',
        CityName: 'Dâmbovia',
        StateCode: '16',
        StateName: 'Dâmbovia',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '1701',
        CityName: 'Dolj',
        StateCode: '17',
        StateName: 'Dolj',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '1801',
        CityName: 'Galai',
        StateCode: '18',
        StateName: 'Galai',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '1901',
        CityName: 'Giurgiu',
        StateCode: '19',
        StateName: 'Giurgiu',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '2001',
        CityName: 'Gorj',
        StateCode: '20',
        StateName: 'Gorj',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '2101',
        CityName: 'Harghita',
        StateCode: '21',
        StateName: 'Harghita',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '2201',
        CityName: 'Hunedoara',
        StateCode: '22',
        StateName: 'Hunedoara',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '2301',
        CityName: 'Ialomia',
        StateCode: '23',
        StateName: 'Ialomia',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '2401',
        CityName: 'Iai',
        StateCode: '24',
        StateName: 'Iai',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '2501',
        CityName: 'Ilfov',
        StateCode: '25',
        StateName: 'Ilfov',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '2601',
        CityName: 'Maramure',
        StateCode: '26',
        StateName: 'Maramure',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '2701',
        CityName: 'Mehedini',
        StateCode: '27',
        StateName: 'Mehedini',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '2801',
        CityName: 'Mure',
        StateCode: '28',
        StateName: 'Mure',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '2901',
        CityName: 'Neam',
        StateCode: '29',
        StateName: 'Neam',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '3001',
        CityName: 'Olt',
        StateCode: '30',
        StateName: 'Olt',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '3101',
        CityName: 'Prahova',
        StateCode: '31',
        StateName: 'Prahova',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '3201',
        CityName: 'Satu Mare',
        StateCode: '32',
        StateName: 'Satu Mare',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '3301',
        CityName: 'Salaj',
        StateCode: '33',
        StateName: 'Salaj',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '3401',
        CityName: 'Sibiu',
        StateCode: '34',
        StateName: 'Sibiu',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '3501',
        CityName: 'Suceava',
        StateCode: '35',
        StateName: 'Suceava',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '3601',
        CityName: 'Teleorman',
        StateCode: '36',
        StateName: 'Teleorman',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '3701',
        CityName: 'Timi',
        StateCode: '37',
        StateName: 'Timi',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '3801',
        CityName: 'Tulcea',
        StateCode: '38',
        StateName: 'Tulcea',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '3901',
        CityName: 'Vaslui',
        StateCode: '39',
        StateName: 'Vaslui',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '4001',
        CityName: 'Vâlcea',
        StateCode: '40',
        StateName: 'Vâlcea',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '4101',
        CityName: 'Vrancea',
        StateCode: '41',
        StateName: 'Vrancea',
        CountryCode: 'Ro',
        CountryName: 'Rumania',
    },
    {
        CityCode: '0101',
        CityName: 'Maikop',
        StateCode: '01',
        StateName: 'Adygea Adigueya',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '0201',
        CityName: 'Ufá',
        StateCode: '02',
        StateName: 'Bashkortostan Baskortostán',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '0301',
        CityName: 'Ulán-Udé -',
        StateCode: '03',
        StateName: 'Buryatia Buriatia',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '0401',
        CityName: 'Gorno-Altaisk -',
        StateCode: '04',
        StateName: 'Altai Republic Altái',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '0501',
        CityName: 'Majachkalá',
        StateCode: '05',
        StateName: 'Dagestan Daguestán',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '0601',
        CityName: 'Magás',
        StateCode: '06',
        StateName: 'Ingushetia Ingusetia',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '0701',
        CityName: 'Nálchik',
        StateCode: '07',
        StateName: 'Kabardino-Balkaria Kabardia-Balkaria -',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '0801',
        CityName: 'Elistá',
        StateCode: '08',
        StateName: 'Kalmykia Kalmukia',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '0901',
        CityName: 'Cherkessk',
        StateCode: '09',
        StateName: 'Karachay-Cherkessia Karacháyevo-Cherkesia',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '1001',
        CityName: 'Petrozavodsk',
        StateCode: '10',
        StateName: 'Karelia Carelia',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '1101',
        CityName: 'Syktyvkar',
        StateCode: '11',
        StateName: 'Komi Komi',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '1201',
        CityName: 'Yoshkar-Olá -',
        StateCode: '12',
        StateName: 'Mari El Marí El -',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '1301',
        CityName: 'Saransk',
        StateCode: '13',
        StateName: 'Mordovia Mordovia',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '1401',
        CityName: 'Yakutsk',
        StateCode: '14',
        StateName: 'Sakha Sajá Yakutia',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '1501',
        CityName: 'Vladikavkaz',
        StateCode: '15',
        StateName: 'North Ossetia Osetia del Norte - Alania',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '1601',
        CityName: 'Kazán',
        StateCode: '16',
        StateName: 'Tatarstan Tartaristán',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '1701',
        CityName: 'Kizil',
        StateCode: '17',
        StateName: 'Tuva Tuvá',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '1801',
        CityName: 'Izhevsk',
        StateCode: '18',
        StateName: 'Udmurtia Udmurtia',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '1901',
        CityName: 'Abakán',
        StateCode: '19',
        StateName: 'Khakassia Jakasia',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '2001',
        CityName: 'Grozni',
        StateCode: '20',
        StateName: 'the Chechen Republic Chechenia',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '2101',
        CityName: 'Cheboksary',
        StateCode: '21',
        StateName: 'Chuvashia Chuvasia',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '2201',
        CityName: 'Barnaúl',
        StateCode: '22',
        StateName: 'Altai Krai Altái',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '2301',
        CityName: 'Krasnodar',
        StateCode: '23',
        StateName: 'Krasnodar Krai Krasnodar',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '2401',
        CityName: 'Krasnoyarsk',
        StateCode: '24',
        StateName: 'Krasnoyarsk Krai Krasnoyarsk',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '2501',
        CityName: 'Vladivostok',
        StateCode: '25',
        StateName: 'Primorsky Krai Primorie',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '2601',
        CityName: 'Stávropol',
        StateCode: '26',
        StateName: 'Stavropol Krai Stávropol',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '2701',
        CityName: 'Jabárovsk',
        StateCode: '27',
        StateName: 'Khabarovsk Krai Jabárovsk',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '2801',
        CityName: 'Petropavlovsk de Kamchatka-',
        StateCode: '28',
        StateName: 'Kamchatka Oblast Kamchatka',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '2901',
        CityName: 'Perm',
        StateCode: '29',
        StateName: 'Perm Krai Perm',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '3001',
        CityName: 'Chitá',
        StateCode: '30',
        StateName: 'Zabaykalsky Krai Zabaikalie ´',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '3101',
        CityName: 'Blagovéshchensk',
        StateCode: '31',
        StateName: 'Amur Oblast Amur',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '3201',
        CityName: 'Arcángel',
        StateCode: '32',
        StateName: 'Arcángel',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '3301',
        CityName: 'Astracán',
        StateCode: '33',
        StateName: 'Astrakhan Oblast Astracán',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '3401',
        CityName: 'Bélgorod',
        StateCode: '34',
        StateName: 'Belgorod Oblast Bélgorod',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '3501',
        CityName: 'Briansk',
        StateCode: '35',
        StateName: 'Bryansk Oblast Briansk',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '3601',
        CityName: 'Vladímir',
        StateCode: '36',
        StateName: 'Vladimirskaya Oblast Vladímir',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '3701',
        CityName: 'Volgogrado',
        StateCode: '37',
        StateName: 'Volgograd Oblast Volgogrado',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '3801',
        CityName: 'Vólogda',
        StateCode: '38',
        StateName: 'Vologda oblast Vólogda',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '3901',
        CityName: 'Vorónezh',
        StateCode: '39',
        StateName: 'Vorónezh',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '4001',
        CityName: 'Ivánovo',
        StateCode: '40',
        StateName: 'Ivanovo Oblast Ivánovo',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '4101',
        CityName: 'Irkutsk',
        StateCode: '41',
        StateName: 'Irkutsk Oblast Irkutsk',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '4201',
        CityName: 'Kaliningrado',
        StateCode: '42',
        StateName: 'Kaliningrad Oblast Kaliningrado',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '4301',
        CityName: 'Kaluga',
        StateCode: '43',
        StateName: 'Kaluga Oblast Kaluga',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '4401',
        CityName: 'Kémerovo',
        StateCode: '44',
        StateName: 'Kemerovo oblast Kémerovo',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '4501',
        CityName: 'Kírov',
        StateCode: '45',
        StateName: 'Kirov Oblast Kírov',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '4601',
        CityName: 'Kostromá',
        StateCode: '46',
        StateName: 'Kostromá',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '4701',
        CityName: 'Kurgán',
        StateCode: '47',
        StateName: 'Kurgan Oblast Kurgán',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '4801',
        CityName: 'Kursk',
        StateCode: '48',
        StateName: 'Kursk Oblast Kursk',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '4901',
        CityName: 'San Petersburgo -',
        StateCode: '49',
        StateName: 'Leningrad Oblast Leningrado',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '5001',
        CityName: 'Lípetsk',
        StateCode: '50',
        StateName: 'Lípetsk',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '5101',
        CityName: 'Magadán',
        StateCode: '51',
        StateName: 'Magadan Oblast Magadán',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '5201',
        CityName: 'Moscú',
        StateCode: '52',
        StateName: 'Moscow oblast Moscú, Óblast',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '5301',
        CityName: 'Múrmansk',
        StateCode: '53',
        StateName: 'Murmansk Oblast Múrmansk',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '5401',
        CityName: 'Nizhni Nóvgorod',
        StateCode: '54',
        StateName: 'Nizhny Novgorod Region Nizhni Nóvgorod',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '5501',
        CityName: 'Nóvgorod',
        StateCode: '55',
        StateName: 'Novgorod Oblast Nóvgorod',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '5601',
        CityName: 'Novosibirsk',
        StateCode: '56',
        StateName: 'Novosibirsk oblast Novosibirsk',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '5701',
        CityName: 'Omsk',
        StateCode: '57',
        StateName: 'Omsk Oblast Omsk',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '5801',
        CityName: 'Oremburgo',
        StateCode: '58',
        StateName: 'Orenburg Oblast Oremburgo',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '5901',
        CityName: 'Oriol',
        StateCode: '59',
        StateName: 'Oryol Oblast Oriol',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '6001',
        CityName: 'Penza',
        StateCode: '60',
        StateName: 'Penza Oblast Penza',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '6101',
        CityName: 'Pskov',
        StateCode: '61',
        StateName: 'Pskov Pskov oblast Pskov',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '6201',
        CityName: 'Rostov del Don',
        StateCode: '62',
        StateName: 'Rostov Oblast Rostov',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '6301',
        CityName: 'Riazán',
        StateCode: '63',
        StateName: 'Ryazan Oblast Riazán',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '6401',
        CityName: 'Samara',
        StateCode: '64',
        StateName: 'Samara Oblast Samara',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '6501',
        CityName: 'Sarátov',
        StateCode: '65',
        StateName: 'Saratov Oblast Sarátov',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '6601',
        CityName: 'Yuzhno-Sajalinsk -',
        StateCode: '66',
        StateName: 'Sakhalin Oblast Sajalín',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '6701',
        CityName: 'Ekaterimburgo',
        StateCode: '67',
        StateName: 'Sverdlovsk Oblast Sverdlovsk',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '6801',
        CityName: 'Smolensk',
        StateCode: '68',
        StateName: 'Smolensk oblast Smolensk',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '6901',
        CityName: 'Tambov',
        StateCode: '69',
        StateName: 'Tambov Oblast Tambov',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '7001',
        CityName: 'Tver',
        StateCode: '70',
        StateName: 'Tver Oblast Tver',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '7101',
        CityName: 'Tomsk',
        StateCode: '71',
        StateName: 'Tomsk Oblast Tomsk',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '7201',
        CityName: 'Tula',
        StateCode: '72',
        StateName: 'Tula Oblast Tula',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '7301',
        CityName: 'Tiumén',
        StateCode: '73',
        StateName: 'Tyumen Oblast Tiumén',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '7401',
        CityName: 'Uliánovsk',
        StateCode: '74',
        StateName: 'Ulyanovsk Oblast Uliánovsk',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '7501',
        CityName: 'Cheliábinsk',
        StateCode: '75',
        StateName: 'Chelyabinsk Oblast Cheliábinsk',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '7601',
        CityName: 'Yaroslavl',
        StateCode: '76',
        StateName: 'Yaroslavl Oblast Yaroslavl',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '7701',
        CityName: 'Moscú, ciudad',
        StateCode: '77',
        StateName: 'Moscow Moscú',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '7801',
        CityName: 'San Petersburgo -',
        StateCode: '78',
        StateName: 'Saint Petersburg Russia San Petersburgo -',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '7901',
        CityName: 'Birobidján',
        StateCode: '79',
        StateName: 'the Jewish Autonomous Oblast Hebreo',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '8001',
        CityName: 'Narian-Mar -',
        StateCode: '80',
        StateName: 'Nenets Autonomous District Nenetsia',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '8101',
        CityName: 'Janti-Mansisk -',
        StateCode: '81',
        StateName: 'Yugra Janti-Mansi -',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '8201',
        CityName: 'Anádyr',
        StateCode: '82',
        StateName: 'Chukotka Chukotka',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '8301',
        CityName: 'Salejard',
        StateCode: '83',
        StateName: 'Yamal-Nenets Autonomous District Yamalia-Nenetsia',
        CountryCode: 'Ru',
        CountryName: 'Rusia',
    },
    {
        CityCode: '0101',
        CityName: 'La Güera',
        StateCode: '01',
        StateName: 'Provincia de Auserd',
        CountryCode: 'Eh',
        CountryName: 'Sahara Occidental',
    },
    {
        CityCode: '0201',
        CityName: 'Assa-Zag',
        StateCode: '02',
        StateName: 'Provincia de Assa-Zag',
        CountryCode: 'Eh',
        CountryName: 'Sahara Occidental',
    },
    {
        CityCode: '0301',
        CityName: 'Bojador',
        StateCode: '03',
        StateName: 'Provincia de Bojador',
        CountryCode: 'Eh',
        CountryName: 'Sahara Occidental',
    },
    {
        CityCode: '0401',
        CityName: 'El Aaiún',
        StateCode: '04',
        StateName: 'Provincia de El Aaiún',
        CountryCode: 'Eh',
        CountryName: 'Sahara Occidental',
    },
    {
        CityCode: '0501',
        CityName: 'Esmara',
        StateCode: '05',
        StateName: 'Condado de Esmara',
        CountryCode: 'Eh',
        CountryName: 'Sahara Occidental',
    },
    {
        CityCode: '0601',
        CityName: 'Dajla',
        StateCode: '06',
        StateName: 'Condado de Oued Ed-Dahab',
        CountryCode: 'Eh',
        CountryName: 'Sahara Occidental',
    },
    {
        CityCode: '0101',
        CityName: 'Afega',
        StateCode: '01',
        StateName: 'Tuamasaga',
        CountryCode: 'Ws',
        CountryName: 'Samoa',
    },
    {
        CityCode: '0201',
        CityName: 'Leulumoega',
        StateCode: '02',
        StateName: "A'ana",
        CountryCode: 'Ws',
        CountryName: 'Samoa',
    },
    {
        CityCode: '0301',
        CityName: 'Mulifanua',
        StateCode: '03',
        StateName: 'Aiga-i-le-Tai',
        CountryCode: 'Ws',
        CountryName: 'Samoa',
    },
    {
        CityCode: '0401',
        CityName: 'Lufilufi',
        StateCode: '04',
        StateName: 'Atua',
        CountryCode: 'Ws',
        CountryName: 'Samoa',
    },
    {
        CityCode: '0501',
        CityName: 'Samamea',
        StateCode: '05',
        StateName: "Va'a-o-Fonoti",
        CountryCode: 'Ws',
        CountryName: 'Samoa',
    },
    {
        CityCode: '0601',
        CityName: 'Safotulafai',
        StateCode: '06',
        StateName: "Fa'asaleleaga",
        CountryCode: 'Ws',
        CountryName: 'Samoa',
    },
    {
        CityCode: '0701',
        CityName: 'Saleaula',
        StateCode: '07',
        StateName: "Gaga'emauga",
        CountryCode: 'Ws',
        CountryName: 'Samoa',
    },
    {
        CityCode: '0801',
        CityName: 'Aopo',
        StateCode: '08',
        StateName: "Gaga'ifomauga",
        CountryCode: 'Ws',
        CountryName: 'Samoa',
    },
    {
        CityCode: '0901',
        CityName: 'Asau',
        StateCode: '09',
        StateName: 'Vaisigano',
        CountryCode: 'Ws',
        CountryName: 'Samoa',
    },
    {
        CityCode: '1001',
        CityName: "Satupa'itea",
        StateCode: '10',
        StateName: "Satupa'itea",
        CountryCode: 'Ws',
        CountryName: 'Samoa',
    },
    {
        CityCode: '1101',
        CityName: 'Vailoa',
        StateCode: '11',
        StateName: 'Palauli',
        CountryCode: 'Ws',
        CountryName: 'Samoa',
    },
    {
        CityCode: '0101',
        CityName: 'Pago Pago',
        StateCode: '01',
        StateName: 'Pago Pago',
        CountryCode: 'As',
        CountryName: 'Samoa Americana',
    },
    {
        CityCode: '0101',
        CityName: 'Gustavia',
        StateCode: '01',
        StateName: 'Gustavia',
        CountryCode: 'Bl',
        CountryName: 'San Bartolome',
    },
    {
        CityCode: '0101',
        CityName: 'Nichola Town',
        StateCode: '01',
        StateName: 'Christ Church Nichola Town',
        CountryCode: 'Kn',
        CountryName: 'San Cristóbal Y Nieves',
    },
    {
        CityCode: '0201',
        CityName: 'Anne Sandy Point',
        StateCode: '02',
        StateName: 'Saint Anne Sandy Point',
        CountryCode: 'Kn',
        CountryName: 'San Cristóbal Y Nieves',
    },
    {
        CityCode: '0301',
        CityName: 'George Basseterre',
        StateCode: '03',
        StateName: 'Saint George Basseterre',
        CountryCode: 'Kn',
        CountryName: 'San Cristóbal Y Nieves',
    },
    {
        CityCode: '0401',
        CityName: 'George Gingerland',
        StateCode: '04',
        StateName: 'Saint George Gingerland',
        CountryCode: 'Kn',
        CountryName: 'San Cristóbal Y Nieves',
    },
    {
        CityCode: '0501',
        CityName: 'James Windward',
        StateCode: '05',
        StateName: 'Saint James Windward',
        CountryCode: 'Kn',
        CountryName: 'San Cristóbal Y Nieves',
    },
    {
        CityCode: '0601',
        CityName: 'John Capisterre',
        StateCode: '06',
        StateName: 'Saint John Capisterre',
        CountryCode: 'Kn',
        CountryName: 'San Cristóbal Y Nieves',
    },
    {
        CityCode: '0701',
        CityName: 'John Figtree',
        StateCode: '07',
        StateName: 'Saint John Figtree',
        CountryCode: 'Kn',
        CountryName: 'San Cristóbal Y Nieves',
    },
    {
        CityCode: '0801',
        CityName: 'Mary Cayon',
        StateCode: '08',
        StateName: 'Saint Mary Cayon',
        CountryCode: 'Kn',
        CountryName: 'San Cristóbal Y Nieves',
    },
    {
        CityCode: '0901',
        CityName: 'Paul Capisterre',
        StateCode: '09',
        StateName: 'Saint Paul Capisterre',
        CountryCode: 'Kn',
        CountryName: 'San Cristóbal Y Nieves',
    },
    {
        CityCode: '1001',
        CityName: 'Paul Charlestown',
        StateCode: '10',
        StateName: 'Saint Paul Charlestown',
        CountryCode: 'Kn',
        CountryName: 'San Cristóbal Y Nieves',
    },
    {
        CityCode: '1101',
        CityName: 'Peter Basseterre',
        StateCode: '11',
        StateName: 'Saint Peter Basseterre',
        CountryCode: 'Kn',
        CountryName: 'San Cristóbal Y Nieves',
    },
    {
        CityCode: '1201',
        CityName: 'Thomas Lowland',
        StateCode: '12',
        StateName: 'Saint Thomas Lowland',
        CountryCode: 'Kn',
        CountryName: 'San Cristóbal Y Nieves',
    },
    {
        CityCode: '1301',
        CityName: 'Thomas Middle Island',
        StateCode: '13',
        StateName: 'Saint Thomas Middle Island',
        CountryCode: 'Kn',
        CountryName: 'San Cristóbal Y Nieves',
    },
    {
        CityCode: '1401',
        CityName: 'Trinity Palmetto Point',
        StateCode: '14',
        StateName: 'Trinity Palmetto Point',
        CountryCode: 'Kn',
        CountryName: 'San Cristóbal Y Nieves',
    },
    {
        CityCode: '0101',
        CityName: 'San Marino',
        StateCode: '01',
        StateName: 'San Marino',
        CountryCode: 'Sm',
        CountryName: 'San Marino',
    },
    {
        CityCode: '0101',
        CityName: 'Marigot',
        StateCode: '01',
        StateName: 'Marigot',
        CountryCode: 'Mf',
        CountryName: 'San Martin (Parte Francesa)',
    },
    {
        CityCode: '0101',
        CityName: 'San Pedro',
        StateCode: '01',
        StateName: 'San Pedro',
        CountryCode: 'Pm',
        CountryName: 'San Pedro Y Miquelon',
    },
    {
        CityCode: '0101',
        CityName: 'Georgetown',
        StateCode: '01',
        StateName: 'Charlotte',
        CountryCode: 'Vc',
        CountryName: 'San Vicente Y Las Granadinas',
    },
    {
        CityCode: '0201',
        CityName: 'Port Elizabeth',
        StateCode: '02',
        StateName: 'Grenadines',
        CountryCode: 'Vc',
        CountryName: 'San Vicente Y Las Granadinas',
    },
    {
        CityCode: '0301',
        CityName: 'Layou',
        StateCode: '03',
        StateName: 'Saint Andrew',
        CountryCode: 'Vc',
        CountryName: 'San Vicente Y Las Granadinas',
    },
    {
        CityCode: '0401',
        CityName: 'Chateaubelair',
        StateCode: '04',
        StateName: 'Saint David',
        CountryCode: 'Vc',
        CountryName: 'San Vicente Y Las Granadinas',
    },
    {
        CityCode: '0501',
        CityName: 'Kingstown',
        StateCode: '05',
        StateName: 'Saint George',
        CountryCode: 'Vc',
        CountryName: 'San Vicente Y Las Granadinas',
    },
    {
        CityCode: '0601',
        CityName: 'Barrouallie',
        StateCode: '06',
        StateName: 'Saint Patrick',
        CountryCode: 'Vc',
        CountryName: 'San Vicente Y Las Granadinas',
    },
    {
        CityCode: '0701',
        CityName: 'Kingstown',
        StateCode: '07',
        StateName: 'Bandera de San Vicente y las Granadinas San Vicent',
        CountryCode: 'Vc',
        CountryName: 'San Vicente Y Las Granadinas',
    },
    {
        CityCode: '0101',
        CityName: 'Jamestown',
        StateCode: '01',
        StateName: 'Jamestown',
        CountryCode: 'Sh',
        CountryName: 'Santa Helena, Ascension Y Tristan De Acuña',
    },
    {
        CityCode: '0101',
        CityName: 'Anse La Raye',
        StateCode: '01',
        StateName: 'Anse La Raye',
        CountryCode: 'Lc',
        CountryName: 'Santa Lucía',
    },
    {
        CityCode: '0201',
        CityName: 'Castries',
        StateCode: '02',
        StateName: 'Castries',
        CountryCode: 'Lc',
        CountryName: 'Santa Lucía',
    },
    {
        CityCode: '0301',
        CityName: 'Choiseul',
        StateCode: '03',
        StateName: 'Choiseul',
        CountryCode: 'Lc',
        CountryName: 'Santa Lucía',
    },
    {
        CityCode: '0401',
        CityName: 'Dauphin',
        StateCode: '04',
        StateName: 'Dauphin',
        CountryCode: 'Lc',
        CountryName: 'Santa Lucía',
    },
    {
        CityCode: '0501',
        CityName: 'Dennery',
        StateCode: '05',
        StateName: 'Dennery',
        CountryCode: 'Lc',
        CountryName: 'Santa Lucía',
    },
    {
        CityCode: '0601',
        CityName: 'Gros Islet',
        StateCode: '06',
        StateName: 'Gros Islet',
        CountryCode: 'Lc',
        CountryName: 'Santa Lucía',
    },
    {
        CityCode: '0701',
        CityName: 'Laborie',
        StateCode: '07',
        StateName: 'Laborie',
        CountryCode: 'Lc',
        CountryName: 'Santa Lucía',
    },
    {
        CityCode: '0801',
        CityName: 'Micoud',
        StateCode: '08',
        StateName: 'Micoud',
        CountryCode: 'Lc',
        CountryName: 'Santa Lucía',
    },
    {
        CityCode: '0901',
        CityName: 'Praslin',
        StateCode: '09',
        StateName: 'Praslin',
        CountryCode: 'Lc',
        CountryName: 'Santa Lucía',
    },
    {
        CityCode: '1001',
        CityName: 'Soufrière',
        StateCode: '10',
        StateName: 'Soufrière',
        CountryCode: 'Lc',
        CountryName: 'Santa Lucía',
    },
    {
        CityCode: '1101',
        CityName: 'Vieux Fort',
        StateCode: '11',
        StateName: 'Vieux Fort',
        CountryCode: 'Lc',
        CountryName: 'Santa Lucía',
    },
    {
        CityCode: '0101',
        CityName: 'Santo Tomé',
        StateCode: '01',
        StateName: 'Água Grande',
        CountryCode: 'St',
        CountryName: 'Santo Tomé Y Príncipe',
    },
    {
        CityCode: '0201',
        CityName: 'Santana',
        StateCode: '02',
        StateName: 'Cantagalo',
        CountryCode: 'St',
        CountryName: 'Santo Tomé Y Príncipe',
    },
    {
        CityCode: '0301',
        CityName: 'São João dos Angolares',
        StateCode: '03',
        StateName: 'Caué',
        CountryCode: 'St',
        CountryName: 'Santo Tomé Y Príncipe',
    },
    {
        CityCode: '0401',
        CityName: 'Neves',
        StateCode: '04',
        StateName: 'Lembá',
        CountryCode: 'St',
        CountryName: 'Santo Tomé Y Príncipe',
    },
    {
        CityCode: '0501',
        CityName: 'Guadalupe',
        StateCode: '05',
        StateName: 'Lobata',
        CountryCode: 'St',
        CountryName: 'Santo Tomé Y Príncipe',
    },
    {
        CityCode: '0601',
        CityName: 'Trindade',
        StateCode: '06',
        StateName: 'Mé-Zóchi',
        CountryCode: 'St',
        CountryName: 'Santo Tomé Y Príncipe',
    },
    {
        CityCode: '0701',
        CityName: 'Santo António',
        StateCode: '07',
        StateName: 'Pagué',
        CountryCode: 'St',
        CountryName: 'Santo Tomé Y Príncipe',
    },
    {
        CityCode: '0101',
        CityName: 'Dakar',
        StateCode: '01',
        StateName: 'Dakar',
        CountryCode: 'Sn',
        CountryName: 'Senegal',
    },
    {
        CityCode: '0201',
        CityName: 'Diourbel',
        StateCode: '02',
        StateName: 'Diourbel',
        CountryCode: 'Sn',
        CountryName: 'Senegal',
    },
    {
        CityCode: '0301',
        CityName: 'Fatick',
        StateCode: '03',
        StateName: 'Fatick',
        CountryCode: 'Sn',
        CountryName: 'Senegal',
    },
    {
        CityCode: '0401',
        CityName: 'Kaffrine',
        StateCode: '04',
        StateName: 'Kaffrine',
        CountryCode: 'Sn',
        CountryName: 'Senegal',
    },
    {
        CityCode: '0501',
        CityName: 'Kédougou',
        StateCode: '05',
        StateName: 'Kédougou',
        CountryCode: 'Sn',
        CountryName: 'Senegal',
    },
    {
        CityCode: '0601',
        CityName: 'Kolda',
        StateCode: '06',
        StateName: 'Kolda',
        CountryCode: 'Sn',
        CountryName: 'Senegal',
    },
    {
        CityCode: '0701',
        CityName: 'Louga',
        StateCode: '07',
        StateName: 'Louga',
        CountryCode: 'Sn',
        CountryName: 'Senegal',
    },
    {
        CityCode: '0801',
        CityName: 'Matam',
        StateCode: '08',
        StateName: 'Matam',
        CountryCode: 'Sn',
        CountryName: 'Senegal',
    },
    {
        CityCode: '0901',
        CityName: 'Saint-Louis',
        StateCode: '09',
        StateName: 'Saint-Louis',
        CountryCode: 'Sn',
        CountryName: 'Senegal',
    },
    {
        CityCode: '1001',
        CityName: 'Tambacounda',
        StateCode: '10',
        StateName: 'Tambacounda',
        CountryCode: 'Sn',
        CountryName: 'Senegal',
    },
    {
        CityCode: '1101',
        CityName: 'Kaolack',
        StateCode: '11',
        StateName: 'Kaolack',
        CountryCode: 'Sn',
        CountryName: 'Senegal',
    },
    {
        CityCode: '1201',
        CityName: 'Sédhiou',
        StateCode: '12',
        StateName: 'Sédhiou',
        CountryCode: 'Sn',
        CountryName: 'Senegal',
    },
    {
        CityCode: '1301',
        CityName: 'Thiès',
        StateCode: '13',
        StateName: 'Thiès',
        CountryCode: 'Sn',
        CountryName: 'Senegal',
    },
    {
        CityCode: '1401',
        CityName: 'Ziguinchor',
        StateCode: '14',
        StateName: 'Ziguinchor',
        CountryCode: 'Sn',
        CountryName: 'Senegal',
    },
    {
        CityCode: '0101',
        CityName: 'Belgrado',
        StateCode: '01',
        StateName: 'Belgrado',
        CountryCode: 'Rs',
        CountryName: 'Serbia',
    },
    {
        CityCode: '0101',
        CityName: 'Victoria',
        StateCode: '01',
        StateName: 'Victoria',
        CountryCode: 'Sc',
        CountryName: 'Seychelles',
    },
    {
        CityCode: '0101',
        CityName: 'Kenema',
        StateCode: '01',
        StateName: 'Provincia del Este',
        CountryCode: 'Sl',
        CountryName: 'Sierra Leona',
    },
    {
        CityCode: '0201',
        CityName: 'Makeni',
        StateCode: '02',
        StateName: 'Provincia del Norte',
        CountryCode: 'Sl',
        CountryName: 'Sierra Leona',
    },
    {
        CityCode: '0301',
        CityName: 'Bo',
        StateCode: '03',
        StateName: 'Provincia del Sur',
        CountryCode: 'Sl',
        CountryName: 'Sierra Leona',
    },
    {
        CityCode: '0401',
        CityName: 'Freetown',
        StateCode: '04',
        StateName: 'Área Occidental',
        CountryCode: 'Sl',
        CountryName: 'Sierra Leona',
    },
    {
        CityCode: '0101',
        CityName: 'Singapur',
        StateCode: '01',
        StateName: 'Singapur',
        CountryCode: 'Sg',
        CountryName: 'Singapur',
    },
    {
        CityCode: '0101',
        CityName: 'Philipsburg',
        StateCode: '01',
        StateName: 'Philipsburg',
        CountryCode: 'Sx',
        CountryName: 'Sint Maarten (Parte Neerlandesa)',
    },
    {
        CityCode: '0101',
        CityName: 'Daraa',
        StateCode: '01',
        StateName: "Dar'a",
        CountryCode: 'Sy',
        CountryName: 'Siria',
    },
    {
        CityCode: '0201',
        CityName: 'Deir ez-Zor',
        StateCode: '02',
        StateName: 'Deir ez-Zor',
        CountryCode: 'Sy',
        CountryName: 'Siria',
    },
    {
        CityCode: '0301',
        CityName: 'Alepo',
        StateCode: '03',
        StateName: 'Alepo',
        CountryCode: 'Sy',
        CountryName: 'Siria',
    },
    {
        CityCode: '0401',
        CityName: 'Hama',
        StateCode: '04',
        StateName: 'Hama',
        CountryCode: 'Sy',
        CountryName: 'Siria',
    },
    {
        CityCode: '0501',
        CityName: 'Hasaka',
        StateCode: '05',
        StateName: 'Hasaka',
        CountryCode: 'Sy',
        CountryName: 'Siria',
    },
    {
        CityCode: '0601',
        CityName: 'Homs',
        StateCode: '06',
        StateName: 'Homs',
        CountryCode: 'Sy',
        CountryName: 'Siria',
    },
    {
        CityCode: '0701',
        CityName: 'Idlib',
        StateCode: '07',
        StateName: 'Idlib',
        CountryCode: 'Sy',
        CountryName: 'Siria',
    },
    {
        CityCode: '0801',
        CityName: 'Quneitra',
        StateCode: '08',
        StateName: 'Quneitra',
        CountryCode: 'Sy',
        CountryName: 'Siria',
    },
    {
        CityCode: '0901',
        CityName: 'Latakia',
        StateCode: '09',
        StateName: 'Latakia',
        CountryCode: 'Sy',
        CountryName: 'Siria',
    },
    {
        CityCode: '1001',
        CityName: 'Raqqa',
        StateCode: '10',
        StateName: 'Ar-Raqqa',
        CountryCode: 'Sy',
        CountryName: 'Siria',
    },
    {
        CityCode: '1101',
        CityName: 'Damasco',
        StateCode: '11',
        StateName: 'Rif Damasco',
        CountryCode: 'Sy',
        CountryName: 'Siria',
    },
    {
        CityCode: '1201',
        CityName: 'As-Suwayda',
        StateCode: '12',
        StateName: 'Sueida',
        CountryCode: 'Sy',
        CountryName: 'Siria',
    },
    {
        CityCode: '1301',
        CityName: 'Damasco',
        StateCode: '13',
        StateName: 'Damasco',
        CountryCode: 'Sy',
        CountryName: 'Siria',
    },
    {
        CityCode: '1401',
        CityName: 'Tartus',
        StateCode: '14',
        StateName: 'Tartus',
        CountryCode: 'Sy',
        CountryName: 'Siria',
    },
    {
        CityCode: '0101',
        CityName: 'Baki',
        StateCode: '01',
        StateName: 'Awdal',
        CountryCode: 'So',
        CountryName: 'Somalia',
    },
    {
        CityCode: '0201',
        CityName: 'Xuddur',
        StateCode: '02',
        StateName: 'Bakool',
        CountryCode: 'So',
        CountryName: 'Somalia',
    },
    {
        CityCode: '0301',
        CityName: 'Mogadiscio',
        StateCode: '03',
        StateName: 'Banaadir',
        CountryCode: 'So',
        CountryName: 'Somalia',
    },
    {
        CityCode: '0401',
        CityName: 'Bender Cassim',
        StateCode: '04',
        StateName: 'Bari',
        CountryCode: 'So',
        CountryName: 'Somalia',
    },
    {
        CityCode: '0501',
        CityName: 'Baidoa',
        StateCode: '05',
        StateName: 'Bay',
        CountryCode: 'So',
        CountryName: 'Somalia',
    },
    {
        CityCode: '0601',
        CityName: 'Dusa Mareb',
        StateCode: '06',
        StateName: 'Galguduud',
        CountryCode: 'So',
        CountryName: 'Somalia',
    },
    {
        CityCode: '0701',
        CityName: 'Garbahaarey',
        StateCode: '07',
        StateName: 'Gedo',
        CountryCode: 'So',
        CountryName: 'Somalia',
    },
    {
        CityCode: '0801',
        CityName: 'Beledweyne',
        StateCode: '08',
        StateName: 'Hiiraan',
        CountryCode: 'So',
        CountryName: 'Somalia',
    },
    {
        CityCode: '0901',
        CityName: "Bu'aale",
        StateCode: '09',
        StateName: 'Middle Juba',
        CountryCode: 'So',
        CountryName: 'Somalia',
    },
    {
        CityCode: '1001',
        CityName: 'Kismaayo',
        StateCode: '10',
        StateName: 'Lower Juba',
        CountryCode: 'So',
        CountryName: 'Somalia',
    },
    {
        CityCode: '1101',
        CityName: 'Galcaio',
        StateCode: '11',
        StateName: 'Mudug',
        CountryCode: 'So',
        CountryName: 'Somalia',
    },
    {
        CityCode: '1201',
        CityName: 'Garoowe',
        StateCode: '12',
        StateName: 'Nugaal',
        CountryCode: 'So',
        CountryName: 'Somalia',
    },
    {
        CityCode: '1301',
        CityName: 'Erigavo',
        StateCode: '13',
        StateName: 'Sanaag',
        CountryCode: 'So',
        CountryName: 'Somalia',
    },
    {
        CityCode: '1401',
        CityName: 'Giohar',
        StateCode: '14',
        StateName: 'Shabeellaha Dhexe',
        CountryCode: 'So',
        CountryName: 'Somalia',
    },
    {
        CityCode: '1501',
        CityName: 'Merka',
        StateCode: '15',
        StateName: 'Shabeellaha Hoose',
        CountryCode: 'So',
        CountryName: 'Somalia',
    },
    {
        CityCode: '1601',
        CityName: 'Laascaanood',
        StateCode: '16',
        StateName: 'Sool',
        CountryCode: 'So',
        CountryName: 'Somalia',
    },
    {
        CityCode: '1701',
        CityName: 'Burao',
        StateCode: '17',
        StateName: 'Togdheer',
        CountryCode: 'So',
        CountryName: 'Somalia',
    },
    {
        CityCode: '1801',
        CityName: 'Hargeysa',
        StateCode: '18',
        StateName: 'Woqooyi Galbeed',
        CountryCode: 'So',
        CountryName: 'Somalia',
    },
    {
        CityCode: '0101',
        CityName: 'Kandy',
        StateCode: '01',
        StateName: 'Central',
        CountryCode: 'Lk',
        CountryName: 'Sri Lanka',
    },
    {
        CityCode: '0201',
        CityName: 'Trincomalee',
        StateCode: '02',
        StateName: 'Este',
        CountryCode: 'Lk',
        CountryName: 'Sri Lanka',
    },
    {
        CityCode: '0301',
        CityName: 'Anuradhapura',
        StateCode: '03',
        StateName: 'Central del Norte',
        CountryCode: 'Lk',
        CountryName: 'Sri Lanka',
    },
    {
        CityCode: '0401',
        CityName: 'Kurunegala',
        StateCode: '04',
        StateName: 'Noroeste',
        CountryCode: 'Lk',
        CountryName: 'Sri Lanka',
    },
    {
        CityCode: '0501',
        CityName: 'Jaffna',
        StateCode: '05',
        StateName: 'Norte',
        CountryCode: 'Lk',
        CountryName: 'Sri Lanka',
    },
    {
        CityCode: '0601',
        CityName: 'Ratnapura',
        StateCode: '06',
        StateName: 'Sabaragamuwa',
        CountryCode: 'Lk',
        CountryName: 'Sri Lanka',
    },
    {
        CityCode: '0701',
        CityName: 'Galle',
        StateCode: '07',
        StateName: 'Sur',
        CountryCode: 'Lk',
        CountryName: 'Sri Lanka',
    },
    {
        CityCode: '0801',
        CityName: 'Badulla',
        StateCode: '08',
        StateName: 'Uva',
        CountryCode: 'Lk',
        CountryName: 'Sri Lanka',
    },
    {
        CityCode: '0901',
        CityName: 'Sri Jayawardenapura Kotte',
        StateCode: '09',
        StateName: 'Oeste',
        CountryCode: 'Lk',
        CountryName: 'Sri Lanka',
    },
    {
        CityCode: '0101',
        CityName: 'Mbabane',
        StateCode: '01',
        StateName: 'Hhohho',
        CountryCode: 'Sz',
        CountryName: 'Suazilandia',
    },
    {
        CityCode: '0201',
        CityName: 'Siteki',
        StateCode: '02',
        StateName: 'Lubombo',
        CountryCode: 'Sz',
        CountryName: 'Suazilandia',
    },
    {
        CityCode: '0301',
        CityName: 'Manzini',
        StateCode: '03',
        StateName: 'Manzini',
        CountryCode: 'Sz',
        CountryName: 'Suazilandia',
    },
    {
        CityCode: '0401',
        CityName: 'Nhlangano',
        StateCode: '04',
        StateName: 'Shishelweni',
        CountryCode: 'Sz',
        CountryName: 'Suazilandia',
    },
    {
        CityCode: '0101',
        CityName: 'Kimberley',
        StateCode: '01',
        StateName: 'Cabo del Norte',
        CountryCode: 'Za',
        CountryName: 'Sudáfrica',
    },
    {
        CityCode: '0201',
        CityName: 'Ciudad del Cabo',
        StateCode: '02',
        StateName: 'Cabo Occidental',
        CountryCode: 'Za',
        CountryName: 'Sudáfrica',
    },
    {
        CityCode: '0301',
        CityName: 'Bhisho',
        StateCode: '03',
        StateName: 'Cabo Oriental',
        CountryCode: 'Za',
        CountryName: 'Sudáfrica',
    },
    {
        CityCode: '0401',
        CityName: 'Bloemfontein',
        StateCode: '04',
        StateName: 'Estado Libre',
        CountryCode: 'Za',
        CountryName: 'Sudáfrica',
    },
    {
        CityCode: '0501',
        CityName: 'Johannesburgo',
        StateCode: '05',
        StateName: 'Gauteng',
        CountryCode: 'Za',
        CountryName: 'Sudáfrica',
    },
    {
        CityCode: '0601',
        CityName: 'Pietermaritzburg',
        StateCode: '06',
        StateName: 'KwaZulu-Natal',
        CountryCode: 'Za',
        CountryName: 'Sudáfrica',
    },
    {
        CityCode: '0701',
        CityName: 'Polokwane',
        StateCode: '07',
        StateName: 'Limpopo',
        CountryCode: 'Za',
        CountryName: 'Sudáfrica',
    },
    {
        CityCode: '0801',
        CityName: 'Nelspruit',
        StateCode: '08',
        StateName: 'Mpumalanga',
        CountryCode: 'Za',
        CountryName: 'Sudáfrica',
    },
    {
        CityCode: '0901',
        CityName: 'Mafikeng',
        StateCode: '09',
        StateName: 'Noroeste',
        CountryCode: 'Za',
        CountryName: 'Sudáfrica',
    },
    {
        CityCode: '0101',
        CityName: 'Jartum',
        StateCode: '01',
        StateName: 'Jartum',
        CountryCode: 'Sd',
        CountryName: 'Sudán',
    },
    {
        CityCode: '0201',
        CityName: 'Kordofán del Norte',
        StateCode: '02',
        StateName: 'Kordofán del Norte',
        CountryCode: 'Sd',
        CountryName: 'Sudán',
    },
    {
        CityCode: '0301',
        CityName: 'Norte',
        StateCode: '03',
        StateName: 'Norte',
        CountryCode: 'Sd',
        CountryName: 'Sudán',
    },
    {
        CityCode: '0401',
        CityName: 'Kassala',
        StateCode: '04',
        StateName: 'Kassala',
        CountryCode: 'Sd',
        CountryName: 'Sudán',
    },
    {
        CityCode: '0501',
        CityName: 'Nilo Azul',
        StateCode: '05',
        StateName: 'Nilo Azul',
        CountryCode: 'Sd',
        CountryName: 'Sudán',
    },
    {
        CityCode: '0601',
        CityName: 'Darfur del Norte',
        StateCode: '06',
        StateName: 'Darfur del Norte',
        CountryCode: 'Sd',
        CountryName: 'Sudán',
    },
    {
        CityCode: '0701',
        CityName: 'Darfur del Sur',
        StateCode: '07',
        StateName: 'Darfur del Sur',
        CountryCode: 'Sd',
        CountryName: 'Sudán',
    },
    {
        CityCode: '0801',
        CityName: 'Kordofán del Sur',
        StateCode: '08',
        StateName: 'Kordofán del Sur',
        CountryCode: 'Sd',
        CountryName: 'Sudán',
    },
    {
        CityCode: '0901',
        CityName: 'Gezira',
        StateCode: '09',
        StateName: 'Gezira',
        CountryCode: 'Sd',
        CountryName: 'Sudán',
    },
    {
        CityCode: '1001',
        CityName: 'Nilo Blanco',
        StateCode: '10',
        StateName: 'Nilo Blanco',
        CountryCode: 'Sd',
        CountryName: 'Sudán',
    },
    {
        CityCode: '1101',
        CityName: 'Río Nilo',
        StateCode: '11',
        StateName: 'Río Nilo',
        CountryCode: 'Sd',
        CountryName: 'Sudán',
    },
    {
        CityCode: '1201',
        CityName: 'Mar Rojo',
        StateCode: '12',
        StateName: 'Mar Rojo',
        CountryCode: 'Sd',
        CountryName: 'Sudán',
    },
    {
        CityCode: '1301',
        CityName: 'Gadarif',
        StateCode: '13',
        StateName: 'Gadarif',
        CountryCode: 'Sd',
        CountryName: 'Sudán',
    },
    {
        CityCode: '1401',
        CityName: 'Sennar',
        StateCode: '14',
        StateName: 'Sennar',
        CountryCode: 'Sd',
        CountryName: 'Sudán',
    },
    {
        CityCode: '1501',
        CityName: 'Darfur del Oeste',
        StateCode: '15',
        StateName: 'Darfur del Oeste',
        CountryCode: 'Sd',
        CountryName: 'Sudán',
    },
    {
        CityCode: '1601',
        CityName: 'Darfur Central',
        StateCode: '16',
        StateName: 'Darfur Central',
        CountryCode: 'Sd',
        CountryName: 'Sudán',
    },
    {
        CityCode: '1701',
        CityName: 'Darfur del Este',
        StateCode: '17',
        StateName: 'Darfur del Este',
        CountryCode: 'Sd',
        CountryName: 'Sudán',
    },
    {
        CityCode: '1801',
        CityName: 'Kordofán del Oeste',
        StateCode: '18',
        StateName: 'Kordofán del Oeste',
        CountryCode: 'Sd',
        CountryName: 'Sudán',
    },
    {
        CityCode: '0101',
        CityName: 'Malakal',
        StateCode: '01',
        StateName: 'Alto Nilo',
        CountryCode: 'Ss',
        CountryName: 'Sudán Del Sur',
    },
    {
        CityCode: '0201',
        CityName: 'Wau',
        StateCode: '02',
        StateName: 'Bahr el-Ghazal Occidental',
        CountryCode: 'Ss',
        CountryName: 'Sudán Del Sur',
    },
    {
        CityCode: '0301',
        CityName: 'Uwail',
        StateCode: '03',
        StateName: 'Bahr el-Ghazal del Norte',
        CountryCode: 'Ss',
        CountryName: 'Sudán Del Sur',
    },
    {
        CityCode: '0401',
        CityName: 'Yuba',
        StateCode: '04',
        StateName: 'Ecuatoria Central',
        CountryCode: 'Ss',
        CountryName: 'Sudán Del Sur',
    },
    {
        CityCode: '0501',
        CityName: 'Yambio',
        StateCode: '05',
        StateName: 'Ecuatoria Occidental',
        CountryCode: 'Ss',
        CountryName: 'Sudán Del Sur',
    },
    {
        CityCode: '0601',
        CityName: 'Torit',
        StateCode: '06',
        StateName: 'Ecuatoria Oriental',
        CountryCode: 'Ss',
        CountryName: 'Sudán Del Sur',
    },
    {
        CityCode: '0701',
        CityName: 'Bur',
        StateCode: '07',
        StateName: 'Junqali',
        CountryCode: 'Ss',
        CountryName: 'Sudán Del Sur',
    },
    {
        CityCode: '0801',
        CityName: 'Rumbek',
        StateCode: '08',
        StateName: 'Lagos',
        CountryCode: 'Ss',
        CountryName: 'Sudán Del Sur',
    },
    {
        CityCode: '0901',
        CityName: 'Bentiu',
        StateCode: '09',
        StateName: 'Unidad',
        CountryCode: 'Ss',
        CountryName: 'Sudán Del Sur',
    },
    {
        CityCode: '1001',
        CityName: 'Kuajok',
        StateCode: '10',
        StateName: 'Warab',
        CountryCode: 'Ss',
        CountryName: 'Sudán Del Sur',
    },
    {
        CityCode: '0101',
        CityName: 'Luleå',
        StateCode: '01',
        StateName: 'Provincias Län',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '0201',
        CityName: 'län Umeå',
        StateCode: '02',
        StateName: 'Provincia de Norrbotten',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '0301',
        CityName: 'Umeå',
        StateCode: '03',
        StateName: 'Provincia de Västerbotten',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '0401',
        CityName: 'Östersund',
        StateCode: '04',
        StateName: 'Provincia de Jämtland',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '0501',
        CityName: 'Härnösand',
        StateCode: '05',
        StateName: 'Provincia de Västernorrland',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '0601',
        CityName: 'Gävle',
        StateCode: '06',
        StateName: 'Provincia de Gävleborg',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '0701',
        CityName: 'Falun',
        StateCode: '07',
        StateName: 'Provincia de Dalarna',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '0801',
        CityName: 'Karlstad',
        StateCode: '08',
        StateName: 'Provincia de Värmland',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '0901',
        CityName: 'Örebro',
        StateCode: '09',
        StateName: 'Provincia de Örebro',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '1001',
        CityName: 'Västerås',
        StateCode: '10',
        StateName: 'Provincia de Västmanland',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '1101',
        CityName: 'Upsala',
        StateCode: '11',
        StateName: 'Provincia de Upsala',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '1201',
        CityName: 'Estocolmo',
        StateCode: '12',
        StateName: 'Provincia de Estocolmo',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '1301',
        CityName: 'Nyköping',
        StateCode: '13',
        StateName: 'Provincia de Södermanland',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '1401',
        CityName: 'Gotemburgo',
        StateCode: '14',
        StateName: 'Provincia de Västra Götaland',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '1501',
        CityName: 'Linköping',
        StateCode: '15',
        StateName: 'Provincia de Östergötland',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '1601',
        CityName: 'Jönköping',
        StateCode: '16',
        StateName: 'Provincia de Jönköping',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '1701',
        CityName: 'Kalmar',
        StateCode: '17',
        StateName: 'Provincia de Kalmar',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '1801',
        CityName: 'Halmstad',
        StateCode: '18',
        StateName: 'Provincia de Halland',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '1901',
        CityName: 'Växjö',
        StateCode: '19',
        StateName: 'Provincia de Kronoberg',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '2001',
        CityName: 'Karlskrona',
        StateCode: '20',
        StateName: 'Provincia de Blekinge',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '2101',
        CityName: 'Malmö',
        StateCode: '21',
        StateName: 'Provincia de Escania',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '2201',
        CityName: 'Visby',
        StateCode: '22',
        StateName: 'Provincia de Gotland',
        CountryCode: 'Se',
        CountryName: 'Suecia',
    },
    {
        CityCode: '0101',
        CityName: 'Nidwalden',
        StateCode: '01',
        StateName: 'Argovia',
        CountryCode: 'Ch',
        CountryName: 'Suiza',
    },
    {
        CityCode: '0201',
        CityName: 'Obwalden',
        StateCode: '02',
        StateName: 'Appenzell Rodas Exteriores',
        CountryCode: 'Ch',
        CountryName: 'Suiza',
    },
    {
        CityCode: '0301',
        CityName: 'Schaffhausen',
        StateCode: '03',
        StateName: 'Appenzell Rodas Interiores',
        CountryCode: 'Ch',
        CountryName: 'Suiza',
    },
    {
        CityCode: '0401',
        CityName: 'Schwyz',
        StateCode: '04',
        StateName: 'Basilea-Ciudad',
        CountryCode: 'Ch',
        CountryName: 'Suiza',
    },
    {
        CityCode: '0501',
        CityName: 'Soleura',
        StateCode: '05',
        StateName: 'Basilea-Campiña',
        CountryCode: 'Ch',
        CountryName: 'Suiza',
    },
    {
        CityCode: '0601',
        CityName: 'San Galo',
        StateCode: '06',
        StateName: 'Berna',
        CountryCode: 'Ch',
        CountryName: 'Suiza',
    },
    {
        CityCode: '0701',
        CityName: 'Turgovia',
        StateCode: '07',
        StateName: 'Friburgo',
        CountryCode: 'Ch',
        CountryName: 'Suiza',
    },
    {
        CityCode: '0801',
        CityName: 'Tesino',
        StateCode: '08',
        StateName: 'Ginebra',
        CountryCode: 'Ch',
        CountryName: 'Suiza',
    },
    {
        CityCode: '0901',
        CityName: 'Uri',
        StateCode: '09',
        StateName: 'Glaris',
        CountryCode: 'Ch',
        CountryName: 'Suiza',
    },
    {
        CityCode: '1001',
        CityName: 'Valais',
        StateCode: '10',
        StateName: 'Grisones',
        CountryCode: 'Ch',
        CountryName: 'Suiza',
    },
    {
        CityCode: '1101',
        CityName: 'Vaud',
        StateCode: '11',
        StateName: 'Jura',
        CountryCode: 'Ch',
        CountryName: 'Suiza',
    },
    {
        CityCode: '1201',
        CityName: 'Zug',
        StateCode: '12',
        StateName: 'Lucerna',
        CountryCode: 'Ch',
        CountryName: 'Suiza',
    },
    {
        CityCode: '1301',
        CityName: 'Zúrich',
        StateCode: '13',
        StateName: 'Neuchâtel',
        CountryCode: 'Ch',
        CountryName: 'Suiza',
    },
    {
        CityCode: '0101',
        CityName: 'Brokopondo',
        StateCode: '01',
        StateName: 'Brokopondo',
        CountryCode: 'Sr',
        CountryName: 'Surinam',
    },
    {
        CityCode: '0201',
        CityName: 'Nieuw Amsterdam',
        StateCode: '02',
        StateName: 'Commewijne',
        CountryCode: 'Sr',
        CountryName: 'Surinam',
    },
    {
        CityCode: '0301',
        CityName: 'Totness',
        StateCode: '03',
        StateName: 'Coronie',
        CountryCode: 'Sr',
        CountryName: 'Surinam',
    },
    {
        CityCode: '0401',
        CityName: 'Albina',
        StateCode: '04',
        StateName: 'Marowijne',
        CountryCode: 'Sr',
        CountryName: 'Surinam',
    },
    {
        CityCode: '0501',
        CityName: 'Nieuw Nickerie',
        StateCode: '05',
        StateName: 'Nickerie',
        CountryCode: 'Sr',
        CountryName: 'Surinam',
    },
    {
        CityCode: '0601',
        CityName: 'Onverwacht',
        StateCode: '06',
        StateName: 'Para',
        CountryCode: 'Sr',
        CountryName: 'Surinam',
    },
    {
        CityCode: '0701',
        CityName: 'Paramaribo',
        StateCode: '07',
        StateName: 'Paramaribo',
        CountryCode: 'Sr',
        CountryName: 'Surinam',
    },
    {
        CityCode: '0801',
        CityName: 'Groningen',
        StateCode: '08',
        StateName: 'Saramacca',
        CountryCode: 'Sr',
        CountryName: 'Surinam',
    },
    {
        CityCode: '0901',
        CityName: 'Sipaliwini',
        StateCode: '09',
        StateName: 'Sipaliwini',
        CountryCode: 'Sr',
        CountryName: 'Surinam',
    },
    {
        CityCode: '1001',
        CityName: 'Lelydorp',
        StateCode: '10',
        StateName: 'Wanica',
        CountryCode: 'Sr',
        CountryName: 'Surinam',
    },
    {
        CityCode: '1101',
        CityName: 'Paramaribo',
        StateCode: '11',
        StateName: 'Bandera de Surinam Surinam',
        CountryCode: 'Sr',
        CountryName: 'Surinam',
    },
    {
        CityCode: '0101',
        CityName: 'Svalbard',
        StateCode: '01',
        StateName: 'Svalbard',
        CountryCode: 'Sj',
        CountryName: 'Svalbard Y Jan Mayen',
    },
    {
        CityCode: '0101',
        CityName: 'Chiang Mai',
        StateCode: '01',
        StateName: 'Chiang Mai',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '0201',
        CityName: 'Chiang Rai',
        StateCode: '02',
        StateName: 'Chiang Rai',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '0301',
        CityName: 'Kamphaeng Phet',
        StateCode: '03',
        StateName: 'Kamphaeng Phet',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '0401',
        CityName: 'Lampang',
        StateCode: '04',
        StateName: 'Lampang',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '0501',
        CityName: 'Lamphun',
        StateCode: '05',
        StateName: 'Lamphun',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '0601',
        CityName: 'Mae Hong Son',
        StateCode: '06',
        StateName: 'Mae Hong Son',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '0701',
        CityName: 'Nakhon Sawan',
        StateCode: '07',
        StateName: 'Nakhon Sawan',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '0801',
        CityName: 'Nan',
        StateCode: '08',
        StateName: 'Nan',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '0901',
        CityName: 'Phayao',
        StateCode: '09',
        StateName: 'Phayao',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '1001',
        CityName: 'Phetchabun',
        StateCode: '10',
        StateName: 'Phetchabun',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '1101',
        CityName: 'Phichit',
        StateCode: '11',
        StateName: 'Phichit',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '1201',
        CityName: 'Phitsanulok',
        StateCode: '12',
        StateName: 'Phitsanulok',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '1301',
        CityName: 'Phrae',
        StateCode: '13',
        StateName: 'Phrae',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '1401',
        CityName: 'Sukhothai',
        StateCode: '14',
        StateName: 'Sukhothai',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '1501',
        CityName: 'Tak',
        StateCode: '15',
        StateName: 'Tak',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '1601',
        CityName: 'Uthai Thani',
        StateCode: '16',
        StateName: 'Uthai Thani',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '1701',
        CityName: 'Uttaradit',
        StateCode: '17',
        StateName: 'Uttaradit',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '1801',
        CityName: 'Amnat Charoen',
        StateCode: '18',
        StateName: 'Amnat Charoen',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '1901',
        CityName: 'Buri Ram',
        StateCode: '19',
        StateName: 'Buri Ram',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '2001',
        CityName: 'Chaiyaphum',
        StateCode: '20',
        StateName: 'Chaiyaphum',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '2101',
        CityName: 'Kalasin',
        StateCode: '21',
        StateName: 'Kalasin',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '2201',
        CityName: 'Khon Kaen',
        StateCode: '22',
        StateName: 'Khon Kaen',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '2301',
        CityName: 'Loei',
        StateCode: '23',
        StateName: 'Loei',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '2401',
        CityName: 'Maha Sarakham',
        StateCode: '24',
        StateName: 'Maha Sarakham',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '2501',
        CityName: 'Mukdahan',
        StateCode: '25',
        StateName: 'Mukdahan',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '2601',
        CityName: 'Nakhon Phanom',
        StateCode: '26',
        StateName: 'Nakhon Phanom',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '2701',
        CityName: 'Nakhon Ratchasima',
        StateCode: '27',
        StateName: 'Nakhon Ratchasima',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '2801',
        CityName: 'Nong Bua Lam Phu',
        StateCode: '28',
        StateName: 'Nong Bua Lam Phu',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '2901',
        CityName: 'Nong Khai',
        StateCode: '29',
        StateName: 'Nong Khai',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '3001',
        CityName: 'Roi Et',
        StateCode: '30',
        StateName: 'Roi Et',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '3101',
        CityName: 'Sakon Nakhon',
        StateCode: '31',
        StateName: 'Sakon Nakhon',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '3201',
        CityName: 'Si Sa Ket',
        StateCode: '32',
        StateName: 'Si Sa Ket',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '3301',
        CityName: 'Surin',
        StateCode: '33',
        StateName: 'Surin',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '3401',
        CityName: 'Ubon Ratchathani',
        StateCode: '34',
        StateName: 'Ubon Ratchathani',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '3501',
        CityName: 'Udon Thani',
        StateCode: '35',
        StateName: 'Udon Thani',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '3601',
        CityName: 'Yasothon',
        StateCode: '36',
        StateName: 'Yasothon',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '3701',
        CityName: 'Bueng Kan',
        StateCode: '37',
        StateName: 'Bueng Kan',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '3801',
        CityName: 'Ang Thong',
        StateCode: '38',
        StateName: 'Ang Thong',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '3901',
        CityName: 'Phra Nakhon Si Ayutthaya',
        StateCode: '39',
        StateName: 'Phra Nakhon Si Ayutthaya',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '4001',
        CityName: 'Bangkok',
        StateCode: '40',
        StateName: 'Bangkok',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '4101',
        CityName: 'Chainat',
        StateCode: '41',
        StateName: 'Chainat',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '4201',
        CityName: 'Kanchanaburi',
        StateCode: '42',
        StateName: 'Kanchanaburi',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '4301',
        CityName: 'Lop Buri',
        StateCode: '43',
        StateName: 'Lop Buri',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '4401',
        CityName: 'Nakhon Nayok',
        StateCode: '44',
        StateName: 'Nakhon Nayok',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '4501',
        CityName: 'Nakhon Pathom',
        StateCode: '45',
        StateName: 'Nakhon Pathom',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '4601',
        CityName: 'Nonthaburi',
        StateCode: '46',
        StateName: 'Nonthaburi',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '4701',
        CityName: 'Pathum Thani',
        StateCode: '47',
        StateName: 'Pathum Thani',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '4801',
        CityName: 'Phetchaburi',
        StateCode: '48',
        StateName: 'Phetchaburi',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '4901',
        CityName: 'Prachuap Khiri Khan',
        StateCode: '49',
        StateName: 'Prachuap Khiri Khan',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '5001',
        CityName: 'Ratchaburi',
        StateCode: '50',
        StateName: 'Ratchaburi',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '5101',
        CityName: 'Samut Prakan',
        StateCode: '51',
        StateName: 'Samut Prakan',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '5201',
        CityName: 'Samut Sakhon',
        StateCode: '52',
        StateName: 'Samut Sakhon',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '5301',
        CityName: 'Samut Songkhram',
        StateCode: '53',
        StateName: 'Samut Songkhram',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '5401',
        CityName: 'Saraburi',
        StateCode: '54',
        StateName: 'Saraburi',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '5501',
        CityName: 'Sing Buri',
        StateCode: '55',
        StateName: 'Sing Buri',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '5601',
        CityName: 'Suphan Buri',
        StateCode: '56',
        StateName: 'Suphan Buri',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '5701',
        CityName: 'Chachoengsao',
        StateCode: '57',
        StateName: 'Chachoengsao',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '5801',
        CityName: 'Chanthaburi',
        StateCode: '58',
        StateName: 'Chanthaburi',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '5901',
        CityName: 'Chon Buri',
        StateCode: '59',
        StateName: 'Chon Buri',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '6001',
        CityName: 'Prachin Buri',
        StateCode: '60',
        StateName: 'Prachin Buri',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '6101',
        CityName: 'Rayong',
        StateCode: '61',
        StateName: 'Rayong',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '6201',
        CityName: 'Sa Kaeo',
        StateCode: '62',
        StateName: 'Sa Kaeo',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '6301',
        CityName: 'Trat',
        StateCode: '63',
        StateName: 'Trat',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '6401',
        CityName: 'Chumphon',
        StateCode: '64',
        StateName: 'Chumphon',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '6501',
        CityName: 'Krabi',
        StateCode: '65',
        StateName: 'Krabi',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '6601',
        CityName: 'Nakhon Si Thammarat',
        StateCode: '66',
        StateName: 'Nakhon Si Thammarat',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '6701',
        CityName: 'Narathiwat',
        StateCode: '67',
        StateName: 'Narathiwat',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '6801',
        CityName: 'Pattani',
        StateCode: '68',
        StateName: 'Pattani',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '6901',
        CityName: 'Phang Nga',
        StateCode: '69',
        StateName: 'Phang Nga',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '7001',
        CityName: 'Phatthalung',
        StateCode: '70',
        StateName: 'Phatthalung',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '7101',
        CityName: 'Phuket',
        StateCode: '71',
        StateName: 'Phuket',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '7201',
        CityName: 'Ranong',
        StateCode: '72',
        StateName: 'Ranong',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '7301',
        CityName: 'Satun',
        StateCode: '73',
        StateName: 'Satun',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '7401',
        CityName: 'Songkhla',
        StateCode: '74',
        StateName: 'Songkhla',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '7501',
        CityName: 'Surat Thani',
        StateCode: '75',
        StateName: 'Surat Thani',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '7601',
        CityName: 'Trang',
        StateCode: '76',
        StateName: 'Trang',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '7701',
        CityName: 'Yala',
        StateCode: '77',
        StateName: 'Yala',
        CountryCode: 'Th',
        CountryName: 'Tailandia',
    },
    {
        CityCode: '0101',
        CityName: 'Taipéi',
        StateCode: '01',
        StateName: 'Taipéi',
        CountryCode: 'Tw',
        CountryName: 'Taiwán',
    },
    {
        CityCode: '0101',
        CityName: 'Arusha',
        StateCode: '01',
        StateName: 'Arusha',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '0201',
        CityName: 'Dar es Salaam',
        StateCode: '02',
        StateName: 'Dar es Salaam',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '0301',
        CityName: 'Dodoma',
        StateCode: '03',
        StateName: 'Dodoma',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '0401',
        CityName: 'Geita',
        StateCode: '04',
        StateName: 'Geita',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '0501',
        CityName: 'Iringa',
        StateCode: '05',
        StateName: 'Iringa',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '0601',
        CityName: 'Bukoba',
        StateCode: '06',
        StateName: 'Kagera',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '0701',
        CityName: 'Mpanda',
        StateCode: '07',
        StateName: 'Katavi',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '0801',
        CityName: 'Kigoma',
        StateCode: '08',
        StateName: 'Kigoma',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '0901',
        CityName: 'Moshi',
        StateCode: '09',
        StateName: 'Kilimanjaro',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '1001',
        CityName: 'Lindi',
        StateCode: '10',
        StateName: 'Lindi',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '1101',
        CityName: 'Babati',
        StateCode: '11',
        StateName: 'Manyara',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '1201',
        CityName: 'Musoma',
        StateCode: '12',
        StateName: 'Mara',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '1301',
        CityName: 'Mbeya',
        StateCode: '13',
        StateName: 'Mbeya',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '1401',
        CityName: 'Morogoro',
        StateCode: '14',
        StateName: 'Morogoro',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '1501',
        CityName: 'Mtwara',
        StateCode: '15',
        StateName: 'Mtwara',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '1601',
        CityName: 'Mwanza',
        StateCode: '16',
        StateName: 'Mwanza',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '1701',
        CityName: 'Njombe',
        StateCode: '17',
        StateName: 'Njombe',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '1801',
        CityName: 'Kibaha',
        StateCode: '18',
        StateName: 'Pwani',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '1901',
        CityName: 'Wete',
        StateCode: '19',
        StateName: 'Pemba Norte',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '2001',
        CityName: 'Mkoani',
        StateCode: '20',
        StateName: 'Pemba Sur',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '2101',
        CityName: 'Sumbawanga',
        StateCode: '21',
        StateName: 'Rukwa',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '2201',
        CityName: 'Songea',
        StateCode: '22',
        StateName: 'Ruvuma',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '2301',
        CityName: 'Shinyanga',
        StateCode: '23',
        StateName: 'Shinyanga',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '2401',
        CityName: 'Singida',
        StateCode: '24',
        StateName: 'Singida',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '2501',
        CityName: 'Bariadi',
        StateCode: '25',
        StateName: 'Simiyu',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '2601',
        CityName: 'Tabora',
        StateCode: '26',
        StateName: 'Tabora',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '2701',
        CityName: 'Tanga',
        StateCode: '27',
        StateName: 'Tanga',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '2801',
        CityName: 'Koani',
        StateCode: '28',
        StateName: 'Zanzíbar Central/Sur',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '2901',
        CityName: 'Mkokotoni',
        StateCode: '29',
        StateName: 'Zanzíbar Norte',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '3001',
        CityName: 'Zanzíbar',
        StateCode: '30',
        StateName: 'Zanzíbar Ciudad/Oeste',
        CountryCode: 'Tz',
        CountryName: 'Tanzania',
    },
    {
        CityCode: '0101',
        CityName: 'Buston',
        StateCode: '01',
        StateName: 'Mastchoh',
        CountryCode: 'Tj',
        CountryName: 'Tayikistán',
    },
    {
        CityCode: '0201',
        CityName: 'Ghafurov',
        StateCode: '02',
        StateName: 'Ghafurov',
        CountryCode: 'Tj',
        CountryName: 'Tayikistán',
    },
    {
        CityCode: '0301',
        CityName: 'Shaydon',
        StateCode: '03',
        StateName: 'Asht',
        CountryCode: 'Tj',
        CountryName: 'Tayikistán',
    },
    {
        CityCode: '0401',
        CityName: 'Zafarobod',
        StateCode: '04',
        StateName: 'Zafarobod',
        CountryCode: 'Tj',
        CountryName: 'Tayikistán',
    },
    {
        CityCode: '0501',
        CityName: 'Nov, Nau',
        StateCode: '05',
        StateName: 'Spitamen',
        CountryCode: 'Tj',
        CountryName: 'Tayikistán',
    },
    {
        CityCode: '0601',
        CityName: 'Proletarsk',
        StateCode: '06',
        StateName: 'Rasulov',
        CountryCode: 'Tj',
        CountryName: 'Tayikistán',
    },
    {
        CityCode: '0701',
        CityName: 'Konibodom',
        StateCode: '07',
        StateName: 'Konibodom',
        CountryCode: 'Tj',
        CountryName: 'Tayikistán',
    },
    {
        CityCode: '0801',
        CityName: 'Isfara',
        StateCode: '08',
        StateName: 'Isfara',
        CountryCode: 'Tj',
        CountryName: 'Tayikistán',
    },
    {
        CityCode: '0901',
        CityName: 'Istaravshan',
        StateCode: '09',
        StateName: 'Istarawshan',
        CountryCode: 'Tj',
        CountryName: 'Tayikistán',
    },
    {
        CityCode: '1001',
        CityName: 'Shahriston',
        StateCode: '10',
        StateName: 'Shahriston',
        CountryCode: 'Tj',
        CountryName: 'Tayikistán',
    },
    {
        CityCode: '1101',
        CityName: 'Ghonchi',
        StateCode: '11',
        StateName: 'Ghonchi',
        CountryCode: 'Tj',
        CountryName: 'Tayikistán',
    },
    {
        CityCode: '1201',
        CityName: 'Panjakent',
        StateCode: '12',
        StateName: 'Panjakent',
        CountryCode: 'Tj',
        CountryName: 'Tayikistán',
    },
    {
        CityCode: '1301',
        CityName: 'Aini',
        StateCode: '13',
        StateName: 'Aini',
        CountryCode: 'Tj',
        CountryName: 'Tayikistán',
    },
    {
        CityCode: '1401',
        CityName: 'Mehron',
        StateCode: '14',
        StateName: 'Kuhistoni Mastchoh',
        CountryCode: 'Tj',
        CountryName: 'Tayikistán',
    },
    {
        CityCode: '0101',
        CityName: 'Camp Justice',
        StateCode: '01',
        StateName: 'Camp Justice',
        CountryCode: 'Io',
        CountryName: 'Territorio Britanico Del Oceano Indico',
    },
    {
        CityCode: '0101',
        CityName: 'Base Martin de Viviès',
        StateCode: '01',
        StateName: 'San Pablo y Ámsterdam',
        CountryCode: 'Tf',
        CountryName: 'Territorios Australes Franceses',
    },
    {
        CityCode: '0201',
        CityName: 'Base Alfred Faure',
        StateCode: '02',
        StateName: 'Islas Crozet',
        CountryCode: 'Tf',
        CountryName: 'Territorios Australes Franceses',
    },
    {
        CityCode: '0301',
        CityName: 'Port-aux-Français',
        StateCode: '03',
        StateName: 'Islas Kerguelen',
        CountryCode: 'Tf',
        CountryName: 'Territorios Australes Franceses',
    },
    {
        CityCode: '0401',
        CityName: "Estación Dumont d'Urville",
        StateCode: '04',
        StateName: 'Tierra Adelia',
        CountryCode: 'Tf',
        CountryName: 'Territorios Australes Franceses',
    },
    {
        CityCode: '0501',
        CityName: 'Saint-Pierre',
        StateCode: '05',
        StateName: 'Islas Dispersas',
        CountryCode: 'Tf',
        CountryName: 'Territorios Australes Franceses',
    },
    {
        CityCode: '0101',
        CityName: 'Pante Macassar',
        StateCode: '01',
        StateName: 'Oecusse',
        CountryCode: 'Tl',
        CountryName: 'Timor Oriental',
    },
    {
        CityCode: '0201',
        CityName: 'Liquiçá',
        StateCode: '02',
        StateName: 'Liquiçá',
        CountryCode: 'Tl',
        CountryName: 'Timor Oriental',
    },
    {
        CityCode: '0301',
        CityName: 'Dili',
        StateCode: '03',
        StateName: 'Dili',
        CountryCode: 'Tl',
        CountryName: 'Timor Oriental',
    },
    {
        CityCode: '0401',
        CityName: 'Manatuto',
        StateCode: '04',
        StateName: 'Manatuto',
        CountryCode: 'Tl',
        CountryName: 'Timor Oriental',
    },
    {
        CityCode: '0501',
        CityName: 'Baucau',
        StateCode: '05',
        StateName: 'Baucau',
        CountryCode: 'Tl',
        CountryName: 'Timor Oriental',
    },
    {
        CityCode: '0601',
        CityName: 'Lospalos',
        StateCode: '06',
        StateName: 'Lautem',
        CountryCode: 'Tl',
        CountryName: 'Timor Oriental',
    },
    {
        CityCode: '0701',
        CityName: 'Maliana',
        StateCode: '07',
        StateName: 'Bobonaro',
        CountryCode: 'Tl',
        CountryName: 'Timor Oriental',
    },
    {
        CityCode: '0801',
        CityName: 'Gleno',
        StateCode: '08',
        StateName: 'Ermera',
        CountryCode: 'Tl',
        CountryName: 'Timor Oriental',
    },
    {
        CityCode: '0901',
        CityName: 'Aileu',
        StateCode: '09',
        StateName: 'Aileu',
        CountryCode: 'Tl',
        CountryName: 'Timor Oriental',
    },
    {
        CityCode: '1001',
        CityName: 'Viqueque',
        StateCode: '10',
        StateName: 'Viqueque',
        CountryCode: 'Tl',
        CountryName: 'Timor Oriental',
    },
    {
        CityCode: '1101',
        CityName: 'Suai',
        StateCode: '11',
        StateName: 'Cova-Lima',
        CountryCode: 'Tl',
        CountryName: 'Timor Oriental',
    },
    {
        CityCode: '1201',
        CityName: 'Ainaro',
        StateCode: '12',
        StateName: 'Ainaro',
        CountryCode: 'Tl',
        CountryName: 'Timor Oriental',
    },
    {
        CityCode: '1301',
        CityName: 'Same',
        StateCode: '13',
        StateName: 'Manufahi',
        CountryCode: 'Tl',
        CountryName: 'Timor Oriental',
    },
    {
        CityCode: '0101',
        CityName: 'Atakpamé',
        StateCode: '01',
        StateName: 'Altiplano',
        CountryCode: 'Tg',
        CountryName: 'Togo',
    },
    {
        CityCode: '0201',
        CityName: 'Sokodé',
        StateCode: '02',
        StateName: 'Central',
        CountryCode: 'Tg',
        CountryName: 'Togo',
    },
    {
        CityCode: '0301',
        CityName: 'Kara',
        StateCode: '03',
        StateName: 'Kara',
        CountryCode: 'Tg',
        CountryName: 'Togo',
    },
    {
        CityCode: '0401',
        CityName: 'Lomé',
        StateCode: '04',
        StateName: 'Marítima',
        CountryCode: 'Tg',
        CountryName: 'Togo',
    },
    {
        CityCode: '0501',
        CityName: 'Dapaong',
        StateCode: '05',
        StateName: 'Sabana',
        CountryCode: 'Tg',
        CountryName: 'Togo',
    },
    {
        CityCode: '0601',
        CityName: 'LOMÉ',
        StateCode: '06',
        StateName: 'TOGO',
        CountryCode: 'Tg',
        CountryName: 'Togo',
    },
    {
        CityCode: '0101',
        CityName: 'Atafu',
        StateCode: '01',
        StateName: 'Atafu',
        CountryCode: 'Tk',
        CountryName: 'Tokelau',
    },
    {
        CityCode: '0101',
        CityName: "'Ohonua",
        StateCode: '01',
        StateName: "'Eua",
        CountryCode: 'To',
        CountryName: 'Tonga',
    },
    {
        CityCode: '0201',
        CityName: 'Pangai',
        StateCode: '02',
        StateName: "Ha'apai",
        CountryCode: 'To',
        CountryName: 'Tonga',
    },
    {
        CityCode: '0301',
        CityName: 'Hihifo',
        StateCode: '03',
        StateName: 'Niuas',
        CountryCode: 'To',
        CountryName: 'Tonga',
    },
    {
        CityCode: '0401',
        CityName: 'Nukualofa',
        StateCode: '04',
        StateName: 'Tongatapu',
        CountryCode: 'To',
        CountryName: 'Tonga',
    },
    {
        CityCode: '0501',
        CityName: 'Neiafu',
        StateCode: '05',
        StateName: "Vava'u",
        CountryCode: 'To',
        CountryName: 'Tonga',
    },
    {
        CityCode: '0101',
        CityName: 'Puerto España',
        StateCode: '01',
        StateName: 'Puerto España',
        CountryCode: 'Tt',
        CountryName: 'Trinidad Y Tobago',
    },
    {
        CityCode: '0101',
        CityName: 'Ariana',
        StateCode: '01',
        StateName: 'Ariana',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '0201',
        CityName: 'Béja',
        StateCode: '02',
        StateName: 'Béja',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '0301',
        CityName: 'Ben Arous',
        StateCode: '03',
        StateName: 'Ben Arous',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '0401',
        CityName: 'Bizerta',
        StateCode: '04',
        StateName: 'Bizerta',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '0501',
        CityName: 'Gabes',
        StateCode: '05',
        StateName: 'Gabes',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '0601',
        CityName: 'Gafsa',
        StateCode: '06',
        StateName: 'Gafsa',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '0701',
        CityName: 'Jendouba',
        StateCode: '07',
        StateName: 'Jendouba',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '0801',
        CityName: 'Kairuán',
        StateCode: '08',
        StateName: 'Kairuán',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '0901',
        CityName: 'Kasserine',
        StateCode: '09',
        StateName: 'Kasserine',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '1001',
        CityName: 'Kebili',
        StateCode: '10',
        StateName: 'Kebili',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '1101',
        CityName: 'Kef',
        StateCode: '11',
        StateName: 'Kef',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '1201',
        CityName: 'Mahdia',
        StateCode: '12',
        StateName: 'Mahdia',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '1301',
        CityName: 'Manouba',
        StateCode: '13',
        StateName: 'Manouba',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '1401',
        CityName: 'Medenine',
        StateCode: '14',
        StateName: 'Medenine',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '1501',
        CityName: 'Monastir',
        StateCode: '15',
        StateName: 'Monastir',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '1601',
        CityName: 'Nabeul',
        StateCode: '16',
        StateName: 'Nabeul',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '1701',
        CityName: 'Sfax',
        StateCode: '17',
        StateName: 'Sfax',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '1801',
        CityName: 'Sidi Bou Said',
        StateCode: '18',
        StateName: 'Sidi Bou Said',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '1901',
        CityName: 'Siliana',
        StateCode: '19',
        StateName: 'Siliana',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '2001',
        CityName: 'Susa',
        StateCode: '20',
        StateName: 'Susa',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '2101',
        CityName: 'Tataouine',
        StateCode: '21',
        StateName: 'Tataouine',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '2201',
        CityName: 'Tozeur',
        StateCode: '22',
        StateName: 'Tozeur',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '2301',
        CityName: 'Túnez',
        StateCode: '23',
        StateName: 'Túnez',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '2401',
        CityName: 'Zaghouan',
        StateCode: '24',
        StateName: 'Zaghouan',
        CountryCode: 'Tn',
        CountryName: 'Túnez',
    },
    {
        CityCode: '0101',
        CityName: 'Asjabad',
        StateCode: '01',
        StateName: 'Asjabad',
        CountryCode: 'Tm',
        CountryName: 'Turkmenistán',
    },
    {
        CityCode: '0201',
        CityName: 'Annau',
        StateCode: '02',
        StateName: 'Ahal',
        CountryCode: 'Tm',
        CountryName: 'Turkmenistán',
    },
    {
        CityCode: '0301',
        CityName: 'Balkanabat',
        StateCode: '03',
        StateName: 'Balkan',
        CountryCode: 'Tm',
        CountryName: 'Turkmenistán',
    },
    {
        CityCode: '0401',
        CityName: 'Dasoguz',
        StateCode: '04',
        StateName: 'Dasoguz',
        CountryCode: 'Tm',
        CountryName: 'Turkmenistán',
    },
    {
        CityCode: '0501',
        CityName: 'Türkmenabat',
        StateCode: '05',
        StateName: 'Lebap',
        CountryCode: 'Tm',
        CountryName: 'Turkmenistán',
    },
    {
        CityCode: '0601',
        CityName: 'Mary',
        StateCode: '06',
        StateName: 'Mary',
        CountryCode: 'Tm',
        CountryName: 'Turkmenistán',
    },
    {
        CityCode: '0101',
        CityName: 'Adana',
        StateCode: '01',
        StateName: 'Provincia de Adana',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '0201',
        CityName: 'Adiyaman',
        StateCode: '02',
        StateName: 'Provincia de Adiyaman',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '0301',
        CityName: 'Afyonkarahisar',
        StateCode: '03',
        StateName: 'Provincia de Afyonkarahisar',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '0401',
        CityName: 'Agri',
        StateCode: '04',
        StateName: 'Provincia de Agri',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '0501',
        CityName: 'Amasya',
        StateCode: '05',
        StateName: 'Provincia de Amasya',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '0601',
        CityName: 'Ankara',
        StateCode: '06',
        StateName: 'Provincia de Ankara',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '0701',
        CityName: 'Antalya',
        StateCode: '07',
        StateName: 'Provincia de Antalya',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '0801',
        CityName: 'Artvin',
        StateCode: '08',
        StateName: 'Provincia de Artvin',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '0901',
        CityName: 'Aydin',
        StateCode: '09',
        StateName: 'Provincia de Aydin',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '1001',
        CityName: 'Balikesir',
        StateCode: '10',
        StateName: 'Provincia de Balikesir',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '1101',
        CityName: 'Bilecik',
        StateCode: '11',
        StateName: 'Provincia de Bilecik',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '1201',
        CityName: 'Bingöl',
        StateCode: '12',
        StateName: 'Provincia de Bingöl',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '1301',
        CityName: 'Bitlis',
        StateCode: '13',
        StateName: 'Provincia de Bitlis',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '1401',
        CityName: 'Bolu',
        StateCode: '14',
        StateName: 'Provincia de Bolu',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '1501',
        CityName: 'Burdur',
        StateCode: '15',
        StateName: 'Provincia de Burdur',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '1601',
        CityName: 'Bursa',
        StateCode: '16',
        StateName: 'Provincia de Bursa',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '1701',
        CityName: 'Çanakkale',
        StateCode: '17',
        StateName: 'Provincia de Çanakkale',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '1801',
        CityName: 'Çankiri',
        StateCode: '18',
        StateName: 'Provincia de Cankiri',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '1901',
        CityName: 'Çorum',
        StateCode: '19',
        StateName: 'Provincia de Çorum',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '2001',
        CityName: 'Denizli',
        StateCode: '20',
        StateName: 'Provincia de Denizli',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '2101',
        CityName: 'Diyarbakir',
        StateCode: '21',
        StateName: 'Provincia de Diyarbakir',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '2201',
        CityName: 'Edirne',
        StateCode: '22',
        StateName: 'Provincia de Edirne',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '2301',
        CityName: 'Elazig',
        StateCode: '23',
        StateName: 'Provincia de Elazig',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '2401',
        CityName: 'Erzincan',
        StateCode: '24',
        StateName: 'Provincia de Erzincan',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '2501',
        CityName: 'Erzurum',
        StateCode: '25',
        StateName: 'Provincia de Erzurum',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '2601',
        CityName: 'Eskisehir',
        StateCode: '26',
        StateName: 'Provincia de Eskisehir',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '2701',
        CityName: 'Gaziantep',
        StateCode: '27',
        StateName: 'Provincia de Gaziantep',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '2801',
        CityName: 'Giresun',
        StateCode: '28',
        StateName: 'Provincia de Giresun',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '2901',
        CityName: 'Gümüshane',
        StateCode: '29',
        StateName: 'Provincia de Gümüshane',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '3001',
        CityName: 'Hakkâri',
        StateCode: '30',
        StateName: 'Provincia de Hakkâri',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '3101',
        CityName: 'Antioquía',
        StateCode: '31',
        StateName: 'Provincia de Hatay',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '3201',
        CityName: 'Isparta',
        StateCode: '32',
        StateName: 'Provincia de Isparta',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '3301',
        CityName: 'Mersin',
        StateCode: '33',
        StateName: 'Provincia de Mersin',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '3401',
        CityName: 'Estambul',
        StateCode: '34',
        StateName: 'Provincia de Estambul',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '3501',
        CityName: 'Esmirna',
        StateCode: '35',
        StateName: 'Provincia de Esmirna',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '3601',
        CityName: 'Kars',
        StateCode: '36',
        StateName: 'Provincia de Kars',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '3701',
        CityName: 'Kastamonu',
        StateCode: '37',
        StateName: 'Provincia de Kastamonu',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '3801',
        CityName: 'Kayseri',
        StateCode: '38',
        StateName: 'Provincia de Kayseri',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '3901',
        CityName: 'Kirklareli',
        StateCode: '39',
        StateName: 'Provincia de Kirklareli',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '4001',
        CityName: 'Kirsehir',
        StateCode: '40',
        StateName: 'Provincia de Kirsehir',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '4101',
        CityName: 'Izmit',
        StateCode: '41',
        StateName: 'Provincia de Kocaeli',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '4201',
        CityName: 'Konya',
        StateCode: '42',
        StateName: 'Provincia de Konya',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '4301',
        CityName: 'Kütahya',
        StateCode: '43',
        StateName: 'Provincia de Kütahya',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '4401',
        CityName: 'Malatya',
        StateCode: '44',
        StateName: 'Provincia de Malatya',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '4501',
        CityName: 'Manisa',
        StateCode: '45',
        StateName: 'Provincia de Manisa',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '4601',
        CityName: 'Kahramanmaras',
        StateCode: '46',
        StateName: 'Provincia de Kahramanmaras',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '4701',
        CityName: 'Mardin',
        StateCode: '47',
        StateName: 'Provincia de Mardin',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '4801',
        CityName: 'Mugla',
        StateCode: '48',
        StateName: 'Provincia de Mugla',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '4901',
        CityName: 'Mus',
        StateCode: '49',
        StateName: 'Provincia de Mus',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '5001',
        CityName: 'Nevsehir',
        StateCode: '50',
        StateName: 'Provincia de Nevsehir',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '5101',
        CityName: 'Nigde',
        StateCode: '51',
        StateName: 'Provincia de Nigde',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '5201',
        CityName: 'Ordu',
        StateCode: '52',
        StateName: 'Provincia de Ordu',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '5301',
        CityName: 'Rize',
        StateCode: '53',
        StateName: 'Provincia de Rize',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '5401',
        CityName: 'Adapazari',
        StateCode: '54',
        StateName: 'Provincia de Sakarya',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '5501',
        CityName: 'Samsun',
        StateCode: '55',
        StateName: 'Provincia de Samsun',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '5601',
        CityName: 'Siirt',
        StateCode: '56',
        StateName: 'Provincia de Siirt',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '5701',
        CityName: 'Sinope',
        StateCode: '57',
        StateName: 'Provincia de Sinope',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '5801',
        CityName: 'Sivas',
        StateCode: '58',
        StateName: 'Provincia de Sivas',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '5901',
        CityName: 'Tekirdag',
        StateCode: '59',
        StateName: 'Provincia de Tekirdag',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '6001',
        CityName: 'Tokat',
        StateCode: '60',
        StateName: 'Provincia de Tokat',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '6101',
        CityName: 'Trebisonda',
        StateCode: '61',
        StateName: 'Provincia de Trebisonda',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '6201',
        CityName: 'Tunceli',
        StateCode: '62',
        StateName: 'Provincia de Tunceli',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '6301',
        CityName: 'Sanliurfa',
        StateCode: '63',
        StateName: 'Provincia de Sanliurfa',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '6401',
        CityName: 'Usak',
        StateCode: '64',
        StateName: 'Provincia de Usak',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '6501',
        CityName: 'Van',
        StateCode: '65',
        StateName: 'Provincia de Van',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '6601',
        CityName: 'Yozgat',
        StateCode: '66',
        StateName: 'Provincia de Yozgat',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '6701',
        CityName: 'Zonguldak',
        StateCode: '67',
        StateName: 'Provincia de Zonguldak',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '6801',
        CityName: 'Aksaray',
        StateCode: '68',
        StateName: 'Provincia de Aksaray',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '6901',
        CityName: 'Bayburt',
        StateCode: '69',
        StateName: 'Provincia de Bayburt',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '7001',
        CityName: 'Karaman',
        StateCode: '70',
        StateName: 'Provincia de Karaman',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '7101',
        CityName: 'Kirikkale',
        StateCode: '71',
        StateName: 'Provincia de Kirikkale',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '7201',
        CityName: 'Batman',
        StateCode: '72',
        StateName: 'Provincia de Batman',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '7301',
        CityName: 'Sirnak',
        StateCode: '73',
        StateName: 'Provincia de Sirnak',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '7401',
        CityName: 'Bartin',
        StateCode: '74',
        StateName: 'Provincia de Bartin',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '7501',
        CityName: 'Ardahan',
        StateCode: '75',
        StateName: 'Provincia de Ardahan',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '7601',
        CityName: 'Igdir',
        StateCode: '76',
        StateName: 'Provincia de Igdir',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '7701',
        CityName: 'Yalova',
        StateCode: '77',
        StateName: 'Provincia de Yalova',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '7801',
        CityName: 'Karabük',
        StateCode: '78',
        StateName: 'Provincia de Karabük',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '7901',
        CityName: 'Kilis',
        StateCode: '79',
        StateName: 'Provincia de Kilis',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '8001',
        CityName: 'Osmaniye',
        StateCode: '80',
        StateName: 'Provincia de Osmaniye',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '8101',
        CityName: 'Düzce',
        StateCode: '81',
        StateName: 'Provincia de Düzce',
        CountryCode: 'Tr',
        CountryName: 'Turquía',
    },
    {
        CityCode: '0101',
        CityName: 'Funafuti',
        StateCode: '01',
        StateName: 'Funafuti',
        CountryCode: 'Tv',
        CountryName: 'Tuvalu',
    },
    {
        CityCode: '0101',
        CityName: 'Cherkasy',
        StateCode: '01',
        StateName: 'Cherkasy',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '0201',
        CityName: 'Chernihiv',
        StateCode: '02',
        StateName: 'Chernihiv',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '0301',
        CityName: 'Chernivtsi',
        StateCode: '03',
        StateName: 'Chernivtsi',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '0401',
        CityName: 'Dnipropetrovsk',
        StateCode: '04',
        StateName: 'Dnipropetrovsk',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '0501',
        CityName: 'Donetsk',
        StateCode: '05',
        StateName: 'Donetsk',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '0601',
        CityName: 'Ivano-Frankivsk',
        StateCode: '06',
        StateName: 'Ivano-Frankivsk',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '0701',
        CityName: 'Járkov',
        StateCode: '07',
        StateName: 'Járkov',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '0801',
        CityName: 'Jersón',
        StateCode: '08',
        StateName: 'Jersón',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '0901',
        CityName: 'Jmelnitski',
        StateCode: '09',
        StateName: 'Jmelnitski',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '1001',
        CityName: 'Kirovogrado',
        StateCode: '10',
        StateName: 'Kirovogrado',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '1101',
        CityName: 'Kiev',
        StateCode: '11',
        StateName: 'Kiev',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '1201',
        CityName: 'Lugansk',
        StateCode: '12',
        StateName: 'Lugansk',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '1301',
        CityName: 'Leópolis',
        StateCode: '13',
        StateName: 'Leópolis',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '1401',
        CityName: 'Mykolaiv',
        StateCode: '14',
        StateName: 'Mykolaiv',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '1501',
        CityName: 'Odesa',
        StateCode: '15',
        StateName: 'Odesa',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '1601',
        CityName: 'Poltava',
        StateCode: '16',
        StateName: 'Poltava',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '1701',
        CityName: 'Rivne',
        StateCode: '17',
        StateName: 'Rivne',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '1801',
        CityName: 'Sumy',
        StateCode: '18',
        StateName: 'Sumy',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '1901',
        CityName: 'Ternópil',
        StateCode: '19',
        StateName: 'Ternópil',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '2001',
        CityName: 'Transcarpacia',
        StateCode: '20',
        StateName: 'Transcarpacia',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '2101',
        CityName: 'Volinia',
        StateCode: '21',
        StateName: 'Volinia',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '2201',
        CityName: 'Vinnytsia',
        StateCode: '22',
        StateName: 'Vinnytsia',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '2301',
        CityName: 'Zaporizhia',
        StateCode: '23',
        StateName: 'Zaporizhia',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '2401',
        CityName: 'Zhytomyr',
        StateCode: '24',
        StateName: 'Zhytomyr',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '2501',
        CityName: 'Simferópol',
        StateCode: '25',
        StateName: 'República Autónoma de Crimea',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '0101',
        CityName: 'Kampala',
        StateCode: '01',
        StateName: 'Kampala',
        CountryCode: 'Ug',
        CountryName: 'Uganda',
    },
    {
        CityCode: '0101',
        CityName: 'Artigas',
        StateCode: '01',
        StateName: 'Artigas',
        CountryCode: 'Uy',
        CountryName: 'Uruguay',
    },
    {
        CityCode: '0201',
        CityName: 'Canelones',
        StateCode: '02',
        StateName: 'Canelones',
        CountryCode: 'Uy',
        CountryName: 'Uruguay',
    },
    {
        CityCode: '0301',
        CityName: 'Melo',
        StateCode: '03',
        StateName: 'Cerro Largo',
        CountryCode: 'Uy',
        CountryName: 'Uruguay',
    },
    {
        CityCode: '0401',
        CityName: 'Colonia del Sacramento',
        StateCode: '04',
        StateName: 'Colonia',
        CountryCode: 'Uy',
        CountryName: 'Uruguay',
    },
    {
        CityCode: '0501',
        CityName: 'Durazno',
        StateCode: '05',
        StateName: 'Durazno',
        CountryCode: 'Uy',
        CountryName: 'Uruguay',
    },
    {
        CityCode: '0601',
        CityName: 'Trinidad',
        StateCode: '06',
        StateName: 'Flores',
        CountryCode: 'Uy',
        CountryName: 'Uruguay',
    },
    {
        CityCode: '0701',
        CityName: 'Florida',
        StateCode: '07',
        StateName: 'Florida',
        CountryCode: 'Uy',
        CountryName: 'Uruguay',
    },
    {
        CityCode: '0801',
        CityName: 'Minas',
        StateCode: '08',
        StateName: 'Lavalleja',
        CountryCode: 'Uy',
        CountryName: 'Uruguay',
    },
    {
        CityCode: '0901',
        CityName: 'Maldonado',
        StateCode: '09',
        StateName: 'Maldonado',
        CountryCode: 'Uy',
        CountryName: 'Uruguay',
    },
    {
        CityCode: '1001',
        CityName: 'Montevideo',
        StateCode: '10',
        StateName: 'Montevideo',
        CountryCode: 'Uy',
        CountryName: 'Uruguay',
    },
    {
        CityCode: '1101',
        CityName: 'Paysandú',
        StateCode: '11',
        StateName: 'Paysandú',
        CountryCode: 'Uy',
        CountryName: 'Uruguay',
    },
    {
        CityCode: '1201',
        CityName: 'Fray Bentos',
        StateCode: '12',
        StateName: 'Río Negro',
        CountryCode: 'Uy',
        CountryName: 'Uruguay',
    },
    {
        CityCode: '1301',
        CityName: 'Rivera',
        StateCode: '13',
        StateName: 'Rivera',
        CountryCode: 'Uy',
        CountryName: 'Uruguay',
    },
    {
        CityCode: '1401',
        CityName: 'Rocha',
        StateCode: '14',
        StateName: 'Rocha',
        CountryCode: 'Uy',
        CountryName: 'Uruguay',
    },
    {
        CityCode: '1501',
        CityName: 'Salto',
        StateCode: '15',
        StateName: 'Salto',
        CountryCode: 'Uy',
        CountryName: 'Uruguay',
    },
    {
        CityCode: '1601',
        CityName: 'San José de Mayo',
        StateCode: '16',
        StateName: 'San José',
        CountryCode: 'Uy',
        CountryName: 'Uruguay',
    },
    {
        CityCode: '1701',
        CityName: 'Mercedes',
        StateCode: '17',
        StateName: 'Soriano',
        CountryCode: 'Uy',
        CountryName: 'Uruguay',
    },
    {
        CityCode: '1801',
        CityName: 'Tacuarembó',
        StateCode: '18',
        StateName: 'Tacuarembó',
        CountryCode: 'Uy',
        CountryName: 'Uruguay',
    },
    {
        CityCode: '1901',
        CityName: 'Treinta y Tres',
        StateCode: '19',
        StateName: 'Treinta y Tres',
        CountryCode: 'Uy',
        CountryName: 'Uruguay',
    },
    {
        CityCode: '0101',
        CityName: 'Andillán',
        StateCode: '01',
        StateName: 'Andillán',
        CountryCode: 'Uz',
        CountryName: 'Uzbekistán',
    },
    {
        CityCode: '0201',
        CityName: 'Bujará',
        StateCode: '02',
        StateName: 'Bujará',
        CountryCode: 'Uz',
        CountryName: 'Uzbekistán',
    },
    {
        CityCode: '0301',
        CityName: 'Ferganá',
        StateCode: '03',
        StateName: 'Ferganá',
        CountryCode: 'Uz',
        CountryName: 'Uzbekistán',
    },
    {
        CityCode: '0401',
        CityName: 'Djizaks',
        StateCode: '04',
        StateName: 'Djizaks',
        CountryCode: 'Uz',
        CountryName: 'Uzbekistán',
    },
    {
        CityCode: '0501',
        CityName: 'Urgench',
        StateCode: '05',
        StateName: 'Corasmia',
        CountryCode: 'Uz',
        CountryName: 'Uzbekistán',
    },
    {
        CityCode: '0601',
        CityName: 'Namangán',
        StateCode: '06',
        StateName: 'Namangán',
        CountryCode: 'Uz',
        CountryName: 'Uzbekistán',
    },
    {
        CityCode: '0701',
        CityName: 'Navoi',
        StateCode: '07',
        StateName: 'Navoi',
        CountryCode: 'Uz',
        CountryName: 'Uzbekistán',
    },
    {
        CityCode: '0801',
        CityName: 'Qarshi',
        StateCode: '08',
        StateName: 'Kashkadar',
        CountryCode: 'Uz',
        CountryName: 'Uzbekistán',
    },
    {
        CityCode: '0901',
        CityName: 'Nukus',
        StateCode: '09',
        StateName: 'Rep Autónoma',
        CountryCode: 'Uz',
        CountryName: 'Uzbekistán',
    },
    {
        CityCode: '1001',
        CityName: 'Karakalpakistán',
        StateCode: '10',
        StateName: 'Karakalpakistán',
        CountryCode: 'Uz',
        CountryName: 'Uzbekistán',
    },
    {
        CityCode: '1101',
        CityName: 'Samarcanda',
        StateCode: '11',
        StateName: 'Samarcanda',
        CountryCode: 'Uz',
        CountryName: 'Uzbekistán',
    },
    {
        CityCode: '1201',
        CityName: 'Guliston',
        StateCode: '12',
        StateName: 'Sir Daria',
        CountryCode: 'Uz',
        CountryName: 'Uzbekistán',
    },
    {
        CityCode: '1301',
        CityName: 'Termez',
        StateCode: '13',
        StateName: 'Surjandarín',
        CountryCode: 'Uz',
        CountryName: 'Uzbekistán',
    },
    {
        CityCode: '1401',
        CityName: 'Taskent',
        StateCode: '14',
        StateName: 'Taskent',
        CountryCode: 'Uz',
        CountryName: 'Uzbekistán',
    },
    {
        CityCode: '1501',
        CityName: 'Taskent',
        StateCode: '15',
        StateName: 'Taskent ciudad',
        CountryCode: 'Uz',
        CountryName: 'Uzbekistán',
    },
    {
        CityCode: '0101',
        CityName: 'Port Vila',
        StateCode: '01',
        StateName: 'Port Vila',
        CountryCode: 'Vu',
        CountryName: 'Vanuatu',
    },
    {
        CityCode: '0101',
        CityName: 'Vaticano',
        StateCode: '01',
        StateName: 'Vaticano',
        CountryCode: 'Va',
        CountryName: 'Vaticano',
    },
    {
        CityCode: '0101',
        CityName: 'Puerto Ayacucho',
        StateCode: '01',
        StateName: 'Amazonas',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '0201',
        CityName: 'Barcelona',
        StateCode: '02',
        StateName: 'Anzoátegui',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '0301',
        CityName: 'San Fernando de Apure',
        StateCode: '03',
        StateName: 'Apure',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '0401',
        CityName: 'Maracay',
        StateCode: '04',
        StateName: 'Aragua',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '0501',
        CityName: 'Barinas',
        StateCode: '05',
        StateName: 'Barinas',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '0601',
        CityName: 'Ciudad Bolívar',
        StateCode: '06',
        StateName: 'Bolívar',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '0701',
        CityName: 'Valencia',
        StateCode: '07',
        StateName: 'Carabobo',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '0801',
        CityName: 'San Carlos',
        StateCode: '08',
        StateName: 'Cojedes',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '0901',
        CityName: 'Tucupita',
        StateCode: '09',
        StateName: 'Delta Amacuro',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '1001',
        CityName: 'Caracas',
        StateCode: '10',
        StateName: 'Distrito Capital',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '1101',
        CityName: 'Coro',
        StateCode: '11',
        StateName: 'Falcón',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '1201',
        CityName: 'San Juan de Los Morros',
        StateCode: '12',
        StateName: 'Guárico',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '1301',
        CityName: 'Barquisimeto',
        StateCode: '13',
        StateName: 'Lara',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '1401',
        CityName: 'Mérida',
        StateCode: '14',
        StateName: 'Mérida',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '1501',
        CityName: 'Los Teques',
        StateCode: '15',
        StateName: 'Miranda',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '1601',
        CityName: 'Maturín',
        StateCode: '16',
        StateName: 'Monagas',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '1701',
        CityName: 'La Asunción',
        StateCode: '17',
        StateName: 'Nueva Esparta',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '1801',
        CityName: 'Guanare',
        StateCode: '18',
        StateName: 'Portuguesa',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '1901',
        CityName: 'Cumaná',
        StateCode: '19',
        StateName: 'Sucre',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '2001',
        CityName: 'San Cristóbal',
        StateCode: '20',
        StateName: 'Táchira',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '2101',
        CityName: 'Trujillo',
        StateCode: '21',
        StateName: 'Trujillo',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '2201',
        CityName: 'La Guaira',
        StateCode: '22',
        StateName: 'Vargas',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '2301',
        CityName: 'San Felipe',
        StateCode: '23',
        StateName: 'Yaracuy',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '2401',
        CityName: 'Maracaibo',
        StateCode: '24',
        StateName: 'Zulia',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '2501',
        CityName: 'Gran Roque',
        StateCode: '25',
        StateName: 'Dependencias Federales',
        CountryCode: 'Ve',
        CountryName: 'Venezuela',
    },
    {
        CityCode: '0101',
        CityName: 'Bc Ninh',
        StateCode: '01',
        StateName: 'Bc Ninh',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '0201',
        CityName: 'Phu Ly',
        StateCode: '02',
        StateName: 'Hà Nam',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '0301',
        CityName: 'Hai Duong',
        StateCode: '03',
        StateName: 'Hai Duong',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '0401',
        CityName: 'Hung Yen',
        StateCode: '04',
        StateName: 'Hung Yen',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '0501',
        CityName: 'Nam Dinh',
        StateCode: '05',
        StateName: 'Nam Dinh',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '0601',
        CityName: 'Ninh Binh',
        StateCode: '06',
        StateName: 'Ninh Binh',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '0701',
        CityName: 'Thai Binh',
        StateCode: '07',
        StateName: 'Thai Binh',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '0801',
        CityName: 'Vinh Yen',
        StateCode: '08',
        StateName: 'Vinh Phuc',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '0901',
        CityName: 'Ha Noi',
        StateCode: '09',
        StateName: 'Ha Noi',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '1001',
        CityName: 'Hai Phong',
        StateCode: '10',
        StateName: 'Hai Phong',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '1101',
        CityName: 'Ha Tinh',
        StateCode: '11',
        StateName: 'Ha Tinh',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '1201',
        CityName: 'Vinh',
        StateCode: '12',
        StateName: 'Nghe An',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '1301',
        CityName: 'Dong Hoi',
        StateCode: '13',
        StateName: 'Quang Binh',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '1401',
        CityName: 'Dong Ha',
        StateCode: '14',
        StateName: 'Quang Tri',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '1501',
        CityName: 'Thanh Hóa',
        StateCode: '15',
        StateName: 'Thanh Hóa',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '1601',
        CityName: 'Hu',
        StateCode: '16',
        StateName: 'Thua Thien-Hue',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '1701',
        CityName: 'Bc Giang',
        StateCode: '17',
        StateName: 'Bac Giang',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '1801',
        CityName: 'Bc Kn',
        StateCode: '18',
        StateName: 'Bac Kan',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '1901',
        CityName: 'Cao Bang',
        StateCode: '19',
        StateName: 'Cao Bang',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '2001',
        CityName: 'Hà Giang',
        StateCode: '20',
        StateName: 'Hà Giang',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '2101',
        CityName: 'Lang Son',
        StateCode: '21',
        StateName: 'Lang Son',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '2201',
        CityName: 'Lào Cai',
        StateCode: '22',
        StateName: 'Lao Cai',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '2301',
        CityName: 'Viet Tri',
        StateCode: '23',
        StateName: 'Phu Tho',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '2401',
        CityName: 'Ha Long',
        StateCode: '24',
        StateName: 'Quang Ninh',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '2501',
        CityName: 'Thai Nguyen',
        StateCode: '25',
        StateName: 'Thai Nguyen',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '2601',
        CityName: 'Tuyen Quang',
        StateCode: '26',
        StateName: 'Tuyen Quang',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '2701',
        CityName: 'Yen Bai',
        StateCode: '27',
        StateName: 'Yen Bai',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '2801',
        CityName: 'Dien Bien Phu',
        StateCode: '28',
        StateName: 'Dien Bien',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '2901',
        CityName: 'Hoa Binh',
        StateCode: '29',
        StateName: 'Hoa Binh',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '3001',
        CityName: 'Lai Châu',
        StateCode: '30',
        StateName: 'Lai Châu',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '3101',
        CityName: 'Son La',
        StateCode: '31',
        StateName: 'Son La',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '3201',
        CityName: 'Buon Ma Thuot',
        StateCode: '32',
        StateName: 'Dak Lak',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '3301',
        CityName: 'Gia Nghia',
        StateCode: '33',
        StateName: 'Dak Nong',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '3401',
        CityName: 'PleiKu',
        StateCode: '34',
        StateName: 'Gia Lai',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '3501',
        CityName: 'Kon Tum',
        StateCode: '35',
        StateName: 'Kon Tum',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '3601',
        CityName: 'Da Lat',
        StateCode: '36',
        StateName: 'Lam Dong',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '3701',
        CityName: 'Qui Nhon',
        StateCode: '37',
        StateName: 'Binh Dinh',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '3801',
        CityName: 'Nha Trang',
        StateCode: '38',
        StateName: 'Khanh Hoa',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '3901',
        CityName: 'Tuy Hoa',
        StateCode: '39',
        StateName: 'Phu Yen',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '4001',
        CityName: 'Tam Ky',
        StateCode: '40',
        StateName: 'Quang Nam',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '4101',
        CityName: 'Quang Ngai',
        StateCode: '41',
        StateName: 'Quang Ngai',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '4201',
        CityName: 'Da Nang',
        StateCode: '42',
        StateName: 'Da Nang',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '4301',
        CityName: 'Vung Tau',
        StateCode: '43',
        StateName: 'Ba Ria-Vung Tau',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '4401',
        CityName: 'Thu Dau Mot',
        StateCode: '44',
        StateName: 'Binh Duong',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '4501',
        CityName: 'Dong Xoai',
        StateCode: '45',
        StateName: 'Binh Phuoc',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '4601',
        CityName: 'Phan Thiet',
        StateCode: '46',
        StateName: 'Binh Thuan',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '4701',
        CityName: 'Bien Hoa',
        StateCode: '47',
        StateName: 'Dong Nai',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '4801',
        CityName: 'Phan Rang-Thap Cham',
        StateCode: '48',
        StateName: 'Ninh Thuan',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '4901',
        CityName: 'Tây Ninh',
        StateCode: '49',
        StateName: 'Tây Ninh',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '5001',
        CityName: 'Ciudad Ho Chi Minh',
        StateCode: '50',
        StateName: 'Ciudad Ho Chi Minh',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '5101',
        CityName: 'Long Xuyên',
        StateCode: '51',
        StateName: 'An Giang',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '5201',
        CityName: 'Bc Liêu',
        StateCode: '52',
        StateName: 'Bc Liêu',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '5301',
        CityName: 'Ben Tre',
        StateCode: '53',
        StateName: 'Ben Tre',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '5401',
        CityName: 'Ca Mau',
        StateCode: '54',
        StateName: 'Ca Mau',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '5501',
        CityName: 'Cao Lanh',
        StateCode: '55',
        StateName: 'Dong Thap',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '5601',
        CityName: 'Vi Thanh',
        StateCode: '56',
        StateName: 'Hau Giang',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '5701',
        CityName: 'Rach Gia',
        StateCode: '57',
        StateName: 'Kien Giang',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '5801',
        CityName: 'Tan An',
        StateCode: '58',
        StateName: 'Long An',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '5901',
        CityName: 'Soc Trang',
        StateCode: '59',
        StateName: 'Soc Trang',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '6001',
        CityName: 'My Tho',
        StateCode: '60',
        StateName: 'Tien Giang',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '6101',
        CityName: 'Trà Vinh',
        StateCode: '61',
        StateName: 'Trà Vinh',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '6201',
        CityName: 'Vinh Long',
        StateCode: '62',
        StateName: 'Vinh Long',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '6301',
        CityName: 'Can Tho',
        StateCode: '63',
        StateName: 'Can Tho',
        CountryCode: 'Vn',
        CountryName: 'Vietnam',
    },
    {
        CityCode: '0101',
        CityName: 'Mata-Utu',
        StateCode: '01',
        StateName: 'Mata-Utu',
        CountryCode: 'Wf',
        CountryName: 'Wallis Y Futuna',
    },
    {
        CityCode: '0101',
        CityName: 'Zinjibar',
        StateCode: '01',
        StateName: 'Abyan',
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '0201',
        CityName: 'Adén',
        StateCode: '02',
        StateName: 'Adén',
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '0301',
        CityName: "Ad Dali'",
        StateCode: '03',
        StateName: "Ad Dali'",
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '0401',
        CityName: 'Al Bayda',
        StateCode: '04',
        StateName: "Al Bayda'",
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '0501',
        CityName: 'Al Hudayda',
        StateCode: '05',
        StateName: 'Al Hudayda',
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '0601',
        CityName: 'Al Hazm',
        StateCode: '06',
        StateName: 'Al Jawf',
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '0701',
        CityName: 'Al Ghaydah',
        StateCode: '07',
        StateName: 'Al Mahrah',
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '0801',
        CityName: 'Al Mahwit',
        StateCode: '08',
        StateName: 'Al Mahwit',
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '0901',
        CityName: 'Saná',
        StateCode: '09',
        StateName: 'Amanat Al Asimah',
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '1001',
        CityName: "'Amran",
        StateCode: '10',
        StateName: "'Amran",
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '1101',
        CityName: 'Dhamar',
        StateCode: '11',
        StateName: 'Dhamar',
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '1201',
        CityName: 'Al Mukalla',
        StateCode: '12',
        StateName: 'Hadramaut',
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '1301',
        CityName: 'Hajjah',
        StateCode: '13',
        StateName: 'Hajjah',
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '1401',
        CityName: 'Ibb',
        StateCode: '14',
        StateName: 'Ibb',
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '1501',
        CityName: 'Lahij',
        StateCode: '15',
        StateName: 'Lahij',
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '1601',
        CityName: 'Marib',
        StateCode: '16',
        StateName: 'Marib',
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '1701',
        CityName: 'Raymah',
        StateCode: '17',
        StateName: 'Raymah',
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '1801',
        CityName: "Sa'dah",
        StateCode: '18',
        StateName: "Sa'dah",
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '1901',
        CityName: 'Saná',
        StateCode: '19',
        StateName: 'Saná',
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '2001',
        CityName: 'Ataq',
        StateCode: '20',
        StateName: 'Shabwah',
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '2101',
        CityName: 'Hadiboh',
        StateCode: '21',
        StateName: 'Socotra',
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '2201',
        CityName: 'Taiz',
        StateCode: '22',
        StateName: 'Taiz',
        CountryCode: 'Ye',
        CountryName: 'Yemen',
    },
    {
        CityCode: '0101',
        CityName: 'Yibuti',
        StateCode: '01',
        StateName: 'Yibuti',
        CountryCode: 'Dj',
        CountryName: 'Yibuti',
    },
    {
        CityCode: '0201',
        CityName: 'Arta',
        StateCode: '02',
        StateName: 'Arta',
        CountryCode: 'Dj',
        CountryName: 'Yibuti',
    },
    {
        CityCode: '0301',
        CityName: 'Ali Sabieh',
        StateCode: '03',
        StateName: 'Ali Sabieh',
        CountryCode: 'Dj',
        CountryName: 'Yibuti',
    },
    {
        CityCode: '0401',
        CityName: 'Dikhil',
        StateCode: '04',
        StateName: 'Dikhil',
        CountryCode: 'Dj',
        CountryName: 'Yibuti',
    },
    {
        CityCode: '0501',
        CityName: 'Tadjoura',
        StateCode: '05',
        StateName: 'Tadjoura',
        CountryCode: 'Dj',
        CountryName: 'Yibuti',
    },
    {
        CityCode: '0601',
        CityName: 'Obock',
        StateCode: '06',
        StateName: 'Obock',
        CountryCode: 'Dj',
        CountryName: 'Yibuti',
    },
    {
        CityCode: '0101',
        CityName: 'Kabwe',
        StateCode: '01',
        StateName: 'Central',
        CountryCode: 'Zm',
        CountryName: 'Zambia',
    },
    {
        CityCode: '0201',
        CityName: 'Ndola',
        StateCode: '02',
        StateName: 'Copperbelt',
        CountryCode: 'Zm',
        CountryName: 'Zambia',
    },
    {
        CityCode: '0301',
        CityName: 'Chipata',
        StateCode: '03',
        StateName: 'Este',
        CountryCode: 'Zm',
        CountryName: 'Zambia',
    },
    {
        CityCode: '0401',
        CityName: 'Mansa',
        StateCode: '04',
        StateName: 'Luapula',
        CountryCode: 'Zm',
        CountryName: 'Zambia',
    },
    {
        CityCode: '0501',
        CityName: 'Lusaka',
        StateCode: '05',
        StateName: 'Lusaka',
        CountryCode: 'Zm',
        CountryName: 'Zambia',
    },
    {
        CityCode: '0601',
        CityName: 'Chinsali',
        StateCode: '06',
        StateName: 'Muchinga',
        CountryCode: 'Zm',
        CountryName: 'Zambia',
    },
    {
        CityCode: '0701',
        CityName: 'Kasama',
        StateCode: '07',
        StateName: 'Norte',
        CountryCode: 'Zm',
        CountryName: 'Zambia',
    },
    {
        CityCode: '0801',
        CityName: 'Solwezi',
        StateCode: '08',
        StateName: 'Noroeste',
        CountryCode: 'Zm',
        CountryName: 'Zambia',
    },
    {
        CityCode: '0901',
        CityName: 'Mongu',
        StateCode: '09',
        StateName: 'Oeste',
        CountryCode: 'Zm',
        CountryName: 'Zambia',
    },
    {
        CityCode: '1001',
        CityName: 'Livingstone',
        StateCode: '10',
        StateName: 'Sur',
        CountryCode: 'Zm',
        CountryName: 'Zambia',
    },
    {
        CityCode: '0101',
        CityName: 'Bulawayo',
        StateCode: '01',
        StateName: 'Bulawayo',
        CountryCode: 'Zw',
        CountryName: 'Zimbabue',
    },
    {
        CityCode: '0201',
        CityName: 'Harare',
        StateCode: '02',
        StateName: 'Harare',
        CountryCode: 'Zw',
        CountryName: 'Zimbabue',
    },
    {
        CityCode: '0301',
        CityName: 'Mutare',
        StateCode: '03',
        StateName: 'Manicalandia',
        CountryCode: 'Zw',
        CountryName: 'Zimbabue',
    },
    {
        CityCode: '0401',
        CityName: 'Bindura',
        StateCode: '04',
        StateName: 'Mashonalandia Central',
        CountryCode: 'Zw',
        CountryName: 'Zimbabue',
    },
    {
        CityCode: '0501',
        CityName: 'Marondera',
        StateCode: '05',
        StateName: 'Mashonalandia Oriental',
        CountryCode: 'Zw',
        CountryName: 'Zimbabue',
    },
    {
        CityCode: '0601',
        CityName: 'Chinhoyi',
        StateCode: '06',
        StateName: 'Mashonalandia Occidental',
        CountryCode: 'Zw',
        CountryName: 'Zimbabue',
    },
    {
        CityCode: '0701',
        CityName: 'Masvingo',
        StateCode: '07',
        StateName: 'Masvingo',
        CountryCode: 'Zw',
        CountryName: 'Zimbabue',
    },
    {
        CityCode: '0801',
        CityName: 'Lupane',
        StateCode: '08',
        StateName: 'Matabelelandia Septentrional',
        CountryCode: 'Zw',
        CountryName: 'Zimbabue',
    },
    {
        CityCode: '0901',
        CityName: 'Gwanda',
        StateCode: '09',
        StateName: 'Matabelelandia Meridional',
        CountryCode: 'Zw',
        CountryName: 'Zimbabue',
    },
    {
        CityCode: '1001',
        CityName: 'Gweru',
        StateCode: '10',
        StateName: 'Midlands',
        CountryCode: 'Zw',
        CountryName: 'Zimbabue',
    },
    {
        CityCode: '0101',
        CityName: 'Noord',
        StateCode: '01',
        StateName: 'Noord',
        CountryCode: 'Aw',
        CountryName: 'Aruba',
    },
    {
        CityCode: '0201',
        CityName: 'Oranjestad',
        StateCode: '02',
        StateName: 'Oranjestad',
        CountryCode: 'Aw',
        CountryName: 'Aruba',
    },
    {
        CityCode: '0301',
        CityName: 'Paradera',
        StateCode: '03',
        StateName: 'Paradera',
        CountryCode: 'Aw',
        CountryName: 'Aruba',
    },
    {
        CityCode: '0401',
        CityName: 'Sint Nicolaas',
        StateCode: '04',
        StateName: 'Sint Nicolaas',
        CountryCode: 'Aw',
        CountryName: 'Aruba',
    },
    {
        CityCode: '0501',
        CityName: 'Santa Cruz',
        StateCode: '05',
        StateName: 'Santa Cruz',
        CountryCode: 'Aw',
        CountryName: 'Aruba',
    },
    {
        CityCode: '0601',
        CityName: 'Savaneta',
        StateCode: '06',
        StateName: 'Savaneta',
        CountryCode: 'Aw',
        CountryName: 'Aruba',
    },
    {
        CityCode: '0501',
        CityName: 'Ciudad de panamá',
        StateCode: '05',
        StateName: 'Ciudad de panamá',
        CountryCode: 'Pa',
        CountryName: 'Panamá',
    },
    {
        CityCode: '01',
        CityName: 'Bellshill',
        StateCode: '04',
        StateName: 'Escocia',
        CountryCode: 'Gb',
        CountryName: 'Reino Unido',
    },
    {
        CityCode: '01',
        CityName: 'Piggotts',
        StateCode: '01',
        StateName: 'Saint George',
        CountryCode: 'AG',
        CountryName: 'Antigua y Barbuda',
    },
    {
        CityCode: '02',
        CityName: 'Saint John',
        StateCode: '02',
        StateName: 'Saint John',
        CountryCode: 'AG',
        CountryName: 'Antigua y Barbuda',
    },
    {
        CityCode: '03',
        CityName: 'Bolands',
        StateCode: '03',
        StateName: 'Saint Mary',
        CountryCode: 'AG',
        CountryName: 'Antigua y Barbuda',
    },
    {
        CityCode: '04',
        CityName: "Nelson's Dockyard",
        StateCode: '04',
        StateName: 'Saint Paul',
        CountryCode: 'AG',
        CountryName: 'Antigua y Barbuda',
    },
    {
        CityCode: '05',
        CityName: 'Parham',
        StateCode: '05',
        StateName: 'Saint Peter',
        CountryCode: 'AG',
        CountryName: 'Antigua y Barbuda',
    },
    {
        CityCode: '06',
        CityName: 'Carlisle',
        StateCode: '06',
        StateName: 'Saint Philip',
        CountryCode: 'AG',
        CountryName: 'Antigua y Barbuda',
    },
    {
        CityCode: '0601',
        CityName: 'Provincia de Colón',
        StateCode: '06',
        StateName: 'Provincia de Colón',
        CountryCode: 'Pa',
        CountryName: 'Panamá',
    },
    {
        CityCode: '0101',
        CityName: 'George Town',
        StateCode: '01',
        StateName: 'Gran Caimán',
        CountryCode: 'KY',
        CountryName: 'Islas Caimán',
    },
    {
        CityCode: '19300',
        CityName: 'Guachené',
        StateCode: '19',
        StateName: 'Cauca',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '3002',
        CityName: 'Paramus',
        StateCode: '30',
        StateName: 'Nueva Jersey',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '0101',
        CityName: 'Road Town',
        StateCode: '01',
        StateName: 'Tórtola',
        CountryCode: 'Vg',
        CountryName: 'Islas Vírgenes Británicas',
    },
    {
        CityCode: '23682',
        CityName: 'San José de Uré',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '13490',
        CityName: 'Norosí',
        StateCode: '13',
        StateName: 'Bolívar',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '23815',
        CityName: 'Tuchín',
        StateCode: '23',
        StateName: 'Córdoba',
        CountryCode: 'Co',
        CountryName: 'Colombia',
    },
    {
        CityCode: '0101',
        CityName: 'Islas Vírgenes de los Estados Unidos',
        StateCode: '21',
        StateName: 'Massachusetts',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '25307',
        CityName: 'GIRARDOT',
        StateCode: '25',
        StateName: ' República Autónoma de Crimea',
        CountryCode: 'Ua',
        CountryName: 'Ucrania',
    },
    {
        CityCode: '0502',
        CityName: 'Menlo Park, CA',
        StateCode: '05',
        StateName: 'California',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '02119',
        CityName: 'Boston',
        StateCode: '21',
        StateName: 'Massachusetts',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '1',
        CityName: 'Miami',
        StateCode: '09',
        StateName: 'Florida',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '08027',
        CityName: 'Cataluña',
        StateCode: '08',
        StateName: 'Cataluña',
        CountryCode: 'Es',
        CountryName: 'España',
    },
    {
        CityCode: '11226',
        CityName: 'Brooklyn',
        StateCode: '32',
        StateName: 'Nueva York',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '21',
        CityName: 'Massachusetts',
        StateCode: '21',
        StateName: 'Massachusetts',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '33',
        CityName: 'Carolina del Norte',
        StateCode: '21',
        StateName: 'Massachusetts',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '08811',
        CityName: 'California',
        StateCode: '05',
        StateName: 'California',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '07097',
        CityName: 'Nueva Jersey',
        StateCode: '30',
        StateName: 'Nueva Jersey',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: '32801',
        CityName: 'Orlando',
        StateCode: '09',
        StateName: 'Florida',
        CountryCode: 'Us',
        CountryName: 'Estados Unidos',
    },
    {
        CityCode: 'C5014',
        CityName: 'Cordoba',
        StateCode: '02',
        StateName: 'Córdoba',
        CountryCode: 'Ar',
        CountryName: 'Argentina',
    },
];

exports.countries = [
    { countryCode: 'Co', countryName: 'Colombia' },
    { countryCode: 'Ec', countryName: 'Ecuador' },
    { countryCode: 'Pe', countryName: 'Perú' },
    { countryCode: 'Af', countryName: 'Afganistán' },
    { countryCode: 'Al', countryName: 'Albania' },
    { countryCode: 'De', countryName: 'Alemania' },
    { countryCode: 'Ao', countryName: 'Angola' },
    { countryCode: 'Sa', countryName: 'Arabia Saudita' },
    { countryCode: 'Dz', countryName: 'Argelia' },
    { countryCode: 'Ar', countryName: 'Argentina' },
    { countryCode: 'Am', countryName: 'Armenia' },
    { countryCode: 'Au', countryName: 'Australia' },
    { countryCode: 'At', countryName: 'Austria' },
    { countryCode: 'Az', countryName: 'Azerbaiyán' },
    { countryCode: 'Bs', countryName: 'Bahamas' },
    { countryCode: 'Bd', countryName: 'Bangladés' },
    { countryCode: 'Bb', countryName: 'Barbados' },
    { countryCode: 'Bh', countryName: 'Baréin' },
    { countryCode: 'Be', countryName: 'Bélgica' },
    { countryCode: 'Bz', countryName: 'Belice' },
    { countryCode: 'Bj', countryName: 'Benín' },
    { countryCode: 'By', countryName: 'Bielorrusia' },
    { countryCode: 'Bo', countryName: 'Bolivia' },
    { countryCode: 'Bq', countryName: 'Bonaire, San Eustaquio Y Saba' },
    { countryCode: 'Ba', countryName: 'Bosnia-Herzegovina' },
    { countryCode: 'Bw', countryName: 'Botsuana' },
    { countryCode: 'Br', countryName: 'Brasil' },
    { countryCode: 'Bn', countryName: 'Brunéi' },
    { countryCode: 'Bg', countryName: 'Bulgaria' },
    { countryCode: 'Bf', countryName: 'Burkina Faso' },
    { countryCode: 'Bi', countryName: 'Burundi' },
    { countryCode: 'Bt', countryName: 'Bután' },
    { countryCode: 'Cv', countryName: 'Cabo Verde' },
    { countryCode: 'Kh', countryName: 'Camboya' },
    { countryCode: 'Cm', countryName: 'Camerún' },
    { countryCode: 'Ca', countryName: 'Canadá' },
    { countryCode: 'Td', countryName: 'Chad' },
    { countryCode: 'Cl', countryName: 'Chile' },
    { countryCode: 'Cn', countryName: 'China' },
    { countryCode: 'Cy', countryName: 'Chipre' },
    { countryCode: 'Km', countryName: 'Comoras' },
    { countryCode: 'Cg', countryName: 'Congo' },
    { countryCode: 'Kp', countryName: 'Corea Del Norte' },
    { countryCode: 'Kr', countryName: 'Corea Del Sur' },
    { countryCode: 'Ci', countryName: 'Costa De Marfil' },
    { countryCode: 'Cr', countryName: 'Costa Rica' },
    { countryCode: 'Hr', countryName: 'Croacia' },
    { countryCode: 'Cu', countryName: 'Cuba' },
    { countryCode: 'Cw', countryName: 'Curazao' },
    { countryCode: 'Dk', countryName: 'Dinamarca' },
    { countryCode: 'Dm', countryName: 'Dominica' },
    { countryCode: 'Eg', countryName: 'Egipto' },
    { countryCode: 'Sv', countryName: 'El Salvador' },
    { countryCode: 'Ae', countryName: 'Emiratos Árabes Unidos' },
    { countryCode: 'Er', countryName: 'Eritrea' },
    { countryCode: 'Sk', countryName: 'Eslovaquia' },
    { countryCode: 'Si', countryName: 'Eslovenia' },
    { countryCode: 'Es', countryName: 'España' },
    { countryCode: 'Us', countryName: 'Estados Unidos' },
    { countryCode: 'Ee', countryName: 'Estonia' },
    { countryCode: 'Et', countryName: 'Etiopía' },
    { countryCode: 'Ph', countryName: 'Filipinas' },
    { countryCode: 'Fi', countryName: 'Finlandia' },
    { countryCode: 'Fj', countryName: 'Fiyi' },
    { countryCode: 'Fr', countryName: 'Francia' },
    { countryCode: 'Ga', countryName: 'Gabón' },
    { countryCode: 'Gm', countryName: 'Gambia' },
    { countryCode: 'Ge', countryName: 'Georgia' },
    { countryCode: 'Gh', countryName: 'Ghana' },
    { countryCode: 'Gr', countryName: 'Grecia' },
    { countryCode: 'Gl', countryName: 'Groenlandia' },
    { countryCode: 'Gp', countryName: 'Guadalupe' },
    { countryCode: 'Gu', countryName: 'Guam' },
    { countryCode: 'Gt', countryName: 'Guatemala' },
    { countryCode: 'Gf', countryName: 'Guayana Francesa' },
    { countryCode: 'Gg', countryName: 'Guernsey' },
    { countryCode: 'Gn', countryName: 'Guinea' },
    { countryCode: 'Gq', countryName: 'Guinea Ecuatorial' },
    { countryCode: 'Gw', countryName: 'Guinea-Bisáu' },
    { countryCode: 'Gy', countryName: 'Guyana' },
    { countryCode: 'Ht', countryName: 'Haití' },
    { countryCode: 'Hn', countryName: 'Honduras' },
    { countryCode: 'Hk', countryName: 'Hong Kong' },
    { countryCode: 'Hu', countryName: 'Hungría' },
    { countryCode: 'In', countryName: 'India' },
    { countryCode: 'Id', countryName: 'Indonesia' },
    { countryCode: 'Iq', countryName: 'Irak' },
    { countryCode: 'Ir', countryName: 'Irán' },
    { countryCode: 'Ie', countryName: 'Irlanda' },
    { countryCode: 'Is', countryName: 'Islandia' },
    { countryCode: 'Il', countryName: 'Israel' },
    { countryCode: 'It', countryName: 'Italia' },
    { countryCode: 'Jm', countryName: 'Jamaica' },
    { countryCode: 'Jp', countryName: 'Japón' },
    { countryCode: 'Je', countryName: 'Jersey' },
    { countryCode: 'Jo', countryName: 'Jordania' },
    { countryCode: 'Kz', countryName: 'Kazajistán' },
    { countryCode: 'Ke', countryName: 'Kenia' },
    { countryCode: 'Kg', countryName: 'Kirguistán' },
    { countryCode: 'Kw', countryName: 'Kuwait' },
    { countryCode: 'La', countryName: 'Laos' },
    { countryCode: 'Ls', countryName: 'Lesoto' },
    { countryCode: 'Lv', countryName: 'Letonia' },
    { countryCode: 'Lb', countryName: 'Líbano' },
    { countryCode: 'Lr', countryName: 'Liberia' },
    { countryCode: 'Ly', countryName: 'Libia' },
    { countryCode: 'Li', countryName: 'Liechtenstein' },
    { countryCode: 'Lt', countryName: 'Lituania' },
    { countryCode: 'Lu', countryName: 'Luxemburgo' },
    { countryCode: 'Mk', countryName: 'Macedonia, La Antigua Republicayugoslava De' },
    { countryCode: 'Mg', countryName: 'Madagascar' },
    { countryCode: 'My', countryName: 'Malasia' },
    { countryCode: 'Mw', countryName: 'Malaui' },
    { countryCode: 'Mv', countryName: 'Maldivas' },
    { countryCode: 'Ml', countryName: 'Malí' },
    { countryCode: 'Mt', countryName: 'Malta' },
    { countryCode: 'Ma', countryName: 'Marruecos' },
    { countryCode: 'Mq', countryName: 'Martinica' },
    { countryCode: 'Mu', countryName: 'Mauricio' },
    { countryCode: 'Mr', countryName: 'Mauritania' },
    { countryCode: 'Yt', countryName: 'Mayotte' },
    { countryCode: 'Mx', countryName: 'México' },
    { countryCode: 'Md', countryName: 'Moldavia' },
    { countryCode: 'Mc', countryName: 'Mónaco' },
    { countryCode: 'Mn', countryName: 'Mongolia' },
    { countryCode: 'Me', countryName: 'Montenegro' },
    { countryCode: 'Mz', countryName: 'Mozambique' },
    { countryCode: 'Na', countryName: 'Namibia' },
    { countryCode: 'Nr', countryName: 'Nauru' },
    { countryCode: 'Np', countryName: 'Nepal' },
    { countryCode: 'Ni', countryName: 'Nicaragua' },
    { countryCode: 'Ne', countryName: 'Níger' },
    { countryCode: 'Ng', countryName: 'Nigeria' },
    { countryCode: 'Nu', countryName: 'Niue' },
    { countryCode: 'No', countryName: 'Noruega' },
    { countryCode: 'Nc', countryName: 'Nueva Caledonia' },
    { countryCode: 'Nz', countryName: 'Nueva Zelanda' },
    { countryCode: 'Om', countryName: 'Omán' },
    { countryCode: 'Nl', countryName: 'Países Bajos' },
    { countryCode: 'Pk', countryName: 'Pakistán' },
    { countryCode: 'Pw', countryName: 'Palaos' },
    { countryCode: 'Ps', countryName: 'Palestina' },
    { countryCode: 'Pa', countryName: 'Panamá' },
    { countryCode: 'Pg', countryName: 'Papúa Nueva Guinea' },
    { countryCode: 'Py', countryName: 'Paraguay' },
    { countryCode: 'Pn', countryName: 'Pitcairn' },
    { countryCode: 'Pf', countryName: 'Polinesia Francesa' },
    { countryCode: 'Pl', countryName: 'Polonia' },
    { countryCode: 'Pt', countryName: 'Portugal' },
    { countryCode: 'Pr', countryName: 'Puerto Rico' },
    { countryCode: 'Qa', countryName: 'Qatar' },
    { countryCode: 'Gb', countryName: 'Reino Unido' },
    { countryCode: 'Cf', countryName: 'República Centroafricana' },
    { countryCode: 'Cz', countryName: 'República Checa' },
    { countryCode: 'Cd', countryName: 'República Democrática Del Congo' },
    { countryCode: 'Do', countryName: 'República Dominicana' },
    { countryCode: 'Rw', countryName: 'Ruanda' },
    { countryCode: 'Ro', countryName: 'Rumania' },
    { countryCode: 'Ru', countryName: 'Rusia' },
    { countryCode: 'Eh', countryName: 'Sahara Occidental' },
    { countryCode: 'Ws', countryName: 'Samoa' },
    { countryCode: 'As', countryName: 'Samoa Americana' },
    { countryCode: 'Bl', countryName: 'San Bartolome' },
    { countryCode: 'Kn', countryName: 'San Cristóbal Y Nieves' },
    { countryCode: 'Sm', countryName: 'San Marino' },
    { countryCode: 'Mf', countryName: 'San Martin (Parte Francesa)' },
    { countryCode: 'Pm', countryName: 'San Pedro Y Miquelon' },
    { countryCode: 'Vc', countryName: 'San Vicente Y Las Granadinas' },
    { countryCode: 'Sh', countryName: 'Santa Helena, Ascension Y Tristan De Acuña' },
    { countryCode: 'Lc', countryName: 'Santa Lucía' },
    { countryCode: 'St', countryName: 'Santo Tomé Y Príncipe' },
    { countryCode: 'Sn', countryName: 'Senegal' },
    { countryCode: 'Rs', countryName: 'Serbia' },
    { countryCode: 'Sc', countryName: 'Seychelles' },
    { countryCode: 'Sl', countryName: 'Sierra Leona' },
    { countryCode: 'Sg', countryName: 'Singapur' },
    { countryCode: 'Sx', countryName: 'Sint Maarten (Parte Neerlandesa)' },
    { countryCode: 'Sy', countryName: 'Siria' },
    { countryCode: 'So', countryName: 'Somalia' },
    { countryCode: 'Lk', countryName: 'Sri Lanka' },
    { countryCode: 'Sz', countryName: 'Suazilandia' },
    { countryCode: 'Za', countryName: 'Sudáfrica' },
    { countryCode: 'Sd', countryName: 'Sudán' },
    { countryCode: 'Ss', countryName: 'Sudán Del Sur' },
    { countryCode: 'Se', countryName: 'Suecia' },
    { countryCode: 'Ch', countryName: 'Suiza' },
    { countryCode: 'Sr', countryName: 'Surinam' },
    { countryCode: 'Sj', countryName: 'Svalbard Y Jan Mayen' },
    { countryCode: 'Th', countryName: 'Tailandia' },
    { countryCode: 'Tw', countryName: 'Taiwán' },
    { countryCode: 'Tz', countryName: 'Tanzania' },
    { countryCode: 'Tj', countryName: 'Tayikistán' },
    { countryCode: 'Io', countryName: 'Territorio Britanico Del Oceano Indico' },
    { countryCode: 'Tf', countryName: 'Territorios Australes Franceses' },
    { countryCode: 'Tl', countryName: 'Timor Oriental' },
    { countryCode: 'Tg', countryName: 'Togo' },
    { countryCode: 'Tk', countryName: 'Tokelau' },
    { countryCode: 'To', countryName: 'Tonga' },
    { countryCode: 'Tt', countryName: 'Trinidad Y Tobago' },
    { countryCode: 'Tn', countryName: 'Túnez' },
    { countryCode: 'Tm', countryName: 'Turkmenistán' },
    { countryCode: 'Tr', countryName: 'Turquía' },
    { countryCode: 'Tv', countryName: 'Tuvalu' },
    { countryCode: 'Ua', countryName: 'Ucrania' },
    { countryCode: 'Ug', countryName: 'Uganda' },
    { countryCode: 'Uy', countryName: 'Uruguay' },
    { countryCode: 'Uz', countryName: 'Uzbekistán' },
    { countryCode: 'Vu', countryName: 'Vanuatu' },
    { countryCode: 'Va', countryName: 'Vaticano' },
    { countryCode: 'Ve', countryName: 'Venezuela' },
    { countryCode: 'Vn', countryName: 'Vietnam' },
    { countryCode: 'Wf', countryName: 'Wallis Y Futuna' },
    { countryCode: 'Ye', countryName: 'Yemen' },
    { countryCode: 'Dj', countryName: 'Yibuti' },
    { countryCode: 'Zm', countryName: 'Zambia' },
    { countryCode: 'Zw', countryName: 'Zimbabue' },
    { countryCode: 'Aw', countryName: 'Aruba' },
    { countryCode: 'AG', countryName: 'Antigua y Barbuda' },
    { countryCode: 'KY', countryName: 'Islas Caimán' },
    { countryCode: 'Vg', countryName: 'Islas Vírgenes Británicas' },
];
