import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import LayoutComponent from '../../components/LayoutComponent';
import OrderService from '../../services/OrderService';
import { formatCurrency } from '../../utils/format';
import { cities, countries } from '../../constants';

export default function OrderPage() {
    const [order, setOrder] = useState({
        products: [],
    });
    const [isPedidosProcesados, setIsPedidosProcesados] = useState(false);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const getOrder = async () => {
            const order = await OrderService.getOrder(params.orderId);
            setOrder(order);
        };

        getOrder();
    }, [params]);

    useEffect(() => {
        const getPath = () => {
            const path = localStorage.getItem('path');
            if (path.includes('pedidos-procesados')) {
                setIsPedidosProcesados(true);
            }
        };
        getPath();
    }, []);

    const getInvoice = async () => {
        window.open(
            `https://api.truliftparts.com/api/invoice/${order.invoice_number._id}/pdf`,
            '_blank'
        );
    };

    return (
        <LayoutComponent>
            <div style={{ backgroundColor: '#F2F2F2', minHeight: 'calc(100vh - 130px)' }}>
                <div className="py-4 px-md-5 px-2">
                    <div className="d-flex gap-2">
                        <button
                            onClick={() => navigate(-1)}
                            className="btn btn-outline-primary mb-3"
                        >
                            Volver
                        </button>
                        {isPedidosProcesados && (
                            <button onClick={getInvoice} className="btn btn-outline-primary mb-3">
                                Imprimir
                            </button>
                        )}
                    </div>
                    <div className="row w-100 ">
                        <div className="col-lg-12">
                            <div className="card bg-white">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Producto</th>
                                                    <th scope="col">Cantidad</th>
                                                    <th scope="col">Valor unit</th>
                                                    <th scope="col">Valor total</th>
                                                    <th scope="col">Referencia</th>
                                                    <th scope="col">Número de orden</th>
                                                    <th scope="col">Número de orden cliente</th>
                                                    <th scope="col">Dirección de envió</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {order.products.length === 0 && (
                                                    <tr>
                                                        <td colSpan="5" className="text-center">
                                                            No hay productos
                                                        </td>
                                                    </tr>
                                                )}
                                                {order.products &&
                                                    order.products.map((product) => (
                                                        <tr key={product._id}>
                                                            <td>{product.name}</td>
                                                            <td>{product.quantity}</td>
                                                            <td>{formatCurrency(product.price)}</td>
                                                            <td>
                                                                {formatCurrency(
                                                                    product.price * product.quantity
                                                                )}
                                                            </td>
                                                            <td>{product.crossReference}</td>
                                                            <td>{order.order_number}</td>
                                                            <td>
                                                                {order.customer_purchase_order
                                                                    ? order.customer_purchase_order?.split(
                                                                          ':'
                                                                      )[1]
                                                                    : 'N/A'}
                                                            </td>
                                                            <td>
                                                                {' '}
                                                                {
                                                                    countries.find(
                                                                        (country) =>
                                                                            country.countryCode ===
                                                                            order?.address?.country
                                                                    )?.countryName
                                                                }
                                                                ,{' '}
                                                                {[
                                                                    cities.find(
                                                                        (city) =>
                                                                            city.StateCode ===
                                                                            order?.address?.state
                                                                    )?.StateName,
                                                                    cities.find(
                                                                        (city) =>
                                                                            city.CityCode ===
                                                                            order?.address?.city
                                                                    )?.CityName,
                                                                ]
                                                                    .filter((item) => item)
                                                                    .join(', ')}
                                                                , {order?.address?.address}
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{ backgroundColor: '#E0E0E0' }}
                                className="mt-3 py-2 px-3 rounded d-flex justify-content-end"
                            >
                                <span>
                                    Valor Total:{' '}
                                    <span className="text-primary">
                                        <b>{formatCurrency(order.total)}</b>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutComponent>
    );
}
