import React from 'react';
import LayoutComponent from '../../components/LayoutComponent';
import { useNavigate } from 'react-router-dom';

export default function SoportePage() {
    const navigate = useNavigate();
    return (
        <LayoutComponent>
            <button className="btn btn-outline-primary m-2" onClick={() => navigate(-1)}>
                Volver
            </button>
            <div className="mt-5 w-50" style={{ marginLeft: '150px' }}>
                <h1 className="text-secondary">Problemas Usando el Sitio?</h1>
                <hr style={{ borderTop: '3px solid' }} />
                <div className="row">
                    <div className="col-md-4">
                        <span className="text-info">Internet Explorer</span>
                    </div>
                    <div className="col-md-4">
                        <span className="text-info">Firefox</span>
                    </div>
                    <div className="col-md-4">
                        <span className="text-info">Chrome</span>
                    </div>
                </div>
                <hr style={{ borderTop: '5px solid' }} />

                <h3 className="text-secondary">Preguntas frecuentes</h3>
                <hr style={{ borderTop: '2px solid' }} />
                <p>
                    <strong>How do I update my IRMN password?</strong>
                    <br />
                    You can update your password by navigating to the Account tab, found on any page
                    of IRMN, and then click on User Settings. You will find the password field on
                    the right side of the page. Type in a new password, confirm the new password,
                    and click the Change Password button.
                    <br />
                </p>
                <p>
                    <strong>
                        How do I update IRMN usernames and passwords for personnel changes?
                    </strong>
                    <br />
                    You can call IRMN Support at 877-660-8381 or email us at marketing@tvh.com to
                    initiate usernames) and/or password(s) changes on your account. You may also
                    contact customer service at 913-829-1000 or 800-255-4109 for assistance.
                    <br />
                </p>
                <p>
                    <strong>How do I find a part number if I only have a partial number?</strong>
                    <br />
                    If you only have partial information, you can find the part number in several
                    different ways. You can find the information in research with the make and model
                    number of the truck. You can also go to Search and type in a partial part
                    number, and IRMN will go into our database and find any part number in our
                    system that includes those numbers. In Search you can also do a search for a
                    description of a part. For instance, you can type in "wire" and IRMN will bring
                    back any part that is in our system that has the word "wire" in its description.
                    <br />
                </p>
                <p>
                    <strong>
                        When I add parts to my order basket, I get stuck on the Shipping Information
                        window.
                    </strong>{' '}
                    <br />
                    Just type a purchase order number in the PO# field and click the ACCEPT button.
                    You can change the PO number, ship-to address, or shipping method at any time
                    before the order is committed by clicking the Update Shipping button of the
                    ORDER screen.
                    <br />
                </p>
                <p>
                    <strong>How do I find the carrier Tracking Number on an order?</strong> <br />
                    Click on History and find the order for the shipment in question. The tracking
                    number will be at the right end of that line. If you click on the tracking
                    number, it will take you directly to the website of the carrier and the page
                    that has your shipment's information. Only shipments via UPS or FedEx can be
                    tracked at this time.
                </p>
            </div>
        </LayoutComponent>
    );
}
