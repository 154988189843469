import { NavLink } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import LayoutAdminComponent from '../../components/LayoutAdminComponent'
import OrderService from '../../services/OrderService'

export default function PedidosTramitadosPage() {
  const [orders, setOrders] = useState([])
  useEffect(() => {
    const getOrders = async () => {
      const response = await OrderService.getProcessed()
      setOrders(response)
    }
    getOrders()
  }, [])


  return (
    <LayoutAdminComponent>
      <p className='text-primary title mb-4'>Pedidos tramitados</p>
      <div className="card shadow pt-3 pb-3">
        <div className="card-body">
          <table className='w-100 table-sm'>
            <thead>
              <tr>
                <th>Número de orden</th>
                <th>Bussiness Name</th>
                <th>Metodo de entrega</th>
                <th>Status</th>
                <th className='no-print'>Acción</th>
              </tr>
            </thead>
            <tbody>
              {
                (!orders || orders.length === 0) && (
                  <tr>
                    <td colSpan='5' className='text-center'>No hay pedidos tramitados</td>
                  </tr>
                )
              }
              {
                orders && orders.map(order => (
                  <tr key={order._id}>
                    <td>#{order.order_number}</td>
                    <td>{[order.user?.firstName, order.user?.lastName].join(' ')}</td>
                    <td>{order.delivery_method}</td>
                    <td>
                      <span className={`badge py-1 bg-success`}>Tramitado</span>
                    </td>
                    <td className='no-print'>
                      <NavLink className='d-block' to={`/bodega/pedido/${order._id}`}>Ver</NavLink>
                    </td>
                  </tr>
                ))
              }

            </tbody>
          </table>
        </div>
      </div>

    </LayoutAdminComponent>
  )
}
