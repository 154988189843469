import React from 'react'
import { NavLink } from 'react-router-dom'

export default function MenuContabilidad() {
    return (
        <>
            <li>
                <NavLink aria-current="page" to="/pedidos" className='nav-link text-white'>
                    Pedidos
                </NavLink>
            </li>
        </>

    )
}
