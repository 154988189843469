import { getAuth, postAuth } from '../utils/request';

class OrderService {
    async getMyOrders(invoiceSearch) {
        return await getAuth('user/orders', { invoiceSearch });
    }

    async getOrder(id) {
        return await getAuth(`order/${id}`);
    }
    async getOrders() {
        return await getAuth('order');
    }

    async approveOrder(id) {
        return await postAuth(`order/${id}/approve`);
    }

    async rejectOrder(id) {
        return await postAuth(`order/${id}/reject`);
    }

    async getPending() {
        return await getAuth('order/pending');
    }

    async getProcessed() {
        return await getAuth('order/processed');
    }

    async getCompleted() {
        return await getAuth('order/completed');
    }

    async setProcessed(orderId) {
        return await postAuth(`order/${orderId}/processed`);
    }

    async setCompleted(orderId) {
        return await postAuth(`order/${orderId}/completed`);
    }

    async updateShipping(orderId, shipping) {
        return await postAuth(`order/${orderId}/shipping`, shipping);
    }

    async updateAddress(orderId, address) {
        return await postAuth(`order/${orderId}/address`, address);
    }

    async savePayment(orderId, payload) {
        return await postAuth(`order/${orderId}/payment`, payload);
    }

    async createInvoice(orderId) {
        return await postAuth(`order/${orderId}/invoice`);
    }

    async getBackOrders() {
        return await getAuth('order/backorder');
    }

    async createBackOrder(cartId) {
        return await postAuth(`order/${cartId}/backorder`);
    }

    async getBackOrder(backOrderId) {
        return await getAuth(`order/backorder/${backOrderId}`);
    }

    async sendBackOrderAccounting(backOrderId) {
        return await postAuth(`order/backorder/${backOrderId}/status`);
    }

    async deleteBackOrder(backOrderId) {
        return await postAuth(`order/backorder/${backOrderId}`);
    }

    async orderPDF(orderId) {
        return await getAuth(`order/pdf/${orderId}`);
    }

    async getInvoice(orderId) {
        return await getAuth(`invoice/${orderId}/pdf`);
    }

    async changePrintedStatus(orderId, isLabelPrinted, isPackingListPrinted) {
        return await postAuth(`order/status/printed/${orderId}`, {
            isLabelPrinted,
            isPackingListPrinted,
        });
    }
}

export default new OrderService();
