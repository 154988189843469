import { userLoged, token } from "./auth";

const URI = `${process.env.REACT_APP_API_URL}/api`;

const post = async (path, payload, headers = {}) => {

    const resp = await fetch(`${URI}/${path}`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...headers,
        },
        body: JSON.stringify(payload),
    })
    return await resp.json();
}

const put = async (path, payload, headers = {}) => {

    const resp = await fetch(`${URI}/${path}`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            ...headers,
        },
        body: JSON.stringify(payload),
    })
    return await resp.json();
}

const deleteRequest = async (path, headers = {}) => {
    const resp = await fetch(`${URI}/${path}`, {
        method: 'DELETE',
        headers: {
            "Content-Type": "application/json",
            ...headers,
        },
    })
    return await resp.json();
}

const get = async (path, payload, headers = {}) => {
    const params = new URLSearchParams(payload).toString();
    const resp = await fetch(`${URI}/${path}${params ? '?' + params : ''}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...headers,
        },
    })
    return await resp.json();
}

const postAuth = async (path, payload) => {
    return await post(path, payload, { "Authorization": `Bearer ${token}` })
}
const putAuth = async (path, payload) => {
    return await put(path, payload, { "Authorization": `Bearer ${token}` })
}

const getAuth = async (path, payload) => {
    return await get(path, payload, { "Authorization": `Bearer ${token}` })
}

const deleteAuth = async (path) => {
    return await deleteRequest(path, { "Authorization": `Bearer ${token}` })
}

export {
    get,
    put,
    post,
    getAuth,
    putAuth,
    postAuth,
    deleteAuth,
    deleteRequest,
}