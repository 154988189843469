import React, { useEffect } from 'react'
import { Field, Form, Formik, FormikContext, useFormikContext } from 'formik'
import UserService from '../services/UserService'
import { userLoged } from '../utils/auth'
import Swal from 'sweetalert2';

function CuentaFormContent() {
    const { setValues, isSubmitting } = useFormikContext();

    useEffect(() => {
        const getUser = async () => {
            const resp = await UserService.getUser(userLoged._id);
            console.log(resp)
            setValues(resp)
        }

        getUser()
    }, [setValues])


    return (
        <div className="row w-100">

            <div className="col-lg-10 mb-2">
                <label htmlFor="">Nombre *</label>
                <Field type="text" className='form-control form-control-sm' id="firstName" name="firstName" />
            </div>
            <div className="col-lg-10 mb-2">
                <label htmlFor="">Apellido *</label>
                <Field type="text" className='form-control form-control-sm' id="lastName" name="lastName" />
            </div>
            <div className="col-lg-10 mb-2">
                <label htmlFor="">Correo electrónico</label>
                <Field type="email" className='form-control form-control-sm' id="email" name="email" />
            </div>
            <div className="col-lg-10 mb-2">
                <label htmlFor="">Teléfono</label>
                <Field type="text" className='form-control form-control-sm' id="phone" name="phone" />
            </div>
            <div className="col-lg-10 mb-4">
                <label htmlFor="">Fax</label>
                <Field type="text" className='form-control form-control-sm' id="fax" name="fax" />
            </div>
            <div className="col-lg-10 mb-2">
                <button className='btn btn-outline-primary btn-sm'>{isSubmitting ? 'Actualizando...' : 'Actualizar información'}</button>
            </div>


        </div>

    )
}



export default function CuentaForm() {

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        fax: '',
    }
    const validation = values => {
        const errors = {}
        if (!values.firstName) {
            errors.firstName = 'Requerido'
        }
        if (!values.lastName) {
            errors.lastName = 'Requerido'
        }
        return errors
    }

    const onSubmit = async (values, { setSubmitting }) => {
        console.log(values)
        setSubmitting(true)
        const resp = await UserService.update(userLoged._id, values);
        if (resp.user) {
            Swal.fire({
                icon: 'success',
                title: 'Actualizado',
                text: 'Información actualizada correctamente',
                showConfirmButton: false,
                timer: 1500
            })
        }
        setSubmitting(false)
    }


    return (
        <>
            <p className='mb-3 border-bottom'>Información de usuario</p>
            <Formik
                initialValues={initialValues}
                validation={validation}
                onSubmit={onSubmit}
            >

                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <Form>
                        <CuentaFormContent />
                    </Form>
                )}
            </Formik>
        </>

    )
}
