import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LayoutComponent from '../../components/LayoutComponent';
import ModalAddToCart from '../../components/ModalAddToCart';
import ProductService from '../../services/ProductService';
import { formatCurrency } from '../../utils/format';
import imgPlaceholder from '../../assets/placeholder.gif';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default function ProductPage() {
    const [showCartsModal, setShowCartsModal] = useState(false);
    const [showCrossReferences, setShowCrossReferences] = useState(false);
    const [crossReference, setCrossReference] = useState();
    const params = useParams();

    const [product, setProduct] = useState({});
    const [productRelated, setProductRelated] = useState([]);

    const [cantidad, setCantidad] = useState(1);
    const handleChangeCantidad = (e) => {
        let inputValue = e.target.value;
        // Validar que el valor sea un número entre 1 y 1000
        if (/^\d*$/.test(inputValue)) {
            let minValue = 1;
            let maxValue = 1000;
            const numValue = parseInt(inputValue, 10);
            if (numValue >= minValue && numValue <= maxValue) {
                setCantidad(e.target.value);
            } else if (numValue <= minValue) {
                setCantidad(minValue);
            } else {
                setCantidad(maxValue);
            }
        }
    };

    useEffect(() => {
        const productId = params['productId'];
        const crossReference = params['*'];
        setCrossReference(crossReference);
        console.log({ productId });
        setProduct({});
        getProduct(productId ?? '');
        getRelated(productId ?? '');
        setShowCrossReferences(false);
    }, [params]);

    async function getProduct(productId) {
        const product = await ProductService.getProduct(productId);
        setProduct(product);
    }

    async function getRelated(productId) {
        const product = await ProductService.getRelated(productId);
        console.log({ product });
        setProductRelated(product);
    }

    function openAddToCart() {
        setShowCartsModal(true);
    }

    return (
        <LayoutComponent>
            <ModalAddToCart
                show={showCartsModal}
                handleClose={() => setShowCartsModal(false)}
                product={product}
                crossReference={crossReference}
                quantity={cantidad}
            />
            <div style={{ backgroundColor: '#E0E0E0', minHeight: 'calc(100vh - 130px)' }}>
                <div className="container pt-4">
                    <div className="row">
                        <div className="col-lg-12">
                            <Link
                                to="/partes"
                                className="mb-2 mt-3 btn btn-sm btn-outline-primary "
                            >
                                Regresar
                            </Link>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8">
                            <div className="card mb-3 product-card-list">
                                <div className="card-body bg-white rounded pb-5 pt-5">
                                    <div className="row">
                                        <div className="col-md-6 d-flex justify-content-center">
                                            <img
                                                src={product.image || imgPlaceholder}
                                                alt=""
                                                className="img-fluid w-75 shadow"
                                                style={{ maxHeight: '300px' }}
                                            />
                                        </div>
                                        <div className="col-md-6 pt-lg-4">
                                            <h1
                                                className="text-primary mb-1"
                                                style={{ fontSize: 15 }}
                                            >
                                                {product?.name}
                                            </h1>
                                            <p className="text-primary mb-0">
                                                {[
                                                    product?.additional_fields?.brand,
                                                    product.additional_fields?.model,
                                                ]
                                                    .filter((item) => item)
                                                    .join(' / ')}
                                            </p>
                                            <p className="text-muted">{crossReference}</p>

                                            <p className="text-success">
                                                Precio{' '}
                                                {formatCurrency(product.price, product.currency)}
                                            </p>
                                            <label htmlFor="">Cant.</label>
                                            <div className="d-flex align-items-center">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    style={{ width: '75px', fontSize: 13 }}
                                                    max={1000}
                                                    onChange={handleChangeCantidad}
                                                    value={cantidad}
                                                />
                                                <small className="text-success ms-3">
                                                    En existencia
                                                </small>
                                            </div>
                                            <p className="text-primary">
                                                Precio total:{' '}
                                                {formatCurrency(
                                                    product.price * cantidad,
                                                    product.currency
                                                )}
                                            </p>

                                            <button
                                                className="btn btn-primary"
                                                onClick={openAddToCart}
                                            >
                                                Seleccionar parte
                                            </button>
                                            <br />

                                            <button
                                                className="mb-2 mt-3 btn btn-sm btn-outline-primary"
                                                onClick={() => {
                                                    setShowCrossReferences(!showCrossReferences);
                                                }}
                                            >
                                                Referencia cruzada
                                            </button>

                                            <ul
                                                className={`${
                                                    showCrossReferences ? 'd-block' : 'd-none'
                                                }`}
                                            >
                                                {product?.crossReferences &&
                                                    product?.crossReferences.map(
                                                        (crossReference) => (
                                                            <li>{crossReference}</li>
                                                        )
                                                    )}
                                                <li></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutComponent>
    );
}
