import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import ImageModal from '../../components/ImageModal'
import LayoutAdminComponent from '../../components/LayoutAdminComponent'
import ProductService from '../../services/ProductService'
import imgPlaceholder from '../../assets/placeholder.gif'

export default function ProductsPage() {
  const [products, setProducts] = useState([])
  const [showImageModal, setShowImageModal] = useState(false)
  const [productSelected, setProductSelected] = useState(null)


  useEffect(() => {
    const getProducts = async () => {
      const products = await ProductService.getAllProducts()
      setProducts(products.map(product => {
        return product;
      }))
    }
    getProducts()
  }, [])

  const openImageViewer = (product) => {
    setProductSelected(product)
    setShowImageModal(true)
  }

  return (
    <LayoutAdminComponent>

      <ImageModal
        show={showImageModal}
        product={productSelected}
        onSave={(product) => {
          setProductSelected(product)
          setProducts(products.map(p => {
            if (p._id === product._id) {
              return product
            }
            return p
          }))
        }}
        handleClose={() => { setShowImageModal(false) }}
      />
      <p className='text-primary title mb-4'>Productos</p>

      <div className="card boder-white shadow pt-3 pb-4">
        <div className="card-body">
          <table className='w-100'>
            <thead>
              <tr>
                <th>Imagen</th>
                <th className='text-start'>Nombre</th>
                <th>Marca</th>
                <th>Modelo</th>
                <th>Cantidad</th>
                <th>Precio</th>
                <th>Fecha creación</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {
                products && products.map(product => (
                  <tr key={product._id}>
                    <td role={'button'} onClick={() => { openImageViewer(product) }}>
                      <img src={product.image || imgPlaceholder} alt="" className='img-fluid rounded' width={60} />
                    </td>
                    <td className='text-start'>{product.name}</td>
                    <td>{product?.additional_fields?.brand}</td>
                    <td>{product?.additional_fields?.model}</td>
                    <td>{product?.available_quantity}</td>
                    <td>{product?.price} {product.currency}</td>
                    <td>{moment(product.metadata.created).format('DD MMM YY HH:mm a')}</td>
                    <td>
                      <NavLink className='d-block' to={`/admin/producto/${product._id}`}>Ver</NavLink>
                    </td>
                  </tr>
                ))
              }

            </tbody>
          </table>
        </div>
      </div>

    </LayoutAdminComponent >
  )
}
