import React from 'react'
import { NavLink } from 'react-router-dom'

export default function MenuBodega() {
    return (
        <>
            <li>
                <NavLink aria-current="page" to="/pedidos-pendientes" className='nav-link text-white'>
                    Pedidos Pendientes
                </NavLink>
            </li>
            <li>
                <NavLink aria-current="page" to="/pedidos-procesados" className='nav-link text-white'>
                    Pedidos Procesados
                </NavLink>
            </li>
            <li>
                <NavLink aria-current="page" to="/backorders" className='nav-link text-white'>
                    Backorders
                </NavLink>
            </li>
        </>

    )
}
