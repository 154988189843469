import React from 'react';
import { NavLink } from 'react-router-dom';

export default function MenuAdmin() {
    return (
        <>
            <li className="nav-item">
                <NavLink to="/usuarios" className="nav-link text-white">
                    Usuarios
                </NavLink>
            </li>
            <li>
                <NavLink to="/productos" className="nav-link text-white mb-2">
                    Productos
                </NavLink>
            </li>
            <hr />
            <li>
                <NavLink to="/gestor-pedidos" className="nav-link text-white">
                    Gestor de pedidos
                </NavLink>
            </li>
            <li>
                <NavLink to="/backorders" className="nav-link text-white">
                    Backorders
                </NavLink>
            </li>
            <li>
                <NavLink to="/reporte-ventas" className="nav-link text-white">
                    Reporte Ventas
                </NavLink>
            </li>
            <li>
                <NavLink to="/ranking-piezas-mas-vendidas" className="nav-link text-white">
                    Ranking piezas mas vendidas
                </NavLink>
            </li>
            <li>
                <NavLink to="/historico-busqueda" className="nav-link text-white">
                    Historico de búsquedas
                </NavLink>
            </li>
            <li>
                <NavLink to="/reporte-usuarios" className="nav-link text-white">
                    Reporte usuarios
                </NavLink>
            </li>

            <li>
                <NavLink to="/siigo" className="nav-link text-white">
                    Siigo
                </NavLink>
            </li>
        </>
    );
}
