import { get, post } from '../utils/request'

class SoporteService {

    async getSoportes() {
        return await get('soporte');
    }

    async getSoporte(soporteId) {
        return await get(`soporte/${soporteId}`);
    }

    async createSoporte(data) {
        return await post(`contact`, data);
    }

}

export default new SoporteService();