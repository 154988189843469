import React from 'react';
import { countries } from '../constants';
export const SelectCountry = ({ handleChange, defaultValue, className = 'form-control', disabled }) => {
    return (
        <div className="form-group">
            <label htmlFor="country">País</label>
            <select
                name="country"
                id="country"
                className={className}
                onChange={handleChange}
                defaultValue={defaultValue}
                disabled={disabled}
            >
                <option value={defaultValue ? defaultValue : ''}>
                    {countries.find(
                        (country) =>
                            country.countryCode?.toLowerCase() === defaultValue?.toLowerCase()
                    )
                        ? countries.find(
                              (country) =>
                                  country.countryCode?.toLowerCase() === defaultValue?.toLowerCase()
                          ).countryName
                        : 'Seleccione'}
                </option>
                {countries.map((country) => (
                    <option key={country.countryCode} value={country.countryCode}>
                        {country.countryName}
                    </option>
                ))}
            </select>
        </div>
    );
};
