import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import LayoutComponent from '../../components/LayoutComponent'
import SoporteService from '../../services/SoporteService'
import WhatsAppIcon from '../../assets/WhatsApp.svg'
import { userLoged } from '../../utils/auth'
const initialValues = {
  name: '',
  email: '',
  department: '',
  subject: '',
  message: '',
};
export default function SoporteContacto() {
  
  const sendSoporte = async (values,{resetForm}) => {
    const { name, email, department, subject, message} = values
    
    const soporte = await SoporteService.createSoporte({ name, email, department, subject, message, user: userLoged._id })
    if (soporte.status === 201) {
      alert(soporte.message)
      resetForm(initialValues)
    } else {
      alert(soporte.message)
    }      
    
  }
  const onValidate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'El nombre es requerido';
    } else if (!values.email) {
      errors.email = 'El correo es requerido';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Correo inválido';
    } else if (!values.department) {
      errors.department = 'El departamento es requerido';
    } else if (!values.subject) {
      errors.subject = 'El asunto es requerido';
    } else if (!values.message) {
      errors.message = 'El mensaje es requerido';
    }
    return errors;
  }

  return (
    <LayoutComponent>
        <div className='row mt-5' style={{ margin:'0px 150px' }}>
            <div className="col-md-6">
                <div className="w-50">
                    <h3>Información del usuario</h3>
                    <hr style={{ borderTop:'3px solid', marginTop:'0' }} />
                    <Formik initialValues={initialValues} validate={onValidate} onSubmit={sendSoporte}>{
                        (formik) => ( 
                          <Form>
                            <div className="form-group">
                              <label className='text-primary' for="name">Nombre*</label>
                              <Field type="text" className="form-control border-primary" name="name" id="name" aria-describedby="helpId" placeholder="Nombre"/>
                              {formik.errors.name && <ErrorMessage name="name" component="small" className='text-danger' />}
                            </div>
                            <div className="form-group">
                              <label className='text-primary' for="email">Email</label>
                              <Field type="email" className="form-control border-primary" name="email" id="email" aria-describedby="helpId" placeholder="Email"/>
                              {formik.errors.email && <ErrorMessage name="email" component="small" className='text-danger' />}
                            </div>
                            <div className="form-group">
                              <Field as="select" className="form-control border-primary" placeholder='Seleccione el departamento' name="department" id="department">
                                <option>Seleccione el departamento</option>
                                <option value="Amazonas">Amazonas</option>
                                <option value="Antioquia">Antioquia</option>
                                <option value="Arauca">Arauca</option>
                                <option value="Atlántico">Atlántico</option>
                                <option value="Bolívar">Bolívar</option>
                                <option value="Boyacá">Boyacá</option>
                                <option value="Caldas">Caldas</option>
                                <option value="Caquetá">Caquetá</option>
                                <option value="Casanare">Casanare</option>
                                <option value="Cauca">Cauca</option>
                                <option value="Cesar">Cesar</option>
                                <option value="Chocó">Chocó</option>
                                <option value="Córdoba">Córdoba</option>
                                <option value="Cundinamarca">Cundinamarca</option>
                                <option value="Guainía">Guainía</option>
                                <option value="Guaviare">Guaviare</option>
                                <option value="Huila">Huila</option>
                                <option value="La Guajira">La Guajira</option>
                                <option value="Magdalena">Magdalena</option>
                                <option value="Meta">Meta</option>
                                <option value="Nariño">Nariño</option>
                                <option value="Norte de Santander">Norte de Santander</option>
                                <option value="Putumayo">Putumayo</option>
                                <option value="Quindío">Quindío</option>
                                <option value="Risaralda">Risaralda</option>
                                <option value="San Andrés y Providencia">San Andrés y Providencia</option>
                                <option value="Santander">Santander</option>
                                <option value="Sucre">Sucre</option>
                                <option value="Tolima">Tolima</option>
                                <option value="Valle del Cauca">Valle del Cauca</option>
                                <option value="Vaupés">Vaupés</option>
                                <option value="Vichada">Vichada</option>
                              </Field>
                              {formik.errors.department && <ErrorMessage name="department" component="small" className='text-danger' />}
                            </div>

                            <div className="form-group">
                              <label className='text-primary' for="subject">Asunto</label>
                              <Field as="textarea" className="form-control border-primary" name="subject" id="subject" rows="3"></Field>
                              {formik.errors.subject && <ErrorMessage name="subject" component="small" className='text-danger' />}
                            </div>
                            <div className="form-group">
                              <label className='text-primary' for="message">Mensaje</label>
                              <Field as="textarea" className="form-control border-primary" name="message" id="message" rows="6"></Field>
                              {formik.errors.message && <ErrorMessage name="message" component="small" className='text-danger' />}
                            </div>

                            <button type="submit" className="btn btn-outline-primary">Enviar</button>
                          </Form>
                        )}
                    </Formik>
                </div>
            </div>
            <div className="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1958.1737215114335!2d-74.79372326052219!3d11.012537259163157!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sV%C3%ADa%2040%20%23%2073-290%2C%20Oficina%20624%20Barranquilla%2C%20Atl%C3%A1ntico%20Colombia!5e0!3m2!1ses-419!2smx!4v1676515634439!5m2!1ses-419!2smx" className='w-100' height="450"></iframe>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-4">
                        <h3>Dirección</h3>
                        <p>
                            Vía 40 # 73-290, Oficina 624
                            Barranquilla, Atlántico
                            Colombia
                        </p>
                    </div>
                    <div className="col-md-4">
                        <h3>Teléfono</h3>
                        <a href='tel:3147334288'>+57 314 7334288</a>
                    </div>
                    <div className="col-md-4">
                        <h3>Correo electrónico</h3>
                        <a href='mailto:soporte@truliftparts.com'>soporte@truliftparts.com</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <p>Habla directamente con nosotros a trávesde nuestra línea de whatsapp</p>
                    </div>
                    <div className="col-md-6">
                        <a href=""><img src={WhatsAppIcon} alt="" /></a>
                    </div>
                </div>
            </div>
        </div>
    </LayoutComponent>
  )
}
