import Swal from 'sweetalert2';
import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';

import loginImage from '../assets/loginImage.png';
import UserService from '../services/UserService';
import { NavLink } from 'react-router-dom';
import { SelectCountry } from '../components/SelectCountry';
import { SelectState } from '../components/SelectState';
import { SelectCity } from '../components/SelectCity';

const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    actividadEconomica: '',
    address: '',
};

export default function SignupPage() {
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [loginErrors, setLoginErrors] = useState('');
    const [address, setAddress] = useState({
        address: '',
        country: '',
        state: '',
        city: '',
    });
    const login = async (values) => {
        if (!address.country || !address.state || !address.city) {
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Debes seleccionar un pais, estado y ciudad',
            });
        }
        const newAddress = {
            ...address,
            address: values.address,
        }
        const data = {
            ...values,
            address: newAddress,
        };
        console.log(data)
        setIsSubmiting(true);
        setLoginErrors(undefined);
        const resp = await UserService.requestUser(data);
        setIsSubmiting(false);
        if (resp && typeof resp !== 'string' && resp.success) {
            window.location.href = '/request-user/success';
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: resp.message,
            });
        }
    };

    const onValidate = (values) => {
        const errors = {};
        if (!values.firstname) {
            errors.firstname = 'Los nombres es requerido';
        }
        if (!values.lastname) {
            errors.lastname = 'Los apellidos son requeridos';
        }
        if (!values.email) {
            errors.email = 'El correo electrónico es requerido';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Correo inválido';
        }

        if (!values.address) {
            errors.address = 'La dirección es requerida';
        }

        if (!values.phone) {
            errors.phone = 'El número de telefono es requerido';
        }

        if (!values.actividadEconomica) {
            errors.actividadEconomica = 'La actividad economica es requerida';
        }
        return errors;
    };

    const handleChangeAddress = (e) => {
        setAddress({
            ...address,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div>
            <div className="row mx-0">
                <div className="col-md-6 col-xl-5 col-xxl-4 offset-xxl-1 d-flex align-items-center justify-content-center py-5 py-md-0">
                    <div className="px-3 px-md-2 px-lg-5">
                        <h3 className="text-primary mb-4">
                            Llena el siguiente formulario para continuar con la solicitud de tu
                            cuenta.
                        </h3>
                        <h5 className="text-primary">Datos personales</h5>
                        <div>
                            <Formik
                                initialValues={initialValues}
                                validate={onValidate}
                                onSubmit={login}
                            >
                                {(formik) => (
                                    <Form>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="firstname">Nombres</label>
                                                    <Field
                                                        type="text"
                                                        name="firstname"
                                                        id="firstname"
                                                        className="form-control"
                                                        placeholder="Escribe tu nombre completo"
                                                    />
                                                    {formik.errors.firstname && (
                                                        <ErrorMessage
                                                            name="firstname"
                                                            component="small"
                                                            className="text-danger"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="lastname">Apellidos</label>
                                                    <Field
                                                        type="text"
                                                        name="lastname"
                                                        id="lastname"
                                                        className="form-control"
                                                        placeholder="Escribe tu nombre completo"
                                                    />
                                                    {formik.errors.lastname && (
                                                        <ErrorMessage
                                                            name="lastname"
                                                            component="small"
                                                            className="text-danger"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <label htmlFor="email">Correo electrónico</label>
                                            <Field
                                                type="email"
                                                name="email"
                                                id="email"
                                                className="form-control"
                                                placeholder="Escribe tu correo electrónico"
                                            />
                                            {formik.errors.email && (
                                                <ErrorMessage
                                                    name="email"
                                                    component="small"
                                                    className="text-danger"
                                                />
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <SelectCountry handleChange={handleChangeAddress} />
                                            <SelectState
                                                handleChange={handleChangeAddress}
                                                country={address.country}
                                            />
                                            <SelectCity
                                                handleChange={handleChangeAddress}
                                                country={address.country}
                                                state={address.state}
                                            />
                                        </div>
                                        <div className="form-group ">
                                            <label htmlFor="address">Dirección</label>
                                            <Field
                                                type="text"
                                                name="address"
                                                id="address"
                                                className="form-control"
                                                placeholder="Escribe tu dirección"
                                            />
                                            {formik.errors.address && (
                                                <ErrorMessage
                                                    name="address"
                                                    component="small"
                                                    className="text-danger"
                                                />
                                            )}
                                        </div>
                                        <div className="form-group ">
                                            <label htmlFor="">Número de telefono</label>
                                            <Field
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                className="form-control"
                                                placeholder="Escribe tu número de telefono"
                                            />
                                            {formik.errors.phone && (
                                                <ErrorMessage
                                                    name="phone"
                                                    component="small"
                                                    className="text-danger"
                                                />
                                            )}
                                        </div>

                                        <div className="form-group ">
                                            <label htmlFor="actividadEconomica">
                                                Actividad economica
                                            </label>
                                            <Field
                                                type="tel"
                                                name="actividadEconomica"
                                                id="actividadEconomica"
                                                className="form-control"
                                                placeholder="Escribe tu actividad económica"
                                            />
                                            {formik.errors.actividadEconomica && (
                                                <ErrorMessage
                                                    name="actividadEconomica"
                                                    component="small"
                                                    className="text-danger"
                                                />
                                            )}
                                        </div>

                                        {loginErrors && (
                                            <p className="text-danger">{loginErrors}</p>
                                        )}
                                        <button
                                            type="submit"
                                            className="btn btn-primary py-md-2 py-lg-3 w-100 mb-4 mt-4"
                                        >
                                            {isSubmiting ? 'Enviando...' : 'Siguiente'}
                                        </button>
                                        <NavLink to="/login" className="text-primary text-center">
                                            ¿Ya tienes una cuenta? Inicia sesión
                                        </NavLink>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
                <div
                    className="col-md-6 col-xl-7 col-xxl-6 offset-xxl-1 d-none d-md-flex align-items-center"
                    style={{ height: '100vh' }}
                >
                    <div>
                        <img src={loginImage} alt="login-bg" className="img-fluid my-5 py-4" />
                    </div>
                </div>
            </div>
        </div>
    );
}
