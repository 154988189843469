import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from '@mui/material';

import CartService from '../services/CartService';
import Swal from 'sweetalert2';

export const BottomNav = ({ handleSelect, avaliableProducts, dispatchShow }) => {
    const [optionSelected, setOptionSelected] = React.useState('');
    const [actionSelected, setActionSelected] = React.useState('1');
    const [nameOrder, setNameOrder] = React.useState('');
    const [carts, setCarts] = React.useState([]);

    const userLoged = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    const createCart = async () => {
        const response = await CartService.create({
            name: nameOrder || 'Nuevo carrito de compras',
            user_id: userLoged._id,
        });
        return response;
    };

    const getCart = async (cartId) => {
        const response = await CartService.getOne(cartId);
        return response;
    };

    const handleSubmit = async () => {
        if (optionSelected === 'mover' && actionSelected === 'crear') {
            const cart = createCart();
            const showLoading = async () => {
                const swalWithLoading = Swal.mixin({
                    title: '¡Procesando!',
                    text: 'Espere por favor...',
                    icon: 'info',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    onBeforeOpen: () => {
                        Swal.showLoading();
                    },
                });

                await swalWithLoading.fire();
            };

            const hideLoading = () => {
                Swal.close();
            };

            showLoading();

            try {
                const response = await cart;
                const cartId = response._id;

                await CartService.massiveAddProduct(cartId, avaliableProducts);

                Swal.fire({
                    icon: 'success',
                    title: 'Productos agregados al carrito',
                    showConfirmButton: false,
                    timer: 1500,
                });

                navigate(`/carrito/${cartId}`);
            } catch (error) {
                console.error('Error al agregar productos al carrito', error);
                Swal.fire({
                    icon: 'error',
                    title: '¡Error!',
                    text: 'Hubo un problema al agregar productos al carrito.',
                });
            } finally {
                hideLoading();
            }
        }

        if (optionSelected === 'mover' && (actionSelected !== '1' || actionSelected !== 'crear')) {
            const cart = getCart(actionSelected);

            const showLoading = async () => {
                const swalWithLoading = Swal.mixin({
                    title: '¡Procesando!',
                    text: 'Espere por favor...',
                    icon: 'info',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    onBeforeOpen: () => {
                        Swal.showLoading();
                    },
                });

                await swalWithLoading.fire();
            };

            const hideLoading = () => {
                Swal.close();
            };

            showLoading();

            try {
                const response = await cart;
                const cartId = response._id;

                await CartService.massiveAddProduct(cartId, avaliableProducts);

                Swal.fire({
                    icon: 'success',
                    title: 'Productos agregados al carrito',
                    showConfirmButton: false,
                    timer: 1500,
                });

                navigate(`/carrito/${cartId}`);
            } catch (error) {
                console.error('Error al agregar productos al carrito', error);
                Swal.fire({
                    icon: 'error',
                    title: '¡Error!',
                    text: 'Hubo un problema al agregar productos al carrito.',
                });
            } finally {
                hideLoading();
            }

            return;
        }

        if (optionSelected === 'borrar') {
            const productsIds = avaliableProducts.map((product) => product._id);
            const noShowProducts = JSON.parse(localStorage.getItem('noShowProducts'));
            if (noShowProducts) {
                productsIds.push(...noShowProducts);
            }
            localStorage.setItem('noShowProducts', JSON.stringify(productsIds));
            dispatchShow(true);
        }
    };

    React.useEffect(() => {
        const fetchCarts = async () => {
            const response = await CartService.getCarts(userLoged._id);
            setCarts(response);
        };
        fetchCarts();
    }, [optionSelected]);

    return (
        <div
            style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                padding: '0 10px',
                backgroundColor: '#fff',
                borderTop: '1px solid #ccc',
                marginRigth: '1rem',
            }}
        >
            <div>
                <Checkbox onChange={(e) => handleSelect(e.target.checked)} color="primary" />
                <b>Seleccionar todo</b>
            </div>
            <div>
                <select
                    value={optionSelected}
                    onChange={(e) => setOptionSelected(e.target.value)}
                    className="form-control"
                    style={{ width: '170px' }}
                >
                    <option value="1">Seleccione</option>
                    <option value="mover">Mover</option>
                    <option value="borrar">Borrar</option>
                </select>
            </div>

            <div>
                {optionSelected === 'mover' && (
                    <select
                        name=""
                        id=""
                        value={actionSelected}
                        onChange={(e) => setActionSelected(e.target.value)}
                        className="form-control"
                        style={{ width: '265px' }}
                    >
                        <option value="1">Seleccione</option>
                        <option value="crear">Crear nuevo carrito de compras</option>
                        {carts?.map((cart) => (
                            <option value={cart._id}>{cart.name}</option>
                        ))}
                    </select>
                )}
            </div>
            <div>
                {actionSelected === 'crear' && (
                    <input
                        type="text"
                        placeholder="Nombre del carrito"
                        className="form-control"
                        style={{ width: '250px' }}
                        value={nameOrder}
                        onChange={(e) => setNameOrder(e.target.value)}
                    />
                )}
            </div>
            <div>
                <button
                    className="btn btn-primary"
                    // disabled={optionSelected === 'borrar'}
                    onClick={handleSubmit}
                >
                    Aplicar
                </button>
            </div>
        </div>
    );
};
