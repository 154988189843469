const loadImagePlaceholder = ({ currentTarget }) => {
    console.log("error loading image");
    currentTarget.onerror = null
    currentTarget.src = 'https://via.placeholder.com/150'
}


module.exports = {
    loadImagePlaceholder,
}
