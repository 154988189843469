import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import LayoutAdminComponent from '../../components/LayoutAdminComponent';
import {
    cities,
    countries,
    idTypes,
    payment_methods,
    statusBadgeColor,
    statusBadgeText,
} from '../../constants';
import OrderService from '../../services/OrderService';
import { formatCurrency } from '../../utils/format';
import CircularProgress from '@mui/material/CircularProgress';

export default function ContabilidadOrderPage() {
    const [order, setOrder] = useState({
        products: [],
    });
    const [user, setUser] = useState({});

    const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);

    const params = useParams();
    const navigate = useNavigate();
    const totalPrice = formatCurrency(order?.total);
    const iva = formatCurrency(order?.total * 0.19);
    const total = formatCurrency(
        order.address?.city?.country_code === 'Co'
            ? order?.total + order?.total * 0.19
            : order?.total
    );

    const getOrder = async () => {
        const order = await OrderService.getOrder(params.orderId);
        setOrder(order);
    };

    const openAprobar = () => {
        Swal.fire('Aprobar orden', '¿Está seguro de aprobar esta orden?', 'question').then(
            async (result) => {
                if (result.isConfirmed) {
                    const resp = await OrderService.approveOrder(params.orderId);
                    console.log(resp);
                    if (resp._id) {
                        getOrder();
                        Swal.fire('Orden aprobada', 'La orden ha sido aprobada', 'success');
                    } else {
                        Swal.fire('Error', 'La orden no se pudo aprobar.' + resp.error, 'error');
                    }
                }
            }
        );
    };

    const openRechazar = () => {
        Swal.fire('Rechazar orden', '¿Está seguro de rechazar esta orden?', 'question').then(
            async (result) => {
                if (result.isConfirmed) {
                    const resp = await OrderService.rejectOrder(params.orderId);
                    if (resp._id) {
                        getOrder();
                        Swal.fire('Orden rechazada', 'La orden ha sido rechazada', 'success');
                    }
                }
            }
        );
    };

    const generarFactura = async () => {
        if (order.paymentMethod && order.paymentDate) {
            setIsLoadingInvoice(true);
            const resp = await OrderService.createInvoice(params.orderId);
            if (resp._id) {
                Swal.fire({
                    icon: 'success',
                    title: 'Factura generada',
                    text: `Se ha generado la factura ${resp.name}`,
                    confirmButtonText: 'Ok',
                    timer: 1000,
                });
                setIsLoadingInvoice(false);
            }
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Debe completar la info de método de pago',
                text: `Para poder generar la factura se necesita registrar la forma y fecha de pago `,
                confirmButtonText: 'Ok',
            });
            setIsLoadingInvoice(false);
        }
        getOrder();
        setIsLoadingInvoice(false);
    };
    const [paymentMethod, setPaymentMethod] = useState('');
    const [paymentDate, setPaymentDate] = useState('');

    const handleChange = (e) => {
        if (e.target.name === 'paymentMethod') {
            setPaymentMethod(e.target.value);
        } else if (e.target.name === 'paymentDate') {
            setPaymentDate(e.target.value);
        }
    };

    useEffect(() => {
        const getOrder = async () => {
            const order = await OrderService.getOrder(params.orderId);
            setOrder(order);
            setPaymentDate(order.paymentDate);
            setPaymentMethod(order.paymentMethod);
            setUser(order.user);
        };
        getOrder();
    }, [params]);

    const savePaymentMethod = async (e) => {
        if (!paymentMethod || !paymentDate) {
            Swal.fire({
                icon: 'warning',
                title: 'Debe completar la info de método de pago',
                text: `Para poder generar la factura se necesita registrar la forma y fecha de pago `,
                confirmButtonText: 'Ok',
                timer: 3000,
            });
            return;
        }
        const resp = await OrderService.savePayment(params.orderId, { paymentMethod, paymentDate });
        if (resp._id) {
            Swal.fire({
                icon: 'success',
                title: 'Método de pago guardado',
                text: 'El método de pago ha sido guardado',
                confirmButtonText: 'Ok',
                timer: 1000,
            }).then(() => {
                getOrder();
            });
        }
    };
    // console.log( order.address?.length && order?.address[0])
    return (
        <LayoutAdminComponent>
            <div>
                <div className="py-2 px-md-5 px-2">
                    <div className="mb-4">
                        <span
                            role="button"
                            className="text-decoration-none text-primary"
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            Volver
                        </span>
                    </div>
                    <div className="d-flex align-items-center pb-3">
                        <h4 className="text-primary me-3">Orden #{order.order_number}</h4>
                        <span className={`badge ${statusBadgeColor[order.status]}`}>
                            {statusBadgeText[order.status]}
                        </span>
                    </div>

                    <div className="row w-100 mb-3">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body pt-3">
                                    <div className="d-flex justify-content-between border-bottom align-items-center mb-2 pb-2">
                                        <p className="mb-0">Método de pago</p>
                                        {!order.paymentMethod && !order.paymentDate && (
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={savePaymentMethod}
                                                disabled={!paymentMethod || !paymentDate}
                                            >
                                                Procesar pago
                                            </button>
                                        )}
                                    </div>
                                    {order.paymentMethod && order.paymentDate ? (
                                        <>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <p className="text-secondary mb-0">
                                                        Forma de pago:
                                                    </p>
                                                    <p className="text-dark">
                                                        {
                                                            payment_methods.find(
                                                                (method) =>
                                                                    method.id == order.paymentMethod
                                                            )?.name
                                                        }
                                                    </p>
                                                </div>
                                                <div className="col-lg-6">
                                                    <p className="text-secondary mb-0">
                                                        Fecha de pago:
                                                    </p>
                                                    <p className="text-dark">{order.paymentDate}</p>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <label htmlFor="">Forma de pago</label>
                                                <select
                                                    name="paymentMethod"
                                                    id="paymentMethod"
                                                    className="form-control form-control-sm"
                                                    value={paymentMethod}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Seleccione</option>
                                                    {payment_methods.map((method) => (
                                                        <option
                                                            value={method.id}
                                                            selected={
                                                                method.id === order.payment_method
                                                            }
                                                        >
                                                            {method.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-lg-6">
                                                <label htmlFor="">Fecha de pago</label>
                                                <input
                                                    type="date"
                                                    name="paymentDate"
                                                    id="paymentDate"
                                                    className="form-control form-control-sm"
                                                    value={paymentDate}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="border-bottom d-flex justify-content-between align-items-center">
                                        <p className="mb-0 mt-0">Factura</p>
                                        {!order.invoice_number?._id ? (
                                            <button
                                                disabled={!order.paymentMethod ? true : false}
                                                className="btn btn-sm btn-primary mb-1 mt-0 w-25"
                                                onClick={generarFactura}
                                            >
                                                {isLoadingInvoice ? (
                                                    <CircularProgress size={20} color="inherit" />
                                                ) : (
                                                    'Generar factura'
                                                )}
                                            </button>
                                        ) : (
                                            <a
                                                rel="noreferrer"
                                                href={`${process.env.REACT_APP_API_URL}/api/invoice/${order.invoice_number._id}/pdf`}
                                                target="_blank"
                                            >
                                                <button className="btn btn-sm btn-primary mb-1 mt-0">
                                                    Visualizar
                                                </button>
                                            </a>
                                        )}
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12">
                                            {!order.invoice_number?._id ? (
                                                <p className="mb-0 mt-3 text-secondary">
                                                    Factura aun no generada
                                                </p>
                                            ) : (
                                                <>
                                                    <p className="mb-0 mt-3 mb-0 ">
                                                        Factura numero: {order.invoice_number.name}
                                                    </p>
                                                    <p className="">
                                                        Fecha: {order.invoice_number.date}
                                                    </p>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row w-100 mb-3">
                        <div className="col-lg-12">
                            <div className="card bg-white">
                                <div className="card-body">
                                    <p className="border-bottom">Cliente</p>
                                    <table className="table table-sm w-100">
                                        <thead>
                                            <tr>
                                                <th>Bussiness Name</th>
                                                <th>Identificación</th>
                                                <th>Email</th>
                                                <th>Telefono</th>
                                                <th>País</th>
                                                <th>Ciudad</th>
                                                <th>Dirección</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr key={order._id}>
                                                <td>
                                                    {[order.user?.firstName, order.user?.lastName]
                                                        .filter((item) => item)
                                                        .join(' ')}
                                                </td>
                                                <td>
                                                    {[
                                                        idTypes[order.user?.id_type],
                                                        order.user?.identification,
                                                    ].join(': ')}
                                                </td>
                                                <td>{order.user?.email}</td>
                                                <td>{order.user?.phone}</td>
                                                <td>
                                                    {
                                                        countries.find(
                                                            (country) =>
                                                                country.countryCode ===
                                                                user.address?.city?.country_code
                                                        )?.countryName
                                                    }
                                                </td>
                                                <td>
                                                    {[
                                                        cities.find(
                                                            (city) =>
                                                                city.StateCode ===
                                                                user.address?.city?.state_code
                                                        )?.StateName,
                                                        cities.find(
                                                            (city) =>
                                                                city.CityCode ===
                                                                user?.address?.city?.city_code
                                                        )?.CityName,
                                                    ]
                                                        .filter((item) => item)
                                                        .join(', ')}
                                                </td>
                                                <td>
                                                    {[
                                                        order.address?.address,
                                                        order.address?.apartment,
                                                    ]
                                                        .filter((item) => item)
                                                        .join(', ')}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row w-100 ">
                        <div className="col-lg-12">
                            <div className="card bg-white">
                                <div className="card-body">
                                    <p className="border-bottom">Productos</p>
                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Nombre producto</th>
                                                    <th scope="col">Cantidad</th>
                                                    <th scope="col">Valor unit</th>
                                                    <th scope="col">Valor total</th>
                                                    <th scope="col">Referencia cruzada</th>
                                                    <th scope="col">Referencia interna</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {order.products.length === 0 && (
                                                    <tr>
                                                        <td colSpan="5" className="text-center">
                                                            No hay productos
                                                        </td>
                                                    </tr>
                                                )}
                                                {order.products &&
                                                    order.products.map((product) => (
                                                        <tr key={product._id}>
                                                            <td>{product.name}</td>
                                                            <td>{product.quantity}</td>
                                                            <td>{formatCurrency(product.price)}</td>
                                                            <td>
                                                                {formatCurrency(
                                                                    product.price * product.quantity
                                                                )}
                                                            </td>
                                                            <td>{product.crossReference}</td>
                                                            <td>{product.internalReference}</td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{ backgroundColor: '#E0E0E0' }}
                                className="mt-3 py-2 px-3 rounded d-flex justify-content-end gap-2"
                            >
                                {order.address?.city?.country_code === 'Co' && (
                                    <>
                                        <span>
                                            Subtotal:{' '}
                                            <span className="text-primary">
                                                <b>{totalPrice}</b>
                                            </span>
                                        </span>
                                        <span>
                                            IVA:{' '}
                                            <span className="text-primary">
                                                <b>{iva}</b>
                                            </span>
                                        </span>
                                    </>
                                )}
                                <span>
                                    Valor Total:{' '}
                                    <span className="text-primary">
                                        <b>{total}</b>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mt-4">
                            {order.status === 'pending' &&
                                order.paymentMethod &&
                                order.invoice_number && (
                                    <>
                                        <button
                                            className="btn btn-outline-danger btn-sm me-3"
                                            onClick={openRechazar}
                                        >
                                            Rechazar
                                        </button>
                                        <button
                                            className="btn btn-primary btn-sm"
                                            onClick={openAprobar}
                                        >
                                            Aprobar
                                        </button>
                                    </>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </LayoutAdminComponent>
    );
}
