import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import UserService from '../../services/UserService';
import LayoutAdminComponent from '../../components/LayoutAdminComponent';
import ChangePassword from '../../components/ChangePassword';
import UserSiigoInfo from '../../components/UserSiigoInfo';
import Swal from 'sweetalert2';
import { SelectCountry } from '../../components/SelectCountry';
import { SelectState } from '../../components/SelectState';
import { SelectCity } from '../../components/SelectCity';
import { cities, countries } from '../../constants';

export default function UserDetailsPage() {
    let { userId } = useParams();
    const [user, setUser] = useState({});
    const [address, setAddress] = useState({
        address: user.addresses?.length && user?.addresses[0]?.address,
        country:
            user.addresses?.length &&
            (user?.addresses[0]?.country ||
                user?.addresses[0]?.city?.country_code ||
                user?.address?.city?.country_code ||
                user?.address?.country),
        state:
            user.addresses?.length &&
            (user?.addresses[0]?.state ||
                user?.addresses[0]?.city?.state_code ||
                user?.address?.city?.state_code ||
                user?.address?.state),
        city:
            user.addresses?.length &&
            (user?.addresses[0]?.city ||
                user?.addresses[0]?.city?.city_code ||
                user?.address?.city?.city_code ||
                user?.address?.city),
        details: user.addresses?.length && user?.addresses[0]?.address?.split(',')[1],
    });
    // otra hpta chambonada
    const [address1, setAddress1] = useState({});
    const [address2, setAddress2] = useState({});
    const [address3, setAddress3] = useState({});
    const [address4, setAddress4] = useState({});
    const [address5, setAddress5] = useState({});

    const onSubmit = (values, { setSubmitting }) => {
        updateUser(values);
        setTimeout(() => {
            setSubmitting(false);
        }, 400);
    };

    const validateForm = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'Correo electrónico es requerido';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Correo electrónico inválido';
        }
        if (!values.firstName) {
            errors.firstName = 'El campo nombres es requerido';
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            identification: '',
            id_type: '',
        },
        validate: validateForm,
        onSubmit: onSubmit,
    });

    useEffect(() => {
        const getUser = async (userId) => {
            const resp = await UserService.getUser(userId);
            if (resp) {
                setUser(resp);
                setAddress({
                    address: resp?.address?.address || '',
                    country:
                        resp?.addresses[0]?.country ||
                        resp?.addresses[0]?.city?.country_code ||
                        resp?.address?.city?.country_code ||
                        resp?.address?.country,
                    state:
                        resp?.addresses[0]?.state ||
                        resp?.addresses[0]?.city?.state_code ||
                        resp?.address?.city?.state_code ||
                        resp?.address?.state,
                    city:
                        resp?.addresses[0]?.city ||
                        resp?.addresses[0]?.city?.city_code ||
                        resp?.address?.city?.city_code ||
                        resp?.address?.city,
                    details: resp?.address?.details || '',
                });
                //** Chambonada */
                // setAddress1({
                //     address: resp?.addresses[0]?.address || '',
                //     country: resp?.addresses[0]?.country,
                //     state: resp?.addresses[0]?.state,
                //     city: resp?.addresses[0]?.city,
                // });
                // setAddress2({
                //     address: resp?.addresses[1]?.address || '',
                //     country: resp?.addresses[1]?.country,
                //     state: resp?.addresses[1]?.state,
                //     city: resp?.addresses[1]?.city,
                // });
                // setAddress3({
                //     address: resp?.addresses[2]?.address || '',
                //     country: resp?.addresses[2]?.country,
                //     state: resp?.addresses[2]?.state,
                //     city: resp?.addresses[2]?.city,
                // });
                // setAddress4({
                //     address: resp?.addresses[3]?.address || '',
                //     country: resp?.addresses[3]?.country,
                //     state: resp?.addresses[3]?.state,
                //     city: resp?.addresses[3]?.city,
                // });
                // setAddress5({
                //     address: resp?.addresses[4]?.address || '',
                //     country: resp?.addresses[4]?.country,
                //     state: resp?.addresses[4]?.state,
                //     city: resp?.addresses[4]?.city,
                // });

                formik.setValues(resp);
            }
        };
        getUser(userId);
    }, [userId]);

    const getUser = async (userId) => {
        const resp = await UserService.getUser(userId);
        if (resp) {
            setUser(resp);
            formik.setValues(resp);
        }
    };
    const updateUser = async (payload) => {
        payload.address = address;
        payload.address.address =
            address.address + ',' + address?.details ||
            user.addresses[0]?.address + ',' + address?.details;
        console.log({ payload });
        const resp = await UserService.update(userId, payload);
        console.log({ resp });
        if (resp.user) {
            Swal.fire({
                icon: 'success',
                title: 'Actualizado',
                text: 'Información actualizada correctamente',
                showConfirmButton: false,
                timer: 1500,
            });
            getUser(userId);
        } else {
            if (resp.message) {
                Swal.fire({
                    title: 'Datos incorrectos',
                    text: resp.message,
                    icon: 'warning',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    };

    const rechazar = async () => {
        const resp = await UserService.reject(userId);
        if (resp.success) {
            Swal.fire({
                icon: 'success',
                title: 'Usuario rechazado',
                text: 'Información actualizada correctamente',
                showConfirmButton: false,
                timer: 1500,
            });

            getUser(userId);
        } else {
            if (resp.message) {
                Swal.fire({
                    title: 'Datos incorrectos',
                    text: resp.message,
                    icon: 'warning',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    };
    const aprobar = async () => {
        const resp = await UserService.approve(userId);
        if (resp.success) {
            Swal.fire({
                icon: 'success',
                title: 'Usuario aprobado',
                text: 'Información actualizada correctamente',
                showConfirmButton: false,
                timer: 1500,
            });

            getUser(userId);
        } else {
            if (resp.message) {
                Swal.fire({
                    title: 'Datos incorrectos',
                    text: resp.message,
                    icon: 'warning',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    };

    const handleChangeAddress = (e) => {
        setAddress({
            ...address,
            [e.target.name]: e.target.value,
        });
    };

    const handleAddreses = (setState) => (event) => {
        setState({ ...address, [event.target.name]: event.target.value });
        console.log({ address1, address2, address3, address4, address5 });
    };

    const handleUpdateAddress = async (addressId) => {
        const address = {
            address: 'Hans landa111',
            country: 'co',
            state: '54',
            city: '54245',
        };
        const resp = await UserService.updateAddresses(user._id, addressId, { address })
            .then((resp) =>
                Swal.fire({
                    icon: 'success',
                    title: 'Dirección actualizada',
                    text: 'Dirección actualizada correctamente',
                    showConfirmButton: false,
                    timer: 1500,
                })
            )
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <LayoutAdminComponent>
            <p className="text-primary title mb-4">Detalles del usuario</p>
            <div>
                <button
                    onClick={() => window.history.back()}
                    className="btn btn-sm btn-outline-primary"
                >
                    Volver
                </button>
            </div>
            <div className="bg-primary rounded px-3 py-2 my-4">
                <p className="text-white mb-0">Acerca de la compañia</p>
            </div>

            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-lg-4 mb-4">
                        <label htmlFor="">Nombres *</label>
                        <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            onChange={formik.handleChange}
                            value={formik.values.firstName}
                        />
                        <span className="text-danger">
                            {formik.errors.firstName && formik.errors.firstName}
                        </span>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <label htmlFor="">Apellidos</label>
                        <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                        />
                    </div>
                    <div className="col-lg-4 mb-4">
                        <label htmlFor="">Correo electrónico *</label>
                        <input
                            type="text"
                            className="form-control"
                            id="email"
                            name="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                        />
                        <span className="text-danger">
                            {formik.errors.email && formik.errors.email}
                        </span>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <label htmlFor="">Tipo de Identificación</label>
                        <select
                            type="text"
                            className="form-control"
                            id="id_type"
                            name="id_type"
                            onChange={formik.handleChange}
                            value={formik.values.id_type}
                        >
                            <option value="">Seleccione</option>
                            <option value="13">Cédula de ciudadanía</option>
                            <option value="31">NIT</option>
                            <option value="22">Cédula de extranjería</option>
                            <option value="42">Documento de identificación extranjero</option>
                            <option value="50">NIT de otro país</option>
                            <option value="R-00-PN">No obligado a registrarse en el RUT PN</option>
                            <option value="91">NUIP</option>
                            <option value="41">Pasaporte</option>
                            <option value="47">Permiso especial de permanencia PEP</option>
                            <option value="11">Registro civil</option>
                            <option value="43">
                                Sin identificación del exterior o para uso definido por la DIAN
                            </option>
                            <option value="21">Tarjeta de extranjería</option>
                            <option value="12">Tarjeta de identidad </option>
                        </select>
                        <span className="text-danger">
                            {formik.errors.id_type && formik.errors.id_type}
                        </span>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <label htmlFor="">Identificación</label>
                        <input
                            type="text"
                            className="form-control"
                            id="identification"
                            name="identification"
                            onChange={formik.handleChange}
                            value={formik.values.identification}
                        />
                        <span className="text-danger">
                            {formik.errors.identification && formik.errors.identification}
                        </span>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <label htmlFor="">Número de teléfono</label>
                        <input
                            type="text"
                            className="form-control"
                            name="phone"
                            onChange={formik.handleChange}
                            value={formik.values.phone}
                        />
                    </div>

                    <div className="col-lg-4 mb-4">
                        <SelectCountry
                            handleChange={handleChangeAddress}
                            defaultValue={
                                user?.addresses?.length
                                    ? // ? user?.addresses[0]?.city?.country_code
                                      user?.addresses[0]?.country ||
                                      user?.addresses[0]?.city?.country_code
                                    : ''
                            }
                        />
                    </div>
                    <div className="col-lg-4 mb-4">
                        <SelectState
                            handleChange={handleChangeAddress}
                            defaultValue={
                                // user?.addresses[0]?.city?.state_code
                                user?.addresses?.length
                                    ? user?.addresses[0]?.state ||
                                      user?.addresses[0]?.city?.state_code
                                    : ''
                            }
                            country={address.country}
                        />
                    </div>
                    <div className="col-lg-4 mb-4">
                        <SelectCity
                            handleChange={handleChangeAddress}
                            defaultValue={
                                user?.addresses?.length
                                    ? user?.addresses[0]?.city?.city_code ||
                                      user?.addresses[0]?.city
                                    : ''
                            }
                            country={address.country}
                            state={address.state}
                        />
                    </div>

                    <div className="col-lg-4 mb-3">
                        <label htmlFor="">Dirección</label>
                        <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            onChange={handleChangeAddress}
                            defaultValue={address.address}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="">Casa o Apartamento</label>
                        <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="details"
                            onChange={handleChangeAddress}
                            defaultValue={address.details}
                        />
                    </div>

                    <div className="col-lg-4 mb-4">
                        <label htmlFor="">Actividad económica de la empresa</label>
                        <input
                            type="text"
                            className="form-control"
                            name="actividadEconomica"
                            onChange={formik.handleChange}
                            value={formik.values.actividadEconomica}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 mb-4">
                        <label htmlFor="">Technicians People</label>
                        <input
                            type="text"
                            className="form-control"
                            name="techniciansPeople"
                            onChange={formik.handleChange}
                            value={formik.values.techniciansPeople}
                        />
                    </div>
                </div>

                <div className="d-flex">
                    <button type="submit" className="btn btn-sm btn-outline-danger me-3">
                        Actualizar
                    </button>
                </div>
            </form>
            {
                // Chambonada sin presedentes sorry pero no hay tiempo *//}
            }
            {/* {user?.addresses?.length && user.addresses[0]?.address && (
                <div
                    className="row"
                    style={{
                        border: '1px solid #ccc',
                        padding: '1em',
                        borderRadius: '5px',
                    }}
                >
                    <div className="col-lg-4 mb-3">
                        {/* <SelectCountry
                            handleChange={handleAddreses}
                            defaultValue={address?.city?.country_code}
                        /> 
                        <div className="form-group">
                            <label htmlFor="country">País</label>
                            <select
                                name="country"
                                id="country"
                                onChange={handleAddreses(setAddress1)}
                                className="form-control"
                                defaultValue={address1?.country}
                            >
                                {/* {countries.map((country) => (
                                    <option key={country.countryCode} value={country.countryCode}>
                                        {country.countryName}
                                    </option>
                                ))} 
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        {/* <SelectState
                            handleChange={handleAddreses}
                            defaultValue={address?.city?.state_code}
                            country={address.country}
                        /> 
                        <div className="form-group">
                            <label htmlFor="state">Departamento/Estado</label>
                            <select
                                name="state"
                                id="state"
                                className="form-control"
                                onChange={handleAddreses(setAddress1)}
                                defaultValue={address1?.city?.state_code}
                                //map all states from country
                            >
                                {/* {cities
                                    .filter(
                                        (city) =>
                                            city.CountryCode?.toLowerCase() ===
                                            address1?.city?.country_code?.toLowerCase()
                                    )
                                    .filter(
                                        (city, index, self) =>
                                            self.findIndex(
                                                (c) => c.StateName === city.StateName
                                            ) === index
                                    )
                                    .sort()
                                    .map((city, index) => (
                                        <option key={index} value={city.StateCode}>
                                            {city.StateName}
                                        </option>
                                    ))} 

                                {/* {cities.find(
                                    (city) => city.StateCode === user.addresses[0]?.city?.state_code
                                ) ? (
                                    <option value={user.addresses[0]?.city?.state_code}>
                                        {
                                            cities.find(
                                                (city) =>
                                                    city.StateCode ===
                                                    user.addresses[0]?.city?.state_code
                                            ).StateName
                                        }
                                    </option>
                                ) : (
                                    <option value="">Seleccione</option>
                                )} */}
            {/* {cities.find((city) => city.StateCode === defaultValue) ? (
                                    <option value={defaultValue}>
                                        {
                                            cities.find((city) => city.StateCode === defaultValue)
                                                .StateName
                                        }
                                    </option>
                                ) : (
                                    <option value="">Seleccione</option>
                                )}
                                {cities
                                    .filter(
                                        (city) =>
                                            city.CountryCode?.toLowerCase() ===
                                            country?.toLowerCase()
                                    )
                                    .filter(
                                        (city, index, self) =>
                                            self.findIndex(
                                                (c) => c.StateName === city.StateName
                                            ) === index
                                    )
                                    .sort()
                                    .map((city, index) => (
                                        <option key={index} value={city.StateCode}>
                                            {city.StateName}
                                        </option>
                                    ))} 
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        {/* <SelectCity
                            handleChange={handleAddreses}
                            defaultValue={address?.city?.city_code}
                            country={address.country}
                            state={address.state}
                        /> 
                        <div className="form-group">
                            <label htmlFor="city">Ciudad</label>
                            <select
                                name="city"
                                id="city"
                                className="form-control"
                                onChange={handleAddreses(setAddress1)}
                            >
                                {/* {cities.find((city) => city.CityCode === defaultValue) ? (
                                    <option value={defaultValue}>
                                        {
                                            cities.find((city) => city.CityCode === defaultValue)
                                                .CityName
                                        }
                                    </option>
                                ) : (
                                    <option value="">Seleccione</option>
                                )}
                                {cities
                                    .filter(
                                        (city) =>
                                            city.CountryCode === country && city.StateCode === state
                                    )
                                    .sort()
                                    .map((city, index) => (
                                        <option key={index} value={city.CityCode}>
                                            {city.CityName}
                                        </option>
                                    ))} 
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="">Dirección</label>
                        <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            onChange={handleAddreses(setAddress1)}
                            defaultValue={address.address}
                        />
                    </div>
                    <div>
                        <button className="btn btn-sm btn-outline-danger m-1">Eliminar</button>
                        <button
                            className="btn btn-sm btn-outline-primary"
                            // onClick={() => handleUpdateAddress(index)}
                        >
                            Actualizar
                        </button>
                    </div>
                </div>
            )} */}
            {/* {user?.addresses?.length && user.addresses[1]?.address && (
                <div
                    className="row"
                    style={{
                        border: '1px solid #ccc',
                        padding: '1em',
                        borderRadius: '5px',
                    }}
                >
                    <div className="col-lg-4 mb-3">
                        <SelectCountry
                            handleChange={handleAddreses}
                            defaultValue={user.addresses[1]?.city?.country_code}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <SelectState
                            handleChange={handleAddreses}
                            defaultValue={user.addresses[1]?.city?.state_code}
                            country={address.country}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <SelectCity
                            handleChange={handleAddreses}
                            defaultValue={user.addresses[1]?.city?.city_code}
                            country={address.country}
                            state={address.state}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="">Dirección</label>
                        <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            onChange={handleAddreses}
                            defaultValue={user.addresses[1]?.address}
                        />
                    </div>
                    <div>
                        <button className="btn btn-sm btn-outline-danger m-1">Eliminar</button>
                        <button
                            className="btn btn-sm btn-outline-primary"
                            // onClick={() => handleUpdateAddress(index)}
                        >
                            Actualizar
                        </button>
                    </div>
                </div>
            )} */}
            {/* {user?.addresses?.length && user.addresses[2]?.address && (
                <div
                    className="row"
                    style={{
                        border: '1px solid #ccc',
                        padding: '1em',
                        borderRadius: '5px',
                    }}
                >
                    <div className="col-lg-4 mb-3">
                        <SelectCountry
                            handleChange={handleAddreses}
                            defaultValue={user.addresses[2]?.city?.country_code}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <SelectState
                            handleChange={handleAddreses}
                            defaultValue={user.addresses[2]?.city?.state_code}
                            country={address.country}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <SelectCity
                            handleChange={handleAddreses}
                            defaultValue={user.addresses[2]?.city?.city_code}
                            country={address.country}
                            state={address.state}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="">Dirección</label>
                        <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            onChange={handleAddreses}
                            defaultValue={user.addresses[2]?.address}
                        />
                    </div>
                    <div>
                        <button className="btn btn-sm btn-outline-danger m-1">Eliminar</button>
                        <button
                            className="btn btn-sm btn-outline-primary"
                            // onClick={() => handleUpdateAddress(index)}
                        >
                            Actualizar
                        </button>
                    </div>
                </div>
            )} */}
            {/* {user?.addresses?.length && user.addresses[3]?.address && (
                <div
                    className="row"
                    style={{
                        border: '1px solid #ccc',
                        padding: '1em',
                        borderRadius: '5px',
                    }}
                >
                    <div className="col-lg-4 mb-3">
                        <SelectCountry
                            handleChange={handleAddreses}
                            defaultValue={user.addresses[3]?.city?.country_code}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <SelectState
                            handleChange={handleAddreses}
                            defaultValue={user.addresses[3]?.city?.state_code}
                            country={address.country}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <SelectCity
                            handleChange={handleAddreses}
                            defaultValue={user.addresses[3]?.city?.city_code}
                            country={address.country}
                            state={address.state}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="">Dirección</label>
                        <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            onChange={handleAddreses}
                            defaultValue={user.addresses[3]?.address}
                        />
                    </div>
                    <div>
                        <button className="btn btn-sm btn-outline-danger m-1">Eliminar</button>
                        <button
                            className="btn btn-sm btn-outline-primary"
                            // onClick={() => handleUpdateAddress(index)}
                        >
                            Actualizar
                        </button>
                    </div>
                </div>
            )} */}
            {/* {user?.addresses?.length && user.addresses[4]?.address && (
                <div
                    className="row"
                    style={{
                        border: '1px solid #ccc',
                        padding: '1em',
                        borderRadius: '5px',
                    }}
                >
                    <div className="col-lg-4 mb-3">
                        <SelectCountry
                            handleChange={handleAddreses}
                            defaultValue={user.addresses[4]?.city?.country_code}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <SelectState
                            handleChange={handleAddreses}
                            defaultValue={user.addresses[4]?.city?.state_code}
                            country={address.country}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <SelectCity
                            handleChange={handleAddreses}
                            defaultValue={address?.city?.city_code}
                            country={user.addresses[4].country}
                            state={address.state}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="">Dirección</label>
                        <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            onChange={handleAddreses}
                            defaultValue={user.addresses[4]?.address}
                        />
                    </div>
                    <div>
                        <button className="btn btn-sm btn-outline-danger m-1">Eliminar</button>
                        <button
                            className="btn btn-sm btn-outline-primary"
                            // onClick={() => handleUpdateAddress(index)}
                        >
                            Actualizar
                        </button>
                    </div>
                </div>
            )} */}
            <div className="row mt-3">
                <div className="col-md-6">
                    <div className="bg-primary rounded px-3 py-2 my-  3">
                        <p className="text-white mb-0">Agregar contraseña</p>
                    </div>
                    <ChangePassword userId={userId} />
                </div>
                <div className="col-md-6 mt-3 mt-md-0">
                    <UserSiigoInfo user={user} updateData={getUser} />
                </div>
            </div>
            <hr className="mt-5" />
            <div className="d-flex" style={{ gap: '1em' }}>
                {user?.approvalStatus === 'pending' ? (
                    <>
                        <button className="btn btn-sm btn-outline-danger" onClick={rechazar}>
                            Rechazar
                        </button>
                        <button className="btn btn-sm btn-outline-primary" onClick={aprobar}>
                            Aprobar
                        </button>
                    </>
                ) : user?.approvalStatus === 'approved' ? (
                    <>
                        <span className="text-success">Aprobado</span>
                        <button className="btn btn-sm btn-outline-danger" onClick={rechazar}>
                            Rechazar
                        </button>
                    </>
                ) : (
                    <>
                        <span className="text-danger">Rechazado</span>
                        <button className="btn btn-sm btn-outline-primary" onClick={aprobar}>
                            Aprobar
                        </button>
                    </>
                )}
            </div>
        </LayoutAdminComponent>
    );
}
