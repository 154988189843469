import React, { useEffect, useState } from 'react';
import { NavLink, useParams, useNavigate } from 'react-router-dom';

import { formatCurrency } from '../../utils/format';
import imgPlaceholder from '../../assets/placeholder.gif';
import ProductService from '../../services/ProductService';
import LayoutComponent from '../../components/LayoutComponent';
import ModalAddToCart from '../../components/ModalAddToCart';
import { BottomNav } from '../../components/BottomNav';
import ReactPaginate from 'react-paginate';
import { Stack } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import _ from 'lodash';

export default function ProductList() {
    const params = useParams();

    const [products, setProducts] = useState([]);
    const [showCartsModal, setShowCartsModal] = useState(false);
    const [product, setProduct] = useState({});
    const [crossReference, setCrossReference] = useState();
    const [searchText, setSearchText] = useState('');
    const [asc, setAsc] = useState(false);
    const savedProducts = JSON.parse(localStorage.getItem('noShowProducts'));
    const [avaliableSelectedProducts, setAvaliableSelectedProducts] = useState([]);
    const [dispatchShow, setDispatchShow] = useState(false);
    const [quantitySearch, setQuantitySearch] = useState(0);
    const [showQuantityMessage, setShowQuantityMessage] = useState(false);

    const [page, setPage] = useState(1);

    const pageCount = Math.ceil(2415 / 100);

    const navigate = useNavigate();

    useEffect(() => {
        const search = params['*'];
        setProducts([]);
        getProducts(search ?? '');
    }, [params]);

    async function getProducts(search) {
        const products = await ProductService.getProducts(search, quantitySearch, page);
        const initialSelectedProducts = products.map((product) => {
            const isSelected = savedProducts && savedProducts.includes(product._id);
            if (!product.quantity) {
                product.quantity = 1;
            } else {
                product.quantity = parseInt(product.quantity);
            }
            return {
                ...product,
                show: isSelected,
                chedkedProduct: false,
            };
        });

        setProducts(
            _.orderBy(
                initialSelectedProducts?.length > 0 ? initialSelectedProducts : products,
                (item) => new Date(item.metadata?.created),
                'desc'
            )
        );
    }
    function openAddToCart(product, crossReference) {
        setShowCartsModal(true);
        console.log(product);
        setProduct(product);
        setCrossReference(crossReference);
    }

    const search = () => {
        if (searchText.length > 0) {
            getProducts(searchText);
            // dispatchQuantity(products);
            if (quantitySearch > 0) {
                setShowQuantityMessage(true);
            } else {
                setShowQuantityMessage(false);
            }
        } else {
            getProducts('');
            setShowQuantityMessage(false);
        }
    };

    useEffect(() => {
        if (searchText.length === 0) {
            getProducts('');
        }
        setDispatchShow(false);
    }, [searchText, dispatchShow, page]);

    const sorteByCreatedDate = (asc) => {
        const filtered = _.orderBy(
            products,
            (item) => new Date(item.metadata?.created),
            asc ? 'asc' : 'desc'
        );
        setProducts(filtered);
        asc ? setAsc(true) : setAsc(false);
    };
    const handleAllSelectedProducts = (checked) => {
        const filtered = products.map((product) => {
            return {
                ...product,
                checkedProduct: checked,
            };
        });
        setProducts([...filtered]);
    };

    const handleQuantity = (e, product) => {
        const value = e.target.value;
        const newProducts = [...products];
        const index = newProducts.findIndex((p) => p._id === product._id);
        newProducts[index].quantity = value;
        setProducts(newProducts);
    };

    const handlePageClick = (event) => {
        setPage(event.selected + 1);
    };

    useEffect(() => {
        const filtered = products.filter((product) => product.checkedProduct);
        setAvaliableSelectedProducts(filtered);
    }, [products]);

    return (
        <LayoutComponent>
            <ModalAddToCart
                show={showCartsModal}
                handleClose={() => setShowCartsModal(false)}
                product={product}
                crossReference={crossReference}
                quantity={1}
            />
            <div className="container-fluid py-3" style={{ background: '#E0E0E0' }}>
                <div className="container">
                    <button className="btn btn-outline-primary mb-2" onClick={() => navigate(-1)}>
                        volver
                    </button>
                    <p className="mb-2">Ingresa el número de parte para realizar una busqueda</p>
                    <div className="d-flex gap-1">
                        <div className="d-flex">
                            <input
                                type="text"
                                className="form-control mb-2"
                                placeholder="Número de parte"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                onKeyUp={(e) => e.key === 'Enter' && search()}
                                style={{ width: '300px' }}
                            />
                            <input
                                type="number"
                                className="form-control mb-2"
                                placeholder="Cantidad"
                                min="0"
                                onChange={(e) => setQuantitySearch(e.target.value)}
                                style={{ width: '130px' }}
                            />
                        </div>
                        <div className="col-lg-8 col-lg-8 d-flex align-items-center">
                            <button className="btn btn-outline-primary mb-2" onClick={search}>
                                Buscar
                            </button>
                            <button
                                className={
                                    asc
                                        ? 'btn btn-outline-primary mb-2 mx-1 active'
                                        : 'btn btn-outline-primary mb-2 mx-1 '
                                }
                                onClick={() => sorteByCreatedDate(true)}
                            >
                                Ordenar más viejo
                            </button>
                            <button
                                className={
                                    !asc
                                        ? 'btn btn-outline-primary mb-2 mx-1 active'
                                        : 'btn btn-outline-primary mb-2 mx-1 '
                                }
                                onClick={() => sorteByCreatedDate(false)}
                            >
                                Ordenar más nuevo
                            </button>

                            {/* <div className="mb-2">
                                <CheckboxSelect
                                    options={options}
                                    setOptionsSelected={setSelected}
                                />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#F2F2F2', minHeight: 'calc(100vh - 130px)' }}>
                <div className="container pt-4">
                    {products?.length === 0 && (
                        <div className="text-center">
                            <h3 className="text-primary">No se encontraron productos</h3>
                        </div>
                    )}
                    {products?.map((product, index) => (
                        <div
                            key={index}
                            className="card mb-3 product-card-list"
                            style={product?.show ? { display: 'none' } : {}}
                            // || product.available_quantity < 1 
                        >
                            {/* <h1>{product.account_group.name}</h1> */}
                            <div className="card-body bg-white rounded ">
                                <div className="row">
                                    <div className="col-lg-2 mb-2 d-flex justify-content-center">
                                        <div className="d-flex">
                                            <input
                                                type="checkbox"
                                                name=""
                                                id=""
                                                className="me-2 "
                                                onChange={(e) => {
                                                    const newProducts = [...products];
                                                    const index = newProducts.findIndex(
                                                        (p) => p._id === product._id
                                                    );
                                                    newProducts[index].checkedProduct =
                                                        e.target.checked;
                                                    setProducts(newProducts);
                                                }}
                                                checked={product.checkedProduct}
                                            />
                                            <div className="px-1">
                                                <img
                                                    src={product?.image || imgPlaceholder}
                                                    alt=""
                                                    className="img-fluid rounded "
                                                    style={{ maxHeight: '150px' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 mb-2 d-flex align-items-start flex-column justify-content-center">
                                        <p className="text-primary mb-0">{product?.name}</p>
                                        {product?.additional_fields && (
                                            <small className="text-primary">
                                                {[
                                                    product?.additional_fields?.brand,
                                                    product?.additional_fields?.model,
                                                ].join(' / ')}
                                            </small>
                                        )}
                                        <small className="text-muted">{product?.code}</small>
                                    </div>
                                    <div className="col-lg-2 mb-2 ">
                                        <label>Referencia</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm mb-2"
                                            value={product?.code}
                                        />

                                        <label>Bodega</label>
                                        <select className="form-control form-control-sm">
                                            <option value="">Bodega 1</option>
                                            <option value="">Bodega 2</option>
                                            <option value="">Bodega 3</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-1 mb-2">
                                        <label>Cant.</label> <br />
                                        <input
                                            type="number"
                                            className="form-control form-control-sm mb-2"
                                            min="0"
                                            defaultValue={product.quantity}
                                            label="Cantidad"
                                            onChange={(e) => handleQuantity(e, product)}
                                            key={
                                                'OKAYG_' +
                                                (10000 + Math.random() * (1000000 - 10000))
                                            }
                                            onKeyUp={(e) => e.key === 'Enter' && search()}
                                        />
                                        {showQuantityMessage && (
                                            <span>
                                                {product.available_quantity < product.quantity &&
                                                    `Hay ${product.available_quantity} disponibles`}
                                            </span>
                                        )}
                                        {/* {<input disabled={product.available_quantity > 0 ? false : true}  type="number" min={1} max={product.available_quantity} onChange={(ev)=>{validateQuantity(ev, product.available_quantity)}} value={quatitySelected} className='form-control form-control-sm mb-1' />} */}
                                    </div>
                                    <div className="col-lg-2 mb-2">
                                        <label>Precio</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm mb-2"
                                            value={formatCurrency(
                                                product?.price,
                                                product?.currency
                                            )}
                                            readOnly
                                        />
                                        <label>Precio Total</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm mb-2"
                                            value={formatCurrency(
                                                product?.price,
                                                product?.currency
                                            )}
                                        />
                                    </div>
                                    <div className="col-lg-2 mb-2 text-center pt-4">
                                        <NavLink
                                            to={`/producto/${product?._id}/${product?.name
                                                ?.toLowerCase()
                                                .replaceAll(' ', '-')}`}
                                            className="btn btn-sm btn-outline-primary mb-md-3 mb-0 me-3 me-md-0"
                                        >
                                            Detalle producto
                                        </NavLink>
                                        <button
                                            className="btn btn-sm btn-outline-primary"
                                            onClick={() => openAddToCart(product, product?.code)}
                                        >
                                            Agregar al carrito
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="d-flex justify-content-center my-5">
                        <Stack
                            spacing={2}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '2rem',
                            }}
                        >
                            <ReactPaginate
                                breakLabel="..."
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                renderOnZeroPageCount={null}
                                previousLabel={<ChevronLeftIcon />}
                                nextLabel={<KeyboardArrowRightIcon />}
                                containerClassName="pagination"
                            />
                        </Stack>
                    </div>
                    {/**Pagination */}
                </div>
            </div>
            <BottomNav
                handleSelect={handleAllSelectedProducts}
                avaliableProducts={avaliableSelectedProducts}
                dispatchShow={setDispatchShow}
            />
        </LayoutComponent>
    );
}
