import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import LayoutAdminComponent from '../../components/LayoutAdminComponent';
import { formatCurrency } from '../../utils/format';
import OrderService from '../../services/OrderService';
import { cities, countries, statusBadgeColor, statusBadgeText } from '../../constants';
import { userLoged } from '../../utils/auth';
import _ from 'lodash';
import { AscButton, DescButton } from '../../components/SortButton';

export default function PedidosPage() {
    const [orders, setOrders] = useState(null);
    const [asc, setAsc] = useState(true);
    const [desc, setDesc] = useState(false);
    useEffect(() => {
        const getOrders = async () => {
            const response = await OrderService.getOrders();
            const filterBackOrder = response.filter((order) => order.status !== 'backorder');
            const sorted = _.orderBy(filterBackOrder, (item) => new Date(item.createdAt), 'desc');
            setOrders(sorted);
        };
        getOrders();
    }, []);

    const handleSort = () => {
        if (asc) {
            setOrders(_.orderBy(orders, (item) => new Date(item.createdAt), ['asc']));
            setDesc((desc) => !desc);
            setAsc((asc) => !asc);
        } else {
            setOrders(_.orderBy(orders, (item) => new Date(item.createdAt), ['desc']));
            setAsc((asc) => !asc);
            setDesc((desc) => !desc);
        }
    };

    return (
        <LayoutAdminComponent>
            {userLoged.role === 'admin' ? (
                <p className="text-primary title mb-4">Gestor de pedidos</p>
            ) : (
                <div>
                    <p className="text-primary title mb-4">Aprobar pedidos</p>
                </div>
            )}

            <div className="d-flex justify-content-end">
                <div>
                    <AscButton label="Ordenar más nuevo" asc={asc} onClick={handleSort} />
                    <DescButton label="Ordenar más viejo" desc={desc} onClick={handleSort} />
                </div>
            </div>

            <div className="card shadow pt-3 pb-3">
                <div className="card-body">
                    <table className="w-100">
                        <thead>
                            <tr>
                                <th>Número de orden</th>
                                <th>Cliente</th>
                                <th>Cantidad de productos</th>
                                <th>Valor total</th>
                                <th>Dirección</th>
                                <th>Metodo de entrega</th>
                                <th>Fecha de creación </th>
                                <th>Status</th>
                                <th>Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(!orders || orders.length === 0) && (
                                <tr>
                                    <td colSpan="6" className="text-center">
                                        No hay pedidos
                                    </td>
                                </tr>
                            )}
                            {orders &&
                                orders.map((order) => (
                                    <tr key={order._id}>
                                        <td>#{order.order_number}</td>
                                        <td>
                                            {[order.user?.firstName, order.user?.lastName]
                                                .filter((item) => item)
                                                .join(' ')}
                                        </td>
                                        <td>
                                            {order.products.reduce(
                                                (acc, product) => acc + product.quantity,
                                                0
                                            )}
                                        </td>
                                        <td>{formatCurrency(order.total)}</td>
                                        <td>
                                            {[
                                                countries.find(
                                                    (country) =>
                                                        country.countryCode ===
                                                        order?.address?.country
                                                )?.countryName,
                                                cities.find(
                                                    (city) =>
                                                        city.StateCode === order?.address?.state
                                                )?.StateName,
                                                cities.find(
                                                    (city) => city.CityCode === order?.address?.city
                                                )?.CityName,
                                                order.address?.address,
                                                order.address?.apartment,
                                            ]
                                                .filter((item) => item)
                                                .join(', ')}
                                        </td>
                                        <td>{order.delivery_method}</td>
                                        <td>
                                            {moment(order.createdAt).format('DD/MM/YYYY HH:mm a')}
                                        </td>
                                        <td>
                                            <span
                                                className={`badge ${
                                                    statusBadgeColor[order.status]
                                                }`}
                                            >
                                                {statusBadgeText[order.status]}
                                            </span>
                                        </td>

                                        <td>
                                            {userLoged.role === 'admin' ? (
                                                <NavLink
                                                    className="d-block"
                                                    to={`/gestor-pedidos/pedido/${order._id}`}
                                                >
                                                    Ver detalles
                                                </NavLink>
                                            ) : (
                                                <NavLink
                                                    className="d-block"
                                                    to={`/pedido/${order._id}`}
                                                >
                                                    Ver detalles
                                                </NavLink>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </LayoutAdminComponent>
    );
}
